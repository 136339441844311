import axios from 'axios';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generatePath, Redirect, useParams } from 'react-router';
import { Loader } from 'semantic-ui-react';
import ShortenedPlayerName from '../../common/ShortenedName';
import History from '../../toolbar/History';
import Profile from '../../toolbar/Profile';
import ContractOffer from '../../toolbar/Contract';
import TransferOfferNew from '../../toolbar/Transfer';

import {PlayerFreeAuctionCreateOfferInterface, PlayerFreeAuctionViewOfferInterface} from './interface';
import { URL } from '../../../common/lib/paths';

const includeStr = 'includes=playerContract,person'

export default function PlayerFreeAuction(props) {
    const {playerFreeAuctionId, playerId} = useParams();

    const getPlayerFreeAuctionQuery = useQuery(
        ['getPlayerFreeAuction', playerFreeAuctionId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionFree/${playerFreeAuctionId}`),
        {
            enabled: playerFreeAuctionId !== undefined
        }
    );

    const getPlayerQuery = useQuery(
        ['getPlayer', playerId, includeStr],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/?${includeStr}`)
    );

    useEffect(() => {
        if(getPlayerQuery.data?.data?.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getPlayerQuery.data.data.response} /> offer</>,
                navigation: [
                    <Profile key={1} playerId={playerId} />,
                    <ContractOffer key={2} playerId={playerId} myClubId={props.manager.club?.id} playerClubId={getPlayerQuery.data.data.response.playerContract?.clubId} retirementSeasonId={getPlayerQuery.data.data.response.retirementSeasonId} />,
                    <TransferOfferNew key={3} playerId={playerId} myClubId={props.manager.club?.id} playerClubId={getPlayerQuery.data.data.response.playerContract?.clubId} retirementSeasonId={getPlayerQuery.data.data.response.retirementSeasonId} />,
                    <History key={4} playerId={playerId} />
                ]
            });
        }
    }, [getPlayerQuery.data]);

    if(!!getPlayerQuery.data?.data?.response.playerContract) {
        return(
            <Redirect to={generatePath(URL.playerAuction, {playerId: playerId})} />
        );
    }

    if(!playerFreeAuctionId) {
        return(
            <PlayerFreeAuctionCreateOfferInterface
                playerFreeAuction={null} //assumed no auction in progress
            />
        )
    } else {
        if(getPlayerFreeAuctionQuery.isLoading) {
            return(
                <Loader active />
            )
        } else {
            if(!!getPlayerFreeAuctionQuery.data?.data?.response.playerTransferAuctionBidFree) {
                return(
                    <PlayerFreeAuctionViewOfferInterface
                        playerFreeAuction={getPlayerFreeAuctionQuery.data?.data?.response}
                    />
                )
            } else {
                return(
                    <PlayerFreeAuctionCreateOfferInterface
                        playerFreeAuction={getPlayerFreeAuctionQuery.data?.data?.response}
                    />
                )
            }
        }
    }
}
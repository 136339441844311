import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedDate } from "react-intl";

function contractExpiry(
    dateResolver,
    sortable,
    hide
) {
    return date(
        'contractExpiry',
        dateResolver,
        sortable,
        hide,
        'numeric',
        'short',
        undefined,
        'UTC'
    );
}

function seasonName(
    dateResolver,
    sortable,
    hide,
    left
) {
    return date(
        'contractExpiry',
        dateResolver,
        sortable,
        hide,
        'numeric',
        'short',
        undefined,
        'UTC',
        left
    );
}

function date(
    id,
    dateResolver,
    sortable,
    hide,
    day='numeric',
    month='short',
    year='2-digit',
    timeZone,
    left = false,
    omit
) {
    return {
        id,
        name: <FontAwesomeIcon icon={faClock} />,
        selector: (rowData) => {const d = dateResolver(rowData); return !!d ? <FormattedDate value={dateResolver(rowData)} day={day} month={month} year={year} timeZone={timeZone} /> : '-'},
        sortable,
        sortFunction: sorter,
        wrap: false,
        allowOverflow: false,
        compact: true,
        center: !left,
        grow: 1,
        minWidth: '60px',
        maxWidth: '80px',
        hide,
        left,
        omit
    }

    function sorter(a, b) {
        a = dateResolver(a);
        b = dateResolver(b);

        return b-a;
    }
}

export {
    contractExpiry,
    seasonName,
    date
}
import { useEffect, useMemo } from "react"
import { generatePath, useHistory, useLocation } from "react-router";
import { Grid, Header, Menu, Placeholder, Segment } from "semantic-ui-react";
import { groupBy } from "lodash";
import { FormattedDate, FormattedTime } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import CupRoundName from "../cup/RoundName";
import TeamLink from "../team/common/TeamLink";
import { Link } from "react-router-dom";
import { URL } from "../common/lib/paths";
import { useTutorial } from "../tutorial/hooks/useTutorial";
import dayjs from 'dayjs';

export default function FixturesInterface(props) {
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if(!location.state?.fixtureStatus && !!props.fixtures) {
            if(
                props.fixtures.planned.length > 0
            ) {
                var d = 'planned';
            } else {
                var d = 'complete';
            }
        
            history.replace({pathname: location.pathname, state: {...location.state, fixtureStatus: d}})
        }
    }, [location.state?.fixtureStatus, props.fixtures]);

    const fixtures = useMemo(() => {
        if(!props.fixtures) return;

        return {
            complete: groupBy(props.fixtures.complete, fixtureGrouper),
            planned: groupBy(props.fixtures.planned, fixtureGrouper)
        }
    }, [props.fixtures]);

    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('fixtures', 1);
    const isTutorial2 = checkTutorial('fixtures', 4);

    let tutorialFixtureId, tutorialNextStepSearchString, tutorialNotClubId;
    if(isTutorial1 === true) {
        tutorialFixtureId = fixtures.planned[0].id;
        tutorialNotClubId = props.manager.club.id;
        tutorialNextStepSearchString = getSearchParamsString('fixtures', 2);
    } else if(isTutorial2 === true) {
        tutorialFixtureId = fixtures.complete[0].id;
        tutorialNextStepSearchString = getSearchParamsString('fixtures', 5);
    }

    return (
        <>
            {props.isLoading || !location.state?.fixtureStatus ?
                <LoadingCards />
                :
                Object.keys(fixtures[location.state.fixtureStatus]).length > 0 ?
                    Object.keys(fixtures[location.state.fixtureStatus]).map((date) => (
                        <FixtureCard
                            key={fixtures[location.state.fixtureStatus][date].id}
                            date={dayjs(date, 'DD/MM/YYYY').toDate()}
                            fixtures={fixtures[location.state.fixtureStatus][date]}
                            highlightClubId={props.highlightClubId}
                            tutorialFixtureId={tutorialFixtureId}
                            tutorialNotClubId={tutorialNotClubId}
                            tutorialNextStepSearchString={tutorialNextStepSearchString}
                            view={props.view}
                        />
                    ))
                    :
                    <Segment><p>No fixtures found</p></Segment>
            }
        </>
    )
}

function FixtureCard(props) {
    return(
        <Segment>
            <Header as='h4' style={styles.fixtureCardHeader}>
                <FormattedDate value={props.date} month='long' day='numeric' weekday='long' />
            </Header>
            <Grid style={styles.fixtureCardGrid}>
                {props.fixtures.map((fixture) => (
                    <>
                        <Grid.Row
                            columns={1}
                            style={styles.fixtureCardRowA}
                        >
                            <Grid.Column
                                style={styles.fixtureCardRowAColumn1}
                            >
                                <Header as='h5'>
                                    {fixture.type === 'promotion_playoff' ? 'Promotion play-off final - ' : null}
                                    {fixture.type === 'relegation_playoff' ? 'Relegation play-off final - ' : null}
                                    {fixture.type === 'cup' && props.view !== 'cupFixtures' ?
                                        <span><FontAwesomeIcon icon={faTrophy} className='inlineIcon' /> Mug of Nations <CupRoundName roundsFromFinal={fixture.fixtureRound.roundsFromFinal} /> </span>
                                        :
                                        null
                                    }
                                    <FormattedTime value={fixture.fixtureRound.gameJob.startDate} hour='numeric' />
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row
                            style={styles.fixtureCardRowB}
                        >
                            <Grid.Column
                                width={6}
                                style={styles.fixtureCardRowBColumn1}
                            >
                                <TeamLink
                                    team={fixture.homeTeam}
                                    className={props.tutorialFixtureId === fixture.id && !!props.tutorialNotClubId && props.tutorialNotClubId !== fixture.homeTeam.club.id && 'glow'}
                                    search={props.tutorialFixtureId === fixture.id && !!props.tutorialNotClubId && props.tutorialNotClubId !== fixture.homeTeam.club.id && props.tutorialNextStepSearchString}
                                />
                            </Grid.Column>
                            <Grid.Column
                                width={4}
                                style={styles.fixtureCardRowBColumn2}
                            >
                                
                                    <Link
                                        to={{
                                            pathname: generatePath(fixture.type === 'cup' ? URL.cupFixture : URL.leagueFixture, {fixtureId: fixture.id}),
                                            search: props.tutorialFixtureId === fixture.id && props.tutorialNextStepSearchString
                                        }}
                                        className={props.tutorialFixtureId === fixture.id && 'glow'}
                                    >
                                        {fixture.status !== 'planned' ?
                                            `\u00A0${fixture.scoreHome + (fixture.penaltyScoreHome || 0)} - ${fixture.scoreAway + (fixture.penaltyScoreAway || 0)}\u00A0`
                                            :
                                            '\u00A0vs.\u00A0'
                                        }
                                    </Link>
                            </Grid.Column>
                            <Grid.Column
                                width={6}
                                style={styles.fixtureCardRowBColumn3}
                            >
                                <TeamLink
                                    team={fixture.awayTeam}
                                    className={props.tutorialFixtureId === fixture.id && !!props.tutorialNotClubId && props.tutorialNotClubId !== fixture.homeTeam.club.id && 'glow'}
                                    search={props.tutorialFixtureId === fixture.id && !!props.tutorialNotClubId && props.tutorialNotClubId !== fixture.homeTeam.club.id && props.tutorialNextStepSearchString}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        {fixture.penaltyScoreHome !== null && fixture.penaltyScoreAway !== null &&
                            <Grid.Row
                                style={styles.fixtureCardRowC}
                                columns={1}
                            >
                                <Grid.Column style={styles.fixtureCardRowAColumn1}>
                                    <Header as='h5'>After penalties</Header>
                                </Grid.Column>
                            </Grid.Row>
                        }
                    </>
                ))}
            </Grid>
        </Segment>
    )
}

function LoadingCards(props) {
    return(
        <>
            {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(() =>
                <Segment>
                    <Placeholder style={{width: '50%', margin: '0 auto'}}>
                        <Placeholder.Header>
                            <Placeholder.Line length='full' />
                        </Placeholder.Header>
                    </Placeholder>
                    <Grid style={{marginTop: '0.5em'}}>
                        <Grid.Row>
                            <Grid.Column width={6} style={{textAlign: 'right', padding: 0}}>
                                <Placeholder style={{width: '80%', margin: '0 auto'}}>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Grid.Column>
                            <Grid.Column width={4} style={{textAlign: 'center', padding: 0}}>
                                <Placeholder style={{width: '60%', margin: '0 auto'}}>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Grid.Column>
                            <Grid.Column width={6} style={{padding: 0}}>
                                <Placeholder style={{width: '80%', margin: '0 auto'}}>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            )}
        </>
    )
}

function fixtureGrouper(fixture) {
    return dayjs(fixture.fixtureRound.gameJob.startDate).format('DD/MM/YYYY');
}

const styles = {
    fixtureCardHeader: {
        textAlign: 'center',
        margin: 0
    },
    fixtureCardGrid: {
        margin: 0
    },
    fixtureCardRowA: {
        padding: 0,
        paddingTop: '1em'
    },
    fixtureCardRowAColumn1: {
        textAlign: 'center'
    },
    fixtureCardRowB: {
        padding: 0
    },
    fixtureCardRowBColumn1: {
        textAlign: 'right',
        paddingRight: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fixtureCardRowBColumn2: {
        textAlign: 'center',
        padding: 0
    },
    fixtureCardRowBColumn3: {
        paddingLeft: 0,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fixtureCardRowC: {
        paddingTop: 0
    }
}
import {Button, Icon, Dropdown} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {filter} from "../../../common/components/i18n.js";
import {CSSTransition} from 'react-transition-group-react-18';

import FilterOverlay from '../overlay/interface';
import SUIIconShim from '../../../common/components/SUIIconShim.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import usePersistedState from '../../../common/lib/usePersistedState.js';
import { filterStateReducer, initialFilterState } from '../lib.js';
import { useTutorial } from '../../../tutorial/hooks/useTutorial.js';

export default function FilterButton(props) {    
    const {persistedState, updatePersistedState} = usePersistedState(props.persistedStateKey, initialFilterState);
    const {checkTutorial, goNextStep} = useTutorial();

    const isTutorial1 = checkTutorial('transfers', 0);

    return(
        <>
            <Button.Group>
                <Button
                    color='black'
                    toggle
                    icon
                    labelPosition="left"
                    onClick={handleFilterClick.bind(this)}
                    active={props.isFilterActive}
                    style={{backgroundColor: 'rgba(15, 16, 17)'}}
                    className={isTutorial1 && 'glow'}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faFilter} className='buttonIconFix' />
                    </SUIIconShim>
                    <FormattedMessage {...filter} />
                </Button>
                <Dropdown
                    toggle
                    active={props.isFilterActive}
                    as={Button}
                    className="icon"
                    color='black'
                    style={{backgroundColor: 'rgba(15, 16, 17)'}}
                >
                    <Dropdown.Menu>
                        {/*this.renderSavedFilterMenuItems()*/}
                        <Dropdown.Divider style={{margin: 0}} />
                        {/*<Dropdown.Item text="Save filter" />*/}
                        <Dropdown.Item text="Clear filter" onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'clearFilters'}))} />
                    </Dropdown.Menu>
                </Dropdown>
            </Button.Group>
            <CSSTransition
                in={persistedState.filterOverlayOpen}
                timeout={300}
                classNames='transferSearchFilterOverlay'
                mountOnEnter={true}
                unmountOnExit={true}
            >
                {state => 
                    <FilterOverlay
                        state={state}
                        persistedStateKey={props.persistedStateKey}
                    />
                }
            </CSSTransition>
        </>
    );

    function handleFilterClick() {
        updatePersistedState(filterStateReducer(persistedState, {name: 'toggleFilterOverlay'}))
        isTutorial1 && goNextStep();
    }

    function handleSavedFilterClick() {
        //apply saved filter
    }

    function handleSaveFilter() {

    }
}
import {Component} from 'react'
import { Table } from 'semantic-ui-react';

export default class MessageErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {}
  
    render() {
      if (this.state.hasError) {
        return(
            <Table.Row key={this.props.key} negative>
                <Table.Cell width={6}>Error (ID {this.props.id})</Table.Cell>
                <Table.Cell width={8}>Error when loading this message ({this.props.type})</Table.Cell>
            </Table.Row>
        )
      }
  
      return this.props.children;
    }
  }
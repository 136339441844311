import {defineMessages} from 'react-intl';

import {URL} from '../../../common/lib/paths';
import ToolbarItem from '../../../menu/toolbar/ToolbarItem';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
    text: {
        id: 'world:toolbar:nations',
        defaultMessage: 'Clubs',
        description: 'Link in the toolbar in the world section for the view all clubs page'
    }
})

export default function WorldClubsToolbarItem(props) {
    return(
        <ToolbarItem
            path={URL.worldClubs}
            icon={faShieldHalved}
            messages={messages}
        />
    )
}
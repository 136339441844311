import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import NarrowLayout from "../../common/components/NarrowLayout";
import CityProfileToolbarItem from "../toolbar/Profile";

import CityProfileInterface from "./interface";

export default function CityProfile(props) {
    const {cityId} = useParams();

    const {data, error, isLoading, isError} = useQuery(
        ['getCity', cityId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cities/${cityId}`)
    );

    useEffect(() => {
        if(data?.data?.response) {
            props.setToolbar({
                title: `${data.data.response.name} profile`,
                navigation: [
                    <CityProfileToolbarItem cityId={cityId} />
                ]
            })
        }
    }, [cityId, data?.data?.response])

    return(
        <NarrowLayout>
            <CityProfileInterface
                city={data?.data?.response}
                isLoading={isLoading}
            />
        </NarrowLayout>
    )
}
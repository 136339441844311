const style = {
    maxWidth: '400px',
    margin: '0 auto'
}

export default function NarrowLayout(props) {
    return(
        <div style={{...style, ...props.style}}>
            {props.children}
        </div>
    )
}
import {Grid, Progress, Placeholder} from 'semantic-ui-react';
import meanBy from 'lodash/meanBy';

export default function Stats(props) {
    let homeTeamPlayerAbilityMean, awayTeamPlayerAbilityMean, homeTeamPlayerEffectiveMean, awayTeamPlayerEffectiveMean;

    if(!props.isLoading) {
        homeTeamPlayerAbilityMean = Math.round(meanBy(props.lineups.home, (lineup) => lineup.player[0].currentAbilityInPlayingPosition));
        awayTeamPlayerAbilityMean = Math.round(meanBy(props.lineups.away, (lineup) => lineup.player[0].currentAbilityInPlayingPosition));
        homeTeamPlayerEffectiveMean = Math.round(meanBy(props.lineups.home, (lineup) => lineup.player[0].effectiveCurrentAbility));
        awayTeamPlayerEffectiveMean = Math.round(meanBy(props.lineups.away, (lineup) => lineup.player[0].effectiveCurrentAbility));
    }

    return(
        <Grid columns='equal' style={{margin: 0}}>
            <Grid.Row>
                <p style={{textAlign: 'center', width: '100%', marginBottom:'0.5em'}}>Shots</p>
                {props.isLoading ?
                    <Placeholder style={{width: '100%', margin: '0 auto', height: '12px'}}>
                        <Placeholder.Header>
                            <Placeholder length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <div style={{position: 'relative', width: '100%'}}>
                        <Progress percent={Math.round((props.stats.home.shots/(props.stats.home.shots+props.stats.away.shots))*100)} style={{width: '100%', margin: 0}} className='stat' />
                        <span style={{position: 'absolute', left: '6px', top: '3px', color:'white', fontWeight: 'bold'}}>{props.stats.home.shots}</span>
                        <span style={{position: 'absolute', right: '6px', top: '3px', color:'white', fontWeight: 'bold'}}>{props.stats.away.shots}</span>
                    </div>
                }
            </Grid.Row>
            <Grid.Row>
                <p style={{textAlign: 'center', width: '100%', marginBottom:'0.5em'}}>Shots on target</p>
                {props.isLoading ?
                    <Placeholder style={{width: '100%', margin: '0 auto', height: '12px'}}>
                        <Placeholder.Header>
                            <Placeholder length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <div style={{position: 'relative', width: '100%'}}>
                        <Progress percent={Math.round((props.stats.home.onTarget/(props.stats.home.onTarget+props.stats.away.onTarget))*100)} style={{width: '100%', margin: 0}} className='stat' />
                        <span style={{position: 'absolute', left: '6px', top: '3px', color:'white', fontWeight: 'bold'}}>{props.stats.home.onTarget}</span>
                        <span style={{position: 'absolute', right: '6px', top: '3px', color:'white', fontWeight: 'bold'}}>{props.stats.away.onTarget}</span>
                    </div>
                }
            </Grid.Row>
            <Grid.Row>
                <p style={{textAlign: 'center', width: '100%', marginBottom:'0.5em'}}>Attack rating</p>
                {props.isLoading ?
                    <Placeholder style={{width: '100%', margin: '0 auto', height: '12px'}}>
                        <Placeholder.Header>
                            <Placeholder length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <div style={{position: 'relative', width: '100%'}}>
                        <Progress percent={props.stats.home.homeTeamAttackShare} style={{width: '100%', margin: 0}} className='stat' />
                        <span style={{position: 'absolute', left: '6px', top: '2px', color:'white', fontWeight: 'bold'}}>{props.stats.home.homeTeamAttackShare}%</span>
                        <span style={{position: 'absolute', right: '6px', top: '2px', color:'white', fontWeight: 'bold'}}>{100-props.stats.home.homeTeamAttackShare}%</span>
                    </div>
                }
            </Grid.Row>
            <Grid.Row>
                <p style={{textAlign: 'center', width: '100%', marginBottom:'0.5em'}}>Defence rating</p>
                {props.isLoading ?
                    <Placeholder style={{width: '100%', margin: '0 auto', height: '12px'}}>
                        <Placeholder.Header>
                            <Placeholder length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <div style={{position: 'relative', width: '100%'}}>
                        <Progress percent={props.stats.home.homeTeamDefenceShare} style={{width: '100%', margin: 0}} className='stat' />
                        <span style={{position: 'absolute', left: '6px', top: '2px', color:'white', fontWeight: 'bold'}}>{props.stats.home.homeTeamDefenceShare}%</span>
                        <span style={{position: 'absolute', right: '6px', top: '2px', color:'white', fontWeight: 'bold'}}>{100-props.stats.home.homeTeamDefenceShare}%</span>
                    </div>
                }
            </Grid.Row>
            <Grid.Row>
                {props.isLoading ?
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder length='short' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Grid.Column width={3} textAlign='left' style={{paddingLeft: 0, fontWeight: 'bold'}}>{props.stats.home.averagePerformanceRating.toFixed(1)}</Grid.Column>
                }
                <Grid.Column textAlign='center'><span style={{fontWeight: '0.8rem'}}>Average performance</span></Grid.Column>
                {props.isLoading ?
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder length='short' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Grid.Column width={3} textAlign='right' style={{paddingRight: 0, fontWeight: 'bold'}}>{props.stats.away.averagePerformanceRating.toFixed(1)}</Grid.Column>
                }
            </Grid.Row>
            <Grid.Row>
                {props.isLoading ?
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder length='short' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Grid.Column width={3} textAlign='left' style={{paddingLeft: 0, fontWeight: 'bold'}}>{homeTeamPlayerAbilityMean} ({homeTeamPlayerEffectiveMean})</Grid.Column>
                }
                <Grid.Column textAlign='center'><span style={{fontWeight: '0.8rem'}}>Average rating</span></Grid.Column>
                {props.isLoading ?
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder length='short' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <Grid.Column width={3} textAlign='right' style={{paddingRight: 0, fontWeight: 'bold'}}>{awayTeamPlayerAbilityMean} ({awayTeamPlayerEffectiveMean})</Grid.Column>
                }
            </Grid.Row>
        </Grid>
    );
}
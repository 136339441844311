import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { useParams } from "react-router";
import PlayerContractOfferInterface from "./interface";

const includeStr = 'includes=person,playerContract,playerReg';

export default function PlayerContractOffer(props) {
    const {playerId} = useParams();

    const contractDemandsQuery = useQuery(
        ['getPlayerContractDemands', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands`)
    );

    const playerQuery = useQuery(
        ['getPlayer', playerId, includeStr],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}?${includeStr}`)
    );

    return(
        <PlayerContractOfferInterface
            isLoading={contractDemandsQuery.isLoading || playerQuery.isLoading}
            contractDemands={contractDemandsQuery.data?.data?.response.contractDemands}
            currentSeason={contractDemandsQuery.data?.data?.response.currentSeason}
            isInterested={contractDemandsQuery.data?.data?.response.reputationWithinRange}
            player={playerQuery.data?.data?.response}
        />
    );
}
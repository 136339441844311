import { Redirect, generatePath } from 'react-router';
import {useTutorial} from '../hooks/useTutorial';

import tutorialData from '../tutorials/staff';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Loader } from 'semantic-ui-react';

import {initialFilterState} from '../../staff/filter/lib';
import useUser from '../../common/useUser';
import useGetStaff from '../../transfer/staffSearch/useGetStaff';

export default function StaffTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    const userQuery = useUser(!cachedTutorialData);
    const staffQuery = useGetStaff({...initialFilterState, filterContractStatus: true, filterUninterested: true}, !cachedTutorialData);

    useEffect(() => {
        if(!cachedTutorialData && userQuery.isSuccess && staffQuery.isSuccess) {
            var compiledTutorialData = cloneDeep(tutorialData);

            if(staffQuery.data.data.response.length === 0) {
                compiledTutorialData.steps.splice(2, 9);
            }

            console.log(compiledTutorialData.steps)

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                    case 2:
                    case 11:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: userQuery.club.id})
                        }
                        break;
                    case 3:
                        step.path = {
                            pathname: generatePath(step.path)
                        }

                        step.updatePersistedState = function(queryClient) {
                            const filterState = initialFilterState;

                            localStorage.setItem('staffSearchFilter', JSON.stringify(filterState));
                            queryClient.invalidateQueries('staffSearchFilter');
                        }
                        break;
                    case 4:
                    case 5:
                    case 6:
                        step.path = {
                            pathname: generatePath(step.path, {filterDepartment: 'coaching'}),
                            state: {
                                partial: true,
                            }
                        }

                        step.updatePersistedState = function(queryClient) {
                            const filterState = {
                                ...initialFilterState,
                                filterOverlayOpen: true
                            }

                            localStorage.setItem('staffSearchFilter', JSON.stringify(filterState));
                            queryClient.invalidateQueries('staffSearchFilter');
                        }
                        break;
                    case 7:
                    case 8:
                        step.path = {
                            pathname: generatePath(step.path, {filterDepartment: 'coaching'}),
                            state: {
                                partial: true,
                            }
                        }

                        step.updatePersistedState = function(queryClient) {
                            const filterState = {
                                ...initialFilterState,
                                filterOverlayOpen: false,
                                filterContractStatus: true,
                                filterUninterested: true
                            }

                            localStorage.setItem('staffSearchFilter', JSON.stringify(filterState));
                            queryClient.invalidateQueries('staffSearchFilter');
                        }
                        break;
                    case 9:
                    case 10:
                        step.path = {
                            pathname: generatePath(step.path, {staffId: staffQuery.data.data.response[0].id}),
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData, userQuery.isSuccess, staffQuery.isSuccess]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
import { useEffect } from "react";
import { useQuery } from '@tanstack/react-query';
import axios from "axios";
import NewsInterface from "./interface";
import { useHistory, useLocation } from "react-router";

export default function Home(props) {
    const location = useLocation();
    const history = useHistory();

    const getSeasonsQuery = useQuery(
        ['getAllSeasons'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/seasons?excludeFutureSeasons=true`),
        {
            onSuccess: (data) => {
                if(location.state?.seasonId === undefined) {
                    history.replace({
                        pathname: location.pathname,
                        search: location.search,
                        state: {
                            ...location.state,
                            seasonId: data.data.response[0].id
                        }
                    })
                }
            }
        }
    );

    const getNewsQuery = useQuery(
        ['getAllNews', location.state?.seasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/news?seasonId=${location.state.seasonId}`),
        {
            enabled: location.state?.seasonId !== undefined
        }
    );

    useEffect(() => {
        props.setToolbar({title: "News", navigation: null});
    }, []);

    return(
        <NewsInterface
            isLoading={getNewsQuery.isLoading || getSeasonsQuery.isLoading}
            news={getNewsQuery.data?.data?.response}
            seasons={getSeasonsQuery.data?.data?.response}
        />
    )
}
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router"
import { Loader } from "semantic-ui-react";
import LeagueSeason from ".";
import { useQuery } from '@tanstack/react-query';

export default function TeamLeagueSeasonResolver(props) {
    const location = useLocation();
    const history = useHistory();
    const {leagueId} = useParams();

    const getCurrentLeagueSeasonQuery = useQuery(
        ['getCurrentLeagueSeason', leagueId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagues/${leagueId}/leagueSeasons?currentSeason=true`),
        {
            onSuccess: (data) => {
                if(location.state?.leagueSeasonId === undefined) {
                    history.replace(
                        {
                            pathname: location.pathname,
                            state: {...location.state, leagueSeasonId: data.data.response.LS[0].id},
                            search: location.search
                        }
                    )
                }
            },
            enabled: location.state?.leagueSeasonId === undefined
        }
    );

    if(getCurrentLeagueSeasonQuery.state === 'loading') return <Loader active />;

    return(
        <LeagueSeason
            {...props}
            leagueId={leagueId}
        />
    )
}
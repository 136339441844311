import React, { useEffect, useMemo, useRef } from 'react';
import ViewGL from './ViewGL';
import NarrowLayout from '../../../common/components/NarrowLayout';
import useDimensions from 'react-cool-dimensions';
import { Button } from 'semantic-ui-react';

export default function ClubStadiumInterface(props) {
    const canvasRef = useRef(null);
    const {ref: containerRef, height, width} = useDimensions();

    // useEffect(() => {
    //     if(!!viewGL) {
    //         viewGL.onWindowResize(width, height);
    //     }
    // }, [width, height]);

    const viewGL = useMemo(() => {
        if(!!canvasRef.current) return new ViewGL(canvasRef.current, width, height);
    }, [canvasRef.current, width, height]);

    return (
        <>
        <NarrowLayout>
            <div className="canvasContainer" ref={containerRef}>
                <canvas ref={canvasRef} />
            </div>
        </NarrowLayout>
        <Button onClick={() => viewGL.handleClick()}>Change stands</Button>
        <Button onClick={() => viewGL.size(1)}>size 1</Button>
        <Button onClick={() => viewGL.size(2)}>size 2</Button>
        <Button onClick={() => viewGL.size(3)}>size 3</Button>
        </>
    );
}
import React from "react";
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_TransfersShortlist.text",
		defaultMessage: "Staff",
		description: "Link for favourite staff page"
	}
});

export default function FavStaffToolbarItem(props) {
	return(
		<ToolbarItem
			path={generatePath(URL.favStaff)}
			icon={faUserTie}
			messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
		/>
	)
}
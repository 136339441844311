import {FormattedMessage} from 'react-intl';
import {Label} from 'semantic-ui-react';

import {messages} from 'shared-messages';
import {formatValues} from '../../inbox/lib';

export default function MessageSubjectInterface(props) {
    if(!props.message.type) {
        throw new Error('No message type supplied');
    }
    
    let values = {};
    if(props.message.values !== null) {
        for(const value of props.message.values) {
            // console.log(value);
            values[value.name] = formatValues(value.values, value.entityType);
        }
    }


    // console.log(props.message.type);
    // console.log(values);

    return(
        <>
        {/*<Label as='span' color='teal' ribbon='right'>Unread</Label>*/}
        <FormattedMessage {...messages[props.message.type].subject} values={values} />
        </>
    )
}
import { faCancel, faCircle, faCross, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import dayjs from 'dayjs';
import {useState} from 'react';
import { useQuery} from '@tanstack/react-query';
import { useParams } from 'react-router';
import {Form, Modal, Dropdown, Button, Progress, Icon, Confirm} from 'semantic-ui-react';

export default function RetrainPosition(props) {
    const {teamType} = useParams();
    const [open, setOpen] = useState(false);
    const [cancelNewPositionOpen, setCancelNewPositionOpen] = useState(false);

    const {isLoading, data} = useQuery(
        ['getPositionOptions', props.playerRegistration.playerContract.player.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${props.playerRegistration.playerContract.player.id}/positionOptions`),
        {
            enabled: !!props.playerRegistration
        }
    );

    return(
        <>
            {props.playerRegistration.playerContract.newPosition === null ?
                <Form.Button primary label='New position' loading={isLoading} disabled={data?.data?.response?.length === 0 || props.isOnLoan === true} onClick={() => setOpen(true)}>Retrain position</Form.Button>
                :
                <Form.Field style={styles.field}>
                    <label style={styles.label}>Retraining to {props.playerRegistration.playerContract.newPosition}</label>
                    <div style={styles.container}>
                        <Progress percent={Math.min(Math.round((((teamType === 'first' ? 7 : 4)-dayjs(props.playerRegistration.playerContract.newPositionEffective).diff(dayjs(), 'day'))/7)*100), 95)} progress color='blue' style={styles.progress} />
                        <span className='fa-layers fa-lg' style={styles.cancel}>
                            <FontAwesomeIcon icon={faCircle} color='#DB3B30' />
                            <FontAwesomeIcon
                                icon={faXmark}
                                color='white'
                                onClick={() => setCancelNewPositionOpen(true)}
                                transform='shrink-5'
                                className='iconHover'
                            />
                        </span>
                    </div>
                </Form.Field>
            }
            {open && !!data?.data?.response && <RetrainPositionModal setOpen={setOpen} newPositionOptions={data?.data?.response} handleSetNewPosition={handleSetNewPosition} />}
            <Confirm
                open={cancelNewPositionOpen}
                onCancel={() => setCancelNewPositionOpen(false)}
                onConfirm={() => handleSetNewPosition(null, setCancelNewPositionOpen)}
                content='Are you sure you want to cancel retraining this player?'
            />
        </>
    );

    function handleSetNewPosition(newPosition, modalCloser) {
        props.setNewPosition.mutate({playerContractId: props.playerRegistration.playerContract.id, newPosition})
        modalCloser(false);
    }
}

function RetrainPositionModal(props) {
    const [newPosition, setNewPosition] = useState();

    function handleNewPosition(e, {value}) {
        setNewPosition(value);
    }

    return(
        <Modal
            onClose={() => props.setOpen(false)}
            open={true}
        >
            <Modal.Header>Retrain position</Modal.Header>
            <Modal.Content>
                <Modal.Description>
                    <p>You can retrain this player to a different position. Players can only be retrained to positions which suit their attributes.</p>
                    <p>Retraining takes 1 season to complete. During this time the player will focus all their training effort on their new position, so their rating growth will slow.</p>
                    <p>After retraining completes the player's rating may go up or down, depending on how suitable their attributes are for their new position. This could affect their value</p>
                    <Dropdown
                        selection
                        fluid
                        placeholder='Select position'
                        clearable={true}
                        selectOnBlur={false}
                        options={props.newPositionOptions.map((position) => ({
                            key: position,
                            text: position,
                            value: position
                        }))}
                        value={newPosition}
                        onChange={handleNewPosition}
                    />
                </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => props.setOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    positive
                    onClick={() => props.handleSetNewPosition(newPosition, props.setOpen)}
                >
                    Confirm
                </Button>
            </Modal.Actions>
        </Modal>
    )
}

const styles = {
    field: {
        display: 'flex',
        flexDirection: 'column'
    },
    label: {
        flex: '0 0 auto'  
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'center',
        flex: '1 1 auto'
    },
    progress: {
        flex: '1 1 auto',
        margin: 0
    },
    cancel: {
        flex: '0 1 auto',
        margin: '0 0 0 4px',
        cursor: 'pointer'
    }
}
import {defineMessages} from 'react-intl';

import {URL} from '../../../common/lib/paths';
import ToolbarItem from '../../../menu/toolbar/ToolbarItem';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
    text: {
        id: 'world:toolbar:nations',
        defaultMessage: 'Cups',
        description: 'Link in the toolbar in the world section for the view all cups page'
    }
})

export default function WorldCupsToolbarItem(props) {
    return(
        <ToolbarItem
            path={URL.worldCups}
            icon={faTrophy}
            messages={messages}
        />
    )
}
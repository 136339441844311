import {Form} from 'semantic-ui-react';
import {useHistory, useLocation} from 'react-router-dom';
import {useIntl} from "react-intl";
import { useMemo } from 'react';

export default function SeasonSelector(props) {
    const intl = useIntl();
    const location = useLocation();
    const history = useHistory();

    const seasonOptions = useMemo(() => {
        if(!props.seasons) {
            return []
        } else {
            return props.seasons.map((season) => ({
                key: season.id,
                text: intl.formatDate(season.startDate, {day: 'numeric', month: 'short', year: '2-digit'}),
                value: season.id
            }))
        }
    }, [props.seasons]);

    return(
        <Form.Select
            options={seasonOptions}
            value={location.state?.seasonId}
            loading={props.isLoading}
            disabled={props.isLoading}
            onChange={
                (e, {value}) => history.replace(
                    {
                        pathname: location.pathname,
                        state: {
                            ...location.state,
                            seasonId: value
                        }
                    }
                )
            }
            label='Season'
            fluid
            width={props.width}
        />
    );
}
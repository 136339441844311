import axios from "axios";
import { useEffect } from "react";
import {useMutation, useQueryClient} from '@tanstack/react-query';

import InboxInterface from './interface';
import useGetMessages from "./useGetMessages";

export default function InboxData(props) {
    useEffect(() => {
        props.setToolbar({title: 'Inbox', navigation: null});
    }, []);

    const {data, isLoading} = useGetMessages();

    const queryClient = useQueryClient();

    const deleteAllMessages = useMutation(() => axios.delete(`${process.env.REACT_APP_APPHOST}/messages`), {
        onSuccess: () => {
            queryClient.invalidateQueries('getMessages');
            queryClient.invalidateQueries('countMessages');
        }
    });

    const deleteSelectedMessages = useMutation(
        (messageIds) => axios.delete(`${process.env.REACT_APP_APPHOST}/messages?messageIds=${messageIds.join(',')}`),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getMessages');
                queryClient.invalidateQueries('countMessages');
            }
        }
    );

    function handleDeleteSelectedMessages(messageIds) {
        console.log(messageIds);
        deleteSelectedMessages.mutate(messageIds);
    }

    return(
        <InboxInterface
            isLoading={isLoading}
            messages={data ? data.data.response : undefined}
            deleteInProgress={deleteAllMessages.isLoading || deleteSelectedMessages.isLoading}
            deleteAllMessages={() => deleteAllMessages.mutate()}
            deleteSelectedMessages={handleDeleteSelectedMessages.bind(this)}
        />
    );
}
import { BitmapText, Graphics, Point } from "pixi.js";
import { ApplicationException, getTeamInitial, orientations, sizes } from "./lib";

class Goalkeeper {
    constructor(
        team,
        name
    ) {
        this.team = team;

        this.player = {
            name,
            position: 'GK'
        }

        this.player.intialLocation = new Point(
            _getInitialXPosition(team.orientation),
            30
        );

        this.reset();

        this.graphics = _createPlayerGraphic(
            this.team.app,
            this.team.sim,
            this.player,
            team.color
        );
    }

    reset() {
        this.player.movement = null;
        this.player.location = this.player.intialLocation.clone();
    }

    _log(m, i) {
        if(i === undefined || i === this.player.index) {
            console.log(`${getTeamInitial(this.team.orientation)}${this.player.index}:`, m);
        }
    }

    log(m, i) {
        if(this.team.logConfig.GK === true) {
            this._log(m, i)
        }
    }

    verbose(m, i) {
        if(
            this.team.logConfig.GK === true &&
            this.team.sim.logConfig.verbose === true
        ) {
            this._log(m, i)
        }
    }

    updatePlayerLocation(deltaMS) {
        if(this.player.movement !== null) {
            this.player.location = this.player.movement.update(deltaMS);
        }

        if(this.player.location.equals(this.team.sim.ball.location)) {
            console.log('SAVED!!!');
            this.player.team.sim.app.ticker.stop();
        }
    }

    handleShot(movement) {
        if(this.player.movement !== null) {
            throw new ApplicationException(`movement already in progress: ${this.player.movement.type}`);
        }

        movement.callback = this.handleMovementComplete.bind(this);

        this.player.movement = movement;

        this.log(`new player movemenet registered:`)
        // console.log(this.player.movement);
    }

    handleMovementComplete() {
        this.log('movement completed');
        
        this.cancelMovement();
    }

    cancelMovement() {
        this.log(`cancelling existing movement in progess: ${this.player.movement?.type}`);
        this.player.movement = null;
    }

    tick(time) {
        //if ball within 1m of keeper then perform save
        
        this.updatePlayerLocation(time.deltaMS);
    }

    buildFrame() {
        this.graphics.puck.position = this.team.sim.transformPos(this.player.location);

        this.graphics.positionLabel.position = this.team.sim.transformPos(this.player.location);
    }
}

export default Goalkeeper;

function _getInitialXPosition(orientation) {
    if(orientation === orientations.LEFT) {
        return 0+(sizes.playerRadius/8);
    } else if(orientation === orientations.RIGHT) {
        return 100-(sizes.playerRadius/8);
    }
}

function _createPlayerGraphic(
    app,
    sim,
    player,
    teamColour
) {
    const graphics = {};

    graphics.puck = new Graphics()
        .circle(0, 0, sizes.playerRadius)
        .fill('#000')
        .stroke('#FFF');

    graphics.positionLabel = new BitmapText({
        text: player.position,
        style: {
            fontFamily: 'Arial',
            fontSize: 12,
            fill: '#FFFFFF'
        },
        anchor: 0.5
    });

    app.stage.addChild(graphics.puck, graphics.positionLabel);

    return graphics;
}
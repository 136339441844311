import {Link as RRLink, generatePath} from "react-router-dom";
import Flag from '../../common/components/Flag';

import {URL} from "../../common/lib/paths";
import ShortenedPlayerName from "../../player/common/ShortenedName";

export default function StaffLink(props) {
    return(
        <RRLink to={{pathname: generatePath(URL.staffProfile, {staffId: props.staff.id}), search: props.search}} className={`noTextwrap ${props.className}`}>
            {!!props.staff.person?.nation?.nationGroupShortcode &&
                <Flag name={props.staff.person.nation.nationGroupShortcode} className={!props.nationFlagClassName ? 'nationFlag nationFlag-tablet' : `nationFlag ${props.nationFlagClassName}`} />
            }
            {props.fullName ?
                props.fullName
                :
                <ShortenedPlayerName player={props.staff} />
            }
            {props.icon || null}
        </RRLink>
    );
}
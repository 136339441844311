import NarrowLayout from '../../../common/components/NarrowLayout';
import { FormattedDate, FormattedMessage, FormattedTime, defineMessages } from 'react-intl';
import ContractDemandsInterface from '../../contract/common/ContractDemands';
import { generatePath, useHistory, useParams } from 'react-router';
import ContractOfferForm from '../../contract/offerForm/interface';
import { URL } from '../../../common/lib/paths';
import axios from 'axios';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import TitledSegment from '../../../common/components/TitledSegment';
import ContractTable from '../../../common/components/ContractTable';
import { Button, Message } from 'semantic-ui-react';
import Currency from '../../../common/components/Currency';

function PlayerFreeAuctionCreateOfferInterface(props) {
    const {playerId} = useParams();
    const history = useHistory();
    const queryClient = useQueryClient();

    const contractDemandsQuery = useQuery(
        ['getPlayerContractDemands', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands`)
    );

    const clubBudgetQuery = useQuery(
        ['getClubBudgets'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/budgets`)
    );

    const createPlayerFreeAuctionOffer = useMutation(
        ({salaryOffer, endSeasonIdOffer}) => axios.post(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionBidFree`, {
            playerId: parseInt(playerId),
            salaryOffer,
            endSeasonIdOffer
        }),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries(['getPlayerFreeAuction']);
                history.replace(generatePath(URL.playerTransferAuctionFree, {playerId: playerId, playerFreeAuctionId: data.data.response.playerTransferAuctionFreeId}));
            }
        }
    );

    function handleSubmitContractOffer(salaryOffer, endSeasonIdOffer) {
        createPlayerFreeAuctionOffer.mutate({
            salaryOffer: parseInt(salaryOffer),
            endSeasonIdOffer: parseInt(endSeasonIdOffer)
        });
    }

    return(
        <NarrowLayout>
            <TitledSegment
                title='Contract demands'
            >
                <ContractDemandsInterface
                    introMessage={<IntroMessage playerFreeAuction={props.playerFreeAuction} />}
                    isLoading={contractDemandsQuery.isLoading}
                    isMyPlayer={false}
                    isYouthTeamPlayer={false}
                    contractDemands={contractDemandsQuery.data?.data?.response.contractDemands}
                    currentSeason={contractDemandsQuery.data?.data?.response.currentSeason}
                    contractOffer={null}
                    playerContract={null}
                />
            </TitledSegment>
            <TitledSegment
                title='Contract offer'
            >
                {!clubBudgetQuery.isLoading &&
                    <p>Remaining wage budget: <Currency value={clubBudgetQuery.data.data.response.remainingWageBudget} /></p>
                }
                <ContractOfferForm
                    isLoading={contractDemandsQuery.isLoading}
                    currentSeason={contractDemandsQuery.data?.data?.response.currentSeason}
                    contractDemands={contractDemandsQuery.data?.data?.response.contractDemands}
                    reputationWithinRange={contractDemandsQuery.data?.data?.response.reputationWithinRange}
                    handleSubmit={handleSubmitContractOffer}
                    isSubmitting={createPlayerFreeAuctionOffer.isLoading}
                />
            </TitledSegment>
            {createPlayerFreeAuctionOffer.error &&
                <Message negative>
                    <FormattedMessage
                        {...errorMessages[createPlayerFreeAuctionOffer.error?.response?.data?.response?.key || 'unknown']}
                        values={{key: createPlayerFreeAuctionOffer.error?.response?.data?.response?.key || '-1'}}    
                    />
                </Message>
            }
        </NarrowLayout>
    );
}

const errorMessages = defineMessages({
    'unknown': {
        id: 'player:loanOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'EXPIRED': {
        id: 'playerTransferAuctionBid:expired',
        defaultMessage: 'You cannot bid for this player because the auction has ended',
        description: 'Error message shown if a bid is made but the auction has already ended'
    },
    'CONTRACT_VALUE_LOW': {
        id: 'contractValueLow',
        defaultMessage: 'The player has rejected your contract offer, try increasing your offer',
        description: 'Error message shown when player rejects contract as it is not high enough value'
    },
    'INSUFFICIENT_WAGE_BUDGET': {
        id: 'player:transferOffer:create:error:wageBudget',
        defaultMessage: 'Your wage budget is not sufficient for this offer. Try lowering your contract offer.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (Wage budget)'
    },
    'TOO_MANY_PLAYERS': {
        id: 'player:transferOffer:create:error:tooManyPlayers',
        defaultMessage: 'You cannot bid for this player because you have too many players',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of players'
    },
    'PLAYER_RETIRING': {
        id: 'player:transferOffer:create:error:playerRetiring',
        defaultMessage: 'You cannot bid for this player because they are retired or retiring',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is retired/retiring'
    },
    'REPUTATION_MISMATCH': {
        id: 'player:transferOffer:create:error:reputationAbs',
        defaultMessage: 'This player is not interested in joining your club',
    },
    'FREQUENCY_ERROR': {
        id: 'player:transferOffer:create:error:pendingBid',
        defaultMessage: 'You have already submitted a bid for this player, please wait for the other club to respond.'
    },
    'MODIFY_RESOURCE_PERMISSION_DENIED': {
        id: 'player:transferOffer:create:error:permission',
        defaultMessage: 'You cannot bid for this player because they are not a free agent'
    },
    'LENGTH_MAX_CONSTRAINT': {
        id: 'player:transferOffer:create:error:maxContractLengthExceeded',
        defaultMessage: 'The contract you have offered is too long. Please try again with a shorter offer.'
    },
    'CONTRACT_VALUE_HIGH': {
        id: 'player:transferOffer:create:error:contractValueHigh',
        defaultMessage: 'The board feel this contract offer is too generous . Please try again with a lower contract offer.'
    }
});

function PlayerFreeAuctionViewOfferInterface(props) {
    const queryClient = useQueryClient();

    const currentSeasonQuery = useQuery(
        ['getSeasonByDate'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/seasons?bounds=0`)
    );

    const deletePlayerFreeAuctionOffer = useMutation(
        (playerFreeAuctionBidId) => axios.delete(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionBidFree/${playerFreeAuctionBidId}`),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('getPlayerFreeAuction');
            }
        }
    );

    function handleWithdrawContractOffer() {
        deletePlayerFreeAuctionOffer.mutate(props.playerFreeAuction.playerTransferAuctionBidFree.id);
    }

    if(currentSeasonQuery.isLoading) return null;

    return(
        <NarrowLayout>
            <TitledSegment
                title='Contract demands'
            >
                <ContractDemandsInterface
                    introMessage={<IntroMessage playerFreeAuction={props.playerFreeAuction} />}
                    isLoading={currentSeasonQuery.isLoading}
                    isMyPlayer={false}
                    isYouthTeamPlayer={false}
                    currentSeason={currentSeasonQuery.data?.data?.response[0]}
                    contractOffer={{
                        salaryDemand: props.playerFreeAuction.playerTransferAuctionBidFree.salaryDemand,
                        endSeasonDemand: props.playerFreeAuction.playerTransferAuctionBidFree.endSeasonDemand
                    }}
                />
            </TitledSegment>
            <TitledSegment
                title='Contract offer'
            >
                <p><FormattedMessage {...messages.contractOfferedText} /></p>
                <ContractTable
                    contractSalary={props.playerFreeAuction.playerTransferAuctionBidFree.salaryOffer}
                    contractExpiry={props.playerFreeAuction.playerTransferAuctionBidFree.endSeasonOffer.endDate}
                    currentSeason={currentSeasonQuery.data?.data?.response[0]}
                />
                <Button
                    fluid
                    negative
                    onClick={handleWithdrawContractOffer}
                    loading={deletePlayerFreeAuctionOffer.isLoading}
                >
                    Withdraw
                </Button>
            </TitledSegment>
        </NarrowLayout>
    )
}

function IntroMessage(props) {
    if(!!props.playerFreeAuction) {
        return(
            <FormattedMessage
                {...messages.auctionInProgress}
                values={{
                    offersCount: props.playerFreeAuction.playerTransferAuctionBidFreeCount,
                    endDateMin: <><FormattedDate value={props.playerFreeAuction.endDateMin} /> <FormattedTime value={props.playerFreeAuction.endDateMin} /></>,
                    endDateMax: <><FormattedDate value={props.playerFreeAuction.endDateMax} /> <FormattedTime value={props.playerFreeAuction.endDateMax} /></>
                }}
            />
        )
    } else {
        return(
            <FormattedMessage {...messages.auctionNotStarted} />
        )
    }
}

export {
    PlayerFreeAuctionCreateOfferInterface,
    PlayerFreeAuctionViewOfferInterface
}

const messages = {
    auctionInProgress: {
        id: 'playerFreeAuction:inProgress',
        defaultMessage: 'I do not currently have a club, so I am available on a free transfer. I am currently considering offers from {offersCount} club(s). I will decide my next club between {endDateMin} - {endDateMax}.',
        description: 'Message when a player is a free agent and an auction is in progress'
    },
    auctionNotStarted: {
        id: 'playerFreeAuction:notStarted',
        defaultMessage: 'I do not currently have a club, so I am available on a free transfer. After you submit a contract offer I will decide whether to accept within 24 hours.',
        description: 'Message when a player is a free agent but an auction has not yet started'
    },
    contractOfferedText: {
        id: 'contractOffer:contractOfferedText',
        defaultMessage: 'This is the contract that you offered.',
        description: 'Conversation-style sentence used where the player states the contract that you have offered them'
    }
}
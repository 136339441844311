import DataTable from "../../../../common/components/DataTable";
import { currentAbility } from "../../../../common/dataTableColumns/currentAbility";
import {date} from '../../../../common/dataTableColumns/date';
import {clubName} from '../../../../common/dataTableColumns/club/clubName';
import {leagueName} from '../../../../common/dataTableColumns/leagueName';
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useState } from "react";
import WorldNationsToolbarItem from "../../toolbar/WorldNationsToolbarItem";
import WorldClubsToolbarItem from "../../toolbar/WorldClubsToolbarItem";
import WorldCupsToolbarItem from "../../toolbar/WorldCupsToolbarItem";
import WorldRecordsToolbarItem from "../../toolbar/WorldRecordsToolbarItem";
import MediumLayout from "../../../../common/components/MediumLayout";
import RecordFilter from "../filter";

export default function ClubRatingRecords(props) {
    const [timePeriod, setTimePeriod] = useState('allTime');
    const [nationGroupShortcode, setNationGroupShortcode] = useState(null);

    useEffect(() => {
        props.setToolbar({
            title: 'Club rating records',
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        });
    }, []);

    const reputationAbsRecordsQuery = useQuery(
        ['getClubReputationAbsRecords', timePeriod, nationGroupShortcode],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/world/records/clubs?record=reputationAbs&timePeriod=${timePeriod}${!!nationGroupShortcode ? `&nationId=${nationGroupShortcode}` : ''}`)
    );

    return(
        <MediumLayout>
            <RecordFilter
                validTimePeriodFilters={['allTime', 'anySeason', 'seasonId']}
                nationFilter={true}
                selectedTimePeriod={timePeriod}
                handleTimePeriodChange={setTimePeriod}
                selectedNationGroupShortcode={nationGroupShortcode}
                handleNationGroupShortcodeChange={setNationGroupShortcode}
            />
            {timePeriod === 'allTime' ?
                <AllTimeReputationAbsRecordsInterface
                    isLoading={reputationAbsRecordsQuery.isLoading}
                    records={reputationAbsRecordsQuery.data?.data?.response}
                />
                :
                <SeasonReputationAbsRecordsInterface
                    isLoading={reputationAbsRecordsQuery.isLoading}
                    records={reputationAbsRecordsQuery.data?.data?.response}
                />
            }
        </MediumLayout>
    );
}

function AllTimeReputationAbsRecordsInterface(props) {
    const columns = [
        clubName((club) => club),
        leagueName((club) => club.league),
        date('seasonStartDate', (club) => club.season.startDate),
        currentAbility((club) => club.maxReputationAbs)
    ]

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}

function SeasonReputationAbsRecordsInterface(props) {
    const columns = [
        clubName((club) => club),
        leagueName((club) => club.league),
        date(
            'season',
            (club) => club.season.startDate,
            false
        ),
        currentAbility((club) => club.maxReputationAbs)
    ]

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}
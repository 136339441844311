import { Button, Grid, Image } from "semantic-ui-react";
import SUIIconShim from "../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from 'swiper/react';
import { useMemo, useState } from "react";
import TitledSegment from "../common/components/TitledSegment";
import useBodyClass from "../useBodyClass";
import { FormattedDate, FormattedMessage } from "react-intl";
import { formatValues } from "../messages/components/inbox/lib";
import MessageActionButtonInterface from "../messages/components/message/MessageActionButton/interface";

import logo from './invertlogo2.png';
import SeasonSelectorModal from "../common/components/SeasonSelectorModal";
import {useTutorial} from '../tutorial/hooks/useTutorial';

export default function NewsInterface(props) {
    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const {checkTutorial, goNextStep} = useTutorial();

    useBodyClass('noMainContainerPadding');
    useBodyClass('mainContainerFullHeight');

    const isTutorial1 = checkTutorial('news', 1);

    function handleNext() {
        swiper.slideNext();

        if(isTutorial1 === true) {
            goNextStep();
        }
    }

    return(
        <div style={{height: '100%'}}>
            <div style={styles.wrapper}>
                <div style={styles.menu}>
                    <Button.Group>
                        <Button
                            icon
                            disabled={activeIndex === 0}
                            onClick={() => swiper.slidePrev()}
                            color='black'
                            loading={props.isLoading}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </SUIIconShim>
                        </Button>
                        <Button
                            disabled
                            className="opacity-override"
                            color='black'
                            loading={props.isLoading}
                        >
                            {activeIndex+1}/{props.news?.length}
                        </Button>
                        <Button
                            icon
                            disabled={(activeIndex+1) === props.news?.length}
                            onClick={handleNext.bind(this)}
                            color='black'
                            loading={props.isLoading}
                            className={isTutorial1 && 'glow'}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faArrowRight} />
                            </SUIIconShim>
                        </Button>
                    </Button.Group>
                    <SeasonSelectorModal
                        seasons={props.seasons}
                    >
                        <Button
                            icon
                            disabled={props.isLoading}
                            loading={props.isLoading}
                            color='black'
                            style={{margin: '0 0 0 1em'}}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faClockRotateLeft} />
                            </SUIIconShim>
                        </Button>
                    </SeasonSelectorModal>
                </div>
                <div style={styles.slider} className='sliderContainer'>
                    <Swiper
                        slidesPerView='auto'
                        centeredSlides={true}
                        centeredSlidesBounds={true}
                        onSwiper={setSwiper}
                        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        style={{height: '100%'}}
                    >
                        {props.news?.map((news) =>
                            <SwiperSlide style={styles.swiperSlide} key={news.id}>
                                <NewsCard
                                    news={news}
                                />
                            </SwiperSlide>
                        )}
                    </Swiper>
                </div>
            </div>
        </div>
    );
}

function NewsCard(props) {
    const values = useMemo(() => {
        let values = {};
        if(props.news.values !== null) {
            for(const value of props.news.values) {
                values[value.name] = formatValues(value.values, value.entityType);
            }
        }

        return values;
    }, [props.news]);

    return(
        <div style={styles.card}>
            <TitledSegment
                title={<FormattedMessage {...messages[props.news.type].title} values={values}  />}
                contentStyle={{overflow: 'hidden'}}
            >
                <div style={styles.segmentWrapper}>
                    <div style={styles.meta}>
                        <div style={styles.metaText}>
                            <p style={styles.date}><FormattedDate value={props.news.date} year="numeric" month="long" day="numeric"/></p>
                            <p style={styles.season}>Season 1</p>
                        </div>
                        <Image
                            style={styles.logo}
                            src={logo}
                        />
                    </div>
                    <div style={styles.content}>
                        <p><FormattedMessage {...messages[props.news.type].body} values={values} /></p>
                        {props.news.components?.map((component, i) => {
                            let componentValues = {}
                            for(const value of component.values) {
                                componentValues[value.name] = formatValues(value.values, value.entityType);
                            }

                            return (
                                <p key={i}><FormattedMessage {...messages[props.news.type].components[component.type]} values={componentValues} /></p>
                            )
                        })}
                        {messages[props.news.type].footer &&
                            <p><FormattedMessage {...messages[props.news.type].footer} values={values} /></p>
                        }
                        {props.news.actionButton &&
                            <Grid className='gridReset'>
                                <Grid.Row columns={1} style={{padding: 0}}>
                                    <Grid.Column textAlign="center">
                                        <MessageActionButtonInterface message={props.news} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        }
                    </div>
                </div>
            </TitledSegment>
        </div>
    )
}

const messages = {
    'game:welcome': {
        title: {
            id: 'news:game:welcome',
            defaultMessage: 'Welcome to manager Soccer',
            description: 'test'
        },
        body: {
            id: 'news:game:welcome',
            defaultMessage: 'As you embark on this epic footballing odyssey, know that Manager Soccer provides you with an opportunity to leave an indelible mark on the history of a game. Build a dynasty, nurture young talents into world-class superstars,delve into the nostalgic glory of bygone eras and secure trophies that will be part of the game\'s fabric forever. As you climb the ranks and conquer domestic and international competitions, your managerial prowess will be celebrated, cementing your status as a legend.\n\nSo gather your wits, don your virtual tracksuit, and step into the grand arena of Manager Soccer — where the timeless pursuit of victory awaits!\n\nGame on',
            description: 'test'
        }
    },
    'fixture:topScoringGameOfDay': {
        title: {
            id: 'news:fixture:topScoringGameOfDay',
            defaultMessage: 'Highest scoring game of the day!',
            description: 'test'
        },
        body: {
            id: 'news:fixture:topScoringGameOfDay',
            defaultMessage: 'The titans of {homeTeamClub} faced off against the giants of {awayTeamClub} in {leagueName} which was always going to be an event for the ages.\n\nIn an extraordinary display of attacking prowess and relentless determination,this thrilling encounter finished {scoreHome}-{scoreAway} and showcased the clash of two offensive powerhouses traded blows in a high-scoring spectacle featuring {totalGoals} goals that will be etched in the memories of fans forever, a testament to the captivating nature of the beautiful game.\n\nLets take a closer look shall we?',
            description: 'test'
        }
    },
    'transfer:summary': {
        title: {
            id: 'news:transfer:summary',
            defaultMessage: 'A Spectacular Transfer Window!',
            description: 'test'
        },
        body: {
            id: 'news:transfer:summary',
            defaultMessage: 'In a whirlwind of negotiations, speculations, and record-breaking deals, the Manager soccer world has been gripped by a flurry of transfer activity as the new season draws near. Clubs made a total of {totalTransferCount} transfers to strengthen their squads ahead of the first game.',
            description: 'test'
        },
        components: {
            highestSpendingClub: {
                id: 'news:transfer:summary:highestSpendingClub',
                defaultMessage: '{highestSpendingClub} were the biggest spenders this season, spending a total of {highestSpendingClubTotalSpend}',
                description: 'test'
            },
            highestFeeTransfer: {
                id: 'news:transfer:summary:highestFeeTransfer',
                defaultMessage: 'The biggest transfer was {highestFeePlayerAge} year old {highestFeePlayerPosition} {highestFeePlayer} who moved to {highestFeePayingClub} from {highestFeeReceivingClub} for a fee of {highestFee}',
                description: 'test'
            },
            mostActiveClub: {
                id: 'news:transfer:summary:mostActiveClub',
                defaultMessage: 'The most active club was {mostActiveClub} who made a total of {mostActiveClubTransferCount} transfers last season',
                description: 'test'
            },
            highestRatedFreeAgentTransfer: {
                id: 'news:transfer:summary:highestRatedFreeAgentTransfer',
                defaultMessage: '{highestRatedFreeAgentTransferToClub} did a great piece of business signing {highestRatedFreeAgentTransferPlayerCA}-rated {highestRatedFreeAgentTransferPlayerPosition} {highestRatedFreeAgentTransferPlayer} on a free transfer',
                description: 'test'
            },
            lowestRatedFreeAgentTransfer: {
                id: 'news:transfer:summary:lowestRatedFreeAgentTransfer',
                defaultMessage: 'Desparate times for {lowestRatedFreeAgentTransferToClub} who signed {lowestRatedFreeAgentTransferPlayerCA}-rated {lowestRatedFreeAgentTransferPlayerPosition} {lowestRatedFreeAgentTransferPlayer} on a free transfer',
                description: 'test'
            },
            oldestPlayerTransfer: {
                id: 'news:transfer:summary:oldestPlayerTransfer',
                defaultMessage: '{oldestPlayerTransferToClub} signed the experienced {oldestPlayerTransferPlayerPosition} {oldestPlayerTransferPlayer}. At {oldestPlayerTransferPlayerAge} years old many doubt the impact he can have at the club.',
                description: 'test'
            },
            mostGoalContributionsPlayerTransfer: {
                id: 'news:transfer:summary:mostGoalContributionsPlayerTransfer',
                defaultMessage: '{mostGoalContributionsPlayerTransferToClub} bolstered their attacking options by signing {mostGoalContributionsPlayerTransferPlayerAge} year old {mostGoalContributionsPlayerTransferPlayerPosition} {mostGoalContributionsPlayerTransferPlayer} who has a sensational {mostGoalContributionsTotalGoalContributions} goals and assists over his career.',
                description: 'test'
            },
            mostRedCardsPlayerTransfer: {
                id: 'news:transfer:summary:mostRedCardsPlayerTransfer',
                defaultMessage: '{mostRedCardsPlayerTransferToClub} will have to watch out with recent signing {mostRedCardsPlayerTransferPlayer}. The {mostRedCardsPlayerTransferPlayerAge} year old {mostRedCardsPlayerTransferPlayerPosition} has racked up a concerning {mostRedCardsPlayerTransferRedCards} red cards over his career',
                description: 'test'
            }
        },
        footer: {
            id: 'news:transfer:summary:footer',
            defaultMessage: 'As the new season draws closer the Manager Soccer world stood in awe of the intricate dance of negotiations, as clubs, and athletes strategized for a promising future. With these remarkable transfers, fans worldwide eagerly anticipate the unfolding of the upcoming season, rivalries will intensify, and the pursuit of sporting glory will captivate us all.',
            description: 'test'
        }
    },
    'transfer:dailySummary': {
        title: {
            id: 'news:transfer:dailySummary',
            defaultMessage: 'Transfer news',
            description: 'test'
        },
        body: {
            id: 'news:transfer:dailySummary:body',
            defaultMessage: 'Here is a round up of today\'s biggest transfer activity.',
            description: 'test'
        },
        components: {
            highestRatedTransferOffer: {
                id: 'news:transfer:dailySummary:highestRatedTransferOffer',
                defaultMessage: '{highestRatedTransferOfferToClub} have made a {highestRatedTransferOfferFee} bid for {highestRatedTransferOfferPlayerCA} rated {highestRatedTransferOfferPlayerPosition} {highestRatedTransferOfferPlayer} who currently plays for {highestRatedTransferOfferFromClub}.',
                description: 'test'
            },
            largestTransferOffer: {
                id: 'news:transfer:dailySummary:largestTransferOffer',
                defaultMessage: '{largestTransferOfferToClub} are looking to splash the cash with a {largestTransferOfferFee} bid for {largestTransferOfferPlayerCA} rated {largestTransferOfferPlayerPosition} {largestTransferOfferPlayer} who currently plays for {largestTransferOfferFromClub}.',
                description: 'test'
            },
            prospectTransferOffer: {
                id: 'news:transfer:dailySummary:prospectTransferOffer',
                defaultMessage: '{prospectTransferOfferToClub} have launched a {prospectTransferOfferFee} bid for young prospect {prospectTransferOfferPlayer}. The {prospectTransferOfferPlayerCA} rated {prospectTransferOfferPlayerPosition} is expected to develop into a top player and {prospectTransferOfferFromClub} have a tough job fighting off interest from a number of clubs.',
                description: 'test'
            }
        }
    },
    'players:retiring': {
        title: {
            id: 'news:players:retiring',
            defaultMessage: 'Players announce retirement',
            description: 'test'
        },
        body: {
            id: 'news:players:retiring:body',
            defaultMessage: 'Today\'s spotlight is on the {totalRetireesCount} players who have announced they will retire at the end of this season. These extraordinary athletes have left a mark on their respective clubs, inspiring generations and captivating fans with their incredible skills and previous lack of injuries.',
            description: 'test'
        },
        components: {
            topCARetiringPlayer: {
                id: 'news:players:retiring:topCARetiringPlayer',
                defaultMessage: '{topCARetiringPlayer}, who plays for {topCARetiringPlayerClub}, is the highest rated player to announce this season will be their last. The {topCARetiringPlayerAge} year old {topCARetiringPlayerPosition} reached a peak rating of {topCARetiringPlayerMaxCA} during their prime.',
                description: 'test'
            },
            topAppearancesRetiringPlayer: {
                id: 'news:players:retiring:topAppearancesRetiringPlayer',
                defaultMessage: '{topAppearancesRetiringPlayer}, who plays for {topAppearancesRetiringPlayerClub}, retires at the age of {topAppearancesRetiringPlayerAge} after making a total of {topAppearancesRetiringPlayerTotalAppearances} appearances over the course of their career as a {topAppearancesRetiringPlayerPosition}.',
                description: 'test'
            },
            topScoringRetiringPlayer: {
                id: 'news:players:retiring:topScoringRetiringPlayer',
                defaultMessage: '{topScoringRetiringPlayer} will retire at the age of {topScoringRetiringPlayerAge}. The {topScoringRetiringPlayerClub} player has scored a total of {topScoringRetiringPlayerTotalGoals} goals over their career as a {topScoringRetiringPlayerPosition}.',
                description: 'test'
            },
            topAssistingRetiringPlayer: {
                id: 'news:players:retiring:topAssistingRetiringPlayer',
                defaultMessage: '{topAssistingRetiringPlayer} will retire at the age of {topAssistingRetiringPlayerAge}. The {topAssistingRetiringPlayerClub} player has a total of {topAssistingRetiringPlayerTotalAssists} assists over their career as a {topAssistingRetiringPlayerPosition}.',
                description: 'test'
            }
        }
    },
    'cup:qualification': {
        title: {
            id: 'news:cup:qualification:title',
            defaultMessage: '{cupName} first round draw',
            description: 'todo'
        },
        body: {
            id: 'news:cup:qualification:body',
            defaultMessage: 'The {cupName} gets underway with the {roundName} on {firstRoundDate} with {teamCount} teams participating over {totalRounds} knockout rounds on the way to this season\'s final.\n\nMany are tipping {likelyWinner} as the favourites to win this season\'s title. The winner of the tournament will receive {prizePool} in prize money.',
            description: 'todo'
        },
        components: {
            currentHoldersQualified: {
                id: 'news:cup:qualification:body:currentHoldersQualified',
                defaultMessage: 'Current holders {clubName} have qualified again and will be hoping to repeat last season\'s triumph.',
                description: 'test'
            },
            currentHoldersNotQualified: {
                id: 'news:cup:qualification:body:currentHoldersNotQualified',
                defaultMessage: 'Current holders {clubName} have missed out on qualifying for this season\'s competition, so we are guaranteed to have a new champion!',
                description: 'test'
            },
            noCurrentHolders: {
                id: 'news:cup:qualification:body:noCurrentHolder',
                defaultMessage: 'Excitement is building for the first ever {cupName} and all teams involved are ready to compete to be the first ever name on the trophy.',
                description: 'test'
            }
        }
    },
    'cup:roundSummary': {
        title: {
            id: 'news:cup:roundSummary:title',
            defaultMessage: '{cupName} {roundName} results',
            description: 'todo'
        },
        body: {
            id: 'news:cup:roundSummary:body',
            defaultMessage: 'The {roundName} of the prestigious {cupName} concluded today, leaving fans worldwide on the edge of their seats. The tournament, featuring top-tier teams from around the globe, is the pinnacle achievement in the Manager.Soccer universe.',
            description: 'todo'
        },
        components: {
            highestRankedFixture: {
                id: 'news:cup:roundSummary:components:highestRankedFixture',
                defaultMessage: 'The hotly-anticipated fixture between {homeClubName} and {awayClubName} finished {homeTeamScore} - {awayTeamScore}, featuring two of the highly rated teams in the competition.',
                description: ''
            },
            mismatchFixtureExpected: {
                id: 'news:cup:roundSummary:components:mismatchFixtureExpected',
                defaultMessage: '{favouritesClubName} surprised nobody by beating {underdogClubName} {scoreHome} - {scoreAway}. Many were expecting {favouriteClubName} to win comfortable, and a professional performance ensured that they progress through to the next round.',
                description: ''
            },
            mismatchFixtureUpset: {
                id: 'news:cup:roundSummary:components:mismatchFixtureUpset',
                defaultMessage: '{underdogClubName} caused an upset by beating {favouritesClubName} {scoreHome} - {scoreAway}. Many were expecting {favouritesClubName} to win comfortably, but {underdogClubName} managed to clinch victory and prove that in Manager.Soccer, anything can happen.',
                description: ''
            },
            highestScoringFixture: {
                id: 'news:cup:roundSummary:components:highestScoringFixture',
                defaultMessage: 'The fixture between {homeClubName} and {awayClubName} stole the spotlight with a goal-packed game finishing {homeTeamScore} - {awayTeamScore}',
                description: ''
            }
        },
        footer: {
            id: 'news:cup:roundSummary:footer',
            defaultMessage: 'As we bid farewell to some teams, the anticipation for the {nextRoundName} is increasing. Due to be played on {nextRoundDate}, surviving teams will be aiming to keep their dreams of winning the competition alive, including favourites {favouriteClubName} and underdogs {underdogClubName}. Click below to view the full fixture list.',
            description: 'todo'
        }
    },
    'cup:winner': {
        title: {
            id: 'news:cup:winner:title',
            defaultMessage: '{cupName} winner!',
            description: 'todo'
        },
        body: {
            id: 'news:cup:winner:body',
            defaultMessage: 'The culmination of the {cupName} had fans worldwide on the edge of their seats as {winningClub} were crowned victorous against {defeatedClub} in a {scoreHome}-{scoreAway} triumph.\n\nThe tension was palpable as both teams displayed exceptional skill and determination, with {winningClub} taking the plaudits as the undisputed champions of the Manager.Soccer universe.\n\nThe final whistle marked the end of a fantastic tournament which featured {numberOfTeams} clubs from across the world battle it out across {numberOfRounds} knockout rounds.',
            description: 'todo'
        },
        components: {
            expectedWinner: {
                id: 'news:cup:winner:components:expectedWinner',
                defaultMessage: 'Many pundits were not surprised to see {winningClub} win the tournament, as many had predicted they would challenge for the title.',
                description: ''
            },
            unexpectedWinner: {
                id: 'news:cup:winner:components:unexpectedWinner',
                defaultMessage: '{winningClub} have been a surprise package this tournament, with not many predicting their strong performance. This underdog story will certainly be remembered!',
                description: ''
            },
            firstWin: {
                id: 'news:cup:winner:components:firstWin',
                defaultMessage: 'This is the first time that {winningClub} have won the {cupName}, fans will be celebrating this historic victory long into the night!',
                description: ''
            },
            previousWins: {
                id: 'news:cup:winner:components:previousWins',
                defaultMessage: 'This is not the first time that {winningClub} have won the {cupName}, having lifted the trophy on {previousWinCount} previous occasions.',
                description: ''
            }
        },
        footer: {
            id: 'news:cup:winner:footer',
            defaultMessage: 'A total of {prizeMoney} has been paid out in prize money to competing teams including {winnerPrizeMoney} for winners {winningClub}.\n\nDetails for next season\'s tournament will be available shortly, with the best performing teams domestically qualifying for the next instalment of this prestigious tournament.',
            description: 'todo'
        }
    }
};

const styles = {
    container: {
        margin: '0 0.5em'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    menu: {
        flex: '0 1 auto',
        margin: '1em 1em 0 1em',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center'
    },
    swiperSlide: {
        
    },
    slider: {
        marginTop: '1em',
        marginBottom: '1em',
        flex: '1 1 auto',
        overflow: 'auto',
    },
    nav: {
        flex: '1 0 auto',
        display: 'flex',
        justifyContent: 'center'
    },
    empty: {
        flex: '1 1 auto',
        padding: '0 1em 1em 1em'
    },
    card: {
        boxSizing: 'border-box',
        margin: '0 0.5em',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    segmentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    meta: {
        flex: '0',
        marginBottom: '1em'
    },
    metaText: {
        float: 'left',
        display: 'inline',
        fontSize: '1em',
        color: 'rgba(0,0,0,.4)'
    },
    date: {
        margin: 0
    },
    season: {

    },
    content: {
        whiteSpace: 'pre-line',
        flex: '1 1',
        overflow: 'auto',
    },
    actionButtonWrapper: {
        // flex: '0',
        margin: '1em auto 0 auto'
    },
    logo: {
        display: 'inline',
        float: 'right',
        height: '40px',
        width: '40px',
        margin: '0 auto'
    }
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function useGetClub(clubId, isOwner, enabled) {
    return useQuery(
        ['getClub', clubId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}?ownershipClaim=${isOwner}`),
        {
            enabled
        }
    );
}
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import ClubPlayerTransferOfferPaidInterface from "./interface";
import usePersistedState from "../../../../common/lib/usePersistedState";

export default function ClubPlayerTransferOfferPaid(props) {
    const {persistedState} = usePersistedState('myTransferOffers', {view: 'paidAuctions', activeOnly: false});

    const getClubPlayerTransferOfferPaidQuery = useQuery(
        [
            'getClubPlayerTransferOfferPaid',
            persistedState.activeOnly
        ],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/playerTransferOfferPaid?activeOnly=${persistedState.activeOnly}`)
    );

    return(
        <ClubPlayerTransferOfferPaidInterface
            isLoading={getClubPlayerTransferOfferPaidQuery.isLoading}
            proposedPlayerTransferOfferPaid={getClubPlayerTransferOfferPaidQuery.data?.data?.response.proposedPlayerTransferOfferPaid}
            receivedPlayerTransferOfferPaid={getClubPlayerTransferOfferPaidQuery.data?.data?.response.receivedPlayerTransferOfferPaid}
        />
    )
}
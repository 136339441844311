import * as THREE from 'three';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/addons/controls/OrbitControls.js';

export default class ViewGL{
    constructor(canvasRef, width, height) {
        this.id = null;
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera( 20, width/height, 1, 1000 );

        this.loader = new GLTFLoader();

        this.renderer = new THREE.WebGLRenderer({
            canvas: canvasRef,
            antialias: true,
            alpha: true
        });
        // this.renderer.setClearColor(0xffffff, 0);

        // this.renderer.setClearColor( 0xC5C5C3 );
        this.renderer.setPixelRatio( window.devicePixelRatio );
        this.renderer.setSize(width, height);

        //this.scene.background = null;

        //Create meshes, materials, etc.
        // const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        // const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
        // const cube = new THREE.Mesh( geometry, material );
        // this.scene.add(cube);
        
        const light = new THREE.AmbientLight('white', 0.4); // soft white light
        this.scene.add( light );

        const mainLight = new THREE.DirectionalLight('white', 0.4);
        mainLight.position.set(0, 1, 0);
        this.scene.add( mainLight );

        this.controls = new OrbitControls( this.camera, canvasRef );
        this.controls.autoRotate = true;
        this.controls.autoRotateSpeed = 4.5;

        this.camera.position.z = 0;
        this.camera.position.y = 50;
        this.camera.position.x = 70;
        this.camera.aspect = width/height;
        this.camera.updateProjectionMatrix();

        this.loader.load(
            `${process.env.PUBLIC_URL}/pitch.glb`,
            this.handleAssetLoad.bind(this, 'pitch'),
            undefined,
            function(error) {
                console.error(error)
            }
        );

        this.loader.load(
            `${process.env.PUBLIC_URL}/1/stad1-n.glb`,
            this.handleAssetLoad.bind(this, 'north-1'),
            undefined,
            function(error) {
                console.error(error)
            }
        );

        this.loader.load(
            `${process.env.PUBLIC_URL}/1/stad1-e.glb`,
            this.handleAssetLoad.bind(this, 'east-1'),
            undefined,
            function(error) {
                console.error(error)
            }
        );

        this.loader.load(
            `${process.env.PUBLIC_URL}/1/stad1-s.glb`,
            this.handleAssetLoad.bind(this, 'south-1'),
            undefined,
            function(error) {
                console.error(error)
            }
        );

        this.loader.load(
            `${process.env.PUBLIC_URL}/1/stad1-w.glb`,
            this.handleAssetLoad.bind(this, 'west-1'),
            undefined,
            function(error) {
                console.error(error)
            }
        );

        this.update();
    }

    handleAssetLoad(name, gltf) {
        gltf.scene.name = name;
        this.scene.add(gltf.scene);
        this.update();
    }

    updateValue(value) {
      // Whatever you need to do with React props
    }

    // onWindowResize(vpW, vpH) {
    //     this.camera.aspect = vpW / vpH;
    //     this.camera.updateProjectionMatrix();
    //     this.renderer.setSize(width, height);
    // }

    handleClick() {
        var selectedObject = this.scene.getObjectByName('north-1');
        var selectedObject2 = this.scene.getObjectByName('west-1');
        var selectedObject3 = this.scene.getObjectByName('east-1');
        var s = this.scene.getObjectByName('south-1');
        var size = this.scene.getObjectByName('size');

        this.scene.remove(s);
        this.scene.remove( selectedObject );
        this.scene.remove( selectedObject2 );
        this.scene.remove( selectedObject3 );
        this.scene.remove(size);

        this.loader.load(
            `${process.env.PUBLIC_URL}/1/stad1-s.glb`,
            this.handleAssetLoad.bind(this, 'south-1'),
            undefined,
            function(error) {
                console.error(error)
            }
        );

        this.loader.load(
            `${process.env.PUBLIC_URL}/2/stad2-n-2.glb`,
            this.handleAssetLoad.bind(this, 'north-1'),
            undefined,
            function(error) {
                console.error(error)
            }
        );

        this.loader.load(
            `${process.env.PUBLIC_URL}/3/stad3-w.glb`,
            this.handleAssetLoad.bind(this, 'west-1'),
            undefined,
            function(error) {
                console.error(error)
            }
        );

        this.loader.load(
            `${process.env.PUBLIC_URL}/4/stad4-e.glb`,
            this.handleAssetLoad.bind(this, 'east-1'),
            undefined,
            function(error) {
                console.error(error)
            }
        );
    }

    size(size) {
        var n = this.scene.getObjectByName('north-1');
        var e = this.scene.getObjectByName('east-1');
        var s = this.scene.getObjectByName('south-1');
        var w = this.scene.getObjectByName('west-1');
        var sizeC = this.scene.getObjectByName('size');

        this.scene.remove(n, e, s, w, sizeC);

        this.loader.load(
            `${process.env.PUBLIC_URL}/sizes/size${size}.glb`,
            this.handleAssetLoad.bind(this, 'size'),
            undefined,
            function(error) {
                console.error(error)
            }
        );
    }

    // ******************* RENDER LOOP ******************* //
    update(t) {
        if(this.id !== null) {
            cancelAnimationFrame(this.id)
        }
        this.renderer.render(this.scene, this.camera);
        this.controls.update();
        this.id = requestAnimationFrame(this.update.bind(this));
    }
}
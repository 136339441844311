//last verified against STAGE FOUR spreadsheet 3/10/2021

export default {
    positionMap: [
        "GK",
        "CB",
        "CB",
        "CB",
        "LWB",
        "RWB",
        "CM",
        "CM",
        "CM",
        "ST",
        "ST"
    ],
    pitchHalfMap: [
        12,
        7,
        13,
        19,
        2,
        26,
        9,
        15,
        21,
        11,
        23
    ]
}
import React from "react";
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from '../../common/lib/paths';
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_StaffProfile.text",
		defaultMessage: "Profile",
		description: "Link for staff profile page"
	}
});

export default function Profile(props) {
	return(
		<ToolbarItem
			path={generatePath(URL.staffProfile, {staffId: props.staffId})} 
			icon={faIdBadge}
			messages={messages}
		/>
	);
}
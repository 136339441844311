import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

import NarrowLayout from '../../common/components/NarrowLayout';
import NationInterface from "./interface";
import FullNationNameWithFlag from '../../nation/common/FullNationNameWithFlag';

import NationOverview from '../toolbar/overview';
import NationLeagues from '../toolbar/leagues';
import NationClubs from '../toolbar/clubs';
import NationPlayers from '../toolbar/players';

export default function Nation(props) {
    const {nationId} = useParams();

    const nationQuery = useQuery(
        ['getNation', nationId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nations/${nationId}`),
        {
            enabled: nationId !== undefined
        }
    );

    useEffect(() => {
        if(!nationQuery.isLoading && nationQuery.data?.data?.response) {
            props.setToolbar({
                title: <><FullNationNameWithFlag nation={nationQuery.data?.data?.response} /> overview</>,
                navigation: [
                    <NationOverview key={1} nationId={nationId} />,
                    <NationLeagues key={2} nationId={nationId} />,
                    <NationClubs key={3} nationId={nationId} />,
                    <NationPlayers key={4} nationId={nationId} />
                ]
            });
        }
    }, [nationId, nationQuery.data?.data?.response]);

    return(
        <NarrowLayout>
            <NationInterface
                nationQueryIsLoading={nationQuery.isLoading}
                nation={nationQuery.data?.data?.response}
            />
        </NarrowLayout>
    )
}
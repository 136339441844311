import Flag from '../../../common/components/Flag';
import Component_RatingRoundall from '../../../player/common/RatingRoundall4';

export default function ClubName(props) {
    return(
        <>
            {!!props.club.nation?.nationGroupShortcode && props.nation !== false && <Flag name={props.club.nation.nationGroupShortcode} />}
            {props.club.name}
            {props.rating === true && <Component_RatingRoundall rating={props.club.reputationAbs} />}
        </>
    );
}
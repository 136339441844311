import { faFutbol, faShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import DataTable from "../../../../common/components/DataTable";
import { playerName } from "../../../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../../../common/dataTableColumns/player/playerPosition";
import { clubName } from "../../../../common/dataTableColumns/club/clubName";
import { leagueName } from "../../../../common/dataTableColumns/leagueName";
import { date } from "../../../../common/dataTableColumns/date";
import { fetchRecords } from "./lib";
import WorldNationsToolbarItem from "../../toolbar/WorldNationsToolbarItem";
import WorldClubsToolbarItem from "../../toolbar/WorldClubsToolbarItem";
import WorldCupsToolbarItem from "../../toolbar/WorldCupsToolbarItem";
import WorldRecordsToolbarItem from "../../toolbar/WorldRecordsToolbarItem";
import MediumLayout from "../../../../common/components/MediumLayout";
import RecordFilter from "../filter";
import { useEffect, useState } from "react";

export default function PlayerGoalsScoredRecords(props) {
    const [timePeriod, setTimePeriod] = useState('allTime');
    const [nationGroupShortcode, setNationGroupShortcode] = useState(null);
    const [competition, setCompetition] = useState('allCompetitions');

    useEffect(() => {
        props.setToolbar({
            title: 'Player goal records',
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        });
    }, []);

    const goalsScoredRecordsQuery = useQuery(
        ['getPlayerGoalsScoredRecords', timePeriod, nationGroupShortcode, competition],
        () => fetchRecords('goalsScored', timePeriod, nationGroupShortcode, competition)
    );

    return(
        <MediumLayout>
            <RecordFilter
                validTimePeriodFilters={['allTime', 'anySeason', 'seasonId']}
                nationFilter={true}
                selectedTimePeriod={timePeriod}
                handleTimePeriodChange={setTimePeriod}
                selectedNationGroupShortcode={nationGroupShortcode}
                handleNationGroupShortcodeChange={setNationGroupShortcode}
                validCompetitionFilters={['allCompetitions', 'league', 'mugOfNations']}
                selectedCompetition={competition}
                handleCompetitionChange={setCompetition}
            />
            {timePeriod === 'allTime' ?
                <AllTimeGoalsScoredRecordsInterface
                    isLoading={goalsScoredRecordsQuery.isLoading}
                    records={goalsScoredRecordsQuery.data?.data?.response}
                />
                :
                <SeasonGoalsScoredRecordsInterface
                    isLoading={goalsScoredRecordsQuery.isLoading}
                    records={goalsScoredRecordsQuery.data?.data?.response}
                    timePeriod={timePeriod}
                />
            }
        </MediumLayout>
    );
}

function AllTimeGoalsScoredRecordsInterface(props) {
    const columns = [
        playerName(
            (player) => player,
            (player) => false,
            (player) => false,
            undefined,
            undefined,
            undefined,
            undefined
        ),
        playerPosition(
            (player) => player.position,
            undefined
        ),
        {
            name: <FontAwesomeIcon icon={faShield} />,
            cell: (player) => `${player.teamCount} clubs`,
            compact: true,
            grow: 1,
            minWidth: '70px',
            maxWidth: '100px',
            center: true,
            hide: 325
        },
        {
            name: <FontAwesomeIcon icon={faFutbol} />,
            cell: (player) => player.timePeriodGoalsScored,
            compact: true,
            minWidth: '38px',
            maxWidth: '60px',
            center: true
        }
    ];

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    )
}

function SeasonGoalsScoredRecordsInterface(props) {
    const columns = [
        playerName(
            (player) => player,
            (player) => false,
            (player) => false,
            undefined,
            undefined,
            undefined,
            undefined
        ),
        playerPosition(
            (player) => player.position,
            undefined
        ),
        clubName(
            (player) => player.club,
            350
        ),
        leagueName(
            (player) => player.league,
            650,
        ),
        date(
            'season',
            (player) => player.season.startDate,
            false,
            500,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            props.timePeriod !== 'allTime' && props.timePeriod !== 'anySeason'
        ),
        {
            name: <FontAwesomeIcon icon={faFutbol} />,
            cell: (player) => player.timePeriodGoalsScored,
            compact: true,
            minWidth: '38px',
            maxWidth: '60px',
            center: true
        }
    ];

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    )
}
import { Form } from "semantic-ui-react";
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import times from 'lodash/times';

export default function SelectLeagueLevel(props) {
    const nationQuery = useQuery(
        ['getNation', props.nationId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nations/${props.nationId}`),
        {
            enabled: props.nationId !== undefined
        }
    );

	return(
        <Form.Select
            options={nationQuery.data?.data?.response ? times(nationQuery.data?.data?.response?.nationLeagueLevels, (level) => (
                {
                    key: level+1,
                    text: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(level),
                    value: level+1
                }
            )) : []}
            value={props.selectedLeagueLevel}
            loading={nationQuery.isLoading}
            disabled={nationQuery.isLoading}
            onChange={
                (e, {value}) => props.setSelectedLeagueLevel(value)
            }
            label='Level'
            fluid
        />
	);
}

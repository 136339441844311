import { useState } from "react";
import { generatePath, useHistory, useLocation, useParams } from "react-router";
import { Button, Confirm, List, Modal } from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPencil, faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { URL } from "../common/lib/paths";
import SUIIconShim from "../common/components/SUIIconShim";
import NarrowLayout from "../common/components/NarrowLayout";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { FormattedDate } from "react-intl";
import colours from "../common/colours";

export default function ManageTactics(props) {
    const location = useLocation();
    const history = useHistory();
    const {teamType} = useParams();
    const queryClient = useQueryClient();
    const [selectedTeamTacticId, setSelectedTeamTacticId] = useState(props.teamTactics[props.activeTeamTacticIndex].id)

    const [confirmDeleteTeamTacticId, setConfirmDeleteTeamTacticId] = useState(null);

    const createNewTeamTactic = useMutation(
        () => axios.post(`${process.env.REACT_APP_APPHOST}/teams/${teamType}/teamTactics`),
        {
            onSuccess: (data) => {
                handleEditTeamTactic(data.data.response.id);
            }
        }
    )

    const deleteTeamTactic = useMutation(
        (teamTacticId) => axios.delete(`${process.env.REACT_APP_APPHOST}/teamTactics/${teamTacticId}`),
        {
            onSuccess: (data) => queryClient.invalidateQueries('getTeamTactics')
        }
    )

    return(
        <Modal
            onClose={handleClose}
            open={location.state?.manageTacticsModal}
        >
            <Modal.Header>Manage tactics</Modal.Header>
            <Modal.Content>
                <NarrowLayout>
                    <List
                        divided
                        relaxed
                        verticalAlign="middle"
                        selection
                    >
                        {props.teamTactics?.map((teamTactic, i) => (
                            <List.Item
                                key={teamTactic.id}
                                onClick={() => setSelectedTeamTacticId(teamTactic.id)}
                                active={selectedTeamTacticId === teamTactic.id}
                            >
                                <List.Content>
                                    <div
                                        style={{display: 'inline', float: 'left'}}
                                    >
                                        <List.Header>
                                            Tactic {teamTactic.name}
                                            {selectedTeamTacticId === teamTactic.id ?
                                                <SUIIconShim>
                                                    <FontAwesomeIcon
                                                        icon={faCheck}
                                                        color={colours.positive}
                                                        className='inlineIconMirror'
                                                    />
                                                </SUIIconShim>
                                                :
                                                null
                                            }
                                        </List.Header>
                                        <List.Description>
                                            <p>Created <FormattedDate value={teamTactic.createdAt} /></p>
                                        </List.Description>
                                    </div>
                                    <div
                                        style={{display: 'inline', float: 'right'}}
                                    >
                                        <Button
                                            icon
                                            size='mini'
                                            onClick={() => handleEditTeamTactic(teamTactic.id)}
                                        >
                                            <SUIIconShim>
                                                <FontAwesomeIcon icon={faPencil} />
                                            </SUIIconShim>
                                        </Button>
                                        <Button
                                            icon
                                            size='mini'
                                            negative
                                            onClick={() => setConfirmDeleteTeamTacticId(teamTactic.id)}
                                            loading={deleteTeamTactic.isLoading}
                                            disabled={deleteTeamTactic.isLoading || i === props.activeTeamTacticIndex}
                                        >
                                            <SUIIconShim>
                                                <FontAwesomeIcon icon={faTrash} />
                                            </SUIIconShim>
                                        </Button>
                                    </div>
                                </List.Content>
                            </List.Item>
                        ))}
                    </List>
                </NarrowLayout>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    floated="left"
                    icon
                    labelPosition="left"
                    primary
                    style={{marginLeft: '0.25em'}}
                    loading={createNewTeamTactic.isLoading}
                    disabled={createNewTeamTactic.isLoading}
                    onClick={() => createNewTeamTactic.mutate()}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faPlus} className='buttonIconFix' />
                    </SUIIconShim>
                    New
                </Button>
                <Button
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    positive
                    onClick={handleSelectTeamTactic.bind(this)}
                    disabled={!selectedTeamTacticId}
                >
                    Select
                </Button>
            </Modal.Actions>

            <Confirm
                open={!!confirmDeleteTeamTacticId}
                onCancel={() => setConfirmDeleteTeamTacticId(null)}
                onConfirm={() => handleDeleteTeamTactic(confirmDeleteTeamTacticId)}
                content={'Are you sure you want to delete this tactic? It can\'t be undone.'}
            />
        </Modal>
    )

    function handleClose() {
        history.replace({pathname: location.pathname, state: {...location.state, manageTacticsModal: false}})
    }

    function handleEditTeamTactic(teamTacticId) {
        history.push(generatePath(URL.teamTacticsBuilder, {teamTacticId}));
    }

    function handleDeleteTeamTactic(teamTacticId) {
        deleteTeamTactic.mutate(teamTacticId);
        setConfirmDeleteTeamTacticId(null);
        setSelectedTeamTacticId(null);
    }

    function handleSelectTeamTactic() {
        props.handleSetActiveTeamTactic(selectedTeamTacticId);
        handleClose();
    }
}
import axios from "axios";
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import ScoutReportInterface from "./interface";

export default function PlayerScouting(props) {
    const queryClient = useQueryClient();

    const {isLoading, data} = useQuery(
        ['getPlayerScoutReport', props.player.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${props.player.id}/scoutReports`), {
            enabled: props.player.id !== undefined
        }
    );

    const createReport = useMutation((playerId) => axios.post(`${process.env.REACT_APP_APPHOST}/scoutReports`, {
        playerId: playerId
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getPlayerScoutReport');
        }
    });

    const deleteReport = useMutation((scoutReportId) => axios.delete(`${process.env.REACT_APP_APPHOST}/scoutReports/${scoutReportId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries('getPlayerScoutReport');
        }
    });

    function handleCreateReport() {
        createReport.mutate(props.player.id);
    }

    function handleCancelReport() {
        deleteReport.mutate(data.data.response.scoutReport.id);
    }

    function handleDeleteReport() {
        deleteReport.mutate(data.data.response.scoutReport.id);
    }

    return(
        <ScoutReportInterface
            isLoading={isLoading}
            scoutReport={data?.data.response.scoutReport}
            dueDate={data?.data.response.dueDate}
            deletionDate={data?.data.response.deletionDate}
            handleCreateReport={handleCreateReport.bind(this)}
            handleCancelReport={handleCancelReport.bind(this)}
            handleDeleteReport={handleDeleteReport.bind(this)}
            manager={props.manager}
            isMyPlayer={props.isMyPlayer}
        />
    )
}
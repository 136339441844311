import { defineMessages } from "@formatjs/intl";

const messages = {
    'game:welcome': defineMessages({
        subject: {
            id: "messageSubject:game:welcome",
            defaultMessage: "Welcome to Manager.Soccer",
            description: "Subject for the system message template which a user receives when they join the game"
        },
        body: {
            id:'messageBody:game:welcome',
            defaultMessage: 'Welcome to Manager.Soccer, {managerName}!\n\nTODO: Put some more content in here to explain the mechanics of the game, where to get help, etc.'
        }
    }),
}

export default messages;
import axios from "axios";
import { useQuery } from '@tanstack/react-query';

import ProfileOverviewInterface from "./ProfileOverviewInterface";

export default function PlayerExpanderProfile(props) {
    const {isLoading, data} = useQuery(
        ['getPlayerProfile', props.playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${props.playerId}?includes=person,playerContract,playerReg`),
        {
            enabled: props.playerId !== undefined
        }
    );

    return(
        <ProfileOverviewInterface
            isLoading={isLoading}
            player={data?.data.response}
            extendedView={true}
        />
    )
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarHalfStroke, faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

export default function(props) {
    return(
        <>
            {props.rating > 0.5 ?
                <FontAwesomeIcon icon={faStar} color='#FBBD08' />
                :
                props.rating === 0.5 ?
                    <FontAwesomeIcon icon={faStarHalfStroke} color='#FBBD08' />
                    :
                    <FontAwesomeIcon icon={faStarRegular} color='#FBBD08' />
            }
            {props.rating > 1.5 ?
                <FontAwesomeIcon icon={faStar} color='#FBBD08' />
                :
                props.rating === 1.5 ?
                    <FontAwesomeIcon icon={faStarHalfStroke} color='#FBBD08' />
                    :
                    <FontAwesomeIcon icon={faStarRegular} color='#FBBD08' />
            }
            {props.rating > 2.5 ?
                <FontAwesomeIcon icon={faStar} color='#FBBD08' />
                :
                props.rating === 2.5 ?
                    <FontAwesomeIcon icon={faStarHalfStroke} color='#FBBD08' />
                    :
                    <FontAwesomeIcon icon={faStarRegular} color='#FBBD08' />
            }
            {props.rating > 3.5 ?
                <FontAwesomeIcon icon={faStar} color='#FBBD08' />
                :
                props.rating === 3.5 ?
                    <FontAwesomeIcon icon={faStarHalfStroke} color='#FBBD08' />
                    :
                    <FontAwesomeIcon icon={faStarRegular} color='#FBBD08' />
            }
            {props.rating > 4.5 ?
                <FontAwesomeIcon icon={faStar} color='#FBBD08' />
                :
                props.rating === 4.5 ?
                    <FontAwesomeIcon icon={faStarHalfStroke} color='#FBBD08' />
                    :
                    <FontAwesomeIcon icon={faStarRegular} color='#FBBD08' />
            }
        </>
    );
}
import { FormattedMessage, defineMessages } from "react-intl";

export default function NationName(props) {
    if(!regions[props.nation.name]) return null;

    return(
        <FormattedMessage
            {...regions[props.nation.name]}
        />
    )
}

const regions = defineMessages({
    'us_northeast': {
        id: 'regions:us:northeast',
        defaultMessage: 'Northeast',
        description: 'Name for the Northeast region of the USA'
    },
    'us_west': {
        id: 'regions:us:west',
        defaultMessage: 'West',
        description: 'Name for the West region of the USA'
    },
    'us_south': {
        id: 'regions:us:south',
        defaultMessage: 'South',
        description: 'Name for the South region of the USA'
    },
    'us_midwest': {
        id: 'regions:us:midwest',
        defaultMessage: 'Midwest',
        description: 'Name for the Midwest region of the USA'
    },
    'fr_north': {
        id: 'regions:fr:north',
        defaultMessage: 'North',
        description: 'Name for the North region of France'
    },
    'fr_south': {
        id: 'regions:fr:south',
        defaultMessage: 'South',
        description: 'Name for the South region of France'
    },
    'de_north': {
        id: 'regions:de:north',
        defaultMessage: 'North',
        description: 'Name for the North region of France'
    },
    'de_south': {
        id: 'regions:de:south',
        defaultMessage: 'South',
        description: 'Name for the South region of France'
    },
});
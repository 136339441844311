import {generatePath, useLocation} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faCalendar} from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "toolbar:cup:fixutres",
		defaultMessage: "Fixtures",
		description: "Link for cup fixtures page"
	}
});

export default function CupFixturesToolbarItem(props) {
    const location = useLocation();

	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.cupFixtures, {cupId: props.cupId}),
                state: location.state
			}}
			genericPath={URL.cupFixtures}
			icon={faCalendar}
			messages={messages}
		/>
	);
}
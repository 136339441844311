import { faBullseye, faClock, faHammer, faHorse, faLock, faMagnifyingGlass, faPeopleGroup, faPeopleLine, faPersonHarassing, faPersonRunning, faPersonShelter, faSackDollar, faStar, faUserLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Table, Placeholder, Header, Segment, Grid, Button, Message, Modal } from "semantic-ui-react";
import StarRating from "../../../common/components/StarRating";
import { FormattedDate, FormattedMessage, FormattedNumber, FormattedTime } from "react-intl";
import dayjs from "dayjs";
import {default as RatingColor} from '../../common/RatingRoundall';
import { generatePath, Link, useHistory, useLocation } from "react-router-dom";
import { URL } from "../../../common/lib/paths";
import Component_Currency from "../../../common/components/Currency";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { useMemo } from "react";
import colours from '../../../common/colours';
import { useTutorial } from "../../../tutorial/hooks/useTutorial";

const traitIconMap = {
    workhorse: faPersonRunning,
    leader: faPersonHarassing,
    loyal: faUserLock,
    versatile: faPeopleLine,
    mercenary: faSackDollar,
    setpieces: faBullseye,
    teamplayer: faPeopleGroup,
    bigmatches: faStar
}

export default function PlayerScoutReport(props) {
    const location = useLocation();
    const history = useHistory();
    const {checkTutorial, goNextStep} = useTutorial();

    const traitModal = useMemo(() => {
        const p = new URLSearchParams(location.search);

        return p.get('traitModal');
    }, [location.search]);

    const isTutorial1 = checkTutorial('transfers', 7);

    function getModalPath(trait) {
        let p;

        if(!!trait) {
            p = new URLSearchParams();
            p.set('traitModal', trait);
            p = p.toString();
        }

        return {
            pathname: location.pathname,
            state: location.state,
            search: p
        };
    }

    function getTableRow(label, getValue) {
        return(
            <Table.Row>
                <Table.Cell collapsing style={{fontWeight: 'bold'}}>{label}</Table.Cell>
                <Table.Cell textAlign="right">
                    {props.isLoading ?
                        <Placeholder><Placeholder.Line length='short' style={{height: 'unset'}} /></Placeholder>
                        :
                        props.scoutReport?.status === 'completed' || props.scoutReport?.status === 'instant' ?
                            getValue()
                            :
                            <FontAwesomeIcon icon={faLock} />
                    }
                </Table.Cell>
            </Table.Row>
        );
    }

    function handleBeginScouting() {
        props.handleCreateReport()

        isTutorial1 && goNextStep();
    }

    function getContext() {
        if(props.isLoading) {
            return(
                <>
                    <Grid.Row columns={1}>
                        <Grid.Column centered textAlign="center">
                            <Placeholder><Placeholder.Line length='long' style={{height: 'unset'}} /></Placeholder>
                        </Grid.Column>
                    </Grid.Row>
                </>
            );
        } else if(props.scoutReport === null) {
            if(!props.isMyPlayer) {
                return(
                    <Grid.Row columns={1}>
                        <Grid.Column centered textAlign="center">
                            <Button
                                primary
                                onClick={handleBeginScouting.bind(this)}
                                disabled={props.dueDate === null}
                                className={isTutorial1 && 'glow'}
                            >Begin scouting (<FormattedNumber value={dayjs(props.dueDate).diff(new Date(), 'hour')} unit='hour' style='unit' unitDisplay='long' />)</Button>
                        </Grid.Column>
                    </Grid.Row>
                );   
            } else {
                if(props.dueDate === null) {
                    return(
                        <Grid.Row columns={1}>
                            <Grid.Column centered textAlign="center">
                                <Header as='h5'>You need to <Link to={generatePath(URL.clubStaff, {clubId: props.manager.club.id})}>hire some coaching staff</Link> to create a coach report</Header>
                            </Grid.Column>
                        </Grid.Row>
                    );
                } else {
                    return(
                        <Grid.Row columns={1}>
                            <Grid.Column centered textAlign="center">
                                <Header as='h5'>Your coaching staff need a little more time to assess this player. Check back on <FormattedDate value={props.dueDate} weekday='short' day='numeric' month='short' /> at <FormattedTime value={props.dueDate} />.</Header>
                            </Grid.Column>
                        </Grid.Row>
                    );
                }
            }
        } else if(props.scoutReport.status === 'pending') {
            return(
                <>
                    <Grid.Row style={{paddingBottom: 0}} columns={1}>
                        <Grid.Column centered textAlign="center">
                            <Button negative onClick={props.handleCancelReport}>Cancel scouting</Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column centered textAlign="center">
                            {props.dueDate === null ?
                                <Message error>
                                    <p>You need to <Link to={generatePath(URL.clubStaff, {clubId: props.manager.club.id})}>hire some scouting staff</Link> to complete this scout report.</p>
                                </Message>
                                :
                                <p>
                                    This scout report is in progress and is due to be completed <span style={{fontWeight: 700}}><FormattedDate value={props.dueDate} weekday='short' day='numeric' month='short' /></span> at <span style={{fontWeight: 700}}><FormattedTime value={props.dueDate} /></span>.
                                </p>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </>
            );
        }
    }

    return(
        <div>
            <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)', marginTop: '1em'}}>
                {props.scoutReport?.status === 'instant' ? 'Coach report' : 'Scout report'}
                <FontAwesomeIcon icon={faMagnifyingGlass} style={{float: 'right'}} />
            </Header>
            <Segment attached className='attachedInvertedSegment'>
                <Grid style={{width: '100%', margin: 0}}>
                    <Grid.Row style={{padding: 0}}>
                        <Grid.Column width={16}>
                        <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable'>
                            <Table.Body>
                                {getTableRow('Potential', () => <span><RatingColor rating={props.scoutReport.PAMin} disableFloat={true} /> - <RatingColor rating={props.scoutReport.PAMax} disableFloat={true} /></span>)}
                                {getTableRow('Stamina', () => <StarRating rating={props.scoutReport.staminaEst/20} />)}
                                {getTableRow('Longevity', () => <StarRating rating={props.scoutReport.longevityEst/20} />)}
                                {getTableRow('Injury risk', () => <span style={{color: qualFontColours[getQualIndex(props.scoutReport.injurySusceptibilityEst)]}}><FormattedMessage {...messages.qual[getQualIndex(props.scoutReport.injurySusceptibilityEst)]} /></span>)}
                                {getTableRow('Aggression', () => <span style={{color: qualFontColours[getQualIndex(props.scoutReport.aggressionEst)]}}><FormattedMessage {...messages.qual[getQualIndex(props.scoutReport.aggressionEst)]} /></span>)}
                                {getTableRow('Retrain as', () => props.scoutReport.retrainPositions.join(', ') || 'N/A')}
                                {!props.isMyPlayer && props.scoutReport?.salaryDemand && getTableRow('Salary demand', () => <Component_Currency value={props.scoutReport.salaryDemand} />)}
                                {!props.isMyPlayer && props.scoutReport?.lengthDemand && getTableRow('Length demand', () => `${props.scoutReport.lengthDemand} seasons`)}
                                {getTableRow('Happiness', () => props.scoutReport.isHappy ? <span style={{color: colours.positive}}>Happy</span> : <span style={{color: colours.negative}}>Unhappy</span>)}
                                {props.scoutReport?.status === 'completed' && getTableRow('Report completed', () => <FormattedDate value={props.scoutReport.updatedAt} weekday='short' day='numeric' month='short' />)}
                                {!!props.deletionDate && getTableRow('Report expires', () => <FormattedDate value={props.deletionDate} weekday='short' day='numeric' month='short' />)}
                            </Table.Body>
                        </Table>
                        </Grid.Column>
                    </Grid.Row>
                    {(props.scoutReport?.status === 'completed' || props.scoutReport?.status === 'instant') && 
                        <>
                            <Grid.Row className='traitGrid' style={{marginTop: '1em'}}>
                                <Grid.Column width={4}>
                                    <Link to={getModalPath('workhorse')} className={`trait ${props.scoutReport?.traits.includes('workhorse') && 'active'}`}>
                                        <div style={{position: 'absolute', top: '1em', left: '1em', right: '1em', bottom: '1em'}}>
                                            <FontAwesomeIcon icon={traitIconMap.workhorse} fixedWidth style={{width: '100%', height: '100%'}} />
                                        </div>
                                    </Link>
                                    <Header as='h6' style={{textAlign: 'center', margin: 0}}>Work horse</Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Link to={getModalPath('leader')} className={`trait ${props.scoutReport?.traits.includes('leader') && 'active'}`}>
                                        <div style={{position: 'absolute', top: '1em', left: '1em', right: '1em', bottom: '1em'}}>
                                            <FontAwesomeIcon icon={traitIconMap.leader} fixedWidth style={{width: '100%', height: '100%'}} />
                                        </div>
                                    </Link>
                                    <Header as='h6' style={{textAlign: 'center', margin: 0}}>Leader</Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Link to={getModalPath('loyal')} className={`trait ${props.scoutReport?.traits.includes('loyal') && 'active'}`}>
                                        <div style={{position: 'absolute', top: '1em', left: '1em', right: '1em', bottom: '1em'}}>
                                            <FontAwesomeIcon icon={traitIconMap.loyal} fixedWidth style={{width: '100%', height: '100%'}} />
                                        </div>
                                    </Link>
                                    <Header as='h6' style={{textAlign: 'center', margin: 0}}>One club player</Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Link to={getModalPath('versatile')} className={`trait ${props.scoutReport?.traits.includes('versatile') && 'active'}`}>
                                        <div style={{position: 'absolute', top: '1em', left: '1em', right: '1em', bottom: '1em'}}>
                                            <FontAwesomeIcon icon={traitIconMap.versatile} fixedWidth style={{width: '100%', height: '100%'}} />
                                        </div>
                                    </Link>
                                    <Header as='h6' style={{textAlign: 'center', margin: 0}}>Jack of all trades</Header>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row className='traitGrid' style={{marginTop: '0.5em', marginBottom: '0.5em'}}>
                                <Grid.Column width={4}>
                                    <Link to={getModalPath('mercenary')} className={`trait ${props.scoutReport?.traits.includes('mercenary') && 'active'}`}>
                                        <div style={{position: 'absolute', top: '1em', left: '1em', right: '1em', bottom: '1em'}}>
                                            <FontAwesomeIcon icon={traitIconMap.mercenary} fixedWidth style={{width: '100%', height: '100%'}} />
                                        </div>
                                    </Link>
                                    <Header as='h6' style={{textAlign: 'center', margin: 0}}>Mercenary</Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Link to={getModalPath('setpieces')} className={`trait ${props.scoutReport?.traits.includes('setpieces') && 'active'}`}>
                                        <div style={{position: 'absolute', top: '1em', left: '1em', right: '1em', bottom: '1em'}}>
                                            <FontAwesomeIcon icon={traitIconMap.setpieces} fixedWidth style={{width: '100%', height: '100%'}} />
                                        </div>
                                    </Link>
                                    <Header as='h6' style={{textAlign: 'center', margin: 0}}>Set-piece specialist</Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Link to={getModalPath('teamplayer')} className={`trait ${props.scoutReport?.traits.includes('teamplayer') && 'active'}`}>
                                        <div style={{position: 'absolute', top: '1em', left: '1em', right: '1em', bottom: '1em'}}>
                                            <FontAwesomeIcon icon={traitIconMap.teamplayer} fixedWidth style={{width: '100%', height: '100%'}} />
                                        </div>
                                    </Link>
                                    <Header as='h6' style={{textAlign: 'center', margin: 0}}>Team player</Header>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Link to={getModalPath('bigmatches')} className={`trait ${props.scoutReport?.traits.includes('bigmatches') && 'active'}`}>
                                        <div style={{position: 'absolute', top: '1em', left: '1em', right: '1em', bottom: '1em'}}>
                                            <FontAwesomeIcon icon={traitIconMap.bigmatches} fixedWidth style={{width: '100%', height: '100%'}} />
                                        </div>
                                    </Link>
                                    <Header as='h6' style={{textAlign: 'center', margin: 0}}>Big match player</Header>
                                </Grid.Column>
                            </Grid.Row>
                        </>
                    }
                    {getContext()}
                </Grid>
            </Segment>
            <Modal
                open={!!traitModal}
                onClose={() => history.push(getModalPath())}
            >
                <Modal.Header><FormattedMessage {...messages.traits[traitModal]} /> trait</Modal.Header>
                <Modal.Content>
                    <p><FormattedMessage {...messages.traitDescriptions[traitModal]} /></p>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => history.push(getModalPath())}>Close</Button>
                </Modal.Actions>
            </Modal>
        </div>
    )
}

function getQualIndex(rating) {
    return [
        undefined,
        0,
        0,
        0,
        0,
        0,
        1,
        1,
        1,
        2,
        2,
        2,
        2,
        3,
        3,
        3,
        4,
        4,
        4,
        4,
        4
    ][rating];
}

const qualFontColours = [
    '#21BA45',
    '#B4CC17',
    '#000000',
    '#F2701D',
    '#DA2828'
];

const messages = {
    qual: [
        {
            id: 'player:scoutReport:lowest',
            defaultMessage: 'Very low',
            description: 'Qualitative measure for scout report - lowest value'
        },
        {
            id: 'player:scoutReport:low',
            defaultMessage: 'Low',
            description: 'Qualitative measure for scout report - lowe value'
        },
        {
            id: 'player:scoutReport:average',
            defaultMessage: 'Average',
            description: 'Qualitative measure for scout report - average value'
        },
        {
            id: 'player:scoutReport:high',
            defaultMessage: 'High',
            description: 'Qualitative measure for scout report - high value'
        },
        {
            id: 'player:scoutReport:highest',
            defaultMessage: 'Very high',
            description: 'Qualitative measure for scout report - highest value'
        },
    ],
    traits: {
        workhorse: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Work horse'
        },
        leader: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Leader'
        },
        loyal: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'One club player'
        },
        versatile: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Jack of all trades'
        },
        mercenary: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Mercanary'
        },
        setpieces: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Set piece specialist'
        },
        bigmatches: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Big match player'
        },
        teamplayer: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Team player'
        }
    },
    traitDescriptions: {
        workhorse: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Players with the work horse trait train harder and are able to perform better at lower stamina levels'
        },
        leader: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Making this player your captain will improve the performance of other players'
        },
        loyal: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'These players will always want to stay at your club and will be more flexible in their contract demands'
        },
        versatile: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'These players are better suited to being played out of position'
        },
        mercenary: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'Mercenaries demand higher wages and are very inflexible with their demands'
        },
        setpieces: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'These players are masters at set pieces and penalties with a high conversion rate'
        },
        bigmatches: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'These players enjoy big matches and will perform better against stronger opposition'
        },
        teamplayer: {
            id: 'player:traits:',
            description: '',
            defaultMessage: 'These players encourage the players around them to play better'
        }
    }
}
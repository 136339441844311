import {useState} from 'react';
import {Segment, Table, Header, Grid, Button, Divider, Placeholder, Modal, Message} from "semantic-ui-react";
import {FormattedDate, defineMessage, FormattedMessage, FormattedPlural, defineMessages} from "react-intl";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import AttributesTable from "../common/PlayerExpander/AttributeTable.js";
import ClubLink from '../../club/components/common/Link';
import Currency from '../../common/components/Currency';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsLeftRight, faBan, faBarsProgress, faChartColumn, faCheck, faCircle, faDownLeftAndUpRightToCenter, faExclamation, faEye, faFileContract, faList, faPersonArrowUpFromLine, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import SUIIconShim from '../../common/components/SUIIconShim.js';
import PlayerScouting from './scouting/data.js';
import PlayerExpanderStats from '../common/PlayerExpander/Stats.js';
import ProfileOverviewInterface from '../common/PlayerExpander/ProfileOverviewInterface.js';
import useCompare from '../compare/useCompare.js';
import CityLink from '../../city/common/CityLink.js';
import PromotePlayer from './PromotePlayer.js';
import colours from '../../common/colours.js';
import TitledSegment from '../../common/components/TitledSegment.js';
import { useTutorial } from '../../tutorial/hooks/useTutorial.js';
import { generatePath, useHistory } from 'react-router';
import { URL } from '../../common/lib/paths.js';

dayjs.extend(relativeTime);

export default function PlayerProfileInterface(props) {
    const [releaseDialogeOpen, setReleaseDialogOpen] = useState(false);
    const {playerIds, togglePlayerId} = useCompare();
    const {checkTutorial, goNextStep} = useTutorial();
    const history = useHistory();

    const isTutorial1 = checkTutorial('players', 9);

    const handleReleasePlayer = () => {
        props.handleReleasePlayer();
    }

    if(props.releasePlayerSuccess && releaseDialogeOpen) {
        setReleaseDialogOpen(false);
    }

    function handleCompareClick() {
        togglePlayerId(props.player.id)
        
        if(isTutorial1 === true) {
            goNextStep()  
        } 
    }

    return(
        <div style={{boxSizing: 'border-box', ...props.style}}>
            <div style={props.compareMode ? {overflow: 'hidden', marginTop: '1em'} : {overflow: 'hidden'}}>
                {props.compareMode === true &&
                    <Button
                        icon
                        color='black'
                        floated='left'
                        onClick={() => history.push(generatePath(URL.playerProfile, {playerId: props.player?.id}))}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faEye} fixedWidth />
                        </SUIIconShim>
                    </Button>
                }
                <Button
                    icon
                    toggle
                    disabled={props.isLoading}
                    loading={props.toggleFavPlayerLoading}
                    active={props.isFavPlayer}
                    onClick={props.handleToggleFavPlayer}
                    floated='right'
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faStar} fixedWidth />
                    </SUIIconShim>
                </Button>
                {!props.compareMode &&
                    <Button
                        icon
                        onClick={handleCompareClick.bind(this)}
                        toggle
                        active={playerIds.includes(props.player?.id)}
                        color='black'
                        floated='right'
                        className={isTutorial1 && 'glow'}
                    >
                        <SUIIconShim><FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} transform={{rotate: 45}} fixedWidth /></SUIIconShim>
                    </Button>
                }
                {!props.compareMode && props.isMyPlayer && props.player?.playerContract.playerReg.team.type === 'youth' &&
                    <PromotePlayer
                        handlePromoteToFirstTeam={props.handlePromoteToFirstTeam}
                    />
                }
            </div>
            <TitledSegment
                title={!props.isLoading && `${props.player.person.firstName} ${props.player.person.lastName}`}
                isLoading={props.isLoading}
                icon={faUser}
                noPadding={true}
            >
                <ProfileOverviewInterface player={props.player} isLoading={props.isLoading} />
            </TitledSegment>
            {!props.isLoading && !!props.manager.club && <PlayerScouting player={props.player} manager={props.manager} isMyPlayer={props.isMyPlayer} />}
            {!props.isLoading && (!props.player.retirementSeason || dayjs.utc().isBefore(props.player.retirementSeason?.endDate)) &&
                <>
                    <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)', marginTop: '1em'}}>
                        Attributes
                        <FontAwesomeIcon icon={faBarsProgress} style={{float: 'right'}} />
                    </Header>
                    <Segment attached className='attachedInvertedSegment'>
                        <AttributesTable player={props.player} />
                    </Segment>
                    <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)', marginTop: '1em'}}>
                        Transfer
                        <FontAwesomeIcon icon={faArrowsLeftRight} style={{float: 'right'}} />
                    </Header>
                    <Segment attached className='attachedInvertedSegment profileSegmentFix2' style={{paddingLeft: '1em', paddingRight: '1em'}}>
                        <Table basic='very' compact='very' unstackable style={{width: '100%', margin: 0}} className='attrTable'>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={{fontWeight: 'bold'}} width={7}>Transfer status</Table.Cell>
                                    <Table.Cell width={9} textAlign='right'>
                                        {props.isLoading ?
                                            <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                            :
                                            props.player.playerContract?.blockOffers ?
                                                <span style={{color: '#DB3B30'}}><FontAwesomeIcon icon={faBan} color='#DB3B30' className='inlineIcon' />Offers blocked</span>
                                                :
                                                props.player.playerContract?.transferListed ?
                                                    <span style={{color: '#21ba45'}}><FontAwesomeIcon icon={faCheck} color='#21BA45' className='inlineIcon' />Listed</span>
                                                    :
                                                    'Not listed'
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={{fontWeight: 'bold'}} width={7}>Loan status</Table.Cell>
                                    <Table.Cell width={9} textAlign='right'>
                                        {props.isLoading ?
                                            <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                            :
                                            props.player.playerContract?.blockOffers ?
                                                <span style={{color: '#DB3B30'}}><FontAwesomeIcon icon={faBan} color='#DB3B30' className='inlineIcon' />Offers blocked</span>
                                                :
                                                props.player.playerContract?.loanListed ?
                                                    <span style={{color: '#21ba45'}}><FontAwesomeIcon icon={faCheck} color='#21BA45' />Listed</span>
                                                    :
                                                    'Not listed'
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell style={{fontWeight: 'bold'}}>Value</Table.Cell>
                                    <Table.Cell  textAlign='right'>
                                        {props.isLoading ?
                                            <Placeholder style={{width: '60px'}}><Placeholder.Line length='long' style={{height: 'unset'}} /></Placeholder>
                                            :
                                            <Currency value={props.player.value} />
                                        }
                                    </Table.Cell>
                                </Table.Row>
                                {props.player.interestedClubs > 0 ?
                                    <Table.Row>
                                            <Table.Cell style={{fontWeight: 'bold'}}>Favourites</Table.Cell>
                                            <Table.Cell style={{textAlign: 'right'}}>
                                                {props.player.interestedClubs /* 940 is this the right prop */} managers
                                            </Table.Cell>
                                    </Table.Row>
                                    :
                                    null
                                }
                                {(!!parseInt(props.player.activeFreeAuction) || !!parseInt(props.player.activeOfferLoan) || !!parseInt(props.player.activeOfferPaid) || !!parseInt(props.player.activePaidAuction)) &&
                                    <Table.Row>
                                        <Table.Cell style={{fontWeight: 'bold'}}>Offers</Table.Cell>
                                        <Table.Cell>
                                            {!!parseInt(props.player.activeOfferLoan) &&
                                                <p><SUIIconShim icon={faCheck} color={colours.positive} className='inlineIcon' />Loan</p>
                                            }
                                            {(!!parseInt(props.player.activeFreeAuction) || !!parseInt(props.player.activePaidAuction) || !!parseInt(props.player.activeOfferPaid)) &&
                                                <p><SUIIconShim icon={faCheck} color={colours.positive} className='inlineIcon' />Transfer</p>
                                            }
                                        </Table.Cell>
                                    </Table.Row>
                                }
                            </Table.Body>
                        </Table>
                        {props.isMyPlayer &&
                            <div style={{margin: '1em 0'}}>
                                {props.toggleTransferListedError &&
                                    <Message negative>
                                        <FormattedMessage {...toggleTransferStatusErrorMessages[props.toggleTransferListedError.response?.key || 'unknown']} />
                                    </Message>
                                }
                                {props.toggleLoanListedError &&
                                    <Message negative>
                                        <FormattedMessage {...toggleTransferStatusErrorMessages[props.toggleLoanListedError.response?.key || 'unknown']} />
                                    </Message>
                                }
                                <Button.Group widths={3} size='small'>
                                    <Button
                                        toggle
                                        disabled={props.toggleTransferListedLoading || props.player.playerContract.blockOffers}
                                        loading={props.toggleTransferListedLoading}
                                        active={props.player.playerContract.transferListed}
                                        onClick={props.handleToggleTransferListed}
                                    >
                                        Transfer
                                    </Button>
                                    <Button
                                        toggle
                                        disabled={props.toggleLoanListedLoading || props.player.playerContract.blockOffers}
                                        loading={props.toggleLoanListedLoading}
                                        active={props.player.playerContract.loanListed}
                                        onClick={props.handleToggleLoanListed}
                                    >
                                        Loan
                                    </Button>
                                    <Button
                                        compact
                                        toggle
                                        disabled={props.toggleBlockOffersIsLoading || props.player.playerContract.transferListed || props.player.playerContract.loanListed}
                                        loading={props.toggleBlockOffersIsLoading}
                                        active={props.player.playerContract.blockOffers}
                                        onClick={props.handleToggleBlockOffers}
                                    >
                                        Block offers
                                    </Button>
                                </Button.Group>
                            </div>
                        }
                    </Segment>
                    {props.player.playerContract &&
                        <>
                            <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)', marginTop: '1em'}}>
                                Contract
                                <FontAwesomeIcon icon={faFileContract} style={{float: 'right'}} />
                            </Header>
                            <Segment attached className='attachedInvertedSegment profileSegmentFix2'>
                                <Table basic='very' compact='very' unstackable style={{width: '100%', margin: 0}} className='attrTable'>
                                    <Table.Body>
                                        <Table.Row>
                                            <Table.Cell style={{fontWeight: 'bold'}} width={7}>Wage</Table.Cell>
                                            <Table.Cell width={9} textAlign='right'>
                                                {props.isLoading ?
                                                    <Placeholder style={{width: '60px'}}><Placeholder.Line length='long' style={{height: 'unset'}} /></Placeholder>
                                                    :
                                                    <Currency value={props.player.playerContract.salary} />
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={{fontWeight: 'bold'}}>Contract expiry</Table.Cell>
                                            <Table.Cell textAlign='right'>
                                                {props.isLoading ?
                                                    <Placeholder style={{width: '60px'}}><Placeholder.Line length='very long' style={{height: 'unset'}} /></Placeholder>
                                                    :
                                                    <FormattedMessage
                                                        {...messages.contractLengthValue}
                                                        values={{
                                                            date: <FormattedDate value={props.player.playerContract.endSeason.endDate} timeZone='UTC' day='numeric' month='short' year='numeric' />,
                                                            length: <FormattedPlural
                                                                value={dayjs.utc(props.player.playerContract.endSeason.endDate).diff(dayjs.utc().endOf('isoWeek'), 'week')}
                                                                zero={
                                                                    <FormattedMessage {...messages.seasonZeroText}  />
                                                                }
                                                                one={
                                                                    <FormattedMessage {...messages.seasonSingularText} values={{length: dayjs.utc(props.player.playerContract.endSeason.endDate).diff(dayjs.utc().endOf('isoWeek'), 'week')}} />
                                                                }
                                                                other={
                                                                    <FormattedMessage {...messages.seasonPluralText} values={{length: dayjs.utc(props.player.playerContract.endSeason.endDate).diff(dayjs.utc().endOf('isoWeek'), 'week')}} />
                                                                }
                                                            />
                                                        }}    
                                                    />
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                        <Table.Row>
                                            <Table.Cell style={{fontWeight: 'bold'}}>Time at club</Table.Cell>
                                            <Table.Cell textAlign='right'>
                                                {props.isLoading ?
                                                    <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                                    :
                                                    dayjs(props.player.playerContract.createdAt).to(new Date(), true)
                                                }
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                {props.isMyPlayer ?
                                    <Grid style={{margin: 0}}>
                                        <Grid.Column textAlign='center'>
                                            <Button compact negative disabled={props.isLoading} loading={props.releasePlayerLoading} onClick={() => setReleaseDialogOpen(true)}>Release player</Button>
                                        </Grid.Column>
                                    </Grid>
                                    :
                                    null
                                }
                            </Segment>
                        </>
                    }
                    <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)', marginTop: '1em'}}>
                        Stats
                        <FontAwesomeIcon icon={faChartColumn} style={{float: 'right'}} />
                    </Header>
                    <Segment attached className='attachedInvertedSegment'>
                        <PlayerExpanderStats
                            playerId={props.player.id}
                        />
                    </Segment>
                    <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)', marginTop: '1em'}}>
                        Other info
                        <FontAwesomeIcon icon={faList} style={{float: 'right'}} />
                    </Header>
                    <Segment attached className='attachedInvertedSegment profileSegmentFix2'>
                        <Table
                            basic='very'
                            compact='very'
                            unstackable
                            style={{width: '100%', margin: 0}}
                            className='attrTable'
                        >
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell style={{fontWeight: 'bold'}} width={7}>
                                        Place of birth
                                    </Table.Cell>
                                    <Table.Cell width={9} textAlign='right'>
                                        <CityLink city={props.player.person.city} />
                                    </Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Segment>
                </>
            }
            {!props.isLoading &&
            <Modal
                onClose={() => setReleaseDialogOpen(false)}
                open={releaseDialogeOpen}
            >
                <Modal.Header>Release player</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <p>Are you sure that you want to release {props.player.person.firstName} {props.player.person.lastName}? You will have to pay <Currency value={(props.player.playerContract?.salary/7) * dayjs(props.player.playerContract?.endSeason.endDate).diff(dayjs.utc(), 'day')} /> compensation to the player.</p>
                        <p>Note, you can only release a maximum of 2 players per season.</p>
                        {props.releasePlayerError &&
                            <Message negative>
                                <SUIIconShim style={{verticalAlign: 'middle'}}>
                                    <span className="fa-layers fa-fw fa-lg">
                                        <FontAwesomeIcon icon={faCircle} color='#db2828' />
                                        <FontAwesomeIcon icon={faExclamation} inverse transform='shrink-5' />
                                    </span>
                                </SUIIconShim>
                                <FormattedMessage
                                    {...releasePlayerErrorMessages[props.releasePlayerError.response?.key || 'unknown']}
                                    values={{playerName: `${props.player.person.firstName} ${props.player.person.lastName}`}}
                                />
                            </Message>
                        }
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        onClick={() => setReleaseDialogOpen(false)}
                    >Cancel</Button>
                    <Button
                        negative
                        onClick={handleReleasePlayer.bind(this)}
                        loading={props.releasePlayerIsLoading}
                        disabled={props.releasePlayerIsLoading}
                    >Release player</Button>
                </Modal.Actions>
            </Modal>
            }
        </div>
    );
}

const toggleTransferStatusErrorMessages = defineMessages({
    unknown: {
        id: 'toggleTransferStatus:error:unknown',
        defaultMessage: 'An unknown error occured when attempting to list this player. Try again later.',
        description: 'Unknown error shown when user tries to transfer/loan list player'
    },
    AUCTION_IN_PROGRESS: {
        id: 'toggleTransferStatus:error:auction',
        defaultMessage: 'You cannot list this player because an auction is in progress',
        description: 'Error shown when user tries to transfer/loan list player who is under auction'
    },
    PLAYER_RETIRING: {
        id: 'toggleTransferStatus:error:retiring',
        defaultMessage: 'You cannot list this player because they are retiring soon',
        description: 'Error shown when user tries to transfer/loan list player who is retiring'
    },
    YOUTH_BLOCK: {
        id: 'toggleTransferStatus:error:youth',
        defaultMessage: 'You cannot list players who are in the youth team',
        description: 'Error shown when user tries to transfer/loan list player who is in the youth team'
    },
    PLAYER_LOAN: {
        id: 'toggleTransferStatus:error:loan',
        defaultMessage: 'You cannot list players who are out on loan',
        description: 'Error shown when user tries to transfer/loan list player who is on loan at another club'
    },
    TRANSFER_BLOCK: {
        id: 'toggleTransferStatus:error:transferBlock',
        defaultMessage: 'You cannot list this player because they have not been at the club long enough',
        description: 'Error shown when user tries to transfer/loan list player who is under transfer restriction'
    },
    NOT_ENOUGH_PLAYERS: {
        id: 'toggleTransferStatus:error:minPlayerLimit',
        defaultMessage: 'The board will not allow you to list this player due to a shortage of players',
        description: 'Error shown when user tries to transfer/loan list player but they do not have enough players'
    },
    PLAYER_RATING_HIGH: {
        id: 'toggleTransferStatus:error:playerRating',
        defaultMessage: 'The board will not allow you to list this player because they are one of the best at the club',
        description: 'Error shown when user tries to transfer/loan list player but the board blocks it'
    }
})

const messages = {
    contractLengthValue: {
        id: "ContractOfferInterface:contractLengthDemandsDescriptor",
        defaultMessage: "{date} ({length})"
    },
    seasonZeroText: {
        id: "Layout_PersonContractOffer.seasonZeroText",
        defaultMessage: "end of season",
        description: "end of season"
    },
    seasonSingularText: {
        id: "Layout_PersonContractOffer.seasonSingularText",
        defaultMessage: "{length} season",
        description: "Singular form of the word 'season'. Lowercase."
    },
    seasonPluralText: {
        id: "Layout_PersonContractOffer.seasonPluralText",
        defaultMessage: "{length} seasons",
        description: "Plural form of the word 'season'. Lowercase."
    },
    playerType_defensive: {
        id: "playerType_defensive",
        defaultMessage: "Defensive",
        description: "Description for a player with high defensive attributes"
    },
    playerType_standard: {
        id: "playerType_standard",
        defaultMessage: "Standard",
        description: "Description for a player with balanced  attributes"
    },
    playerType_attacking: {
        id: "playerType_attacking",
        defaultMessage: "Attacking",
        description: "Description for a player with high attacking attributes"
    }
}

const releasePlayerErrorMessages = {
    'unknown': defineMessage({
        id: 'player:release:generalError',
        defaultMessage: 'An unknown error occured',
        description: 'An unknown error occured when trying to release this player'
    }),
    'PLAYER_AGE_YOUNG': defineMessage({
        id: 'player:release:tooYoung',
        defaultMessage: 'You cannot release this player because they are under the age of 22',
        description: 'Error message shown when a user tries to release a player 21 years old or younger'
    }),
    'INSUFFICIENT_TRANSFER_BUDGET': defineMessage({
        id: 'player:release:insufficientBudget',
        defaultMessage: 'You cannot release this player because you cannot afford his compensation',
        description: 'Error message shown when a user tries to release a player but they cannot afford the compensation due'
    }),
   'PENDING_TRANSFER': defineMessage({
        id: 'player:release:pendingTransfer',
        defaultMessage: 'You cannot release this player because they have a pending transfer',
        description: 'Error message shown when a user tries to release a player but they cannot because they have a pending transfer'
    }),
    'PLAYER_RATING_HIGH': defineMessage({
        id: 'player:release:tooGood',
        defaultMessage: 'The board have rejected your request to release {playerName} because they feel he is a valuable asset to the team',
        description: 'Error message shown when a user tries to release a player who is too good to be released'
    }),
    'NOT_ENOUGH_PLAYERS': defineMessage({
        id: 'player:release:notEnoughPlayers',
        defaultMessage: 'You cannot release this player because it would leave the club with too few players',
        description: 'Error message shown when a user tries to release a player which would leave the club with fewer than 14 players'
    }),
    'FREQUENCY_ERROR': defineMessage({
        id: 'player:release:limitReached',
        defaultMessage: 'This club has reached the limit (2) for the maximum number of players that can be released this season.',
        description: 'Error message shown when a user tries to release more than 2 players a season'
    }),
    'AUCTION_IN_PROGRESS': defineMessage({
        id: 'player:release:auctionInProgress',
        defaultMessage: 'You cannot release this player while there is an auction in progress',
        description: 'Error message shown when a user tries to release a player who is currently under auction'
    })
}
import {Button, Icon, Dropdown} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {filter} from "../../../common/components/i18n.js";
import {CSSTransition} from 'react-transition-group-react-18';

import FilterOverlay from '../overlay/interface';
import SUIIconShim from '../../../common/components/SUIIconShim.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import usePersistedState from '../../../common/lib/usePersistedState.js';
import { filterStateReducer, initialFilterState } from '../lib.js';

export default function FilterMenuButton(props) {    
    const {persistedState, updatePersistedState} = usePersistedState(props.persistedStateKey, initialFilterState);
    
    function handleSavedFilterClick() {
        //apply saved filter
    }

    function handleSaveFilter() {

    }

    return(
        <>
            <Dropdown
                item
                toggle
                className={props.isFilterActive ? 'activeDropdown' : ''}
                icon={<SUIIconShim><FontAwesomeIcon icon={faFilter} /></SUIIconShim>}
            >
                <Dropdown.Menu>
                    {/*this.renderSavedFilterMenuItems()*/}
                    {/* <Dropdown.Divider style={{margin: 0}} /> */}
                    {/*<Dropdown.Item text="Save filter" />*/}
                    <Dropdown.Item
                        text='Open filter'
                        onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'toggleFilterOverlay'}))} active={props.isFilterActive}
                    />
                    <Dropdown.Item text="Reset filter" onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'clearFilters'}))} />
                </Dropdown.Menu>
            </Dropdown>
            <CSSTransition
                in={persistedState.filterOverlayOpen}
                timeout={300}
                classNames='transferSearchFilterOverlay'
                mountOnEnter={true}
                unmountOnExit={true}
            >
                {state => 
                    <FilterOverlay
                        state={state}
                        persistedStateKey={props.persistedStateKey}
                        ownTeam={true}
                    />
                }
            </CSSTransition>
        </>
    );
}
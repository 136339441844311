import { Redirect, generatePath } from 'react-router';
import {useTutorial} from '../hooks/useTutorial';

import tutorialData from '../tutorials/clubs';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Loader } from 'semantic-ui-react';

import useUser from '../../common/useUser';
import useGetClub from '../../club/components/profile/useGetClub';

export default function ClubsTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    const userQuery = useUser(!cachedTutorialData);
    const clubQuery = useGetClub(userQuery.club?.id, false, !cachedTutorialData);

    useEffect(() => {
        if(!cachedTutorialData && userQuery.isSuccess && clubQuery.isSuccess) {
            var compiledTutorialData = cloneDeep(tutorialData);

            console.log(clubQuery.data);

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                    case 5:
                    case 8:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: clubQuery.data.data.response.id})
                        }
                        break;
                    case 2:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: clubQuery.data.data.response.id, teamType: 'first'}),
                            state: {view: 'TLSHistory'},
                        }
                        break;
                    case 3:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: clubQuery.data.data.response.id, teamType: 'first'}),
                            state: {view: 'PTLSHistory'},
                        }
                        break;
                    case 6:
                        step.path = {
                            pathname: generatePath(step.path, {cityId: clubQuery.data.data.response.cityId})
                        }
                        break;
                    case 7:
                        step.path = {
                            pathname: generatePath(step.path, {cityId: clubQuery.data.data.response.cityId}),
                            state: {
                                filterRetired: false
                            }
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData, userQuery.isSuccess, clubQuery.isSuccess]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
import { Button, Form } from "semantic-ui-react";

export default function SelectLeagueType(props) {	
	return(
        <Form.Field>
            <label>League type</label>
            <Button.Group fluid toggle>
                <Button
                    active={props.selectedLeagueType === 'first'}
                    onClick={() => props.setSelectedLeagueType('first')}
                >
                    First
                </Button>
                <Button
                    active={props.selectedLeagueType === 'youth'}
                    onClick={() => props.setSelectedLeagueType('youth')}
                >
                    Youth
                </Button>
            </Button.Group>
        </Form.Field>
	);
}

import { Point } from "pixi.js";
import { calculateDirection, calculateDistance, distanceToTime } from "./physics";
import { ApplicationException, getRandomInt, movementTypes, constrainValue } from "./lib";

class Movement {
    constructor(
        type,
        fromLocation,
        toLocation,
        speed,
        delay = 0,
        data,
        callback,
        callbackTime = 1,
        minCurve = 0
    ) {
        this.type = type;
        this.fromLocation = fromLocation.clone();
        this.toLocation = toLocation.clone();

        if(fromLocation.equals(toLocation)) {
            throw new ApplicationException('invalid movement');
        }

        this.direction = calculateDirection(fromLocation, toLocation);
        this.distance = calculateDistance(fromLocation, toLocation);
        this.duration = distanceToTime(this.distance, speed);
        this.delay = delay;

        if(isNaN(this.distance) || this.distance === 0) {
            throw new ApplicationException('invalid movement');
        }

        this.isComplete = false;
        this.elapsedTime = 0;
        this.callback = callback;
        this.callbackTime = callbackTime;
        this.callbackExecuted = false;
        this.data = data;

        this.curveDistance = getRandomInt(minCurve, constrainValue(Math.round(this.distance / 6)-1, minCurve, 5));
    }

    update(deltaMS) {
        if(this.isComplete === true) {
            console.log(this);
            throw new ApplicationException('stale movement');
        }

        this.elapsedTime += deltaMS;

        const normalisedTime = Math.max(Math.min((this.elapsedTime-this.delay) / this.duration, 1), 0);

        if(
            normalisedTime >= this.callbackTime &&
            this.callbackExecuted === false &&
            typeof this.callback === 'function'
        ) {
            this.callbackExecuted = true;
            this.callback();
        }

        if(normalisedTime === 1) {
            this.isComplete = true;
            return this.toLocation;
        }

        if(this.type === movementTypes.DRIBBLE) {
            var easeValue = _linear(normalisedTime);
        } else if(this.type !== movementTypes.PASS_TO_FEET && this.type !== movementTypes.PASS_IN_BEHIND) {
            var easeValue = _easeInOutSine(normalisedTime);
        } else {
            var easeValue = _easeOutSine(normalisedTime);
        }

        // Calculate control point for the curve
        const mid_x = (this.fromLocation.x + this.toLocation.x) / 2;
        const mid_y = (this.fromLocation.y + this.toLocation.y) / 2;
        const angle = Math.atan2(this.toLocation.y - this.fromLocation.y, this.toLocation.x - this.fromLocation.x) + Math.PI / 2;
        const cx = mid_x + this.curveDistance * Math.cos(angle);
        const cy = mid_y + this.curveDistance * Math.sin(angle);

        // Quadratic Bezier curve calculations
        const x = Math.pow(1 - easeValue, 2) * this.fromLocation.x + 2 * (1 - easeValue) * easeValue * cx + Math.pow(easeValue, 2) * this.toLocation.x;
        const y = Math.pow(1 - easeValue, 2) * this.fromLocation.y + 2 * (1 - easeValue) * easeValue * cy + Math.pow(easeValue, 2) * this.toLocation.y;

        return new Point(x, y);
        
        // const distanceProgression = easeValue * this.distance;

        // return new Point(
        //     this.fromLocation.x + (distanceProgression * this.direction.x),
        //     this.fromLocation.y + (distanceProgression * this.direction.y)
        // );
    }
}

function _linear(x) {
    return x;
}

function _easeInOutSine(x) {
    return -(Math.cos(Math.PI * x) - 1) / 2;
}

function _easeInSine(x) {
    return 1 - Math.cos((x * Math.PI) / 2);
  }

function _easeOutSine(x) {
    return Math.sin((x * Math.PI) / 2);
  }

export default Movement;
import {Accordion} from "semantic-ui-react";

import DataTable from '../../common/components/DataTable';

import sortByLineup from "./sorters/sortByLineup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faFutbol, faShirt, faSquare, faThumbsUp, faTrophy } from "@fortawesome/free-solid-svg-icons";
import PlayerExpander from "../common/PlayerExpander/Index";
import usePersistedState from "../../common/lib/usePersistedState";
import SUIIconShim from "../../common/components/SUIIconShim";
import { useState } from "react";
import NarrowLayout from "../../common/components/NarrowLayout";

import colours from '../../common/colours';
import { playerName } from "../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../common/dataTableColumns/player/playerPosition";
import { statColumn } from "../../common/dataTableColumns/player/PTLSStat";
import AssistIcon from "../common/AssistIcon";
import { useTutorial } from "../../tutorial/hooks/useTutorial";

export default function PlayersStatsView(props) {
    const {tutorialKey, tutorialStep, checkTutorial, getSearchParamsString} = useTutorial();
    const isTutorial = checkTutorial('players', 3);
    
    const [isExpanded, setIsExpanded] = useState(false);

    const {
        persistedState,
        updatePersistedState
    } = usePersistedState(
        props.isOwner ? 'squadStatsViewSort_1' : 'squadStatsViewSort_0',
        props.isOwner ?
            {
                column: 1,
                direction: 'asc'
            }
            :
            {
                column: 3,
                direction: 'desc'
            }
    );

    const columns = [
        playerName(
            (playerReg) => playerReg.playerContract.player,
            (playerReg) => playerReg.playerContract,
            (playerReg) => playerReg,
            (playerReg) => playerReg.teamTacticPosition,
            undefined,
            (rowData, i) => isTutorial ? getSearchParamsString(tutorialKey, tutorialStep+1) : undefined,
            (rowData, i) => isTutorial && i === 0 && 'glow',
            sortByLineup.bind(this, props.ownerClubFormation, (playerReg) => playerReg.playerContract.player)
        ),
        playerPosition(
            (playerReg) => playerReg.playerContract.player.position,
            380
        ),
        statColumn(
            'playerAppearances',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.appearances,
            <FontAwesomeIcon icon={faShirt} />
        ),
        statColumn(
            'playerGoalsScored',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.goalsScored,
            <FontAwesomeIcon icon={faFutbol} />
        ),
        statColumn(
            'playerAssists',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.assists,
            <AssistIcon />
        ),
        statColumn(
            'playerAvgMatchRating',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.averageMatchRating,
            <FontAwesomeIcon icon={faThumbsUp} />,
            650
        ),
        statColumn(
            'playerTotalMOTM',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.totalMOTM,
            <FontAwesomeIcon icon={faTrophy} />,
            700
        ),
        statColumn(
            'playerYellowCards',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.yellowCards,
            <FontAwesomeIcon icon={faSquare} color='#FBBD08' />,
            750
        ),
        statColumn(
            'playerRedCards',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.redCards,
            <FontAwesomeIcon icon={faSquare} color='#DB2828' />,
            800
        ),
        statColumn(
            'playerChancesCreated90',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.chancesCreated90,
            'CC/90',
            850
        ),
        statColumn(
            'playerTotalShots90',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.totalShots90,
            'S/90',
            900
        ),
        statColumn(
            'playerTotalShotsOnTargetP',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.totalShotsOnTargetP,
            'SOT %',
            950
        ),
        statColumn(
            'playerGoalsConceded90',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.goalsConceded90,
            'GC/90',
            1050
        ),
        statColumn(
            'playerTotalCleanSheets',
            (playerReg) => playerReg.playerContract.player.currentPTLS?.totalCleanSheets,
            'CS',
            1100
        )
    ];

    return(
        <>
            <DataTable
                columns={columns}
                data={props.playerRegistrations}
                isLoading={props.isLoading}
                placeholderCount={14}
                expandableRows
                expandableRowsComponent={PlayerExpander}
                defaultSortFieldId={persistedState.column}
                defaultSortAsc={persistedState.direction === 'asc'}
                keyField='id'
                onSort={(column, direction) => updatePersistedState({column: column.id, direction})}
                conditionalRowStyles={[
                    {
                        when: (playerReg) => playerReg.teamId !== props.teamId,
                        style: {backgroundColor: colours.grey}
                    }
                ]}
            />
            <NarrowLayout>
                <Accordion inverted>
                    <Accordion.Title
                        active={isExpanded}
                        onClick={() => setIsExpanded(isExpanded => !isExpanded)}
                        index={0}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} className='inlineIcon' />
                            Key
                        </SUIIconShim>
                    </Accordion.Title>
                    <Accordion.Content
                        active={isExpanded}
                    >
                        <div style={{color: '#FFF'}}>
                            <p>CC = chances created</p>
                            <p>S = shots taken</p>
                            <p>SOT = total shots on target</p>
                            <p>SOT % = percentage of shots which are target</p>
                            <p>SC % = percentage of shots which are goals</p>
                        </div>
                    </Accordion.Content>
                </Accordion>
            </NarrowLayout>
        </>
    )
}
import {useIntl} from 'react-intl';

export default function StaffRoleName(props) {
    const intl = useIntl();

    if(props.plural === true) {
        var message = intl.formatMessage(plural[`${props.staff.isHead === true ? 'head' : ''}${props.staff.department}`]);
    } else {
        var message = intl.formatMessage(singular[`${props.staff.isHead === true ? 'head' : ''}${props.staff.department}`]);
    }

    if(props.lowercase === true) {
        return message.toLowerCase();
    } else return message;
}

const singular = {
    headcoaching: {
        id: 'staff:roleNames:coach',
        defaultMessage: 'Head coach',
        description: 'Name for the staff member responsible for training players'
    },
    coaching: {
        id: 'staff:roleNames:coach',
        defaultMessage: 'Coach',
        description: 'Name for the staff member responsible for training players'
    },
    headyouth: {
        id: 'staff:roleNames:youth',
        defaultMessage: 'Youth manager',
        description: 'Name for the staff member responsible for youth players'
    },
    youth: {
        id: 'staff:roleNames:youth',
        defaultMessage: 'Youth coach',
        description: 'Name for the staff member responsible for youth players'
    },
    headphysio: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Head physio',
        description: 'Name for the staff member responsible for injured players'
    },
    physio: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Physio',
        description: 'Name for the staff member responsible for injured players'
    },
    headscouting: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Head scout',
        description: 'Name for the staff member responsible for injured players'
    },
    scouting: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Scout',
        description: 'Name for the staff member responsible for injured players'
    }
}

const plural = {
    headcoaching: {
        id: 'staff:roleNames:coach',
        defaultMessage: 'Head coaches',
        description: 'Name for the staff member responsible for training players'
    },
    coaching: {
        id: 'staff:roleNames:coach',
        defaultMessage: 'Coaches',
        description: 'Name for the staff member responsible for training players'
    },
    headyouth: {
        id: 'staff:roleNames:youth',
        defaultMessage: 'Youth managers',
        description: 'Name for the staff member responsible for youth players'
    },
    youth: {
        id: 'staff:roleNames:youth',
        defaultMessage: 'Youth coaches',
        description: 'Name for the staff member responsible for youth players'
    },
    headphysio: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Head physios',
        description: 'Name for the staff member responsible for injured players'
    },
    physio: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Physios',
        description: 'Name for the staff member responsible for injured players'
    },
    headscouting: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Head scouts',
        description: 'Name for the staff member responsible for injured players'
    },
    scouting: {
        id: 'staff:roleNames:physio',
        defaultMessage: 'Scouts',
        description: 'Name for the staff member responsible for injured players'
    }
}
import {Checkbox, Segment, Form} from 'semantic-ui-react';
import { useEffect } from 'react';

import Players from '../toolbar/Players';
import History from '../toolbar/History';
import Staff from '../toolbar/Staff';
import Scouting from '../toolbar/Scouting';
import Auctions from "../toolbar/Auction";
import ClubPlayerTransferOfferPaid from './views/playerTransferOfferPaid/data';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import ClubPlayerTransferAuctionPaid from './views/playerTransferAuctionPaid/data';
import ClubPlayerTransferOfferLoan from './views/playerTransferOfferLoan/data';
import ClubPlayerTransferAuctionFree from './views/playerTransferAuctionFree/data';
import ClubStaffTransferAuctionFree from './views/staffTransferAuctionFree/data';
import OffersNew from '../toolbar/Offers';
import NarrowLayout from '../../common/components/NarrowLayout';
import usePersistedState from '../../common/lib/usePersistedState';

export default function PlayerTransferOffersInterface(props) {
    const {persistedState, updatePersistedState} = usePersistedState('myTransferOffers', {view: 'paidAuctions', activeOnly: true});

    console.log(persistedState.view);

    const paidAuctionsCountQuery = useQuery(
        ['countClubTransferAuctionPaid'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/playerTransferAuctionPaid?countOnly=true`)
    );

    const freeAuctionsCountQuery = useQuery(
        ['countClubTransferAuctionFree'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/playerTransferAuctionFree?countOnly=true`)
    );

    const paidOffersCountQuery = useQuery(
        ['countClubTransferOfferPaid'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/playerTransferOfferPaid?countOnly=true`)
    );

    const loanOffersCountQuery = useQuery(
        ['countClubTransferOfferLoan'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/playerTransferOfferLoan?countOnly=true`)
    );

    const staffAuctionsCountQuery = useQuery(
        ['countClubStaffTransferAuctionFree'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/staffTransferAuctionFree?countOnly=true`)
    );

    useEffect(() => {
        props.setToolbar({
            title: 'Transfer offers',
            navigation: [
                <OffersNew />,
                <Players key={2} />,
                <Staff key={3} />,
                <Auctions key={7} />,
                <Scouting key={4} />,
                <History key={6} />
            ]
        })
    }, []);

    return(
        <div>
            <NarrowLayout>
                <Segment inverted style={{margin: '1em 0'}}>
                    <Form inverted>
                        <Form.Group widths={2} unstackable={true} style={{marginBottom: 0}}>
                            <Form.Field>
                                <Form.Select
                                    className='transferTypeDropdown dropdownNoMaxHeight'
                                    options={[
                                        {
                                            key: 'paidAuctions',
                                            text: 'Auctions',
                                            value: 'paidAuctions',
                                            label: {
                                                content: paidAuctionsCountQuery.data?.data?.response.receivedCount+paidAuctionsCountQuery.data?.data?.response.proposedCount,
                                                size: 'mini',
                                                horizontal: true,
                                                color: 'black',
                                                style: {marginRight: 0}
                                            }
                                        },
                                        {
                                            key: 'paidOffers',
                                            text: 'Bids',
                                            value: 'paidOffers',
                                            label: {
                                                content: paidOffersCountQuery.data?.data?.response.receivedCount+paidOffersCountQuery.data?.data?.response.proposedCount,
                                                size: 'mini',
                                                horizontal: true,
                                                color: 'black',
                                                style: {marginRight: 0}
                                            }
                                        },
                                        {
                                            key: 'loanOffers',
                                            text: 'Loans',
                                            value: 'loanOffers',
                                            label: {
                                                content: loanOffersCountQuery.data?.data?.response.receivedCount+loanOffersCountQuery.data?.data?.response.proposedCount,
                                                size: 'mini',
                                                horizontal: true,
                                                color: 'black',
                                                style: {marginRight: 0}
                                            }
                                        },
                                        {
                                            key: 'freeAuctions',
                                            text: 'Free agents',
                                            value: 'freeAuctions',
                                            label: {
                                                content: freeAuctionsCountQuery.data?.data?.response.proposedCount,
                                                size: 'mini',
                                                horizontal: true,
                                                color: 'black',
                                                style: {marginRight: 0}
                                            }
                                        },
                                        {
                                            key: 'staffAuctions',
                                            text: 'Staff',
                                            value: 'staffAuctions',
                                            label: {
                                                content: staffAuctionsCountQuery.data?.data?.response.proposedCount,
                                                size: 'mini',
                                                horizontal: true,
                                                color: 'black',
                                                style: {marginRight: 0}
                                            }
                                        }
                                    ]}
                                    value={persistedState.view}
                                    onChange={
                                        (e, {value}) => updatePersistedState({view: value, activeOnly: persistedState.activeOnly})
                                    }
                                    fluid
                                />
                            </Form.Field>
                            <Form.Field style={{alignSelf: 'center'}}>
                                <Checkbox
                                    checked={persistedState.activeOnly}
                                    onChange={
                                        (e, {checked}) => updatePersistedState({view: persistedState.view, activeOnly: checked})
                                    }
                                    label='Active only' />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                </Segment>
            </NarrowLayout>
            {persistedState.view === 'paidAuctions' && <ClubPlayerTransferAuctionPaid manager={props.manager} />}
            {persistedState.view === 'paidOffers' && <ClubPlayerTransferOfferPaid manager={props.manager} />}
            {persistedState.view === 'loanOffers' && <ClubPlayerTransferOfferLoan manager={props.manager} />}
            {persistedState.view === 'freeAuctions' && <ClubPlayerTransferAuctionFree manager={props.manager} />}
            {persistedState.view === 'staffAuctions' && <ClubStaffTransferAuctionFree manager={props.manager} />}
        </div>
    )
}
import { Point } from "pixi.js";
import 'pixi.js/math-extras';

function calculateDirection(pointA, pointB) {
    if(pointA.equals(pointB)) {
        return new Point(0, 0);
    }

    return pointB.subtract(pointA).normalize();
}

//speed measured in pixels per second
function distanceToTime(distance, speed) {
    return distance / speed;
}

function calculateDistance(pointA, pointB) {
    return Math.sqrt(Math.pow(pointB.x - pointA.x, 2) + Math.pow(pointB.y - pointA.y, 2));
}

function calculateFieldOfView(location, direction) {
    if(direction.x === 0 && direction.y === 0) return null;

    const leftDirection = rotateVector(direction, -20);
    const rightDirection = rotateVector(direction, 20);

    return {
        left: new Point(
            location.x + (leftDirection.x * 20),
            location.y + (leftDirection.y * 20)
        ),
        right: new Point(
            location.x + (rightDirection.x * 20),
            location.y + (rightDirection.y * 20)
        )
    }
}

function rotateVector(vector, angle) {
    // Convert angle from degrees to radians
    let radians = angle * (Math.PI / 180);

    // Calculate the rotated coordinates
    let cosTheta = Math.cos(radians);
    let sinTheta = Math.sin(radians);

    let xNew = vector.x * cosTheta - vector.y * sinTheta;
    let yNew = vector.x * sinTheta + vector.y * cosTheta;

    return new Point(xNew, yNew);
}

function isPointInTriangle(point, triangle) {
    const dX = point.x - triangle[2].x;
    const dY = point.y - triangle[2].y;
    const dX21 = triangle[2].x - triangle[1].x;
    const dY12 = triangle[1].y - triangle[2].y;
    const D = dY12 * (triangle[0].x - triangle[2].x) + dX21 * (triangle[0].y - triangle[2].y);
    const s = dY12 * dX + dX21 * dY;
    const t = (triangle[2].y - triangle[0].y) * dX + (triangle[0].x - triangle[2].x) * dY;
    
    if (D < 0) return s <= 0 && t <= 0 && s + t >= D;
    return s >= 0 && t >= 0 && s + t <= D;
}

function isPointInBoundingBox(point, bbox) {
    return (
        point.x >= bbox[0].x &&
        point.y >= bbox[0].y &&
        point.x <= bbox[1].x &&
        point.y <= bbox[1].y
    )
}

//deprecated
function calculateAngle() {
    // Calculate the angle in radians
    let angleRadians = Math.atan2(locations.awayGoal.y - this.player.location.y, locations.awayGoal.x - this.player.location.x);

    // Convert the angle to degrees (optional)
    let angleDegrees = Math.abs(angleRadians * (180 / Math.PI));
}

export {
    calculateDirection,
    distanceToTime,
    calculateDistance,
    rotateVector,
    calculateFieldOfView,
    isPointInTriangle,
    isPointInBoundingBox
}
const style = {
    maxWidth: '1400px',
    margin: '0 auto'
}

export default function(props) {
    return(
        <div style={{...style, ...props.style}} className={props.className}>
            {props.children}
        </div>
    )
}
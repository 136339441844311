import {generatePath, useHistory} from 'react-router';
import {Button, Icon} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

import {URL} from '../../../../common/lib/paths';
import SUIIconShim from '../../../../common/components/SUIIconShim';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import useCompare from '../../../../player/compare/useCompare';

export default function MessageActionButtonInterface(props) {
    const history = useHistory();
    const {addPlayerIds} = useCompare();


    if(!props.message.actionButton) return null;

    const handleClick = (actionButton) => {
        if(actionButton.routeName) {
            let params = {};
            if(actionButton.routeParameterName) {
                params[actionButton.routeParameterName] = actionButton.routeParameterValue;
            }
            history.push(generatePath(URL[actionButton.routeName], params));
        } else {
            if(actionButton.name === 'addToCompare') {
                addPlayerIds(props.message.values[0].values.map((value) => value.routeParameterValue));
                history.push(generatePath(URL.playerCompare));
            }
        }
    }

    if(Array.isArray(props.message.actionButton)) {
        return(
            <>
                {props.message.actionButton.map((action) => 
                    <Button
                        primary
                        onClick={handleClick.bind(this, action)}
                    >
                        <FormattedMessage {...messages[action.name]} />
                    </Button>
                )}
            </>
        )
    } else {
        return(
            <Button
                primary
                icon
                labelPosition='right'
                onClick={handleClick.bind(this, props.message.actionButton)}
            >
                <FormattedMessage {...messages[props.message.actionButton.name]} />
                <SUIIconShim>
                    <FontAwesomeIcon icon={faArrowRight} className='buttonIconFix' />
                </SUIIconShim>
            </Button>
        );
    }
}

const messages = {
    'clubProfile': {
        id: 'messages:buttons:clubProfile',
        defaultMessage: 'View club profile',
        description: 'Action button associated to a message which lets user visit a club profile'
    },
    'playerProfile': {
        id: 'messages:buttons:playerProfile',
        defaultMessage: 'View player profile',
        description: 'Action button shown on a message which lets user visit a player profile'
    },
    'staffProfile': {
        id: 'messages:buttons:staffProfile',
        defaultMessage: 'View staff profile',
        description: 'Action button shown on a message which lets user visit a staff profile'
    },
    'transferOffer': {
        id: 'messages:buttons:transferOffer',
        defaultMessage: 'View offer',
        description: 'Action button associated to a message which lets user visit view transfer offer screen'
    },
    'contractOffer': {
        id: 'messages:buttons:contractOffer',
        defaultMessage: 'Offer contract',
        description: 'Action button associate to a message which lets user offer a player a contract (transfer or renewal)'
    },
    'newTransferOffer': {
        id: 'messages:buttons:newTransferOffer',
        defaultMessage: 'New bid',
        description: 'Action button associated to a message which lets user submit a new transfer offer for a player'
    },
    'transferOffers': {
        id: 'messages:buttons:transferOffers',
        defaultMessage: 'Transfer center',
        description: 'Action button which takes user to transfers section'
    },
    'leagueTable': {
        id: 'messages:buttons:leagueTable',
        defaultMessage: 'League table',
        description: 'Action button which takes user to league table'
    },
    'scoutReport': {
        id: 'messages:buttons:scoutReport',
        defaultMessage: 'View report',
        description: 'Action button which takes user to a completed scout report'
    },
    'training': {
        id: 'messages:buttons:training',
        defaultMessage: 'Training',
        description: 'Action button which takes user to the training screen'
    },
    'playerTransferOfferPaid': {
        id: 'messages:buttons:playerTransferOfferPaid',
        defaultMessage: 'View offer',
        description: 'Action button which takes use to the view transfer offer screen'
    },
    'playerTransferOfferLoan': {
        id: 'messages:butons:playerTransferOfferLoan',
        defaultMessage: 'View offer',
        description: 'Action button which takes user to the view loan offer screen'
    },
    'viewPlayerTransferAuctionPaid': {
        id: 'messages:buttons:viewPlayerTransferAuctionPaid',
        defaultMessage: 'View auction',
        description: 'Action button which takes user to view auction page'
    },
    'viewPlayerTransferOfferPaidHistory': {
        id: 'messages:buttons:viewPlayerTransferOfferPaidHistory',
        defaultMessage: 'View offer',
        description: 'Action button which takes user to view offer page'
    },
    'viewPlayerTransferOfferLoanHistory': {
        id: 'messages:buttons:viewPlayerTransferOfferLoanHistory',
        defaultMessage: 'View offer',
        description: 'Action button which takes user to the view loan offer page'
    },
    'viewPlayerTransferAuctionFree': {
        id: 'messages:buttons:viewPlayerTransferAuctionFree',
        defaultMessage: 'View offers',
        description: 'Action button which takes user to the view free auction page'
    },
    'viewPlayerTransferAuctionFreeHistory': {
        id: 'messages:buttons:viewPlayerTransferAuctionFreeHistory',
        defaultMessage: 'View bids',
        description: 'Action button which takes user to the view free auction history page'
    },
    'viewStaffTransferAuctionFreeHistory': {
        id: 'messages:buttons:viewStaffTransferAuctionFreeHistory',
        defaultMessage: 'View bids',
        description: 'Action button which takes user to the view free auction history page'
    },
    'viewFixture': {
        id: 'messages:buttons:viewFixture',
        defaultMessage: 'View fixture',
        description: 'Action button which takes user to the view fixture page'
    },
    'transferHistory': {
        id: 'messages:buttons:transferHistory',
        defaultMessage: 'Transfer stats',
        description: 'Action button which takes user to season transfer stats page'
    },
    'scoutReports': {
        id: 'messages:buttons:scoutReports',
        defaultMessage: 'Scout reports',
        description: 'Action button which takes user to scout reports page'
    },
    'addToCompare': {
        id: 'messages:buttons:addToCompare',
        defaultMessage: 'Compare',
        description: 'Action button which adds players in the message to the compare widget'
    },
    'cupFixtures': {
        id: 'messages:buttons:cupFixtures',
        defaultMessage: 'View fixtures',
        description: 'Action button which takes user to cup fixtures page'
    }
}
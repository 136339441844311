import {Card, Button, Loader} from 'semantic-ui-react';
import {FormattedMessage, FormattedDate} from 'react-intl';
import PlayerLink from '../../common/Link';
import Component_Currency from '../../../common/components/Currency';
import ClubLink from '../../../club/components/common/Link';
import { useMemo } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { URL } from '../../../common/lib/paths';

export default function ViewPlayerTransferOfferPaidInterface(props) {
    const history = useHistory();

    const proposingClubLink = useMemo(() => !!props.playerTransferOffer ? <ClubLink club={props.playerTransferOffer.club} /> : null, [props.playerTransferOffer]);
    const receivingClubLink = useMemo(() => !!props.playerTransferOffer ? <ClubLink club={props.playerTransferOffer.playerContract.club} /> : null, [props.playerTransferOffer]);
    const playerLink = useMemo(() => !!props.playerTransferOffer ? <PlayerLink player={props.playerTransferOffer.playerContract.player} /> : null, [props.playerTransferOffer]);

    const withdrawPlayerTransferOfferMutation = useMutation(() => 
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${props.playerTransferOffer.id}`,
            {
                status: 'withdrawn'
            }
        ),
        {
            onSuccess: (data) => history.replace(generatePath(URL.viewPlayerTransferOfferPaidHistory, {playerTransferOfferPaidHistoryId: data.data.response.id}))
        }
    );

    const acceptPlayerTransferOfferMutation = useMutation(() => 
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${props.playerTransferOffer.id}`,
            {
                status: 'accepted'
            }
        ),
        {
            onSuccess: (data) => history.replace(generatePath(URL.playerTransferAuction, {playerTransferAuctionId: data.data.response}))
        }
    );

    const rejectPlayerTransferOfferMutation = useMutation(() => 
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${props.playerTransferOffer.id}`,
            {
                status: 'rejected'
            }
        ),
        {
            onSuccess: (data) => history.replace(generatePath(URL.viewPlayerTransferOfferPaidHistory, {playerTransferOfferPaidHistoryId: data.data.response.id}))
        }
    );

    function handleWithdrawPlayerTransferOffer() {
        withdrawPlayerTransferOfferMutation.mutate();
    }

    function handleAcceptPlayerTransferOffer() {
        acceptPlayerTransferOfferMutation.mutate();
    }

    function handleRejectPlayerTransferOffer() {
        rejectPlayerTransferOfferMutation.mutate();
    }

    if(props.isLoading) return(<Loader active />);

    return(
        <Card fluid>
            <Card.Content>
                <Card.Header>
                    <FormattedMessage
                        {...messages.cardHeader}
                        values={{
                            personName: playerLink
                        }}
                    />
                </Card.Header>
                <Card.Meta>
                    <FormattedDate
                        value={props.playerTransferOffer.createdAt}
                        day="numeric" 
                        month="short"
                        year="numeric" 
                    />
                </Card.Meta>
                <Card.Description style={{whiteSpace: 'pre-line'}}>
                    <p>
                        <FormattedMessage
                            {...messages.overview}
                            values={{
                                proposingClubName: proposingClubLink,
                                compensation: <Component_Currency value={props.playerTransferOffer.compensation} />,
                                receivingClubName: receivingClubLink,
                                personName: playerLink
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            {...messages.pending}
                            values={{
                                receivingClubName: receivingClubLink,
                                deadline: <FormattedDate value={props.playerTransferOffer.deadline} day='numeric' month='short' />
                            }}
                        />
                    </p>
                </Card.Description>
            </Card.Content>
            <Card.Content extra>
                {props.isMyPlayer === false ?
                    <Button
                        negative
                        fluid
                        onClick={handleWithdrawPlayerTransferOffer}
                        loading={withdrawPlayerTransferOfferMutation.isLoading}
                        disabled={withdrawPlayerTransferOfferMutation.isLoading}
                    >
                        Withdraw
                    </Button>
                    :
                    <Button.Group fluid width={2}>
                        <Button
                            positive
                            onClick={handleAcceptPlayerTransferOffer}
                            disabled={acceptPlayerTransferOfferMutation.isLoading}
                            loading={acceptPlayerTransferOfferMutation.isLoading}
                        >
                            <FormattedMessage {...messages.button_accept} />
                        </Button>
                        <Button
                            negative
                            onClick={handleRejectPlayerTransferOffer}
                            disabled={rejectPlayerTransferOfferMutation.isLoading}
                            loading={rejectPlayerTransferOfferMutation.isLoading}
                        >
                            <FormattedMessage {...messages.button_reject} />
                        </Button>
                    </Button.Group>
                }
            </Card.Content>
        </Card>
    );
}

const messages = {
    pageHeader: {
        id: "Page_TransferProposal.pageHeader",
        defaultMessage: "Transfer offer",
        description: "Header for the transfer offer page where the user can review a transfer offer in progress (accept/reject/withdraw)"
    },
    cardHeader: {
        id: "Page_TransferProposal.cardHeader",
        defaultMessage: "Transfer offer for {personName}",
        description: "Header for the card on the transfer offer page which contains the transfer offer details"
    },
    overview: {
        id: "Page_TransferProposal.overview_2",
        defaultMessage: "{proposingClubName} have offered {compensation} to {receivingClubName} for {personName}.",
        description: "Text describing the overview of the transfer where the proposing club have offered a sum of money to sign a player/staff from another club"
    },
    pending: {
        id: "Page_TransferProposal.description_1",
        defaultMessage: "{receivingClubName} have until {deadline} to respond to the offer.",
        description: "Text describing the status of the transfer where the club who have received the bid have not yet responded to it"
    },
    button_accept: {
        id: "Page_TransferProposal.button_accept",
        defaultMessage: "Accept",
        description: "Label for the button which accepts the transfer offer"
    },
    button_reject: {
        id: "Page_TransferProposal.button_reject",
        defaultMessage: "Reject",
        description: "Label for the button which rejects the transfer offer"
    }
}
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import TitledSegment from "../../../../common/components/TitledSegment";
import WideLayout from "../../../../common/components/WideLayout";
import { generatePath, useHistory } from "react-router";
import { staffName } from "../../../../common/dataTableColumns/staff/staffName";
import { transferAuctionDuration } from "../../../../common/dataTableColumns/transferAuctionDuration";
import { auctionStatus } from "../../../../common/dataTableColumns/auctionStatus";
import { currentAbility } from '../../../../common/dataTableColumns/currentAbility';
import DataTable from "../../../../common/components/DataTable";
import { URL } from "../../../../common/lib/paths";

export default function ClubStaffTransferAuctionFreeInterface(props) {
    return(
        <WideLayout>
            <TitledSegment
                title='Offers made'
                icon={faArrowUp}
                noPadding={true}
            >
                <ProposedStaffTransferAuctionFreeTable
                    isLoading={props.isLoading}
                    staffTransferAuctionFree={props.staffTransferAuctionFree}
                />
            </TitledSegment>
        </WideLayout>
    );
}

function ProposedStaffTransferAuctionFreeTable(props) {
    const history = useHistory();

    const columns = [
        auctionStatus((staffTransferAuctionFree) => staffTransferAuctionFree.isHistorical ? (staffTransferAuctionFree.staffTransferAuctionBidFreeHistory[0].isWinner ? 'won' : 'lost') : 'inProgress'),
        staffName((staffTransferAuctionFree) => staffTransferAuctionFree.staff),
        //TODO - staff role resolver
        transferAuctionDuration((staffTransferAuctionFree) => staffTransferAuctionFree.interval),
        currentAbility((staffTransferAuctionFree) => staffTransferAuctionFree.staff.currentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.staffTransferAuctionFree}
            onRowClicked={(row) => row.isHistorical ? history.push(generatePath(URL.staffTransferAuctionFreeHistory, {staffTransferAuctionFreeHistoryId: row.id})) : history.push(generatePath(URL.staffTransferAuctionFree, {staffId: row.staff.id, staffTransferAuctionFreeId: row.id}))}
        />
    );
}
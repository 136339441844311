import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function useGetTeamFixtures(clubId, teamType, seasonId, enabled) {
    return useQuery(
        ['getTeamFixtures', clubId, teamType, seasonId],
        () => axios.get(getQueryURL(clubId, teamType, seasonId)),
        {
            enabled
        }
    );
}

function getQueryURL(clubId, teamType, seasonId) {
    var queryString = '';

    if(!!seasonId) {
        queryString = `?seasonId=${seasonId}`;
    }

    return `${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/${teamType}/fixtures/${queryString}`;
}
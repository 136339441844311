import {Button} from "semantic-ui-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faCakeCandles, faClock, faCoins, faShieldHalved, faStar, faStreetView } from '@fortawesome/free-solid-svg-icons';
import {FormattedDate, useIntl} from "react-intl";

import DataTable from '../../common/components/DataTable';
import PlayerLink from "../../player/common/Link";
import InfoLabel from '../../player/common/InfoLabel';
import Currency from '../../common/components/Currency';
import ClubLink from "../../club/components/common/Link";

import { hsvToRgb, ratingToHsv, getColor } from '../../common/lib/color';

import ScoutReportDetailExpander from './ScoutReportDetailExpander';
import SUIIconShim from "../../common/components/SUIIconShim";
import { useHistory, useLocation } from "react-router";
import { filterStateReducer, initialFilterState } from "../../player/filter/lib";
import PlayerExpander from "../../player/common/PlayerExpander/Index";
import usePersistedState from "../../common/lib/usePersistedState";

const positionSortOrder = [
    'GK',
    'CB',
    'LB',
    'RB',
    'LWB',
    'RWB',
    'CDM',
    'CM',
    'LM',
    'RM',
    'CAM',
    'LW',
    'RW',
    'ST'
];

const positionBgColors = {
    'GK': '#5E37C1',
    'CB': '#51B2AC',
    'LB': '#51B2AC',
    'RB': '#51B2AC',
    'LWB': '#51B2AC',
    'RWB': '#51B2AC',
    'CDM': '#42B3CA',
    'CM': '#42B3CA',
    'LM': '#42B3CA',
    'RM': '#42B3CA',
    'CAM': '#42B3CA',
    'LW': '#4283CA',
    'RW': '#4283CA',
    'ST': '#4283CA'
}


function positionSorter(a, b) {
    const aIndex = positionSortOrder.indexOf(a.position);
    const bIndex = positionSortOrder.indexOf(b.position);

    if(aIndex > bIndex) return 1;
    if(aIndex < bIndex) return -1;
    return 0;
}

function valueSorter(a, b) {
    if(a.value > b.value) return 1;
    if(a.value < b.value) return -1;
    return 0;
}

export default function ScoutingInterface(props) {
    const intl = useIntl();
    const {persistedState, updatePersistedState} = usePersistedState('scoutingFilter', initialFilterState);

    const columns = [
        {
            name: intl.formatMessage(messages.playerNameColumnLabel),
            selector: scoutReport =>
                <>
                    <div className='playerName'>
                        <PlayerLink player={scoutReport.player} />
                    </div>
                    <div className='infoLabels'>
                        <InfoLabel
                            player={scoutReport.player}
                            playerContract={scoutReport.player.playerContract}
                        />
                    </div>
                </>,
            ignoreRowClick: true,
			wrap: false,
			allowOverflow: true,
            compact: true,
            grow: 4,
            style: {
                '&[role="cell"]': {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }
        },
        {
            name: <FontAwesomeIcon icon={faStreetView} />,
            selector: scoutReport => scoutReport.player.position,
            sortable: true,
            sortFunction: positionSorter,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            width: '38px',
            hide: 374,
            grow: 1,
            conditionalCellStyles: [
                {
                    when: () => true,
                    style: (scoutReport) => ({
                        backgroundColor: positionBgColors[scoutReport.player.position],
                        fontWeight: '700',
                        color: '#FFF',
                        fontSize: '12px'
                    })
                }
            ]
        },
        {
            name: <FontAwesomeIcon icon={faCakeCandles} />,
            selector: scoutReport => scoutReport.player.person.age,
            sortable: true,
            wrap: false,
            center: true,
            allowOverflow: false,
            compact: true,
            grow: 1,
            minWidth: '30px',
            maxWidth: '80px',
            hide: 560
        },
        {
            name: <FontAwesomeIcon icon={faShieldHalved} />,
            cell: scoutReport => scoutReport.player.playerContract?.id ? <ClubLink club={scoutReport.player.playerContract.club} /> : 'Free agent',
            wrap: false,
            allowOverflow: false,
            compact: true,
            grow: 2,
            hide: 600
        },
        {
            name: <FontAwesomeIcon icon={faCoins} />,
            cell: scoutReport => <Currency value={scoutReport.player.value} />,
            sortable: true,
            sortFunction: valueSorter,
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            grow: 1,
            minWidth: '60px',
            maxWidth: '80px',
            hide: 425
        },
        {
            name: <FontAwesomeIcon icon={faClock} />,
            selector: scoutReport => <FormattedDate value={scoutReport.dueDate || scoutReport.updatedAt} day='numeric' month='short' />,
            sortable: true,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            minWidth: '60px',
            maxWidth: '100px',
            hide: 350,
            grow: 1
        },
        {
            name: <FontAwesomeIcon icon={faStar} />,
            selector: scoutReport => scoutReport.player.currentAbility,
            sortable: true,
            wrap: false,
            allowOverflow: false,
            center: true,
            compact: true,
            minWidth: '30px',
            maxWidth: '40px',
            grow: 1,
            conditionalCellStyles: [
                {
                    when: () => true,
                    style: scoutReport => ({
                        backgroundColor: hsvToRgb(ratingToHsv(scoutReport.player.currentAbility)),
                        color: getColor(hsvToRgb(ratingToHsv(scoutReport.player.currentAbility)).substr(1)),
                        fontWeight: '700'
                    })
                }
            ]
        }
    ]

    return(
        <>
            <DataTable
                columns={columns}
                data={props.scoutReports}
                isLoading={props.isLoading}
                placeholderCount={5}
                expandableRows
                expandableRowsComponent={PlayerExpander}
            />
            <Button.Group>
                <Button
                    icon
                    disabled={persistedState.page === 1}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                ><SUIIconShim><FontAwesomeIcon icon={faArrowLeft} /></SUIIconShim></Button>
                <Button disabled className="opacity-override" color='black'>Page {persistedState.page}</Button>
                <Button
                    icon
                    disabled={props.players?.length < 10}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage'}))}
                    color='black'
                ><SUIIconShim><FontAwesomeIcon icon={faArrowRight} /></SUIIconShim></Button>
            </Button.Group>
        </>
    );
}

const messages = {
    playerNameColumnLabel: {
        id: "Layout_TransfersPlayersOffersTable.nameHeader",
        defaultMessage: "Name",
        description: "Header for the column in the player offer table which states the name of a player"
    }
}
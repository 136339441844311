import {currency} from '../../../../common/dataTableColumns/currency';
import {date} from '../../../../common/dataTableColumns/date';
import {leagueName} from '../../../../common/dataTableColumns/leagueName';
import {clubName} from '../../../../common/dataTableColumns/club/clubName';
import { useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import DataTable from '../../../../common/components/DataTable';
import { useEffect, useState } from 'react';
import WorldNationsToolbarItem from '../../toolbar/WorldNationsToolbarItem';
import WorldClubsToolbarItem from '../../toolbar/WorldClubsToolbarItem';
import WorldCupsToolbarItem from '../../toolbar/WorldCupsToolbarItem';
import WorldRecordsToolbarItem from '../../toolbar/WorldRecordsToolbarItem';
import MediumLayout from '../../../../common/components/MediumLayout';
import RecordFilter from '../filter';

export default function ClubBalanceRecords(props) {
    const [timePeriod, setTimePeriod] = useState('allTime');
    const [nationGroupShortcode, setNationGroupShortcode] = useState(null);

    useEffect(() => {
        props.setToolbar({
            title: 'Club balance records',
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        });
    }, []);

    const balanceRecordsQuery = useQuery(
        ['getClubBalanceRecords', timePeriod, nationGroupShortcode],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/world/records/clubs?record=balance&timePeriod=${timePeriod}${!!nationGroupShortcode ? `&nationId=${nationGroupShortcode}` : ''}`)
    );

    return(
        <MediumLayout>
            <RecordFilter
                validTimePeriodFilters={['allTime', 'anySeason', 'seasonId']}
                nationFilter={true}
                selectedTimePeriod={timePeriod}
                handleTimePeriodChange={setTimePeriod}
                selectedNationGroupShortcode={nationGroupShortcode}
                handleNationGroupShortcodeChange={setNationGroupShortcode}
            />
            {timePeriod === 'allTime' ?
                <AllTimeBalanceRecordsInterface
                    isLoading={balanceRecordsQuery.isLoading}
                    records={balanceRecordsQuery.data?.data?.response}
                />
                :
                <SeasonBalanceRecordsInterface
                    isLoading={balanceRecordsQuery.isLoading}
                    records={balanceRecordsQuery.data?.data?.response}
                />
            }
        </MediumLayout>
    );
}

function AllTimeBalanceRecordsInterface(props) {
    const columns = [
        clubName((club) => club),
        leagueName((club) => club.league),
        date('seasonStartDate', (club) => club.season.startDate),
        currency('balance', (club) => club.maxBalance)
    ]

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}

function SeasonBalanceRecordsInterface(props) {
    const columns = [
        clubName((club) => club),
        leagueName((club) => club.league),
        date(
            'season',
            (club) => club.season.startDate,
            false
        ),
        currency('balance', (club) => club.maxBalance)
    ]

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}
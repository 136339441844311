import axios from "axios";
import CreatePlayerTransferOfferInterface from "./interface";
import {useParams} from 'react-router-dom';
import {useMutation, useQueryClient, useQuery} from '@tanstack/react-query';

export default function CreatePlayerTransferOffer(props) {
    const {playerId} = useParams();
    const queryClient = useQueryClient();

    const submitBidMutation = useMutation(({playerContractId, compensation, salaryOffer, endSeasonIdOffer}) =>
        axios.post(`${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid`, {
            playerContractId: parseInt(playerContractId),
            compensation: parseInt(compensation),
            contractSalaryOffer: parseInt(salaryOffer),
            contractEndSeasonIdOffer: endSeasonIdOffer
        }), {
        onSuccess: (data) => {
            queryClient.invalidateQueries('getPlayerPlayerTransferOffer');
        }
    });

    const contractDemandsQuery = useQuery(
        ['getPlayerContractDemands', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/contractDemands`)
    );

    const clubBudgetQuery = useQuery(
        ['getClubBudgets'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/budgets`)
    );

    const handleSubmitBid = (compensation, salaryOffer, endSeasonIdOffer) => {
        submitBidMutation.mutate({
            playerContractId: props.player.playerContract.id,
            compensation,
            salaryOffer,
            endSeasonIdOffer
        });
    }

    return(
        <CreatePlayerTransferOfferInterface
            {...props}
            isLoading={contractDemandsQuery.isLoading || clubBudgetQuery.isLoading}
            handleSubmitBid={handleSubmitBid}
            isSubmitting={submitBidMutation.isSubmitting}
            submitBidError={submitBidMutation.error?.response?.data}
            contractDemands={contractDemandsQuery.data?.data?.response.contractDemands}
            clubBudgets={clubBudgetQuery.data?.data?.response}
            currentSeason={contractDemandsQuery.data?.data?.response.currentSeason}
            isInterested={contractDemandsQuery.data?.data?.response.reputationWithinRange}
            isTransferBlocked={contractDemandsQuery.data?.data?.response.isTransferBlocked}
        />
    )
}
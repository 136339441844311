import TitledSegment from "../../../common/components/TitledSegment";
import { FormattedMessage } from "react-intl";
import { Loader, Message } from "semantic-ui-react";
import ClubLink from "../../../club/components/common/Link";
import PlayerLink from "../../common/Link";

export default function ViewPlayerTransferOfferLoanHistoryInterface(props) {
    if(props.isLoading) return(<Loader active />);

    const proposingClubLink = <ClubLink club={props.playerTransferOfferLoanHistory.toClub} />;
    const receivingClubLink = <ClubLink club={props.playerTransferOfferLoanHistory.fromClub} />;
    const playerLink = <PlayerLink player={props.playerTransferOfferLoanHistory.player} />;

    return(
        <TitledSegment
            title='Loan offer'
        >
            <div>
                <p>
                    <FormattedMessage
                        {...messages.intro}
                        values={{
                            proposingClubName: proposingClubLink,
                            playerName: playerLink
                        }}
                    />
                </p>
                {props.playerTransferOfferLoanHistory.status === 'accepted' &&
                    <Message positive>
                        <FormattedMessage
                            {...messages.accepted}
                            values={{
                                receivingClubName: receivingClubLink
                            }}
                        />
                    </Message>
                }
                {props.playerTransferOfferLoanHistory.status === 'rejected' &&
                    <Message negative>
                        <FormattedMessage
                            {...messages.rejected}
                            values={{
                                receivingClubName: receivingClubLink
                            }}
                        />
                    </Message>
                }
                {props.playerTransferOfferLoanHistory.status === 'withdrawn' &&
                    <Message negative>
                        <FormattedMessage
                            {...messages.withdrawn}
                            values={{
                                proposingClubName: proposingClubLink
                            }}
                        />
                    </Message>
                }
            </div>
        </TitledSegment>
    )
}

const messages = {
    intro: {
        id: 'playerLoanOffer:view:intro',
        defaultMessage: '{proposingClubName} have offered to take {playerName} on loan until the end of the season',
        description: 'Intro for a loan offer'
    },
    accepted: {
        id: 'playerLoanOffer:view:accepted',
        defaultMessage: '{receivingClubName} have accepted the offer and the transfer has completed.',
        description: "Text describing the status of the loan offer where the club who have received the bid have accepted it and the transfer has completed"
    },
    rejected: {
        id: "playerLoanOffer:view:rejected",
        defaultMessage: "{receivingClubName} have rejected the offer.",
        description: "Text describing the status of the loan offer where the club who have received the bid have rejected it."
    },
    withdrawn: {
        id: "playerLoanOffer:view:withdrawn",
        defaultMessage: "{proposingClubName} have withdrawn the offer",
        description: "Text describing the status of the loan offer where it has been withdrawn by the proposing club"
    },
}
import { faCaretDown, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Accordion, Message } from "semantic-ui-react";
import ContractTable from "../../../common/components/ContractTable";
import {FormattedMessage} from 'react-intl';
import SUIIconShim from "../../../common/components/SUIIconShim";

export default function ContractDemandsInterface(props) {
    if(props.isLoading) {
        return null;
    }

    return(
        <>
            {getCustomIntroText()}
            {getIntroText()}
            {getDemandModifierText()}
            {getContractDemands()}
            <ExistingContract
                playerContract={props.playerContract}
                currentSeason={props.currentSeason}
            />
            {getPlayerRegText()}
        </>
    );

    function getCustomIntroText() {
        if(!props.introMessage) {
            return null;
        } else {
            return(
                <p>{props.introMessage}</p>
            )
        }
    }

    function getIntroText() {
        if(props.isMyPlayer) {
            return(<p><FormattedMessage {...messages.introContractRenewal} /></p>);
        } else {
            return(<p><FormattedMessage {...messages.introTransferOffer} /></p>);
        }
    }

    function getDemandModifierText() {
        return(
            <>
                {props.contractDemands?.modifiers.isHomeCity ?
                    props.isMyPlayer ? <p><FormattedMessage {...messages.homeClubDiscountRenewal} /></p> : <p><FormattedMessage {...messages.homeClubDiscountTransfer} /></p>
                    :
                    null
                }
                {props.contractDemands?.modifiers.clubReputationHigh &&
                    <p>I'm not sure about this deal due to concerns about my playing time.</p>
                }
                {props.contractDemands?.modifiers.playerReputationHigh &&
                    <p>I'm not sure about this deal as it is a step down in my career.</p>
                }
            </>
        );
    }

    function ExistingContract(props) {
        const [isExpanded, setIsExpanded] = useState(false);

        if(!props.playerContract) return null;
        
        return(
            <Accordion>
                <Accordion.Title
                    active={isExpanded}
                    onClick={() => setIsExpanded(isExpanded => !isExpanded)}
                    index={0}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faCaretDown} className='inlineIcon' />
                        Existing contract
                    </SUIIconShim>
                </Accordion.Title>
                <Accordion.Content
                    active={isExpanded}
                >
                    <ContractTable
                        contractSalary={props.playerContract.salary}
                        contractExpiry={props.playerContract.endSeason.endDate}
                        currentSeason={props.currentSeason}
                    />
                </Accordion.Content>
            </Accordion>
        );
    }

    function getContractDemands() {
        if(props.contractOffer) {
            return(
                <>
                    <FormattedMessage {...messages.contractDemandedText} />
                    <ContractTable
                        contractSalary={props.contractOffer.salaryDemand}
                        contractExpiry={props.contractOffer.endSeasonDemand.endDate}
                        currentSeason={props.currentSeason}
                    />
                </>
            )
        } else {
            return(
                <>
                    <FormattedMessage {...messages.contractDemandsText} />
                    <ContractTable
                        contractSalary={props.contractDemands.adjustedSalaryDemand}
                        contractExpiry={props.contractDemands.adjustedEndSeasonDemand.endDate}
                        currentSeason={props.currentSeason}
                    />
                </>
            )
        }
    }

    function getPlayerRegText() {
        if(props.isYouthTeamPlayer === true) {
            return(
                <Message negative>
                    <FormattedMessage
                        {...messages.willPromoteToFirstTeam}
                    />
                </Message>
            );
        } else {
            return null;
        }
    }
}

const messages = {
    introContractRenewal: {
        id: "Layout_PersonContractOffer.introContractRenewal",
        defaultMessage: "I'm really enjoying my time at the club so I'm delighted that you want to offer me a new contract.",
        description: "Conversation-style sentence where the person states that they're happy to open contract renewal discussions with the club"
    },
    introTransferOffer: {
        id: "Layout_PersonContractOffer.introTransferOffer",
        defaultMessage: "I'm really exicited about playing for this great club. My contract demands are below.",
        description: "Conversation-style sentence where the person states their happiness that the club have decided to make a transfer bid for them"
    },
    homeClubDiscountTransfer: {
        id: 'player:contractOffer:homeClubDiscount',
        defaultMessage: 'As you can see, I have lowered my wage demands as I would like to return to my home city.',
        description: 'Conversational sentence shown when player is in negotiations about a return to their home club'
    },
    homeClubDiscountRenewal: {
        id: 'player:contractOffer:homeClubDiscount',
        defaultMessage: 'As you can see, I have again lowered my wage demands as I am enjoying playing in my home city.',
        description: 'Conversational sentence shown when player is in negotiations about renewing with their home club'
    },
    contractDemandsText: {
        id: "Layout_PersonContractOffer.contractDemandsText",
        defaultMessage: "This is the contract that I want.",
        description: "Conversation-style sentence where the person states their contract demands"
    },
    contractDemandedText: {
        id: "Layout_PersonContractOffer.contractDemandedText",
        defaultMessage: "This is the contract that I requested.",
        description: "Conversation-style sentence where the person states the contract that they had demanded (contract offer in progress)"
    },
    willPromoteToFirstTeam: {
        id: 'player:contractOffer:willPromoteToFirstTeam',
        defaultMessage: 'If this player accepts the contract offer they will automatically be moved to the first team',
        description: 'Warning message shown if you try to offer a new contract to a youth player warning that the player will be moved to the first team if they accept'
    }
}
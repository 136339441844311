import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useLocation} from 'react-router-dom';

import usePersistedState from '../../../common/lib/usePersistedState';

import StatsTable from './interface';

const PERSISTED_STATE_KEY = 'playerTeamLeagueSeasons';

export default function StatsTableData(props) {
    const location = useLocation();
    const {persistedState} = usePersistedState(PERSISTED_STATE_KEY, {page: 1, stat: 'goalsScored'});
    
    const leagueSeasonId = location.state?.leagueSeasonId;

    const playerTeamLeagueSeasonsQuery = useQuery(
        ['getLeagueSeasonPlayerTeamLeagueSeasons', leagueSeasonId, persistedState.stat, persistedState.page],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueSeasons/${leagueSeasonId}/playerTeamLeagueSeasons?stat=${persistedState.stat}&page=${persistedState.page}`),
        {
            keepPreviousData: true
        }
    );

    return(
        <StatsTable
            playerTeamLeagueSeasonsQueryIsLoading={playerTeamLeagueSeasonsQuery.isLoading}
            playerTeamLeagueSeasons={playerTeamLeagueSeasonsQuery.data?.data?.response?.playerTeamLeagueSeasons}
            highlightTeamIds={props.highlightTeamIds}
            persistedStateKey={PERSISTED_STATE_KEY}
            clubResolver={(PTLS) => PTLS.TLS.team.club}
        />
    )
}
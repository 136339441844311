import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { useParams } from "react-router";
import StaffContractInterface from "./interface";
import { useEffect } from "react";
import Profile from "../toolbar/Profile";
import StaffContractOfferToolbarItem from "../toolbar/ContractOffer";
import History from "../toolbar/History";
import ShortenedPlayerName from "../../player/common/ShortenedName";

export default function StaffContract(props) {
    const {staffId} = useParams();

    const contractDemandsQuery = useQuery(
        ['getStaffContractDemands', staffId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/staff/${staffId}/contractDemands`)
    );

    const staffQuery = useQuery(
        ['getStaff', staffId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/staff/${staffId}`)
    );

    useEffect(() => {
        if(!!staffQuery.data?.data.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={staffQuery.data.data.response} /> contract offer</>,
                navigation: [
                    <Profile
                        key={1}
                        staffId={staffId}
                    />,
                    <StaffContractOfferToolbarItem
                        key={2}
                        staffId={staffId}
                        isMyStaff={true}
                        isRetired={false}
                    />,
                    <History
                        key={3}
                        staffId={staffId}
                    />
                ]
            })
        }
    }, [staffId, staffQuery.isLoading]);

    return(
        <StaffContractInterface
            isLoading={contractDemandsQuery.isLoading}
            contractDemands={contractDemandsQuery.data?.data?.response.contractDemands}
            currentSeason={contractDemandsQuery.data?.data?.response.currentSeason}
            isInterested={contractDemandsQuery.data?.data?.response.reputationWithinRange}
            staff={staffQuery.data?.data?.response}
        />
    )
}
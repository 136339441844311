import axios from "axios";
import ViewPlayerTransferOfferPaidHistoryInterface from "./interface";
import NarrowLayout from "../../../common/components/NarrowLayout";
import { useParams } from "react-router";
import { useQuery } from '@tanstack/react-query';
import { useEffect } from "react";

export default function ViewPlayerTransferOfferPaidHistory(props) {
    const {playerTransferOfferPaidHistoryId} = useParams();

    const getPlayerTransferOfferPaidHistoryQuery = useQuery(
        ['getPlayerTransferOfferPaidHistory', playerTransferOfferPaidHistoryId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferOfferPaidHistory/${playerTransferOfferPaidHistoryId}`)
    );

    useEffect(() => {

    }, [getPlayerTransferOfferPaidHistoryQuery.data?.data?.response]);

    return(
        <NarrowLayout>
            <ViewPlayerTransferOfferPaidHistoryInterface
                isLoading={getPlayerTransferOfferPaidHistoryQuery.isLoading}
                playerTransferOfferPaidHistory={getPlayerTransferOfferPaidHistoryQuery.data?.data?.response}
                isMyPlayer={getPlayerTransferOfferPaidHistoryQuery.data?.data?.response.fromClub.id === props.manager.club.id}
            />
        </NarrowLayout>
    );
}
import ManagerLink from "../../../manager/components/MangerLink";

function managerName(
    managerResolver
) {
    return {
        id: 'managerName',
        name: 'Manager',
        selector: (rowData) =>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}} data-tag='allowRowEvents'>
                <div className='playerName'>
                    <ManagerLink manager={managerResolver(rowData)} />
                </div>
            </div>,
        wrap: false,
        compact: true,
        grow: 3,
        ignoreRowClick: true,
        style: {
            '&[role="cell"]': {
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    }
}

export {
    managerName
}
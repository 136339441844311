import axios from "axios";
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { generatePath, useHistory, useParams } from "react-router";
import { Button, Message } from "semantic-ui-react";
import ContractTable from "../../common/components/ContractTable";
import NarrowLayout from "../../common/components/NarrowLayout";
import TitledSegment from "../../common/components/TitledSegment";
import { URL } from "../../common/lib/paths";
import ContractOfferForm from "../../player/contract/offerForm/interface";
import StaffContractDemandsInterface from "../common/ContractDemands";

export {
    CreateStaffTransferAuctionFreeInterface,
    ViewStaffTransferAuctionFreeInterface
}

function CreateStaffTransferAuctionFreeInterface(props) {
    const {staffId} = useParams();
    const history = useHistory();
    const queryClient = useQueryClient();
    
    const contractDemandsQuery = useQuery(
        ['getStaffContractDemands', staffId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/staff/${staffId}/contractDemands`)
    );

    const createStaffTransferAuctionFree = useMutation(
        ({salaryOffer, endSeasonIdOffer}) => axios.post(`${process.env.REACT_APP_APPHOST}/staffTransferAuctionBidFree`, {
            staffId: parseInt(staffId),
            salaryOffer,
            endSeasonIdOffer
        }),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('getStaffTransferAuctionFree');
                history.replace(generatePath(URL.staffTransferAuctionFree, {staffId: staffId, staffTransferAuctionFreeId: data.data.response.staffTransferAuctionFreeId}));
            }
        }
    )

    function handleSubmitContractOffer(salaryOffer, endSeasonIdOffer) {
        createStaffTransferAuctionFree.mutate({
            salaryOffer: parseInt(salaryOffer),
            endSeasonIdOffer: parseInt(endSeasonIdOffer)
        });
    }

    if(!!props.staff?.staffContract) {
        return(
            <Message negative>
                <p>You cannot bid for this staff as they are under contract</p>
            </Message>
        );
    }

    return(
        <NarrowLayout>
            <TitledSegment
                title='Contract demands'
            >
                <StaffContractDemandsInterface
                    isLoading={contractDemandsQuery.isLoading}
                    isMyStaff={false}
                    contractDemands={contractDemandsQuery.data?.data?.response.contractDemands}
                    currentSeason={contractDemandsQuery.data?.data?.response.currentSeason}
                    contractOffer={null}
                    staffContract={null}
                />
            </TitledSegment>
            <TitledSegment
                title='Contract offer'
            >
                <ContractOfferForm
                    isLoading={contractDemandsQuery.isLoading}
                    isMyPlayer={false}
                    currentSeason={contractDemandsQuery.data?.data?.response.currentSeason}
                    contractDemands={contractDemandsQuery.data?.data?.response.contractDemands}
                    reputationWithinRange={contractDemandsQuery.data?.data?.response.reputationWithinRange}
                    handleSubmit={handleSubmitContractOffer}
                    isSubmitting={createStaffTransferAuctionFree.isLoading}
                />
            </TitledSegment>
        </NarrowLayout>
    )
}

function ViewStaffTransferAuctionFreeInterface(props) {
    const queryClient = useQueryClient();

    const currentSeasonQuery = useQuery(
        ['getSeasonByDate'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/seasons?bounds=0`)
    );

    const deleteStaffFreeAuctionOffer = useMutation(
        (staffFreeAuctionBidId) => axios.delete(`${process.env.REACT_APP_APPHOST}/staffTransferAuctionBidFree/${staffFreeAuctionBidId}`),
        {
            onSuccess: (data) => {
                queryClient.invalidateQueries('getStaffTransferAuctionFree');
            }
        }
    );

    function handleWithdrawContractOffer() {
        deleteStaffFreeAuctionOffer.mutate(props.staffTransferAuctionFree.staffTransferAuctionBidFree.id);
    }

    if(currentSeasonQuery.isLoading) return null;

    return(
        <NarrowLayout>
            <TitledSegment
                title='Contract demands'
            >
                <StaffContractDemandsInterface
                    introMessage={<IntroMessage staffTransferAuctionFree={props.staffTransferAuctionFree} />}
                    isLoading={currentSeasonQuery.isLoading}
                    isMyStaff={false}
                    currentSeason={currentSeasonQuery.data?.data?.response[0]}
                    contractOffer={{
                        salaryDemand: props.staffTransferAuctionFree.staffTransferAuctionBidFree.salaryDemand,
                        endSeasonDemand: props.staffTransferAuctionFree.staffTransferAuctionBidFree.endSeasonDemand,
                    }}
                />
            </TitledSegment>
            <TitledSegment
                title='Contract offer'
            >
                <p><FormattedMessage {...messages.contractOfferedText} /></p>
                <ContractTable
                    contractSalary={props.staffTransferAuctionFree.staffTransferAuctionBidFree.salaryOffer}
                    contractExpiry={props.staffTransferAuctionFree.staffTransferAuctionBidFree.endSeasonOffer.endDate}
                    currentSeason={currentSeasonQuery.data?.data?.response[0]}
                />
                <Button
                    fluid
                    negative
                    onClick={handleWithdrawContractOffer}
                    loading={deleteStaffFreeAuctionOffer.isLoading}
                >
                    Withdraw
                </Button>
            </TitledSegment>
        </NarrowLayout>
    )
}

function IntroMessage(props) {
    if(!!props.staffTransferAuctionFree) {
        return(
            <FormattedMessage
                {...messages.auctionInProgress}
                values={{
                    offersCount: props.staffTransferAuctionFree.staffTransferAuctionBidFreeCount,
                    endDateMin: <><FormattedDate value={props.staffTransferAuctionFree.endDateMin} /> <FormattedTime value={props.staffTransferAuctionFree.endDateMin} /></>,
                    endDateMax: <><FormattedDate value={props.staffTransferAuctionFree.endDateMax} /> <FormattedTime value={props.staffTransferAuctionFree.endDateMax} /></>
                }}
            />
        )
    } else {
        return(
            <FormattedMessage {...messages.auctionNotStarted} />
        )
    }
}

const messages = {
    auctionInProgress: {
        id: 'playerFreeAuction:inProgress',
        defaultMessage: 'I do not currently have a club, so I am available on a free transfer. I am currently considering offers from {offersCount} club(s). I will decide my next club between {endDateMin} - {endDateMax}.',
        description: 'Message when a player is a free agent and an auction is in progress'
    },
    auctionNotStarted: {
        id: 'playerFreeAuction:notStarted',
        defaultMessage: 'I do not currently have a club, so I am available on a free transfer. After you submit a contract offer I will decide whether to accept within 24 hours.',
        description: 'Message when a player is a free agent but an auction has not yet started'
    },
    contractOfferedText: {
        id: 'contractOffer:contractOfferedText',
        defaultMessage: 'This is the contract that you offered.',
        description: 'Conversation-style sentence used where the player states the contract that you have offered them'
    }
}
import { CSSTransition } from "react-transition-group-react-18";
import { Button, Dropdown } from "semantic-ui-react";
import usePersistedState from "../../../common/lib/usePersistedState";
import { filterStateReducer, initialFilterState } from "../lib";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { filter } from "../../../common/components/i18n";
import ClubFilterOverlay from "../overlay/interface";
import { FormattedMessage } from "react-intl";

export default function ClubFilterButton(props) {
    const {persistedState, updatePersistedState} = usePersistedState(props.persistedStateKey, initialFilterState);

    return(
        <>
            <Button.Group>
                <Button
                    color='black'
                    toggle
                    icon
                    labelPosition="left"
                    onClick={handleFilterClick.bind(this)}
                    active={props.isFilterActive}
                    style={{backgroundColor: 'rgba(15, 16, 17)'}}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faFilter} className='buttonIconFix' />
                    </SUIIconShim>
                    <FormattedMessage {...filter} />
                </Button>
                <Dropdown
                    toggle
                    active={props.isFilterActive}
                    as={Button}
                    className="icon"
                    color='black'
                    style={{backgroundColor: 'rgba(15, 16, 17)'}}
                >
                    <Dropdown.Menu>
                        {/*this.renderSavedFilterMenuItems()*/}
                        <Dropdown.Divider style={{margin: 0}} />
                        {/*<Dropdown.Item text="Save filter" />*/}
                        <Dropdown.Item text="Clear filter" onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'clearFilters'}))} />
                    </Dropdown.Menu>
                </Dropdown>
            </Button.Group>
            <CSSTransition
                in={persistedState.filterOverlayOpen}
                timeout={300}
                classNames='transferSearchFilterOverlay'
                mountOnEnter={true}
                unmountOnExit={true}
            >
                {state => 
                    <ClubFilterOverlay
                        state={state}
                        persistedStateKey={props.persistedStateKey}
                    />
                }
            </CSSTransition>
        </>
    );
    
    function handleFilterClick() {
        updatePersistedState(filterStateReducer(persistedState, {name: 'toggleFilterOverlay'}));
    }
}
import { faCheck, faHourglassHalf, faInfo, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defineMessages, FormattedMessage } from "react-intl";
import colours from "../colours";

function transferOfferStatus(resolver) {
    return {
        id: 'offerStatus',
        name: '',
        selector: (rowData) => statusIcons[resolver(rowData)],
        compact: true,
        grow: 1,
        width: '30px'
    }
}

export {
    transferOfferStatus
}

const statusIcons = {
    pending: <FontAwesomeIcon icon={faHourglassHalf} className='inlineIcon' />,
    rejected: <FontAwesomeIcon icon={faXmark} className='inlineIcon' color={colours.negative} />,
    withdrawn: <FontAwesomeIcon icon={faXmark} className='inlineIcon' color={colours.negative} />,
    accepted: <FontAwesomeIcon icon={faCheck} className='inlineIcon' color={colours.positive} />
}

const messages = defineMessages({
    pending: {
        id: 'dataTable:transferOfferStatus:pending',
        defaultMessage: '{icon}Pending',
        description: 'Status message for transfer offer which is still awaiting a response from the receiving club'
    },
    rejected: {
        id: 'dataTable:transferOfferStatus:rejected',
        defaultMessage: '{icon}Rejected',
        description: 'Status message for transfer offer which has been rejected by the receiving club'
    },
    withdrawn: {
        id: 'dataTable:transferOfferStatus:rejected',
        defaultMessage: '{icon}Withdrawn',
        description: 'Status message for transfer offer which has been withdrawn by the proposing club'
    },
    accepted: {
        id: 'dataTable:transferOfferStatus:accepted',
        defaultMessage: '{icon}Accepted',
        description: 'Status message for transfer offer which has been accepted by the proposing club'
    }
})
import { Redirect, generatePath } from 'react-router';
import {useTutorial} from '../hooks/useTutorial';

import tutorialData from '../tutorials/transfers';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Loader } from 'semantic-ui-react';

import {initialFilterState} from '../../player/filter/lib';
import useUser from '../../common/useUser';
import useGetPlayers from '../../transfer/playerSearch/useGetPlayers';

export default function TransfersTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    const userQuery = useUser(!cachedTutorialData);
    const playersQuery = useGetPlayers({...initialFilterState, filterTransferListed: true, filterUninterested: true}, false, !cachedTutorialData);

    useEffect(() => {
        if(!cachedTutorialData && userQuery.isSuccess && playersQuery.isSuccess) {
            var compiledTutorialData = cloneDeep(tutorialData);

            if(playersQuery.data.data.response.length === 0) {
                compiledTutorialData.steps.splice(0, 10);
            }

            localStorage.setItem('playerSearchFilter', JSON.stringify(initialFilterState));

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                        step.path = {
                            pathname: generatePath(step.path)
                        }
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        step.path = {
                            pathname: generatePath(step.path)
                        }

                        step.updatePersistedState = function(queryClient) {
                            const filterState = {
                                ...initialFilterState,
                                filterOverlayOpen: true
                            }

                            localStorage.setItem('playerSearchFilter', JSON.stringify(filterState));
                            queryClient.invalidateQueries('playerSearchFilter');
                        }
                        break;
                    case 5:
                    case 6:
                        step.path = {
                            pathname: generatePath(step.path)
                        }

                        step.updatePersistedState = function(queryClient) {
                            const filterState = {
                                ...initialFilterState,
                                filterOverlayOpen: false,
                                filterTransferListed: true,
                                filterUninterested: true
                            }

                            localStorage.setItem('playerSearchFilter', JSON.stringify(filterState));
                            queryClient.invalidateQueries('playerSearchFilter');
                        }
                        break;
                    case 7:
                    case 8:
                    case 9:
                        step.path = {
                            pathname: generatePath(step.path, {playerId: playersQuery.data.data.response[0].id}),
                        }
                        break;
                    case 10:
                    case 16:
                    case 17:
                        step.path = {
                            pathname: generatePath(step.path)
                        }
                        break;
                    case 11:
                    case 12:
                        step.path = {
                            pathname: generatePath(step.path),
                            state: {
                                status: 'completed'
                            }
                        }
                        break;
                    case 13:
                    case 14:
                        step.path = {
                            pathname: generatePath(step.path),
                            state: {
                                status: 'pending'
                            }
                        }
                        break;
                    case 15:
                        step.path = {
                            pathname: generatePath(step.path),
                            state: {
                                status: 'pending'
                            }
                        }

                        step.updatePersistedState = function(queryClient) {
                            localStorage.setItem('comparePlayers', JSON.stringify([playersQuery.data.data.response[0].id]));
                            queryClient.invalidateQueries('comparePlayers');
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData, userQuery.isSuccess, playersQuery.isSuccess]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
import {defineMessages} from 'react-intl';
import {generatePath} from 'react-router-dom';

import {URL} from '../../common/lib/paths';
import ToolbarItem from '../../menu/toolbar/ToolbarItem';

const messages = defineMessages({
    text: {
        id: 'nation:toolbar:overview',
        defaultMessage: 'Overview',
        description: 'Link in the toolbar in the nation section for the nation overview page'
    }
})

export default function NationProfile(props) {
    return(
        <ToolbarItem
            path={generatePath(URL.nation, {nationId: props.nationId})}
            icon='table'
            messages={messages}
            genericPath={URL.nation}
        />
    )
}
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {uniq} from 'lodash';

export default function useCompare() {
    const queryClient = useQueryClient();

    const {data} = useQuery(
        ['comparePlayers'],
        () => {
            return getPlayerIds()
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            staleTime: Infinity,
            cacheTime: Infinity,
            initialData: () => getPlayerIds()
        }
    );

    function removePlayerIndex(index) {
        let newPlayerIds = [...data];
        newPlayerIds.splice(index, 1);

        localStorage.setItem('comparePlayers', JSON.stringify(newPlayerIds));
        
        queryClient.invalidateQueries('comparePlayers');
    }

    function removeAll() {
        localStorage.setItem('comparePlayers', JSON.stringify([]));

        queryClient.invalidateQueries('comparePlayers');
    }

    function addPlayerIds(addPlayerIds) {
        const newPlayerIds = uniq([...data, ...addPlayerIds]);

        localStorage.setItem('comparePlayers', JSON.stringify(newPlayerIds));
        
        queryClient.invalidateQueries('comparePlayers');
    }

    function togglePlayerId(playerId) {
        const index = data.indexOf(playerId);

        if(index !== -1) {
            removePlayerIndex(index);
        } else {
            addPlayerIds([playerId]);
        }
    }

    return {
        playerIds: data,
        removePlayerIndex,
        removeAll,
        addPlayerIds,
        togglePlayerId
    };
}

function getPlayerIds() {
    let playersToCompare;
    
    try {
        playersToCompare = JSON.parse(localStorage.getItem('comparePlayers')) || [];
    } catch(error) {
        playersToCompare = [];
    }

    return playersToCompare;
}
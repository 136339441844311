import { generatePath, Link } from 'react-router-dom';
import Flag from '../../common/components/Flag';

import {URL} from '../../common/lib/paths';
import NationGroupName from './NationGroupName';

export default function NationGroupLinkWithFlag(props) {
    return(
        <>
            <Flag name={props.shortcode} className={`${props.flagClass}`} />
            <Link
                to={{
                    pathname: generatePath(URL.nation, {nationId: props.shortcode}),
                    search: props.search
                }}
                className={props.className}
            >
                <NationGroupName shortcode={props.shortcode} />
            </Link>
        </>
    );
}
import { useQuery } from "@tanstack/react-query"
import axios from "axios";
import { Redirect, useLocation, useParams } from "react-router"
import { Loader } from "semantic-ui-react";

export default function Cup(props) {
    const {cupId} = useParams();
    const location = useLocation();

    const getCupSeasonQuery = useQuery(
        ['getCupSeasons', cupId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cups/${cupId}/cupSeasons?currentSeason=true`),
        {
            enabled: location.state?.cupSeasonId === undefined
        }
    );

    if(!!location.state?.cupSeasonId) {
        return props.children;
    } else {
        if(getCupSeasonQuery.isLoading) {
            return(
                <Loader active />
            )
        } else {
            return(
                <Redirect
                    to={{
                        pathname: location.pathname,
                        state: {...location.state, cupSeasonId: getCupSeasonQuery.data.data.response.CS.id},
                        search: location.search
                    }}
                />
            )
        }   
    }
}
//last verified against spreadsheet 3/10/2021

export default {
    positionMap: [
        "GK",
        "LB",
        "CB",
        "CB",
        "RB",
        "CM",
        "CM",
        "CM",
        "ST",
        "ST",
        "ST"
    ],
    pitchHalfMap: [
        12,
        1,
        7,
        19,
        25,
        9,
        15,
        21,
        11,
        17,
        23
    ]
}
import DataTable from '../../common/components/DataTable.js';
import PlayerExpander from "../common/PlayerExpander/Index.js";
import { useTutorial } from "../../tutorial/hooks/useTutorial.js";
import { playerName } from "../../common/dataTableColumns/player/playerName.js";
import { playerPosition } from "../../common/dataTableColumns/player/playerPosition.js";
import { clubName } from "../../common/dataTableColumns/club/clubName.js";
import { personAge } from "../../common/dataTableColumns/person/personAge.js";
import { currency } from "../../common/dataTableColumns/currency.js";
import { contractExpiry } from "../../common/dataTableColumns/date.js";
import { currentAbility } from "../../common/dataTableColumns/currentAbility.js";

export default function PlayerListTransferView(props) {
    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('transfers', 6);

    const columns = [
        playerName(
            (player) => player,
            (player) => player.playerContract,
            () => false,
            undefined,
            undefined,
            (rowData, i) => isTutorial1 && i === 0 && getSearchParamsString('transfers', 7),
            (rowData, i) => isTutorial1 && i === 0 && 'glow',
            undefined
        ),
        playerPosition(
            (player) => player.position
        ),
        clubName(
            (player) => player.playerContract?.club,
            600
        ),
        personAge(
            (player) => player.person.age,
            450
        ),
        currency(
            'playerValue',
            (player) => player.value,
            450,
            false,
            {
                minWidth: '38px',
                maxWidth: '100px'
            }
        ),
        contractExpiry(
            (player) => player.playerContract?.endSeason.endDate,
            false,
            650
        ),
        currentAbility(
            (player) => player.currentAbility
        ),
        // {
        //     id: 'aa',
        //     selector: (player) =>
        //         (player.repRel === true && player.repAbs === false) ?
        //             'repRel'
        //             :
        //             player.repRel === false && player.repAbs === true ?
        //                 'repAbs'
        //                 :
        //                 'both'
        // }
    ];

    return(
        <DataTable
            columns={columns}
            data={props.players}
            isLoading={props.isLoading}
            placeholderCount={10}
            expandableRows
            expandableRowsComponent={PlayerExpander}
        />
    );
}
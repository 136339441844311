import { generatePath, Link } from "react-router-dom";
import { URL } from "../../common/lib/paths";

export default function CityLink(props) {
    if(!props.city) return null;

    return(
        <Link
            to={{
                pathname: generatePath(URL.city, {cityId: props.city.id}),
                search: props.search
            }}
            className={props.className}
        >
            {props.city.name}
        </Link>
    );
}
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { generatePath, Redirect, useParams } from "react-router";
import { Loader } from "semantic-ui-react";
import { URL } from "../../common/lib/paths";

export default function TransferOfferNew(props) {
    const {playerId} = useParams();
    const getPlayerAuction = useQuery(
        ['getPlayerAuction', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/playerTransferAuction`)
    );

    if(getPlayerAuction.isLoading) {
        return(<Loader active />);
    } else {
        const player = getPlayerAuction.data.data.response;
        
        if(!!player.playerContract) {
            if(!!player.playerContract.playerTransferAuctionPaid) {
                return(
                    <Redirect to={generatePath(URL.playerTransferAuction, {playerTransferAuctionId: player.playerContract.playerTransferAuctionPaid.id})} />
                );
            } else {
                return(
                    <Redirect to={generatePath(URL.playerTransferOrLoanOffer, {playerId: playerId})} />
                );
            }
        } else {
            if(!!player.playerTransferAuctionFree) {
                return(
                    <Redirect to={generatePath(URL.playerTransferAuctionFree, {playerId: playerId, playerFreeAuctionId: player.playerTransferAuctionFree.id})} />
                );
            } else {
                return(
                    <Redirect to={generatePath(URL.playerTransferAuctionFree, {playerId: playerId})} />
                );
            }
        }
    }
}
import {useState} from 'react';
import {Menu} from 'semantic-ui-react';
import { useMediaQuery } from 'react-responsive'
import AttributeTable from './AttributeTable';
import PlayerExpanderStats from './Stats';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsProgress, faChartSimple, faUser } from '@fortawesome/free-solid-svg-icons';
import PlayerExpanderProfile from './ProfileOverviewData';

export default function PlayerExpander(props) {
    const [view, setView] = useState('profile');

    const compactView = useMediaQuery({
        query: '(max-width: 500px)'
    });

    const styles = compactView ? mobileStyles : desktopStyles;

    return(
        <div style={styles.wrapper}>
            <div style={styles.container}>
                <Menu
                    secondary
                    pointing
                    widths={3}
                    style={{marginBottom: '0.5em'}}
                    className='expanderMenu'
                >
                    <Menu.Item
                        active={view==='attributes'}
                        onClick={() => setView('attributes')}
                    >
                        <FontAwesomeIcon icon={faBarsProgress} className='inlineIcon' />Attributes
                    </Menu.Item>
                    <Menu.Item
                        active={view==='profile'}
                        onClick={() => setView('profile')}
                    >
                        <FontAwesomeIcon icon={faUser} className='inlineIcon' />Profile
                    </Menu.Item>
                    <Menu.Item
                        active={view==='stats'}
                        onClick={() => setView('stats')}
                    >
                        <FontAwesomeIcon icon={faChartSimple} className='inlineIcon' />Stats
                    </Menu.Item>
                </Menu>
                {view === 'attributes' &&
                    <AttributeTable
                        player={props.data}
                        fullWidth={true}
                    />
                }
                {view === 'profile' &&
                    <PlayerExpanderProfile
                        playerId={props.data.id}
                    />
                }
                {view === 'stats' &&
                    <PlayerExpanderStats
                        playerId={props.data.id}
                    />
                }
            </div>
        </div>
    )
}

const desktopStyles = {
    wrapper: {
        backgroundColor: '#f2f2f2'
    },
    container: {
        maxWidth: '350px',
        margin: '0 auto',
        backgroundColor: '#FFF',
        padding: '0'
    },
    menu: {
        justifyContent: 'center'
    },
    form: {
        padding: '1em'
    }
}

const mobileStyles = {
    wrapper: {
        backgroundColor: '#f2f2f2'
    },
    container: {
        maxWidth: '350px',
        margin: '0 auto',
        backgroundColor: '#FFF'
    },
    menu: {
        justifyContent: 'center'
    },
    form: {
        padding: '0 1em'
    }
}
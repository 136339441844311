import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function useUser(enabled = true) {
    const query = useQuery(
        ['getUser'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/user`),
        {
            enabled: !!enabled,
            refetchOnMount: false,
            staleTime: Infinity,
            cacheTime: Infinity
        }
    );

    return {
        isLoading: query.isLoading,
        isSuccess: query.isSuccess,
        data: query.data,
        refetch: query.refetch,
        remove: query.remove,
        fetchStatus: query.fetchStatus,
        manager: query.data?.data?.response,
        club: query.data?.data?.response.club
    }
}
import { Segment } from "semantic-ui-react";
import {List} from "semantic-ui-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as tutorialTopics from '../tutorials';
import { Link } from "react-router-dom";
import SUIIconShim from "../../common/components/SUIIconShim";
import { useEffect } from "react";
import NarrowLayout from '../../common/components/NarrowLayout';

const sortOrder = {
    clubs: 8,
    finances: 1,
    fixtures: 9,
    league: 10,
    messages: 3,
    news: 2,
    players: 4,
    staff: 7,
    tactics: 5,
    training: 6,
    world: 12,
    intro: 0,
    transfers: 11
}

export default function TutorialMenu(props) {
    useEffect(() => {
        props.setToolbar({
            title: 'Tutorials',
            navigation: []
        });
    }, []);

    return(
        <NarrowLayout>
            <Segment>
                <p>Here is a list of tutorials to help you learn how to play the game</p>
            </Segment>
            <Segment>
                <List
                    divided
                    relaxed
                >
                    {Object.keys(tutorialTopics).sort((a, b) => sortOrder[a] - sortOrder[b]).map((topicKey) => (
                        <List.Item>
                            <List.Icon verticalAlign="middle">
                                <SUIIconShim>
                                    <FontAwesomeIcon icon={tutorialTopics[topicKey].icon} fixedWidth mask="fa-regular fa-circle" size='xl' />
                                </SUIIconShim>
                            </List.Icon>
                            <List.Content>
                                <List.Header>
                                    <Link to={tutorialTopics[topicKey].loaderPath}>{tutorialTopics[topicKey].name} tutorial</Link>
                                </List.Header>
                                <List.Description>
                                    <p>{tutorialTopics[topicKey].description}</p>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Segment>
        </NarrowLayout>
    );
}
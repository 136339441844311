import {default as generalMessages} from './messages/general.js';
import {default as clubMessages} from './messages/club.js';
import {default as transferMessages} from './messages/transfer.js';
import {default as contractMessages} from './messages/contract.js';
import {default as playerMessages} from './messages/player.js';
import {default as staffMessages} from './messages/staff.js';
import {default as scoutReportMessages} from './messages/scoutReport.js';
import {default as teamMessages} from './messages/team.js';
import {default as playerTransferOfferPaidMessages} from './messages/playerTransferOfferPaid.js';
import {default as playerTransferOfferLoanMessages} from './messages/playerTransferOfferLoan.js';
import {default as playerTransferAuctionFreeMessages} from './messages/playerTransferAuctionFree.js';
import {default as playerTransferAuctionPaidMessages} from './messages/playerTransferAuctionPaid.js';
import {default as staffTransferAuctionFreeMessages} from './messages/staffTransferAuctionFree.js';
import {default as shortlistMessages} from './messages/shortlist.js';

import {default as f442} from './formations/442.js';
import {default as f433} from './formations/433.js';
import {default as f4123} from './formations/4123.js';
import {default as f41212} from './formations/41212.js';
import {default as f541} from './formations/541.js';
import {default as f352} from './formations/352.js';
import {default as f451} from './formations/451.js';
import {default as f343_W} from './formations/343_W.js';
import {default as f4231} from './formations/4231.js';
import {default as f4321} from './formations/4321.js';
import {default as f343_N} from './formations/343_N.js';
import {default as f3412} from './formations/3412.js';
import {default as f4312} from './formations/4312.js';
import {default as f111} from './formations/111.js';

const formations = {
    '442': f442,
    '433': f433,
    '4123': f4123,
    '41212': f41212,
    '541': f541,
    '352': f352,
    '451': f451,
    '343_W': f343_W,
    '4231': f4231,
    '4321': f4321,
    '343_N': f343_N,
    '3412': f3412,
    '4312': f4312,
    '111': f111
}

const messages = {
    ...generalMessages,
    ...clubMessages,
    ...transferMessages,
    ...contractMessages,
    ...playerMessages,
    ...staffMessages,
    ...scoutReportMessages,
    ...teamMessages,
    ...playerTransferOfferPaidMessages,
    ...playerTransferOfferLoanMessages,
    ...playerTransferAuctionFreeMessages,
    ...playerTransferAuctionPaidMessages,
    ...staffTransferAuctionFreeMessages,
    ...shortlistMessages
}

export {messages, formations}
import axios from "axios";
import { useHistory, useLocation, useParams } from "react-router"
import { Loader } from "semantic-ui-react";
import LeagueSeason from ".";
import { useQuery } from '@tanstack/react-query';

export default function TeamLeagueSeasonResolver(props) {
    const location = useLocation();
    const history = useHistory();
    const {clubId, teamType} = useParams();

    const getTeamLeagueSeasonQuery = useQuery(
        ['getTeamLeagueSeason', clubId, teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/${teamType}/leagueSeasons`),
        {
            onSuccess: (data) => {
                if(location.state?.leagueSeasonId !== data.data.response.TLS[0].LS.id) {
                    history.replace(
                        {
                            pathname: location.pathname,
                            state: {...location.state, leagueSeasonId: data.data.response.TLS[0].LS.id},
                            search: location.search
                        }
                    )
                }
            }
        }
    );

    if(getTeamLeagueSeasonQuery.isLoading) return <Loader active />;

    return(
        <LeagueSeason
            {...props}
            leagueId={getTeamLeagueSeasonQuery.data?.data?.response.TLS[0].LS.league.id}
        />
    )
}
import axios from "axios";
import { useEffect } from "react";
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';

import TLSHistoryInterface from './interface';
import TeamName from "../../../../../team/common/TeamName.js";
import ClubProfileToolbarItem from "../../../toolbar/ClubProfile";
import TeamPlayersToolbarItem from "../../../toolbar/TeamPlayers";
import TeamFixturesToolbarItem from "../../../toolbar/TeamFixtures";
import ClubStaffToolbarItem from "../../../toolbar/ClubStaff";
import ClubTransfersToolbarItem from "../../../toolbar/ClubTransfers";
import TeamLeagueSeasonsToolbarItem from "../../../toolbar/TeamLeagueSeasons";
import TeamLeagueToolbarItem from "../../../toolbar/TeamLeague";
import TeamTacticsToolbarItem from "../../../toolbar/TeamTactics";
import TeamTrainingToolbarItem from "../../../toolbar/TeamTraining";
import { FormattedMessage } from "react-intl";

export default function TLSHistory(props) {
    const {clubId, teamType} = useParams();

    const {isLoading, data} = useQuery(
        ['getTeamLeagueSeasons', clubId, teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/${teamType}/leagueSeasons?includeActiveSeason=true&includeTLS=true`),
        {
            enabled: clubId !== undefined && teamType !== undefined
        }
    );

    useEffect(() => {
        if(data?.data?.response) {
            const isOwner = !!props.manager.club && (parseInt(clubId) === props.manager.club.id);

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{teamName: <TeamName team={data.data.response} />}} />,
                navigation: [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamTacticsToolbarItem isMyClub={isOwner} teamType={teamType} key={3} />,
                    <TeamTrainingToolbarItem isMyClub={isOwner} teamType={teamType} key={4} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            });
        }
    }, [clubId, teamType, data]);

    return(
        <TLSHistoryInterface
            isLoading={isLoading}
            TLS={data?.data?.response?.TLS}
        />
    )
}

const messages = {
    screenTitle: {
        id: 'todo',
        defaultMessage: '{teamName} history',
        description: 'todo'
    }
}
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/lib/paths";

export default {
    key: 'finances',
    name: 'Finances',
    loaderPath: URL.financesTutorial,
    icon: faCoins,
    description: 'View and adjust your transfer and wage budgets',
    steps: [
        {
            key: 0,
            path: URL.clubFinances,
            description: 'This is where you find out the quantities of money that the owner of the club is willing to throw at you.',
            summary: 'Have a look around',
            task: 'browse'
        },
        {
            key: 1,
            path: URL.clubFinances,
            description: 'If you want to change your transfer and wage budgets, select “change Allocation” to edit your budget',
            summary: 'Find the budget allocation button',
            task: 'interaction'
        },
        {
            key: 3,
            path: URL.clubFinances,
            description: 'Have a quick look around. If the board is willing to let you, move the blue slider from side to side to change your transfer and wage budget allocation.',
            summary: 'Assess budget allocation',
            task: 'browse'
        },
        {
            key: 2,
            path: URL.clubFinances,
            description: 'Well done! You have completed the tutorial',
            summary: 'Congrats'
        }
    ]
}
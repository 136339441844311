import {useState, useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useLocation} from 'react-router-dom';

import Players from '../toolbar/Players';
import Staff from '../toolbar/Staff';
import Scouting from '../toolbar/Scouting';
import History from '../toolbar/History';
import TransferHistoryInterface from './interface';
import NarrowLayout from '../../common/components/NarrowLayout';
import Auctions from '../toolbar/Auction';
import OffersNew from '../toolbar/Offers';

export default function TransferOffer(props) {
    const location = useLocation();

    const [selectedSeasonId, setSelectedSeasonId] = useState(null);

    const seasonQuery = useQuery(
        ['getSeasons'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/seasons?excludeFutureSeasons=true`),
        {
            onSuccess: (data) => {
                setSelectedSeasonId(data.data.response[0].id);
            },
            enabled: !selectedSeasonId
        }
    );

    const transferStatsQuery = useQuery(
        ['getTransferStats', selectedSeasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/transferOffers?seasonId=${selectedSeasonId}`),
        {
            enabled: !!selectedSeasonId
        }
    );

    useEffect(() => {
        props.setToolbar({
            title: 'Transfer stats',
            navigation: [
                <OffersNew />,
                <Players key={2} />,
                <Staff key={3} />,
                <Auctions key={7} />,
                <Scouting key={4} />,
                <History key={6} />
            ]
        })
    }, []);

    return(
        <NarrowLayout>
            <TransferHistoryInterface
                seasonQueryLoading={seasonQuery.isLoading}
                transferStatsQueryLoading={transferStatsQuery.isLoading}
                selectedSeasonId={selectedSeasonId}
                setSelectedSeasonId={setSelectedSeasonId}
                seasonData={seasonQuery.data?.data?.response}
                transferStatsData={transferStatsQuery.data?.data?.response}
            />
        </NarrowLayout>
    );
}
import {useEffect} from "react";

import Players from '../toolbar/Players';
import Staff from '../toolbar/Staff';
import History from '../toolbar/History';
import Scouting from '../toolbar/Scouting';

import FilterButton from "../../player/filter/button/interface.js";
import PlayerListTransferView from "../../player/lists/PlayerListTransferView.js";
import WideLayout from "../../common/components/WideLayout";
import { Button } from "semantic-ui-react";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
import useCompare from "../../player/compare/useCompare";

import {initialFilterState, isFilterActive, filterStateReducer} from '../../player/filter/lib';
import usePersistedState from "../../common/lib/usePersistedState";
import Auctions from "../toolbar/Auction";
import OffersNew from "../toolbar/Offers";
import useGetPlayers from "./useGetPlayers";

export default function TransferSearch(props) {
    const {persistedState, updatePersistedState} = usePersistedState('playerSearchFilter', initialFilterState);
    const {addPlayerIds} = useCompare();

    useEffect(() => {
        props.setToolbar({
            title: 'Transfer centre',
            navigation: [
                <OffersNew />,
                <Players key={2} />,
                <Staff key={3} />,
                <Auctions key={7} />,
                <Scouting key={4} />,
                <History key={6} />
            ]
        });
    }, []);

    const {isLoading, data} = useGetPlayers(persistedState, false, true);

    return (
        <WideLayout>
            <FilterButton
                loading={isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey='playerSearchFilter'
            />
            <Button
                disabled={isLoading}
                color='black'
                style={{backgroundColor: 'rgba(15, 16, 17)'}}
                floated='right'
                icon
                onClick={() => addPlayerIds(data.data.response.map((player) => player.id))}
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} transform={{rotate: 45}} />
                </SUIIconShim>
            </Button>
            <PlayerListTransferView
                isLoading={isLoading}
                players={data?.data?.response}
            />
            <Button.Group>
                <Button
                    icon
                    disabled={persistedState.page === 1}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </SUIIconShim>
                </Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.page}
                </Button>
                <Button
                    icon
                    disabled={data?.data?.response.length < 10}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </SUIIconShim>
                </Button>
            </Button.Group>
        </WideLayout>
    );
}

/*
class TransferSearch_data extends React.Component {
    constructor(props) {
        super(props);

        this.resultsPerPage = 15;

        this.state = {
            filterPositions: null,
            filterTransferListed: null,
            filterLoanListed: null,
            filterContractStatus: null,
            filterContractLength: null,
            filterNationality: null,
            filterValue: null,
            filterRating: null,
            savedFilters: [],
            currentFilter: null,
            players: null,
            loadedFilter: false,
            loadedPlayers: false,
            page: 1
        };
    }

    componentDidMount() {
        this.props.setToolbarTitle("Transfer search");
        this.props.setToolbarNavigation([
            <Players currentPath={this.location.pathname} key={1} />,
            <Shortlist currentPath={this.location.pathname} key={2} />
        ]);

        const filter = this.getLastFilter();

        this.setState({
            filterPositions: filter.filterPositions,
            filterTransferListed: filter.filterTransferListed,
            filterLoanListed: filter.filterLoanListed,
            filterContractStatus: filter.filterContractStatus,
            filterContractLength: filter.filterContractLength,
            filterNationality: filter.filterNationality,
            filterValue: filter.filterValue,
            filterRating: filter.filterRating,
            savedFilters: this.getSavedFilters(),
            loadedPlayers: false,
            loadedFilter: true
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if(
            JSON.stringify(prevState.filterPositions) !== JSON.stringify(this.state.filterPositions) ||
            prevState.filterTransferListed !== this.state.filterTransferListed ||
            prevState.filterLoanListed !== this.state.filterLoanListed ||
            prevState.filterContractStatus !== this.state.filterContractStatus ||
            JSON.stringify(prevState.filterContractLength) !== JSON.stringify(this.state.filterContractLength) ||
            prevState.filterNationality !== this.state.filterNationality ||
            JSON.stringify(prevState.filterValue) !== JSON.stringify(this.state.filterValue) ||
            JSON.stringify(prevState.filterRating) !== JSON.stringify(this.state.filterRating) ||
            prevState.page !== this.state.page
        ) {
            this.setState({
                players: this.applyFilter(),
                loadedPlayers: true
            });
        }
    }

    getSavedFilters() {
        // if(!user || !user.transferFilters || !user.transferFilters.savedFilters) {
        //     return [];
        // } else {
        //     return user.transferFilters.savedFilters;
        // }

        return [];
    }

    getLastFilter() {
        // if(!user || !user.transferFilters || !user.transferFilters.lastFilter) {
        //     return null;
        // } else {
        //     return user.transferFilters.lastFilter;
        // }

        return null;
    }

    applyFilter() {
        let players = false;

        let query = {};

        if(this.state.filterPositions.length !== 0) {
            query.position = {$in: this.state.filterPositions};
        }

        //query["status.transfer.transferListed"] = this.state.filter.transferListed;
        //query["status.transfer.loanListed"] = this.state.filter.loanListed;
        
        if(!this.state.filterContractStatus) {
            query.contract = null;
        }
    
        if(this.state.filterNationality !== null) {
            query.nationalityId = this.state.filterNationality;
        }

        const offset = this.state.page * this.resultsPerPage - this.resultsPerPage;

        console.error('TODO - fetch players')

        return players;
    }

    updateFilter(
        filterPositions,
        filterTransferListed,
        filterLoanListed,
        filterContractStatus,
        filterContractLength,
        filterNationality,
        filterValue,
        filterRating
    ) {
        //persist the filtero
        // db.getCollection("user").findAndUpdate(undefined, function(doc) {doc.transferFilters.lastFilter = {
        //     filterPositions: JSON.parse(JSON.stringify(filterPositions)),
        //     filterTransferListed: filterTransferListed,
        //     filterLoanListed: filterLoanListed,
        //     filterContractStatus: filterContractStatus,
        //     filterContractLength: filterContractLength,
        //     filterNationality: filterNationality,
        //     filterValue: filterValue,
        //     filterRating: filterRating
        // }; return doc;});

        this.setState({
            filterPositions: filterPositions,
            filterTransferListed: filterTransferListed,
            filterLoanListed: filterLoanListed,
            filterContractStatus: filterContractStatus,
            filterContractLength: filterContractLength,
            filterNationality: filterNationality,
            filterValue: filterValue,
            filterRating: filterRating,
            currentFilter: null
        });
    }

    handlePreviousPage() {
        if(this.state.page === 0) return;

        this.setState({
            page: this.state.page-1
        });
    }

    handleNextPage() {
        this.setState({
            page: this.state.page+1
        })
    }

    toggleShortlist(playerId) {
        console.error('TODO - toggle shortlist');

        this.setState({
            players: this.applyFilter()
        });
    }

    applySavedFilter(index) {
        this.setState(state => {
            return {
                ...state.savedFilters[index],
                currentFilter: index
            }
        });
    }

    saveFilter(name) {
        //persist the filter
        // db.getCollection("user").findAndUpdate(undefined, function(doc) {
        //     doc.transferFilters.savedFilters.push({
        //         name: name,
        //         filterPositions: JSON.parse(JSON.stringify(this.state.filterPositions)),
        //         filterTransferListed: this.state.filterTransferListed,
        //         filterLoanListed: this.state.filterLoanListed,
        //         filterContractStatus: this.state.filterContractStatus,
        //         filterContractLength: this.state.filterContractLength,
        //         filterNationality: this.state.filterNationality,
        //         filterValue: this.state.filterValue,
        //         filterRating: this.state.filterRating
        //     });
        //     return doc;
        // }.bind(this));

        this.setState({
            savedFilters: this.getSavedFilters()
        });
    }

    render() {
        return(
            <>
                <TransferSearch_Filter_interface
                    loading={!this.state.loadedFilter}
                    filterPositions={this.state.filterPositions}
                    filterTransferListed={this.state.filterTransferListed}
                    filterLoanListed={this.state.filterLoanListed}
                    filterContractStatus={this.state.filterContractStatus}
                    filterContractLength={this.state.filterContractLength}
                    filterNationality={this.state.filterNationality}
                    filterValue={this.state.filterValue}
                    filterRating={this.state.filterRating}
                    savedFilters={this.state.savedFilters}
                    currentFilter={this.state.currentFilter}
                    applySavedFilter={this.applySavedFilter.bind(this)}
                    saveFilter={this.saveFilter.bind(this)}
                    updateFilter={this.updateFilter.bind(this)} />
                <TransferSearch_PlayerList_interface
                    loading={!this.state.loadedPlayers}
                    players={this.state.players}
                    manager={this.props.manager}
                    toggleShortlist={this.toggleShortlist.bind(this)}
                    page={this.state.page}
                    handlePreviousPage={this.handlePreviousPage.bind(this)}
                    handleNextPage={this.handleNextPage.bind(this)} />
            </>
        )
    }
}*/
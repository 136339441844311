import axios from "axios";
import ViewPlayerTransferOfferPaidHistoryInterface from "./interface";
import NarrowLayout from "../../../common/components/NarrowLayout";
import { useParams } from "react-router";
import { useQuery } from '@tanstack/react-query';
import { useEffect } from "react";

export default function ViewPlayerTransferOfferLoanHistory(props) {
    const {playerTransferOfferLoanHistoryId} = useParams();

    const getPlayerTransferOfferLoanHistoryQuery = useQuery(
        ['getPlayerTransferOfferPaidHistory', playerTransferOfferLoanHistoryId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferOfferLoanHistory/${playerTransferOfferLoanHistoryId}`)
    );

    useEffect(() => {

    }, [getPlayerTransferOfferLoanHistoryQuery.data?.data?.response]);

    return(
        <NarrowLayout>
            <ViewPlayerTransferOfferPaidHistoryInterface
                isLoading={getPlayerTransferOfferLoanHistoryQuery.isLoading}
                playerTransferOfferLoanHistory={getPlayerTransferOfferLoanHistoryQuery.data?.data?.response}
                isMyPlayer={getPlayerTransferOfferLoanHistoryQuery.data?.data?.response.fromClub.id === props.manager.club.id}
            />
        </NarrowLayout>
    );
}
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import WorldCupsInterface from "./interface";
import WorldNationsToolbarItem from "../toolbar/WorldNationsToolbarItem";
import WorldClubsToolbarItem from "../toolbar/WorldClubsToolbarItem";
import WorldCupsToolbarItem from "../toolbar/WorldCupsToolbarItem";
import WorldRecordsToolbarItem from "../toolbar/WorldRecordsToolbarItem";
import { useEffect } from "react";
import NarrowLayout from "../../../common/components/NarrowLayout";

export default function WorldCups(props) {
    const getCupsQuery = useQuery(
        ['getAllCups'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cups`)
    );

    useEffect(() => {
        props.setToolbar({
            title: `All cups`,
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        });
    }, []);

    return(
        <NarrowLayout>
            <WorldCupsInterface
                isLoading={getCupsQuery.isLoading}
                cups={getCupsQuery.data?.data.response}
            />
        </NarrowLayout>
    );
}
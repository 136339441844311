import { FormattedMessage, defineMessages } from "react-intl";

export default function CupName(props) {
    if(!props.cup?.name) return null;

    return(
        <FormattedMessage {...messages[props.cup.name]} />
    )
}

const messages = defineMessages({
    mug_of_nations: {
        id: 'cups:mug_of_nations',
        defaultMessage: 'Mug of Nations',
        description: 'Name for the tournament where the best teams in the game compete against each other'
    }
})
import { useQuery } from '@tanstack/react-query';
import PlayerTransferAuctionPaidHistoryInterface from "./interface";
import { useParams } from "react-router";
import axios from "axios";
import { useMemo } from "react";

export default function PlayerTransferAuctionPaidHistory(props) {
    const {playerTransferAuctionPaidHistoryId} = useParams();

    const playerTransferAuctionPaidHistoryQuery = useQuery(
        ['getPlayerTransferAuctionPaidHistory', playerTransferAuctionPaidHistoryId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionPaidHistory/${playerTransferAuctionPaidHistoryId}`)
    );

    const myBid = useMemo(() => playerTransferAuctionPaidHistoryQuery.data?.data?.response.playerTransferAuctionBidPaidHistory.find((bid) => bid.clubId === props.manager.club.id),[playerTransferAuctionPaidHistoryQuery.data?.data?.response]);
    const winningBid = useMemo(() => playerTransferAuctionPaidHistoryQuery.data?.data?.response.playerTransferAuctionBidPaidHistory.find((bid) => bid.isWinner === true),[playerTransferAuctionPaidHistoryQuery.data?.data?.response]);

    return(
        <PlayerTransferAuctionPaidHistoryInterface
            isLoading={playerTransferAuctionPaidHistoryQuery.isLoading}
            playerTransferAuctionPaidHistory={playerTransferAuctionPaidHistoryQuery.data?.data?.response}
            myBid={myBid}
            winningBid={winningBid}
        />
    );
}
import { generatePath } from "react-router";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faListUl } from "@fortawesome/free-solid-svg-icons";

const messages = {
    text: {
        id: 'transfers:toolbar:offers',
        defaultMessage: 'Offers',
        description: 'Link for transfer offers in progress page'
    }
}

export default function OffersNew(props) {
    return(
        <ToolbarItem
            path={generatePath(URL.clubPlayerTransferOffers)}
            icon={faListUl}
            messages={messages}
            activeToolbarItemRef={props.activeToolbarItemRef}
        />
    )
}
import {Segment, Table, Placeholder, Header} from 'semantic-ui-react';
import { FormattedDate } from 'react-intl';
import times from 'lodash/times';
import sum from 'lodash/sum';
import PlayerLink from '../../player/common/Link';
import ClubLink from '../../club/components/common/Link';
import Component_RatingRoundall from '../../player/common/RatingRoundall2';
import FullNationNameWithFlag from '../common/FullNationNameWithFlag';

export default function NationInterface(props) {
    const numberOfLeagues = !props.nationQueryIsLoading ? sum(times(props.nation.nationLeagueLevels, (n) => Math.pow(2, n))) : undefined;

    return(
        <Segment>
            {!props.nationQueryIsLoading ?
                <Header as='h3'><FullNationNameWithFlag nation={props.nation} /></Header>
                :
                <Placeholder>
                    <Placeholder.Header>
                        <Placeholder.Line />
                    </Placeholder.Header>
                </Placeholder>
            }
            <Table basic='very' compact='very' unstackable>
                <Table.Body>
                <Table.Row>
                        <Table.Cell width={6}>Reputation</Table.Cell>
                        <Table.Cell width={8}>
                            {props.nationQueryIsLoading ?
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                                :
                                <Component_RatingRoundall rating={props.nation.reputationAbs} />
                            }
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={6}>Leagues</Table.Cell>
                        <Table.Cell width={8}>
                            {props.nationQueryIsLoading ?
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                                :
                                `${numberOfLeagues} (${props.nation.nationLeagueLevels} levels)`
                            }
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={6}>Clubs</Table.Cell>
                        <Table.Cell width={8}>
                            {props.nationQueryIsLoading ?
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                                :
                                numberOfLeagues*10
                            }
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={6}>Date added</Table.Cell>
                        <Table.Cell width={8}>
                            {props.nationQueryIsLoading ?
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                                :
                                <FormattedDate value={props.nation.createdAt} month='short' year='numeric' day='numeric' />
                            }
                        </Table.Cell>
                    </Table.Row>
                    {/* <Table.Row>
                        <Table.Cell width={6}>Best player</Table.Cell>
                        <Table.Cell width={8}>
                            {props.nationPlayersQueryIsLoading ?
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                                :
                                <PlayerLink player={props.nationBestPlayer[0]} />
                            }
                        </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell width={6}>Champions</Table.Cell>
                        <Table.Cell width={8}>
                            {props.nationClubLeagueSeasonsQueryIsLoading ?
                                <Placeholder>
                                    <Placeholder.Line length='full' />
                                </Placeholder>
                                :
                                props.nationTitleHolders ? <ClubLink club={props.nationTitleHolders} /> : 'None'
                            }
                        </Table.Cell>
                    </Table.Row> */}
                </Table.Body>
            </Table>
        </Segment>
    );
}
import { defineMessages } from '@formatjs/intl';

const messages = {
    'club:welcome': defineMessages({
        subject: {
            id: "messageSubject:club:welcome",
            defaultMessage: "Welcome to {clubName}",
            description: "Subject body of the system message template which a user will receive when they join a club. It's a welcome message from the club chairman."
        },
        body: {
            id: "messageBody:club:welcome",
            defaultMessage: "I'd like to take this opportunity to welcome you to {clubName}.\n\nOn behalf of the staff, players and supporters I wish you the best of luck in your time as manager of this great club, we look forward to great success under your leadership.",
            description: "Message body of the system message template which a user will receive when they join a club. It's a welcome message from the club chairman."
        }
    }),
    'club:leagueSeasonOutcome:champions': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeasonOutcome:champions',
            defaultMessage: '{teamName} league champions!',
            description: 'Message subject of the system message which a user receives if they finish as champions'
        },
        body: {
            id: 'messageBody:club:leagueSeasonOutcome:champions',
            defaultMessage: 'Congratulations on finishing as champions of {leagueName}!\n\nThe {teamName} fans are delighted with your performance this season and the owners are looking forward to more success in the future.\n\n',
            description: 'Message body of the system message which a user receives if they finish as champions'
        }
    }),
    'favClub:leagueSeasonOutcome:champions': defineMessages({
        subject: {
            id: 'messageSubject:favClub:leagueSeasonOutcome:champions',
            defaultMessage: '{teamName} league champions!',
            description: 'Message subject of the system message which a user receives when a favourite club finishes as champions'
        },
        body: {
            id: 'messageBody:favClub:leagueSeasonOutcome:champions',
            defaultMessage: '{teamName} have won the {leagueName} title!\n\nThe {teamName} fans are delighted with the performances this season and the owners are looking forward to more success in the future.\n\n',
            description: 'Message body of the system message which a user receives when a favourite club finishes as champions'
        }
    }),
    'club:leagueSeasonOutcome:promoted': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeasonOutcome:promoted',
            defaultMessage: '{teamName} promoted!',
            description: 'Message subject of the system message which a user receives if promoted'
        },
        body: {
            id: 'messageBody:club:leagueSeasonOutcome:promoted',
            defaultMessage: 'Congratulations on winning promotion from {leagueName}!\n\nThe {teamName} owners and fans are looking forward to seeing their team play in league {newLeagueLevel} next season.',
            description: 'Message body of the system message which a user receives if promoted'
        }
    }),
    'favClub:leagueSeasonOutcome:promoted': defineMessages({
        subject: {
            id: 'messageSubject:favClub:leagueSeasonOutcome:promoted',
            defaultMessage: '{teamName} promoted!',
            description: 'Message subject of the system message which a user receives when a favourite club is promoted'
        },
        body: {
            id: 'messageBody:favClub:leagueSeasonOutcome:promoted',
            defaultMessage: '{teamName} have won promotion from {leagueName}!\n\nThe {teamName} owners and fans are looking forward to seeing their team play in league {newLeagueLevel} next season.',
            description: 'Message body of the system message which a user receives when a favourite club is promoted'
        }
    }),
    'club:leagueSeasonOutcome:relegated': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeasonOutcome:relegated',
            defaultMessage: '{teamName} relegated',
            description: 'Message subject of the system message which a user receives if they are relegated'
        },
        body: {
            id: 'messageBody:club:leagueSeasonOutcome:relegated',
            defaultMessage: 'Unfortunately you have been relegated from {leagueName}.\n\nThe {teamName} fans are devastated that next season the club will be playing in league {newLeagueLevel}.',
            description: 'Message body of the system message which a user receives if they are relegated'
        }
    }),
    'favClub:leagueSeasonOutcome:relegated': defineMessages({
        subject: {
            id: 'messageSubject:favClub:leagueSeasonOutcome:relegated',
            defaultMessage: '{teamName} relegated',
            description: 'Message subject of the system message which a user receives when a favourite team is relegated'
        },
        body: {
            id: 'messageBody:favClub:leagueSeasonOutcome:relegated',
            defaultMessage: 'Unfortunately {teamName} have been relegated from {leagueName}.\n\nThe {teamName} fans are devastated that next season the club will be playing in league {newLeagueLevel}.',
            description: 'Message body of the system message which a user receives when a favourite team is relegated'
        }
    }),
    'club:leagueSeasonOutcome:promotionPlayOff': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeasonOutcome:promotionPlayOff',
            defaultMessage: '{teamName} promotion play-offs',
            description: 'Message subject of the system message which a user receives if they qualify for the promotion play offs'
        },
        body: {
            id: 'messageBody:club:leagueSeasonOutcome:promotionPlayOff',
            defaultMessage: 'Congratulations on {teamName} qualifying for the {leagueName} promotion play-offs.\n\n\The winner of the play-off final will gain promotion to league {newLeagueLevel}.\n\nFull details will be announced shortly. Good luck!',
            description: 'Message body of the system message which a user receives if they qualify for the promotion play offs'
        }
    }),
    'club:leagueSeasonOutcome:relegationPlayOff': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeasonOutcome:relegationPlayOff',
            defaultMessage: '{teamName} relegation play-offs',
            description: 'Message subject of the system message which a user receives if they qualify for the relegation play offs'
        },
        body: {
            id: 'messageBody:club:leagueSeasonOutcome:relegationPlayOff',
            defaultMessage: 'Unfortunately due to your final league position {teamName} must face the relegation play-off to secure safety in {leagueName} for next season.\n\nThe loser of the play-off final will be relegated to league {newLeagueLevel}. Full details will be announced shortly. Good luck!',
            description: 'Message body of the system message which a user receives if they qualify for the relegation play offs'
        }
    }),
    'club:leagueSeasonOutcome:safety': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeasonOutcome:safety',
            defaultMessage: '{teamName} final league position',
            description: 'Message subject of the system message which a user receives at the end of the season if do not finish in either promotion or relegation places (stay in league)'
        },
        body: {
            id: 'messageBody:club:leagueSeasonOutcome:safety',
            defaultMessage: '{teamName}\'s league season has now concluded. Your final position means you will continue to play in {leagueName} next season.',
            description: 'Message body of the system message which a user receives if do not finish in either promotion or relegation places (stay in league)'
        }
    }),
    'club:leagueSeason:playOffScheduled:promotion:final': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeason:playOffScheduled:promotion:final',
            defaultMessage: '{teamName} promotion play-off final',
            description: 'Message subject of the system message which a user receives when their play off final fixture has been scheduled'
        },
        body: {
            id: 'messageBody:club:leagueSeason:playOffScheduled:promotion:final',
            defaultMessage: 'The details for {teamName}\'s promotion play-off final have now been confirmed. You will play {opponent} on {fixtureDate}.\n\nIf you win the game, you will be promoted to league {newLeagueLevel}.',
            description: 'Message body of the system message which a user receives when their play off  final fixture has been scheduled'
        }
    }),
    'club:leagueSeason:playOffScheduled:relegation:final': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeason:playOffScheduled:relegation:final',
            defaultMessage: '{teamName} relegation play-off final',
            description: 'Message subject of the system message which a user receives when their relegation play off final fixture has been scheduled'
        },
        body: {
            id: 'messageBody:club:leagueSeason:playOffScheduled:relegation:final',
            defaultMessage: 'The details for {teamName}\'s relegation play-off final have now been confirmed. You will play {opponent} on {fixtureDate}.\n\nThe winner of the tie will secure their safety in this league for another season. The loser will be relegated to league {newLeagueLevel}.',
            description: 'Message body of the system message which a user receives when their relegation play off final fixture has been scheduled'
        }
    }),
    'club:leagueSeason:playOffOutcome:promotion:winner': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeason:playOffOutcome:promotion:winner',
            defaultMessage: '{teamName} promoted!',
            description: 'Message subject of the system message which a user receives if promoted after winning the play-off final'
        },
        body: {
            id: 'messageBody:club:leagueSeason:playOffOutcome:promotion:winner',
            defaultMessage: 'Congratulations on winning the promotion play-off final! {teamName} have been promoted from {leagueName}!\n\nThe owners and fans are looking forward to seeing their team play in league {newLeagueLevel} next season.',
            description: 'Message body of the system message which a user receives if promoted after winning the play-off final'
        }
    }),
    'favClub:leagueSeason:playOffOutcome:promotion:winner': defineMessages({
        subject: {
            id: 'messageSubject:favClub:leagueSeason:playOffOutcome:promotion:winner',
            defaultMessage: '{teamName} promoted!',
            description: 'Message subject of the system message which a user receives a favourite team is promoted after winning the play-off final'
        },
        body: {
            id: 'messageBody:favClub:leagueSeason:playOffOutcome:promotion:winner',
            defaultMessage: 'After winning their promotion play-off final today, {teamName} have won promotion from {leagueName}!\n\nThe owners and fans are looking forward to seeing their team play in league {newLeagueLevel} next season.',
            description: 'Message body of the system message which a user receives when a favourite team is promoted after winning the play-off final'
        }
    }),
    'club:leagueSeason:playOffOutcome:promotion:loser': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeason:playOffOutcome:promotion:loser',
            defaultMessage: '{teamName} promotion play-off final',
            description: 'Message subject of the system message which a user receives if they lose the promotion play-off final'
        },
        body: {
            id: 'messageBody:club:leagueSeason:playOffOutcome:promotion:loser',
            defaultMessage: 'Unfortunately, due to your defeat in the promotion play-off final, you will be competing in {leagueName} again next season. The fans hope that you can mount another promotion challenge next season.',
            description: 'Message body of the system message which a user receives if they lose the promotion play-off final'
        }
    }),
    'club:leagueSeason:playOffOutcome:relegation:winner': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeason:playOffOutcome:relegation:winner',
            defaultMessage: '{teamName} safety secured',
            description: 'Message subject of the system message which a user receives if they win their relegation play-off final (and stay in the league)'
        },
        body: {
            id: 'messageBody:club:leagueSeason:playOffOutcome:relegation:winner',
            defaultMessage: 'As a result of winning your relegation play-off final you have secured your safety in {leagueName} and you will compete in this league again next season.',
            description: 'Message body of the system message which a user receives if they win their relegation play-off final (and stay in the league)'
        }
    }),
    'club:leagueSeason:playOffOutcome:relegation:loser': defineMessages({
        subject: {
            id: 'messageSubject:club:leagueSeason:playOffOutcome:relegation:loser',
            defaultMessage: '{teamName} relegated',
            description: 'Message subject of the system message which a user receives if they are relegated after a relegation play-off final'
        },
        body: {
            id: 'messageBody:club:leagueSeason:playOffOutcome:relegation:loser',
            defaultMessage: 'As a result of your relegation play-off final defeat today you have unfortunately been relegated from {leagueName}.\n\nThe {teamName} fans are devastated that next season the club will be playing in league {newLeagueLevel}.',
            description: 'Message body of the system message which a user receives if they are relegated after a relegation play-off final'
        }
    }),
    'favClub:leagueSeason:playOffOutcome:relegation:loser': defineMessages({
        subject: {
            id: 'messageSubject:favClub:leagueSeason:playOffOutcome:relegation:loser',
            defaultMessage: '{teamName} relegated',
            description: 'Message subject of the system message which a user receives when a favourite team is relegated after a relegation play-off final'
        },
        body: {
            id: 'messageBody:favClub:leagueSeason:playOffOutcome:relegation:loser',
            defaultMessage: 'As a result of their relegation play-off final defeat today, {teamName} have unfortunately been relegated from {leagueName}.\n\nThe {teamName} fans are devastated that next season the club will be playing in league {newLeagueLevel}.',
            description: 'Message body of the system message which a user receives when a favourite team is relegated after a relegation play-off final'
        }
    }),
    'club:endOfLeagueSeason': defineMessages({
        subject: {
            id: 'messageSubject:club:endOfLeagueSeason',
            defaultMessage: 'End of season',
            description: 'Message subject of the system message which a user receives at the end of the season'
        },
        body: {
            id: 'messageBody:club:endOfLeagueSeason',
            defaultMessage: 'At the end of the season every club is awarded prize money for their league performance. This season a total of {prizePot} has been paid out to all clubs.\n\n{clubName} have been awarded {prizeMoney} in prize money for finishing in position {finalPosition} in {leagueName} this season.\n\nDetails about next season will be available shortly.',
            description: 'Message body of the system message which a user receives at the end of the season'
        }
    }),
    'club:newLeagueDetails': defineMessages({
        subject: {
            id: 'messageSubject:club:newLeagueDetails',
            defaultMessage: 'New league season begins for {teamName}',
            description: 'Message subject of the system message which a user receives at the start of the season which states which league the club will play in'
        },
        body: {
            id: 'messageBody:club:newLeagueDetails',
            defaultMessage: 'The new league season starts today. This season {teamName} will play in {leagueName}. Good luck!',
            description: 'Message body of the system message which a user receives at the start of the season which states which league the club will play in'
        }
    }),
    'club:playerContractExpiryReminder': defineMessages({
        subject: {
            id: 'messageSubject:club:playerContractExpiryReminder',
            defaultMessage: 'Player contract expiry reminder',
            description: 'Message subject of the message which a user receives to remind them of any of their players who have contracts expiring at the end of the season'
        },
        body: {
            id: 'messageSubject:club:playerContractExpiryReminder',
            defaultMessage: 'Just to remind you that the following players have contracts which expire at the end of this season.\n\nFirst team: {firstTeamPlayerNames}\n\nYouth team: {youthTeamPlayerNames}\n\nIf you want to keep them at the club then you should offer them a contract as soon as possible, otherwise they will leave the club at the end of the season on a free transfer.',
            description: 'Message subject of the message which a user receives to remind them of any of their players who have contracts expiring at the end of the season'
        }
    }),
    'club:staffContractExpiryReminder': defineMessages({
        subject: {
            id: 'messageSubject:club:staffContractExpiryReminder',
            defaultMessage: 'Staff contract expiry reminder',
            description: 'Message subject of the message which a user receives to remind them of any of their staff who have contracts expiring at the end of the season'
        },
        body: {
            id: 'messageSubject:club:staffContractExpiryReminder',
            defaultMessage: 'Just to remind you that the following staff have contracts which expire at the end of this season.\n\n{staffNames}\n\nIf you want to keep them at the club then you should offer them a contract as soon as possible, otherwise they will leave the club at the end of the season on a free transfer.',
            description: 'Message subject of the message which a user receives to remind them of any of their staff who have contracts expiring at the end of the season'
        }
    }),
    'club:seasonBudget': defineMessages({
        subject: {
            id: 'messageSubject:club:seasonBudget',
            defaultMessage: 'Season budget',
            description: 'Subject of message which user receives at the start of the season outlining their transfer and wage budget'
        },
        body: {
            id: 'messageBody:club:seasonBudget',
            defaultMessage: 'The board have confirmed the transfer and wage budgets for the upcoming season.\n\nTransfer budget: {transferBudget}\nWage budget: {wageBudget} ({currentWageBill} used)\n\nYou can adjust these budgets in the finances screen.',
            description: 'Body of message which user receives at the start of the season outlining their transfer and wage budget'
        }
    }),
    'club:cup:qualification': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:qualification',
            defaultMessage: '{cupName} qualification',
            description: 'Subject of message which user receives if they qualify for a cup'
        },
        body: {
            id: 'messageBody:club:cup:qualification',
            defaultMessage: 'Great news, {clubName} have qualified for this season\'s {cupName} tournament. The entry fee is {entryFee} which will be taken at the end of the tournament. The winner will receive a prize of {prizePool}. Click the button to see your first fixture.',
            description: 'Body of message which user receives if they qualify for a cup'
        }
    }),
    'favClub:cup:qualification': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:qualification',
            defaultMessage: '{clubName} qualify for {cupName}',
            description: 'Subject of message which user receives if their fav club qualifies for a cup'
        },
        body: {
            id: 'messageBody:club:cup:qualification',
            defaultMessage: '{clubName}, who are on your favourites, have qualified for this season\'s {cupName} tournament. Click the button to see their first fixture.',
            description: 'Body of message which user receives if their fav club qualifies for a cup'
        }
    }),
    'club:cup:nextRound': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:nextRound',
            defaultMessage: 'RE: {cupName}',
            description: 'Subject of message which user receives with news of the next cup round'
        },
        body: {
            id: 'messageBody:club:cup:knockedOut',
            defaultMessage: 'Following your win against {opponentClubName}, your club {myClubName} have qualified for the {nextRoundName} of {cupName}. Use the button below to find out who you will play next.',
            description: 'Body of message which user receives if their fav club qualifies for a cup'
        }
    }),
    'club:cup:knockedOut': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:knockedOut',
            defaultMessage: 'RE: {cupName}',
            description: 'Subject of message which user receives with news of the next cup round'
        },
        body: {
            id: 'messageBody:club:cup:knockedOut',
            defaultMessage: 'Following your defeat to {opponentClubName}, your club {myClubName} have been knocked out of the {cupName} and sadly you will not proceed to the {nextRoundName}.',
            description: 'Body of message which user receives if their fav club qualifies for a cup'
        }
    }),
    'club:cup:final:won': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:final:won',
            defaultMessage: 'RE: {cupName}',
            description: ''
        },
        body: {
            id: 'messageBody:club:cup:final:won',
            defaultMessage: 'Congratulations! Your team have won the prestigious {cupName}. The fans are delighted at this monumentous achievement, cementing the club\'s status as one of the best in the world.',
            description: ''
        }
    }),
    'club:cup:final:lost': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:final:lost',
            defaultMessage: 'RE: {cupName}',
            description: ''
        },
        body: {
            id: 'messageBody:club:cup:final:lost',
            defaultMessage: 'The fans are devastated that the team have fallen short of winning the {cupName}. Never the less, they are proud at the team\'s efforts and hope that the team can go one further next time.',
            description: ''
        }
    }),
    'club:cup:prizeMoney:no': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:prizeMoney:no',
            defaultMessage: '{cupName} finished',
            description: ''
        },
        body: {
            id: 'messageBody:club:cup:prizeMoney:no',
            defaultMessage: 'The {cupName} has now finished. Unfortunately you didn\'t win any prize money due to your early exit. Your entry fee of {entryFee} has been deducted from your balance.',
            description: ''
        }
    }),
    'club:cup:prizeMoney:yes': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:prizeMoney:yes',
            defaultMessage: '{cupName} prize money',
            description: ''
        },
        body: {
            id: 'messageBody:club:cup:prizeMoney:yes',
            defaultMessage: 'The {cupName} has now finished. You have been awarded {prizeMoney} in prize money for your performance, minus the entry fee of {entryFee} of course.',
            description: ''
        }
    }),
    'favClub:cup:final:won': defineMessages({
        subject: {
            id: 'messageSubject:club:cup:final:won',
            defaultMessage: '{clubName} wins {cupName}',
            description: ''
        },
        body: {
            id: 'messageBody:club:cup:final:won',
            defaultMessage: 'Your favourite club {clubName} have just won the {cupName}!',
            description: ''
        }
    }),
}

export default messages;
import {useEffect, useMemo, useState} from 'react';
import {Loader, Grid, Header, Segment, Dimmer, Form, Menu, Dropdown, Modal, Select, Button} from 'semantic-ui-react';
import filter from 'lodash/filter';
import clone from 'lodash/clone';
import useDimensions from 'react-cool-dimensions';
import {CSSTransition} from 'react-transition-group-react-18';

import pitch from './pitch.png';
import PlayerShirt from '../common/components/PlayerShirt';
import {formations} from 'shared-messages';
import RatingRoundall from '../player/common/RatingRoundall3';

import PlayerSelector from './PlayerSelector';
import ConfigureSubstitutions from './ConfigureSubstitutions';
import buildPitchFromLineup from '../common/lib/pitch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft, faArrowsUpToLine, faCheck, faChild, faCopyright, faPencil, faPlus, faSpinner, faTrash, faUser, faUserTie, faUsersLine, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import { generatePath, matchPath, useHistory, useLocation, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { URL } from '../common/lib/paths';
import SUIIconShim from '../common/components/SUIIconShim';
import {useTutorial} from '../tutorial/hooks/useTutorial';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import ShortenedPlayerName from '../player/common/ShortenedName';
import colours from '../common/colours';
import ManageTactics from './ManageTactics';

const styles = {
    container: {
        position: 'relative',
        overflow: 'hidden'
    },
    pitch: {
        width: '100%',
        position: 'absolute'
    },
    pitchLoading: {
        width: '100%'
    },
    row: {
        padding: 0
    },
    column: {
        // paddingLeft: '0.5rem',
        // paddingRight: '0.5rem'
    },
    ratingIcon: {
        flex: '0 1 auto',
        margin: '0 7px 0 0',
        alignSelf: 'center'
    },
    icon: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#FFF',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '0.5em'
    },
    iconLast: {
        marginRight: 0
    },
    header: {
        margin: '1em 0 0 0',
        backgroundColor: 'rgb(27 29 30)',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    subBench: {
        //backgroundColor: '#000', //'#009444',//'#39b54a',
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        //marginTop: '1em',
        //borderTop: '10px solid #000'
    }
}

export default function TacticsInterface(props) {
    const {teamType} = useParams();
    const location = useLocation();
    const history = useHistory();
    const { observe, height } = useDimensions();
    const {checkTutorial, goNextStep, getSearchParamsString, tutorialStep} = useTutorial();

    const handleShirtClick = (positionIndex, playerId, teamTacticPositionIndex) => {
        let search;

        if(
            props.teamTactic.teamTacticPositions[teamTacticPositionIndex].pitchSection === 12 && isTutorial2// ||
            // teamTacticPosition.pitchSection === 11 && isTutorial11
        ) {
            search = getSearchParamsString('tactics', tutorialStep+1)
        }

        history.push({
            pathname: location.pathname,
            search,
            state: {
                ...location.state,
                selectForTeamTacticPositionIndex: teamTacticPositionIndex
            }
        });
    }

    const cancelPlayerSelector = (nextTutorialStep = false) => {
        history.replace({
            pathname: location.pathname,
            search: nextTutorialStep ? getSearchParamsString('tactics', tutorialStep+1) : location.search,
            state: {
                ...location.state,
                selectForTeamTacticPositionIndex: false
            }
        });
    }

    function handleSelectPlayer(teamTacticPositionId, playerRegId, nextTutorialStep) {
        props.handleSelectPlayer(teamTacticPositionId, playerRegId);
        cancelPlayerSelector(nextTutorialStep);
    }

    function handleConfigureSubstitutions() {
        const search = isTutorial5 ? getSearchParamsString('tactics', tutorialStep+1) : location.search;

        history.replace({
            pathname: location.pathname,
            search,
            state: {
                ...location.state,
                configureSubstitutions: true
            }
        });
    }

    function cancelConfigureSubstitutions() {
        history.replace({
            pathname: location.pathname,
            search: location.search,
            state: {
                ...location.state,
                configureSubstitutions: false
            }
        });
    }

    function handleDeleteLineup() {
        props.handleDeleteLineup();

        isTutorial3 && goNextStep();
    }

    function handleLineupFix() {
        props.handleFixLineup();

        isTutorial4 && goNextStep();
    }

    function handleDelegateTactics() {
        props.handleDelegate(isTutorial8 ? true : !props.delegateTactics);

        isTutorial8 && goNextStep();
    }

    function handleSetRoles(open) {
        const search = isTutorial9 || isTutorial10 ? getSearchParamsString('tactics', tutorialStep+1) : location.search;

        history.replace({
            pathname: location.pathname,
            search,
            state: {
                ...location.state,
                configureRoles: open
            }
        });
    }

    const isTutorial1 = checkTutorial('tactics', 0);
    const isTutorial2 = checkTutorial('tactics', 1);
    const isTutorial3 = checkTutorial('tactics', 5);
    const isTutorial4 = checkTutorial('tactics', 6);
    const isTutorial5 = checkTutorial('tactics', 8);
    const isTutorial6 = checkTutorial('tactics', 10);
    const isTutorial7 = checkTutorial('tactics', 11);
    const isTutorial8 = checkTutorial('tactics', 12);
    const isTutorial9 = checkTutorial('tactics', 14);
    const isTutorial10 = checkTutorial('tactics', 16);
    const isTutorial11 = checkTutorial('tactics', 7);

    const pitchMap = useMemo(() => {
        if(!props.teamTactic?.teamTacticPositions.length) return [];

        return buildPitchFromLineup(
            resolveToPitch(props.teamTactic.teamTacticPositions),
            undefined,
            undefined,
            handleShirtClick.bind(this),
            false,
            isTutorial2 ? 0 : undefined
        )
    }, [props.teamTactic, isTutorial2]);

    const averagePlayerRating = useMemo(() => {
        if(!props.teamTactic) return 0;

        const r = props.teamTactic.teamTacticPositions.reduce((sum, position) => {
            if(!position.playerReg || position.pitchSection === null) return sum;
            return sum+=position.playerReg.playerContract.player.effectiveRating || position.playerReg.playerContract.player.currentAbility || 0
        }, 0);

        return Math.round(r/11);
    }, [props.teamTactic]);

    const configureSubstitutions = location.state?.configureSubstitutions || false;
    const configureRoles = location.state?.configureRoles || false;

    return(
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Menu
                    inverted
                    icon
                    floated='left'
                    size='tiny'
                >
                    <Menu.Item
                        as={Link}
                        to={{pathname: generatePath(URL.teamTactics, {teamType: 'first'})}}
                        active={matchPath(location.pathname, {path: generatePath(URL.teamTactics, {teamType: 'first'})})}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faUser} />
                        </SUIIconShim>
                    </Menu.Item>
                    <Menu.Item
                        as={Link}
                        to={{pathname: generatePath(URL.teamTactics, {teamType: 'youth'}), search: isTutorial7 && getSearchParamsString('tactics', tutorialStep+1)}}
                        active={matchPath(location.pathname, {path: generatePath(URL.teamTactics, {teamType: 'youth'})})}
                        className={isTutorial7 && 'glow'}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faChild} />
                        </SUIIconShim>
                    </Menu.Item>
                </Menu>
                <Menu
                    inverted
                    icon
                    floated='right'
                    size='tiny'
                >
                    {teamType === 'youth' &&
                        <Menu.Item
                            active={props.delegateTactics}
                            onClick={handleDelegateTactics.bind(this)}
                            className={isTutorial8 && 'glow'}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faUserTie} />
                            </SUIIconShim>
                        </Menu.Item>
                    }
                    <Menu.Item
                        onClick={() => handleSetRoles(true)}
                        disabled={props.delegateTactics}
                        className={isTutorial9 && 'glow'}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faCopyright} />
                        </SUIIconShim>
                    </Menu.Item>
                </Menu>
            </div>
            <Header inverted attached='top' style={styles.header}>
                {/*<span style={{flex: '1 1 auto', alignSelf: 'center'}}></span>*/}
                <div style={{flex: '1 1 auto', alignSelf: 'center'}}>
                    <Dropdown
                        text={`Tactic ${props.teamTactics?.[props.activeTeamTacticIndex]?.name}`}
                    >
                        <Dropdown.Menu>
                            {props.teamTactics?.map((teamTactic) => (
                                <Dropdown.Item
                                    onClick={() => props.handleSetActiveTeamTactic(teamTactic.id)}
                                >
                                    <span style={{fontWeight: teamTactic.isActive ? 'bold' : 'normal'}}>Tactic {teamTactic.name}</span>
                                    {teamTactic.isActive === true ?
                                        <FontAwesomeIcon icon={faCheck} color={colours.positive} className='inlineIconMirror' />
                                        :
                                        null
                                    }
                                </Dropdown.Item>
                            ))}
                            <Dropdown.Divider style={{margin: 0}} />
                            <Dropdown.Item
                                onClick={() => history.replace({pathname: location.pathname, state: {...location.state, manageTacticsModal: true}})}
                            >
                                <SUIIconShim>
                                    <FontAwesomeIcon icon={faPencil} />
                                </SUIIconShim>
                                Manage
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div style={{flex: '0 0 auto', display: 'flex'}}>
                    <RatingRoundall rating={averagePlayerRating} style={styles.ratingIcon} />
                    <span
                        style={styles.icon}
                        className={isTutorial5 && 'glow'}
                        onClick={handleConfigureSubstitutions.bind(this)}
                    >
                        <FontAwesomeIcon color='black' icon={faArrowRightArrowLeft} className='iconHover' />
                    </span>
                    <span
                        style={styles.icon}
                        className={isTutorial3 && 'glow'}
                        onClick={handleDeleteLineup.bind(this)}
                    >
                        {props.deleteLineupMutationIsLoading ?
                            <FontAwesomeIcon color='black' icon={faSpinner} spin />
                            :
                            <FontAwesomeIcon color='black' icon={faTrash} className='iconHover' />
                        }
                    </span>
                    <span
                        style={{...styles.icon, ...styles.iconLast}}
                        className={isTutorial4 && 'glow'}
                        onClick={handleLineupFix.bind(this)}
                    >
                        {props.fixLineupMutationIsLoading ?
                            <FontAwesomeIcon color='black' icon={faSpinner} spin />
                            :
                            <FontAwesomeIcon color='black' icon={faWandMagicSparkles} className='iconHover' />
                        }
                    </span>
                </div>
            </Header>
            <Segment attached style={{padding: 0, margin: 0, width: '100%', overflow: 'auto', borderRadius: '0 0 .28571429rem .28571429rem', backgroundColor: 'rgb(27 29 30)'}}>
                <Dimmer active={props.delegateTactics} />
                <div style={styles.container}>
                    <img src={pitch} style={props.getTeamTacticQueryIsLoading ? styles.pitchLoading : styles.pitch} ref={observe} />
                    {props.getTeamTacticQueryIsLoading ?
                        <Loader active />
                        :
                        <Grid style={{overflow: 'hidden', width: '100%', margin: 0, height: `${height}px`}} textAlign='center'>
                            {!!pitchMap && pitchMap.map((row) => {
                                if(row.length === 0) return null

                                return(
                                    <Grid.Row style={styles.row} columns={row.length} verticalAlign='bottom'>
                                        {row}
                                    </Grid.Row>
                                );
                            })}
                        </Grid>
                    }
                </div>
                <div style={styles.subBench}>
                    {props.getTeamTacticQueryIsLoading ?
                        <Loader active />
                        :
                        <Grid style={{overflow: 'hidden', width: '100%', margin: 0}}>
                            <Grid.Row columns={5} centered>
                                {props.teamTactic.teamTacticPositions.reduce((subs, position, i) => {
                                    if(position.pitchSection !== null) return subs;
                                    
                                    subs.push(
                                        <PlayerShirt
                                            glow={isTutorial11}
                                            position={position.playerReg?.playerContract.player.position}
                                            playerNaturalPosition={position.playerReg?.playerContract.player.position}
                                            occupied={!!position.playerReg}
                                            condition={position.playerReg?.playerContract.player.condition}
                                            mentality='standard'
                                            injured={false}
                                            playerName={position.playerReg?.playerContract.player.person.lastName}
                                            rating={position.playerReg?.playerContract.player.currentAbility}
                                            reverse={false}                    
                                            handleShirtClick={handleShirtClick.bind(this, undefined, undefined, i)}
                                        />
                                    );

                                    return subs;
                                }, [])}
                            </Grid.Row>
                            <Header as='h5' style={{margin: '-1em 0 0.5em 0', width: '100%', textTransform: 'uppercase', color: 'rgba(255, 255, 255, 0.9)', textAlign: 'center'}}>Substitutes</Header>
                        </Grid>
                    }
                </div>
            </Segment>
            {!props.getTeamTacticQueryIsLoading &&
                <>
                    <CSSTransition
                        in={typeof location.state?.selectForTeamTacticPositionIndex === 'number'}
                        timeout={300}
                        classNames='modalOverlay'
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <PlayerSelector
                            selectForPosition={namedPositions[props.teamTactic.teamTacticPositions[location.state?.selectForTeamTacticPositionIndex]?.pitchSection] || null}
                            selectForTeamTacticPositionIndex={location.state?.selectForTeamTacticPositionIndex}
                            teamTactic={props.teamTactic}
                            cancelPlayerSelector={cancelPlayerSelector.bind(this)}
                            clubId={props.clubId}
                            managerId={props.managerId}
                            handleSelectPlayer={handleSelectPlayer}
                            initialFilterPosition={props.teamTactic.teamTacticPositions[location.state?.selectForTeamTacticPositionIndex]?.playerReg?.playerContract.player.position || namedPositions[props.teamTactic.teamTacticPositions[location.state?.selectForTeamTacticPositionIndex]?.pitchSection] || 'GK'}
                        />
                    </CSSTransition>
                    <CSSTransition
                        in={configureSubstitutions}
                        timeout={300}
                        classNames='modalOverlay'
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <ConfigureSubstitutions
                            cancelConfigureSubstitutions={cancelConfigureSubstitutions}
                            lineups={props.playerRegistrations}
                            teamTacticId={props.teamTactic.id}
                            clubId={props.clubId}
                            teamType={teamType}
                        />
                    </CSSTransition>
                    <CSSTransition
                        in={configureRoles}
                        timeout={300}
                        classNames='modalOverlay'
                        mountOnEnter={true}
                        unmountOnExit={true}
                    >
                        <ConfigureRoles
                            lineups={props.playerRegistrations}
                            cancelConfigureRoles={() => handleSetRoles(false)}
                            clubId={props.clubId}
                            teamType={teamType}
                        />
                    </CSSTransition>
                </>
            }
            {typeof props.activeTeamTacticIndex === 'number' && !!props.teamTactics ?
                <ManageTactics
                    activeTeamTacticIndex={props.activeTeamTacticIndex}
                    teamTactics={props.teamTactics}
                    handleSetActiveTeamTactic={props.handleSetActiveTeamTactic}
                />
                :
                null
            }
        </div>
    );
}

function resolveToPitch(teamTacticPositions) {
    const pitchPlayers = [];

    for(let i=0; i<teamTacticPositions.length; i++) {
        pitchPlayers.push({
            pitchHalfSectionIndex: teamTacticPositions[i].pitchSection,
            player: teamTacticPositions[i].playerReg?.playerContract.player,
            position: namedPositions[teamTacticPositions[i].pitchSection],
            teamTacticPositionIndex: i
        });
    }
    
    return pitchPlayers;
}

function ConfigureRoles(props) {
    const [captainPlayerRegId, setCaptainPlayerRegId] = useState();
    const [penaltyTakerPlayerRegId, setPenaltyTakerPlayerRegId] = useState();
    const [setPieceTakerPlayerRegId, setSetPieceTakerPlayerRegId] = useState();
    const queryClient = useQueryClient();
    const {checkTutorial, goNextStep, getSearchParamsString, tutorialStep} = useTutorial();

    const isTutorial1 = checkTutorial('tactics', 16);

    const {isLoading, data} = useQuery(
        ['getSquad'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.clubId}/teams/${props.teamType}/playerRegistrations?ownershipClaim=true&context=list`)
    );

    const setIsCaptain = useMutation(
        () => axios.patch(`${process.env.REACT_APP_APPHOST}/playerRegistrations/${captainPlayerRegId}`, {
            isCaptain: true
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries('getSquad');
            }
        }
    );

    const setIsPenaltyTaker = useMutation(
        () => axios.patch(`${process.env.REACT_APP_APPHOST}/playerRegistrations/${penaltyTakerPlayerRegId}`, {
            isPenaltyTaker: true
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries('getSquad');
            }
        }
    );

    const setIsSetPieceTaker = useMutation(
        () => axios.patch(`${process.env.REACT_APP_APPHOST}/playerRegistrations/${setPieceTakerPlayerRegId}`, {
            isSetPieceTaker: true
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries('getSquad');
            }
        }
    );

    useEffect(() => {
        if(!data?.data?.response.playerReg) return;

        if(captainPlayerRegId === undefined) {
            const c = data.data.response.playerReg.find((lineup) => lineup.isCaptain === true)?.id;

            setCaptainPlayerRegId(c || null);
        }

        if(penaltyTakerPlayerRegId === undefined) {
            const p = data.data.response.playerReg.find((lineup) => lineup.isPenaltyTaker === true)?.id;

            setPenaltyTakerPlayerRegId(p || null);
        }

        if(setPieceTakerPlayerRegId === undefined) {
            const s = data.data.response.playerReg.find((lineup) => lineup.isSetPieceTaker === true)?.id;

            setSetPieceTakerPlayerRegId(s || null);
        }
    }, [data?.data?.response.playerReg]);

    function handleSetValue(value, setter) {
        if(!value) {
            setter(null);
        } else {
            setter(value);
        }
    }

    return(
        <Modal
            open={true}
            onClose={props.cancelConfigureRoles}
            size='small'
        >
            <Modal.Header className='headerFixPadding'>
                Roles
            </Modal.Header>
            <Modal.Content>
                <Form>
                    <Form.Field
                        control={Select}
                        label='Captain'
                        options={data?.data?.response.playerReg.map((lineup) => (
                            {
                                value: lineup.id,
                                text: (<ShortenedPlayerName player={lineup.playerContract.player} />),
                                name: lineup.id
                            }
                        ))}
                        placeholder='Select a player'
                        value={captainPlayerRegId}
                        loading={isLoading}
                        onChange={(e, {value}) => handleSetValue(value, setCaptainPlayerRegId)}
                        selectOnBlur={false}
                    />
                    <Form.Field
                        control={Select}
                        label='Penalties'
                        options={data?.data?.response.playerReg.map((lineup) => (
                            {
                                value: lineup.id,
                                text: (<ShortenedPlayerName player={lineup.playerContract.player} />),
                                name: lineup.id
                            }
                        ))}
                        placeholder='Select a player'
                        value={penaltyTakerPlayerRegId}
                        loading={isLoading}
                        onChange={(e, {value}) => handleSetValue(value, setPenaltyTakerPlayerRegId)}
                        selectOnBlur={false}
                    />
                    <Form.Field
                        control={Select}
                        label='Set pieces'
                        options={data?.data?.response.playerReg.map((lineup) => (
                            {
                                value: lineup.id,
                                text: (<ShortenedPlayerName player={lineup.playerContract.player} />),
                                name: lineup.id
                            }
                        ))}
                        placeholder='Select a player'
                        value={setPieceTakerPlayerRegId}
                        loading={isLoading}
                        onChange={(e, {value}) => handleSetValue(value, setSetPieceTakerPlayerRegId)}
                        selectOnBlur={false}
                    />
                </Form>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={props.cancelConfigureRoles}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => handleSave()}
                    positive
                    className={isTutorial1 ? 'glow' : ''}
                >
                    Save
                </Button>
            </Modal.Actions>
        </Modal>
    );

    function handleSave() {
        if(!!captainPlayerRegId) {
            setIsCaptain.mutate();
        }

        if(!!penaltyTakerPlayerRegId) {
            setIsPenaltyTaker.mutate();
        }

        if(!!setPieceTakerPlayerRegId) {
            setIsSetPieceTaker.mutate();
        }

        props.cancelConfigureRoles();
    }
}

const namedPositions = {
    1: 'LB',
    7: 'CB',
    12: 'GK',
    13: 'CB',
    19: 'CB',
    25: 'RB',
    2: 'LWB',
    8: 'CDM',
    14: 'CDM',
    20: 'CDM',
    26: 'RWB',
    3: 'LM',
    9: 'CM',
    15: 'CM',
    21: 'CM',
    27: 'RM',
    4: 'LW',
    10: 'CAM',
    16: 'CAM',
    22: 'CAM',
    28: 'RW',
    11: 'ST',
    17: 'ST',
    23: 'ST'
}
import { FormattedDate } from "react-intl";

function SeasonName(props) {
    return(
        <FormattedDate value={props.season.startDate} timeZone="UTC" day='2-digit' month="short" year='2-digit' />
    )
}

function getSeasonName(intl, season) {
    return intl.formatDate(season.startDate, {day: '2-digit', month: 'short', year: '2-digit'});
}

export {
    SeasonName,
    getSeasonName
}
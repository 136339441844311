import {useQuery} from '@tanstack/react-query';
import axios from "axios";
import {ResponsiveContainer, LineChart, Line, Legend, YAxis, XAxis} from 'recharts';
import {Loader} from 'semantic-ui-react';

export default function TrainingChart(props) {
    if(!props.playerId) throw new Error('missing playerId');

    const {isLoading, error, data} = useQuery(['getPlayerRatingHistory', props.playerId], () =>
        axios.get([process.env.REACT_APP_APPHOST, 'players', props.playerId, 'ratingHistory'].join('/'))
    );

    if(error) {
        throw new Error(`API Error: ${error}`);
    }

    console.log(data);

    if(isLoading) return(
        <Loader active />
    )

    return(
        <ResponsiveContainer height={200}>
            <LineChart data={data?.data?.response} margin={{right: 10}}>
                <Line name='Overall' type="monotone" dataKey="currentAbility" stroke="#1B1C1D" strokeWidth={2} />
                <Line name='Defence' type="monotone" dataKey="defendRating" stroke="#DA2828" strokeWidth={2} />
                <Line name='Attack' type="monotone" dataKey="createRating" stroke="#21BA45" strokeWidth={2} />
                <Line name='Finishing' type="monotone" dataKey="finishRating" stroke="#2185D0" strokeWidth={2} />
                <YAxis width={35} domain={[25, 100]} ticks={[25, 50, 75, 100]} />
                <Legend wrapperStyle={{paddingTop: '10px', paddingBottom: '10px'}} />
            </LineChart>
        </ResponsiveContainer>
    );
}
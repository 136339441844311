import {useIntl} from "react-intl";
import { Form } from "semantic-ui-react";
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

export default function SelectLeagueSeason(props) {	
	const intl = useIntl();

    const leagueSeasonsQuery = useQuery(
        ['getAllLeagueSeasons', props.leagueId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagues/${props.leagueId}/leagueSeasons`),
        {
            enabled: props.leagueId !== undefined,
            onSuccess: (data) => {
                if(props.selectedLeagueSeasonId === undefined) {
                    props.setSelectedLeagueSeasonId(data.data.response.LS[0].id)
                }
            }
        }
    );

	return(
        <Form.Select
            options={leagueSeasonsQuery.data?.data?.response ? leagueSeasonsQuery.data?.data?.response.LS.map((leagueSeason) => (
                {
                    key: leagueSeason.id,
                    text: intl.formatDate(leagueSeason.season.startDate, {day: 'numeric', month: 'short', year: 'numeric'}),
                    value: leagueSeason.id
                }
            )) : []}
            value={props.selectedLeagueSeasonId}
            loading={leagueSeasonsQuery.isLoading}
            disabled={leagueSeasonsQuery.isLoading}
            onChange={
                (e, {value}) => props.setSelectedLeagueSeasonId(value)
            }
            label='Season'
            fluid
        />
	);
}

import {Card, Image, Button, Header, Segment, Message, Placeholder, Dropdown} from 'semantic-ui-react';
import dayjs from 'dayjs';
import random from 'lodash/random';
import { useHistory, generatePath, useLocation } from 'react-router-dom';

import Currency from "../../../../common/components/Currency";
import LeagueLink from '../../../../league/components/common/Link';
import {URL} from '../../../../common/lib/paths';
import {default as ClubLink} from '../../../../club/components/common/Link';
import SUIIconShim from '../../../../common/components/SUIIconShim';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuffle } from '@fortawesome/free-solid-svg-icons';
import NationGroupNameWithFlag from '../../../../nationGroup/common/NationGroupNameWithFlag';
import FullNationNameWithFlag from '../../../../nation/common/FullNationNameWithFlag';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function FirstJobInterface(props) {
    const history = useHistory();
    const location = useLocation();

    const nationQuery = useQuery(
        ['getAllNationGroups'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nationGroups`)
    );

    const handleAcceptJob = (clubId) => {
        props.handleAcceptJob(clubId);
    }

    function handleFilterByNation(nationGroupShortcode) {
        history.replace({
            pathname: location.pathname,
            search: location.search,
            state: {
                ...location.state,
                nationGroupShortcode
            }
        });
    }

    return(
        <>
            <Segment>
                <Header as='h4'>Welcome to Manager.Soccer, <span style={{fontWeight: 'bold'}}>{props.managerName}</span>!</Header>
                <p>Now it's time to choose your first club.</p>
                <p>You'll start out in the lowest division and work your way up.</p>
                <p>Moving clubs is part of the game, so don't worry if your favourite isn't available.</p>
            </Segment>
            <Dropdown
                selection
                fluid
                placeholder='Filter by nation'
                clearable
                loading={nationQuery.isLoading}
                selectOnBlur={false}
                options={nationQuery.data?.data.response.map((nationGroup) => ({
                    key: nationGroup.shortcode,
                    text: <NationGroupNameWithFlag shortcode={nationGroup.shortcode} responsiveClass={'nationFlag'} />,
                    value: nationGroup.shortcode
                }))}
                value={location.state?.nationId}
                onChange={(e, {value}) => handleFilterByNation(value)}
            />
            {props.jobs?.length === 0 ? 
                <Message negative>
                    <p>There are no jobs available, please check back later</p>
                </Message>
                :
                null
            }
            {props.isLoading ?
                [1, 2, 3].map((index) => 
                    <Card fluid key={index}>
                        <Card.Content>
                            <Placeholder>
                                <Placeholder.Header>
                                    <Placeholder.Line length='medium' />
                                </Placeholder.Header>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line length='very short' />
                                    <Placeholder.Line length='short' />
                                    <Placeholder.Line length='very short' />
                                </Placeholder.Paragraph>
                                <Placeholder.Paragraph>
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                    <Placeholder.Line />
                                </Placeholder.Paragraph>
                            </Placeholder>
                        </Card.Content>
                        <Card.Content extra>
                            <Button disabled={true} positive fluid>Accept job</Button>
                        </Card.Content>
                    </Card>
                )
                :
                props.jobs.map((job) => (
                    <Card fluid key={job.club.id}>
                        <Card.Content>
                            <Image
                                src={`${process.env.PUBLIC_URL}/crests/${job.club.badgeNumber}.png`}
                                floated='right'
                                size='mini'
                                style={{
                                    margin: 0, width: '55px',
                                    filter: 'drop-shadow(1px 1px 3px #000)'
                                }}
                            />
                            <Card.Header style={{display: 'flex', alignItems: 'center'}}><ClubLink club={job.club} nation={false} rating={true} /></Card.Header>
                            <Card.Meta>
                                <p style={{margin:0}}><FullNationNameWithFlag nation={job.club.nation} /></p>
                                <p style={{margin:0}}><LeagueLink league={job.club.league} /></p>
                                <p style={{margin:0}}>Balance: <Currency value={job.club.balance} /></p>
                            </Card.Meta>
                            <Card.Description>
                                <p>{job.club.name} have offered you a <span style={{fontWeight: 'bold'}}>{job.contractLength} season contract</span> with a salary of <span style={{fontWeight: 'bold'}}><Currency value={job.contractSalary} /> per week</span>.</p>
                                <p>{job.club.name} were established in the {dayjs.utc(job.club.createdAt).format('MMM-YY')} season.</p>
                            </Card.Description>
                        </Card.Content>
                        <Card.Content extra>
                            <Button loading={props.isSubmitting} disabled={props.isSubmitting} onClick={handleAcceptJob.bind(this, job.club.id)} positive fluid>Accept job</Button>
                        </Card.Content>
                    </Card>
                ))
            }
            <Button
                disabled={props.isSubmitting || props.isLoading}
                onClick={() => history.push(generatePath(URL.jobs), {...location.state, index: random(1, 21)})}
                icon
                fluid
                labelPosition='left'
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={faShuffle} className='buttonIconFix' />
                </SUIIconShim>
                Show me 3 different clubs
            </Button>
        </>
    );
}
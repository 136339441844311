import axios from "axios";
import { useEffect } from "react";
import {useQuery} from '@tanstack/react-query';
import {useParams, useLocation} from 'react-router-dom';
import PlayerHistoryInterface from "./interface";

import Profile from "../toolbar/Profile.js";
import History from "../toolbar/History.js";
import TransferOffer from "../toolbar/Transfer.js";
import ContractOffer from "../toolbar/Contract.js";
import ShortenedName from '../common/ShortenedName';

export default function PlayerHistory(props) {
    const {playerId} = useParams();
    const location = useLocation();

    const {isLoading, data, isSuccess} = useQuery(
        ['getPlayerClubLeagueSeasons', playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/playerClubLeagueSeasons`),
        {
            enabled: playerId !== undefined
        }
    );

    useEffect(() => {
        if(!!data && isSuccess) {
            props.setToolbar({
                title: <><ShortenedName player={data.data.response} /> history</>,
                navigation: [
                    <Profile key={1} playerId={playerId} currentPath={location.pathname} />,
                    <ContractOffer key={4} playerId={playerId} currentPath={location.pathname} myClubId={props.manager.club?.id} playerClubId={data.data.response.clubId} retirementSeasonId={data.data.response.retirementSeasonId} />,
                    <TransferOffer key={3} playerId={playerId} currentPath={location.pathname} myClubId={props.manager.club?.id} playerClubId={data.data.response.clubId} retirementSeasonId={data.data.response.retirementSeasonId} />,
                    <History key={5} playerId={playerId} currentPath={location.pathname} />,
                ]
            });
        }
    }, [playerId, isLoading]);

    return(
        <PlayerHistoryInterface
            isLoading={isLoading}
            playerClubLeagueSeasons={data?.data?.response?.PTLS || []}
        />
    );
}
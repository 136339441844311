import {useEffect} from "react";
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router-dom';
import axios from "axios";

import TeamPlayersToolbarItem from "../toolbar/TeamPlayers.js";
import TeamFixturesToolbarItem from "../toolbar/TeamFixtures.js";
import TeamLeagueSeasonsToolbarItem from "../toolbar/TeamLeagueSeasons.js";

import ClubSquadInterface from "./interface.js";
import usePersistedState from "../../../common/lib/usePersistedState.js";
import { FormattedMessage } from "react-intl";
import TeamName from "../../../team/common/TeamName.js";
import ClubProfileToolbarItem from "../toolbar/ClubProfile.js";
import ClubTransfersToolbarItem from "../toolbar/ClubTransfers.js";
import ClubStaffToolbarItem from "../toolbar/ClubStaff.js";
import TeamTacticsToolbarItem from "../toolbar/TeamTactics.js";
import TeamLeagueToolbarItem from '../toolbar/TeamLeague.js';
import TeamTrainingToolbarItem from "../toolbar/TeamTraining.js";
import { getFilterQueryParams, getFilterStateFromPersistedState, initialFilterState } from "../../../player/filter/lib.js";

function fetchSquad(filterState, view, clubId, teamType, isOwner) {
    const filterParams = getFilterQueryParams(filterState);

    return axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/${teamType}/playerRegistrations?ownershipClaim=${isOwner}&context=${view}&${filterParams.join('&')}`);
}

export default function ClubSquad(props) {
    const {clubId, teamType} = useParams();
    const {persistedState: persistedView} = usePersistedState('squadView', 'selection');
    const {persistedState: persistedFilter} = usePersistedState('squadFilter', initialFilterState);

    const isOwner = !!props.manager.club && (parseInt(clubId) === props.manager.club.id);

    const {isLoading, error, data} = useQuery(
        [
            'getSquad',
            clubId,
            teamType,
            persistedView,
            ...getFilterStateFromPersistedState(persistedFilter)
        ],
        () => fetchSquad(persistedFilter, persistedView, clubId, teamType, isOwner),
        {
            enabled: clubId !== undefined && teamType !== undefined
        }
    );

    useEffect(() => {
        if(data?.data?.response) {
            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{teamName: <TeamName team={data.data.response} />}} />,
                navigation: [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamTacticsToolbarItem isMyClub={isOwner} teamType={teamType} key={3} />,
                    <TeamTrainingToolbarItem isMyClub={isOwner} teamType={teamType} key={4} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            });
        }
    }, [clubId, teamType, data?.data?.response]);

    if(error) {
        throw new Error(`API error: ${error.message}`);
    }

    return(
        <ClubSquadInterface
            isLoading={isLoading}
            isOwner={isOwner}
            data={data?.data?.response}
            ownerClubFormation={data?.data?.response?.currentFormation}
        />
    )
}

const messages = {
    screenTitle: {
        id: 'teamPlayers:screenTitle',
        defaultMessage: '{teamName} players',
        description: 'Title for the screen showing a teams players'
    }
}
import {useRef, useState, useEffect} from 'react';
import {generatePath, Redirect, useParams} from 'react-router';
import {Form, Button, Message} from 'semantic-ui-react';
import {FormattedMessage, defineMessages} from 'react-intl';

import {bidDecrementer, bidIncrementer, CurrencyInput, salaryDecrementer, salaryIncrementer} from '../../../../../common/components/CurrencyInput';
import { URL } from '../../../../../common/lib/paths';
import TitledSegment from '../../../../../common/components/TitledSegment';
import { faExchange, faSignature } from '@fortawesome/free-solid-svg-icons';
import ContractLengthOptions from '../../../../contract/offerForm/contractLengthOptions';
import ContractDemandsInterface from '../../../../contract/common/ContractDemands';
import Currency from '../../../../../common/components/Currency';

export default function CreatePlayerTransferOfferInterface(props) {
    const {playerId} = useParams();
    const currencyInputEl = useRef(null);
    const salaryInputEl = useRef(null);
    const [contractLength, setContractLength] = useState(null);

    useEffect(() => {
        if(contractLength === null && !!props.contractDemands?.adjustedEndSeasonDemand) {
            setContractLength(props.contractDemands.adjustedEndSeasonDemand.id);
        }
    }, [contractLength, props.contractDemands]);

    if(!props.player.playerContract) {
        return(
            <Redirect to={generatePath(URL.playerTransferAuctionFree, {playerId: playerId})} />
        );
    }

    if(props.isTransferBlocked === true) {
        return(
            <Message negative>
                <FormattedMessage {...submitBidErrorMessages['TRANSFER_BLOCK']} />
            </Message>
        )
    }

    if(props.isInterested === false) {
        return(
            <Message negative>
                <FormattedMessage {...submitBidErrorMessages['REPUTATION_MISMATCH']} />
            </Message>
        )
    }

    return(
        <>
            <TitledSegment
                title='Transfer offer'
                icon={faExchange}
            >
                <>
                    {!props.isLoading && 
                        <p>Remaining transfer budget: <Currency value={props.clubBudgets.remainingTransferBudget} /></p>
                    }
                    <Form
                        loading={props.isSubmitting}
                    >
                        <Form.Field>
                            <label>Offer amount</label>
                            <CurrencyInput
                                currencyInputEl={currencyInputEl}
                                initialValue={props.player.value}
                                isLoading={props.isLoading}
                                getIncrement={bidIncrementer}
                                getDecrement={bidDecrementer}
                            />
                        </Form.Field>
                    </Form>
                </>
            </TitledSegment>
            <TitledSegment
                title='Contract offer'
                icon={faSignature}
            >
                <ContractDemandsInterface
                    isLoading={props.isLoading}
                    isMyPlayer={false}
                    isYouthTeamPlayer={false}
                    contractDemands={props.contractDemands}
                    currentSeason={props.currentSeason}
                    contractOffer={null}
                    playerContract={props.player?.playerContract}
                />
                <br />
                {!props.isLoading &&
                    <p>Remaining wage budget: <Currency value={props.clubBudgets.remainingWageBudget} /></p>
                }
                <Form
                    loading={props.isSubmitting}
                >
                    <Form.Field>
                        <label>Wage</label>
                        <CurrencyInput
                            currencyInputEl={salaryInputEl}
                            initialValue={props.contractDemands?.adjustedSalaryDemand}
                            isLoading={props.isLoading}
                            getIncrement={salaryIncrementer}
                            getDecrement={salaryDecrementer}
                        />
                    </Form.Field>
                    <ContractLengthOptions
                        selectedContractLength={contractLength}
                        setSelectedContractLength={setContractLength}
                        noContractShorterThan={props.currentSeason?.endDate}
                        isLoading={props.isLoading}
                    />
                </Form>
            </TitledSegment>
            {props.submitBidError &&
                <Message negative>
                    <FormattedMessage
                        {...(submitBidErrorMessages[props.submitBidError.response?.key] || submitBidErrorMessages['unknown'])}
                        values={{key: props.submitBidError.response?.key || '-1'}}
                    />
                </Message>
            }
            <Button fluid positive onClick={handleTransferBidSubmit} style={{width: 'calc(100% - 8px)', margin: '1em auto 0 auto'}}>Submit transfer offer</Button>
        </>
    );

    function handleTransferBidSubmit() {
        props.handleSubmitBid(
            currencyInputEl.current.getAttribute('rawvalue'),
            salaryInputEl.current.getAttribute('rawvalue'),
            contractLength
        );
    }
}

const submitBidErrorMessages = defineMessages({
    'unknown': {
        id: 'player:transferOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'PENDING_TRANSFER': {
        id: 'player:transferOffer:create:error:pendingTransfer',
        defaultMessage: 'You cannot bid for this player due to another transfer offer',
        description: 'Error message shown if a transfer bid cannot be submitted due to it clashing with another existing bid'
    },
    'INSUFFICIENT_TRANSFER_BUDGET': {
        id: 'player:transferOffer:create:error:transferBudget',
        defaultMessage: 'Your transfer budget is not sufficient for this offer. Try lowering your bid.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (transfer budget)'
    },
    'INSUFFICIENT_WAGE_BUDGET': {
        id: 'player:transferOffer:create:error:wageBudget',
        defaultMessage: 'Your wage budget is not sufficient for this offer. Try lowering your contract offer.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (Wage budget)'
    },
    'TOO_MANY_PLAYERS': {
        id: 'player:transferOffer:create:error:tooManyPlayers',
        defaultMessage: 'You cannot bid for this player because you have too many players',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of players'
    },
    'NOT_ENOUGH_PLAYERS': {
        id: 'player:transferOffer:create:error:tooFewPlayers',
        defaultMessage: 'You cannot bid for this player because their club would be left with too few players',
        description: 'Error message shown if a transfer bid cannot be submitted because the receiving club has too few players'
    },
    'PLAYER_RETIRING': {
        id: 'player:transferOffer:create:error:playerRetiring',
        defaultMessage: 'You cannot bid for this player because they are retired or retiring',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is retired/retiring'
    },
    'YOUTH_BLOCK': {
        id: 'player:transferOffer:create:error:youthPlayer',
        defaultMessage: 'Transfer offers are not allowed for youth players',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is a youth player'
    },
    'PLAYER_LOAN': {
        id: 'player:transferOffer:create:error:playerLoan',
        defaultMessage: 'You cannnot make a bid for a player who is on loan at another club',
        description: 'Error message shown when a bid is made for a player who is on loan'
    },
    'TRANSFER_BLOCK': {
        id: 'player:transferOffer:create:error:transferBlock',
        defaultMessage: 'You cannot bid for this player as they have only recently joined their club.',
        description: 'Error message shown if you try to bid for a player who has only recently moved clubs'
    },
    'REPUTATION_MISMATCH': {
        id: 'player:transferOffer:create:error:reputationAbs',
        defaultMessage: 'This player is not interested in joining your club',
    },
    'FREQUENCY_ERROR': {
        id: 'player:transferOffer:create:error:pendingBid',
        defaultMessage: 'You have already submitted a bid for this player, please wait for the other club to respond.'
    },
    'AUCTION_IN_PROGRESS': {
        id: 'player:transferOffer:create:error:auctionInProgress',
        defaultMessage: 'You cannot bid for this player as there is currently an auction in progress'
    },
    'MODIFY_RESOURCE_PERMISSION_DENIED': {
        id: 'player:transferOffer:create:error:permission',
        defaultMessage: 'The player\'s current club have blocked offers for this player.'
    },
    'INVALID_COMPENSATION': {
        id: 'player:transferOffer:create:error:compensation',
        defaultMessage: 'The bid value is invalid. Please try again.'
    },
    'LENGTH_MAX_CONSTRAINT': {
        id: 'player:transferOffer:create:error:maxContractLengthExceeded',
        defaultMessage: 'The contract you have offered is too long. Please try again with a shorter offer.'
    },
    'CONTRACT_VALUE_LOW': {
        id: 'player:transferOffer:create:error:contractValueLow',
        defaultMessage: 'The player has rejected your contract offer. Please try again with a better contract offer.'
    },
    'CONTRACT_VALUE_HIGH': {
        id: 'player:transferOffer:create:error:contractValueHigh',
        defaultMessage: 'The board feel this contract offer is too generous. Please try again with a lower contract offer.'
    },
    'OWN_PLAYER': {
        id: 'player:transferOffer:create:error:ownPlayer',
        defaultMessage: 'You cannot bid for your own player.'
    }
});
import { defineMessages } from "@formatjs/intl";
import {compileMessage} from './util.js';

const messages = {
    'playerTransferAuctionFree:started': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferAuctionFree:started',
            defaultMessage: "{playerName} nearing move",
            description: "Message subject for the system message template which a user receives when a free agent on their favourites will soon pick a new team"
        },
        body: {
            id: 'playerTransferAuctionFree:started',
            defaultMessage: "{playerName} has today announced that he will decide which club he will be joining on a free transfer between {endDateMin} and {endDateMax}",
            description: "Message body for the system message template which a user receives when a free agent on their favourites will soon pick a new team"
        }
    })),
    'playerTransferAuctionFree:finished': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferAuctionFree:finished',
            defaultMessage: "{playerName} decides next club",
            description: "Message subject for the system message template which a user receives when a free agent on their favourites chooses next team"
        },
        body: {
            id: 'playerTransferAuctionFree:finished',
            defaultMessage: "After considering offers from {bidCount} clubs, {playerName} has announced the club that he has decided to join on a free transfer. Click the button below to find out who.\n\nThe announcement was made at {auctionEndDate}.",
            description: "Message body for the system message template which a user receives when a free agent on their favourites chooses next team"
        }
    })),
}

export default messages;
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { useTutorial } from "../../tutorial/hooks/useTutorial.js";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_PlayerProfile.text",
		defaultMessage: "Profile",
		description: "Link for player profile page"
	}
});

export default function Profile(props) {
	const {checkTutorial, getSearchParamsString} = useTutorial();

	const isTutorial1 = checkTutorial('players', 8);

	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.playerProfile, {playerId: props.playerId}),
				search: isTutorial1 && getSearchParamsString('players', 9)
			}} 
			icon={faIdBadge}
			messages={messages}
			glow={isTutorial1}
		/>
	);
}
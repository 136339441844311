import {defineMessages} from 'react-intl';

import {URL} from '../../../common/lib/paths';
import ToolbarItem from '../../../menu/toolbar/ToolbarItem';
import { faMap } from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
    text: {
        id: 'world:toolbar:nations',
        defaultMessage: 'Nations',
        description: 'Link in the toolbar in the world section for the view nations page'
    }
})

export default function WorldNationsToolbarItem(props) {
    return(
        <ToolbarItem
            path={URL.nationList}
            icon={faMap}
            messages={messages}
        />
    )
}
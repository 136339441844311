const leftPlayers = {
    LB: 1,
    CB: 2,
    RB: 1,
    CM: 2,
    LW: 1,
    CAM: 1,
    RW: 1,
    ST: 1
};

const rightPlayers = {
    LB: 1,
    CB: 3,
    RB: 1,
    LM: 1,
    CM: 2,
    RM: 1,
    ST: 1
};

export {
    leftPlayers,
    rightPlayers
}
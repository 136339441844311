import { FormattedMessage, defineMessages } from "react-intl"
import NationGroupName from "../../nationGroup/common/NationGroupName";
import NationName from "./NationName";

export default function FullNationName(props) {
    return(
        <FormattedMessage
            {...templates[props.nation.name]}
            values={{
                nationName: (<NationGroupName shortcode={props.nation.nationGroupShortcode} />),
                regionName: (<NationName nation={props.nation} />)
            }}
        />
    )
}

const templates = defineMessages({
    'e1': {
        id: 'nations:england',
        defaultMessage: '{nationName}',
        description: 'Name for England (country)'
    },
    'au': {
        id: 'nations:australia',
        defaultMessage: '{nationName}',
        description: 'Name for Australia (country)'
    },
    'br': {
        id: 'nations:brazil',
        defaultMessage: '{nationName}',
        description: 'Name for Brazil (country)'
    },
    'cz': {
        id: 'nations:czechRepublic',
        defaultMessage: '{nationName}',
        description: 'Name for Czech Republic (country)'
    },
    'de_north': {
        id: 'nations:de:north',
        defaultMessage: '{nationName} {regionName}',
        description: 'Name for North region of Germany'
    },
    'de_south': {
        id: 'nations:de:south',
        defaultMessage: '{nationName} {regionName}',
        description: 'Name for South region of Germany'
    },
    'dk': {
        id: 'nations:denmark',
        defaultMessage: '{nationName}',
        description: 'Name for Denmark (country)'
    },
    'es': {
        id: 'nations:spain',
        defaultMessage: '{nationName}',
        description: 'Name for Spain (country)'
    },
    'nl': {
        id: 'nations:netherlands',
        defaultMessage: '{nationName}',
        description: 'Name for Netherlands (country)'
    },
    'se': {
        id: 'nations:sweden',
        defaultMessage: '{nationName}',
        description: 'Name for Sweden (country)'
    },
    'us_northeast': {
        id: 'nations:us:northeast',
        defaultMessage: '{nationName} {regionName}',
        description: 'Name for the Northeast region of the USA'
    },
    'us_west': {
        id: 'nations:us:west',
        defaultMessage: '{nationName} {regionName}',
        description: 'Name for the West region of the USA'
    },
    'us_south': {
        id: 'nations:us:south',
        defaultMessage: '{nationName} {regionName}',
        description: 'Name for the South region of the USA'
    },
    'us_midwest': {
        id: 'nations:us:midwest',
        defaultMessage: '{nationName} {regionName}',
        description: 'Name for the Midwest region of the USA'
    },
    'fr_north': {
        id: 'nations:fr:north',
        defaultMessage: '{nationName} {regionName}',
        description: 'Name for the North region of France'
    },
    'fr_south': {
        id: 'nations:fr:south',
        defaultMessage: '{nationName} {regionName}',
        description: 'Name for the South region of France'
    }
});
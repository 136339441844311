import { defineMessages } from "@formatjs/intl";

const messages = {
    'contractOffer:accepted:transfer': defineMessages({
        subject: {
            id: "messageSubject:contractOffer:accepted:transfer",
            defaultMessage: "Contract offer accepted",
            description: "Subject for the system message template which a user receives when they join the game"
        },
        body: {
            id:'messageBody:contractOffer:accepted:transfer',
            defaultMessage: 'I have accepted your contract offer of {contractSalary} for {contractLength} season(s) until {contractExpiry}.\n\nI look forward to making the {proposingClubName} fans proud and I can\'t wait to get started at the training ground!\n\nI have always wanted to work under your tutelage and cannot wait to get started, let\'s win some silverware boss!',
            description: 'Body for the system message template which a user receives when a player has accepted their contract offer as part of a transfer bid'
        }
    }),
    'contractOffer:accepted:renewal': defineMessages({
        subject: {
            id: "messageSubject:contractOffer:accepted:transfer",
            defaultMessage: "Contract offer accepted",
            description: "Subject for the system message template which a user receives when they join the game"
        },
        body: {
            id:'messageBody:contractOffer:accepted:transfer',
            defaultMessage:
                `I have accepted your contract offer of {contractSalary} for {contractLength} season(s), it's great to be renewing with the club until {contractExpiry}.
                This club is my home. Let\'s get back to the training ground and win some trophies boss!`,
            description: 'Body for the system message template which a user receives when a player has accepted their contract offer as part of a transfer bid'
        }
    }),
    'contractOffer:rejected:transfer': defineMessages({
        subject: {
            id: "messageSubject:contractOffer:rejected:transfer",
            defaultMessage: "Contract offer rejected",
            description: "Subject for the system message template which a user receives when they join the game"
        },
        body: {
            id:'messageBody:contractOffer:accepted:transfer',
            defaultMessage: 'I am very grateful for the interest you have shown in me and I have great respect for you as a manager. However, I have decided to accept an offer from another club.',
            description: 'Body for the system message template which a user receives when a player has rejected their contract offer as part of a transfer bid'
        }
    }),
    'contractOffer:rejected:renewal': defineMessages({
        subject: {
            id: "messageSubject:contractOffer:rejected:renewal",
            defaultMessage: "Contract offer rejected",
            description: "Subject for the system message template which a user receives when they join the game"
        },
        body: {
            id:'messageBody:contractOffer:accepted:transfer',
            defaultMessage: 'After a lot of thought I have decided to reject the contract renewal that you have offered me. I love this club and it`s fans but I feel that the time has come to move on and so I have persue another opportunity.',
            description: 'Body for the system message template which a user receives when a player has rejected their contract offer as part of a contract renewal'
        }
    }),
    'transferOffer:contractOfferAccepted:informExistingClub': defineMessages({
        subject: {
            id: 'messageSubject:transferOffer:contractOfferAccepted:informExistingClub',
            defaultMessage: "I've agreed a move boss",
            description: 'Subject for the message which a user receives from their player to inform them they have accepted a contract offer from another club (for a transfer)'
        },
        body: {
            id: 'messageBody:transferOffer:contractOfferAccepted:informExistingClub',
            defaultMessage: 'I just wanted to let you know that I have agreed a contract with another club and I will be moving shortly.\n\nThank you for the chances you have given me, I will not forget my time here.',
            description: 'Body for the message which a user receives from their player to inform them they have accepted a contract offer from another club (for a transfer)'
        }
    })
}

export default messages;
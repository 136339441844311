import axios from "axios";
import {Grid, Placeholder, Table} from "semantic-ui-react";
import {useQuery} from '@tanstack/react-query';

import {default as RatingColor} from '../RatingRoundall';

const styles = {
    grid: {
        width: '100%',
        margin: 0
    },
    gridRow: {
        padding: 0
    },
    gridColumn: {
        borderRight: '1px solid #2224261a'
    },
    createAttributeTable: {
        table: {
            wordBreak: 'break-word',
            margin: 0,
            padding: '0 0 1px 0',
            borderRadius: 0
        },
        headerCell: {
            fontWeight: 'bold'
        }
    },
    defendAttributeTable: {
        table: {
            wordBreak: 'break-word',
            margin: 0,
            padding: 0,
            borderBottom: 'none',
            borderRadius: 0
        },
        headerCell: {
            fontWeight: 'bold'
        }
    },
    finishAttributeTable: {
        table: {
            wordBreak: 'break-word',
            margin: 0,
            padding: 0,
            borderTop: '1px solid #e0e1e2',
            borderRadius: 0
        },
        row: {
            firstCell: {
                fontWeight: 'bold'
            }
        }
    },
}

const defendAttributes = [
    'tackling',
    'marking',
    'strength',
    'heading'
];

const createAttributes = [
    'passing',
    'control',
    'pace',
    'dribbling',
    'crossing'
];

const GKAttributes = [
    'reach',
    'handling',
    'reflex',
    'agility',
    'kicking'
]

export default function AttributeTable(props) {
    const {isLoading, data} = useQuery(
        ['getPlayerAttributes', props.player.id],
        () => axios.get([process.env.REACT_APP_APPHOST, 'players', props.player.id, 'attributes'].join('/')),
        {
            enabled: props.player?.id !== undefined
        }
    );

    function getTableRow(attributeName, style = {}) {
        return(
            <Table.Row>
                <Table.Cell style={style.firstCell}>{attributeName.charAt(0).toUpperCase() + attributeName.slice(1)}</Table.Cell>
                <Table.Cell collapsing>
                    {isLoading || !props.player ?
                        <Placeholder><Placeholder.Line style={{height: 'unset'}} length='full' /></Placeholder>
                        :
                        <RatingColor rating={data.data.response[attributeName]} />
                    }
                </Table.Cell>
            </Table.Row>
        )
    }

    function getTable(attributes, name, style = {}) {
        return(
            <Table unstackable compact basic='very' style={style.table} className='attrTable'>
                <Table.Body>
                    {!!name &&
                        <Table.Row>
                            <Table.Cell colSpan={2} style={style.headerCell}>{name}</Table.Cell>
                        </Table.Row>
                    }
                    {attributes.map((attribute) => 
                        getTableRow(attribute, style.row)
                    )}
                </Table.Body>
            </Table>
        )
    }

    return(
        <Grid style={styles.grid}>
            <Grid.Row style={styles.gridRow}>
                <Grid.Column width={8} style={styles.gridColumn}>
                    {props.player.position === 'GK' ?
                        getTable(GKAttributes, 'Goalkeeper', styles.createAttributeTable)
                        :
                        getTable(createAttributes, 'Attacking', styles.createAttributeTable)
                    }
                </Grid.Column>
                <Grid.Column width={8}>
                    {props.player.position !== 'GK' && 
                        <>
                            {getTable(defendAttributes, 'Defending', styles.defendAttributeTable)}
                            {getTable(['finishing'], undefined, styles.finishAttributeTable)}
                        </>
                    }
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}
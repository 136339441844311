import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { generatePath, useHistory } from "react-router";
import DataTable from "../../../../common/components/DataTable";
import TitledSegment from "../../../../common/components/TitledSegment";
import WideLayout from "../../../../common/components/WideLayout";
import { clubName } from "../../../../common/dataTableColumns/club/clubName";
import { currency } from "../../../../common/dataTableColumns/currency";
import { playerName } from "../../../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../../../common/dataTableColumns/player/playerPosition";
import { transferOfferStatus } from "../../../../common/dataTableColumns/transferOfferStatus";
import { URL } from "../../../../common/lib/paths";
import { currentAbility } from "../../../../common/dataTableColumns/currentAbility";

export default function ClubPlayerTransferOfferPaidInterface(props) {
    return(
        <WideLayout>
            <TitledSegment
                title='Offers made'
                icon={faArrowUp}
                noPadding={true}
            >
                <ProposedPlayerTransferOfferPaidTable
                    isLoading={props.isLoading}
                    playerTransferOfferPaid={props.proposedPlayerTransferOfferPaid}
                />
            </TitledSegment>
            <TitledSegment
                title='Offers received'
                icon={faArrowDown}
                noPadding={true}
            >
                <ReceivedPlayerTransferOfferPaidTable
                    isLoading={props.isLoading}
                    playerTransferOfferPaid={props.receivedPlayerTransferOfferPaid}
                />
            </TitledSegment>
        </WideLayout>
    )
}

function ProposedPlayerTransferOfferPaidTable(props) {
    const history = useHistory();

    const columns = [
        transferOfferStatus((playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.status : 'pending'),
        playerName(
            (playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.player : playerTransferOfferPaid.playerContract.player,
            (playerTransferOfferPaid) => playerTransferOfferPaid.playerContract,
            () => false
        ),
        clubName((playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.fromClub : playerTransferOfferPaid.playerContract.club),
        currency(
            'compensation',
            (playerTransferOfferPaid) => playerTransferOfferPaid.compensation,
            350
        ),
        playerPosition((playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.player.position : playerTransferOfferPaid.playerContract.player.position),
        currentAbility((playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.player.currentAbility : playerTransferOfferPaid.playerContract.player.currentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.playerTransferOfferPaid}
            onRowClicked={(row) => history.push(row.isHistorical ? generatePath(URL.viewPlayerTransferOfferPaidHistory, {playerTransferOfferPaidHistoryId: row.id}) : generatePath(URL.viewPlayerTransferOfferPaid, {playerTransferOfferPaidId: row.id}))}
        />
    );
}

function ReceivedPlayerTransferOfferPaidTable(props) {
    const history = useHistory();

    const columns = [
        transferOfferStatus((playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.status : 'pending'),
        playerName(
            (playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.player : playerTransferOfferPaid.playerContract.player,
            (playerTransferOfferPaid) => playerTransferOfferPaid.playerContract,
            () => false
        ),
        clubName((playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.toClub : playerTransferOfferPaid.club),
        currency(
            'compensation',
            (playerTransferOfferPaid) => playerTransferOfferPaid.compensation
        ),
        playerPosition((playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.player.position : playerTransferOfferPaid.playerContract.player.position),
        currentAbility((playerTransferOfferPaid) => playerTransferOfferPaid.isHistorical ? playerTransferOfferPaid.player.currentAbility : playerTransferOfferPaid.playerContract.player.currentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.playerTransferOfferPaid}
            onRowClicked={(row) => history.push(row.isHistorical ? generatePath(URL.viewPlayerTransferOfferPaidHistory, {playerTransferOfferPaidHistoryId: row.id}) : generatePath(URL.viewPlayerTransferOfferPaid, {playerTransferOfferPaidId: row.id}))}
        />
    );
}
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClubLink from "../../../club/components/common/Link";

function clubName(
    resolver,
    hide = 580
) {
    return {
        id: 'clubName',
        name: <FontAwesomeIcon icon={faShieldHalved} />,
        selector: (rowData) => 
            <div style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}>
                <ClubLink club={resolver(rowData)} />
            </div>,
        wrap: false,
        allowOverflow: false,
        compact: true,
        grow: 2,
        hide,
        ignoreRowClick: true
    }
}

export {
    clubName
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChild, faListOl, faMedal } from '@fortawesome/free-solid-svg-icons';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom'

import NarrowLayout from "../../../common/components/NarrowLayout";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { generatePath, matchPath, Redirect, useHistory, useLocation, useParams } from "react-router";
import { URL } from "../../../common/lib/paths";

import TLSHistory from './views/TLSHistory/data';
import PTLSHistory from './views/PTLSHistory/data';
import { useTutorial } from '../../../tutorial/hooks/useTutorial';

export default function ClubLeagueSeasons(props) {
	const {clubId} = useParams();
	const location = useLocation();
	const history = useHistory();
	const {checkTutorial, getSearchParamsString} = useTutorial();

	const isTutorial1 = checkTutorial('clubs', 2);

	if(!location.state?.view) {
		return(
			<Redirect
				to={{
					pathname: location.pathname,
					state: {view: 'TLSHistory'},
					search: location.search
				}}
			/>
		);
	}

	return(
		<div>
			<NarrowLayout style={{margin: '0 auto 1em auto'}}>
				<div style={{display: 'flex', justifyContent: 'space-between'}}>
					<Menu
						icon
						inverted
						floated='left'
						size='tiny'
					>
						<Menu.Item
							as={Link}
							to={{pathname: generatePath(URL.teamHistory, {clubId, teamType: 'first'}), state: {view: location.state?.view}}}
							active={matchPath(location.pathname, {path: generatePath(URL.teamHistory, {clubId, teamType: 'first'})})}
						>
							<SUIIconShim>
								<FontAwesomeIcon icon={faUser} />
							</SUIIconShim>
						</Menu.Item>
						<Menu.Item
							as={Link}
							to={{pathname: generatePath(URL.teamHistory, {clubId, teamType: 'youth'}), state: {view: location.state?.view}}}
							active={matchPath(location.pathname, {path: generatePath(URL.teamHistory, {clubId, teamType: 'youth'})})}
						>
							<SUIIconShim>
								<FontAwesomeIcon icon={faChild} />
							</SUIIconShim>
						</Menu.Item>
					</Menu>
					<Menu
						icon
						inverted
						floated='right'
						size='tiny'
					>
						<Menu.Item
							active={location.state.view === 'TLSHistory'}
							onClick={() => history.replace(location.pathname, {view: 'TLSHistory'})}
						>
							<SUIIconShim>
								<FontAwesomeIcon icon={faListOl} />
							</SUIIconShim>
						</Menu.Item>
						<Menu.Item
							active={location.state.view === 'PTLSHistory'}
							onClick={() => history.replace({pathname: location.pathname, state: {view: 'PTLSHistory'}, search: isTutorial1 && getSearchParamsString('clubs', 3)})}
							className={isTutorial1 && 'glow'}
						>
							<SUIIconShim>
								<FontAwesomeIcon icon={faMedal} />
							</SUIIconShim>
						</Menu.Item>
					</Menu>
				</div>
			</NarrowLayout>
			{location.state.view === 'TLSHistory' &&
				<TLSHistory
					setToolbar={props.setToolbar}
					manager={props.manager}
				/>
			}
			{location.state.view === 'PTLSHistory' &&
				<PTLSHistory
					setToolbar={props.setToolbar}
					manager={props.manager}
				/>
			}
		</div>
	);
}
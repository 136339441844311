import {Table, Menu, Container, Dropdown} from 'semantic-ui-react';
import {useLocation, useHistory} from 'react-router-dom';

import PlayerLink from '../../../player/common/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faAward, faBriefcaseMedical, faFutbol, faKitMedical, faPeopleArrows, faSquare, faStreetView, faThumbsUp } from '@fortawesome/free-solid-svg-icons';

export default function Summary(props) {
    const history = useHistory();
    const location = useLocation();

    return(
        <>
            <Container fluid textAlign='center' style={{marginTop: '1em'}}>
                <Menu compact widths={2} size='small' style={{maxWidth: '200px', marginBottom: '1em'}}>
                    <Menu.Item link active={location.state?.side === 'home'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'ratings', side: 'home', playerStat: location.state?.playerStat}})} className='menuItemPaddingFix' style={{display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.homeClubName}</Menu.Item>
                    <Menu.Item link active={location.state?.side === 'away'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'ratings', side: 'away', playerStat: location.state?.playerStat}})} className='menuItemPaddingFix' style={{display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.awayClubName}</Menu.Item>
                </Menu>
                <Dropdown
                    selection
                    value={location.state?.playerStat || 'rating'}
                    onChange={
                        (e, {value}) => history.replace(
                            {
                                pathname: location.pathname,
                                state: {
                                    view: 'ratings',
                                    side: location.state?.side,
                                    playerStat: value
                                }
                            }
                        )
                    }
                    options={[
                        {
                            key: 'rating',
                            text: 'Match performance',
                            value: 'rating'
                        },
                        {
                            key: 'chancesCreated',
                            text: 'Chances created',
                            value: 'chancesCreated'
                        },
                        {
                            key: 'shots',
                            text: 'Shots',
                            value: 'shots'
                        },
                        {
                            key: 'shotsOnTarget',
                            text: 'Shots on target',
                            value: 'shotsOnTarget'
                        },
                        {
                            key: 'shotsOnTargetP',
                            text: 'Shots on target %',
                            value: 'shotsOnTargetP'
                        },
                        {
                            key: 'shotConversion',
                            text: 'Shot conversion',
                            value: 'shotConversion'
                        },
                        {
                            key: 'saves',
                            text: 'Saves',
                            value: 'saves'
                        },
                    ]}
                />
            </Container>
            <Table basic='very' compact='very' unstackable>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell><FontAwesomeIcon icon={faStreetView} /></Table.HeaderCell>
                        <Table.HeaderCell>Player</Table.HeaderCell>
                        <Table.HeaderCell><FontAwesomeIcon icon={faThumbsUp} /></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {props.lineup[location.state?.side || 'home'].map((lineup) => 
                        lineup.player.map((player, i) => 
                            <Table.Row>
                                {lineup.player.length > 1 ?
                                    i === 0 ?
                                        <Table.Cell collapsing rowSpan={lineup.player.length} style={{verticalAlign: 'middle'}}>{lineup.position}</Table.Cell>
                                        :
                                        null
                                    :
                                    <Table.Cell collapsing>{lineup.position}</Table.Cell>
                                }
                                <Table.Cell style={{paddingLeft: '0.6em'}}>
                                    {player.isMOTM && <FontAwesomeIcon icon={faAward} />}
                                    <PlayerLink player={{id: player.playerId, person: {firstName: player.firstName, lastName: player.lastName}}} />
                                    <span style={{marginLeft: '0.25rem'}}>
                                        {[...Array(player.goals || 0)].map((a, i) => 
                                            <FontAwesomeIcon icon={faFutbol} className='inlineIcon' key={`g_${i}`} />
                                        )}
                                    </span>
                                    <span style={{marginLeft: '0.25rem'}}>
                                    {[...Array(player.assists || 0)].map((a, i) =>
                                        <FontAwesomeIcon icon={faPeopleArrows} className='inlineIcon' key={`a_${i}`} />
                                    )}
                                    {!!player.card && player.card.type === 'red' &&
                                        <FontAwesomeIcon icon={faSquare} color='#DB2828' />
                                    }
                                    {!!player.card && player.card.type === 'yellow' &&
                                        <FontAwesomeIcon icon={faSquare} color='#FBBD08' />
                                    }
                                    {player.timeOnPitch !== 90 && player.startedMatch && <>{player.injured && <FontAwesomeIcon icon={faBriefcaseMedical} className='inlineIcon' />}<FontAwesomeIcon icon={faArrowDown} color='#DB2828' className='inlineIcon' /><span style={{fontSize: '0.7em'}}>{player.timeOnPitch}'</span></>}
                                    {player.timeOnPitch !== 90 && !player.startedMatch && <><FontAwesomeIcon icon={faArrowUp} color='#21BA45' /><span style={{fontSize: '0.7em'}}>{90-player.timeOnPitch}'</span></>}
                                    </span>
                                </Table.Cell>
                                {!location.state?.playerStat || location.state?.playerStat === 'rating' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{player.performanceRating === 10 ? player.performanceRating : player.performanceRating.toFixed(1)}</Table.Cell> : null}
                                {location.state?.playerStat === 'shots' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{player.totalShots || '-'}</Table.Cell> : null}
                                {location.state?.playerStat === 'shotsOnTarget' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{player.totalShotsOnTarget || '-'}</Table.Cell> : null}
                                {location.state?.playerStat === 'shotsOnTargetP' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{player.totalShots ? (Math.round(player.totalShotsOnTarget / player.totalShots * 100) + "%") : '-'}</Table.Cell> : null}
                                {location.state?.playerStat === 'shotConversion' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{player.totalShots ? (Math.round(player.goals / player.totalShots * 100) + "%") : '-'}</Table.Cell> : null}
                                {location.state?.playerStat === 'chancesCreated' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{player.chancesCreated || '-'}</Table.Cell> : null}
                                {location.state?.playerStat === 'saves' ? <Table.Cell collapsing style={{fontWeight: 'bold'}}>{player.shotsSaved}</Table.Cell> : null}
                            </Table.Row>
                        )
                    )}
                </Table.Body>
            </Table>
        </>
    );
}
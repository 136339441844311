import { faDownLeftAndUpRightToCenter, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router";
import { Label } from "semantic-ui-react";
import { URL } from "../../common/lib/paths";
import useCompare from "./useCompare"
import { useTutorial } from "../../tutorial/hooks/useTutorial";

export default function CompareWidget(props) {
    const {playerIds, removeAll} = useCompare();
    const history = useHistory();
    const location = useLocation();
    const {checkTutorial, goNextStep} = useTutorial();

    const isTutorial1 = checkTutorial('players', 10);
    const isTutorial2 = checkTutorial('transfers', 15);

    if(playerIds.length === 0 || location.pathname === URL.playerCompare) return null;

    function handleRemoveAll(e) {
        removeAll();
        e.stopPropagation();
    }

    function handleClick() {
        history.push(URL.playerCompare)
        
        if(isTutorial1 === true || isTutorial2 === true) {
            goNextStep();
        }
    }

    return(
        <div className='compareWidget'>
            <Label size='large' className={(isTutorial1 || isTutorial2) && 'glow'} color='blue' onClick={handleClick.bind(this)}>
                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} className='inlineIcon' transform={{rotate: 45}} />
                &nbsp;{playerIds.length}
                <FontAwesomeIcon icon={faXmark} className='inlineIconMirror' onClick={handleRemoveAll} />
            </Label>
        </div>
    )
}
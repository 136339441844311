import Flag from '../../../common/components/Flag';

export default function LeagueLevelName(props) {
    return(
        <span>
            {!!props.leagueLevel.nation?.nationGroupShortcode && <Flag name={props.leagueLevel.nation?.nationGroupShortcode} />}
            League&nbsp;
            {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(props.leagueLevel.level-1)}
        </span>
    )
}
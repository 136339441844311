import {useState, useRef} from 'react';
import {Card, Loader, Button, Icon, Dimmer, Dropdown, Form, Label} from 'semantic-ui-react';
import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import ShortenedPlayerName from '../player/common/ShortenedName';
import useBodyClass from '../useBodyClass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faClock, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import SUIIconShim from '../common/components/SUIIconShim';
import PositionLabel from '../player/common/position/PositionLabel';

export default function SubstitutionCard(props) {
    const [isCreating, setIsCreating] = useState(false);
    const [onLineupPositionIndex, setOnLineupPositionIndex] = useState(null);
    const [offLineupPositionIndex, setOffLineupPositionIndex] = useState(null);

    useBodyClass('noSelect');
    const incrementTimer = useRef(null);
    const decrementTimer = useRef(null);
    const inputEl = useRef(null);

    let rawValue = 60;

    const substituteIds = reduce(props.substitutes, (arr, substitute) => {arr.push(substitute.onTeamTacticPosition.id, substitute.offTeamTacticPosition.id); return arr;}, []);

    function handleIncreaseTimeHold(event) {
        event.preventDefault();
        increaseTime();
        incrementTimer.current = setInterval(increaseTime, 200);
    }

    function increaseTime() {
        rawValue = Math.min(85, rawValue+5);
        inputEl.current.value = `${rawValue} mins`;
    }

    function clearIncreaseTimeHold(event) {
        event.preventDefault();
        clearInterval(incrementTimer.current);
    }

    function handleDecreaseTimeHold(event) {
        event.preventDefault();
        decreaseTime();
        decrementTimer.current = setInterval(decreaseTime, 200);
    }

    function decreaseTime() {
        rawValue = Math.max(5, rawValue-5);
        inputEl.current.value = `${rawValue} mins`;
    }

    function clearDecreaseTimeHold(event) {
        event.preventDefault();
        clearInterval(decrementTimer.current);
    }

    function handleCreateSub() {
        props.handleCreateSub(onLineupPositionIndex, offLineupPositionIndex, rawValue);
        setIsCreating(false);
    }

    return(
        <Card style={{margin: '0 auto', marginBottom: '1em'}}>
            <Dimmer inverted active={props.isLoading}>
                <Loader />
            </Dimmer>
            <Card.Content>
                <Card.Header>Substitution {props.id+1}</Card.Header>
                {!props.isLoading && !!props.substitutes[props.id] ?
                    <Card.Meta>
                        At {props.substitutes[props.id].time} minutes
                    </Card.Meta>
                    :
                    null
                }
                <Card.Description className='cardDescriptionFix'>
                    {!!props.substitutes[props.id] ?
                            <>
                                <FontAwesomeIcon icon={faArrowDown} color='#DB3B30' className='inlineIcon' /><ShortenedPlayerName player={props.substitutes[props.id].offTeamTacticPosition.playerReg.playerContract.player} /><br />
                                <FontAwesomeIcon icon={faArrowUp} color='#21ba45' className='inlineIcon' /><ShortenedPlayerName player={props.substitutes[props.id].onTeamTacticPosition.playerReg.playerContract.player} />
                            </>
                        :
                        isCreating ?
                            <Form>
                                <Form.Field>
                                    <label>Player off <FontAwesomeIcon icon={faArrowDown} color='#DB3B30' /></label>
                                    <Dropdown
                                        selection
                                        options={
                                            map(filter(props.lineups, (lineup) => lineup.teamTacticPosition?.pitchSection > 0 && !substituteIds.includes(lineup.teamTacticPosition?.id)), (lineup) => {
                                                return {
                                                    text: (
                                                        <>
                                                            <PositionLabel
                                                                teamTacticPosition={lineup.teamTacticPosition}
                                                            />
                                                            <ShortenedPlayerName player={lineup.playerContract.player} />
                                                        </>
                                                    ),
                                                    name: lineup.teamTacticPosition.id,
                                                    value: lineup.teamTacticPosition.id
                                                }
                                            })
                                        }
                                        value={offLineupPositionIndex}
                                        onChange={(e, {value}) => setOffLineupPositionIndex(value)}
                                        selectOnBlur={false}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Player on <FontAwesomeIcon icon={faArrowUp} color='#21ba45' /></label>
                                    <Dropdown
                                        selection
                                        options={
                                            map(filter(props.lineups, (lineup) => lineup.teamTacticPosition?.pitchSection === null && !substituteIds.includes(lineup.teamTacticPosition?.id)), (lineup) => {
                                                return {
                                                    text: (
                                                        <>
                                                            <PositionLabel
                                                                teamTacticPosition={lineup.teamTacticPosition}
                                                            />
                                                            <ShortenedPlayerName player={lineup.playerContract.player} />
                                                        </>
                                                    ),
                                                    name: lineup.teamTacticPosition.id,
                                                    value: lineup.teamTacticPosition.id
                                                }
                                            })
                                        }
                                        value={onLineupPositionIndex}
                                        onChange={(e, {value}) => setOnLineupPositionIndex(value)}
                                        selectOnBlur={false}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Time <FontAwesomeIcon icon={faClock} /></label>
                                    <div className="ui fluid action input transferBid">
                                        <input type="text" defaultValue={`${rawValue} mins`} ref={inputEl} />
                                        <button className="ui icon button" onMouseDown={handleDecreaseTimeHold.bind(this)} onTouchStart={handleDecreaseTimeHold.bind(this)} onTouchEnd={clearDecreaseTimeHold.bind(this)} onMouseOut={clearDecreaseTimeHold.bind(this)} onMouseUp={clearDecreaseTimeHold.bind(this)}>
                                            <SUIIconShim>
                                                <FontAwesomeIcon icon={faMinus} />
                                            </SUIIconShim>
                                        </button>
                                        <button class="ui icon button" onTouchStart={handleIncreaseTimeHold.bind(this)} onMouseDown={handleIncreaseTimeHold.bind(this)} onTouchEnd={clearIncreaseTimeHold.bind(this)} onMouseOut={clearIncreaseTimeHold.bind(this)} onMouseUp={clearIncreaseTimeHold.bind(this)}>
                                            <SUIIconShim>
                                                <FontAwesomeIcon icon={faPlus} />
                                            </SUIIconShim>
                                        </button>
                                    </div>
                                </Form.Field>
                            </Form>
                            :
                            null
                    }
                </Card.Description>
            </Card.Content>
            {!props.isLoading ?
                <Card.Content extra>
                    {!!props.substitutes[props.id] ?
                        <Button
                            negative
                            fluid
                            onClick={() => props.deleteSubMutation(props.substitutes[props.id].id)}
                        >
                            Delete
                        </Button>
                        :
                        !isCreating ?
                            <Button
                                primary
                                fluid
                                onClick={() => setIsCreating(true)}
                            >
                                Configure
                            </Button>
                            :
                            <Button.Group widths={2}>
                                <Button
                                    onClick={() => setIsCreating(false)}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    positive
                                    onClick={handleCreateSub.bind(this)}
                                >
                                    Save
                                </Button>
                            </Button.Group>
                    }
                </Card.Content>
                :
                null
            }
        </Card>
    )
}
import { Redirect, generatePath } from 'react-router';
import {useTutorial} from '../hooks/useTutorial';

import tutorialData from '../tutorials/tactics';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Loader } from 'semantic-ui-react';
import useUser from '../../common/useUser';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function TacticsTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    const userQuery = useUser(!cachedTutorialData);

    const squadQuery = useQuery(
        ['getSquad', userQuery.club?.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${userQuery.club.id}/teams/youth/playerRegistrations?context=list&ownershipClaim=true`),
        {
            enabled: !cachedTutorialData && !!userQuery.club?.id
        }
    )

    useEffect(() => {
        if(!cachedTutorialData && userQuery.isSuccess && squadQuery.isSuccess) {
            var compiledTutorialData = cloneDeep(tutorialData);

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 10:
                    case 11:
                    case 14:
                        step.path = {
                            pathname: generatePath(step.path, {teamType: 'first'}),
                            state: {
                                selectForPositionIndex: false,
                                configureSubstitutions: false,
                                configureRoles: false
                            }
                        }
                        break;
                    case 2:
                    case 3:
                        step.path = {
                            pathname: generatePath(step.path, {teamType: 'first'}),
                            state: {
                                selectForPositionIndex: 0
                            }
                        }
                        break;
                    case 9:
                        step.path = {
                            pathname: generatePath(step.path, {teamType: 'first'}),
                            state: {
                                configureSubstitutions: true
                            }
                        }
                        break;
                    case 12:
                    case 13:
                        step.path = {
                            pathname: generatePath(step.path, {teamType: 'youth'})
                        }
                        break;
                    case 15:
                    case 16:
                        step.path = {
                            pathname: generatePath(step.path, {teamType: 'first'}),
                            state: {
                                selectForPositionIndex: false,
                                configureSubstitutions: false,
                                configureRoles: true
                            }
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData, userQuery.isSuccess, squadQuery.isSuccess]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
import {Segment, Form, Menu, Dropdown} from 'semantic-ui-react';
import { FormattedDate } from 'react-intl';
import { useHistory, useLocation } from 'react-router';
import { useTutorial } from '../../tutorial/hooks/useTutorial';

import axios from "axios";
import { useState } from "react";
import { useQuery } from '@tanstack/react-query';

import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import isToday from 'dayjs/plugin/isToday';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { faHourglass } from '@fortawesome/free-regular-svg-icons';
import SUIIconShim from '../../common/components/SUIIconShim';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

dayjs.extend(utc);
dayjs.extend(isToday);
dayjs.extend(customParseFormat);

export default function FixtureDateSelector(props) {
    const location = useLocation();
    const history = useHistory();
    const {checkTutorial} = useTutorial();
    const [seasonDates, setSeasonDates] = useState([]);
    
    const isTutorial1 = checkTutorial('league', 7);

    const leagueSeasonId = location.state?.leagueSeasonId;

    const leagueSeasonFixtureRoundsQuery = useQuery(
        ['getLeagueSeasonFixtureRounds', leagueSeasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueSeasons/${leagueSeasonId}/fixtureRounds`),
        {
            enabled: leagueSeasonId !== undefined && seasonDates.length === 0,
            onSuccess: (data) => {
                const fixtureRoundDates = {}
                let defaultFixtureRounds;

                for(const fixtureRound of data.data.response) {
                    const d = dayjs(fixtureRound.date);
                    const localD = d.local().format('DD-MM-YYYY')
        
                    fixtureRoundDates[localD] = fixtureRoundDates[localD] || [];
                    fixtureRoundDates[localD].push(fixtureRound.round);

                    if(d.isToday()) {
                        defaultFixtureRounds = fixtureRoundDates[localD];
                    }
                }

                if(!defaultFixtureRounds) {
                    defaultFixtureRounds = fixtureRoundDates[Object.keys(fixtureRoundDates)[0]];
                }

                setSeasonDates(fixtureRoundDates);

                if(!location.state?.fixtureRoundDates) {
                    history.replace(
                        {
                            pathname: location.pathname,
                            state: {
                                ...location.state,
                                fixtureRounds: defaultFixtureRounds.join()
                            },
                            search: location.search
                        }
                    );
                }
            }
        }
    );

    return(
        <Segment inverted style={{margin: '1em 0'}}>
            <Form inverted unstackable>
                <Form.Group>
                    <Form.Field width={11}>
                        <label>Date</label>
                        <Dropdown
                            selection
                            options={Object.keys(seasonDates).map((seasonDate) => ({
                                key: seasonDates[seasonDate].join(),
                                text: (<FormattedDate value={dayjs(seasonDate, 'DD-MM-YYYY').toDate()} weekday='short' day='numeric' month='short' year='numeric' />),
                                value: seasonDates[seasonDate].join()
                            }))}
                            value={location.state?.fixtureRounds}
                            onChange={
                                (e, {value}) => history.replace(
                                    {
                                        pathname: location.pathname,
                                        state: {
                                            ...location.state,
                                            fixtureRounds: value,
                                            search: location.search
                                        }
                                    }
                                )
                            }
                            className={`dropdownNoMinWidth ${isTutorial1 ? 'glow' : ''}`}
                            loading={leagueSeasonFixtureRoundsQuery.isLoading}
                            disabled={leagueSeasonFixtureRoundsQuery.isLoading}
                        />
                    </Form.Field>
                    <Form.Field width={5}>
                        <label>Type</label>
                        <Menu compact size='tiny'>
                            <Menu.Item
                                active={location.state?.fixtureStatus === 'complete'}
                                onClick={() => history.replace({pathname: location.pathname, state: {...location.state, fixtureStatus: 'complete'}})}
                            >
                                <SUIIconShim style={{margin: 0}}>
                                    <FontAwesomeIcon icon={faCheck} />
                                </SUIIconShim>
                            </Menu.Item>
                            <Menu.Item
                                active={location.state?.fixtureStatus === 'planned'}
                                onClick={() => history.replace({pathname: location.pathname, state: {...location.state, fixtureStatus: 'planned'}})}
                            >
                                <SUIIconShim style={{margin: 0}}>
                                    <FontAwesomeIcon icon={faHourglass} />
                                </SUIIconShim>
                            </Menu.Item>
                        </Menu>
                    </Form.Field>
                </Form.Group>
            </Form>
        </Segment>
    );
}
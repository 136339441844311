import {Modal, Table, Label, Button, Loader, Icon, Dropdown, Placeholder} from 'semantic-ui-react';
import {useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios'
import InfoLabel from '../player/common/InfoLabel';
import find from 'lodash/find';
import ShortenedPlayerName from '../player/common/ShortenedName';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHeartbeat, faStar, faStreetView, faWarning } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';
import { useTutorial } from '../tutorial/hooks/useTutorial';

const positions = [
    'GK',
    'LB',
    'CB',
    'RB',
    'LWB',
    'CDM',
    'RWB',
    'LM',
    'CM',
    'RM',
    'LW',
    'CAM',
    'RW',
    'ST'
];

export default function PlayerSelection(props) {
    const {checkTutorial, goNextStep} = useTutorial();

    const {teamType} = useParams();
    const [filterPosition, setFilterPosition] = useState(props.initialFilterPosition);
    const [selectedPlayerRegIndex, setSelectedPlayerRegIndex] = useState(undefined);

    function handleClickFilterPosition(pos) {
        setFilterPosition(pos);

        if(isTutorial1 && pos === 'GK') {
            goNextStep();
        }
    }

    const {isLoading, data} = useQuery(
        ['getSquad', filterPosition],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.clubId}/teams/${teamType}/playerRegistrations?positions=${filterPosition}&ownershipClaim=true&context=list`),
        {
            onSuccess: (data) => {
                if(selectedPlayerRegIndex === undefined && data.data.response?.playerReg) {
                    const index = data.data.response.playerReg.findIndex((playerReg) => playerReg.teamTacticPosition?.id === props.teamTactic.teamTacticPositions[props.selectForTeamTacticPositionIndex].id);

                    if(index !== -1) {
                        setSelectedPlayerRegIndex(index);
                    } else {
                        setSelectedPlayerRegIndex(null);
                    }
                }
            }
        }
    );

    function selectPlayerReg(playerRegIndex) {
        setSelectedPlayerRegIndex(playerRegIndex);
    }

    function handleConfirm() {
        props.handleSelectPlayer(props.teamTactic.teamTacticPositions[props.selectForTeamTacticPositionIndex].id, data.data.response.playerReg[selectedPlayerRegIndex].id, isTutorial2);
    }

    const isTutorial1 = checkTutorial('tactics', 2);
    const isTutorial2 = checkTutorial('tactics', 3);

    return (
        <Modal
            open={true}
            onClose={props.cancelPlayerSelector}
        >
            <Modal.Header className='headerFixPadding'>
                Select a {props.selectForPosition || 'player'}
            </Modal.Header>
            <Modal.Content scrolling>
                <Label.Group>
                    {positions.map((pos) => 
                        <Label
                            className={isTutorial1 && pos === 'GK' ? 'glow labelHover' : 'labelHover'}
                            color={(pos === filterPosition) ? 'blue' : false}
                            onClick={handleClickFilterPosition.bind(this, pos)}
                        >
                            {pos}
                        </Label>
                    )}
                </Label.Group>
                <Table unstackable selectable>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell collapsing><FontAwesomeIcon icon={faStreetView} /></Table.HeaderCell>
                            <Table.HeaderCell collapsing><FontAwesomeIcon icon={faHeartbeat} /></Table.HeaderCell>
                            <Table.HeaderCell collapsing><FontAwesomeIcon icon={faStar} /></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {
                            !!data?.data?.response ?
                                data.data.response.playerReg.map((playerRegistration, i) => 
                                    <>
                                        <Table.Row
                                            onClick={selectPlayerReg.bind(this, i)}
                                            disabled={
                                                playerRegistration.playerContract.player.injuredUntil !== null ||
                                                playerRegistration.playerContract.player.numberOfGamesSuspended > 0
                                            }
                                            positive={data.data.response.playerReg[selectedPlayerRegIndex]?.id === playerRegistration.id}
                                        >
                                            <Table.Cell>
                                                {!!playerRegistration.teamTacticPosition &&
                                                    <Label
                                                        size='mini'
                                                        color={playerRegistration.teamTacticPosition.pitchSection !== null ? 'blue' : false}
                                                        style={{
                                                            width: '34px',
                                                            textAlign: 'center',
                                                            marginRight: '.78571429em',
                                                            verticalAlign: 'text-top'
                                                        }}
                                                    >
                                                        {namedPositions[playerRegistration.teamTacticPosition?.pitchSection] || 'SUB'}
                                                    </Label>
                                                }
                                                <ShortenedPlayerName player={playerRegistration.playerContract.player} />
                                                <InfoLabel
                                                    player={playerRegistration.playerContract.player}
                                                    playerContract={playerRegistration.playerContract}
                                                />
                                                {data.data.response.playerReg[selectedPlayerRegIndex]?.id === playerRegistration.id &&
                                                    <FontAwesomeIcon icon={faCheck} style={{marginLeft: '0.25rem'}} />
                                                }
                                            </Table.Cell>
                                            <Table.Cell>{playerRegistration.playerContract.player.position}</Table.Cell>
                                            <Table.Cell>{playerRegistration.playerContract.player.condition}%</Table.Cell>
                                            <Table.Cell>{playerRegistration.playerContract.player.currentAbility}</Table.Cell>
                                        </Table.Row>
                                        {data.data.response.playerReg[selectedPlayerRegIndex]?.id === playerRegistration.id && data.data.response.playerReg[selectedPlayerRegIndex]?.playerContract.player.position !== props.selectForPosition && !!props.selectForPosition &&
                                            <Table.Row error>
                                                <Table.Cell
                                                    style={{borderTop: 'none'}}
                                                    colSpan={4}
                                                >
                                                    <FontAwesomeIcon icon={faWarning} className='inlineIcon' />
                                                    <ShortenedPlayerName player={data.data.response.playerReg[selectedPlayerRegIndex].playerContract.player} /> is not a natural {props.selectForPosition}
                                                </Table.Cell>
                                            </Table.Row>
                                        }
                                    </>
                                )
                            :
                            !isLoading ?
                                <Table.Row>
                                    <Table.Cell colSpan={3}>No players found</Table.Cell>
                                </Table.Row>
                                :
                                [1, 2, 3].map((i) => (
                                    <Table.Row>
                                        <Table.Cell colSpan={4}>
                                            <Placeholder style={{width: '100%', maxWidth: '100%'}}>
                                                <Placeholder.Paragraph>
                                                    <Placeholder.Line />
                                                </Placeholder.Paragraph>
                                            </Placeholder>
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                        }
                    </Table.Body>
                </Table>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => props.cancelPlayerSelector()}>Cancel</Button>
                <Button
                    positive
                    onClick={handleConfirm.bind(this)}
                    className={isTutorial2 && 'glow'}
                >Select player</Button>
            </Modal.Actions>
        </Modal>
    );
}

const namedPositions = {
    1: 'LB',
    7: 'CB',
    12: 'GK',
    13: 'CB',
    19: 'CB',
    25: 'RB',
    2: 'LWB',
    8: 'CDM',
    14: 'CDM',
    20: 'CDM',
    26: 'RWB',
    3: 'LM',
    9: 'CM',
    15: 'CM',
    21: 'CM',
    27: 'RM',
    4: 'LW',
    10: 'CAM',
    16: 'CAM',
    22: 'CAM',
    28: 'RW',
    11: 'ST',
    17: 'ST',
    23: 'ST'
}
import { Formik } from 'formik';
import {Form as SForm, Message, Divider, Loader, Segment} from "semantic-ui-react";
import {Form, Checkbox, Input, SubmitButton, Select} from 'formik-semantic-ui-react';
import sortBy from "lodash/sortBy";
import {Link} from 'react-router-dom';
import countries from '../../manager/components/new-manager/countries';
import Flag from '../../common/components/Flag';

import messages from '../../manager/components/new-manager/messages';

import {URL} from '../../common/lib/paths';

export default function RegisterInterface(props) {
    const handleSubmit = (values, actions) => {
        props.handleRegisterSubmit(values, actions);
    }

    const registerErrorsDescription = {
        email: 'This email address is already registered',
        name: 'This username is already taken',
        unknown: 'Something went wrong. Please try again later.'
    }

    if(props.isLoading) return (<Loader active />);

    for(const country of countries) {
        if(!messages[country.key]) {
            console.log(country.key);
        }
    }

    const nationOptions = countries.map((nation) => ({
        key: nation.key,
        value: nation.key,
        text: (<><Flag name={nation.key} /> {messages[nation.key].defaultMessage}</>)
    }))

    return(
        <Segment>
            <Formik
                initialValues={{
                    email: '',
                    username: '',
                    password: '',
                    passwordCtrl: '',
                    nationality: '',
                    TCs: false,
                    emailConsent: false,
                    duplicateAcc: false
                }}
                setSubmitting={props.isSubmitting}
                validate={values => {
                    const errors = {};

                    if(!values.email) {
                        errors.email = 'Email is required';
                    } else if(values.email.length > 255 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = 'Email address invalid';
                    }

                    if(!values.username) {
                        errors.username = 'Username is required';
                    } else if(values.username.length < 3 || values.username.length > 14) {
                        errors.username = 'Your username must be between 3 and 14 characters';
                    } else if(!values.username.match('^[A-Za-z0-9]+$')) {
                        errors.username = 'Username must contain letters and numbers only'
                    }

                    if(!values.password) {
                        errors.password = 'Password is required';
                    } else if(values.password.length < 5 ) {
                        errors.password = 'Password must be at least 5 characters long';
                    }

                    if(!values.passwordCtrl) {
                        errors.passwordCtrl = 'Password is required';
                    } else if(values.passwordCtrl !== values.password) {
                        errors.passwordCtrl = 'Passwords do not match';
                    }

                    if(!values.nationality) {
                        errors.nationality = 'Nationality is required';
                    }

                    if(!values.TCs) {
                        errors.TCs = 'You must accept our Terms and Conditions';
                    }

                    if(!values.duplicateAcc) {
                        errors.duplicateAcc = 'Multiple accounts are not allowed. We regularly monitor for players using more than one account and remove these from the game.';
                    }
                    
                    return errors;
                }}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({errors}) => (
                    <Form error={Object.keys(errors).length > 0 || props.registerError !== null} success={props.isSuccess}>
                        <SForm.Field required error={!!errors.email || props.registerError === 'email'}>
                            <label>Email</label>
                            <Input
                                name='email'
                                placeholder='Email'
                            />
                            {errors.email && <Message error content={errors.email} />}
                            {props.registerError === 'email' && <Message error content={registerErrorsDescription.email} />}
                        </SForm.Field>
                        <SForm.Field required error={!!errors.username || props.registerError === 'name'}>
                            <label>Username</label>
                            <Input
                                type='text'
                                name='username'
                                placeholder='Username'
                            />
                            {errors.username && <Message error content={errors.username} />}
                            {props.registerError === 'name' && <Message error content={registerErrorsDescription.name} />}
                            <p style={{fontSize: '0.8em'}}>Between 3-14 characters. This is the name of your manager in game, and is visible to other players.</p>
                        </SForm.Field>
                        <SForm.Field required error={!!errors.nationality}>
                            <label>Nationality</label>
                            <Select
                                name='nationality'
                                placeholder='Select country'
                                search
                                options={nationOptions}
                                selectOnBlur={false}
                            />
                            {errors.nationality && <Message error content={errors.nationality} />}
                        </SForm.Field>
                        <Divider horizontal />
                        <SForm.Field required error={!!errors.password}>
                            <label>Password</label>
                            <Input
                                type='password'
                                name='password'
                                placeholder='Password'
                            />
                            {errors.password && <Message error content={errors.password} />}
                            <p style={{fontSize: '0.8em'}}>At least 5 characters.</p>
                        </SForm.Field>
                        <SForm.Field required error={!!errors.passwordCtrl}>
                            <label>Re-type password</label>
                            <Input
                                type='password'
                                name='passwordCtrl'
                                placeholder='Password'
                            />
                            {errors.passwordCtrl && <Message error content={errors.passwordCtrl} />}
                        </SForm.Field>
                        <Divider horizontal />
                        <SForm.Field error={!!errors.TCs}>
                            <Checkbox
                                name='TCs'
                                label={<label>I agree to the Terms and Conditions</label>}
                            />
                            {errors.TCs && <Message error content={errors.TCs} />}
                        </SForm.Field>
                        <SForm.Field>
                            <Checkbox
                                name='emailConsent'
                                label='I would like to receive email updates from Manager.Soccer'
                            />
                        </SForm.Field>
                        <SForm.Field error={!!errors.duplicateAcc}>
                            <Checkbox
                                name='duplicateAcc'
                                label='I do not have another Manager.Soccer account'
                            />
                            {errors.duplicateAcc && <Message error content={errors.duplicateAcc} />}
                        </SForm.Field>
                        <Message success>
                            <Message.Header>Registration successful</Message.Header>
                            <p>You have successfully registered. You can now <Link to={URL.login}>login</Link> and choose your first club!</p>
                        </Message>
                        <SubmitButton positive disabled={props.isSuccess}>Register</SubmitButton>
                    </Form>
                )}
            </Formik>
        </Segment>
    )
}
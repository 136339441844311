import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

const initialFilterState = {
    filterOverlayOpen: false,
    page: 1,
    filterPositions: [],
    filterNationShortcode: null,
    filterType: null,
    filterTransferListed: false,
    filterLoanListed: false,
    filterContractStatus: false,
    filterContractLength: null,
    filterValue: {minimum: 0, maximum: 200},
    filterRating: {minimum: 0, maximum: 100},
    filterAge: {minimum: 15, maximum: 40},
    filterOwnPlayers: false,
    filterUnavailable: false,
    filterUninterested: false,
    filterCityId: null,
    filterRetired: null
}

function filterStateReducer(currentFilterState, action) {
    let newFilterState = cloneDeep(currentFilterState);

    switch(action.name) {
        case 'toggleFilterOverlay':
            newFilterState.filterOverlayOpen = !currentFilterState.filterOverlayOpen;
            break;
        case 'updateFilters':
            newFilterState.filterPositions = action.value.filterPositions;
            newFilterState.filterNationShortcode = action.value.filterNationShortcode;
            newFilterState.filterType = action.value.filterType;
            newFilterState.filterTransferListed = action.value.filterTransferListed;
            newFilterState.filterLoanListed = action.value.filterLoanListed;
            newFilterState.filterContractStatus = action.value.filterContractStatus;
            newFilterState.filterContractLength = action.value.filterContractLength;
            newFilterState.filterValue = action.value.filterValue;
            newFilterState.filterRating = action.value.filterRating;
            newFilterState.filterAge = action.value.filterAge;
            newFilterState.filterOwnPlayers = action.value.filterOwnPlayers;
            newFilterState.filterUnavailable = action.value.filterUnavailable;
            newFilterState.filterUninterested = action.value.filterUninterested;
            newFilterState.filterCityId = action.value.filterCityId;
            newFilterState.filterRetired = action.value.filterRetired;
            newFilterState.filterOverlayOpen = false;
            newFilterState.page = 1;
            break;
        case 'clearFilters':
            newFilterState = {...newFilterState, ...initialFilterState};
            break;
        case 'nextPage':
            newFilterState.page = currentFilterState.page+1;
            break;
        case 'previousPage':
            newFilterState.page = currentFilterState.page-1;
            break;
    }
    
    return newFilterState;
}

function getFilterQueryParams(filterState, favPlayers = false, nationId) {
    const params = [`page=${filterState.page}`, `favPlayers=${favPlayers}`];

    if(filterState.filterPositions.length > 0) {
        params.push(`positions=${filterState.filterPositions.join(',')}`)
    }

    if(!!filterState.filterNationShortcode) {
        params.push(`nationGroupShortcode=${filterState.filterNationShortcode}`);
    }

    if(!!filterState.filterType) {
        params.push(`type=${filterState.filterType}`);
    }

    if(
        filterState.filterRating.minimum !== initialFilterState.filterRating.minimum ||
        filterState.filterRating.maximum !== initialFilterState.filterRating.maximum
    ) {
        params.push(`ratingMin=${filterState.filterRating.minimum}`);
        params.push(`ratingMax=${filterState.filterRating.maximum}`);
    }

    if(
        filterState.filterAge.minimum !== initialFilterState.filterAge.minimum||
        filterState.filterAge.maximum !== initialFilterState.filterAge.maximum
    ) {
        params.push(`ageMin=${filterState.filterAge.minimum}`);
        params.push(`ageMax=${filterState.filterAge.maximum}`);
    }

    if(
        filterState.filterValue.minimum !== initialFilterState.filterValue.minimum ||
        filterState.filterValue.maximum !== initialFilterState.filterValue.maximum
    ) {
        params.push(`valueMin=${filterState.filterValue.minimum*1000000}`);
        params.push(`valueMax=${filterState.filterValue.maximum*1000000}`);
    }

    if(filterState.filterContractLength !== null) {
        params.push(`contractExpiry=${filterState.filterContractLength}`);
    }

    if(filterState.filterTransferListed) {
        params.push(`transferListed=true`);
    }

    if(filterState.filterLoanListed) {
        params.push(`loanListed=true`);
    }

    if(filterState.filterOwnPlayers) {
        params.push('filterOwnPlayers=true');
    }

    if(filterState.filterUnavailable) {
        params.push('filterUnavailable=true');
    }

    if(filterState.filterUninterested) {
        params.push('filterUninterested=true');
    }

    if(filterState.filterContractStatus) {
        params.push(`freeAgent=true`);
    }

    if(filterState.filterCityId) {
        params.push(`cityId=${filterState.filterCityId}`);
    }

    if(!filterState.filterRetired) {
        params.push(`filterRetired=${filterState.filterRetired}`);
    }

    if(!!nationId) {
        params.push(`nationId=${nationId}`);
    }

    return params;
}

const filterProperties = [
    'filterPositions',
    'filterNationShortcode',
    'filterType',
    'filterRating',
    'filterValue',
    'filterAge',
    'filterTransferListed',
    'filterLoanListed',
    'filterContractStatus',
    'filterContractLength',
    'filterOwnPlayers',
    'filterCityId',
    'filterRetired',
    'filterUnavailable',
    'filterUninterested'
];

function getFilterStateFromPersistedState(filterState) {
    return ['page', ...filterProperties].map((property) => filterState[property]);
}

function isFilterActive(filterState) {
    const a = pick(filterState, filterProperties);
    const b = omit(initialFilterState, ['filterOverlayOpen', 'page']);

    return !isEqual(
        a,
        b
    );
}

export {
    initialFilterState,
    filterStateReducer,
    getFilterQueryParams,
    getFilterStateFromPersistedState,
    isFilterActive
}
import {useState, useEffect} from 'react';
import {useLocation, useParams} from 'react-router-dom';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import Fuse from 'fuse.js';
import map from 'lodash/map';

import NationLeaguesInterface from './interface';

import NarrowLayout from '../../common/components/NarrowLayout';
import FullNationNameWithFlag from '../../nation/common/FullNationNameWithFlag';
import NationOverview from '../toolbar/overview';
import NationLeaguesItem from '../toolbar/leagues';
import NationClubs from '../toolbar/clubs';
import NationPlayers from '../toolbar/players';

export default function NationLeagues(props) {
    const {nationId} = useParams();
    const [filteredLeagues, setFilteredLeagues] = useState([]);
    const [searchValue, setSearchValue] = useState('');

    const location = useLocation();

    const leagueLevel = location.state?.leagueLevel || 1;

    const nationQuery = useQuery(
        ['getNation', nationId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nations/${nationId}`),
        {
            enabled: nationId !== undefined
        }
    );

    const nationLeaguesQuery = useQuery(['getNationLeagues', nationId, leagueLevel], () =>
        axios.get(`${process.env.REACT_APP_APPHOST}/nations/${nationId}/leagues?level=${leagueLevel}`),
        {
            onSuccess: (data) => setFilteredLeagues(data.data.response),
            enabled: nationId !== undefined
        }
    );

    useEffect(() => {
        if(nationQuery.data?.data?.response) {
            props.setToolbar({
                title: <><FullNationNameWithFlag nation={nationQuery.data?.data?.response} /> leagues</>,
                navigation: [
                    <NationOverview key={1} nationId={nationId} />,
                    <NationLeaguesItem key={2} nationId={nationId} />,
                    <NationClubs key={3} nationId={nationId} />,
                    <NationPlayers key={4} nationId={nationId} />
                ]
            });
        }
    }, [nationId, nationQuery.data?.data?.response]);

    let fuse;

    if(!nationLeaguesQuery.isLoading) {
        fuse = new Fuse(nationLeaguesQuery.data?.data?.response, {
            keys: ['number'],
            ignoreLocation: true,
            threshold: 0.9,
            findAllMatches: true
        });
    }

    function handlePerformSearch() {
        if(searchValue === '') {
            setFilteredLeagues(nationLeaguesQuery.data?.data?.response || []);
        } else if(fuse !== undefined) {
            setFilteredLeagues(map(fuse.search(searchValue), 'item'));
        }
    }

    return(
        <NarrowLayout>
            <NationLeaguesInterface
                nationQuryIsLoading={nationQuery.isLoading}
                nationLeaguesQueryIsLoading={nationLeaguesQuery.isLoading}
                nationLeagueLevels={nationQuery.data?.data?.response?.nationLeagueLevels}
                nationLeagues={filteredLeagues}
                nation={nationQuery.data?.data.response}
                leagueLevel={leagueLevel}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                handlePerformSearch={handlePerformSearch.bind(this)}
            />
        </NarrowLayout>
    );
}
import { faArrowDown, faArrowUp, faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation } from "react-router";
import { Form, Label, Menu, Segment } from "semantic-ui-react";
import DataTable from "../../../common/components/DataTable";
import NarrowLayout from "../../../common/components/NarrowLayout";
import WideLayout from "../../../common/components/WideLayout";
import { clubName } from "../../../common/dataTableColumns/club/clubName";
import { playerName } from "../../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../../common/dataTableColumns/player/playerPosition";
import SeasonSelector from '../../../common/components/SeasonSelector';
import Component_Currency from "../../../common/components/Currency";

export default function ClubTransferHistoryInterface(props) {
    const location = useLocation();
    const history = useHistory();

    return(
        <div>
            <NarrowLayout>
                <Segment inverted style={{marginBottom: '1em'}}>
                    <Form inverted>
                        <SeasonSelector
                            isLoading={props.seasonsQueryIsLoading}
                            seasons={props.seasons}
                        />
                    </Form>
                </Segment>
            </NarrowLayout>
            <div style={{maxWidth: '300px', margin: '0 auto'}}>
                    <Menu pointing widths={2}>
                        <Menu.Item
                            active={location.state?.view === 'incoming'}
                            onClick={() => history.replace({pathname: location.pathname, state: {...location.state, view: 'incoming'}})}
                        >
                            <FontAwesomeIcon icon={faArrowDown} className='inlineIcon' />Transfers in
                            {!props.transferHistoryQueryIsLoading &&
                                <Label color='green'>
                                    {props.transferHistory.incoming.length}
                                </Label>
                            }
                        </Menu.Item>
                        <Menu.Item
                            active={location.state?.view === 'outgoing'}
                            onClick={() => history.replace({pathname: location.pathname, state: {...location.state, view: 'outgoing'}})}
                        >
                            <FontAwesomeIcon icon={faArrowUp} className='inlineIcon' />Transfers out
                            {!props.transferHistoryQueryIsLoading &&
                                <Label color='red'>
                                    {props.transferHistory.outgoing.length}
                                </Label>
                            }
                        </Menu.Item>
                    </Menu>
                </div>
                <WideLayout>
                    <TransferHistoryTable
                        isLoading={props.transferHistoryQueryIsLoading}
                        transferHistory={props.transferHistory}
                    />
                </WideLayout>
        </div>
    );
}

function TransferHistoryTable(props) {
    const location = useLocation();

    const columns = [
        playerName(
            (transferHistory) => transferHistory.player,
            (transferHistory) => transferHistory.player.playerContract,
            () => null
        ),
        playerPosition((transferHistory) => transferHistory.player.position),
        clubName(clubNameResolvers[location.state.view]),
        {
            name: <FontAwesomeIcon icon={faCoins} />,
            cell: (transferHistory) => {
                if(transferHistory.compensation !== null) {
                    return(<Component_Currency value={transferHistory.compensation} />)
                } else {
                    if(transferHistory.isLoan === true) {
                        return 'Loan'
                    } else {
                        return 'Free'
                    }
                }
            },
            compact: true,
            width: '60px',
            grow: 1
        },
    ];

    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.transferHistory[location.state.view]}
        />
    )
}

const clubNameResolvers = {
    incoming: (transferHistory) => transferHistory.fromClub,
    outgoing: (transferHistory) => transferHistory.toClub
}
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/lib/paths";

export default {
    key: 'news',
    name: 'News',
    loaderPath: URL.newsTutorial,
    icon: faNewspaper,
    description: 'Learn how to stay up to date with the world of Manager.Soccer',
    steps: [
        {
            key: 0,
            path: URL.home,
            description: 'This is the News tab, here you can see news about big score lines, transfers, old people and how great the game is.',
            summary: 'Read the news',
            task: 'browse'
        },
        {
            key: 1,
            path: URL.home,
            description: 'Press these arrows to scroll through all the hot gossip',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.home,
            description: 'Well done! You have completed the tutorial',
            summary: 'Congrats'
        }
    ]
}
import { faStreetView } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaffRoleName from "../../../staff/common/RoleName";

function staffRole(
    staffResolver
) {
    return {
        id: 'staffRole',
        name: <FontAwesomeIcon icon={faStreetView} />,
        selector: (staff) => <StaffRoleName staff={staffResolver(staff)} />,
        wrap: false,
        allowOverflow: false,
        compact: true,
        center: true,
        minWidth: '80px',
        maxWidth: '100px',
        grow: 1
    }
}

export {
    staffRole
}
import {defineMessages} from 'react-intl';

import {URL} from '../../common/lib/paths';
import ToolbarItem from '../../menu/toolbar/ToolbarItem';
import { faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { generatePath } from 'react-router';

const messages = defineMessages({
    text: {
        id: 'nation:toolbar:leagues',
        defaultMessage: 'Players',
        description: 'Link in the toolbar in the nation section for the nation players page'
    }
})

export default function NationPlayers(props) {
    return(
        <ToolbarItem
            path={{
                pathname: generatePath(URL.nationPlayers, {nationId: props.nationId})
            }}
            icon={faUserGroup}
            messages={messages}
            genericPath={URL.nationPlayers}
        />
    )
}
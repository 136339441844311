import { faGlobe, faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/lib/paths";

export default {
    key: 'world',
    name: 'World',
    loaderPath: URL.worldTutorial,
    icon: faGlobe,
    description: 'Discover the world of Manager.Soccer',
    steps: [
        {
            key: 0,
            path: URL.nationList,
            description: 'Here you can see an overview of different nations in the game. Select a country of your choice',
            summary: 'Select a country',
            task: 'interaction'
        },
        {
            key: 1,
            path: URL.nation,
            description: 'Once you have chosen your destination, select " Leagues" at the top of your screen to be able to view teams and league results of teams in that nation.',
            summary: 'Select leagues',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.nationLeagues,
            description: 'Next select the drop down beneath the word " level" to choose different leagues within that nation.',
            summary: 'Select league level',
            task: 'browse'
        },
        {
            key: 3,
            path: URL.nationLeagues,
            description: 'Have a particular league in mind? Type the name of the league into the box beneath the word "search"',
            summary: 'Search for a league',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.nationLeagues,
            description: 'Want to know who the Goat is? Select the world tab again',
            summary: 'Select world tab',
            task: 'interaction'
        },
        {
            key: 5,
            path: URL.nationList,
            description: 'Now select "records"',
            summary: 'Select records',
            task: 'interaction'
        },
        {
            key: 6,
            path: URL.worldRecords,
            description: 'Scroll up and down to see the world records',
            summary: 'View records',
            task: 'browse'
        },
        {
            key: 7,
            path: URL.worldRecords,
            description: 'Well done! You have completed the tutorial',
            summary: 'Congrats'
        }
    ]
}
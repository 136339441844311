import { faCheck, faHourglassHalf, faInfo, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defineMessages, FormattedMessage } from "react-intl";
import colours from "../colours";

//text+icon was a bit too verbose at mobile so just rolling with icons for now
//<FormattedMessage {...messages[resolver(rowData)]} values={{icon: statusIcons[resolver(rowData)]}} />

function auctionStatus(resolver) {
    return {
        id: 'auctionStatus',
        name: '',
        selector: (rowData) => statusIcons[resolver(rowData)],
        compact: true,
        grow: 1,
        width: '30px'
    }
}

export {
    auctionStatus
}

const statusIcons = {
    inProgress: <FontAwesomeIcon icon={faHourglassHalf} className='inlineIcon' />,
    completed: <FontAwesomeIcon icon={faCheck} className='inlineIcon' color={colours.positive} />,
    won: <FontAwesomeIcon icon={faCheck} className='inlineIcon' color={colours.positive} />,
    winning: <FontAwesomeIcon icon={faCheck} className='inlineIcon' color={colours.positive} />,
    lost: <FontAwesomeIcon icon={faXmark} className='inlineIcon' color={colours.negative} />,
    losing: <FontAwesomeIcon icon={faXmark} className='inlineIcon' color={colours.negative} />
}

const messages = defineMessages({
    inProgress: {
        id: 'dataTable:auctionrStatus:inProgress',
        defaultMessage: '{icon}In progress',
        description: 'Status message for auction which is in progress'
    },
    completed: {
        id: 'dataTable:auctionrStatus:completed',
        defaultMessage: '{icon}Finished',
        description: 'Status message for auction which has finished'
    },
    lost: {
        id: 'dataTable:auctionrStatus:lost',
        defaultMessage: '{icon}Lost',
        description: 'Status message for completed auction which has been lost'
    },
    losing: {
        id: 'dataTable:auctionrStatus:losing',
        defaultMessage: '{icon}Losing',
        description: 'Status message for in progress auction which manager has been outbid'
    },
    won: {
        id: 'dataTable:auctionrStatus:won',
        defaultMessage: '{icon}Won',
        description: 'Status message for completed auction which has been won'
    },
    winning: {
        id: 'dataTable:auctionrStatus:winning',
        defaultMessage: '{icon}Winning',
        description: 'Status message for in progress auction which manager is winning'
    }
})
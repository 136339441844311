import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

import CompareInterface from "./interface";

export default function Compare(props) {
    const {isLoading, data} = useQuery(
        ['getSquad', 'selection'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/teams/first/playerRegistrations?ownershipClaim=true&context=selection`)
    );

    useEffect(() => {
        props.setToolbar({
            title: 'Compare players',
            navigation: []
        });
    }, []);

    return(
        <CompareInterface
            manager={props.manager}
            isLoading={isLoading}
            playerRegistrations={data?.data?.response.playerReg}
            currentFormation={data?.data?.response.currentFormation}
        />
    );
}
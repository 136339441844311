import {Link, generatePath} from "react-router-dom";

import LeagueName from './Name';
import {URL} from "../../../common/lib/paths.js";

export default function LeagueLink(props) {
    return(
        <Link
            to={{
                pathname: generatePath(URL.league, {leagueId: props.league.id})
            }}
            className='noTextWrap'
        >
            <LeagueName league={props.league} />
        </Link>
    );
}
import {useQuery} from '@tanstack/react-query';
import axios from "axios";
import {Form} from 'semantic-ui-react';
import {FormattedMessage, FormattedDate, FormattedPlural} from 'react-intl';
import dayjs from 'dayjs';
import { useMemo } from 'react';

export default function ContractLengthOptions(props) {
    const {isLoading, data} = useQuery(
        ['getFutureSeasons'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/seasons?bounds=5`),
    );

    const contractLengthOptions = useMemo(() => {
        const opts = [];

        if(!!data) {
            const seasons = data.data.response;

            for(let i=1; i<seasons.length; i++) {
                if(dayjs.utc(seasons[i].startDate).isAfter(dayjs.utc(props.noContractShorterThan))) {
                    opts.push({
                        key: seasons[i].id,
                        value: seasons[i].id,
                        text: <FormattedMessage
                            {...messages.contractLengthValue}
                            values={{
                                date: <FormattedDate value={seasons[i].endDate} day='numeric' month='short' year='numeric' timeZone='UTC' />,
                                length: <FormattedPlural
                                    value={i}
                                    one={
                                        <FormattedMessage {...messages.seasonSingularText} values={{length: i}} />
                                    }
                                    other={
                                        <FormattedMessage {...messages.seasonPluralText} values={{length: i}} />
                                    }
                                />
                            }}
                        />
                    });
                }
            }
        }

        return opts;
    }, [data?.data?.response, props.noContractShorterThan]);

    return (
        <Form.Field>
            <label><FormattedMessage {...messages.contractLength} /></label>
            <Form.Dropdown
                selection
                loading={props.isLoading || isLoading}
                options={contractLengthOptions}
                value={props.selectedContractLength}
                onChange={(e, {value}) => props.setSelectedContractLength(value)}
            />
        </Form.Field>
    )
}

const messages = {
    contractLength: {
        id: "ContractNegotation.contractLength",
        defaultMessage: "Contract length offer",
        description: "Length of player/staff contract"
    },
    contractLengthValue: {
        id: "ContractOfferInterface:contractLengthDemandsDescriptor",
        defaultMessage: "{date} ({length})"
    },
    seasonSingularText: {
        id: "Layout_PersonContractOffer.seasonSingularText",
        defaultMessage: "{length} season",
        description: "Singular form of the word 'season'. Lowercase."
    },
    seasonPluralText: {
        id: "Layout_PersonContractOffer.seasonPluralText",
        defaultMessage: "{length} seasons",
        description: "Plural form of the word 'season'. Lowercase."
    },
}
import { useEffect } from 'react';
import {useQuery} from '@tanstack/react-query';
import {useParams} from 'react-router';
import axios from 'axios';

import ClubStaffInterface from "./interface";
import ClubProfileToolbarItem from '../toolbar/ClubProfile';
import TeamPlayersToolbarItem from '../toolbar/TeamPlayers';
import TeamFixturesToolbarItem from '../toolbar/TeamFixtures';
import ClubStaffToolbarItem from '../toolbar/ClubStaff';
import ClubTransfersToolbarItem from '../toolbar/ClubTransfers';
import TeamLeagueSeasonsToolbarItem from '../toolbar/TeamLeagueSeasons';
import TeamTacticsToolbarItem from '../toolbar/TeamTactics';
import TeamTrainingToolbarItem from '../toolbar/TeamTraining';
import TeamLeagueToolbarItem from '../toolbar/TeamLeague';
import { FormattedMessage } from 'react-intl';

export default function ClubStaff(props) {
    const {clubId, teamType = 'first'} = useParams();
    
    const {isLoading, data} = useQuery(
        ['getClubStaff', clubId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/staffContracts`),
        {
            enabled: clubId !== undefined
        }
    );

    useEffect(() => {
        if(data?.data?.response) {
            const isOwner = !!props.manager.club && (parseInt(clubId) === props.manager.club.id);

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{clubName: data.data.response.name}} />,
                navigation: [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamTacticsToolbarItem isMyClub={isOwner} teamType={teamType} key={3} />,
                    <TeamTrainingToolbarItem isMyClub={isOwner} teamType={teamType} key={4} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            })
        }
    }, [data?.data?.response]);

    return(
        <ClubStaffInterface
            isLoading={isLoading}
            departments={data?.data?.response?.clubDepartments}
        />
    );
}

const messages = {
    screenTitle: {
        id: 'todo',
        defaultMessage: '{clubName} staff',
        descripion: 'todo'
    }
}
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/lib/paths";

export default {
    key: 'fixtures',
    name: 'Fixtures',
    loaderPath: URL.fixturesTutorial,
    icon: faCalendarDays,
    description: 'Find out your fixtures for the season',
    steps: [
        {
            key: 0,
            path: URL.teamFixtures,
            description: 'Here are your fixtures for the season boss. Your team will play every team twice for a total of 18 games. Scroll down to view your next opponents.',
            summary: 'View your fixtures',
            task: 'browse'
        },
        {
            key: 1,
            path: URL.teamFixtures,
            description: 'Select one of your next opponents ',
            summary: 'Select an opponent',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.teamPlayers,
            description: 'Note that you are on the "players" tab. This will show you their current injuries and suspensions',
            summary: 'Assess opposition players',
            task: 'browse'
        },
        {
            key: 3,
            path: URL.teamPlayers,
            description: 'After reviewing the state of their players, select "fixtures" at the top of the page.',
            summary: 'Select opponent fixtures',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.teamFixtures,
            description: 'Then select the scoreline of their last game.',
            summary: 'Select previous fixture',
            task: 'interaction'
        },
        {
            key: 5,
            path: URL.leagueFixture,
            description: 'Then select line up.',
            summary: 'Select line-up',
            task: 'interaction'
        },
        {
            key: 6,
            path: URL.leagueFixture,
            description: 'This will show you your competitors favoured formation. Use the information gained from the tabs "players" and "fixtures" to select your formation and players for your upcoming match. Do they have injuries, suspensions or a weak link in their squad? This is the information you need to get as many points as possible.',
            summary: 'Assess opponent tactics',
            task: 'browse'
        },
        {
            key: 7,
            path: URL.teamFixtures,
            description: 'Well done! You have completed the tutorial',
            summary: 'Congrats'
        }
    ]
}
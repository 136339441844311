import { cloneDeep, isEqual, omit, pick } from "lodash"

const initialFilterState = {
    filterOverlayOpen: false,
    page: 1,
    filterLeagueLevel: null,
    filterNationShortcode: null,
    filterUnmanagedClubs: false
}

function filterStateReducer(currentFilterState, action) {
    let newFilterState = cloneDeep(currentFilterState);

    switch(action.name) {
        case 'toggleFilterOverlay':
            newFilterState.filterOverlayOpen = !currentFilterState.filterOverlayOpen;
            break;
        case 'updateFilters':
            newFilterState.filterLeagueLevel = action.value.filterLeagueLevel;
            newFilterState.filterNationShortcode = action.value.filterNationShortcode;
            newFilterState.filterUnmanagedClubs = action.value.filterUnmanagedClubs;
            newFilterState.filterOverlayOpen = false;
            newFilterState.page = 1;
            break;
        case 'clearFilters':
            newFilterState = {...newFilterState, ...initialFilterState};
            break;
        case 'nextPage':
            newFilterState.page = currentFilterState.page+1;
            break;
        case 'previousPage':
            newFilterState.page = currentFilterState.page-1;
            break;
    }

    return newFilterState;
}

function getFilterQueryParams(filterState, favClubs = false, nationId) {
    const params = [`page=${filterState.page}`, `favClubs=${favClubs}`];

    if(!!filterState.filterLeagueLevel) {
        params.push(`leagueLevel=${filterState.filterLeagueLevel}`);
    }

    if(!!filterState.filterNationShortcode) {
        params.push(`nationId=${filterState.filterNationShortcode}`);
    }

    if(filterState.filterUnmanagedClubs === true) {
        params.push(`unmanagedOnly=true`);
    }
    
    if(nationId !== undefined) {
        params.push(`nationId=${nationId}`);
    }

    return params;
}

const filterProperties = [
    'filterLeagueLevel',
    'filterNationShortcode',
    'filterUnmanagedClubs'
]

function getFilterStateFromPersistedState(filterState) {
    return ['page', ...filterProperties].map((property) => filterState[property]);
}

function isFilterActive(filterState) {
    const a = pick(filterState, filterProperties);
    const b = omit(initialFilterState, ['filterOverlayOpen', 'page']);

    return !isEqual(
        a,
        b
    );
}

export {
    initialFilterState,
    filterStateReducer,
    getFilterQueryParams,
    getFilterStateFromPersistedState,
    isFilterActive
}
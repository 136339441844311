function compileMessage(message) {
    for(const key of Object.keys(message)) {
        message[key].id = `message_${key}:${message[key].id}`
    }

    return message;
}

export {
    compileMessage
}
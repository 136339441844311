import { faHeartPulse } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function condition(
    resolver,
    hide,
    omit
) {
    return {
        id: 'playerCondition',
        name: <FontAwesomeIcon icon={faHeartPulse} />,
        selector: (playerReg) => <span style={{color: resolver(playerReg) < 80 ? 'red' : resolver(playerReg) < 90 ? 'orange' : 'black'}}>{resolver(playerReg)}%</span>,
        sortable: true,
        wrap: false,
        allowOverflow: false,
        compact: true,
        minWidth: '45px',
        maxWidth: '80px',
        center: true,
        hide,
        omit,
        grow: 1
    }
}

export {
    condition
}
import {faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import {URL} from '../../common/lib/paths';

export default {
    key: 'clubs',
    name: 'Clubs',
    loaderPath: URL.clubsTutorial,
    icon: faShieldHalved,
    description: 'Find out key information about your club',
    steps: [
        {
            key: 0,
            path: URL.clubProfile,
            description: (
                <>
                <p>Welcome to your club overview boss. Here you can see:</p>
                <ul>
                    <li>Club reputation, which is the determining factor behind the quality of players that will want to come play for you.</li>
                    <li>First and second squad ratings.</li>
                    <li>Trophys (or lack of) that your team has won.</li>
                </ul>
                </>
            ),
            summary: 'Have a look around',
            task: 'browse'
        },
        {
            key: 1,
            path: URL.clubProfile,
            description: (
                <>
                    <p>Now, if you want to learn more about your current club then this is tutorial for you. However this information wont help you actually get better at the game... With that said</p>
                    <p>Please select the "History" tab</p>
                </>
            ),
            summary: 'Select the history tab',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.teamHistory,
            description: 'Select the medal icon',
            summary: 'Select the medal button',
            task: 'interaction'
        },
        {
            key: 3,
            path: URL.teamHistory,
            description: 'Observe the legends of your great club.',
            summary: 'Explore club records',
            task: 'browse'
        },
        {
            key: 4,
            path: URL.teamHistory,
            description: 'Now, go back to your club profile',
            summary: 'Select club profile',
            task: 'interaction'
        },
        {
            key: 5,
            path: URL.clubProfile,
            description: 'If you want to see your current club\'s youth legacy, Select the name of your city',
            summary: 'Select the city',
            task: 'interaction'
        },
        {
            key: 6,
            path: URL.city,
            description: 'Now select "total players”',
            summary: 'Select total players',
            task: 'interaction'
        },
        {
            key: 7,
            path: URL.cityPlayers,
            description: 'this is every youth player your club has produced',
            summary: 'Have a look around',
            task: 'browse'
        },
        {
            key: 8,
            path: URL.clubProfile,
            description: 'Well done! You have completed the tutorial',
        }
    ]
}
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faExchange } from "@fortawesome/free-solid-svg-icons";
import {useTutorial} from '../../tutorial/hooks/useTutorial.js';

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_StaffContract.text",
		defaultMessage: "Transfer",
		description: "Link for staff contract page"
	}
});

export default function StaffTransferOfferToolbarItem(props) {
	const {checkTutorial, getSearchParamsString} = useTutorial()

	const isTutorial1 = checkTutorial('staff', 9);

	if(!props.staffId || !!props.isMyStaff) return null;

	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.staffTransferOffer, {staffId: props.staffId}),
				search: isTutorial1 && getSearchParamsString('staff', 10)
			}}
			genericPath={URL.staffTransferAuctionFree}
			icon={faExchange}
			messages={messages}
			glow={isTutorial1}
		/>
	);
}
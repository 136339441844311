import { generatePath } from "react-router";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const messages = {
    text: {
        id: 'transfers:toolbar:auctions',
        defaultMessage: 'Auctions',
        description: 'Link for transfer auctions in progress page'
    }
}

export default function Auctions(props) {
    return(
        <ToolbarItem
            path={generatePath(URL.playerTransferAuctions)}
            icon={faClock}
            messages={messages}
        />
    )
}
import { Button } from "semantic-ui-react";
import WideLayout from "../../common/components/WideLayout";
import usePersistedState from "../../common/lib/usePersistedState";
import StaffSearchFilterButton from "../../staff/filter/button/button";
import { filterStateReducer, initialFilterState, isFilterActive } from "../../staff/filter/lib";
import StaffListView from "../../staff/list/StaffListView";
import useGetStaff from "../../transfer/staffSearch/useGetStaff";
import FavClubsToolbarItem from "../toolbar/FavClubs";
import FavPlayersToolbarItem from "../toolbar/FavPlayers";
import FavStaffToolbarItem from "../toolbar/FavStaff";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";

const PERSISTED_STATE_KEY = 'favStaffFilter';

export default function FavStaff(props) {
    const {persistedState, updatePersistedState} = usePersistedState(PERSISTED_STATE_KEY, initialFilterState);

    useEffect(() => {
        props.setToolbar({
            title: 'Favourite staff',
            navigation: [
                <FavPlayersToolbarItem key={1} />,
                <FavClubsToolbarItem key={2} />,
                <FavStaffToolbarItem key={3} />
            ]
        });
    }, []);

    const {isLoading, data} = useGetStaff(persistedState, true, true);

    return(
        <WideLayout>
            <StaffSearchFilterButton
                loading={isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey={PERSISTED_STATE_KEY}
            />
            <StaffListView
                isLoading={isLoading}
                staff={data?.data?.response}
            />
            <Button.Group>
                <Button
                    icon
                    disabled={persistedState.page === 1}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </SUIIconShim>
                </Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.page}
                </Button>
                <Button
                    icon
                    disabled={props.staff?.length < 10}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </SUIIconShim>
                </Button>
            </Button.Group>
        </WideLayout>
    );
}
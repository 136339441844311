import Flag from "../../common/components/Flag";
import FullNationName from './FullNationName';

export default function FullNationNameWithFlag(props) {
    return (
        <>
            <Flag name={props.nation.nationGroupShortcode} responsiveClass={props.responsiveClass} className='playerNationFlag' />
            <FullNationName nation={props.nation} />
        </>
    )
}
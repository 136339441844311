const SUIStyles = {
    table: {
        style: {
            border: '1px solid black',
            borderRadius: '0.28571429rem',
            backgroundColor: '#F9FAFB',
            margin: '1em 0'
        }
    },
    tableWrapper: {
        style: {
            display: 'block',
           '&:not(:first-child)': {
               marginTop: '1em'
           }
        }
    },
    headRow: {
        style: {
            minHeight: '46px',
            backgroundColor: 'unset',
            '& > div': {
                // backgroundColor: '#F9FAFB'
            },
            overflow: 'hidden'
        }
    },
    headCells: {
        style: {
            padding: '0.92857143em 0.78571429em',
            borderBottom: '1px solid rgba(34, 36, 38, 0.1)',
            fontSize: '1em',
            fontWeight: 'bold',
            backgroundColor: 'unset',
            '&:first-child': {
            //     borderRadius: '0.28571429rem 0em 0em 0em',
                paddingLeft: '0.78571429em',
            },
            // '&:last-child': {
            //     borderRadius: '0em 0.28571429rem 0em 0em',
            //     paddingRight: '0.78571429em'
            // }
        }
    },
    cells: {
        style: {
            '&[role="cell"]:first-child': {
                paddingLeft: '0.7em'
            },
            '&[role="cell"]:last-child': {
                // paddingRight: '0.7em'
            },
            '&:not([role="cell"])': {
                paddingLeft: '12px',
                paddingRight: '12px',
                borderBottom: '1px solid rgba(34, 36, 38, 0.1)'
            },
            '&[role="cell"]': {
                paddingTop: '0.5em',
                paddingBottom: '0.5em'
            },
            '& > div:not([data-tag="allowRowEvents"])': {
                display: 'flex',
                width: '100%',
                alignItems: 'center'
            }
        }
    },
    rows: {
        style: {
            backgroundColor: '#FFF',
            fontSize: '1em',
            border: 0,
            minHeight: 'unset',
            '&:not(:first-of-type)': {
                borderTop: '1px solid rgba(34, 36, 38, 0.1)',
                borderBottom: 'initial'
            },
            '&:first-of-type': {
                borderBottom: 'initial'
            },
            '& > div:not([role="cell"])': {
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: 'initial'
            }
        }
    },
    expanderCell: {
        style: {
            flex: '0 0 24px',
            minWidth: '0'
        }
    },
    expanderButton: {
        style: {
            padding: 0,
            width: '100%'
        }
    }
}

export { SUIStyles}
import {Label, Menu, Segment, Grid, Header, Statistic} from "semantic-ui-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faHourglass, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import {useHistory, useLocation} from 'react-router';

import StarRating from "../../common/components/StarRating";
import Component_RatingRoundall from "../../player/common/RatingRoundall";
import NarrowLayout from "../../common/components/NarrowLayout";
import { useTutorial } from "../../tutorial/hooks/useTutorial";

export default function ScoutingOverview(props) {
    const history = useHistory();
    const location = useLocation();
    const {checkTutorial, getSearchParamsString, tutorialStep} = useTutorial();

    const isTutorial1 = checkTutorial('transfers', 12);
    const isTutorial2 = checkTutorial('transfers', 11);

    return(
        <>
            <NarrowLayout>
                <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)'}}>
                    <span>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='inlineIcon' />
                        Scout network
                    </span>
                    <span style={{float: 'right', margin: 0}}>
                        {!props.isLoading && <StarRating rating={props.department.overallRating} />}
                    </span>
                </Header>
                <Segment attached className='attachedInvertedSegment'>
                    <Grid style={{margin: 0}}>
                        <Grid.Row columns={2} divided>
                            <Grid.Column style={styles.a}>
                                <Statistic size='tiny'>
                                    <Statistic.Value style={{height: '28px'}}>
                                        {!props.isLoading && <>{props.department.scoutingVelocity}<span style={{fontSize: '14px'}}>/day</span></>}
                                    </Statistic.Value>
                                    <Statistic.Label style={{marginTop: '8px'}}>Reports</Statistic.Label>
                                </Statistic>
                            </Grid.Column>
                            <Grid.Column  style={styles.a}>
                                <Statistic size='tiny'>
                                    <Statistic.Value>
                                        {!props.isLoading && <Component_RatingRoundall rating={props.department.staffRating} disableFloat={true} size={2} />}
                                    </Statistic.Value>
                                    <Statistic.Label>Staff rating</Statistic.Label>
                                </Statistic>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </NarrowLayout>
            <div style={{maxWidth: '300px', margin: '1em auto'}}>
                <Menu widths={2}>
                    <Menu.Item
                        active={location.state?.status === 'completed'}
                        onClick={() => history.replace({pathname: location.pathname, state: {...location.state, status: 'completed'}, search: isTutorial2 && getSearchParamsString('transfers', tutorialStep+1)})}
                        className={isTutorial2 && 'glow'}
                    >
                        <FontAwesomeIcon icon={faCheck} className='inlineIcon' />
                        Complete
                        {!props.isLoading && <Label color='blue'>{props.counts.completedCount}</Label>}
                    </Menu.Item>                    
                    <Menu.Item
                        active={location.state?.status === 'pending'}
                        onClick={() => history.replace({pathname: location.pathname, state: {...location.state, status: 'pending'}, search: isTutorial1 && getSearchParamsString('transfers', tutorialStep+1)})}
                        className={isTutorial1 && 'glow'}
                    >
                        <FontAwesomeIcon icon={faHourglass} className='inlineIcon' />
                        In Progress
                        {!props.isLoading && <Label color='blue'>{props.counts.pendingCount}</Label>}
                    </Menu.Item>
                </Menu>
            </div>
        </>
    )
}

const styles = {
    a: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    }
}

import {FormattedMessage} from 'react-intl';
import Flag from '../../common/components/Flag';

export default function TeamName(props) {
    if(!props.team?.type || !props.team?.club?.name) {
        throw new Error('missing params')
    }

    return (
        <>
            {!!props.team.club.nation?.nationGroupShortcode && props.nation === true && <Flag name={props.team.club.nation.nationGroupShortcode} />}
            <FormattedMessage
                {...messages[props.team.type]}
                values={{clubName: props.team.club.name}}
            />
        </>
    )
}

const messages = {
    'first': {
        id: 'teamNames:first',
        defaultMessage: '{clubName}',
        description: 'Name for a clubs first teamm'
    },
    'youth': {
        id: 'teamNames:youth',
        defaultMessage: '{clubName} U18',
        description: 'Name for a clubs youth team'
    }
}
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import {URL} from '../../common/lib/paths';

export default {
    key: 'staff',
    name: 'Staff',
    loaderPath: URL.staffTutorial,
    icon: faUserTie,
    description: 'Learn how to hire and fire staff',
    steps: [
        {
            key: 0,
            path: URL.clubStaff,
            description: 'Ready for your daily dose of power? Here you will hire and fire your way to a better training, youth recruits, scout reports and the health of your players',
            summary: 'Intro',
            task: 'info'
        },
        {
            key: 1,
            path: URL.clubStaff,
            description: 'Scroll down to assess the state of your staff.',
            summary: 'Assess your current staff',
            task: 'browse'
        },
        {
            key: 2,
            path: URL.clubStaff,
            description: 'If you find yourself unhappy with the current group then select “find coaches”',
            summary: 'Find new staff',
            task: 'interaction'
        },
        {
            key: 3,
            path: URL.transferSearchStaff,
            description: 'Then select “filter”',
            summary: 'Filter staff',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.transferSearchStaff,
            description: 'Now select the box “free agents”',
            summary: 'Select free agents',
            task: 'interaction'
        },
        {
            key: 5,
            path: URL.transferSearchStaff,
            description: 'Now check the box “Interested staff only”',
            summary: 'Select interested staff only',
            task: 'interaction'
        },
        {
            key: 6,
            path: URL.transferSearchStaff,
            description: 'Now select apply',
            summary: 'Apply the filters',
            task: 'interaction'
        },
        {
            key: 7,
            path: URL.transferSearchStaff,
            description: 'Spend time to find the ideal candidate, use the arrows at the bottom of the screen to broaden the search',
            summary: 'Review candidates',
            task: 'browse'
        },
        {
            key: 8,
            path: URL.transferSearchStaff,
            description: 'Pick the staff you wish to acquire by selecting their name.',
            summary: 'Select staff target',
            task: 'interaction'
        },
        {
            key: 9,
            path: URL.staffProfile,
            description: 'Select “transfer” at the top of the page.',
            summary: 'Select transfer tab',
            task: 'interaction'
        },
        {
            key: 10,
            path: URL.staffTransferOffer,
            description: 'If you currently have a vacancy in this position then you can either give them what they are asking for or try to get more years of servitude by paying them more.',
            summary: 'Offer contract',
            task: 'browse'
        },
        {
            key: 11,
            path: URL.clubStaff,
            description: 'Well done! You have completed the tutorial',
        }
    ]
}
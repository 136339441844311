import { clubName } from "../../../../common/dataTableColumns/club/clubName";
import { leagueName } from "../../../../common/dataTableColumns/leagueName";
import {date} from '../../../../common/dataTableColumns/date';
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import DataTable from '../../../../common/components/DataTable';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import WorldNationsToolbarItem from "../../toolbar/WorldNationsToolbarItem";
import WorldClubsToolbarItem from "../../toolbar/WorldClubsToolbarItem";
import WorldCupsToolbarItem from "../../toolbar/WorldCupsToolbarItem";
import WorldRecordsToolbarItem from "../../toolbar/WorldRecordsToolbarItem";
import MediumLayout from "../../../../common/components/MediumLayout";
import RecordFilter from "../filter";

export default function ClubWinRateRecords(props) {
    const [timePeriod, setTimePeriod] = useState('allTime');
    const [nationGroupShortcode, setNationGroupShortcode] = useState(null);

    useEffect(() => {
        props.setToolbar({
            title: 'Club win rate records',
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        });
    }, []);

    const winRateRecordsQuery = useQuery(
        ['getWinRateRecords', timePeriod, nationGroupShortcode],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/world/records/clubs?record=winRate&timePeriod=${timePeriod}${!!nationGroupShortcode ? `&nationGroupShortcode=${nationGroupShortcode}` : ''}`)
    );

    return(
        <MediumLayout>
            <RecordFilter
                validTimePeriodFilters={['allTime', 'anySeason', 'seasonId']}
                nationFilter={true}
                selectedTimePeriod={timePeriod}
                handleTimePeriodChange={setTimePeriod}
                selectedNationGroupShortcode={nationGroupShortcode}
                handleNationGroupShortcodeChange={setNationGroupShortcode}
            />
            {timePeriod === 'allTime' ?
                <AllTimeWinRateRecordsInterface
                    isLoading={winRateRecordsQuery.isLoading}
                    records={winRateRecordsQuery.data?.data?.response}
                />
                :
                <SeasonWinRateRecordsInterface
                    isLoading={winRateRecordsQuery.isLoading}
                    records={winRateRecordsQuery.data?.data?.response}
                />
            }
        </MediumLayout>
    );
}

function AllTimeWinRateRecordsInterface(props) {
    const columns = [
        clubName((club) => club),
        {
            name: <FontAwesomeIcon icon={faPercent} />,
            selector: (club) => `${Math.round(club.avgWinRate * 100)}%`,
            grow: 1,
            compact: true,
            minWidth: '60px',
            maxWidth: '80px'
        }
    ];

    return (
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}

function SeasonWinRateRecordsInterface(props) {
    const columns = [
        clubName((club) => club),
        leagueName((club) => club.league),
        date(
            'season',
            (club) => club.season.startDate,
            false
        ),
        {
            name: <FontAwesomeIcon icon={faPercent} />,
            selector: (club) => `${Math.round(club.winRate * 100)}%`,
            grow: 1,
            compact: true,
            minWidth: '60px',
            maxWidth: '80px'
        }
    ];

    return (
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    );
}
import { faCircleExclamation, faClock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { FormattedDate, FormattedMessage, FormattedTime } from "react-intl"
import { Grid, Statistic } from "semantic-ui-react"
import ClubLink from "../../../club/components/common/Link"
import colours from "../../../common/colours"
import Component_Currency from "../../../common/components/Currency"
import TitledSegment from "../../../common/components/TitledSegment"

export default function PlayerTransferAuctionStatus(props) {
    return(
        <TitledSegment
            title='Status'
            noPadding={true}
        >
            <Grid className='gridReset transferAuctionBidStatus'>
                <Grid.Row columns={2}>
                    <Grid.Column>
                        <Statistic
                            size='mini'
                        >
                            <Statistic.Value>
                                {!props.isLoading && <Component_Currency value={props.playerTransferAuction?.playerTransferAuctionTopBidPaid.currentBid} />}
                            </Statistic.Value>
                            <Statistic.Label>
                                Highest bid
                            </Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                    <Grid.Column>
                        <Statistic
                            size='mini'
                            style={{width: '100%'}}
                        >
                            <Statistic.Value>
                                {!props.isLoading && <ClubLink club={props.playerTransferAuction?.playerTransferAuctionTopBidPaid.club} /> }
                            </Statistic.Value>
                            <Statistic.Label>
                                Club
                            </Statistic.Label>
                        </Statistic>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={1}>
                    <Grid.Column>
                        {!props.isLoading && props.playerTransferAuction.isFinished === true &&
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation} color={colours.negative} /> This auction has ended
                            </span>
                        }
                        {!props.isLoading && props.playerTransferAuction.isFinished === false &&
                            <span>
                                <FontAwesomeIcon icon={faClock} className='inlineIcon' />
                                <FormattedMessage
                                    {...messages.endDate}
                                    values={{
                                        endDate: <FormattedDate value={props.playerTransferAuction.endDateMin} day='numeric' month='short' />,
                                        endTimeMin: <FormattedTime value={props.playerTransferAuction.endDateMin} />,
                                        endTimeMax: <FormattedTime value={props.playerTransferAuction.endDateMax} />,
                                        endTimeInterval: props.playerTransferAuction.interval
                                    }}
                                />
                            </span>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </TitledSegment>
    )
}

const messages = {
    endDate: {
        id: 'playerTransferAuction:endDate',
        defaultMessage: 'Auction ends in {endTimeInterval} hours between {endTimeMin} - {endTimeMax}'
    }
}
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Component_Currency from "../components/Currency";

function currency(
    id,
    resolver,
    hide,
    sortable,
    widths = {width: '60px'}
) {
    return {
        id,
        name: <FontAwesomeIcon icon={faCoins} />,
        selector: (rowData) => <Component_Currency value={resolver(rowData)} />,
        sortable,
        sortFunction: sorter,
        wrap: false,
        center: true,
        allowOverflow: false,
        compact: true,
        grow: 1,
        ...widths,
        hide
    }

    function sorter(a, b) {
        a = resolver(a);
        b = resolver(b);

        return resolver(b) - resolver(a);
    }
}

export {
    currency
}
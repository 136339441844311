import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { useParams} from 'react-router';
import NarrowLayout from "../../../common/components/NarrowLayout";
import { useEffect } from "react";
import ShortenedPlayerName from "../../common/ShortenedName";
import ViewPlayerTransferOfferPaidInterface from "./interface";

export default function ViewPlayerTransferOfferPaid(props) {
    const {playerTransferOfferPaidId} = useParams();
    
    const getPlayerTransferOfferQuery = useQuery(
        ['getPlayerTransferOffer', playerTransferOfferPaidId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferOfferPaid/${playerTransferOfferPaidId}`)
    );

    useEffect(() => {
        if(getPlayerTransferOfferQuery.data?.data?.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getPlayerTransferOfferQuery.data.data.response.playerContract.player} /> offer</>,
                navigation: []
            })
        }
    }, [getPlayerTransferOfferQuery.data?.data?.response]);

    return(
        <NarrowLayout>
            <ViewPlayerTransferOfferPaidInterface
                isLoading={getPlayerTransferOfferQuery.isLoading}
                playerTransferOffer={getPlayerTransferOfferQuery.data?.data?.response}
                isMyPlayer={props.manager.club.id === getPlayerTransferOfferQuery.data?.data?.response.playerContract.club.id}
            />
        </NarrowLayout>
    );
}
import {FormattedMessage} from 'react-intl';

import {messages} from 'shared-messages';
import {formatValues} from '../../inbox/lib';

export default function MessageBodyInterface(props) {
    if(!props.message.type) {
        throw new Error('No message type supplied');
    }

    let values = {};
    if(props.message.values !== null) {
        for(const value of props.message.values) {
            values[value.name] = formatValues(value.values, value.entityType);
        }
    }

    return(
        <FormattedMessage {...messages[props.message.type].body} values={values} />
    );
}
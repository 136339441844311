import {SwitchTransition, CSSTransition} from 'react-transition-group-react-18';
import {useLocation, Redirect} from 'react-router';

import NarrowLayout from "../common/components/NarrowLayout";
import LeagueSeasonMenu from "./menu";

import TeamLeagueSeason from './teamLeagueSeason/data';
import PlayerTeamLeagueSeason from './playerTeamLeagueSeason/interface';
import LeagueSeasonFixtures from './fixtures/data';
import LeagueSeasonHistory from './history/data';
import StatsTableData from './playerTeamLeagueSeason/view/data';

export default function LeagueSeason(props) {
    const location = useLocation();

    if(!location.state?.leagueSeasonId) return null;

    if(!location.state?.view) {
        return(
            <Redirect
                to={{
                    pathname: location.pathname,
                    state: {
                        ...location.state,
                        view: 'table'
                    },
                    search: location.search
                }}
            />
        )
    }

    return(
        <div>
            <NarrowLayout>
                <LeagueSeasonMenu />
            </NarrowLayout>
            <SwitchTransition>
                <CSSTransition
                    key={location.state.view}
                    classNames='mainPage'
                    timeout={150}
                >
                    {switchView()}
                </CSSTransition>
            </SwitchTransition>
        </div>
    )

    function switchView() {
        switch(location.state.view) {
            case 'table':
                return(<TeamLeagueSeason {...props} />);
            case 'stats':
                return(
                    <PlayerTeamLeagueSeason
                        {...{...props, persistedStateKey: 'playerTeamLeagueSeasons'}}
                    >
                        <StatsTableData {...{...props, persistedStateKey: 'playerTeamLeagueSeasons'}} />
                    </PlayerTeamLeagueSeason>
                );
            case 'fixtures':
                return(<LeagueSeasonFixtures {...props} />);
            case 'history':
                return(<LeagueSeasonHistory {...props} />);
        }
    }
}
import { faTimeline } from "@fortawesome/free-solid-svg-icons"
import { useMemo, useState } from "react"
import { FormattedDate, FormattedTime } from "react-intl"
import { Placeholder } from "semantic-ui-react"
import ClubLink from "../../../club/components/common/Link"
import Component_Currency from "../../../common/components/Currency"
import NarrowLayout from "../../../common/components/NarrowLayout"
import TitledSegment from "../../../common/components/TitledSegment"
import PlayerTransferAuctionBidStatus from "./bidStatus"
import CreatePlayerTransferAuctionBid from "./createBid"
import PlayerTransferAuctionStatus from "./status"

export default function PlayerTransferAuctionInterface(props) {
    const [creatingBid, setCreatingBid] = useState(false);

    const isWinning = useMemo(() => {
        return props.playerTransferAuction?.playerTransferAuctionTopBidPaid.club.id === props.manager.club.id
    }, [props.playerTransferAuction]);

    const myLatestBid = useMemo(() => {
        if(props.isMyPlayer !== false) return;

        if(props.playerTransferAuction?.playerTransferAuctionTopBidPaid.club.id === props.manager.club.id) {
            return props.playerTransferAuction.playerTransferAuctionTopBidPaid;
        } else {
            return props.playerTransferAuction?.playerTransferAuctionBidPaid.find((bid) => bid.club.id === props.manager.club.id);
        }
    }, [props.playerTransferAuction, props.isMyPlayer]);

    function handleCreateBid() {
        setCreatingBid(true);
    }

    return(
        <NarrowLayout>
            <PlayerTransferAuctionStatus
                isLoading={props.isLoading}
                playerTransferAuction={props.playerTransferAuction}
            />
            {!props.isMyPlayer ?
                creatingBid === true || myLatestBid === undefined ?
                    <CreatePlayerTransferAuctionBid
                        setCreatingBid={setCreatingBid}
                        isLoading={props.isLoading}
                        playerValue={props.playerTransferAuction?.playerContract.player.value}
                        playerId={props.playerTransferAuction?.playerContract.player.id}
                        myLatestBid={myLatestBid}
                        currentBid={props.playerTransferAuction?.playerTransferAuctionTopBidPaid.currentBid}
                    />
                    :
                    <PlayerTransferAuctionBidStatus
                        isLoading={props.isLoading}
                        playerTransferAuction={props.playerTransferAuction}
                        handleCreateBid={handleCreateBid}
                        isWinning={isWinning}
                        myLatestBid={myLatestBid}
                    />
                :
                null
            }
            <BidHistory
                isLoading={props.isLoading}
                playerTransferAuctionTopBidPaid={props.playerTransferAuction?.playerTransferAuctionTopBidPaid}
                playerTransferAuctionBidPaid={props.playerTransferAuction?.playerTransferAuctionBidPaid}
            />
        </NarrowLayout>
    )
}

function BidHistory(props) {
    return(
        <TitledSegment
            title={`Bid history (${props.playerTransferAuctionBidPaid?.length+1})`}
            icon={faTimeline}
            isLoading={props.isLoading}
            placeholder={BidHistoryPlaceholder}
        >
            <div className='bidHistory'>
                <Bid bid={props.playerTransferAuctionTopBidPaid} />
                {props.playerTransferAuctionBidPaid?.map((bid) => (
                    <Bid bid={bid} key={bid.id} />
                ))}
            </div>
        </TitledSegment>
    )
}

function Bid(props) {
    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center'
        },
        cola: {
            flex: '1 1 auto'
        },
        colb: {
            flex: '0 1 auto'
        },
        club: {
            float: 'right',
            clear: 'right'
        },
        date: {
            float: 'right',
            clear: 'right'
        }
    }

    return(
        <div style={styles.container}>
            <div style={styles.cola}>
                <h3><Component_Currency value={props.bid.currentBid || props.bid.maxBid} /></h3>
            </div>
            <div style={styles.colb}>
                <span style={styles.club}><ClubLink club={props.bid.club} /></span>
                <span style={styles.date}><FormattedDate value={props.bid.createdAt} day='numeric' month='short' /> at <FormattedTime value={props.bid.createdAt} /></span>
            </div>
        </div>
    )
}

function BidHistoryPlaceholder() {
    return(
        <Placeholder>
            <Placeholder.Header>
                <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
                <Placeholder.Line />
            </Placeholder.Paragraph>
        </Placeholder>
    )
}
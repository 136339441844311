import { faPersonArrowUpFromLine } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Confirm } from "semantic-ui-react";
import SUIIconShim from "../../common/components/SUIIconShim";

export default function PromotePlayer(props) {
    const [open, setOpen] = useState(false);

    return(
        <Button
            icon
            onClick={() => setOpen(true)}
            color='black'
            floated='right'
        >
            <SUIIconShim><FontAwesomeIcon icon={faPersonArrowUpFromLine} fixedWidth /></SUIIconShim>
            <Confirm
                open={open}
                onCancel={() => {setOpen(false);console.log('closing')}}
                onConfirm={() => {props.handlePromoteToFirstTeam(); setOpen(false);}}
                header='Promote to first team'
                content='Are you sure you want to promote this player to the first team? You cannot undo this'
            />
        </Button>
    )
}
import { useReducer} from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

import {Button, Form, Dimmer, Divider, Grid, Checkbox, Dropdown} from "semantic-ui-react";
import usePersistedState from "../../../common/lib/usePersistedState.js";
import { initialFilterState as IFS, filterStateReducer as FSR } from "../lib.js";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useIntl } from "react-intl";
import { useTutorial } from "../../../tutorial/hooks/useTutorial.js";
import NationGroupNameWithFlag from "../../../nationGroup/common/NationGroupNameWithFlag.js";

const positions = [
    'GK',
    'LB',
    'CB',
    'RB',
    'LWB',
    'CDM',
    'RWB',
    'LM',
    'CM',
    'RM',
    'LW',
    'CAM',
    'RW',
    'ST'
];

const filterPositions = positions.map((position) => ({key: position, text: position, value: position}));

const styles = {
    overlayContainer: {
        position: "fixed",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        maxWidth: '800px',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottom: 0,
        zIndex: 99999
    },
    overlay: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        alignContent: "stretch",
        alignItems: "stretch"
    },
    dimmer: {
        order: 0,
        flex: "1 1 auto",
        alignSelf: "auto",
        zIndex: -1
    },
    filters: {
        order: 0,
        flex: "0 1 auto",
        alignSelf: "auto",
        overflow: "auto",
        maxHeight: "min-content",
        backgroundColor: "#FFFFFF"
    },
    form: {
        padding: "1rem 1rem 0 1rem"
    },
    submit: {
        padding: "0 1rem 1rem 1rem",
        order: 0,
        flex: "0 0 auto",
        alignSelf: "auto",
        backgroundColor: "#FFFFFF",
    }
};

function filterStateReducer(currentFilterState, action) {
    let newFilterState = {
        filterPositions: currentFilterState.filterPositions,
        filterNationShortcode: currentFilterState.filterNationShortcode,
        filterType: currentFilterState.filterType,
        filterTransferListed: currentFilterState.filterTransferListed,
        filterLoanListed: currentFilterState.filterLoanListed,
        filterContractStatus: currentFilterState.filterContractStatus,
        filterContractLength: currentFilterState.filterContractLength,
        filterValue: currentFilterState.filterValue,
        filterRating: currentFilterState.filterRating,
        filterAge: currentFilterState.filterAge,
        filterOwnPlayers: currentFilterState.filterOwnPlayers,
        filterUnavailable: currentFilterState.filterUnavailable,
        filterUninterested: currentFilterState.filterUninterested,
        filterCityId: currentFilterState.filterCityId,
        filterRetired: currentFilterState.filterRetired
    }

    switch(action.action) {
        case 'filterPosition':
            newFilterState.filterPositions = action.value;
            break;
        case 'filterNationShortcode':
            newFilterState.filterNationShortcode = action.value;
            break;
        case 'filterType':
            newFilterState.filterType = action.value;
            break;
        case 'filterTransferListed':
            newFilterState.filterTransferListed = !currentFilterState.filterTransferListed;
            break;
        case 'filterLoanListed':
            newFilterState.filterLoanListed = !currentFilterState.filterLoanListed;
            break;
        case 'filterValue':
            newFilterState.filterValue = action.value;
            break;
        case 'filterRating':
            newFilterState.filterRating = action.value;
            break;
        case 'filterAge':
            newFilterState.filterAge = action.value;
            break;
        case 'filterContractStatus':
            newFilterState.filterContractStatus = !currentFilterState.filterContractStatus;
            break;
        case 'filterContractLength':
            newFilterState.filterContractLength = action.value;
            break;
        case 'filterOwnPlayers':
            newFilterState.filterOwnPlayers = !currentFilterState.filterOwnPlayers;
            break;
        case 'filterUnavailable':
            newFilterState.filterUnavailable = !currentFilterState.filterUnavailable;
            break;
        case 'filterUninterested':
            newFilterState.filterUninterested = !currentFilterState.filterUninterested;
    }

    return newFilterState;
}

function sliderToNumb(val) {
    return parseInt(val);
}

function sliderFromStr(val) {
    return parseInt(val);
}

function valSliderToNumb(val) {
    if(val < 1) {
        return `£${val}m`
    } else {
        return `£${parseInt(val)}m`;
    }
}

function valSliderFromStr(val) {
    return parseInt(val);
}

export default function FilterOverlay(props) {
    const {persistedState, updatePersistedState} = usePersistedState(props.persistedStateKey, IFS);
    const intl = useIntl();
    const {checkTutorial, goNextStep} = useTutorial();

    const seasonsQuery = useQuery(
        ['getSeasons', 4],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/seasons?bounds=4`)
    );

    const nationsQuery = useQuery(
        ['getNationGroups'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nationGroups`)
    );

    const initialFilterState = {
        filterPositions: persistedState.filterPositions,
        filterNationShortcode: persistedState.filterNationShortcode,
        filterType: persistedState.filterType,
        filterTransferListed: persistedState.filterTransferListed,
        filterLoanListed: persistedState.filterLoanListed,
        filterContractStatus: persistedState.filterContractStatus,
        filterContractLength: persistedState.filterContractLength,
        filterValue: persistedState.filterValue,
        filterRating: persistedState.filterRating,
        filterAge: persistedState.filterAge,
        filterOwnPlayers: persistedState.filterOwnPlayers,
        filterUnavailable: persistedState.filterUnavailable,
        filterUninterested: persistedState.filterUninterested,
        filterCityId: persistedState.filterCityId,
        filterRetired: persistedState.filterRetired
    }

    const [filterState, filterDispatch] = useReducer(filterStateReducer, initialFilterState);

    function handleFilterPositions(e, {value}) {
        filterDispatch({action: 'filterPosition', value: value});
    }

    function handleFilterNationShortcode(e, {value}) {
        filterDispatch({action: 'filterNationShortcode', value: value || null});
    }

    function handleFilterType(e, {value}) {
        filterDispatch({action: 'filterType', value: value || null});
    }

    function handleFilterExpiry(e, {value}) {
        filterDispatch({action: 'filterContractLength', value: value || null});
    }

    function handleTransferListedButtonClick() {
        filterDispatch({action: 'filterTransferListed'});
        isTutorial2 && goNextStep();
    }

    function handleLoanListedButtonClick() {
        filterDispatch({action: 'filterLoanListed'});
    }

    function handleFilterOwnPlayersSet() {
        filterDispatch({action: 'filterOwnPlayers'});
    }

    function handleFilterUnavailableSet() {
        filterDispatch({action: 'filterUnavailable'});
    }

    function handleFilterUninterestedSet() {
        filterDispatch({action: 'filterUninterested'});
        isTutorial1 && goNextStep();
    }

    function handleValueSliderSet(render, handle, value, un, percent) {
        filterDispatch({action: 'filterValue', value: {
            minimum: (value[0] < 1 && value[0] > 0 ? Math.round(value[0] * 10) / 10 : parseInt(value[0])),
            maximum: (value[1] < 1 && value[1] > 0 ? Math.round(value[1] * 10) / 10 : parseInt(value[1]))
        }});
    }

    function handleRatingSliderSet(render, handle, value, un, percent) {
        filterDispatch({action: 'filterRating', value: {
            minimum: parseInt(value[0]),
            maximum: parseInt(value[1])
        }});
    }

    function handleAgeSliderSet(render, handle, value, un, percent) {
        filterDispatch({action: 'filterAge', value: {
            minimum: parseInt(value[0]),
            maximum: parseInt(value[1])
        }});
    }

    function handleContractStatusClick() {
        filterDispatch({action: 'filterContractStatus'});
    }

    function handleApplyFilterClick() {
        updatePersistedState(FSR(persistedState, {
            name: 'updateFilters',
            value: filterState
        }));
        isTutorial3 && goNextStep();
    }

    function handleCloseFilterClick() {
        updatePersistedState(FSR(persistedState, {
            name: 'toggleFilterOverlay'
        }));
    }

    const isTutorial1 = checkTutorial('transfers', 1);
    const isTutorial2 = checkTutorial('transfers', 3);
    const isTutorial3 = checkTutorial('transfers', 4);

    return(
        <div style={styles.overlayContainer}>
            <Dimmer active={true} page={true} />
            <div style={styles.overlay} className='transferSearchFilterOverlayTarget'>
                <div style={styles.dimmer}></div>
                <div style={styles.filters}>
                    <Form style={styles.form}>
                        <Form.Group widths='equal' unstackable style={{flexWrap: 'nowrap'}}>
                            <Form.Dropdown
                                fluid
                                label='Positions'
                                placeholder='All positions'
                                selection
                                multiple
                                options={filterPositions}
                                value={filterState.filterPositions}
                                onChange={handleFilterPositions}
                            />
                            <Form.Dropdown
                                selection
                                fluid
                                clearable
                                selectOnBlur={false}
                                placeholder='All nations'
                                label='Nation'
                                loading={nationsQuery.isLoading}
                                disabled={nationsQuery.isLoading || props.persistedStateKey === 'nationPlayersFilter'}
                                options={nationsQuery.data?.data.response.map((nation) => (
                                    {
                                        key: nation.shortcode,
                                        value: nation.shortcode,
                                        text: (<NationGroupNameWithFlag responsiveClass='nationFlag' shortcode={nation.shortcode} />)
                                    }
                                )) || []}
                                onChange={handleFilterNationShortcode}
                                value={filterState.filterNationShortcode}
                            />
                            {/* <Form.Dropdown
                                fluid
                                clearable
                                label='Type'
                                placeholder='All types'
                                selection
                                options={[{key: 'd', text: 'Defensive', value: 'd'}, {key: 's', text: 'Standard', value: 's'}, {key: 'a', text: 'Attacking', value: 'a'}]}
                                value={filterState.filterType}
                                onChange={handleFilterType}
                            /> */}
                        </Form.Group>
                        <Divider horizontal />
                        <Form.Field>
                            <label>Rating</label>
                            <Nouislider
                                range={{min: 0, max: 100}}
                                start={[
                                    filterState.filterRating.minimum,
                                    filterState.filterRating.maximum
                                ]}
                                step={1}
                                connect
                                tooltips={[{to:sliderToNumb, from:sliderFromStr}, {to:sliderToNumb, from:sliderFromStr}]}
                                onSet={handleRatingSliderSet.bind(this)}
                            />
                        </Form.Field>
                        <Divider horizontal />
                        <Form.Field>
                            <label>Value</label>
                            <Nouislider
                                range={{
                                    'min': [0, 0.1],
                                    '30%': [1,1],
                                    '50%': [20, 5],
                                    '75%': [50, 25],
                                    'max': [200]
                                }}
                                start={[filterState.filterValue.minimum, filterState.filterValue.maximum]}
                                step={1}
                                connect
                                tooltips={[{to:valSliderToNumb, from:valSliderFromStr}, {to:valSliderToNumb, from:valSliderFromStr}]}
                                onSet={handleValueSliderSet.bind(this)}
                            />
                        </Form.Field>
                        <Divider horizontal />
                        <Form.Group widths='equal' unstackable style={{flexWrap: 'nowrap'}}>
                            <Form.Field>
                                <label>Age</label>
                                <Nouislider
                                    range={{min: IFS.filterAge.minimum, max: IFS.filterAge.maximum}}
                                    start={[
                                        filterState.filterAge.minimum,
                                        filterState.filterAge.maximum
                                    ]}
                                    step={1}
                                    connect
                                    tooltips={[{to:sliderToNumb, from:sliderFromStr}, {to:sliderToNumb, from:sliderFromStr}]}
                                    onSet={handleAgeSliderSet.bind(this)}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Contract expiry</label>
                                <Form.Dropdown
                                    selection
                                    fluid
                                    clearable
                                    selectOnBlur={false}
                                    placeholder="All contract lengths"
                                    loading={seasonsQuery.isLoading}
                                    disabled={seasonsQuery.isLoading || filterState.filterContractStatus}
                                    options={seasonsQuery.data?.data.response.map((season) => (
                                        {
                                            key: season.id,
                                            value: season.id,
                                            text: intl.formatDate(season.endDate, {month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC'})
                                        }
                                    )) || []}
                                    onChange={handleFilterExpiry}
                                    value={filterState.filterContractLength}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Divider horizontal />
                        <Form.Group widths='equal' unstackable style={{flexWrap: 'nowrap'}}>
                            <Form.Field>
                                <label>Transfer listed</label>
                                <Button.Group widths="2" toggle size='small'>
                                    <Button
                                        active={!!filterState.filterTransferListed}
                                        disabled={filterState.filterContractStatus}
                                        onClick={handleTransferListedButtonClick.bind(this)}
                                        className={isTutorial2 && 'glow'}
                                    >
                                        Transfer
                                    </Button>
                                    <Button
                                        active={!!filterState.filterLoanListed}
                                        disabled={filterState.filterContractStatus}
                                        onClick={handleLoanListedButtonClick.bind(this)}
                                    >
                                        Loan
                                    </Button>
                                </Button.Group>
                            </Form.Field>
                            <Form.Field>
                                <label>Contract status</label>
                                <Button
                                    toggle
                                    active={filterState.filterContractStatus}
                                    disabled={filterState.filterTransferListed || filterState.filterLoanListed || filterState.filterContractLength || props.ownTeam}
                                    onClick={handleContractStatusClick.bind(this)}
                                    size="small"
                                    fluid
                                >
                                    Free agent
                                </Button>
                            </Form.Field>
                        </Form.Group>
                        <Divider horizontal />
                        <Form.Group widths="equal" unstackable style={{flexWrap: "nowrap"}}>
                            <Form.Field>
                                <Checkbox
                                    label='Include my players'
                                    checked={!filterState.filterOwnPlayers}
                                    onChange={handleFilterOwnPlayersSet.bind(this)}
                                    disabled={props.ownTeam}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    label='Include unavailable'
                                    checked={!filterState.filterUnavailable}
                                    onChange={handleFilterUnavailableSet.bind(this)}
                                    disabled={props.ownTeam}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths="equal" unstackable style={{flexWrap: "nowrap"}}>
                            <Form.Field>
                                <Checkbox
                                    label='Some other filter'
                                    checked={false}
                                    disabled={true}
                                    onChange={() => {}}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Checkbox
                                    label='Interested players only'
                                    checked={filterState.filterUninterested}
                                    onChange={handleFilterUninterestedSet.bind(this)}
                                    disabled={props.ownTeam}
                                    className={isTutorial1 && 'glow'}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Divider horizontal style={{margin: '0.5rem'}} />
                    </Form>
                </div>
                <div style={styles.submit}>
                    <Divider />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Button fluid onClick={handleCloseFilterClick.bind(this)}>Cancel</Button>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Button
                                    positive
                                    fluid
                                    onClick={handleApplyFilterClick.bind(this)}
                                    className={isTutorial3 && 'glow'}
                                >Apply</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        </div>
    );
}
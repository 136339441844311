import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faArrowRightArrowLeft } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_PlayerOffer.text",
		defaultMessage: "Offer",
		description: "Link for player offer page"
	}
});

export default function PlayerTransferToolbarItem(props) {
	if(props.playerClubId === props.myClubId || props.retirementSeasonId !== null) {
		return null;
	}

	return(
		<ToolbarItem
			path={generatePath(URL.playerAuction, {playerId: props.playerId})}
			genericPath={[URL.playerAuction, URL.playerTransferAuctionFree, URL.playerTransferAuction, URL.playerTransferOrLoanOffer, URL.viewPlayerTransferOffer]}
			icon={faArrowRightArrowLeft}
			messages={messages}
		/>
	);
}
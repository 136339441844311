import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { generatePath, useHistory } from "react-router";
import DataTable from "../../../../common/components/DataTable";
import TitledSegment from "../../../../common/components/TitledSegment";
import WideLayout from "../../../../common/components/WideLayout";
import { clubName } from "../../../../common/dataTableColumns/club/clubName";
import { playerName } from "../../../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../../../common/dataTableColumns/player/playerPosition";
import { transferOfferStatus } from "../../../../common/dataTableColumns/transferOfferStatus";
import {currentAbility} from '../../../../common/dataTableColumns/currentAbility';
import { URL } from "../../../../common/lib/paths";

export default function ClubPlayerTransferOfferLoanInterface(props) {
    return(
        <WideLayout>
            <TitledSegment
                title='Offers made'
                icon={faArrowUp}
                noPadding={true}
            >
                <ProposedPlayerTransferOfferLoanTable
                    isLoading={props.isLoading}
                    playerTransferOfferLoan={props.proposedPlayerTransferOfferLoan}
                />
            </TitledSegment>
            <TitledSegment
                title='Offers received'
                icon={faArrowDown}
                noPadding={true}
            >
                <ReceivedPlayerTransferOfferLoanTable
                    isLoading={props.isLoading}
                    playerTransferOfferLoan={props.receivedPlayerTransferOfferLoan}
                />
            </TitledSegment>
        </WideLayout>
    );
}

function ProposedPlayerTransferOfferLoanTable(props) {
    const history = useHistory();

    const columns = [
        transferOfferStatus((playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.status : 'pending'),
        playerName(
            (playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ?
                playerTransferOfferLoan.player
                :
                playerTransferOfferLoan.playerContract.player,
            (playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? 
                playerTransferOfferLoan.player.playerContract
                :
                playerTransferOfferLoan.playerContract,
            () => false
        ),
        clubName((playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.fromClub : playerTransferOfferLoan.playerContract.club),
        playerPosition((playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.player.position : playerTransferOfferLoan.playerContract.player.position),
        currentAbility((playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.player.currentAbility : playerTransferOfferLoan.playerContract.player.currentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.playerTransferOfferLoan}
            onRowClicked={(row) => history.push(row.isHistorical ? generatePath(URL.viewPlayerTransferOfferLoanHistory, {playerTransferOfferLoanHistoryId: row.id}) : generatePath(URL.viewPlayerTransferOfferLoan, {playerTransferOfferLoanId: row.id}))}
        />
    );
}

function ReceivedPlayerTransferOfferLoanTable(props) {
    const history = useHistory();

    const columns = [
        transferOfferStatus((playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.status : 'pending'),
        playerName(
            (playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.player : playerTransferOfferLoan.playerContract.player,
            (playerTransferOfferLoan) => playerTransferOfferLoan.playerContract,
            () => false
        ),
        clubName((playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.toClub : playerTransferOfferLoan.club),
        playerPosition((playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.player.position : playerTransferOfferLoan.playerContract.player.position),
        currentAbility((playerTransferOfferLoan) => playerTransferOfferLoan.isHistorical ? playerTransferOfferLoan.player.currentAbility : playerTransferOfferLoan.playerContract.player.currentAbility)
    ];
    
    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.playerTransferOfferLoan}
            onRowClicked={(row) => history.push(row.isHistorical ? generatePath(URL.viewPlayerTransferOfferLoanHistory, {playerTransferOfferLoanHistoryId: row.id}) : generatePath(URL.viewPlayerTransferOfferLoan, {playerTransferOfferLoanId: row.id}))}
        />
    );
}
//last verified against STAGE FOUR spreadsheet 3/10/2021

export default {
    positionMap: [
        "GK",
        "LB",
        "CB",
        "CB",
        "RB",
        "CDM",
        "CM",
        "CM",
        "CAM",
        "ST",
        "ST"
    ],
    pitchHalfMap: [
        12,
        1,
        7,
        19,
        25,
        14,
        9,
        21,
        16,
        11,
        23
    ]
}
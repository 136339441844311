import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';

import ListInterface from "./interface";
import NarrowLayout from '../../../common/components/NarrowLayout';

import WorldNationsToolbarItem from '../toolbar/WorldNationsToolbarItem';
import WorldClubsToolbarItem from '../toolbar/WorldClubsToolbarItem';
import WorldRecordsToolbarItem from '../toolbar/WorldRecordsToolbarItem';
import WorldCupsToolbarItem from '../toolbar/WorldCupsToolbarItem';

export default function List(props) {
    const nationQuery = useQuery(
        ['getNations'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nations`)
    );

    useEffect(() => {
        props.setToolbar({
            title: `All nations`,
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        });
    }, []);

    return(
        <NarrowLayout>
            <ListInterface
                isLoading={nationQuery.isLoading}
                nations={nationQuery.data?.data?.response}
            />
        </NarrowLayout>
    );
}
import {Table} from 'semantic-ui-react';
import {FormattedMessage, FormattedDate, FormattedPlural} from 'react-intl';
import Currency from './Currency';
import {default as getContractLength} from '../../player/common/ContractLength';

export default function ContractTable(props) {
    if(props.isLoading) return null;

    const contractLength = getContractLength(props.contractExpiry, props.currentSeason.endDate);

    return(
        <Table unstackable definition compact collapsing>
            <Table.Body>
                <Table.Row>
                    <Table.Cell><FormattedMessage {...messages.contractWageLabel} /></Table.Cell>
                    <Table.Cell><Currency value={props.contractSalary} /></Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell><FormattedMessage {...messages.contractLengthLabel} /></Table.Cell>
                    <Table.Cell>
                        <FormattedMessage
                            {...messages.contractLengthValue}
                            values={{
                                date: (
                                    <FormattedDate
                                        value={props.contractExpiry}
                                        day='numeric'
                                        month='short'
                                        year='numeric'
                                        timeZone='UTC'
                                    />
                                ),
                                length: <FormattedPlural
                                    value={contractLength}
                                    one={
                                        <FormattedMessage {...messages.seasonSingularText} values={{length: contractLength}} />
                                    }
                                    other={
                                        <FormattedMessage {...messages.seasonPluralText} values={{length: contractLength}} />
                                    }
                                />
                            }}    
                        />
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}

const messages = {
    contractWageLabel: {
        id: "Layout_PersonContractOffer.contractWageLabel",
        defaultMessage: "Wage",
        description: "Label for the table cell which contains the person's contract wage/salary demands"
    },
    contractLengthLabel: {
        id: "Layout_PersonContractOffer.contractLengthLabel",
        defaultMessage: "Expiry",
        description: "Label for the table cell which contains the person's contract length demands"
    },
    contractLengthValue: {
        id: "ContractOfferInterface:contractLengthDemandsDescriptor",
        defaultMessage: "{date} ({length})"
    },
    seasonSingularText: {
        id: "Layout_PersonContractOffer.seasonSingularText",
        defaultMessage: "{length} season",
        description: "Singular form of the word 'season'. Lowercase."
    },
    seasonPluralText: {
        id: "Layout_PersonContractOffer.seasonPluralText",
        defaultMessage: "{length} seasons",
        description: "Plural form of the word 'season'. Lowercase."
    },
}
import axios from "axios";
import { useEffect } from "react";
import { useQuery } from '@tanstack/react-query';
import { useParams } from "react-router";
import NarrowLayout from "../../../common/components/NarrowLayout";
import ShortenedPlayerName from "../../common/ShortenedName";
import ViewPlayerTransferOfferLoanInterface from "./interface";

export default function ViewPlayerTransferOfferLoan(props) {
    const {playerTransferOfferLoanId} = useParams();

    const getPlayerTransferOfferLoanQuery = useQuery(
        ['getPlayerLoanOffer', playerTransferOfferLoanId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferOfferLoan/${playerTransferOfferLoanId}`)
    );

    useEffect(() => {
        if(getPlayerTransferOfferLoanQuery.data?.data?.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getPlayerTransferOfferLoanQuery.data.data.response.playerContract.player} /> offer</>,
                navigation: []
            });
        }
    }, [getPlayerTransferOfferLoanQuery.data?.data?.response]);

    return(
        <NarrowLayout>
            <ViewPlayerTransferOfferLoanInterface
                isLoading={getPlayerTransferOfferLoanQuery.isLoading}
                playerTransferOfferLoan={getPlayerTransferOfferLoanQuery.data?.data?.response}
                isMyPlayer={props.manager.club.id === getPlayerTransferOfferLoanQuery.data?.data?.response?.playerContract.club.id}
            />
        </NarrowLayout>
    )
}
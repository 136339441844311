import { useQueryClient } from '@tanstack/react-query';
import { generatePath, useHistory } from "react-router";
import { Button, Card, Loader } from "semantic-ui-react";
import PlayerLink from "../../common/Link";
import { FormattedDate, FormattedMessage } from "react-intl";
import Component_Currency from "../../../common/components/Currency";
import { URL } from "../../../common/lib/paths";
import ClubLink from "../../../club/components/common/Link";
import { useMemo } from "react";

export default function ViewPlayerTransferOfferPaidHistoryInterface(props) {
    const history = useHistory();

    const proposingClubLink = useMemo(() => !!props.playerTransferOfferPaidHistory ? <ClubLink club={props.playerTransferOfferPaidHistory.toClub} /> : null, [props.playerTransferOfferPaidHistory]);
    const receivingClubLink = useMemo(() => !!props.playerTransferOfferPaidHistory ? <ClubLink club={props.playerTransferOfferPaidHistory.fromClub} /> : null, [props.playerTransferOfferPaidHistory]);
    const playerLink = useMemo(() => !!props.playerTransferOfferPaidHistory ? <PlayerLink player={props.playerTransferOfferPaidHistory.player} /> : null, [props.playerTransferOfferPaidHistory]);

    if(props.isLoading) return(<Loader active />);

    return(
        <Card fluid>
            <Card.Content>
                <Card.Header>
                    <FormattedMessage
                        {...messages.cardHeader}
                        values={{
                            personName: playerLink
                        }}
                    />
                </Card.Header>
                <Card.Meta>
                    <FormattedDate
                        value={props.playerTransferOfferPaidHistory.createdAt}
                        day="numeric" 
                        month="short"
                        year="numeric" 
                    />
                </Card.Meta>
                <Card.Description>
                    <p>
                        <FormattedMessage
                            {...descriptions[props.playerTransferOfferPaidHistory.status]}
                            values={{
                                toClubName: proposingClubLink,
                                fromClubName: receivingClubLink,
                                compensation: <Component_Currency value={props.playerTransferOfferPaidHistory.compensation} />,
                                playerName: playerLink
                            }}
                        />
                    </p>
                </Card.Description>
            </Card.Content>
            {props.isMyPlayer === false &&
                <Card.Content extra>
                    <Button
                        fluid
                        onClick={() => history.push(generatePath(URL.playerTransferOrLoanOffer, {playerId: props.playerTransferOfferPaidHistory.playerId}))}
                    >
                        New bid
                    </Button>
                </Card.Content>
            }
        </Card>
    )
}

const descriptions = {
    rejected: {
        id: 'playerTransferOfferPaidHistory:view:rejected',
        defaultMessage: '{fromClubName} have rejected an offer of {compensation} from {toClubName} for {playerName}',
        description: 'Description of a transfer offer which has been rejected'
    },
    withdrawn: {
        id: 'playerTransferOfferPaidHistory:view:withdrawn',
        defaultMessage: '{toClubName} have withdrawn their {compensation} offer for {playerName}',
        description: 'Description of a transfer offer which has been withdrawn'
    }
}

const messages = {
    pageHeader: {
        id: "Page_TransferProposal.pageHeader",
        defaultMessage: "Transfer offer",
        description: "Header for the transfer offer page where the user can review a transfer offer in progress (accept/reject/withdraw)"
    },
    cardHeader: {
        id: "Page_TransferProposal.cardHeader",
        defaultMessage: "Transfer offer for {personName}",
        description: "Header for the card on the transfer offer page which contains the transfer offer details"
    },
    button_accept: {
        id: "Page_TransferProposal.button_accept",
        defaultMessage: "Accept",
        description: "Label for the button which accepts the transfer offer"
    },
    button_reject: {
        id: "Page_TransferProposal.button_reject",
        defaultMessage: "Reject",
        description: "Label for the button which rejects the transfer offer"
    },
    button_newBid: {
        id: "Page_TransferProposal.button_newBid",
        defaultMessage: "New bid",
        description: "Label for the button which takes the user to a page where they can submit a new transfer bid"
    },
    button_offerContract: {
        id: "Page_TransferProposal.button_offerContract",
        defaultMessage: "Offer contract",
        description: "Label for the button which takes the user to a page where they can submit a contract offer to the player/staff"
    },
    button_viewContract: {
        id: "viewTransferOffer:viewContractOfferButton",
        defaultMessage: "View contract",
        description: "Text for the button which takes the user to the page where they can view the contract they have offered the player"
    }
}
import {useEffect} from 'react';
import {useQuery, useMutation} from '@tanstack/react-query';
import {useLocation} from 'react-router-dom';
import axios from 'axios';
import FirstClubInterface from './interface';
import NarrowLayout from '../../../../common/components/NarrowLayout';

async function getFirstJobsFetch(charPos, nationGroupShortcode) {
    let string = `${process.env.REACT_APP_APPHOST}/clubs?instantJoin=true&charPos=${charPos}`;

    if(!!nationGroupShortcode) {
        string += `&nationGroupShortcode=${nationGroupShortcode}`;
    }

    const dataResponse = await axios.get(string);

    return dataResponse.data.response;
}

async function acceptJobFetch(data) {
    const response = await axios.patch(
        `${process.env.REACT_APP_APPHOST}/managers/${data.managerId}`,
        {
            instantJoinClub: {
                clubId: {
                    operation: 'add',
                    value: data.clubId
                }
            }
        }
    );

    if(!response.data.success) {
        console.warn(response.data.response);
    }

    return response.data.response;
}

export default function FirstJobData(props) {
    const location = useLocation();

    const charPos = location.state?.index;

    const getClubsQuery = useQuery(
        ['getFirstJobs', charPos, location.state?.nationGroupShortcode],
        () => getFirstJobsFetch(charPos, location.state?.nationGroupShortcode),
        {
            enabled: charPos !== undefined,
            refetchInterval: false
        }
    );

    const acceptJobMutation = useMutation((data) => acceptJobFetch(data), {
        onSuccess: (data) => props.handleNewClub(data)
    });

    const handleAcceptJob = (clubId) => {
        acceptJobMutation.mutate({managerId: props.manager.id, clubId: clubId});
    }

    useEffect(() => {
        props.setToolbar({title: 'Choose first club', navigation: []});
    }, []);

    return (
        <NarrowLayout>
            <FirstClubInterface
                isLoading={getClubsQuery.isLoading}
                isSubmitting={acceptJobMutation.isSubmitting}
                isSuccess={acceptJobMutation.isSuccess}
                managerName={props.manager.name}
                handleAcceptJob={handleAcceptJob}
                jobs={getClubsQuery.data}
            />
        </NarrowLayout>
    )
}
import axios from "axios";
import { useMutation, useQueryClient } from '@tanstack/react-query';
import CreatePlayerLoanOfferInterface from "./interface";

export default function CreatePlayerLoanOffer(props) {
    const queryClient = useQueryClient();

    const submitBidMutation = useMutation((playerContractId) =>
        axios.post(`${process.env.REACT_APP_APPHOST}/playerTransferOfferLoan`, {
            playerContractId: parseInt(playerContractId)
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getPlayerPlayerLoanOffer');
            }        
        }
    );
    
    return(
        <CreatePlayerLoanOfferInterface
            handleSubmitBid={handleSubmitBid}
            isSubmitting={submitBidMutation.isSubmitting}
            submitBidError={submitBidMutation.error?.response?.data}
        />
    )

    function handleSubmitBid() {
        submitBidMutation.mutate(props.player.playerContract.id);
    }
}
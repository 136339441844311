import axios from 'axios';
import {Modal, Button} from 'semantic-ui-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import SubstitutionCard from './SubstitutionCard';

export default function ConfigureSubstitutions(props) {
    const queryClient = useQueryClient();

    const getSubsQuery = useQuery(
        ['getSubs'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teamTactics/${props.teamTacticId}/substitutions`),
        {
            enabled: !!props.teamTacticId
        }
    );

    const {isLoading, data} = useQuery(
        ['getSquad'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.clubId}/teams/${props.teamType}/playerRegistrations?&ownershipClaim=true&context=list`)
    );

    const deleteSubMutation = useMutation((substitutionId) =>
        axios.delete(
            `${process.env.REACT_APP_APPHOST}/substitutions/${substitutionId}`
        ),
        {
            onSuccess: () => queryClient.invalidateQueries('getSubs')
        }
    );

    const createSubMutation = useMutation(({onLineupPositionIndex, offLineupPositionIndex, time}) => 
        axios.post(
            `${process.env.REACT_APP_APPHOST}/teamTactics/${props.teamTacticId}/substitutions`,
            {
                onTeamTacticPositionId: parseInt(onLineupPositionIndex),
                offTeamTacticPositionId: parseInt(offLineupPositionIndex),
                time: parseInt(time)
            }
        ),
        {
            onSuccess: () => queryClient.invalidateQueries('getSubs')
        }
    );

    function handleCreateSub(onLineupPositionIndex, offLineupPositionIndex, time) {
        createSubMutation.mutate({onLineupPositionIndex, offLineupPositionIndex, time});
    }

    return(
        <Modal
            open={true}
            onClose={props.cancelConfigureSubstitutions}
            size='small'
        >
            <Modal.Header className='headerFixPadding'>
                Substitutions
            </Modal.Header>
            <Modal.Content scrolling>
                {
                    [0, 1, 2].map((i) => (
                        <SubstitutionCard
                            key={i}
                            id={i}
                            isLoading={getSubsQuery.isLoading || isLoading}
                            substitutes={getSubsQuery?.data?.data?.response || []}
                            handleCreateSub={handleCreateSub.bind(this)}
                            deleteSubMutation={deleteSubMutation.mutate.bind(this)}
                            lineups={data?.data?.response.playerReg}
                        />
                    ))
                }
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={props.cancelConfigureSubstitutions}>Close</Button>
            </Modal.Actions>
        </Modal>
    )
}
import { defineMessages } from "@formatjs/intl";

const messages = {
    'scoutReport:completed': defineMessages({
        subject: {
            id: "messageSubject:scoutReport:completed",
            defaultMessage: "Scout reports completed",
            description: "Subject for the system message which a user receives when a scout report has been completed"
        },
        body: {
            id: "messageBody:scoutReport:completed",
            defaultMessage: "As requested the scouting team have compiled reports on the following players.\n\n{scoutReports}\n\nThese will be available until {deletionDate}.",
            description: "Message body for the system message which a user receives when a scout report has been completed"
        }
    })
}

export default messages;
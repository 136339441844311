import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useRef, useState, useEffect } from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from "react-router";
import { Accordion, Button, Form, Message } from "semantic-ui-react"
import {bidDecrementer, bidIncrementer, CurrencyInput, salaryIncrementer, salaryDecrementer, makeCustomBidDecrementer} from "../../../common/components/CurrencyInput"
import SUIIconShim from "../../../common/components/SUIIconShim";
import TitledSegment from "../../../common/components/TitledSegment";
import ContractTable from "../../../common/components/ContractTable";
import ContractLengthOptions from "../../contract/offerForm/contractLengthOptions";
import Currency from "../../../common/components/Currency";

export default function CreatePlayerTransferAuctionBid(props) {
    const {playerTransferAuctionId} = useParams();
    const currencyInputEl = useRef(null);
    const salaryInputEl = useRef(null);
    const [contractLength, setContractLength] = useState(null);
    const [contractDemandsExpanded, setContractDemandsExpanded] = useState(null);
    const queryClient = useQueryClient();

    const contractDemandsQuery = useQuery(
        ['getPlayerContractDemands', props.playerId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${props.playerId}/contractDemands`),
        {
            enabled: !props.isLoading && !!props.playerId
        }
    );

    const clubBudgetQuery = useQuery(
        ['getClubBudgets'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/budgets`)
    );

    useEffect(() => {
        if(contractLength === null) {
            if(!!props.myLatestBid) {
                setContractLength(props.myLatestBid.contractEndSeasonIdOffer);
            } else if(!!contractDemandsQuery.data?.data?.response.contractDemands.adjustedEndSeasonDemand) {
                setContractLength(contractDemandsQuery.data?.data?.response.contractDemands.adjustedEndSeasonDemand.id);
            }
        }
    }, [contractLength, contractDemandsQuery.data?.data?.response, props.myLatestBid]);

    const submitBidMutation = useMutation(({playerTransferAuctionId, compensation, contractSalaryOffer, contractEndSeasonIdOffer}) =>
        axios.post(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionBidPaid`, {
            playerTransferAuctionId,
            compensation,
            contractSalaryOffer,
            contractEndSeasonIdOffer
        }), {
            onSuccess: (data) => {
                queryClient.invalidateQueries('getPlayerTransferAuction');
                props.setCreatingBid(false);
            }
        }
    );

    function handleTransferBidSubmit() {
        submitBidMutation.mutate({
            playerTransferAuctionId: parseInt(playerTransferAuctionId),
            compensation: parseInt(currencyInputEl.current.getAttribute('rawvalue')),
            contractSalaryOffer: parseInt(salaryInputEl.current.getAttribute('rawvalue')),
            contractEndSeasonIdOffer: contractLength
        });
    }

    return(
        <TitledSegment
            title='New bid'
        >
            {contractDemandsQuery.data?.data?.response.reputationWithinRange === false ?
                <Message
                    negative
                >
                    <p>This player is not interested in joining your club</p>
                </Message>
                :
                <Form
                    loading={props.isSubmitting}
                >
                    <Form.Field>
                        <label>Max. bid</label>
                        <CurrencyInput
                            currencyInputEl={currencyInputEl}
                            initialValue={props.currentBid*1.2}
                            getIncrement={bidIncrementer}
                            getDecrement={bidDecrementer}
                            minimum={props.currentBid*1.2}
                            isLoading={props.isLoading}
                        />
                        {!clubBudgetQuery.isLoading &&
                            <p>Remaining transfer budget: <Currency value={clubBudgetQuery.data.data.response.remainingTransferBudget} /></p>
                        }
                        <p>Enter the maximum you are willing to pay for this player. You won't pay more than you need to, up to this limit. </p>
                    </Form.Field>
                    <Form.Field>
                        <label>Wage offer</label>
                        <CurrencyInput
                            currencyInputEl={salaryInputEl}
                            initialValue={props.myLatestBid ? props.myLatestBid.contractSalaryOffer : contractDemandsQuery.data?.data?.response.contractDemands.adjustedSalaryDemand}
                            isLoading={props.isLoading || contractDemandsQuery.isLoading}
                            getIncrement={salaryIncrementer}
                            getDecrement={salaryDecrementer}
                        />
                        {!clubBudgetQuery.isLoading &&
                            <p>Remaining wage budget: <Currency value={clubBudgetQuery.data.data.response.remainingWageBudget} /></p>
                        }
                    </Form.Field>
                    <ContractLengthOptions
                        selectedContractLength={contractLength}
                        setSelectedContractLength={setContractLength}
                        noContractShorterThan={contractDemandsQuery.data?.data?.response.currentSeason.endDate}
                        isLoading={props.isLoading || contractDemandsQuery.isLoading}
                    />
                    <Accordion>
                        <Accordion.Title
                            active={contractDemandsExpanded}
                            onClick={() => setContractDemandsExpanded(!contractDemandsExpanded)}
                            index={0}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faCaretDown} className='inlineIcon' />
                                Contract demands
                            </SUIIconShim>
                        </Accordion.Title>
                        <Accordion.Content
                            active={contractDemandsExpanded}
                        >
                            <ContractTable
                                contractSalary={contractDemandsQuery.data?.data?.response.contractDemands.adjustedSalaryDemand}
                                contractExpiry={contractDemandsQuery.data?.data?.response.contractDemands.adjustedEndSeasonDemand.endDate}
                                currentSeason={contractDemandsQuery.data?.data?.response.currentSeason}
                                isLoading={props.isLoading || contractDemandsQuery.isLoading}
                            />
                        </Accordion.Content>
                    </Accordion>
                    {submitBidMutation.error &&
                        <Message negative>
                            <FormattedMessage
                                {...submitBidErrorMessages[submitBidMutation.error?.response?.data?.response?.key || 'unknown']}
                                values={{key: submitBidMutation.error?.response?.data?.response?.key || '-1'}}
                            />
                        </Message>
                    }
                    <Button fluid positive onClick={handleTransferBidSubmit} style={{marginTop: '1em'}}>Submit offer</Button>
                </Form>
            }
        </TitledSegment>
    )
}

const submitBidErrorMessages = defineMessages({
    'unknown': {
        id: 'player:loanOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'EXPIRED': {
        id: 'playerTransferAuctionBid:expired',
        defaultMessage: 'You cannot bid for this player because the auction has ended',
        description: 'Error message shown if a bid is made but the auction has already ended'
    },
    'INSUFFICIENT_COMPENSATION': {
        id: 'playerTransferAuctionBid:expired',
        defaultMessage: 'Your bid is not high enough, try again with a higher bid',
        description: 'Error message shown if a bid but the offer is too low'
    },
    'CONTRACT_VALUE_LOW': {
        id: 'contractValueLow',
        defaultMessage: 'The player has rejected your contract offer, try increasing your offer',
        description: 'Error message shown when player rejects contract as it is not high enough value'
    },
    'INSUFFICIENT_TRANSFER_BUDGET': {
        id: 'player:transferOffer:create:error:transferBudget',
        defaultMessage: 'Your transfer budget is not sufficient for this offer. Try lowering your bid.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (transfer budget)'
    },
    'INSUFFICIENT_WAGE_BUDGET': {
        id: 'player:transferOffer:create:error:wageBudget',
        defaultMessage: 'Your wage budget is not sufficient for this offer. Try lowering your contract offer.',
        description: 'Error message shown if a transfer bid cannot be submitted due to the club not being able to afford the offer (Wage budget)'
    },
    'TOO_MANY_PLAYERS': {
        id: 'player:transferOffer:create:error:tooManyPlayers',
        defaultMessage: 'You cannot bid for this player because you have too many players',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of players'
    },
    'REPUTATION_MISMATCH': {
        id: 'player:transferOffer:create:error:reputationAbs',
        defaultMessage: 'This player is not interested in joining your club',
    },
    'FREQUENCY_ERROR': {
        id: 'player:transferOffer:create:error:pendingBid',
        defaultMessage: 'You cannot bid again for this player because you are already the highest bidder'
    },
    'MODIFY_RESOURCE_PERMISSION_DENIED': {
        id: 'player:transferOffer:create:error:permission',
        defaultMessage: 'You cannot bid for this player because they already play for your club'
    },
    'PLAYER_RETIRING': {
        id: 'player:transferOffer:create:error:playerRetiring',
        defaultMessage: 'You cannot bid for this player because they are retired or retiring',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is retired/retiring'
    },
    'LENGTH_MAX_CONSTRAINT': {
        id: 'player:transferOffer:create:error:maxContractLengthExceeded',
        defaultMessage: 'The contract you have offered is too long. Please try again with a shorter offer.'
    },
    'CONTRACT_VALUE_HIGH': {
        id: 'player:transferOffer:create:error:contractValueHigh',
        defaultMessage: 'The board feel this contract offer is too generous . Please try again with a lower contract offer.'
    }
});
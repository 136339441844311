import {defineMessages, useIntl} from "react-intl";

import {club} from "../../common/components/i18n.js";

import DataTable from '../../common/components/DataTable';
import WideLayout from "../../common/components/WideLayout.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp, faSquare } from "@fortawesome/free-solid-svg-icons";
import TeamLink from "../../team/common/TeamLink.js";
import { useParams } from "react-router";
import NarrowLayout from "../../common/components/NarrowLayout.js";
import { Accordion } from "semantic-ui-react";
import SUIIconShim from "../../common/components/SUIIconShim.js";
import { useState } from "react";

export default function LeagueTable(props) {	
	const intl = useIntl();
	const {clubId} = useParams();
	const [isExpanded, setIsExpanded] = useState(false);

	const columns = [
		{
			name: intl.formatMessage(messages.positionLabel),
			selector: clubLeagueSeason => {
				if(clubLeagueSeason.champions) return 'C';
				if(clubLeagueSeason.promoted !== null) return 'P';
				if(clubLeagueSeason.relegated !== null) return 'R';
				return clubLeagueSeason.position;
			},
			wrap: false,
			allowOverflow: false,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px'
		},
		{
			name: intl.formatMessage(messages.clubNameLabel),
			cell: clubLeagueSeason => (
				<div className='playerName'>
					<TeamLink team={clubLeagueSeason.team} highlight={clubLeagueSeason.team.club.id === parseInt(clubId)} />
				</div>
			),
			wrap: false,
			allowOverflow: true,
			compact: true,
			grow: 1
		},
		{
			name: intl.formatMessage(messages.gamesPlayedLabel),
			selector: (CLS) => CLS.gamesPlayed,
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
		},
		{
			name: intl.formatMessage(messages.gamesWonLabel),
			selector: (CLS) => CLS.wins,
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 580
		},
		{
			name: intl.formatMessage(messages.gamesDrawnLabel),
			selector: (CLS) => CLS.draws,
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 580
		},
		{
			name: intl.formatMessage(messages.gamesLostLabel),
			selector: (CLS) => CLS.losses,
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 580
		},
		{
			name: intl.formatMessage(messages.goalsScoredLabel),
			selector: (CLS) => CLS.goalsScored,
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 750
		},
		{
			name: intl.formatMessage(messages.goalsConcededLabel),
			selector: (CLS) => CLS.goalsConceded,
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 750
		},
		{
			name: intl.formatMessage(messages.goalDifferenceLabel),
			selector: (CLS) => (CLS.goalsScored+(CLS.goalsConceded*-1)),
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
			hide: 340
		},
		{
			name: intl.formatMessage(messages.pointsLabel),
			selector: (CLS) => CLS.points,
			wrap: false,
			allowOverflow: false,
			center: true,
			compact: true,
			minWidth: '30px',
			maxWidth: '50px',
		}
	];

	let conditionalRowStyles = [];

	if(!props.leagueSeasonTLSQueryIsLoading) {
		conditionalRowStyles.push({
			when: row => row.position === 1,
			style: {
				backgroundColor: '#b9e7b6'
			}
		});

		if(props.leagueSeason.league.leagueLevel.level !== 1) {
			conditionalRowStyles.push({
				when: row => [2, 3].includes(row.position),
				style: {
					backgroundColor: '#e2f1dc'
				}
			});
		}
	
		if(props.leagueSeason.league.leagueLevel.level !== props.leagueSeason.league.leagueLevel.nationLeagueLevels) {
			conditionalRowStyles.push({
				when: row => [8, 9, 10].includes(row.position),
				style: {
					backgroundColor: '#fce2e2'
				}
			});
			conditionalRowStyles.push({
				when: row => [6, 7].includes(row.position),
				style: {
					backgroundColor: '#fff1f1'
				}
			});
		}
	}

	return(
		<WideLayout>
			<DataTable
				columns={columns}
				data={props.leagueSeason?.TLS}
				isLoading={props.leagueSeasonTLSQueryIsLoading}
				placeholderCount={12}
				conditionalRowStyles={conditionalRowStyles}
			/>
			<NarrowLayout>
				<Accordion inverted>
					<Accordion.Title
                        active={isExpanded}
                        onClick={() => setIsExpanded(isExpanded => !isExpanded)}
                        index={0}
					>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} className='inlineIcon' />
                            Promotion/relegation
                        </SUIIconShim>
					</Accordion.Title>
					<Accordion.Content
						active={isExpanded}
					>
						{!props.leagueSeasonTLSQueryIsLoading &&
							<div style={{color: '#fff', fontWeight: 'bold'}}>
								{props.leagueSeason.league.leagueLevel.level !== 1 ?
									<>
										<p>
											<FontAwesomeIcon icon={faSquare} color='#b9e7b6' className='inlineIcon' />
											promotion to league {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(props.leagueSeason.league.leagueLevel.level-2)}
										</p>
										<p>
											<FontAwesomeIcon icon={faSquare} color='#e2f1dc' className='inlineIcon' />
											promotion play-offs
										</p>
									</>
									:
									<p>
										<FontAwesomeIcon icon={faSquare} color='#b9e7b6' className='inlineIcon'/>
										League A1 champions
									</p>
								}
								{props.leagueSeason.league.level !== props.leagueSeason.league.leagueLevel.nationLeagueLevels ?
									<>
										<p>
											<FontAwesomeIcon icon={faSquare} color='#fff1f1' className='inlineIcon' />
											relegation play-offs
										</p>
										<p>
											<FontAwesomeIcon icon={faSquare} color='#fce2e2' className='inlineIcon' />
											relegation to league {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(props.leagueSeason.league.leagueLevel.level)}
										</p>
									</>
									:
									null
								}
							</div>
						}
					</Accordion.Content>
				</Accordion>
			</NarrowLayout>
		</WideLayout>
	);
}

const messages = defineMessages({
	positionLabel: {
		id: "Layout_LeagueTable.positionLabel",
		defaultMessage: "Pos",
		description: "Header for column in the league table which states the position of the club. 6 characters maximum (abbreviations allowed)"
	},
	clubNameLabel: club,
	gamesPlayedLabel: {
		id: "Layout_LeagueTable.playedLabel",
		defaultMessage: "P",
		description: "Header for the column in the league table which states how many games the club has played. 6 characters maximum (abbreviations allowed)"
	},
	gamesWonLabel: {
		id: "league:table:gamesWonLabel",
		defaultMessage: "W",
		description: "Header for the column in the league table which states how many games the club has won. 6 characters maximum (abbreviations allowed)"
	},
	gamesDrawnLabel: {
		id: "league:table:gamesDrawnLabel",
		defaultMessage: "D",
		description: "Header for the column in the league table which states how many games the club has drawn. 6 characters maximum (abbreviations allowed)"
	},
	gamesLostLabel: {
		id: "league:table:gamesLostLabel",
		defaultMessage: "L",
		description: "Header for the column in the league table which states how many games the club has lost. 6 characters maximum (abbreviations allowed)"
	},
	goalsScoredLabel: {
		id: 'league:table:goalsScoredLabel',
		defaultMessage: 'F',
		description: 'Header for the column in the league table which states how many goals a team has scored'
	},
	goalsConcededLabel: {
		id: 'league:table:goalsConcededLabel',
		defaultMessage: 'A',
		description: 'Header for the column in the league table which states how many goals a team has conceded'
	},
	goalDifferenceLabel: {
		id: "Layout_LeagueTable.goalDifferenceLabel",
		defaultMessage: "+/-",
		description: "Header for the column in the league table which states the difference between how many goals the club has scored and conceded. 6 characters maximum (abbreviations allowed)"
	},
	pointsLabel: {
		id: "Layout_LeagueTable.pointsLabel",
		defaultMessage: "Pts",
		description: "Header for the column in the league table which states how many points the club has. 6 characters maximum (abbreviations allowed)"
	}
});
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../../common/lib/paths.js";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

import {useTutorial} from '../../../tutorial/hooks/useTutorial.js'

const messages = defineMessages({
	text: {
		id: "toolbarItem:teamLeagueSeasons",
		defaultMessage: "History",
		description: "Link for team league history page"
	}
});

export default function TeamLeagueSeasonsToolbarItem(props) {
	const {checkTutorial, getSearchParamsString} = useTutorial();

	const isTutorial1 = checkTutorial('clubs', 1);

	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.teamHistory, {clubId: props.clubId, teamType: props.teamType}),
				search: isTutorial1 ? getSearchParamsString('clubs', 2) : undefined
			}}
			genericPath={URL.teamHistory}
			icon={faCalendarDays}
			messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
			glow={isTutorial1}
		/>
	);
}
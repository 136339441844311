import axios from 'axios';
import {useEffect, useMemo} from "react";
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';

import PlayerProfile_interface from "./interface.js";

import Profile from "../toolbar/Profile.js";
import History from "../toolbar/History.js";
import TransferOfferNew from '../toolbar/Transfer';
import ShortenedName from '../common/ShortenedName';
import PlayerContractOfferNew from '../toolbar/Contract.js';

const includeStr = 'includes=person,playerContract,playerReg,retirement,isFavPlayer';

export default function PlayerProfile(props) {
    const queryClient = useQueryClient();

    const {isLoading, data} = useQuery(
        ['getPlayer', props.playerId, includeStr],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${props.playerId}/?${includeStr}`),
        {
            enabled: props.playerId !== undefined
        }
    );

    const createFavPlayer = useMutation(() => axios.post(`${process.env.REACT_APP_APPHOST}/favPlayers`, {
        playerId: parseInt(props.playerId)
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getPlayer');
        }
    });

    const deleteFavPlayer = useMutation(() => axios.delete(`${process.env.REACT_APP_APPHOST}/favPlayers?playerId=${props.playerId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries('getPlayer');
        }
    });

    const toggleTransferListed = useMutation(({transferListed, playerContractId}) => axios.patch(`${process.env.REACT_APP_APPHOST}/playerContracts/${playerContractId}`, {
        transferListed
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getPlayer');
        }
    });

    const toggleLoanListed = useMutation(({loanListed, playerContractId}) => axios.patch(`${process.env.REACT_APP_APPHOST}/playerContracts/${playerContractId}`, {
        loanListed
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getPlayer');
        }
    });

    const toggleBlockOffers = useMutation(({blockOffers, playerContractId}) => axios.patch(`${process.env.REACT_APP_APPHOST}/playerContracts/${playerContractId}`, {
        blockOffers
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getPlayer');
        } 
    });

    const promoteToFirstTeam = useMutation((playerRegistrationId) => axios.patch(`${process.env.REACT_APP_APPHOST}/playerRegistrations/${playerRegistrationId}`, {
        promoteToFirstTeam: true
    }), {
        onSuccess: () => {
            queryClient.invalidateQueries('getPlayer');
        }
    });

    const releasePlayer = useMutation(() => axios.delete(`${process.env.REACT_APP_APPHOST}/players/${props.playerId}/contract`), {
       onSuccess: () => {
            queryClient.invalidateQueries('getPlayer');
       },
       onError: (error) => {
            console.warn(error.response.data);
       }
    });

    const handleToggleFavPlayer = () => {
        if(data.data.response.isFavPlayer === false) {
            createFavPlayer.mutate();
        } else if(data.data.response.isFavPlayer === true) {
            deleteFavPlayer.mutate();
        }
    }

    const handleToggleTransferListed = () => {
        toggleTransferListed.mutate({transferListed: !data.data.response.playerContract.transferListed, playerContractId: data.data.response.playerContract.id});
    }

    const handleToggleLoanListed = () => {
        toggleLoanListed.mutate({loanListed: !data.data.response.playerContract.loanListed, playerContractId: data.data.response.playerContract.id});
    }

    const handleToggleBlockOffers = () => {
        toggleBlockOffers.mutate({blockOffers: !data.data.response.playerContract.blockOffers, playerContractId: data.data.response.playerContract.id});
    }

    const handlePromoteToFirstTeam = () => {
        promoteToFirstTeam.mutate(data.data.response.playerContract.playerReg.id);
    }

    const handleReleasePlayer = () => {
        releasePlayer.mutate();
    }

    const isMyPlayer = useMemo(() => {
        if(!props.manager.club?.id || !data?.data?.response?.playerContract?.club.id) return false;

        return props.manager.club?.id === data?.data?.response?.playerContract?.club.id;
    }, [props.manager.club?.id, data?.data?.response?.playerContract?.club.id])

    useEffect(() => {
        if(!!data && data.data.success && props.setToolbar) {
            props.setToolbar({
                title: <><ShortenedName player={data.data.response} /> profile</>,
                navigation: [
                    <Profile key={1} playerId={props.playerId} />,
                    <PlayerContractOfferNew key={7} playerId={props.playerId} isMyPlayer={isMyPlayer} isRetiring={!!data.data.response.retirementSeason} />,
                    <TransferOfferNew key={6} playerId={props.playerId} myClubId={props.manager.club?.id} playerClubId={data.data.response.playerContract?.clubId} retirementSeasonId={data.data.response.retirementSeasonId} />,
                    <History key={5} playerId={props.playerId} />,
                ]
            })
        }
    }, [props.playerId, isLoading]);

    return (
        <PlayerProfile_interface
            isLoading={isLoading}
            manager={props.manager}
            player={data?.data?.response}
            isFavPlayer={data ? data.data.response.isFavPlayer : undefined}
            isMyPlayer={isMyPlayer}
            handleToggleFavPlayer={handleToggleFavPlayer.bind(this)}
            toggleFavPlayerLoading={createFavPlayer.isLoading || deleteFavPlayer.isLoading}
            handleToggleTransferListed={handleToggleTransferListed}
            toggleTransferListedLoading={toggleTransferListed.isLoading}
            toggleTransferListedError={toggleTransferListed.error?.response?.data}
            toggleLoanListedLoading={toggleLoanListed.isLoading}
            handleToggleLoanListed={handleToggleLoanListed}
            toggleLoanListedError={toggleLoanListed.error?.response?.data}
            toggleBlockOffersLoading={toggleBlockOffers.isLoading}
            handleToggleBlockOffers={handleToggleBlockOffers}
            handlePromoteToFirstTeam={handlePromoteToFirstTeam}
            handleReleasePlayer={handleReleasePlayer}
            releasePlayerLoading={releasePlayer.isLoading}
            releasePlayerSuccess={releasePlayer.isSuccess}
            releasePlayerError={releasePlayer.error?.response?.data}
            style={props.style}
            compareMode={!props.setToolbar}
        />
    );
}
import React from "react";
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../../common/lib/paths.js";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { useTutorial } from "../../../tutorial/hooks/useTutorial.js";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_ClubProfile.text",
		defaultMessage: "Profile",
		description: "Link for club profile page"
	}
});

export default function ClubProfileToolbarItem(props) {
	const {checkTutorial, getSearchParamsString} = useTutorial();
	const isTutorial1 = checkTutorial('clubs', 4);

	if(props.clubId === undefined) return null;

	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.clubProfile, {clubId: props.clubId}),
				search: isTutorial1 && getSearchParamsString('clubs', 5)
			}}
			icon={faShieldHalved} messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
			glow={isTutorial1}
			genericPath={URL.clubProfile}
		/>
	);
}


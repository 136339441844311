import {Table, Placeholder, Segment, Image, Header, List, Button} from "semantic-ui-react";
import {FormattedMessage, useIntl} from "react-intl";
import {manager, nationality} from "../../../common/components/i18n.js";
import FullNationNameWithFlag from "../../../nation/common/FullNationNameWithFlag.js";
import RatingRoundall from '../../../player/common/RatingRoundall2';

import CityLink from "../../../city/common/CityLink";
import { LineChart, ResponsiveContainer, XAxis, YAxis, Line, Legend } from "recharts";
import { useMemo } from "react";
import TeamProfileInterface from "../../../team/profile/interface";
import { groupBy, map } from "lodash";
import TitledSegment from "../../../common/components/TitledSegment";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faTrophy } from "@fortawesome/free-solid-svg-icons";
import LeagueLevelName from '../../../league/components/common/LeagueLevelName';
import colours from "../../../common/colours";
import { getSeasonName } from "../../../common/components/SeasonName";
import {useTutorial} from '../../../tutorial/hooks/useTutorial';
import ManagerLink from "../../../manager/components/MangerLink.js";
import CupName from '../../../cup/name/CupName.js';

export default function ClubProfileInterface(props) {
    const {checkTutorial, getSearchParamsString, tutorialStep} = useTutorial();

    const isTutorial1 = checkTutorial('clubs', 5);

    return(
        <div style={{boxSizing: 'border-box'}}>
            {!!props.club &&
                <Image
                    src={`${process.env.PUBLIC_URL}/crests/${props.club.badgeNumber}.png`}
                    style={{
                        filter: 'drop-shadow(1px 1px 3px #000)',
                        maxHeight: '150px',
                        margin: '0 auto'
                    }}
                />
            }
            <div style={{overflow: 'hidden'}}>
                <Button
                    icon
                    toggle
                    disabled={props.isLoading}
                    loading={props.toggleFavClubLoading}
                    active={props.isFavClub}
                    onClick={props.handleToggleFavClub}
                    floated='right'
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faStar} fixedWidth />
                    </SUIIconShim>
                </Button>
            </div>
            <Header as='h5' attached='top' inverted className='attachedHeader'>
                Club
            </Header>
            <Segment attached className='attachedInvertedSegment profileSegmentFix2 t_clubProfile'>
                <Table
                    basic='very'
                    compact='very'
                    unstackable
                    style={{width: '100%'}}
                >
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>Name</Table.Cell>
                            <Table.Cell width={8}>
                                {props.clubQueryIsLoading ?
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                    </Placeholder>
                                    :
                                    props.club.name
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>City</Table.Cell>
                            <Table.Cell width={8}>
                                {props.clubQueryIsLoading ?
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                    </Placeholder>
                                    :
                                    <CityLink
                                        city={{id: props.club.cityId, name: props.club.name}}
                                        className={isTutorial1 && 'glow'}
                                        search={isTutorial1 && getSearchParamsString('clubs', tutorialStep+1)}
                                    />
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}}><FormattedMessage {...nationality} /></Table.Cell>
                            <Table.Cell>
                                {props.clubQueryIsLoading?
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                    </Placeholder>
                                    :
                                    <FullNationNameWithFlag
                                        nation={props.club.nation}
                                    />
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}}><FormattedMessage {...manager} /></Table.Cell>
                            <Table.Cell>
                                {props.clubQueryIsLoading ?
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                    </Placeholder>
                                    :
                                    <ManagerLink manager={props.club.manager} />
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}}>Reputation</Table.Cell>
                            <Table.Cell>
                                {props.clubQueryIsLoading ?
                                    <Placeholder>
                                        <Placeholder.Line length='full' />
                                    </Placeholder>
                                    :
                                    <RatingRoundall rating={props.club.reputationAbs} />
                                }
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
            <Header as='h5' attached='top' inverted className='attachedHeader'>
                History
            </Header>
            <Segment attached className='attachedInvertedSegment'>
                <ClubReputationChart
                    TLS={props.TLS}
                    clubSeasons={props.club?.clubSeasons}
                    numberOfLeagueLevels={props.club?.nationLowestLevel}
                    isLoading={props.TLSQueryIsLoading || props.clubQueryIsLoading}
                />
            </Segment>
            {!!props.club && props.club.teams.map((team) => (
                <TeamProfileInterface team={team} club={props.club} />
            ))}
            <ClubHonours
                championTLS={props.championTLS}
                championTCS={props.championTCS}
            />
        </div>
    );
}

function ClubReputationChart(props) {
    const intl = useIntl();

    const chartData = useMemo(() => {
        const data = [];

        if(!Array.isArray(props.TLS) || !Array.isArray(props.clubSeasons)) return data;

        for(let i=props.TLS.length-1; i>=0; i--) {
            data.push({
                name: intl.formatDate(props.TLS[i].LS.season.startDate, {day: '2-digit', month: 'short', year: '2-digit'}),
                level: props.TLS[i].LS.league.leagueLevel.level,
                reputationAbs: props.clubSeasons[Math.abs(i+1-props.TLS.length)].reputationAbs
            });
        }

        return data;
    }, [props.TLS, props.clubSeasons]);

    if(props.isLoading) return null;

    return(
        <ResponsiveContainer height={250}>
            <LineChart
                data={chartData}
            >
                <XAxis dataKey='name' />
                <YAxis width={35} yAxisId='left' reversed={true} domain={[0, props.numberOfLeagueLevels+1]} allowDecimals={false} ticks={Array.from({length: props.numberOfLeagueLevels}, (_, i) => i + 1)} tickFormatter={(value) => "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(value-1)} />
                <YAxis width={35} yAxisId='right' orientation='right' ticks={[50, 60, 70, 80, 90, 100]} domain={[50, 110]} />
                <Line name='league' yAxisId='left' type='monotone' dataKey='level' stroke='#8884d8' strokeWidth={3} />
                <Line name='rating' yAxisId='right' type='monotone' dataKey='reputationAbs' stroke='#82ca9d' />
                <Legend />
            </LineChart>
        </ResponsiveContainer>
    )
}

function ClubHonours(props) {
    const intl = useIntl();

    const groupedLeagueHonours = groupBy(props.championTLS, (TLS) => TLS.LS.league.leagueLevel.level);
    const groupedCupHonours = groupBy(props.championTCS, (TCS) => TCS.CS.cup.name);

    if(
        Object.keys(groupedLeagueHonours).length === 0 &&
        Object.keys(groupedCupHonours).length === 0
    ) {
        return null;
    }

    return(
        <TitledSegment
            title='Club honours'
        >
            <List
                divided
                relaxed
            >
                {map(groupedCupHonours, (TCSes, cupName) => (
                    <List.Item>
                        <List.Icon verticalAlign="middle">
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faTrophy} size="xl" color={colours.gold} />
                            </SUIIconShim>
                        </List.Icon>
                        <List.Content>
                            <List.Header>
                                <CupName cup={{name: cupName}} /> champions
                            </List.Header>
                            <List.Description>
                                {TCSes.map(TCS => getSeasonName(intl, TCS.CS.season)).join(', ')} (x{TCSes.length})
                            </List.Description>
                        </List.Content>
                    </List.Item>
                ))}
                {map(groupedLeagueHonours, (TLSes, level) => (
                    <List.Item>
                        <List.Icon verticalAlign="middle">
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faTrophy} size="xl" color={colours.silver} />
                            </SUIIconShim>
                        </List.Icon>
                        <List.Content>
                            <List.Header>
                                <LeagueLevelName leagueLevel={{level}} /> champions
                            </List.Header>
                            <List.Description>
                                {TLSes.map(TLS => getSeasonName(intl, TLS.LS.season)).join(', ')} (x{TLSes.length})
                            </List.Description>
                        </List.Content>
                    </List.Item> 
                ))}
            </List>
        </TitledSegment>
    )
}
import { useQuery, useQueryClient } from '@tanstack/react-query';

export default function usePersistedState(name, defaultState) {
    const queryClient = useQueryClient();

    const {data: persistedState} = useQuery(
        [name],
        () => {
            return getPersistedState(name, defaultState)
        },
        {
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            staleTime: Infinity,
            cacheTime: Infinity,
            initialData: () => getPersistedState(name, defaultState)
        }
    );

    function updatePersistedState(state) {
        localStorage.setItem(name, JSON.stringify(state));

        queryClient.invalidateQueries([name]);
    }

    function resetPersistedState() {
        updatePersistedState(defaultState);
    }

    return {
        persistedState,
        updatePersistedState: updatePersistedState.bind(this),
        resetPersistedState: resetPersistedState.bind(this)
    }
}

function getPersistedState(name, defaultState) {
    let state;

    try {
        state = JSON.parse(localStorage.getItem(name)) || defaultState;
    } catch(error) {
        state = defaultState;
    }

    return state;
}
import Flag from '../../../common/components/Flag';
import NationName from '../../../nation/common/NationName';

export default function LeagueName(props) {
    if(!props.league) return null;

    return(
        <span>
            {
                !!props.league.leagueLevel?.nation &&
                    <Flag name={props.league.leagueLevel.nation.nationGroupShortcode} responsiveClass='nationFlag' className='playerNationFlag' />
            }
            League&nbsp;
            {props.league.name ?
                props.league.name
                :
                "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(props.league.leagueLevel.level-1)}
            {props.league.number}
            {
                !!props.league.leagueLevel?.nation &&
                    <>&nbsp;<NationName nation={props.league.leagueLevel.nation} /></>
            }
            {props.league.leagueLevel?.type === 'youth' ? ' (U18)' : ''}
        </span>
    )
}
import {useEffect} from 'react';
import {useQuery, useMutation} from '@tanstack/react-query';

import NewManagerInterface from "./interface.js";

export default function NewManager(props) {
    const handleNewManagerSubmit = (values) => {
        createManagerMutation.mutate({
            name: values.username,
            nationId: values.nationality
        });
    }

    const createManagerMutation = useMutation(newManager =>
        fetch(
            `${process.env.REACT_APP_APPHOST}/managers`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newManager)
            }
        ).then((res) => res.json()),
        {
            onSuccess: data => props.handleNewManager(data.id)
        }
    );

    const {isLoading, data} = useQuery(
        ['getNations'],
        () => fetch(`${process.env.REACT_APP_APPHOST}/nations`).then((res) => res.json())
    );

    useEffect(() => {
        props.setToolbar({title: 'Create manager profile', navigation: []});
    }, []);

    return(
        <NewManagerInterface
            isLoading={isLoading}
            isSubmitting={createManagerMutation.isLoading}
            nations={data ? data.response : undefined}
            handleNewManagerSubmit={handleNewManagerSubmit.bind(this)}
        />
    );
}
import { useQuery } from "@tanstack/react-query";
import NarrowLayout from "../../common/components/NarrowLayout";
import WideLayout from "../../common/components/WideLayout";
import FixturesInterface from "../../fixtures/interface";
import { useHistory, useLocation, useParams } from "react-router";
import axios from "axios";
import { useEffect} from "react";
import CupName from '../../cup/name/CupName';
import { FormattedMessage } from "react-intl";
import { Dropdown, Form, Menu, Segment } from "semantic-ui-react";
import CupRoundName from "../RoundName";
import CupFixturesToolbarItem from "../toolbar/Fixtures";
import CupStatsToolbarItem from '../toolbar/Stats';
import CupHistoryToolbarItem from "../toolbar/History";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHourglass } from "@fortawesome/free-solid-svg-icons";

export default function CupSeasonFixtures(props) {
    const location = useLocation();
    const history = useHistory();
    const {cupId} = useParams();
    const cupSeasonId = location.state?.cupSeasonId;
    
    const cupSeasonQuery = useQuery(//
        ['getCupSeason', cupSeasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cupSeasons/${cupSeasonId}`),
        {
            enabled: cupSeasonId !== undefined
        }
    );

    const cupSeasonFixtureRoundsQuery = useQuery(//
        ['getCupSeasonFixtureRounds', cupSeasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cupSeasons/${cupSeasonId}/fixtureRounds`),
        {
            enabled: cupSeasonId !== undefined,
            onSuccess: (data) => {
                if(location.state?.fixtureRound === undefined && data.data.response.length > 0) {
                    history.replace({
                        pathname: location.pathname,
                        state: {
                            ...location.state,
                            fixtureRound: data.data.response[0].roundsFromFinal
                        },
                        search: location.search
                    });
                }
            }
        }
    )

    const cupSeasonFixturesQuery = useQuery(//
        ['getCupSeasonFixtures', cupSeasonId, location.state?.fixtureRound],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cupSeasons/${cupSeasonId}/fixtures?fixtureRounds=${location.state?.fixtureRound}`),
        {
            enabled: cupSeasonId !== undefined && location.state?.fixtureRound !== undefined
        }
    );

    useEffect(() => {
        if(!!cupSeasonQuery.data?.data?.response && !!location.state?.fixtureStatus) {
            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle[location.state.fixtureStatus]} values={{cupName: <CupName cup={cupSeasonQuery.data.data.response.cup} />}} />,
                navigation: [
                    <CupFixturesToolbarItem key={1} cupId={cupId} />,
                    <CupStatsToolbarItem key={2} cupId={cupId} />,
                    <CupHistoryToolbarItem key={3} cupId={cupId} />
                ]
            });
        }
    }, [cupSeasonQuery.data?.data?.response, location.state?.fixtureStatus]);

    if(cupSeasonFixtureRoundsQuery.data?.data.response.length === 0) {
        return (
            <Segment><p>No fixtures have been scheduled yet! Check back later.</p></Segment>
        )
    }

    return(
        <NarrowLayout>
            <Segment inverted style={{margin: '1em 0'}}>
                <Form inverted unstackable>
                    <Form.Group>
                        <Form.Field width={11}>
                            <label>Round</label>
                            <Dropdown
                                selection
                                options={cupSeasonFixtureRoundsQuery.data?.data.response.map((fixtureRound) => ({
                                    key: fixtureRound.roundsFromFinal,
                                    text: (<CupRoundName roundsFromFinal={fixtureRound.roundsFromFinal} />),
                                    value: fixtureRound.roundsFromFinal
                                }))}
                                value={location.state?.fixtureRound}
                                onChange={
                                    (e, {value}) => history.replace(
                                        {
                                            pathname: location.pathname,
                                            state: {
                                                ...location.state,
                                                fixtureRound: value,
                                                search: location.search
                                            }
                                        }
                                    )
                                }
                                className="dropdownNoMinWidth"
                                loading={cupSeasonFixtureRoundsQuery.isLoading}
                                disabled={cupSeasonFixtureRoundsQuery.isLoading}
                            />
                        </Form.Field>
                        <Form.Field width={5}>
                            <label>Type</label>
                            <Menu compact size='tiny'>
                                <Menu.Item
                                    active={location.state?.fixtureStatus === 'complete'}
                                    onClick={() => history.replace({pathname: location.pathname, state: {...location.state, fixtureStatus: 'complete'}})}
                                >
                                    <SUIIconShim style={{margin: 0}}>
                                        <FontAwesomeIcon icon={faCheck} />
                                    </SUIIconShim>
                                </Menu.Item>
                                <Menu.Item
                                    active={location.state?.fixtureStatus === 'planned'}
                                    onClick={() => history.replace({pathname: location.pathname, state: {...location.state, fixtureStatus: 'planned'}})}
                                >
                                    <SUIIconShim style={{margin: 0}}>
                                        <FontAwesomeIcon icon={faHourglass} />
                                    </SUIIconShim>
                                </Menu.Item>
                            </Menu>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
            <FixturesInterface
                isLoading={cupSeasonFixturesQuery.isLoading}
                fixtures={cupSeasonFixturesQuery.data?.data.response}
                highlightTeamIds={[props.manager?.club?.firstTeam.id, props.manager?.club?.youthTeam.id]}
                view={'cupFixtures'}
            />
        </NarrowLayout>
    )
}

const messages = {
    screenTitle: {
        planned: {
            id: 'cup:fixtures:screenTitle',
            defaultMessage: '{cupName} fixtures',
            description: 'Screen title for cup fixtures page'
        },
        complete: {
            id: 'cup:fixtures:screenTitle',
            defaultMessage: '{cupName} results',
            description: 'Screen title for cup fixtures page'
        }
    }
}
import { Placeholder, Statistic } from "semantic-ui-react";
import Currency from "./Currency";
import colours from "../colours";

export default function LoadedStatistic(props) {
    return(
        <Statistic size='tiny' style={{display: 'block'}}>
            <Statistic.Value>
                {props.value === undefined ?
                    <Placeholder>
                        <Placeholder.Header className='fullWidth'>
                            <Placeholder.Line />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    props.isCurrency ? <Currency value={props.value} /> : props.value
                }
            </Statistic.Value>
            <Statistic.Label>{props.label}</Statistic.Label>
            {props.change !== undefined &&
                <Statistic.Label>
                    <span style={{color: props.change > 0 ? colours.positive : colours.negative}}>
                        <Currency value={props.change} /> available
                    </span>
                </Statistic.Label>
            }
            {props.percent !== undefined &&
                <Statistic.Label>
                    <span style={{color: (props.percent > 1 ? colours.negative : props.percent < 0.9 ? colours.positive : colours.orange)}}>
                        {Math.round(props.percent*100)}% of budget
                    </span>
                </Statistic.Label>
            }
        </Statistic>
    )
}
import Goalkeeper from "./goalkeeper";
import { convertXForOrientation, getTeamInitial, orientations, playerPositions } from "./lib";
import Player from "./player";

class Team {
    constructor(
        app,
        sim,
        orientation,
        colour,
        playerConfig
    ) {
        this.app = app;
        this.sim = sim;
        this.orientation = orientation;
        this.colour = colour;
        this.players = [];
        this.lastDefender = 0;

        const positions = Object.keys(playerConfig);
        for(let i=0; i<positions.length; i++) {
            const positionCount = playerConfig[positions[i]];
            const playerBounds = _getYDist(
                positions[i],
                positionCount
            );

            for(let n=0; n<positionCount; n++) {
                this.players.push(
                    new Player(
                        this,
                        this.players.length,
                        positions[i],
                        playerBounds * (n+1),
                        this.orientation === orientations.LEFT ? `L${this.players.length}` : `R${this.players.length}`
                    )
                );
            }
        }

        this.goalkeeper = new Goalkeeper(
            this,
            'GK'
        );

        this.logConfig = this.sim.logConfig[this.orientation];
    }

    hasPossession() {
        return this === this.sim.attackingTeam;
    }

    log(m) {
        if(this.logConfig.team === true) console.log(`${getTeamInitial(this.orientation)}: ${m}`);
    }

    buildFrame() {
        this.goalkeeper?.buildFrame();
        
        for(let i=0; i<this.players.length; i++) {
            this.players[i].buildFrame();
        }
    }

    reset() {
        for(let i=0; i<this.players.length; i++) {
            this.players[i].reset();
        }

        this.goalkeeper?.reset();
    }

    tick(time) {
        if(this === this.sim.attackingTeam) {
            this.tickAttack(time);
        } else if(this === this.sim.defendingTeam) {
            this.tickDefend(time);
        }
    }

    tickAttack(time) {
        if(this.orientation === orientations.LEFT) {
            this.mostAdvancedPlayerX = 0;
        } else if(this.orientation === orientations.RIGHT) {
            this.mostAdvancedPlayerX = 100;
        }
        this.lastDefender = null;
        
        for(let i=0; i<this.players.length; i++) {
            this.players[i].tickAttack(time);

            if(this.orientation === orientations.LEFT) {
                this.mostAdvancedPlayerX = Math.max(this.players[i].player.location.x, this.mostAdvancedPlayerX);
            } else if(this.orientation === orientations.RIGHT) {
                this.mostAdvancedPlayerX = Math.min(this.players[i].player.location.x, this.mostAdvancedPlayerX);
            }
            

            if(!this.lastDefender || convertXForOrientation(this.players[i].player.location.x, this.orientation) < convertXForOrientation(this.players[this.lastDefender].player.location.x, this.orientation)) {
                this.lastDefender = i;
            }
        }

        this.log(`Most advanced player: ${this.mostAdvancedPlayerX}`);
        this.log(`Last defender: A${this.lastDefender}`);
    }

    tickDefend(time) {
        if(this.orientation === orientations.LEFT) {
            this.defensiveLine = Math.min(this.sim.attackingTeam.mostAdvancedPlayerX, 22.5);
        } else if(this.orientation === orientations.RIGHT) {
            this.defensiveLine = Math.max(this.sim.attackingTeam.mostAdvancedPlayerX, 77.5);
        }
        
        this.log(`Defensive line: ${this.defensiveLine}`);

        this.lastDefender = null;
        for(let i=0; i<this.players.length; i++) {
            this.players[i].tickDefend(time);
            
            if(!this.lastDefender || convertXForOrientation(this.players[i].player.location.x, this.orientation) < convertXForOrientation(this.players[this.lastDefender].player.location.x, this.orientation)) {
                this.lastDefender = i;
            }
        }

        this.goalkeeper.tick(time);

        this.log(`Last defender: D${this.lastDefender}`);
    }
}

function _getYDist(position, count) {
    const bound = playerPositions[position].shape.y;

    const yWidth = bound[1] - bound[0];

    const yDist = (yWidth / (count + 1));

    return yDist;
}

export default Team;
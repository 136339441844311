import { Formik } from 'formik';
import {Form as SForm, Message, Divider, Loader, Segment} from "semantic-ui-react";
import {Form, Input, SubmitButton} from 'formik-semantic-ui-react';
import {Link} from 'react-router-dom';

import {URL} from '../../common/lib/paths';

export default function LoginInterface(props) {
    const handleSubmit = (values, actions) => {
        props.handleLoginSubmit(values, actions);
    }

    const loginErrorsDescription = {
        mismatch: 'Your email or password is incorrect',
        unknown: 'Something went wrong. Please try again later.'
    }

    return(
        <Segment>
            <Formik
                initialValues={{
                    email: '',
                    password: ''
                }}
                setSubmitting={props.isSubmitting}
                validate={values => {
                    const errors = {};

                    if(!values.email) {
                        errors.email = 'Email is required';
                    } else if(values.email.length > 255 || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = 'Email address invalid';
                    }

                    if(!values.password) {
                        errors.password = 'Password is required';
                    } else if(values.password.length < 5) {
                        errors.password = 'Password length must be at least 5 characters';
                    }

                    return errors;
                }}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({errors}) => (
                    <Form error={Object.keys(errors).length > 0 || props.loginError !== null}>
                        <SForm.Field required error={!!errors.email}>
                            <label>Email</label>
                            <Input
                                type='email'
                                name='email'
                                placeholder='Email'
                            />
                            {errors.email && <Message error content={errors.email} />}
                        </SForm.Field>
                        <Divider horizontal />
                        <SForm.Field required error={!!errors.password}>
                            <label>Password</label>
                            <Input
                                type='password'
                                name='password'
                                placeholder='Password'
                            />
                            {errors.password && <Message error content={errors.password} />}
                        </SForm.Field>
                        <Divider horizontal />
                        <SubmitButton positive className='forceInline' style={{marginRight: '1em'}}>Login</SubmitButton>
                        <Link to={URL.register}>Create an account</Link>
                        {props.loginError !== null && 
                            <Message error>
                                <p>
                                    {loginErrorsDescription[props.loginError]}
                                </p>
                            </Message>
                        }
                    </Form>
                )}
            </Formik>
        </Segment>
    )
}
import {useParams} from 'react-router';
import NarrowLayout from '../../common/components/NarrowLayout';

import PlayerProfile from './data';

export default function PlayerIDProvider(props) {
    const {playerId} = useParams();

    return(
        <NarrowLayout>
            <PlayerProfile
                playerId={playerId}
                manager={props.manager}
                setToolbar={props.setToolbar}
                tutorialOpen={props.tutorialOpen}
                setTutorialOpen={props.setTutorialOpen}
            />
        </NarrowLayout>
    );
}
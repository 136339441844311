"use strict";

import { BitmapText, Graphics} from 'pixi.js';
import {calculateDistance} from './physics';
import { getRandomInt, movementTypes, orientations, sizes } from './lib';
import Ball from './ball';
import Team from './team';
import { leftPlayers, rightPlayers } from './testData';

/*

    TODO

    1. Can handleDribbleNearComplete be removed now that dribbling is linear again? Seems unnecessary complexity
        I think the only reason I added it was to make dribbling easing smoother

*/

/*

    Long term stuff left to do

        1. Goal animation
            
        2. Add bias for specific goal scorer / assister
            a) remove position constraints
            b) prevent other players from shooting / scoring
            c) assister will always try to pass to scorer
            etc

            d) for some players, scoring from free play will look weird (rows 1-2) - these players always score from set pieces only

        3. Add a goalkeeper who occasionally saves shots

        4. Set pieces

        5. Very defensive teams take a long time to score.
            a) one option is to overweight dribbling / progressive passes for such teams (based on number of attacking players?)

*/

class Sim {
    constructor(
        app,
        cb,
        lockOrientation
    ) {
        this.app = app;

        this.debugFrame = false;

        this.ball = new Ball(this);

        this.cb = cb;

        this.lockOrientation = lockOrientation;

        this.logConfig = {
            sim: false,
            ball: false,
            left: {
                team: false,
                GK: false,
                // players: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                players: []
            },
            right: {
                team: false,
                GK: false,
                // players: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
                players: []
            },
            verbose: false
        }

        this.reset();

        this.graphics = _createGraphics(app);

        app.ticker.add(this.processFrame.bind(this));
    }

    reset() {
        this.frameNumber = 0;
        this.elapsedMS = 0;
        this.timeSincePossessionChanged = 0;

        this.preTick = null;
        
        this.start = false;
        this.isGoal = false;
        this.attackingTeam = null;
        this.defendingTeam = null;

        if(this.leftTeam === undefined && this.rightTeam === undefined) {
            this.leftTeam = new Team(this.app, this, orientations.LEFT, '#ff0000', leftPlayers);
            this.rightTeam = new Team(this.app, this, orientations.RIGHT, '#0000ff', rightPlayers);
        } else {
            this.leftTeam.reset();
            this.rightTeam.reset();
        }

        this.ball.reset();
        this.setPhasesOfPlay();
    }

    handleEnd() {
        this.isGoal = false;
        this.graphics.goal.visible = false;

        
        if(!!this.cb) {
            this.cb(this.ball.previousPlayer.player.name);
        }
    }

    handleGoal() {
        this.graphics.goal.visible = true;
        this.start = false;
        this.isGoal = true;

        console.log(this);

        window.setTimeout(this.handleEnd.bind(this), 1000);
    }

    hasPhaseOfPlayExpired() {
        return (
            this.timeSincePossessionChanged > 2000 &&
            this.phaseOfPlay < this.phasesOfPlay.length-1 &&
            this.elapsedMS > this.phasesOfPlay[this.phaseOfPlay].elapsedMS
        );
    }

    isLastPhaseOfPlay() {
        return this.phaseOfPlay === this.phasesOfPlay.length - 1;
    }

    setPhasesOfPlay() {
        this.phasesOfPlay = [];
        this.phaseOfPlay = -1;

        const numberOfPhases = getRandomInt(1, 5);
        const durationPerPhase = 25/numberOfPhases;

        for(let i=0; i<numberOfPhases; i++) {
            this.phasesOfPlay.push({
                teamInPossession: ['left', 'right'][getRandomInt(0, 1)],
                methodOfDispossession: i===numberOfPhases-1 ? null : ['tackle', 'interception'][getRandomInt(0, 1)],
                elapsedMS: (durationPerPhase * (i+1))*1000
            });
        }

        //setup first phase of play
        this.teamTakePossession(this.phasesOfPlay[0].teamInPossession, getRandomInt(0, 3))

        console.log(this.phasesOfPlay);
    }

    teamTakePossession(team, playerIndex) {
        if(this.phaseOfPlay === this.phasesOfPlay.length-1) {
            throw new Error('last phase of play');
        }

        if(team === 'left') {
            this.attackingTeam = this.leftTeam;
            this.defendingTeam = this.rightTeam;
            this.leftTeam.players[playerIndex].playerTakeBallPossession();
        } else if(team === 'right') {
            this.attackingTeam = this.rightTeam;
            this.defendingTeam = this.leftTeam;
            this.rightTeam.players[playerIndex].playerTakeBallPossession();
        }

        this.phaseOfPlay++;
        this.timeSincePossessionChanged = 0;
    }

    generateDistanceGraph() {
        for(let i=0; i<10; i++) {
            this.defendingTeam.players[i].friendlyDistanceNodes = [];
            this.defendingTeam.players[i].opponentDistanceNodes = [];
            this.defendingTeam.players[i].player.marking = null;
            this.defendingTeam.players[i].player.marker = null;

            this.attackingTeam.players[i].friendlyDistanceNodes = [];
            this.attackingTeam.players[i].opponentDistanceNodes = [];
            this.attackingTeam.players[i].player.marking = null;
            this.attackingTeam.players[i].player.marker = null;
        }

        for(let a=0; a<this.defendingTeam.players.length; a++) {
            for(let b = a+1; b < this.defendingTeam.players.length; b++) {
                const distance = calculateDistance(this.defendingTeam.players[a].player.location, this.defendingTeam.players[b].player.location);

                this.defendingTeam.players[a].friendlyDistanceNodes.push({
                    playerIndex: b,
                    distance
                });

                this.defendingTeam.players[b].friendlyDistanceNodes.push({
                    playerIndex: a,
                    distance
                });
            }

            this.defendingTeam.players[a].friendlyDistanceNodes.sort((a, b) => a.distance - b.distance);
        }

        for(let a=0; a<this.attackingTeam.players.length; a++) {
            for(let b = a+1; b < this.attackingTeam.players.length; b++) {
                const distance = calculateDistance(this.attackingTeam.players[a].player.location, this.attackingTeam.players[b].player.location);

                this.attackingTeam.players[a].friendlyDistanceNodes.push({
                    playerIndex: b,
                    distance
                });

                this.attackingTeam.players[b].friendlyDistanceNodes.push({
                    playerIndex: a,
                    distance
                });
            }

            this.attackingTeam.players[a].friendlyDistanceNodes.sort((a, b) => a.distance - b.distance);
        }

        const marking = [];
        for(let a=0; a<this.defendingTeam.players.length; a++) {
            for(let b=0; b<this.attackingTeam.players.length; b++) {
                const distance = calculateDistance(this.defendingTeam.players[a].player.location, this.attackingTeam.players[b].player.location);

                this.defendingTeam.players[a].opponentDistanceNodes.push({
                    playerIndex: b,
                    distance
                });

                this.attackingTeam.players[b].opponentDistanceNodes.push({
                    playerIndex: a,
                    distance
                });
            }

            this.defendingTeam.players[a].opponentDistanceNodes.sort((a, b) => a.distance - b.distance);

            marking.push({
                dIndex: a,
                aIndex: this.defendingTeam.players[a].opponentDistanceNodes[0].playerIndex,
                distance: this.defendingTeam.players[a].opponentDistanceNodes[0].distance
            });
        }

        for(let b=0; b<this.attackingTeam.players.length; b++) {
            this.attackingTeam.players[b].opponentDistanceNodes.sort((a, b) => a.distance - b.distance);
        }

        marking.sort((a, b) => a.distance - b.distance);

        if(this.ball.currentPlayer !== null) {
            //mark ball holder first
            marking.unshift({
                aIndex: this.ball.currentPlayer.player.index,
                dIndex: this.attackingTeam.players[this.ball.currentPlayer.player.index].opponentDistanceNodes[0].playerIndex,
                distance: this.attackingTeam.players[this.ball.currentPlayer.player.index].opponentDistanceNodes[0].playerIndex
            });
        }

        for(let i=0; i<marking.length; i++) {
            if(
                !!this.defendingTeam.players[marking[i].dIndex].player.marking ||
                !!this.attackingTeam.players[marking[i].aIndex].player.marker
            ) continue; //skip if either player is marking/has marker already

            if(marking[i].aIndex === this.ball.currentPlayer?.player.index) {
                //ensure ball holder is always being closed down
                this.defendingTeam.players[marking[i].dIndex].player.marking = marking[i];
                this.attackingTeam.players[marking[i].aIndex].player.marker = marking[i];
            } else if(
                marking[i].distance < 10 && //only mark nearby players
                this.attackingTeam.players[marking[i].aIndex].player.location.x-5 > this.ball.location.x//only mark if attacker is in front of phase of play
            ) {
                this.defendingTeam.players[marking[i].dIndex].player.marking = marking[i];
                this.attackingTeam.players[marking[i].aIndex].player.marker = marking[i];
            }
        }
    }

    tick(time) {
        if(typeof this.preTick === 'function') {
            this.preTick();
            this.preTick = null;
        }

        this.generateDistanceGraph();

        this.leftTeam.tick(time);
        this.rightTeam.tick(time);
        this.ball.tick(time);

        if(this.logConfig.sim === true) {
            console.log(this);
        }
    }

    buildFrame() {
        this.attackingTeam.buildFrame();
        this.defendingTeam.buildFrame();

        this.graphics.ball.position = this.transformPos(this.ball.location);

        if(
            !!window.debug &&
            window.debug.globals.dribbleTarget === true &&
            this.ball.currentPlayer !== null &&
            this.ball.currentPlayer.player.movement?.type === movementTypes.DRIBBLE
        ) {
            this.graphics.dribbleTarget.x = this._transformPosX(this.ball.currentPlayer.player.movement.toLocation.x);
            this.graphics.dribbleTarget.y = this._transformPosY(this.ball.currentPlayer.player.movement.toLocation.y);
            this.graphics.dribbleTarget.visible = true;
        } else {
            this.graphics.dribbleTarget.visible = false;
        }
    }

    transformPos(point) {
        const orientation = getOrientation(this.lockOrientation);

        let x;
        let y;

        if(orientation === 'portrait') {
            //translate through 90 degrees
            x = this._transformPosX(point.y, sizes.pitch.y);
            y = this._transformPosY(sizes.pitch.x-point.x, sizes.pitch.x);
        } else {
            x = this._transformPosX(point.x, sizes.pitch.x);
            y = this._transformPosY(point.y, sizes.pitch.y);
        }

        return {x, y};
    }

    _transformPosX(x, pitchX) {
        return (( this.app.renderer.width / pitchX ) * x);
    }

    _transformPosY(y, pitchY) {
        return (( this.app.renderer.height / pitchY ) * y);
    }

    processFrame(time) {
        if(!this.start) return;

        console.log(`Frame number: ${this.frameNumber}`);

        this.tick(time);
        this.buildFrame();

        this.frameNumber++;
        this.elapsedMS += time.deltaMS;
        this.timeSincePossessionChanged += time.deltaMS;
    }
}

function getOrientation(lock) {
    if(lock === true || window.innerWidth >= window.innerHeight) {
        return 'landscape';
    } else {
        return 'portrait';
    }
}

function _createGraphics(
    app
) {
    const graphics = {};

    graphics.goal = new BitmapText({
        text: 'GOAL!!!',
        visible: false,
        style: {
            fontFamily: 'Arial',
            fontSize: 240,
            fill: '#000'
        }
    });

    graphics.ball = new Graphics()
        .circle(0, 0, sizes.ballRadius)
        .fill('#FFF')
        .stroke('#000');

    graphics.dribbleTarget = new Graphics({visible: false})
        .circle(0, 0, 4)
        .fill('#00FF00')
        .stroke('#000');

    app.stage.interactiveChildren = false;

    app.stage.addChild(graphics.goal, graphics.ball, graphics.dribbleTarget);

    return graphics;
}

export default Sim;
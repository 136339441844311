import {useEffect} from 'react';

import Players from '../toolbar/Players';
import Staff from '../toolbar/Staff';
import History from '../toolbar/History';
import Scouting from '../toolbar/Scouting';

import StaffSearchFilterButton from '../../staff/filter/button/button';
import WideLayout from '../../common/components/WideLayout';
import Auctions from '../toolbar/Auction';
import OffersNew from '../toolbar/Offers';
import { isFilterActive, initialFilterState, filterStateReducer } from '../../staff/filter/lib';
import usePersistedState from '../../common/lib/usePersistedState';
import { useLocation } from 'react-router';
import useGetStaff from './useGetStaff';
import { Button } from 'semantic-ui-react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import StaffListView from '../../staff/list/StaffListView';
import SUIIconShim from '../../common/components/SUIIconShim';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StaffSearch(props) {
    const location = useLocation();
    const {persistedState, updatePersistedState} = usePersistedState('staffSearchFilter', initialFilterState);

    useEffect(() => {
        props.setToolbar({
            title: 'Transfer centre',
            navigation: [
                <OffersNew />,
                <Players key={2} />,
                <Staff key={3} />,
                <Auctions key={7} />,
                <Scouting key={4} />,
                <History key={6} />
            ]
        });

        if(location.state !== undefined) {
            updatePersistedState({...persistedState, ...location.state});
        }
    }, []);

    const {isLoading, data} = useGetStaff(persistedState, false, true);

    return(
        <WideLayout>
            <StaffSearchFilterButton
                loading={isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey='staffSearchFilter'
            />
            <StaffListView
                isLoading={isLoading}
                staff={data?.data?.response}
            />
            <Button.Group>
                <Button
                    icon
                    disabled={persistedState.page === 1}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </SUIIconShim>
                </Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.page}
                </Button>
                <Button
                    icon
                    disabled={props.staff?.length < 10}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </SUIIconShim>
                </Button>
            </Button.Group>
        </WideLayout>
    )
}
import { Redirect, generatePath } from 'react-router';
import {useTutorial} from '../hooks/useTutorial';

import tutorialData from '../tutorials/messages';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Loader } from 'semantic-ui-react';
import useGetMessages from '../../messages/components/inbox/useGetMessages';

export default function MessagesTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    const {data, isSuccess} = useGetMessages(!cachedTutorialData);

    useEffect(() => {
        if(!cachedTutorialData && isSuccess) {
            var compiledTutorialData = cloneDeep(tutorialData);

            if(!data.data.response.length > 0) {
                //delete steps 2-5 if user does not have any messages
                compiledTutorialData.steps.splice(1, 4);
            }

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                    case 3:
                    case 4:
                    case 5:
                        step.path = {
                            pathname: step.path
                        }
                        break;
                    case 2:
                        step.path = {
                            pathname: generatePath(step.path, {messageId: data.data.response[0].id})
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData, isSuccess]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
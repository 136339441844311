import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/lib/paths";
import { Header } from "semantic-ui-react";

export default {
    key: 'intro',
    name: 'Introduction',
    loaderPath: URL.introTutorial,
    icon: faInfoCircle,
    description: 'Introduction to the game',
    steps: [
        {
            key: 0,
            path: URL.tutorialMenu,
            description: 'Hello, I shall be your guide! I live in the 3 dots in the top right hand corner of your screen. If you don\'t want me, turn me off by selecting this orange flashing X . However if you do want to learn more please select \"take a look\" and then select the flashing orange arrow icon.',
            summary: 'Find the tutorial button',
            task: 'browse'
        },
        {
            key: 4,
            path: URL.tutorialMenu,
            description: (
                <>
                    <Header as="h4">Whats the game?</Header>
                    <p>It is a league table/ pyramid system with promotion and relegation against real people and Ai. A season plays over a 7 day period from Monday to Sunday and consists of 18 games. This translates to 3 games a day between Monday and Saturday.</p>
                    <Header as="h4">Whats my role?</Header>
                    <p>You are the manager, starting in E league you will utilize tactics and transfers of your chosen club to try and climb to the top of the pyramid which is A league.</p>
                    <Header as="h4">Are there any other competitions?</Header>
                    <p>The mug of nations, an elite competition for the top A league sides from different nations. Nations such as England or the United states have their own league pyramids from E- A league. It is a knock out competition. The winner will get a trophy in game and a mug in real life if they have chosen to be a member.</p>
                    <Header as="h4">Can I lose my job?</Header>
                    <p>Yes. However you can also earn better jobs. Move between the nations to get a trophy's across the world and set new in game records. However the higher your climb, the easier it is to lose your job.</p>
                </>
            ),
            task: 'info'
        },
        {
            key: 1,
            path: URL.tutorialMenu,
            description: (
                <>
                    <p>See those buttons on the left hand side of your screen? Here is what they are in order:</p>
                    <ul>
                        <li>Finance</li>
                        <li>News</li>
                        <li>Messages</li>
                        <li>Players</li>
                        <li>Tactics</li>
                        <li>Training</li>
                        <li>Youth team</li>
                        <li>Staff</li>
                        <li>Club</li>
                        <li>Fixtures</li>
                        <li>League</li>
                        <li>Tranfers</li>
                        <li>World</li>
                    </ul>
                    <p>Select "lets do this" to get a better look</p>
                </>
            ),
            summary: 'Navigation!',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.tutorialMenu,
            description: 'If you ever want to go back to the previous page use the arrow at the top of your screen next to Manage.soccer logo.',
            summary: 'Click the back button',
            task: 'interaction'
        },
        {
            key: 3,
            path: URL.tutorialMenu,
            description: 'Well it seems we off to a good start, you know the basics!'
        }
    ]
}
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { Loader } from "semantic-ui-react";
import CreatePlayerLoanOffer from "./create/data";
import ViewPlayerLoanOffer from "./view/data";

export default function PlayerLoanOffer(props) {
    const getPlayerLoanOfferQuery = useQuery(
        ['getPlayerPlayerLoanOffer', props.player?.playerContract.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerContracts/${props.player?.playerContract.id}/playerTransferOfferLoan`),
        {
            enabled: props.isLoading === false
        }
    );
    
    if(props.isLoading || getPlayerLoanOfferQuery.isLoading) {
        return(
            <Loader active />
        );
    } else {
        if(getPlayerLoanOfferQuery.data?.data?.response.length > 0) {
            return(
                <ViewPlayerLoanOffer
                    manager={props.manager}
                    playerTransferOfferLoanId={getPlayerLoanOfferQuery.data?.data?.response[0].id}
                />
            );
        } else {
            return(
                <CreatePlayerLoanOffer
                    player={props.player}
                />
            );
        }
    }
}
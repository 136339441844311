import { faClockRotateLeft } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Menu, Modal } from "semantic-ui-react";
import NarrowLayout from "./NarrowLayout";
import { useHistory, useLocation } from "react-router";
import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

export default function SeasonSelectorModal(props) {
    const history = useHistory();
    const location = useLocation();
    const intl = useIntl();
    const [open, setOpen] = useState(false);
    const [selectedSeasonId, setSelectedSeasonId] = useState(location.state?.seasonId);

    const seasonOptions = useMemo(() => {
        if(!props.seasons) {
            return []
        } else {
            return props.seasons.map((season) => ({
                key: season.id,
                text: intl.formatDate(season.startDate, {day: 'numeric', month: 'short', year: '2-digit'}),
                value: season.id
            }))
        }
    }, [props.seasons]);

    return(
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={props.children}
        >
            <Modal.Header>
                Select season
            </Modal.Header>
            <Modal.Content>
                <NarrowLayout>
                    <Form>
                        <Form.Select
                            options={seasonOptions}
                            value={selectedSeasonId}
                            loading={props.isLoading}
                            disabled={props.isLoading}
                            onChange={(e, {value}) => setSelectedSeasonId(value)}
                            label='Season'
                            fluid
                        />
                    </Form>
                </NarrowLayout>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    positive
                    onClick={handleChangeSeasonId}
                    disabled={!selectedSeasonId}
                >
                    Confirm
                </Button>
            </Modal.Actions>
        </Modal>
    );

    function handleChangeSeasonId() {
        history.replace({
            pathname: location.pathname,
            state: {...location.state, seasonId: selectedSeasonId}
        });

        setOpen(false);
    }
}
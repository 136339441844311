import { defineMessages } from "@formatjs/intl";
import {compileMessage} from './util.js';

const messages = {
    'playerTransferAuctionPaid:started': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferAuctionPaid:started',
            defaultMessage: "{playerName} auction starts",
            description: "Message subject for the system message template which a user receives when an auction for player on their favourites begins"
        },
        body: {
            id: 'playerTransferAuctionPaid:started',
            defaultMessage: "The auction for {playerName} is now underway. The current highest bid is {currentBid} from {topBidClubName}.\n\nThe auction is due to finish between {endDateMin} and {endDateMax}.",
            description: "Message subject for the system message template which a user receives when an auction for player on their favourites begins"
        }
    })),
    'playerTransferAuctionPaid:newBid': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferAuctionPaid:newBid',
            defaultMessage: "{playerName} auction bid",
            description: "Message subject for the system message template which a user receives when an bid has been made in an auction for player on their favourites"
        },
        body: {
            id: 'playerTransferAuctionPaid:newBid',
            defaultMessage: "{biddingClubName} have made a bid in the auction for {playerName}. The current highest bid is {currentBid} from {topBidClubName}.\n\nThe auction is due to finish between {endDateMin} and {endDateMax}",
            description: "Message body for the system message template which a user receives when an bid has been made in an auction for player on their favourites"
        }
    })),
    'playerTransferAuctionPaid:finished': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferAuctionPaid:finished',
            defaultMessage: "{playerName} auction finished",
            description: "Message subject for the system message template which a user receives when an auction for player on their favourites finishes"
        },
        body: {
            id: 'playerTransferAuctionPaid:finished',
            defaultMessage: "After {bidCount} bids the auction for {playerName} completed at {auctionEndDate}. Click below to find out who won.",
            description: "Message body for the system message template which a user receives when an auction for player on their favourites finishes"
        }
    })),
}

export default messages;
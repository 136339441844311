import { defineMessages } from "@formatjs/intl";

const messages = {
    'favPlayer:playerReleasedFromClub': defineMessages({
        subject: {
            id: "messageSubject:favPlayer:playerReleasedFromClub",
            defaultMessage: "{playerName} has been released",
            description: "Subject for the system message template which a user receives when a club releases a player on their favourites list"
        },
        body: {
            id: "messageBody:favPlayer:playerReleasedFromClub",
            defaultMessage: "{releasingClubName} have released {playerName}, who is on your favourites list. You are now able to approach them on a free transfer.",
            description: "Message body for the system message template which a user receives when a club club releases a player on their favourites list"
        }
    }),
    'transferOffer:renewed:hadOtherOffers': defineMessages({
        subject: {
            id: 'messageSubject:transferOffer:contractOfferAccepted:hadOtherOffers',
            defaultMessage: '{playerName} renews with {proposingClubName}',
            description: 'Subject for the message template which a user receives when a player has renewed a contract with their existing club'
        },
        body: {
            id: 'messageBody:transferOffer:contractOfferAccepted:hadOtherOffers',
            defaultMessage: '{playerName} has today signed a new {contractLength} season contract with his existing club {proposingClubName}, worth {contractSalary} per week.\n\nHis new contract will run until {contractExpiry}.\n\nIn commiting to his new deal he has rejected transfer offers from: {rejectedClubNames}.',
            description: 'Message body for the message template which a user receives when a player accepts a transfer to another club'
        }
    }),
    'transferOffer:renewed:noOtherOffers': defineMessages({
        subject: {
            id: 'messageSubject:transferOffer:contractOfferAccepted:hadOtherOffers',
            defaultMessage: '{playerName} renews with {proposingClubName}',
            description: 'Subject for the message template which a user receives when a player has renewed a contract with their existing club'
        },
        body: {
            id: 'messageBody:transferOffer:contractOfferAccepted:hadOtherOffers',
            defaultMessage: '{playerName} has today signed a new {contractLength} season contract with his existing club {proposingClubName}, worth {contractSalary} per week.\n\nHis new contract will run until {contractExpiry}.',
            description: 'Message body for the message template which a user receives when a player accepts a transfer to another club'
        }
    }),
    'transferOffer:cancelled:retiring': defineMessages({
        subject: {
            id: 'messageSubject:transferOffer:cancelled:retiring',
            defaultMessage: 'Transfer offer for {playerName} cancelled',
            description: 'Subject for the message template which a user receives when their transfer offer gets cancelled because the player has decided to retire'
        },
        body: {
            id: 'messageBody:transferOffer:cancelled:retiring',
            defaultMessage: 'Your transfer offer for {playerName} has been cancelled because they have decided to retire at the end of the season.',
            description: 'Messagebody for the message template which a user receives when their transfer offer gets cancelled because the player has decided to retire'
        }
    }),
    'favPlayer:player:retiring': defineMessages({
        subject: {
            id: "messageSubject:player:retiring",
            defaultMessage: "{playerName} announces retirement",
            description: "Subject for the system message which a user receives when their player announces his retirement"
        },
        body: {
            id: "messageBody:player:retiring",
            defaultMessage: "{playerName}, who is on your favourites, has announced today that, at the age of {age}, this season will be their last. They will retire at the end of the season.",
            description: "Message body for the system message which a user receives when their player announces his retirement"
        }
    }),
    'transferWindow:open': defineMessages({
        subject: {
            id: 'messageSubject:transferWindow:open',
            defaultMessage: 'Transfer window open',
            description: 'Subject for the message template which a user receives when the transfer window opens'
        },
        body: {
            id: 'messageBody:transferWindow:open',
            defaultMessage: 'The transfer window is now open, so you can go and sign new players.',
            description: 'message body for the message template which a user receives when the transfer window opens'
        }
    }),
    'transferWindow:closed': defineMessages({
        subject: {
            id: 'messageSubject:transferWindow:closed',
            defaultMessage: 'Transfer window closed',
            description: 'Subject for the message template which a user receives when the transfer window closes'
        },
        body: {
            id: 'messageBody:transferWindow:closed',
            defaultMessage: 'The transfer window is now closed, any transfers that you agree will not complete until it re-opens later in the season.',
            description: 'message body for the message template which a user receives when the transfer window closes'
        }
    }),
    'favPlayer:player:retrainedPosition': defineMessages({
        subject: {
            id: 'messageSubject:player:retrainedPosition',
            defaultMessage: '{playerName} new position',
            description: 'Subject for the system message which a user receives when a player on their favourites list has changed their position'
        },
        body: {
            id: 'messageBody:player:retrainedPosition',
            defaultMessage: '{playerName}, who is on your favourites list, has been retrained from a {originalPosition} and he is now a {newPosition}.',
            description: 'Message bdoy for the system message which a user receives when a player on their favourites list has changed their position'
        }
    })
}

export default messages;
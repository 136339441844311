import axios from "axios";
import usePersistedState from "../../../common/lib/usePersistedState";
import { initialFilterState as IFS, filterStateReducer as FSR } from "../lib";
import { useQuery } from "@tanstack/react-query";
import { useReducer } from "react";
import { Button, Checkbox, Dimmer, Divider, Form, Grid } from "semantic-ui-react";
import LeagueLevelName from "../../../league/components/common/LeagueLevelName";
import NationGroupNameWithFlag from '../../../nationGroup/common/NationGroupNameWithFlag';

const styles = {
    overlayContainer: {
        position: "fixed",
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        maxWidth: '400px',
        marginLeft: 'auto',
        marginRight: 'auto',
        bottom: 0,
        zIndex: 99999
    },
    overlay: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexWrap: "nowrap",
        justifyContent: "flex-end",
        alignContent: "stretch",
        alignItems: "stretch"
    },
    dimmer: {
        order: 0,
        flex: "1 1 auto",
        alignSelf: "auto",
        zIndex: -1
    },
    filters: {
        order: 0,
        flex: "0 1 auto",
        alignSelf: "auto",
        overflow: "auto",
        maxHeight: "min-content",
        backgroundColor: "#FFFFFF"
    },
    form: {
        padding: "1rem 1rem 0 1rem"
    },
    submit: {
        padding: "0 1rem 1rem 1rem",
        order: 0,
        flex: "0 0 auto",
        alignSelf: "auto",
        backgroundColor: "#FFFFFF",
    }
}

function filterStateReducer(currentFilterState, action) {
    let newFilterState = {
        filterLeagueLevel: currentFilterState.filterLeagueLevel,
        filterNationShortcode: currentFilterState.filterNationShortcode,
        filterUnmanagedClubs: currentFilterState.filterUnmanagedClubs
    }

    switch(action.action) {
        case 'filterLeagueLevel':
            newFilterState.filterLeagueLevel = action.value;
            break;
        case 'filterNationShortcode':
            newFilterState.filterNationShortcode = action.value;
            break;
        case 'filterUnmanagedClubs':
            newFilterState.filterUnmanagedClubs = !currentFilterState.filterUnmanagedClubs;
            break;
    }

    return newFilterState
}

export default function ClubFilterOverlay(props) {
    const {persistedState, updatePersistedState} = usePersistedState(props.persistedStateKey, IFS);

    const nationsQuery = useQuery(
        ['getNationGroups'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nationGroups`)
    );

    const leagueLevelQuery = useQuery(
        ['getDistinctLeagueLevels'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueLevels?distinct=true`)
    );

    const initialFilterState = {
        filterLeagueLevel: persistedState.filterLeagueLevel,
        filterNationShortcode: persistedState.filterNationShortcode,
        filterUnmanagedClubs: persistedState.filterUnmanagedClubs
    }

    const [filterState, filterDispatch] = useReducer(filterStateReducer, initialFilterState);

    return(
        <div style={styles.overlayContainer}>
            <Dimmer active={true} page={true} />
            <div style={styles.overlay} className='transferSearchFilterOverlayTarget'>
                <div style={styles.dimmer}></div>
                <div style={styles.filters}>
                    <Form style={styles.form}>
                        <Form.Field>
                            <Form.Dropdown
                                selection
                                fluid
                                clearable
                                selectOnBlur={false}
                                label='League level'
                                placeholder='All league levels'
                                loading={leagueLevelQuery.isLoading}
                                disabled={leagueLevelQuery.isLoading}
                                options={leagueLevelQuery.data?.data.map((leagueLevel) => (
                                    {
                                        key: leagueLevel.level,
                                        value: leagueLevel.level,
                                        text: (<LeagueLevelName leagueLevel={leagueLevel} />)
                                    }
                                )) || []}
                                onChange={handleFilterLeagueLevel}
                                value={filterState.filterLeagueLevel}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown
                                selection
                                fluid
                                clearable
                                selectOnBlur={false}
                                placeholder='All nations'
                                label='Nation'
                                loading={nationsQuery.isLoading}
                                disabled={nationsQuery.isLoading || props.persistedStateKey === 'nationClubsFilter'}
                                options={nationsQuery.data?.data.response.map((nation) => (
                                    {
                                        key: nation.shortcode,
                                        value: nation.shortcode,
                                        text: (<NationGroupNameWithFlag responsiveClass='nationFlag' shortcode={nation.shortcode} />)
                                    }
                                )) || []}
                                onChange={handleFilterNationShortcode}
                                value={filterState.filterNationShortcode}
                            />
                        </Form.Field>
                        <Divider horizontal />
                        <Form.Field style={{marginBottom: '1em'}}>
                            <Checkbox
                                label='Include managed clubs'
                                checked={!filterState.filterUnmanagedClubs}
                                onChange={handleFilterUnmanagedClubs.bind(this)}
                            />
                        </Form.Field>
                    </Form>
                </div>
                <div style={styles.submit}>
                    <Divider />
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={8}>
                                <Button fluid onClick={handleCloseFilterClick.bind(this)}>Cancel</Button>
                            </Grid.Column>
                            <Grid.Column width={8}>
                                <Button
                                    positive
                                    fluid
                                    onClick={handleApplyFilterClick.bind(this)}
                                >Apply</Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </div>
        </div>
    );

    function handleFilterLeagueLevel(e, {value}) {
        filterDispatch({action: 'filterLeagueLevel', value});
    }

    function handleFilterNationShortcode(e, {value}) {
        filterDispatch({action: 'filterNationShortcode', value});
    }

    function handleFilterUnmanagedClubs() {
        filterDispatch({action: 'filterUnmanagedClubs'});
    }

    function handleApplyFilterClick() {
        updatePersistedState(FSR(persistedState, {
            name: 'updateFilters',
            value: filterState
        }));
    }

    function handleCloseFilterClick() {
        updatePersistedState(FSR(persistedState, {
            name: 'toggleFilterOverlay'
        }));
    }
}
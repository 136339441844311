import { Button, Grid, Message, Modal, Statistic } from "semantic-ui-react";
import TitledSegment from "../../../common/components/TitledSegment";
import Currency from "../../../common/components/Currency";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPencil, faRotate, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useReducer, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import LoadedStatistic from "../../../common/components/LoadedStatistic";
import { useTutorial } from "../../../tutorial/hooks/useTutorial";

export default function ClubFinancesInterface(props) {
    return(
        <div>
            <TitledSegment
                title='Overview'
            >
                <Grid className='gridReset'>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <LoadedStatistic
                                label='Balance'
                                value={props.club?.balance}
                                isCurrency={true}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <LoadedStatistic
                                label='Wage bill'
                                value={props.club?.currentWageBill}
                                percent={props.club?.currentWageBill / (props.club?.totalWageBudget)}
                                isCurrency={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </TitledSegment>
            <TitledSegment
                title='Budgets'
            >
                <Grid className='gridReset'>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <LoadedStatistic
                                label='Transfer budget'
                                change={props.club?.remainingTransferBudget}
                                value={props.club?.transferBudget}
                                isCurrency={true}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <LoadedStatistic
                                label='Wage budget'
                                change={props.club?.remainingWageBudget}
                                value={props.club?.totalWageBudget}
                                isCurrency={true}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    {!props.isLoading &&
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                {props.club.minAllocation < props.club.maxAllocation ?
                                    <BudgetEditor
                                        transferBudget={props.club.transferBudget}
                                        minWageBudget={props.club.minWageBudget}
                                        incWageBudget={props.club.incWageBudget}
                                        maxWageBudget={props.club.maxWageBudget}
                                        allocatableBalance={props.club.allocatableBalance}
                                        allocation={props.club.budgetAllocation}
                                        maxAllocation={props.club.maxAllocation}
                                        minAllocation={props.club.minAllocation}
                                        wageBill={props.club.currentWageBill}
                                        transferSpend={props.club.transferSpend}
                                        transferBudgetHolds={props.club.transferBudgetHolds}
                                        wageBudgetHolds={props.club.wageBudgetHolds}
                                    />
                                    :
                                    <Message
                                        warning
                                        header='Board restriction'
                                        content='The board will not allow you to change your budgets at this time'
                                    />
                                }
                            </Grid.Column>
                        </Grid.Row>
                    }
                </Grid>
            </TitledSegment>
        </div>
    );
}

function BudgetEditor(props) {
    const [open, setOpen] = useState();
    const [budgetState, budgetDispatch] = useReducer(budgetReducer, {
        transferBudget: props.transferBudget,
        incWageBudget: props.incWageBudget,
        allocatableBalance: props.allocatableBalance,
        allocation: props.allocation
    });
    const {tutorialTopic, tutorialStep, goNextStep, checkTutorial} = useTutorial();

    const queryClient = useQueryClient();

    const setBudgetAllocation = useMutation(
        (allocation) => axios.patch(`${process.env.REACT_APP_APPHOST}/clubs`, {
            allocation
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries('getClubFinances');
                setOpen(false);
            }
        }
    );

    const isTutorial = checkTutorial('finances', 1);

    return(
        <Modal
            onClose={() => setOpen(false)}
            onOpen={handleOpenModal}
            open={open}
            trigger={
                <Button
                    icon
                    labelPosition='left'
                    style={{display: 'block', margin: '0 auto'}}
                    className={isTutorial && 'glow'}
                >
                    Change allocation
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faPencil} className='buttonIconFix' />
                    </SUIIconShim>
                </Button>
            }
        >
            <Modal.Header className='headerFixPadding'>
                Edit budgets
                <Button
                    icon
                    floated="right"
                    size='mini'
                    onClick={() => budgetDispatch({action: 'resetAllocation'})}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faRotate} />
                    </SUIIconShim>
                </Button>
            </Modal.Header>
            <Modal.Content>
                <Grid className='gridReset'>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Statistic size='tiny' style={{display: 'block'}}>
                                <Statistic.Label>Transfer budget</Statistic.Label>
                                <Statistic.Value><Currency value={budgetState.transferBudget} /></Statistic.Value><br />
                                <Statistic.Label><Currency value={props.transferBudgetHolds} /> pending</Statistic.Label>
                                <Statistic.Label><Currency value={budgetState.transferBudget-props.transferBudgetHolds} /> available</Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic size='tiny' style={{display: 'block'}}>
                                <Statistic.Label>Wage budget</Statistic.Label>
                                <Statistic.Value><Currency value={props.minWageBudget+budgetState.incWageBudget} /></Statistic.Value><br />
                                <Statistic.Label><Currency value={props.wageBill} /> used</Statistic.Label>
                                <Statistic.Label><Currency value={props.wageBudgetHolds} /> pending</Statistic.Label>
                                <Statistic.Label><Currency value={(props.minWageBudget+budgetState.incWageBudget)-(props.wageBill+props.wageBudgetHolds)} /> available</Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column style={{padding: 0}}>
                            <div style={styles.a}>
                                <div style={styles.b}>
                                    <span>{100-budgetState.allocation}%</span>
                                </div>
                                <div style={styles.c}>
                                    <input
                                        type='range'
                                        style={{width: '100%', display: 'block'}}
                                        step='1'
                                        min='0'
                                        max='100'
                                        value={budgetState.allocation}
                                        onChange={(e) => budgetDispatch({action: 'setAllocation', value: parseInt(e.target.value)})}
                                    />
                                </div>
                                <div style={styles.d}>
                                    <span>{budgetState.allocation}%</span>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column textAlign="center">
                            <p>The club have enforced a maximum wage budget of <Currency value={Math.min(props.maxWageBudget, props.minWageBudget+(props.allocatableBalance/3))} />.</p>
                            <p>Your current wage bill is <Currency value={props.wageBill} /></p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    icon
                    labelPosition="left"
                    onClick={() => setOpen(false)}
                >
                    Cancel
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faXmark} className='buttonIconFix' />
                    </SUIIconShim>
                </Button>
                <Button
                    positive
                    icon
                    labelPosition="left"
                    onClick={handleSubmit}
                    loading={setBudgetAllocation.isLoading}
                >
                    Confirm
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faCheck} className='buttonIconFix' />
                    </SUIIconShim>
                </Button>
            </Modal.Actions>
        </Modal>
    );

    function handleOpenModal() {
        if(isTutorial) {
            goNextStep();
        }

        budgetDispatch({action: 'resetAllocation'});
        setOpen(true);
    }

    function handleSubmit() {
        setBudgetAllocation.mutate(budgetState.allocation);
    }

    function budgetReducer(currentBudgetState, dispatch) {
        switch(dispatch.action) {
            case 'setAllocation':
                if(dispatch.value > props.maxAllocation) {
                    return getValue(Math.floor(props.maxAllocation));
                }

                if(dispatch.value < props.minAllocation) {
                    return getValue(Math.ceil(props.minAllocation));
                }

                // if(dispatch.value > props.maxAllocation || dispatch.value < props.minAllocation) {
                //     return currentBudgetState;
                // }
    
                return getValue(dispatch.value);
            case 'resetAllocation':
                return {
                    transferBudget: props.transferBudget,
                    incWageBudget: props.incWageBudget,
                    allocatableBalance: props.allocatableBalance,
                    allocation: props.allocation
                }
        }

        function getValue(allocation) {
            return {
                transferBudget: currentBudgetState.allocatableBalance*(1-(allocation/100)),
                incWageBudget: (currentBudgetState.allocatableBalance*(allocation/100))/3,
                allocatableBalance: currentBudgetState.allocatableBalance,
                allocation: allocation
            }
        }
    }
}

const styles = {
    a: {
        display: 'flex'
    },
    b: {
        paddingRight: '0.5em'
    },
    c: {
        flexGrow: 1,
        alignSelf: 'center'
    },
    d: {
        paddingLeft: '0.5em'
    }
}
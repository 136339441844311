import {useEffect, useState} from "react";
import {useParams, useLocation, useHistory, Link, generatePath, matchPath} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import FixturesInterface from "../../../fixtures/interface.js";
import TeamName from "../../../team/common/TeamName";
import NarrowLayout from "../../../common/components/NarrowLayout";
import WideLayout from "../../../common/components/WideLayout";
import { Menu, Form, Dropdown, Modal, Button } from "semantic-ui-react";
import { faCheck, faChild, faClock, faHourglass, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { URL } from "../../../common/lib/paths";
import ClubProfileToolbarItem from "../toolbar/ClubProfile.js";
import TeamPlayersToolbarItem from "../toolbar/TeamPlayers.js";
import TeamFixturesToolbarItem from "../toolbar/TeamFixtures.js";
import ClubStaffToolbarItem from "../toolbar/ClubStaff.js";
import ClubTransfersToolbarItem from "../toolbar/ClubTransfers.js";
import TeamLeagueSeasonsToolbarItem from "../toolbar/TeamLeagueSeasons.js";
import TeamTacticsToolbarItem from "../toolbar/TeamTactics";
import TeamLeagueToolbarItem from "../toolbar/TeamLeague";
import TeamTrainingToolbarItem from "../toolbar/TeamTraining";
import useGetTeamLS from "../../../common/data/useGetTeamLS";
import useGetTeamFixtures from "./useGetTeamFixtures";

export default function TeamFixtures(props) {
    const {clubId, teamType} = useParams();
    const location = useLocation();
    const history = useHistory();

    const seasonId = location.state?.seasonId;
    const isOwner = parseInt(clubId) === props.manager.club.id;

    const teamLeagueSeasonsQuery = useGetTeamLS(
        clubId,
        teamType,
        {
            onSuccess: (data) => {
                if(location.state?.seasonId === undefined) {
                    history.replace(
                        {
                            pathname: location.pathname,
                            state: {...location.state, seasonId: data.data.response.TLS[0].LS.season.id},
                            search: location.search
                        }
                    );
                }
            },
            enabled: clubId !== undefined && teamType !== undefined
        }
    );

    const teamFixtureQuery = useGetTeamFixtures(clubId, teamType, seasonId, clubId !== undefined && teamType !== undefined && seasonId !== undefined);

    useEffect(() => {     
        if(teamLeagueSeasonsQuery.data?.data?.response && !!location.state?.fixtureStatus) {
            const navigation = [
                <ClubProfileToolbarItem clubId={clubId} key={1} />,
                <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                <TeamTacticsToolbarItem isMyClub={isOwner} teamType={teamType} key={3} />,
                <TeamTrainingToolbarItem isMyClub={isOwner} teamType={teamType} key={4} />,
                <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                <ClubStaffToolbarItem clubId={clubId} key={7} />,
                <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
            ];

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle[location.state?.fixtureStatus]} values={{teamName: <TeamName team={teamLeagueSeasonsQuery.data?.data?.response} />}} />,
                navigation
            });
        }
    }, [clubId, teamType, teamLeagueSeasonsQuery.data?.data?.response, location.state?.fixtureStatus]);

    return(
        <NarrowLayout>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Menu
                    icon
                    inverted
                    floated='left'
                    size='tiny'
                >
                    <Menu.Item
                        as={Link}
                        to={{pathname: generatePath(URL.teamFixtures, {clubId, teamType: 'first'})}}
                        active={matchPath(location.pathname, {path: generatePath(URL.teamFixtures, {clubId, teamType: 'first'})})}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faUser} />
                        </SUIIconShim>
                    </Menu.Item>
                    <Menu.Item
                        as={Link}
                        to={{pathname: generatePath(URL.teamFixtures, {clubId, teamType: 'youth'})}}
                        active={matchPath(location.pathname, {path: generatePath(URL.teamFixtures, {clubId, teamType: 'youth'})})}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faChild} />
                        </SUIIconShim>
                    </Menu.Item>
                </Menu>
                <Menu icon inverted floated='right' size='tiny'>
                    <Menu.Item
                        active={location.state?.fixtureStatus === 'complete'}
                        onClick={() => history.replace({pathname: location.pathname, state: {...location.state, fixtureStatus: 'complete'}})}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faCheck} />
                        </SUIIconShim>
                    </Menu.Item>
                    <Menu.Item
                        active={location.state?.fixtureStatus === 'planned'}
                        onClick={() => history.replace({pathname: location.pathname, state: {...location.state, fixtureStatus: 'planned'}})}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faHourglass} />
                        </SUIIconShim>
                    </Menu.Item>
                    <ChangeLeagueSeasonModal
                        isLoading={teamLeagueSeasonsQuery.isLoading}
                        teamLeagueSeasons={teamLeagueSeasonsQuery.data?.data?.response.TLS}
                    />
                </Menu>
            </div>
            <FixturesInterface
                isLoading={teamFixtureQuery.isLoading}
                fixtures={teamFixtureQuery.data?.data?.response}
                highlightTeamIds={[teamLeagueSeasonsQuery.data?.data?.response.id]}
            />
        </NarrowLayout>
    )
}

function ChangeLeagueSeasonModal(props) {
    const intl = useIntl();
    const history = useHistory();
    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [selectedSeasonId, setSelectedSeasonId] = useState(location.state?.seasonId)

    function handleConfirm() {
        history.replace({
            pathname: location.pathname,
            state: {...location.state, seasonId: selectedSeasonId}
        });

        setOpen(false);
    }

	return(
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Trigger />}
        >
            <Modal.Header>
                Select season
            </Modal.Header>
            <Modal.Content>
                <Dropdown
                    selection
                    options={props.teamLeagueSeasons ? props.teamLeagueSeasons.map((TLS) => (
                        {
                            key: TLS.LS.season.id,
                            text: intl.formatDate(TLS.LS.season.startDate, {day: 'numeric', month: 'short', year: 'numeric'}),
                            value: TLS.LS.season.id
                        }
                    )) : []}
                    value={selectedSeasonId}
                    loading={props.isLoading}
                    disabled={props.isLoading}
                    onChange={
                        (e, {value}) => setSelectedSeasonId(value)
                    }
                    label='Season'
                    fluid
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button positive onClick={handleConfirm}>Confirm</Button>
            </Modal.Actions>
        </Modal>
	);

    function Trigger(props) {
        return(
            <Menu.Item onClick={props.onClick}>
                <SUIIconShim>
                    <FontAwesomeIcon icon={faClock} />
                </SUIIconShim>
            </Menu.Item>
        );
    }
}

const messages = {
    screenTitle: {
        planned: {
            id: 'teamFixtures:screenTitle',
            defaultMessage: '{teamName} fixtures',
            description: 'Title for the screen showing a teams fixtures'
        },
        complete: {
            id: 'teamFixtures:screenTitle',
            defaultMessage: '{teamName} results',
            description: 'Title for the screen showing a teams fixtures'
        }
    }
}
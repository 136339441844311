const league = {
    id: "Generic.league",
    defaultMessage: "League",
    description: "Word used to describe the group of clubs that will compete against each other over 1 season"
}

const club = {
    id: "Generic.club",
    defaultMessage: "Club",
    description: "Word used to describe an organisation of players, staff and fans"
}

const confirm = {
    id: "Generic.confirm",
    defaultMessage: "Confirm",
    description: "Word used to confirm a positive decision is made by the user"
}

const gamename = {
    id: "Generic.gamename",
    defaultMessage: "Manager.Soccer",
    description: "The name of the game"
}

const personName = {
    id: "Generic.personName",
    defaultMessage: "Name",
    description: "Word for the name given to a person"
}

const tacticalPosition = {
    id: "Generic.tacticalPosition",
    defaultMessage: "Position",
    description: "Word used to indicate the preferred position of a player within a formation/tactic"
}

const tacticalPositionName = {
    1: {
        id: "Generic.tacticalPositionName.goalkeeper",
        defaultMessage: "GK",
        description: "Abbreviated word for the goalkeeper player position. 3 characters maximum"
    },
    2: {
        id: "Generic.tacticalPositionName.leftback",
        defaultMessage: "LB",
        description: "Abbreviated word for the left back defender position (4-4-2 formation). 3 characters maximum"
    },
    3: {
        id: "Generic.tacticalPositionName.centreback",
        defaultMessage: "CB",
        description: "Abbreviated word for the centre back defender position (4-4-2 formation). 3 characters maximum"
    },
    4: {
        id: "Generic.tacticalPositionName.rightback",
        defaultMessage: "RB",
        description: "Abbreviated word for the right back defender position (4-4-2 formation). 3 characters maximum"
    },
    5: {
        id: "Generic.tacticalPositionName.leftmidfield",
        defaultMessage: "LM",
        description: "Abbreviated word for the left midfield position (4-4-2 formation). 3 characters maximum"
    },
    6: {
        id: "Generic.tacticalPositionName.centremidfield",
        defaultMessage: "CM",
        description: "Abbreviated word for the centre midfield position (4-4-2 formation). 3 characters maximum"
    },
    7: {
        id: "Generic.tacticalPositionName.rightmidfield",
        defaultMessage: "RM",
        description: "Abbreviated word for the right midfield position (4-4-2 formation). 3 characters maximum"
    },
    8: {
        id: "Generic.tacticalPositionName.striker",
        defaultMessage: "ST",
        description: "Abbreviated word for the striker/forward position (4-4-2 formation). 3 characters maximum"
    },
    "sub": {
        id: "Generic.tacticalPositionName.substitute",
        defaultMessage: "Sub",
        description: "Abbreviated word for a player who is on the substitutes bench. 3 characters maximum"
    }
}

const playerRating = {
    id: "Generic.playerRating",
    defaultMessage: "Rating",
    description: "Word for the ability of a player on a scale of 1-100"
}

const age = {
    id: "Generic.age",
    defaultMessage: "Age",
    description: "Word used to describe how many years old a person is"
}

const nationality = {
    id: "Generic.nationality",
    defaultMessage: "Nation",
    description: "Word for the country of origin of a person"
}

const wage = {
    id: "Generic.wage",
    defaultMessage: "Wage",
    description: "Word for the regular payments made to an employee in return for their services"
}

const contractLength = {
    id: "Generic.contractLength",
    defaultMessage: "Expiry",
    description: "Word used to describe the point in time when a contract will finish"
}

const value = {
    id: "Generic.value",
    defaultMessage: "Value",
    description: "Word for the monetary value of a player"
}

const manager = {
    id: "Generic.manager",
    defaultMessage: "Manager",
    description: "Word for the manager (or head coach) of a club"
}

const positions = {
    GK: {
        id: "Component_PositionName.goalkeeper",
        defaultMessage: "GK",
        description: "Abbreviated word for the goalkeeper player position. 3 characters maximum"
    },
    LB: {
        id: "Component_PositionName.leftback",
        defaultMessage: "LB",
        description: "Abbreviated word for the left back defender position (4-4-2 formation). 3 characters maximum"
    },
    CB: {
        id: "Component_PositionName.centreback",
        defaultMessage: "CB",
        description: "Abbreviated word for the centre back defender position (4-4-2 formation). 3 characters maximum"
    },
    RB: {
        id: "Component_PositionName.rightback",
        defaultMessage: "RB",
        description: "Abbreviated word for the right back defender position (4-4-2 formation). 3 characters maximum"
    },
    LWB: {
        id: "positionname:leftwingback",
        defaultMessage: "LWB",
        description: "Abbreviated word for a left wing back position. 3 characters maximum"
    },
    RWB: {
        id: "positionname:rightwingback",
        defaultMessage: "RWB",
        description: "Abbreviated word for a right wing back position. 3 characters maximum"
    },
    CDM: {
        id: "positionname:centerdefensivemidfield",
        defaultMessage: "CDM",
        description: "Abbreviated word for a central defensive midfield position. 3 characters maximum"
    },
    LM: {
        id: "Component_PositionName.leftmidfield",
        defaultMessage: "LM",
        description: "Abbreviated word for the left midfield position (4-4-2 formation). 3 characters maximum"
    },
    CM: {
        id: "Component_PositionName.centremidfield",
        defaultMessage: "CM",
        description: "Abbreviated word for the centre midfield position (4-4-2 formation). 3 characters maximum"
    },
    RM: {
        id: "Component_PositionName.rightmidfield",
        defaultMessage: "RM",
        description: "Abbreviated word for the right midfield position (4-4-2 formation). 3 characters maximum"
    },
    LW: {
        id: "positionname:leftwing",
        defaultMessage: "LW",
        description: "Abbreviated word for a left winger position. 3 characters maximum"
    },
    RW: {
        id: "positionname:rightwinger",
        defaultMessage: "RW",
        description: "Abbreviated word for a right winger position. 3 characters maximum"
    },
    CAM: {
        id: "positionname:centerattackingmidfield",
        defaultMessage: "CAM",
        description: "Abbreviated word for a center attacking midfield position. 3 characters maximum"
    },
    ST: {
        id: "Component_PositionName.striker",
        defaultMessage: "ST",
        description: "Abbreviated word for the striker/forward position (4-4-2 formation). 3 characters maximum"
    },
    "sub": {
        id: "Component_PositionName.substitute",
        defaultMessage: "Sub",
        description: "Abbreviated word for a player who is on the substitutes bench. 3 characters maximum"
    }
}

const transferStatus = {
    id: "generic.transferStatus",
    defaultMessage: "Transfer status",
    description: "Phase used to describe the status of the player in relation to their club's transfer intention"
}

const transferListed = {
    id: "generic.transferListed",
    defaultMessage: "Transfer listed",
    description: "Phrase to describe a transfer status where the player is allowed to join another club permanently"
}

const loanListed = {
    id: "generic.loanListed",
    defaultMessage: "Loan listed",
    description: "Phrase to describe a transfer status where the player is allowed to join another club temporarily for a fixed period"
}

const notTransferListed = {
    id: "generic.transferListed",
    defaultMessage: "Not transfer listed",
    description: "Phrase to describe a transfer status where the player is not added to the transfer list"
}

const notLoanListed = {
    id: "generic.loanListed",
    defaultMessage: "Not loan listed",
    description: "Phrase to describe a transfer status where the player is not added to the loan list"
}

const filter = {
    id: "TransferSearch_Filter_interface.filterButtonText",
    defaultMessage: "Filter",
    description: "Text for the button which opens the transfer search filter"
}

const attributes = {
    id: "generic.attributes",
    defaultMessage: "Attributes",
    description: "Word to describe the individual qualities which determine how good a player is"
}

const personalDetails = {
    id: "generic.personalDetails",
    defaultMessage: "Personal details",
    description: "Phase to describe meta information about a person (name, age, nationality, etc)"
}

const playerAttributes = {
    tackling: {
        id: "Layout_PersonProfile.attributeTackling",
        defaultMessage: "Tackling",
        description: "Player tackling attribute"
    },
    marking: {
        id: "Layout_PersonProfile.attributeMarking",
        defaultMessage: "Marking",
        description: "Player marking attribute"
    },
    passing: {
        id: "Layout_PersonProfile.attributePassing",
        defaultMessage: "Passing",
        description: "Player passing attribute"
    },
    shooting: {
        id: "Layout_PersonProfile.attributeShooting",
        defaultMessage: "Shooting",
        description: "Player shooting (finishing) attribute"
    },
    dribbling: {
        id: "Layout_PersonProfile.attributeDribbling",
        defaultMessage: "Dribbling",
        description: "Player dribbling attribute"
    },
    crossing: {
        id: "Layout_PersonProfile.attributeCrossing",
        defaultMessage: "Crossing",
        description: "Player crossing attribute"
    },
    pace: {
        id: "Layout_PersonProfile.attributePace",
        defaultMessage: "Pace",
        description: "Player pace (speed) attribute"
    },
    heading: {
        id: "Layout_PersonProfile.attributeHeading",
        defaultMessage: "Heading",
        description: "Player heading attribute"
    },
    stamina: {
        id: "Layout_PersonProfile.attributeStamina",
        defaultMessage: "Stamina",
        description: "Player stamina/fitness attribute"
    },
    goalkeeper: {
        id: "Layout_PersonProfile.attributeGoalkeeping",
        defaultMessage: "Goalkeeping",
        description: "Player goalkeeper attribute"
    }
}

const staffAttributes = {
    coaching: {
        id: "Layout_PersonProfile.attributeCoaching",
        defaultMessage: "Coaching",
        description: "Staff coaching attribute"
    },
    scouting: {
        id: "Layout_PersonProfile.attributeScouting",
        defaultMessage: "Scouting",
        description: "Staff scouting attribute"
    },
    physiotherapy: {
        id: "Layout_PersonProfile.attributePhysiotherapy",
        defaultMessage: "Physiotherapy",
        description: "Staff physiotherapy rating (doctor/medical)"
    },
    youth: {
        id: "Layout_PersonProfile.attributeYouth",
        defaultMessage: "Youth",
        description: "Staff youth coaching rating"
    },
}

const placeOfBirth = {
    id: "Layout_PersonProfile.placeOfBirthLabel",
    defaultMessage: "Place of birth",
    description: "Label for the table cell which contains the persons place of birth (city)"
}

const condition = {
    id: "Layout_PersonProfile.conditionLabel",
    defaultMessage: "Fitness",
    description: "Word to describe the condition/fitness of a player"
}

const suspension = {
    id: "Layout_PersonProfile.suspensionLabel",
    defaultMessage: "Suspension",
    description: "Label for the field which shows the suspension information of the player"
}

const suspensionLength = {
    id: "Layout_PersonProfile.suspensionText",
    defaultMessage: "{length} games",
    description: "Text describing the player's suspension length"
}

const injury = {
    id: "Layout_PersonProfile.injuryLabel",
    defaultMessage: "Injury",
    description: "Label for the field which shows the injury status of the player"
}

const injuryLength = {
    id: "Layout_PersonProfile.injuryText",
    defaultMessage: "{length} days",
    description: "Text describing the player's injury length"
}

const contractStatus = {
    id: "Layout_PersonProfile.contractStatusLabel",
    defaultMessage: "Contract status",
    description: "Label for the field which contains the person's contract status (in contract/out of contract)",
}

const inContract = {
    id: "Layout_PersonProfile.inContract",
    defaultMessage: "In contract",
    description: "Words to describe when a player is under contract"
}

const outOfContract = {
    id: "Layout_PersonProfile.outOfContract",
    defaultMessage: "Out of contract",
    description: "Words to describe when a player is out of contract"
}

const wantedBy = {
    id: "Layout_PersonProfile.wantedLabel",
    defaultMessage: "Favourites",
    description: "Label for the row in the person profile which indicates how many managers have favourited the player"
}

const role = {
    id: "Layout_PersonProfile.rolesHeader",
    defaultMessage: "Role",
    description: "Word to describe the 'back room' occupancies of a staff member (e.g. his roles are manager, coach)"
}

const personal = {
    id: "Layout_PersonProfile.personalHeader",
    defaultMessage: "Personal information",
    description: "Header for the table which contains the person's personal information (age, nationality, etc)"
}

const season = {
    id: "Layout_PersonProfile.seasonSingularText",
    defaultMessage: "season",
    description: "Singular form of the word 'season'. Lowercase."
}

const seasons = {
    id: "Layout_PersonProfile.seasonPluralText",
    defaultMessage: "seasons",
    description: "Plural form of the word 'season'. Lowercase."
}

const transferBids = {
    id: "Layout_PersonProfile.bidsLabel",
    defaultMessage: "Transfer bids",
    description: "Label for the field which contains the names of clubs which have bid for this player/staff"
}

const loanLabel = {
    id: "Layout_PersonProfile.loanLabel",
    defaultMessage: "On loan from",
    description: "Label for the field which contains the name of the player's parent club, if they are on loan."
}

const careerEarnings = {
    id: "Layout_PersonProfile.careerEarningsLabel",
    defaultMessage: "Career earnings",
    description: "Label for the tile which contains the person's total career salary earnings on the person profile page"
}

const titlesWon = {
    id: "Layout_PersonProfile.titlesWonLabel",
    defaultMessage: "Titles won",
    description: "Label for the tile which contains the number of titles (league, cup) that the person has won"
}

const numberOfClubs = {
    id: "Layout_PersonProfile.numberOfClubsLabel",
    defaultMessage: "Number of clubs",
    description: "Label for the tile which contains the number of clubs that a person has played/coached for"
}

const freeAgent = {
    id: "Layout_PersonProfile.freeAgent",
    defaultMessage: "Free agent",
    description: "Text which is shown in the club field if the person is not employed by a club"
}

const noManager = {
    id: "Generic.noManager",
    defaultMessage: "No manager",
    description: "Phase to state that the club does not currently have a manager/coach"
}

const abbreviatedAppearances = {
    id: "Component_GoalStatistics.appearancesLabel",
    defaultMessage: "Apps",
    description: "Label for the column on the league goals scored statistics page which contains the number of appearances that the player has made. Use an abbreviated form where possible (4 characters max.)"
}

const abbreviatedGoals = {
    id: "Component_GoalStatistics.goalsLabel",
    defaultMessage: "Gls",
    description: "Label for the column in the league goals scored statistics page which contains the number of goals that the player has scored. Use an abbreviated form where possible (4 characters max.)"
}

export {
    league, club, confirm, gamename,
    personName, tacticalPosition,
    tacticalPositionName, playerRating,
    age, nationality, wage, contractLength,
    value, manager, positions, transferStatus,
    transferListed, loanListed, notTransferListed,
    notLoanListed, filter, attributes, personalDetails,
    playerAttributes, staffAttributes, placeOfBirth,
    condition, suspension, suspensionLength, injury,
    injuryLength, contractStatus, inContract, outOfContract,
    wantedBy, role, personal, season, seasons,
    transferBids, loanLabel, careerEarnings,
    titlesWon, numberOfClubs, freeAgent, noManager,
    abbreviatedAppearances, abbreviatedGoals
};
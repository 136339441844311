import { faShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router";
import DataTable from "../../../../common/components/DataTable";
import ClubLink from "../../../../club/components/common/Link";
import { useEffect, useMemo, useState } from "react";

import {playerPosition} from '../../../../common/dataTableColumns/player/playerPosition';
import {currentAbility} from '../../../../common/dataTableColumns/currentAbility';
import { playerName } from "../../../../common/dataTableColumns/player/playerName";
import { fetchRecords } from "./lib";
import WorldNationsToolbarItem from "../../toolbar/WorldNationsToolbarItem";
import WorldClubsToolbarItem from "../../toolbar/WorldClubsToolbarItem";
import WorldCupsToolbarItem from "../../toolbar/WorldCupsToolbarItem";
import WorldRecordsToolbarItem from "../../toolbar/WorldRecordsToolbarItem";
import MediumLayout from "../../../../common/components/MediumLayout";
import RecordFilter from "../filter";

export default function PlayerCurrentAbilityRecords(props) {
    const [timePeriod, setTimePeriod] = useState('allTime');
    const [nationGroupShortcode, setNationGroupShortcode] = useState(null);

    useEffect(() => {
        props.setToolbar({
            title: 'Player rating records',
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        });
    }, []);

    const currentAbilityRecordsQuery = useQuery(
        ['getPlayerGoalsScoredRecords', timePeriod, nationGroupShortcode],
        () => fetchRecords('currentAbility', timePeriod, nationGroupShortcode)
    );

    return(
        <MediumLayout>
            <RecordFilter
                validTimePeriodFilters={['allTime', 'anySeason', 'seasonId']}
                nationFilter={true}
                selectedTimePeriod={timePeriod}
                handleTimePeriodChange={setTimePeriod}
                selectedNationGroupShortcode={nationGroupShortcode}
                handleNationGroupShortcodeChange={setNationGroupShortcode}
            />
            {timePeriod === 'allTime' ?
                <AllTimeCurrentAbilityRecordsInterface
                    isLoading={currentAbilityRecordsQuery.isLoading}
                    records={currentAbilityRecordsQuery.data?.data?.response}
                />
                :
                <SeasonCurrentAbilityRecordsInterface
                    isLoading={currentAbilityRecordsQuery.isLoading}
                    records={currentAbilityRecordsQuery.data?.data?.response}
                    timePeriod={timePeriod}
                />
            }
        </MediumLayout>
    );
}

function AllTimeCurrentAbilityRecordsInterface(props) {
    const columns = [
        playerName(
            (player) => player,
            (player) => false,
            (player) => false,
            undefined,
            undefined,
            undefined,
            undefined
        ),
        playerPosition((player) => player.position),
        {
            name: <FontAwesomeIcon icon={faShield} />,
            cell: (player) => `${player.teamCount} clubs`,
            compact: true,
            grow: 1,
            minWidth: '70px',
            maxWidth: '100px',
            center: true,
            hide: 325
        },
        currentAbility((player) => player.maxCurrentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    )
}

function SeasonCurrentAbilityRecordsInterface(props) {
    const columns = [
        playerName(
            (player) => player,
            (player) => false,
            (player) => false,
            undefined,
            undefined,
            undefined,
            undefined
        ),
        playerPosition((player) => player.position),
        {
            name: <FontAwesomeIcon icon={faShield} />,
            cell: (player) => <ClubLink club={player.club} />,
            compact: true,
            grow: 1,
            minWidth: '80px',
            maxWidth: '150px',
            hide: 350
        },
        currentAbility((player) => player.maxCurrentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    )
}
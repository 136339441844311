import { Form } from "semantic-ui-react";
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import LeagueName from "../../league/components/common/Name";

export default function SelectLeague(props) {	
    const nationLeaguesQuery = useQuery(
        ['getNationLeagues', props.nationId, props.leagueLevel, props.leagueType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nations/${props.nationId}/leagues?level=${props.leagueLevel}&type=${props.leagueType}`),
        {
            enabled: props.nationId !== undefined && props.leagueLevel !== undefined && props.leagueType !== undefined
        }
    );

	return(
        <Form.Select
            options={nationLeaguesQuery.data?.data?.response.map((league) => (
                {
                    key: league.id,
                    text: (<LeagueName league={league} />),
                    value: league.id
                }
            ))}
            value={props.selectedLeagueId}
            loading={nationLeaguesQuery.isLoading}
            disabled={nationLeaguesQuery.isLoading}
            onChange={
                (e, {value}) => props.setSelectedLeagueId(value)
            }
            label='League'
            fluid
        />
	);
}

import { useQuery } from '@tanstack/react-query';
import StaffTransferAuctionFreeHistoryInterface from "./interface";
import { useParams } from "react-router";
import axios from "axios";
import { useMemo } from "react";

export default function StaffTransferAuctionFreeHistory(props) {
    const {staffTransferAuctionFreeHistoryId} = useParams();

    const staffTransferAuctionFreeHistoryQuery = useQuery(
        ['getStaffTransferAuctionFreeHistory', staffTransferAuctionFreeHistoryId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/staffTransferAuctionFreeHistory/${staffTransferAuctionFreeHistoryId}`)
    );

    const myBid = useMemo(() => staffTransferAuctionFreeHistoryQuery.data?.data?.response.staffTransferAuctionBidFreeHistory.find((bid) => bid.clubId === props.manager.club.id),[staffTransferAuctionFreeHistoryQuery.data?.data?.response]);
    const winningBid = useMemo(() => staffTransferAuctionFreeHistoryQuery.data?.data?.response.staffTransferAuctionBidFreeHistory.find((bid) => bid.isWinner === true),[staffTransferAuctionFreeHistoryQuery.data?.data?.response]);

    return(
        <StaffTransferAuctionFreeHistoryInterface
            isLoading={staffTransferAuctionFreeHistoryQuery.isLoading}
            staffTransferAuctionFreeHistory={staffTransferAuctionFreeHistoryQuery.data?.data?.response}
            myBid={myBid}
            winningBid={winningBid}
        />
    );
}
import { useEffect } from "react";
import CupSeasonHistoryInterface from "./interface";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router";
import { FormattedMessage } from "react-intl";
import CupName from "../name/CupName";
import CupFixturesToolbarItem from "../toolbar/Fixtures";
import CupStatsToolbarItem from "../toolbar/Stats";
import CupHistoryToolbarItem from "../toolbar/History";

export default function CupSeasonHistory(props) {
    const {cupId} = useParams();
    
    const cupSeasonsQuery = useQuery(
        ['getCupSeasons', cupId, 'includeTCS=true&filterChampions=true'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cups/${cupId}/cupSeasons?includeTCS=true&filterChampions=true`)
    );
    
    useEffect(() => {
        if(!!cupSeasonsQuery.data?.data?.response) {
            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{cupName: <CupName cup={cupSeasonsQuery.data.data.response} />}} />,
                navigation: [
                    <CupFixturesToolbarItem key={1} cupId={cupId} />,
                    <CupStatsToolbarItem key={2} cupId={cupId} />,
                    <CupHistoryToolbarItem key={3} cupId={cupId} />
                ]
            });
        }
    }, [cupSeasonsQuery.data?.data?.response]);
    
    return(
        <CupSeasonHistoryInterface
            isLoading={cupSeasonsQuery.isLoading}
            cupSeasons={cupSeasonsQuery.data?.data.response.CS}
        />
    )
}

const messages = {
    screenTitle: {
        id: 'cup:history:screenTitle',
        defaultMessage: '{cupName} history',
        description: 'Screen title for cup history page'
    }
}
import { faCakeCandles } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function personAge(
    ageResolver,
    hide
) {
    return {
        id: 'personAge',
        name: <FontAwesomeIcon icon={faCakeCandles} />,
        selector: ageResolver,
        sortable: true,
        wrap: false,
        center: true,
        allowOverflow: false,
        compact: true,
        grow: 1,
        minWidth: '30px',
        maxWidth: '80px',
        hide
    }
}

export {
    personAge
}
import { Graphics, Point } from "pixi.js";
import { ApplicationException, locations, sizes } from "./lib";

class Ball {
    constructor(
        sim
    ) {
        this.sim = sim;

        this.reset();

        this.graphics = _createGraphics(this.sim.app);
    }

    reset() {
        this.location = new Point(0, 0);
        this.movement = null;
        this.currentPlayer = null;
        this.previousPlayer = null;
    }

    log(m) {
        if(this.sim.logConfig.ball === true) {
            console.log(`ball: ${m}`);
        }
    }

    verbose(m) {
        if(
            this.sim.log.ball === true &&
            this.sim.log.verbose === true
        ) {
            console.log(`ball: ${m}`);
        }
    }

    isValidMovement(movement) {
        return (
            movement.toLocation.x >= 0 &&
            movement.toLocation.x <= sizes.pitch.x &&
            movement.toLocation.y >= 0 &&
            movement.toLocation.y <= sizes.pitch.y
        );
    }

    handleMovementComplete() {
        this.log('movement completed');

        this.cancelMovement();
    }

    setMovement(movement) {
        if(this.movement !== null) {
            throw new ApplicationException(`ball movement already in progress ${this.movement.type}`);
        }

        movement.callback = this.handleMovementComplete.bind(this)

        this.movement = movement;
        
        this.log(`new ball movement:`);
        // console.log(this.movement);
    }

    replaceMovement(newMovement) {
        if(this.isValidMovement(newMovement) === false) {
            throw new ApplicationException('invalid movement');
        };

        if(this.movement === null) {
            this.setMovement(newMovement);
        } else if(this.movement.isComplete === true) {
            this.cancelMovement();
            this.setMovement(newMovement);
        } else {
            if(this.movement.toLocation.equals(newMovement.toLocation)) {
                this.verbose('new movement equals existing movement');
            } else {
                this.cancelMovement();
                this.setMovement(newMovement);
            }
        }
    }

    cancelMovement() {
        this.log(`cancelling existing movement: ${this.movement?.type}`);
        this.movement = null;
    }

    //could this be improved by using dot?
    isGoalScored() {
        return (
            (
                this.location.x >= locations.rightGoal.x ||
                this.location.x <= locations.leftGoal.x
            )
            &&
            (
                this.location.y >= (sizes.pitch.y/2)*0.9 &&
                this.location.y <= (sizes.pitch.y/2)*1.1
            )
        );
    }

    tick(time) {
        if(this.isGoalScored()) {
            console.log('Goal scored!!!');
            // this.sim.app.ticker.stop();
            return this.sim.handleGoal();
        }

        if(this.currentPlayer !== null) {
            this.location = this.currentPlayer.player.location.clone();
        } else {
            if(this.movement !== null) {
                this.location = this.movement.update(time.deltaMS);
            }
        }
    }
}

function _createGraphics(
    app
) {
    const graphics = {};

    graphics.ball = new Graphics()
        .circle(0, 0, sizes.ballRadius)
        .fill('#FFF')
        .stroke('#000');

    app.stage.addChild(graphics.ball);

    return graphics;
}

export default Ball;
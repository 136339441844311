export default function sortByLineup(formation, playerAccessor, playerReg1, playerReg2) {
    if(!!playerReg1.teamTacticPosition && !playerReg2.teamTacticPosition) {
        return -1;
    } else if(!playerReg1.teamTacticPosition && !!playerReg2.teamTacticPosition) {
        return 1;
    } else if(!playerReg1.teamTacticPosition && !playerReg2.teamTacticPosition) {
        if(playerReg1.playerContract.player.currentAbility > playerReg2.playerContract.player.currentAbility) {
            return -1;
        } else if(playerReg1.playerContract.player.currentAbility < playerReg2.playerContract.player.currentAbility) {
            return 1;
        } else {
            return 0;
        }
    } else if(!!playerReg1.teamTacticPosition && !!playerReg2.teamTacticPosition) {
        const player1Pos = namedPositions[playerReg1.teamTacticPosition.pitchSection] || "SUB";
        const player2Pos = namedPositions[playerReg2.teamTacticPosition.pitchSection] || "SUB";

        const sortIndex = ['GK', 'LB', 'CB', 'RB', 'LWB', 'CDM', 'RWB', 'LM', 'CM', 'RM', 'LW', 'CAM', 'RW', 'ST', 'SUB'];

        const player1SortIndex = sortIndex.indexOf(player1Pos);
        const player2SortIndex = sortIndex.indexOf(player2Pos);

        if(player1SortIndex < player2SortIndex) return -1;
        if(player1SortIndex > player2SortIndex) return 1;
        if(player1SortIndex === player2SortIndex) {
            if(playerReg1.teamTacticPosition.pitchSection < playerReg2.teamTacticPosition.pitchSection) return -1;
            if(playerReg1.teamTacticPosition.pitchSection > playerReg2.teamTacticPosition.pitchSection) return 1;
            return 0;
        }
    } else {
        return 0;
    }
}

const namedPositions = {
    1: 'LB',
    7: 'CB',
    12: 'GK',
    13: 'CB',
    19: 'CB',
    25: 'RB',
    2: 'LWB',
    8: 'CDM',
    14: 'CDM',
    20: 'CDM',
    26: 'RWB',
    3: 'LM',
    9: 'CM',
    15: 'CM',
    21: 'CM',
    27: 'RM',
    4: 'LW',
    10: 'CAM',
    16: 'CAM',
    22: 'CAM',
    28: 'RW',
    11: 'ST',
    17: 'ST',
    23: 'ST'
}
import axios from "axios";
import { useEffect } from "react";
import { useQuery } from '@tanstack/react-query';
import { useParams } from "react-router";
import { Loader } from "semantic-ui-react";
import { CreateStaffTransferAuctionFreeInterface, ViewStaffTransferAuctionFreeInterface } from "./interface";

import Profile from '../toolbar/Profile';
import TransferOffer from '../toolbar/TransferOffer';
import History from '../toolbar/History';

export default function StaffTransferAuctionFree(props) {
    const {staffTransferAuctionFreeId, staffId} = useParams();

    const getStaffTransferAuctionFreeQuery = useQuery(
        ['getStaffTransferAuctionFree', staffTransferAuctionFreeId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/staffTransferAuctionFree/${staffTransferAuctionFreeId}`),
        {
            enabled: staffTransferAuctionFreeId !== undefined
        }
    );

    const getStaffQuery = useQuery(
        ['getStaff', staffId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/staff/${staffId}`)
    );

    useEffect(() => {
        props.setToolbar({
            title: 'Transfer offer',
            navigation: [
                <Profile key={1} staffId={staffId} />,
                <TransferOffer
                    key={4}
                    staffId={staffId}
                    isMyStaff={false}
                />,
                <History key={3} staffId={staffId} />,
            ]
        })
    }, [getStaffQuery.data]);

    if(!staffTransferAuctionFreeId) {
        return(
            <CreateStaffTransferAuctionFreeInterface
                staffTransferAuctionFree={null}
                staff={getStaffQuery.data?.data?.response}
            />
        )
    } else {
        if(getStaffTransferAuctionFreeQuery.isLoading) {
            return(
                <Loader active />
            )
        } else {
            if(!!getStaffTransferAuctionFreeQuery.data?.data?.response.staffTransferAuctionBidFree?.id) {
                return(
                    <ViewStaffTransferAuctionFreeInterface
                        staffTransferAuctionFree={getStaffTransferAuctionFreeQuery.data?.data.response}
                    />
                )
            } else {
                return(
                    <CreateStaffTransferAuctionFreeInterface
                        staffTransferAuctionFree={getStaffTransferAuctionFreeQuery.data?.data.response}
                    />
                )
            }
        }
    }
}
import { Button } from "semantic-ui-react";
import ClubListView from "../../club/components/lists/ClubListView";
import { filterStateReducer, initialFilterState, isFilterActive } from "../../club/filter/lib";
import ClubFilterButton from "../../club/filter/triggers/button";
import useGetClubs from "../../club/useGetClubs";
import WideLayout from "../../common/components/WideLayout";
import usePersistedState from "../../common/lib/usePersistedState";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import FullNationNameWithFlag from '../../nation/common/FullNationNameWithFlag';
import NationOverview from '../toolbar/overview';
import NationLeagues from '../toolbar/leagues';
import NationClubsT from '../toolbar/clubs';
import NationPlayers from '../toolbar/players'
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useEffect } from "react";

const PERSISTED_STATE_KEY = 'nationClubsFilter';

export default function NationClubs(props) {
    const {nationId} = useParams();
    const {persistedState, updatePersistedState} = usePersistedState(PERSISTED_STATE_KEY, initialFilterState);

    const nationQuery = useQuery(
        ['getNation', nationId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nations/${nationId}`),
        {
            enabled: nationId !== undefined
        }
    );

    const nationClubsQuery = useGetClubs(persistedState, false, true, nationId);

    useEffect(() => {
        if(!nationQuery.isLoading && nationQuery.data?.data?.response) {
            props.setToolbar({
                title: <><FullNationNameWithFlag nation={nationQuery.data?.data?.response} /> clubs</>,
                navigation: [
                    <NationOverview key={1} nationId={nationId} />,
                    <NationLeagues key={2} nationId={nationId} />,
                    <NationClubsT key={3} nationId={nationId} />,
                    <NationPlayers key={4} nationId={nationId} />
                ]
            });
        }
    }, [nationId, nationQuery.data?.data?.response]);

    return(
        <WideLayout>
            <ClubFilterButton
                loading={nationClubsQuery.isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey={PERSISTED_STATE_KEY}
            />
            <ClubListView
                isLoading={nationClubsQuery.isLoading}
                clubs={nationClubsQuery.data?.data.response}
            />
            <Button.Group>
                <Button
                    icon
                    disabled={persistedState.page === 1}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </SUIIconShim>
                </Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.page}
                </Button>
                <Button
                    icon
                    disabled={nationClubsQuery.data?.data?.response.length < 10}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </SUIIconShim>
                </Button>
            </Button.Group>
        </WideLayout>
    );
}
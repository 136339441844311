import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { generatePath, useHistory } from "react-router";
import DataTable from "../../../../common/components/DataTable";
import TitledSegment from "../../../../common/components/TitledSegment";
import WideLayout from "../../../../common/components/WideLayout";
import { playerName } from "../../../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../../../common/dataTableColumns/player/playerPosition";
import { transferAuctionDuration } from "../../../../common/dataTableColumns/transferAuctionDuration";
import { URL } from "../../../../common/lib/paths";
import { auctionStatus } from "../../../../common/dataTableColumns/auctionStatus";
import { currentAbility } from "../../../../common/dataTableColumns/currentAbility";

export default function ClubPlayerTransferAuctionFreeInterface(props) {
    return(
        <WideLayout>
            <TitledSegment
                title='Offers made'
                icon={faArrowUp}
                noPadding={true}
            >
                <ProposedPlayerTransferAuctionFreeTable
                    isLoading={props.isLoading}
                    playerTransferAuctionFree={props.playerTransferAuctionFree}
                    manager={props.manager}
                />
            </TitledSegment>
        </WideLayout>
    );
}

function ProposedPlayerTransferAuctionFreeTable(props) {
    const history = useHistory();

    const columns = [
        auctionStatus((playerTransferAuctionFree) => playerTransferAuctionFree.isHistorical ? (playerTransferAuctionFree.winningBid.clubId === props.manager.club.id ? 'won' : 'lost') : 'inProgress'),
        playerName(
            (playerTransferAuctionFree) => playerTransferAuctionFree.player,
            () => null,
            () => null
        ),
        transferAuctionDuration((playerTransferAuctionFree) => playerTransferAuctionFree.interval),
        playerPosition((playerTransferAuctionFree) => playerTransferAuctionFree.player.position),
        currentAbility((playerTransferAuctionFree) => playerTransferAuctionFree.player.currentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.playerTransferAuctionFree}
            onRowClicked={(row) => row.isHistorical ? history.push(generatePath(URL.playerTransferAuctionFreeHistory, {playerTransferAuctionFreeHistoryId: row.id})) : history.push(generatePath(URL.playerTransferAuctionFree, {playerId: row.player.id, playerFreeAuctionId: row.id}))}
        />
    );
}
import { Link, generatePath } from "react-router-dom";
import { Header, List, Segment } from "semantic-ui-react";
import NarrowLayout from "../../../common/components/NarrowLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightDots, faCoins, faExchange, faFutbol, faMitten, faPeopleArrows, faPercent, faShirt, faStar, faTrophy } from "@fortawesome/free-solid-svg-icons";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { useEffect } from "react";
import WorldNationsToolbarItem from "../toolbar/WorldNationsToolbarItem";
import WorldClubsToolbarItem from "../toolbar/WorldClubsToolbarItem";
import WorldCupsToolbarItem from "../toolbar/WorldCupsToolbarItem";
import WorldRecordsToolbarItem from "../toolbar/WorldRecordsToolbarItem";
import { URL } from "../../../common/lib/paths";

export default function WorldRecordsMenu(props) {
    useEffect(() => {
        props.setToolbar({
            title: 'World records',
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        })
    }, []);

    return (
        <NarrowLayout>
            <Segment>
                <Header as='h3'>Player records</Header>
                <List relaxed>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faFutbol} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.playerGoalsScoredRecords)}>Most goals scored</Link>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <SUIIconShim>
                        <FontAwesomeIcon icon={faPeopleArrows} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.playerAssistRecords)}>Most assists</Link>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faArrowUpRightDots} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.playerGoalContributionRecords)}>Most goal contributions (goals + assists)</Link>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faMitten} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.playerCleanSheetRecords)}>Most clean sheets</Link>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faShirt} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.playerAppearanceRecords)}>Most appearances</Link>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faExchange} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.playerTransferFeeRecords)}>Largest transfer fee</Link>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faStar} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.playerCurrentAbilityRecords)}>Highest rating</Link>
                        </List.Content>
                    </List.Item>
                </List>
            </Segment>
            <Segment>
                <Header as='h3'>Club records</Header>
                <List>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faPercent} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.clubWinRateRecords)}>Win %</Link>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faStar} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.clubRatingRecords)}>Highest rating</Link>
                        </List.Content>
                    </List.Item>
                    <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faCoins} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={generatePath(URL.clubBalanceRecords)}>Balance</Link>
                        </List.Content>
                    </List.Item>
                    {/* <List.Item>
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faTrophy} fixedWidth={true} />
                        </SUIIconShim>
                        <List.Content>
                            <Link to={'https://test.com'}>A league titles</Link>
                        </List.Content>
                    </List.Item> */}
                </List>
            </Segment>
        </NarrowLayout>
    )
}
import { Grid } from "semantic-ui-react";
import NarrowLayout from "../../common/components/NarrowLayout";
import {TrainingBoost, BonusBoost} from "./interface";

export default function TrainingBoosts(props) {
    if(props.isLoading) {
        return null;
    }

    return(
        <Grid.Row columns='equal' style={{padding: 0, maxWidth: '750px', margin: '0 auto'}}>
            {props.trainingBoosts.map((trainingBoost) => 
                <Grid.Column style={{padding: '0 0.5em 0 0'}}>
                    <NarrowLayout>
                        <TrainingBoost
                            key={trainingBoost.id}
                            trainingBoost={trainingBoost}
                            playerRegistrations={props.playerRegistrations}
                            teamId={props.teamId}
                            formation={props.formation}
                        />
                    </NarrowLayout>
                </Grid.Column>
            )}
            {props.pointsRequiredForBonusBoost !== null &&
                <Grid.Column style={{padding: '0 0 0 0.5em'}}>
                    <NarrowLayout>
                        <BonusBoost
                            pointsRequired={props.pointsRequiredForBonusBoost}
                        />
                    </NarrowLayout>
                </Grid.Column>
            }
        </Grid.Row>
    );
}
export default function Flag(props) {
    let shortcode = props.name;

    if(shortcode === 'e1') shortcode = 'gb-eng';
    if(shortcode === 's1') shortcode = 'gb-sct';
    if(shortcode === 'w1') shortcode = 'gb-wls';

    return(
        <span className={`fi fi-${shortcode} ${props.className} inlineIcon ${props.responsiveClass || 'nationFlag-tablet'}`}></span>
    )
}
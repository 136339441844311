import {Dropdown, Menu} from "semantic-ui-react";

import PlayersSelectionView from "../../../player/lists/PlayerListSelectionView.js";
import PlayersContractView from "../../../player/lists/PlayerListContractView.js";
import PlayersStatsView from "../../../player/lists/PlayerListStatsView.js";

import WideLayout from "../../../common/components/WideLayout.js";
import usePersistedState from "../../../common/lib/usePersistedState.js";
import NarrowLayout from "../../../common/components/NarrowLayout.js";
import { faChild, faDownLeftAndUpRightToCenter, faEye, faFilter, faUser } from "@fortawesome/free-solid-svg-icons";
import useCompare from "../../../player/compare/useCompare.js";
import SUIIconShim from "../../../common/components/SUIIconShim.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, generatePath, matchPath, useLocation, useParams } from "react-router-dom";
import { URL } from "../../../common/lib/paths.js";
import FilterMenuButton from "../../../player/filter/button/menu.js";
import { initialFilterState, isFilterActive } from "../../../player/filter/lib.js";
import TeamMetaAccordion from "./MetaAccordion.js";
import { useTutorial } from "../../../tutorial/hooks/useTutorial.js";

export default function TeamSquad(props) {
    const {persistedState: persistedView, updatePersistedState: updatedPersistedView} = usePersistedState('squadView', 'selection');
    const {persistedState: persistedFilter} = usePersistedState('squadFilter', initialFilterState);
    const {addPlayerIds} = useCompare();
    const location = useLocation();
    const {clubId} = useParams();
    const {goNextStep, checkTutorial, getSearchParamsString, tutorialKey, tutorialStep} = useTutorial();

    const isTutorial1 = checkTutorial('players', 1);
    const isTutorial2 = checkTutorial('players', 2);
    const isTutorial3 = checkTutorial('players', 13);

    function handleViewClick(view) {
        if(isTutorial2 && view === 'contract') {
            goNextStep();
        }

        updatedPersistedView(view)
    }

    return(
        <div>
            <NarrowLayout>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <Menu
                        icon
                        inverted
                        floated='left'
                        size='tiny'
                    >
                        <Menu.Item
                            as={Link}
                            to={{pathname: generatePath(URL.teamPlayers, {clubId, teamType: 'first'})}}
                            active={matchPath(location.pathname, {path: generatePath(URL.teamPlayers, {clubId, teamType: 'first'})})}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faUser} />
                            </SUIIconShim>
                        </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to={{pathname: generatePath(URL.teamPlayers, {clubId, teamType: 'youth'}), search: isTutorial3 ? getSearchParamsString(tutorialKey, tutorialStep+1) : undefined}}
                            active={matchPath(location.pathname, {path: generatePath(URL.teamPlayers, {clubId, teamType: 'youth'})})}
                            className={isTutorial3 && 'glow'}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faChild} />
                            </SUIIconShim>
                        </Menu.Item>
                    </Menu>
                    <Menu
                        icon
                        inverted
                        floated='right'
                        size='tiny'
                    >
                        <Menu.Item
                            onClick={() => addPlayerIds(props.data?.playerReg.map((playerReg) => playerReg.playerContract.player.id))}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} transform={{rotate: 45}} />
                            </SUIIconShim>
                        </Menu.Item>
                        <FilterMenuButton
                            isFilterActive={isFilterActive(persistedFilter)}
                            persistedStateKey='squadFilter'
                        />
                        <Dropdown
                            item
                            icon={<SUIIconShim><FontAwesomeIcon icon={faEye} /></SUIIconShim>}
                            direction='left'
                            floating
                            className={isTutorial1 && 'glow'}
                            open={isTutorial2 || undefined}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    active={persistedView === 'selection'}
                                    onClick={() => handleViewClick('selection')}
                                >Selection</Dropdown.Item>
                                <Dropdown.Item
                                    active={persistedView === 'contract'}
                                    onClick={() => handleViewClick('contract')}
                                    className={isTutorial2 && 'glow'}
                                >Contract</Dropdown.Item>
                                <Dropdown.Item
                                    active={persistedView === 'stats'}
                                    onClick={() => handleViewClick('stats')}
                                >Stats</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu>
                </div>
            </NarrowLayout>
            <WideLayout>
                {persistedView === 'selection' &&
                    <PlayersSelectionView
                        isLoading={props.isLoading}
                        playerRegistrations={props.data?.playerReg}
                        teamId={props.data?.id}
                        isOwner={props.isOwner}
                        ownerClubFormation={props.ownerClubFormation}
                    />
                }
                {persistedView === 'contract' &&
                    <PlayersContractView
                        isLoading={props.isLoading}
                        playerRegistrations={props.data?.playerReg}
                        teamId={props.data?.id}
                        isOwner={props.isOwner}
                        ownerClubFormation={props.ownerClubFormation}
                    />
                }
                {persistedView === 'stats' &&
                    <PlayersStatsView
                        isLoading={props.isLoading}
                        playerRegistrations={props.data?.playerReg}
                        teamId={props.data?.id}
                        isOwner={props.isOwner}
                        ownerClubFormation={props.ownerClubFormation}
                    />
                }
            </WideLayout>
            <NarrowLayout>
                <TeamMetaAccordion />
            </NarrowLayout>
        </div>
    );
}
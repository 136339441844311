import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useIntl } from "react-intl";
import { Form, Segment } from "semantic-ui-react";
import NationGroupNameWithFlag from '../../../nationGroup/common/NationGroupNameWithFlag';
import NarrowLayout from "../../../common/components/NarrowLayout";

export default function RecordFilter(props) {
    const intl = useIntl();

    const getSeasonsQuery = useQuery(
        ['getAllSeasons', props.validTimePeriodFilters],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/seasons?excludeFutureSeasons=true`),
        {
            enabled: props.validTimePeriodFilters.includes('seasonId')
        }
    );

    const nationsQuery = useQuery(
        ['getNationGroups'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nationGroups`),
        {
            enabled: props.nationFilter === true
        }
    );

    const timePeriodOptions = [];

    if(props.validTimePeriodFilters.includes('allTime')) {
        timePeriodOptions.push({
            key: 'allTime',
            value: 'allTime',
            text: 'All time'
        });
    }

    if(props.validTimePeriodFilters.includes('anySeason')) {
        timePeriodOptions.push({
            key: 'anySeason',
            value: 'anySeason',
            text: 'Any season'
        });
    }

    if(props.validTimePeriodFilters.includes('seasonId') && !!getSeasonsQuery.data?.data.response) {
        timePeriodOptions.push(
            ...getSeasonsQuery.data?.data.response.map((season) => ({
                key: season.id,
                text: intl.formatDate(season.startDate, {day: 'numeric', month: 'short', year: '2-digit'}),
                value: season.id
            }))
        );
    }

    const competitionOptions = [];

    if(props.validCompetitionFilters?.includes('allCompetitions')) {
        competitionOptions.push({
            key: 'allCompetitions',
            value: 'allCompetitions',
            text: 'All competitions'
        });
    }

    if(props.validCompetitionFilters?.includes('league')) {
        competitionOptions.push({
            key: 'league',
            value: 'league',
            text: 'League'
        });
    }

    if(props.validCompetitionFilters?.includes('mugOfNations')) {
        competitionOptions.push({
            key: 'mugOfNations',
            value: 'mugOfNations',
            text: 'Mug of Nations'
        });
    }

    return (
        <NarrowLayout>
            <Segment inverted style={{margin: '1em 0'}}>
                <Form inverted>
                    <Form.Group widths={2} unstackable={true} style={{marginBottom: '1em'}}>
                        <Form.Field>
                            <Form.Select
                                className='dropdownNoMaxHeight dropdownNoMinWidth'
                                options={timePeriodOptions}
                                isLoading={getSeasonsQuery.isLoading}
                                value={props.selectedTimePeriod}
                                onChange={(e, {value}) => props.handleTimePeriodChange(value)}
                                label='Period'
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Select
                                className='dropdownNoMaxHeight dropdownNoMinWidth'
                                options={nationsQuery.data?.data.response.map((n) => ({
                                    key: n.shortcode,
                                    value: n.shortcode,
                                    text: (<NationGroupNameWithFlag shortcode={n.shortcode} />)
                                })) || []}
                                clearable
                                selectOnBlur={false}
                                isLoading={nationsQuery.isLoading}
                                value={props.nationGroupShortcode}
                                onChange={(e, {value}) => props.handleNationGroupShortcodeChange(value)}
                                label='Nation'
                                placeholder="All nations"
                            />
                        </Form.Field>
                    </Form.Group>
                    {!!props.validCompetitionFilters ?
                        <Form.Group widths={2} unstackable={true} style={{marginBottom: 0}}>
                            <Form.Field>
                                <Form.Select
                                    className='dropdownNoMaxHeight dropdownNoMinWidth'
                                    options={competitionOptions}
                                    value={props.selectedCompetition}
                                    onChange={(e, {value}) => props.handleCompetitionChange(value)}
                                    label='Competition'
                                />
                            </Form.Field>
                            <Form.Field>
                            </Form.Field>
                        </Form.Group>
                        :
                        null
                    }
                </Form>
            </Segment>
        </NarrowLayout>
    )
}
import {Segment} from 'semantic-ui-react';
import {useIntl} from 'react-intl';

import FullNationNameWithFlag from '../../../nation/common/FullNationNameWithFlag';
import DataTable from '../../../common/components/DataTable';
import {useTutorial} from '../../../tutorial/hooks/useTutorial';

import {currentAbility} from '../../../common/dataTableColumns/currentAbility';
import { Link, generatePath } from 'react-router-dom';
import { URL } from '../../../common/lib/paths';

export default function ListInterface(props) {
    const intl = useIntl();
    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('world', 0)

    const columns = [
        {
            name: 'Nation',
            selector: (nation, i) => <Link to={{pathname: generatePath(URL.nation, {nationId: nation.id})}}><FullNationNameWithFlag nation={nation} className={isTutorial1 && i === 0 && 'glow'} search={isTutorial1 && i === 0 && getSearchParamsString('world', 1)} /></Link>,
            wrap: false,
            allowOverflow: true,
            compact: true,
            grow: 4,
            minWidth: '150px'
        },
        {
            name: 'Added',
            cell: nation => intl.formatDate(nation.createdAt, {month: 'short', year: '2-digit'}),
            wrap: false,
            allowOverflow: false,
            compact: true,
            center: true,
            grow: 1,
            hide: 374,
            minWidth: '80px',
            maxWidth: '200px'
        },
        currentAbility(
            (nation) => nation.reputationAbs,
            undefined
        )
    ]

    return(
        <div className='t_nationList'>
            <Segment>Select a nation from the list to view information about it's leagues, clubs and players.</Segment>
            <DataTable
                columns={columns}
                data={props.nations}
                isLoading={props.isLoading}
                placeholderCount={5}
            />
        </div>
    )
}
import { createContext, useContext, useState } from "react";
import Confetti from 'react-confetti';

const ConfettiContext = createContext();

export function ConfettiProvider({children}) {
    const [showConfetti, setShowConfetti] = useState(false);

    return(
        <ConfettiContext.Provider
            value={{showConfetti, setShowConfetti}}
        >
            <>
                {showConfetti === true &&
                    <Confetti
                        recycle={false}
                        onConfettiComplete={handleConfettiComplete}
                        numberOfPieces={300}
                        gravity={0.2}
                    />
                }
                {children}
            </>
        </ConfettiContext.Provider>
    );
    
    function handleConfettiComplete() {
        setShowConfetti(false);
    }
}

export function useConfetti() {
    const {showConfetti, setShowConfetti} = useContext(ConfettiContext);

    return {
        showConfetti,
        setShowConfetti
    }
}
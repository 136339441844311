import {defineMessages} from 'react-intl';

import {URL} from '../../../common/lib/paths';
import ToolbarItem from '../../../menu/toolbar/ToolbarItem';

import {useTutorial} from '../../../tutorial/hooks/useTutorial';
import { faMedal, faTrophy } from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
    text: {
        id: 'world:toolbar:records',
        defaultMessage: 'Records',
        description: 'Link in the toolbar in the world section for the world records page'
    }
})

export default function WorldRecordsToolbarItem(props) {
    const {checkTutorial, getSearchParamsString} = useTutorial();
    const isTutorial1 = checkTutorial('world', 5);

    return(
        <ToolbarItem
            path={{
                pathname: URL.worldRecords,
                search: isTutorial1 && getSearchParamsString('world', 6)
            }}
            icon={faMedal}
            messages={messages}
            className={isTutorial1 && 'glow'}
            genericPath={URL.worldRecords}
        />
    )
}
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import ClubPlayerTransferAuctionPaidInterface from "./interface";
import usePersistedState from "../../../../common/lib/usePersistedState";

export default function ClubPlayerTransferAuctionPaid(props) {
    const {persistedState} = usePersistedState('myTransferOffers', {view: 'paidAuctions', activeOnly: false});

    const getClubPlayerTransferAuctionPaidQuery = useQuery(
        [
            'getClubPlayerTransferAuctionPaid',
            persistedState.activeOnly
        ],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/playerTransferAuctionPaid?activeOnly=${persistedState.activeOnly}`)
    );

    return(
        <ClubPlayerTransferAuctionPaidInterface
            isLoading={getClubPlayerTransferAuctionPaidQuery.isLoading}
            proposedPlayerTransferAuctionPaid={getClubPlayerTransferAuctionPaidQuery.data?.data?.response.proposedPlayerTransferAuctionPaid}
            receivedPlayerTransferAuctionPaid={getClubPlayerTransferAuctionPaidQuery.data?.data?.response.receivedPlayerTransferAuctionPaid}
            manager={props.manager}
        />
    )
}
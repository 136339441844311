import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { generatePath, Redirect, useLocation, useParams } from "react-router";
import { Loader } from "semantic-ui-react";
import { URL } from "../../common/lib/paths";

export default function StaffTransferRouter(props) {
    const {staffId} = useParams();
    const location = useLocation();

    const getStaffAuction = useQuery(
        ['getStaffAuction', staffId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/staff/${staffId}/staffTransferAuctionFree`)
    );

    if(getStaffAuction.isLoading) {
        return(<Loader active />);
    } else {
        if(!!getStaffAuction.data.data.response) {
            return(
                <Redirect
                    to={{
                        pathname: generatePath(URL.staffTransferAuctionFree, {staffId: staffId, staffTransferAuctionFreeId: getStaffAuction.data.data.response.id}),
                        search: location.search
                    }}
                />
            )
        } else {
            return(
                <Redirect
                    to={{
                        pathname: generatePath(URL.staffTransferAuctionFree, {staffId: staffId}),
                        search: location.search
                    }}
                />
            )
        }
    }
}
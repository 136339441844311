import axios from "axios";
import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import ClubProfileToolbarItem from "../../../toolbar/ClubProfile";
import TeamPlayersToolbarItem from "../../../toolbar/TeamPlayers";
import TeamFixturesToolbarItem from "../../../toolbar/TeamFixtures";
import ClubStaffToolbarItem from "../../../toolbar/ClubStaff";
import ClubTransfersToolbarItem from "../../../toolbar/ClubTransfers";
import TeamLeagueSeasonsToolbarItem from "../../../toolbar/TeamLeagueSeasons";
import PTLSHistoryInterface from "./interface";
import TeamTacticsToolbarItem from "../../../toolbar/TeamTactics";
import TeamLeagueToolbarItem from "../../../toolbar/TeamLeague";
import TeamTrainingToolbarItem from "../../../toolbar/TeamTraining";
import { FormattedMessage } from "react-intl";
import TeamName from "../../../../../team/common/TeamName";

export default function PTLSHistory(props) {
    const {clubId, teamType} = useParams();

    const getTeamQuery = useQuery(
        ['getTeam', clubId, teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/${teamType}`)
    );

    const PTLSHistoryQuery = useQuery(
        ['getTeamPTLSHistory', clubId, teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/${teamType}/PTLS`)
    );

    useEffect(() => {
        if(getTeamQuery.data?.data?.response) {
            const isOwner = !!props.manager.club && (parseInt(clubId) === props.manager.club.id);

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{teamName: <TeamName team={getTeamQuery.data.data.response} />}} />,
                navigation: [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType={teamType} key={2} />,
                    <TeamTacticsToolbarItem isMyClub={isOwner} teamType={teamType} key={3} />,
                    <TeamTrainingToolbarItem isMyClub={isOwner} teamType={teamType} key={4} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType={teamType} key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType={teamType} key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType={teamType} key={9} />
                ]
            });
        }
    }, [clubId, teamType, getTeamQuery.data?.data?.response]);

    return(
        <PTLSHistoryInterface
            isLoading={PTLSHistoryQuery.isLoading}
            PTLS={PTLSHistoryQuery.data?.data?.response}
        />
    )
}

const messages = {
    screenTitle: {
        id: 'todo',
        defaultMessage: '{teamName} records',
        description: 'todo'
    }
}
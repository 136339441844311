import { useParams } from "react-router";
import usePersistedState from "../../common/lib/usePersistedState";
import useCompare from "../../player/compare/useCompare";
import { filterStateReducer, initialFilterState, isFilterActive } from "../../player/filter/lib";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useGetPlayers from "../../transfer/playerSearch/useGetPlayers";
import { useEffect } from "react";
import FullNationNameWithFlag from "../common/FullNationNameWithFlag";
import NationOverview from '../toolbar/overview';
import NationLeagues from '../toolbar/leagues';
import NationClubs from '../toolbar/clubs';
import NationPlayersT from '../toolbar/players'
import WideLayout from "../../common/components/WideLayout";
import FilterButton from "../../player/filter/button/interface";
import { Button } from "semantic-ui-react";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faDownLeftAndUpRightToCenter } from "@fortawesome/free-solid-svg-icons";
import PlayerListTransferView from "../../player/lists/PlayerListTransferView";

const PERSISTED_STATE_KEY = 'nationPlayersFilter';

export default function NationPlayers(props) {
    const {persistedState, updatePersistedState} = usePersistedState(PERSISTED_STATE_KEY, initialFilterState);
    const {addPlayerIds} = useCompare();
    const {nationId} = useParams();

    const nationQuery = useQuery(
        ['getNation', nationId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/nations/${nationId}`),
        {
            enabled: nationId !== undefined
        }
    );

    const nationPlayersQuery = useGetPlayers(persistedState, false, true, nationId);

    useEffect(() => {
        if(!nationQuery.isLoading && nationQuery.data?.data?.response) {
            props.setToolbar({
                title: <><FullNationNameWithFlag nation={nationQuery.data?.data?.response} /> players</>,
                navigation: [
                    <NationOverview key={1} nationId={nationId} />,
                    <NationLeagues key={2} nationId={nationId} />,
                    <NationClubs key={3} nationId={nationId} />,
                    <NationPlayersT key={4} nationId={nationId} />
                ]
            });
        }
    }, [nationId, nationQuery.data?.data?.response]);

    return(
        <WideLayout>
            <FilterButton
                loading={nationPlayersQuery.isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey={PERSISTED_STATE_KEY}
            />
            <Button
                disabled={nationPlayersQuery.isLoading}
                color='black'
                style={{backgroundColor: 'rgba(15, 16, 17)'}}
                floated='right'
                icon
                onClick={() => addPlayerIds(nationPlayersQuery.data.data.response.map((player) => player.id))}
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} transform={{rotate: 45}} />
                </SUIIconShim>
            </Button>
            <PlayerListTransferView
                isLoading={nationPlayersQuery.isLoading}
                players={nationPlayersQuery.data?.data?.response}
            />
            <Button.Group>
                <Button
                    icon
                    disabled={persistedState.page === 1}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </SUIIconShim>
                </Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.page}
                </Button>
                <Button
                    icon
                    disabled={nationPlayersQuery.data?.data?.response.length < 10}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </SUIIconShim>
                </Button>
            </Button.Group>
        </WideLayout>
    );
}
import { useQuery } from "@tanstack/react-query";
import ClubFinancesInterface from "./interface";
import axios from "axios";
import NarrowLayout from "../../../common/components/NarrowLayout";
import { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import ClubName from "../common/Name";

export default function ClubFinances(props) {
    const clubFinancesQuery = useQuery(
        ['getClubFinances'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/finances`)
    );

    useEffect(() => {
        if(!!clubFinancesQuery.data?.data?.response) {
            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{clubName: <ClubName club={clubFinancesQuery.data?.data?.response} />}} />,
                navigation: []
            });
        }
    }, [clubFinancesQuery.data?.data?.response]);

    return(
        <NarrowLayout>
            <ClubFinancesInterface
                isLoading={clubFinancesQuery.isLoading}
                club={clubFinancesQuery.data?.data?.response}
            />
        </NarrowLayout>
    );
}

const messages = {
    screenTitle: {
        id: 'todo',
        defaultMessage: '{clubName} finances',
        descripion: 'todo'
    }
}
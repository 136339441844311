import { faStreetView } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const positionSortOrder = [
    'GK',
    'CB',
    'LB',
    'RB',
    'LWB',
    'RWB',
    'CDM',
    'CM',
    'LM',
    'RM',
    'CAM',
    'LW',
    'RW',
    'ST'
]

const positionBgColors = {
    'GK': '#5E37C1',
    'CB': '#51B2AC',
    'LB': '#51B2AC',
    'RB': '#51B2AC',
    'LWB': '#51B2AC',
    'RWB': '#51B2AC',
    'CDM': '#42B3CA',
    'CM': '#42B3CA',
    'LM': '#42B3CA',
    'RM': '#42B3CA',
    'CAM': '#4283CA',
    'LW': '#4283CA',
    'RW': '#4283CA',
    'ST': '#4283CA'
}

function positionSorter(a, b) {
    const aIndex = positionSortOrder.indexOf(a.playerContract.player.position);
    const bIndex = positionSortOrder.indexOf(b.playerContract.player.position);

    if(aIndex > bIndex) return 1;
    if(aIndex < bIndex) return -1;
    return 0;
}

function playerPosition(
    positionResolver,
    hide
) {
    return {
        id: 'playerPosition',
        name: <FontAwesomeIcon icon={faStreetView} />,
        selector: positionResolver,
        sortable: true,
        sortFunction: positionSorter,
        wrap: false,
        allowOverflow: false,
        center: true,
        compact: true,
        width: '38px',
        hide,
        grow: 1,
        conditionalCellStyles: [
            {
                when: (rowData) => !!positionResolver(rowData),
                style: (rowData) => ({
                    backgroundColor: positionBgColors[positionResolver(rowData)],
                    fontWeight: '700',
                    color: '#FFF',
                    fontSize: '12px'
                })
            }
        ]
    }
}

export {
    playerPosition
}
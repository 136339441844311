import {useIntl} from 'react-intl';
import { Header, Segment, Table, Icon, Statistic, Form, Placeholder, Loader, Dimmer } from "semantic-ui-react";

import PlayerLink from '../../player/common/Link';
import ClubLink from '../../club/components/common/Link';
import Currency from '../../common/components/Currency';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightArrowLeft, faCoins, faListOl } from '@fortawesome/free-solid-svg-icons';

export default function TransferHistoryInterface(props) {
    const intl = useIntl();

    return(
        <>
            <Segment inverted>
                {!props.seasonQueryLoading ?
                    <Form inverted>
                        <Form.Field>
                            <label>Season</label>
                            <Form.Select
                                options={props.seasonData.map((season) => ({
                                    key: season.id,
                                    text: intl.formatDate(season.startDate, {day: 'numeric', month: 'short', year: '2-digit'}),
                                    value: season.id
                                }))}
                                value={props.selectedSeasonId}
                            />
                        </Form.Field>
                    </Form>
                    :
                    <Placeholder inverted>
                        <Placeholder.Header>
                            <Placeholder.Line length='short' />
                            <Placeholder.Line length='long' />
                        </Placeholder.Header>
                    </Placeholder>
                }
            </Segment>
            <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)'}}>
                Total spend
                <FontAwesomeIcon icon={faCoins} style={{float: 'right'}} />
            </Header>
            <Segment attached style={{overflow: 'hidden'}} className='attachedInvertedSegment noPaddingFix'>
                {props.transferStatsQueryLoading ?
                    <div style={{padding: '1em'}}><Loader active style={{position: 'relative', top: 'unset', left: 'unset', margin: '0 auto', transform: 'none',}} /></div>
                    :
                    <Statistic size='small' style={{width: '100%', 'padding': '1em'}}>
                        <Statistic.Value><Currency value={props.transferStatsData.totalSpend} /></Statistic.Value>
                    </Statistic>
                }
            </Segment>
            <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)'}}>
                Top 5 transfers
                <FontAwesomeIcon icon={faArrowRightArrowLeft} style={{float: 'right'}} />
            </Header>
            <Segment attached className='attachedInvertedSegment noPaddingFix'>
                <Table
                    striped
                    compact
                    unstackable
                    style={{border: 'none'}}
                >
                    <Table.Body>
                        {props.transferStatsQueryLoading ?
                            [1,2,3,4,5].map((i) => <Table.Row key={i}><Table.Cell><Placeholder><Placeholder.Header><Placeholder.Line length='full' /></Placeholder.Header></Placeholder></Table.Cell></Table.Row>)
                            :
                            props.transferStatsData.top5TransferOffersByValue.length > 0 ?
                                props.transferStatsData.top5TransferOffersByValue.map((transferOffer, index) => 
                                    <Table.Row key={index}>
                                        <Table.Cell style={cellStyle}>
                                            <PlayerLink player={transferOffer.player} />
                                        </Table.Cell>
                                        <Table.Cell style={cellStyle}>
                                            {transferOffer.ToClub ?
                                                <ClubLink club={transferOffer.ToClub} />
                                                :
                                                'Released'
                                            }
                                        </Table.Cell>
                                        <Table.Cell collapsing textAlign='right'>
                                            <Currency value={transferOffer.compensation || 0} />
                                        </Table.Cell>
                                    </Table.Row>
                                )
                                :
                                <Table.Row><Table.Cell>No transfers found</Table.Cell></Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Segment>
            <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)'}}>
                Top 5 spending clubs
                <FontAwesomeIcon icon={faListOl} style={{float: 'right'}} />
            </Header>
            <Segment attached className='attachedInvertedSegment noPaddingFix'>
                <Table
                    striped
                    compact
                    unstackable
                    style={{border: 'none'}}
                >
                    <Table.Body>
                        {props.transferStatsQueryLoading ?
                            [1,2,3,4,5].map((i) => <Table.Row key={i}><Table.Cell><Placeholder><Placeholder.Header><Placeholder.Line length='full' /></Placeholder.Header></Placeholder></Table.Cell></Table.Row>)
                            :
                            props.transferStatsData.topTransfersByClub.length > 0 ?
                                props.transferStatsData.topTransfersByClub.map((transferOffer) => 
                                    <Table.Row>
                                        <Table.Cell>
                                            <ClubLink club={transferOffer.ToClub} />
                                        </Table.Cell>
                                        <Table.Cell collapsing textAlign='right'>
                                            <Currency value={transferOffer.totalSpend} />
                                        </Table.Cell>
                                    </Table.Row>
                                )
                                :
                                <Table.Row><Table.Cell>No transfers found</Table.Cell></Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Segment>
            <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)'}}>
                Top 5 active clubs
                <FontAwesomeIcon icon={faListOl} style={{float: 'right'}} />
            </Header>
            <Segment attached className='attachedInvertedSegment noPaddingFix'>
                <Table
                    striped
                    compact
                    unstackable
                    style={{border: 'none'}}
                >
                    <Table.Body>
                        {props.transferStatsQueryLoading ?
                            [1,2,3,4,5].map((i) => <Table.Row key={i}><Table.Cell><Placeholder><Placeholder.Header><Placeholder.Line length='full' /></Placeholder.Header></Placeholder></Table.Cell></Table.Row>)
                            :
                            props.transferStatsData.mostActiveClubs.map((club) => 
                            <Table.Row>
                                <Table.Cell>
                                    <ClubLink club={club} />
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {club.totalTransfers} transfers
                                </Table.Cell>
                            </Table.Row>
                            )
                        }
                    </Table.Body>
                </Table>
            </Segment>
        </>
    );
}

const cellStyle = {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 0
}
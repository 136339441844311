import { Button, Grid, List, Menu, Modal } from "semantic-ui-react";
import SUIIconShim from "../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import colours from '../../common/colours';
import { useEffect, useReducer, useState } from "react";
import useDimensions from "react-cool-dimensions";
import {DragOverlay, useDraggable, useDroppable} from '@dnd-kit/core';
import NarrowLayout from '../../common/components/NarrowLayout'
import {DndContext} from '@dnd-kit/core';
import {formations as formationsData} from 'shared-messages';

import pitch from '../pitch.png';
import shirtGK from '../../common/components/PlayerShirt/shirt2gk.png';
import shirtOutfield from '../../common/components/PlayerShirt/shirt2.png';
import { URL } from "../../common/lib/paths";
import { generatePath, useHistory } from "react-router";
import useBodyClass from "../../useBodyClass";

export default function TacticsBuilderInterface(props) {
    const { observe, height } = useDimensions();
    const [tactic, dispatchTactic] = useReducer(tacticReducer, null);
    const [activeId, setActiveId] = useState(null);
    const history = useHistory();

    useEffect(() => {
        if(!tactic?.selectedSections && !tactic?.tacticPositionIdMap && !!props.selectedSections && !!props.tacticPositionIds) {
            dispatchTactic({type: 'init', selectedSections: props.selectedSections, tacticPositionIdMap: props.tacticPositionIds})
        }
    }, [props.selectedSections, props.tacticPositionIds]);

    if(props.isLoading || !tactic) return null;

    return(
        <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} autoScroll={false}>
            <NarrowLayout>
                <div style={{marginBottom: '1em'}}>
                    <PresetFormations
                        dispatchTactic={dispatchTactic}
                    />
                    {/*TODO - mentality*/}
                    <div
                        style={{display: 'inline', float: 'right'}}
                    >
                        <Button
                            onClick={() => history.push(generatePath(URL.teamTactics, {teamType: props.teamTactic.team.type}), {manageTacticsModal: true})}
                        >
                            Cancel
                        </Button>
                        <Button
                            positive
                            style={{margin: 0}}
                            loading={props.updateTeamTacticMutationIsLoading}
                            onClick={() => props.updateTeamTacticMutation.mutate(tactic)}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <div
                    style={styles.container}
                >
                    <img src={pitch} style={styles.pitch} ref={observe} />
                    <Grid
                        style={{...styles.grid, height}}
                    >
                        {positions.map((row, rowIndex) => (
                            <Grid.Row
                                key={rowIndex}    
                                columns={5}
                                style={styles.row}
                            >
                                {row.map((pitchSection, positionIndex) => (
                                    <PitchPosition
                                        key={`${rowIndex}_${positionIndex}`}
                                        pitchSection={pitchSection}
                                        tactic={tactic}
                                        activeId={activeId}
                                    />
                                ))}
                            </Grid.Row>
                        ))}
                    </Grid>
                </div>
                <DragOverlay>
                    {activeId ?
                        <PositionShirt />
                        :
                        null
                    }
                </DragOverlay>
            </NarrowLayout>
        </DndContext>
    );

    function handleDragStart(event) {
        setActiveId(event.active.id); //tacticPositionId
    }

    function handleDragEnd(event) {
        setActiveId(null);

        if(!!event.over) {
            //check target is not already occupied
            if(tactic.selectedSections.includes(event.over.id)) {
                return;
            }

            //check target is not disabled
            if(event.over.disabled) {
                return;
            }

            dispatchTactic({type: 'changeSelection', fromTacticPositionId: event.active.id, toPitchSection: event.over.id});
        }
    }
}

function PresetFormations(props) {
    const [open, setOpen] = useState(false);
    const [selectedFormation, setSelectedFormation] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(4);

    return(
        <Modal
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            trigger={<Button color="black">Presets</Button>}
        >
            <Modal.Header>Preset formations</Modal.Header>
            <Modal.Content>
                <Menu compact>
                    <Menu.Item
                        name='3 def.'
                        active={selectedCategory === 3}
                        onClick={() => setSelectedCategory(3)}
                    />
                    <Menu.Item
                        name='4 def.'
                        active={selectedCategory === 4}
                        onClick={() => setSelectedCategory(4)}
                    />
                    <Menu.Item
                        name='5 def.'
                        active={selectedCategory === 5}
                        onClick={() => setSelectedCategory(5)}
                    />
                </Menu>
                <List
                    divided
                    relaxed
                    selection
                >
                    {formations[selectedCategory].map((formation) => (
                        <List.Item
                            key={formation.key}
                            onClick={() => setSelectedFormation(formation.key)}
                            active={selectedFormation === formation.key}
                        >
                            <List.Content>
                                <List.Header>
                                    {formation.name}
                                    {selectedFormation === formation.key ?
                                        <SUIIconShim>
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                color={colours.positive}
                                                className='inlineIconMirror'
                                            />
                                        </SUIIconShim>
                                        :
                                        null
                                    }
                                </List.Header>
                                <List.Description>
                                    <p>{formation.description}</p>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    ))}
                </List>
            </Modal.Content>
            <Modal.Actions>
                <Button
                    onClick={() => setOpen(false)}
                >
                    Cancel
                </Button>
                <Button
                    positive
                    onClick={handleConfirmFormation}
                >
                    Confirm
                </Button>
            </Modal.Actions>
        </Modal>
    )

    function handleConfirmFormation() {
        props.dispatchTactic({type: 'setFormation', formationSections: formationsData[selectedFormation].pitchHalfMap});
        setOpen(false);
    }
}

function PitchPosition(props) {
    /* this component is a droppable */
    const isOccupied = props.tactic.selectedSections.includes(props.pitchSection);

    //Would move leave origin flank empty?
    if(!!props.activeId && !!props.pitchSection) {
        const activeFlank = getFlank(props.tactic.selectedSections[props.tactic.tacticPositionIdMap.indexOf(props.activeId)]);
        const thisFlank = getFlank(props.pitchSection);
    
        if(activeFlank !== thisFlank) {
            const activeFlankPos = flankPos[activeFlank].intersection(new Set(props.tactic.selectedSections));
    
            var minFlankPos = activeFlankPos.size > 1;

            if(thisFlank !== 1) {
                const thisFlankPos = flankPos[thisFlank].intersection(new Set(props.tactic.selectedSections));

                var maxFlankPos = thisFlankPos.size < 2;
            } else {
                var maxFlankPos = true;
            }
        } else {
            var minFlankPos = true;
        }
    } else {
        var minFlankPos = true;
    }

    const disabled = props.pitchSection === null || isOccupied === true || minFlankPos === false || maxFlankPos === false;

    //The ID of a droppable is the pitch section ID
    const {isOver, setNodeRef} = useDroppable({
        id: props.pitchSection,
        disabled
    });

    if(!!props.activeId && !disabled) {
        if(isOver === true) {
            var style = {...styles.droppable, ...styles.droppableActive, ...styles.droppableHover}
        } else {
            var style = {...styles.droppable, ...styles.droppableActive}
        }
    } else {
        var style = styles.droppable;
    }

    return(
        <Grid.Column
            width={3}
            style={styles.column}
        >
            <div
                style={style}
                ref={setNodeRef}
            >
                {isOccupied ?
                    <OccupiedPosition
                        pitchSection={props.pitchSection}
                        tactic={props.tactic}
                    />
                    :
                    null
                }
            </div>
        </Grid.Column>
    )
}

function OccupiedPosition(props) {
    const index = props.tactic.selectedSections.indexOf(props.pitchSection);

    //The ID of a draggable is teamTacticPosition.id
    const {listeners, attributes, setNodeRef, transform} = useDraggable({
        id: props.tactic.tacticPositionIdMap[index],
        disabled: props.pitchSection === 12
    });
    
    const style = transform ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      } : undefined;

    return(
        <div style={{...styles.shirtContainer, ...style}} ref={setNodeRef} {...listeners} {...attributes}>
            <img src={props.pitchSection === 12 ? shirtGK : shirtOutfield} style={styles.shirt} />
            <span style={styles.positionLabel}>{namedPositions[props.pitchSection]}</span>
        </div>
    )
}

function PositionShirt(props) {
    return(
        <div style={styles.shirtContainer}>
            <img src={shirtOutfield} style={styles.shirt} />
        </div>
    )
}

function getFlank(pitchSectionId) {
    if(pitchSectionId <= 5) return 0;
    if(pitchSectionId > 5 && pitchSectionId < 24) return 1;
    if(pitchSectionId >= 24) return 2;

    throw new Error(`${pitchSectionId}`);
}

const flankPos = {
    0: new Set([1, 2, 3, 4]),
    1: new Set([7,8,9,10,11,13,14,15,16,17,19,20,21,22,23]),
    2: new Set([25,26,27,28])
}

const positions = [
    [null, 11, 17, 23, null],
    [4, 10, 16, 22, 28],
    [3, 9, 15, 21, 27],
    [2, 8, 14, 20, 26],
    [1, 7, 13, 19, 25],
    [null, null, 12, null, null]
];

function normalisePitchmap(pitchmap) {
    if(
        pitchmap.includes(11) &&
        !pitchmap.includes(17) &&
        !pitchmap.includes(23)
    ) {
        /*
            ST - - => - ST -
        */

        pitchmap[pitchmap.indexOf(11)] = 17;
    }

    if(
        !pitchmap.includes(11) &&
        !pitchmap.includes(17) &&
        pitchmap.includes(23)
    ) {
        /*
            - - ST => - ST -
        */

        pitchmap[pitchmap.indexOf(23)] = 17;
    }

    if(
        !pitchmap.includes(11) &&
        pitchmap.includes(17) &&
        pitchmap.includes(23)
    ) {
        /*
            - ST ST => ST - ST
        */

        pitchmap[pitchmap.indexOf(17)] = 11;
    }

    if(
        pitchmap.includes(11) &&
        pitchmap.includes(17) &&
        !pitchmap.includes(23)
    ) {
        /*
            ST ST - => ST - ST
        */

        pitchmap[pitchmap.indexOf(17)] = 23;
    }

    //fix CAM
    if(
        pitchmap.includes(10) &&
        !pitchmap.includes(16) &&
        !pitchmap.includes(22)
    ) {
        /*
            ST - - => - ST -
        */

        pitchmap[pitchmap.indexOf(10)] = 16;
    }

    if(
        !pitchmap.includes(10) &&
        !pitchmap.includes(16) &&
        pitchmap.includes(22)
    ) {
        /*
            - - ST => - ST -
        */

        pitchmap[pitchmap.indexOf(22)] = 16;
    }

    if(
        !pitchmap.includes(10) &&
        pitchmap.includes(16) &&
        pitchmap.includes(22)
    ) {
        /*
            - ST ST => ST - ST
        */

        pitchmap[pitchmap.indexOf(16)] = 10;
    }

    if(
        pitchmap.includes(10) &&
        pitchmap.includes(16) &&
        !pitchmap.includes(22)
    ) {
        /*
            ST ST - => ST - ST
        */

        pitchmap[pitchmap.indexOf(16)] = 22;
    }

    //fix CM
    if(
        pitchmap.includes(9) &&
        !pitchmap.includes(15) &&
        !pitchmap.includes(21)
    ) {
        /*
            ST - - => - ST -
        */

        pitchmap[pitchmap.indexOf(9)] = 15;
    }

    if(
        !pitchmap.includes(9) &&
        !pitchmap.includes(15) &&
        pitchmap.includes(21)
    ) {
        /*
            - - ST => - ST -
        */

        pitchmap[pitchmap.indexOf(21)] = 15;
    }

    if(
        !pitchmap.includes(9) &&
        pitchmap.includes(15) &&
        pitchmap.includes(21)
    ) {
        /*
            - ST ST => ST - ST
        */

        pitchmap[pitchmap.indexOf(15)] = 9;
    }

    if(
        pitchmap.includes(9) &&
        pitchmap.includes(15) &&
        !pitchmap.includes(21)
    ) {
        /*
            ST ST - => ST - ST
        */

        pitchmap[pitchmap.indexOf(15)] = 21;
    }


    //fix CDM
    if(
        pitchmap.includes(8) &&
        !pitchmap.includes(14) &&
        !pitchmap.includes(20)
    ) {
        /*
            ST - - => - ST -
        */

        pitchmap[pitchmap.indexOf(8)] = 14;
    }

    if(
        !pitchmap.includes(8) &&
        !pitchmap.includes(14) &&
        pitchmap.includes(20)
    ) {
        /*
            - - ST => - ST -
        */

        pitchmap[pitchmap.indexOf(20)] = 14;
    }

    if(
        !pitchmap.includes(8) &&
        pitchmap.includes(14) &&
        pitchmap.includes(20)
    ) {
        /*
            - ST ST => ST - ST
        */

        pitchmap[pitchmap.indexOf(14)] = 8;
    }

    if(
        pitchmap.includes(8) &&
        pitchmap.includes(14) &&
        !pitchmap.includes(20)
    ) {
        /*
            ST ST - => ST - ST
        */

        pitchmap[pitchmap.indexOf(14)] = 20;
    }

    //fix CB
    if(
        pitchmap.includes(7) &&
        !pitchmap.includes(13) &&
        !pitchmap.includes(19)
    ) {
        /*
            ST - - => - ST -
        */

        pitchmap[pitchmap.indexOf(7)] = 13;
    }

    if(
        !pitchmap.includes(7) &&
        !pitchmap.includes(13) &&
        pitchmap.includes(19)
    ) {
        /*
            - - ST => - ST -
        */

        pitchmap[pitchmap.indexOf(19)] = 13;
    }

    if(
        !pitchmap.includes(7) &&
        pitchmap.includes(13) &&
        pitchmap.includes(19)
    ) {
        /*
            - ST ST => ST - ST
        */

        pitchmap[pitchmap.indexOf(13)] = 7;
    }

    if(
        pitchmap.includes(7) &&
        pitchmap.includes(13) &&
        !pitchmap.includes(19)
    ) {
        /*
            ST ST - => ST - ST
        */

        pitchmap[pitchmap.indexOf(13)] = 19;
    }

    return pitchmap;
}

const namedPositions = {
    1: 'LB',
    7: 'CB',
    12: 'GK',
    13: 'CB',
    19: 'CB',
    25: 'RB',
    2: 'LWB',
    8: 'CDM',
    14: 'CDM',
    20: 'CDM',
    26: 'RWB',
    3: 'LM',
    9: 'CM',
    15: 'CM',
    21: 'CM',
    27: 'RM',
    4: 'LW',
    10: 'CAM',
    16: 'CAM',
    22: 'CAM',
    28: 'RW',
    11: 'ST',
    17: 'ST',
    23: 'ST'
}

const formations = {
    3: [
        {
            key: '352',
            name: '352',
            description: 'A narrower formation with wing-backs'
        },
        {
            key: '343_W',
            name: '343 wide',
            description: 'An expansive attacking formation'
        },
        {
            key: '343_N',
            name: '343 narrow',
            description: 'An attacking formation with 3 strikers'
        },
        {
            key: '3412',
            name: '3412',
            description: 'An attacking formation with wing-backs'
        }
    ],
    4: [
        {
            key: '442',
            name: '442',
            description: 'A well balanced, no-nonsense classic'
        },
        {
            key: '4231',
            name: '4231',
            description: 'An attacking formation with wingers and attacking midfielders'
        },
        {
            key: '451',
            name: '451',
            description: 'A balanced formation used to dominate the midfield'
        },
        {
            key: '433',
            name: '433',
            description: 'A narrow formation with 3 strikers'
        },
        {
            key: '4123',
            name: '4123',
            description: 'A wide formation with a holding midfielder'
        },
        {
            key: '4312',
            name: '4312',
            description: 'A narrow attacking formation'
        },
        {
            key: '4212',
            name: '4321',
            description: 'A balanced attacking formation with 2 attacking midfielders'
        },
        {
            key: '41212',
            name: '41212',
            description: 'A very narrow formation to explot the middle of the pitch'
        }
    ],
    5: [
        {
            key: '541',
            name: '541',
            description: 'A highly defensive formation used to park the bus'
        },
    ]
}

const styles = {
    container: {
        position: 'relative',
        overflow: 'hidden'
    },
    pitch: {
        width: '100%',
        position: 'absolute'
    },
    grid: {
        overflow: 'hidden',
        width: '100%',
        margin: 0,
        textAlign: 'center'
    },
    row: {
        padding: '5px 0 5px 0',
        justifyContent: 'space-between',
        height: '16.66%'
    },
    column: {
        position: 'relative',
        padding: '0 0.5em',
        cursor: 'pointer',
        textAlign: 'center'
    },
    shirt: {
        width: '100%',
        opacity: 1
    },
    shirtContainer: {
        width: '100%',
        touchAction: 'none'
    },
    droppable: {
        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%'
    },
    droppableActive: {
        border: '2px solid #192d4d',
        backgroundColor: 'rgba(25, 45, 77, 0.2)'
    },
    droppableHover: {
        backgroundColor: 'rgba(25, 45, 77, 0.6)'
    },
    positionLabel: {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translateX(-50%)',
        fontWeight: 'bold',
        fontSize: '0.7rem',
        color: 'rgba(0,0,0,.87)'
    },
}

function tacticReducer(tactic, action) {
    let newState;

    switch(action.type) {
        case 'init':
            newState = {
                ...tactic,
                selectedSections: action.selectedSections,
                tacticPositionIdMap: action.tacticPositionIdMap
            }
            break;
        case 'changeSelection':
            const index = tactic.tacticPositionIdMap.indexOf(action.fromTacticPositionId);

            newState = {
                ...tactic,
                selectedSections: normalisePitchmap(tactic.selectedSections.map((s, i) => i === index ? action.toPitchSection : s))
            }
            break;
        case 'setFormation':
            newState = {
                ...tactic,
                selectedSections: action.formationSections
            }
            break;
    }

    return newState;
}

                {/* <Dropdown
                    item
                    direction='left'
                    loading={props.getTacticsQueryIsLoading}
                    disabled={props.getTacticsQueryIsLoading || props.delegateTactics}
                    floating
                    value={props.mentality}
                    text='Mentality'
                    onChange={props.handleUpdateMentality}
                    options={[
                        {
                            key: 'attacking',
                            text: 'Attacking',
                            value: 'attacking'
                        },
                        {
                            key: 'standard',
                            text: 'Standard',
                            value: 'standard'
                        },
                        {
                            key: 'defensive',
                            text: 'Defensive',
                            value: 'defensive'
                        }
                    ]}
                    className={isTutorial6 && 'glow'}
                /> */}
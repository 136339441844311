//last verified against STAGE FOUR spreadsheet 3/10/2021

export default {
    positionMap: [
        "GK",
        "CB",
        "CB",
        "CB",
        "LM",
        "CM",
        "CM",
        "RM",
        "LW",
        "RW",
        "ST"
    ],
    pitchHalfMap: [
        12,
        7,
        13,
        19,
        3,
        9,
        21,
        27,
        4,
        28,
        17
    ]
}
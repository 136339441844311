import {Grid, Table, Button} from 'semantic-ui-react'
import useMediaQuery from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCakeCandles, faCheck, faCoins, faHourglass, faShieldHalved } from '@fortawesome/free-solid-svg-icons';
import {FormattedDate} from "react-intl";
import {useMutation, useQueryClient} from '@tanstack/react-query';
import axios from 'axios';

import ClubLink from "../../club/components/common/Link";
import Currency from '../../common/components/Currency';

export default function ScoutReportDetailExpander(props) {
    const compactView = useMediaQuery({
        query: '(max-width: 500px)'
    });

    const queryClient = useQueryClient();

    const deleteScoutReport = useMutation(scoutReportId => axios.delete(`${process.env.REACT_APP_APPHOST}/scoutReports/${scoutReportId}`), {
        onSuccess: () => {
            queryClient.invalidateQueries('getScoutReports');
        }
    });

    const styles = compactView ? mobileStyles : desktopStyles;
    const scoutReport = props.data;

    return(
          <div style={styles.wrapper}>
            <Grid
                columns={2}
                style={styles.container}
            >
                <Grid.Column style={styles.detailColumn}>
                    <Table
                        unstackable
                        compact
                        basic='very'
                        style={styles.detailTable}
                        className='attrTable'
                    >
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell style={styles.firstCell}><FontAwesomeIcon icon={faCakeCandles} /></Table.Cell>
                                <Table.Cell collapsing>
                                    {scoutReport.player.person.age}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={styles.firstCell}><FontAwesomeIcon icon={faShieldHalved} /></Table.Cell>
                                <Table.Cell collapsing>
                                    {scoutReport.player.playerContract ? <ClubLink club={scoutReport.player.playerContract.club} /> : 'Free agent'}
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={styles.firstCell}><FontAwesomeIcon icon={faCoins} /></Table.Cell>
                                <Table.Cell collapsing>
                                    <Currency value={scoutReport.player.value} />
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column style={styles.controlColumn}>
                    {scoutReport.status === 'pending' ?
                        <>
                            <p style={{margin: 0}}><FontAwesomeIcon icon={faHourglass} className='inlineIcon' /> <FormattedDate value={scoutReport.dueDate || scoutReport.updatedAt} day='numeric' month='short' /></p>
                            <Button
                                size='tiny'
                                negative
                                onClick={() => deleteScoutReport.mutate(scoutReport.id)}
                            >Cancel report</Button>
                        </>
                        :
                        <>
                            <p style={{margin: 0}}><FontAwesomeIcon icon={faCheck} className='inlineIcon' /><FormattedDate value={scoutReport.dueDate || scoutReport.updatedAt} day='numeric' month='short' /></p>
                            <Button size='tiny'>View report</Button>
                        </>
                    }
                </Grid.Column>
            </Grid>
          </div>
    );
}

const desktopStyles = {
    wrapper: {
        backgroundColor: '#e0e1e2'
    },
    container: {
        maxWidth: '350px',
        margin: '0 auto',
        backgroundColor: '#FFF'
    },
    detailColumn: {
        padding: 0
    },
    controlColumn: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    detailTable: {
        wordBreak: 'break-word',
        margin: 0,
        padding: '0 1rem 1px 1rem',
        borderRight: '1px solid #e0e1e2',
        borderRadius: 0
    }
}

const mobileStyles = {
    wrapper: {
        backgroundColor: '#e0e1e2',
    },
    container: {
        backgroundColor: '#FFF',
        margin: 0
    },
    detailColumn: {
        padding: 0
    },
    controlColumn: {
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    detailTable: {
        wordBreak: 'break-word',
        margin: 0,
        padding: '0 1rem 1px 1rem',
        borderRight: '1px solid #e0e1e2',
        borderRadius: 0
    }
}
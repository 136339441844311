import React from "react";
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_TransfersShortlist.text",
		defaultMessage: "Clubs",
		description: "Link for favourite clubs page"
	}
});

export default function FavClubsToolbarItem(props) {
	return(
		<ToolbarItem
			path={generatePath(URL.favClubs)}
			icon={faShieldHalved}
			messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
		/>
	)
}
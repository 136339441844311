import { Statistic, Button, Segment, Header, Grid, Table } from "semantic-ui-react";
import {FormattedMessage} from 'react-intl';
import {useHistory, generatePath} from 'react-router';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import Component_RatingRoundall from "../../../player/common/RatingRoundall";
import Component_Currency from "../../../common/components/Currency";
import StaffLink from "../../../staff/common/Link";
import StaffRoleName from "../../../staff/common/RoleName";
import NarrowLayout from "../../../common/components/NarrowLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faDumbbell, faCakeCandles, faClock, faCoins, faChild, faMagnifyingGlass, faMedkit } from "@fortawesome/free-solid-svg-icons";
import StaffContractExpiry from "../../../staff/common/ContractExpiry";
import StarRating from "../../../common/components/StarRating";
import {URL} from '../../../common/lib/paths';
import {useTutorial} from '../../../tutorial/hooks/useTutorial';

dayjs.extend(relativeTime);

const departmentIcons = {
    coaching: faDumbbell,
    youth: faChild,
    scouting: faMagnifyingGlass,
    physio: faMedkit
}

export default function ClubStaffInterface(props) {
    if(props.isLoading) return null;

    return(
        <NarrowLayout>
            <Header as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)'}}>
                <span>
                    Overview
                </span>
            </Header>
            <Segment attached className='attachedInvertedSegment'>
                <Grid style={{margin: 0}}>
                    <Grid.Row columns={1} style={{padding: 0}}>
                        <Grid.Column>
                            <Table
                                basic='very'
                                compact='very'
                                unstackable
                            >
                                {props.departments.map((department) =>
                                    <Table.Row>
                                        <Table.Cell style={{fontWeight: 'bold'}}>
                                            <a href={`#${department.department}`}>
                                                <FormattedMessage {...messages[department.department]} />
                                            </a>
                                        </Table.Cell>
                                        <Table.Cell collapsing>
                                            <StarRating rating={department.overallRating/20} />
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
            {props.departments.map(department => 
                <Department
                    department={department}
                    isLoading={props.isLoading}
                />
            )}
        </NarrowLayout>
    );
}

function Department(props) {
    const history = useHistory();
    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('staff', 2);

    return(
        <>
            <Header id={props.department.department} as='h5' attached='top' inverted style={{backgroundColor: 'rgb(27 29 30)'}}>
                <span>
                    <FontAwesomeIcon icon={departmentIcons[props.department.department]} className='inlineIcon' />
                    <FormattedMessage {...messages[props.department.department]} />
                </span>
                <span style={{float: 'right', margin: 0}}>
                    <StarRating rating={props.department.overallRating/20} />
                </span>
            </Header>
            <Segment attached className='attachedInvertedSegment'>
                <Grid style={{margin: 0}}>
                    <Grid.Row columns={2} divided>
                        <Grid.Column style={stylesb.a}>
                            <Statistic size='tiny'>
                                <Statistic.Value>
                                    {props.department.facilityRating}
                                    <FontAwesomeIcon icon={faStar} color='#FBBD08' size='sm' />
                                </Statistic.Value>
                                <Statistic.Label style={{marginTop: '8px'}}>Facilities</Statistic.Label>
                            </Statistic>
                            <Button size='tiny' disabled>Upgrade</Button>
                        </Grid.Column>
                        <Grid.Column  style={stylesb.a}>
                            <Statistic size='tiny'>
                                <Statistic.Value>
                                    <Component_RatingRoundall rating={props.department.staffRating} disableFloat={true} size={2} />
                                </Statistic.Value>
                                <Statistic.Label>Staff rating</Statistic.Label>
                            </Statistic>
                            <Button
                                size='tiny'
                                onClick={() => history.push({pathname: generatePath(URL.transferSearchStaff), state: {filterDepartment: props.department.department, partial: true}, search: isTutorial1 && getSearchParamsString('staff', 3)})}
                                className={isTutorial1 && 'glow'}
                            >
                                Find <StaffRoleName staff={{department:props.department.department, isHead: false}} lowercase={true} plural={true} /></Button>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h4'><StaffRoleName staff={{department: props.department.department, isHead: true}} /></Header>
                            <FormattedMessage {...messages[`head${props.department.department}_description`]} />
                            <Table
                                basic='very'
                                compact='very'
                                unstackable
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={5}>Name</Table.HeaderCell>
                                        <Table.HeaderCell><FontAwesomeIcon icon={faCakeCandles} /></Table.HeaderCell>
                                        <Table.HeaderCell><FontAwesomeIcon icon={faClock} /></Table.HeaderCell>
                                        <Table.HeaderCell><FontAwesomeIcon icon={faCoins} /></Table.HeaderCell>
                                        <Table.HeaderCell><FontAwesomeIcon icon={faStar} /></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {props.department.headStaffContracts?.length >= 1 ?
                                        getStaffRow(props.department.headStaffContracts[0])
                                        :
                                        getVacancyRow(props.department.department, true)
                                    }
                                </Table.Body>
                            </Table>
                            <Header as='h4'><StaffRoleName staff={{department: props.department.department}} plural={true} /></Header>
                            <FormattedMessage {...messages[`${props.department.department}_description`]} />
                            <Table
                                basic='very'
                                compact='very'
                                unstackable
                            >
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell width={5}>Name</Table.HeaderCell>
                                        <Table.HeaderCell><FontAwesomeIcon icon={faCakeCandles} /></Table.HeaderCell>
                                        <Table.HeaderCell><FontAwesomeIcon icon={faClock} /></Table.HeaderCell>
                                        <Table.HeaderCell><FontAwesomeIcon icon={faCoins} /></Table.HeaderCell>
                                        <Table.HeaderCell><FontAwesomeIcon icon={faStar} /></Table.HeaderCell>
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {props.department.normalStaffContracts.map((staffContract) => 
                                        staffContract !== null ?
                                            getStaffRow(staffContract)
                                            :
                                            getVacancyRow(props.department.department, false)
                                    )}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        </>
    );

    function getStaffRow(staffContract) {
        return(
            <Table.Row>
                <Table.Cell width={5}>
                    <StaffLink staff={staffContract.staff} />
                </Table.Cell>
                <Table.Cell>
                    {staffContract.staff.person.age}
                </Table.Cell>
                <Table.Cell>
                    <StaffContractExpiry staffContract={staffContract} />
                </Table.Cell>
                <Table.Cell>
                    <Component_Currency value={staffContract.salary} />
                </Table.Cell>
                <Table.Cell>
                    <Component_RatingRoundall rating={staffContract.staff.currentAbility} disableFloat={true} />
                </Table.Cell>
            </Table.Row>
        )
    }

    function getVacancyRow(department, isHead) {
        return(
            <Table.Row>
                <Table.Cell colSpan={3}>
                    Vacant position
                </Table.Cell>
                <Table.Cell colSpan={2}>
                    <Button
                        size='mini'
                        floated='right'
                        onClick={() => history.push(generatePath(URL.transferSearchStaff), {filterDepartment: department, filterIsHead: isHead, partial: true})}
                    >
                        Search
                    </Button>
                </Table.Cell>
            </Table.Row>
        )
    }
}

const stylesb = {
    a: {
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'column'
    }
}

const messages = {
    coaching: {
        id: 'departments:coaching',
        defaultMessage: 'Coaching',
        description: 'Name for the department responsible for training players'
    },
    headcoaching_description: {
        id: 'staff:headcoach:description',
        defaultMessage: 'Your head coach leads training sessions'
    },
    coaching_description: {
        id: 'staff:coach:description',
        defaultMessage: 'Your coaching team assist your head coach in training'
    },
    youth: {
        id: 'departments:youthAcademy',
        defaultMessage: 'Youth academy',
        description: 'Name for the department responsible for youth players'
    },
    headyouth_description: {
        id: 'staff:headyouth:description',
        defaultMessage: 'Your youth manager leads training sessions for youth players'
    },
    youth_description: {
        id: 'staff:youth:description',
        defaultMessage: 'Your youth coaching team assist your youth manager in training'
    },
    scouting: {
        id: 'department:scouting',
        defaultMessage: 'Scout network',
        description: 'Name for the department responsible for scouting new players'
    },
    headscouting_description: {
        id: 'staff:headscout:description',
        defaultMessage: 'Your head scout is the most senior member of the scouting team'
    },
    scouting_description: {
        id: 'staff:scout:description',
        defaultMessage: 'Your scouting team assess transfer targets and provide valuable analysis'
    },
    physio: {
        id: 'department:physio',
        defaultMessage: 'Physiotherapy',
        description: 'Name for the department responsible for treating player injuries'
    },
    headphysio_description: {
        id: 'staff:headphysio:description',
        defaultMessage: 'Your head physio is the most senior member of the physio team'
    },
    physio_description: {
        id: 'staff:physio:description',
        defaultMessage: 'Your physio team are responsible for treating player injuries'
    },
}
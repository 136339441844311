import { useQuery } from '@tanstack/react-query';
import PlayerTransferAuctionFreeHistoryInterface from "./interface";
import { useParams } from "react-router";
import axios from "axios";
import { useMemo } from "react";

export default function PlayerTransferAuctionFreeHistory(props) {
    const {playerTransferAuctionFreeHistoryId} = useParams();

    const playerTransferAuctionFreeHistoryQuery = useQuery(
        ['getPlayerTransferAuctionFreeHistory', playerTransferAuctionFreeHistoryId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferAuctionFreeHistory/${playerTransferAuctionFreeHistoryId}`)
    );

    const myBid = useMemo(() => playerTransferAuctionFreeHistoryQuery.data?.data?.response.playerTransferAuctionBidFreeHistory.find((bid) => bid.clubId === props.manager.club.id),[playerTransferAuctionFreeHistoryQuery.data?.data?.response]);
    const winningBid = useMemo(() => playerTransferAuctionFreeHistoryQuery.data?.data?.response.playerTransferAuctionBidFreeHistory.find((bid) => bid.isWinner === true),[playerTransferAuctionFreeHistoryQuery.data?.data?.response]);

    return(
        <PlayerTransferAuctionFreeHistoryInterface
            isLoading={playerTransferAuctionFreeHistoryQuery.isLoading}
            playerTransferAuctionFreeHistory={playerTransferAuctionFreeHistoryQuery.data?.data?.response}
            myBid={myBid}
            winningBid={winningBid}
        />
    );
}
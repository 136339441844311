import {generatePath, matchPath} from "react-router";
import {Link, useLocation} from "react-router-dom";
import {defineMessages, FormattedMessage} from "react-intl";
import { useMediaQuery } from 'react-responsive'
import random from 'lodash/random';
import {
	faNewspaper,
	faSignIn,
	faEnvelope,
	faUserGroup,
	faSliders,
	faDumbbell,
	faShieldHalved,
	faCalendarDays,
	faListOl,
	faRightLeft,
	faBriefcase,
	faGlobe,
	faUserTie,
	faUserPen,
	faStar
} from "@fortawesome/free-solid-svg-icons";

import {URL} from "../../common/lib/paths.js";

import {Menu, Icon, Label, Image} from "semantic-ui-react";

import Currency from '../../common/components/Currency';

import logo from './logov5.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useTutorial } from "../../tutorial/hooks/useTutorial.js";

export default function Sidebar(props) {
	const location = useLocation();
	const {getSearchParamsString, goNextStep, checkTutorial} = useTutorial();

	const desktopView = useMediaQuery({
        query: '(min-width: 900px)'
    });
 
    const messageCountQuery = useQuery(
        ['countMessages'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/messages/count`),
        {
            enabled: !!props.manager,
			staleTime: 30000,
            cacheTime: 30000
        }
    );

    // const transferOfferCountQuery = useQuery('countTransferOffers', () =>
    //     axios.get(`${process.env.REACT_APP_APPHOST}/transferOffers/count`),
    //     {
    //         enabled: isLoggedIn === true,
    //         staleTime: 600000,
    //     }
    // );

	if(props.isLoading) return null;

	const isPathActive = (currentPath, activePaths, name) => {
		for(const path of activePaths) {
			const match = matchPath(currentPath, {path: path});

			if(!!match) {
				if(name==='home') {
					if(match.isExact) return true;
				} else {
					return true;
				}
			}
		}

		return false;
	}

	const isTutorial = checkTutorial('intro', 1);

	function handleTutorialClick(event) {
		event.stopPropagation();
		event.preventDefault();
		goNextStep();
	}

	return(
		<div style={styles.wrapper} className='sidebar'>
			{desktopView ?
				<div
					style={{
						order: 0,
						flex: "0 1 auto",
						alignSelf: "stretch"
					}}
				>
					<Menu
						secondary
						inverted
						color="blue"
						fluid
						style={desktopView ? styles.title : undefined}
					>
						<Menu.Item header style={{padding: 0, width: '100%'}}>
							<Image
								style={{
									height: '28px',
									margin: '0 auto'
								}}
								src={logo}
							/>
						</Menu.Item>
					</Menu>
				</div>
				:
				<Menu
					inverted
					color="blue"
					fluid
					style={{margin: 0, borderRadius: 0}}
				>
					<Menu.Item href='/' style={{padding: 0, width: '100%'}}>
						<Image
							style={{
								height: '28px',
								margin: '0 auto'
							}}
							src={logo}
						/>
					</Menu.Item>
				</Menu>
			}
			<div style={styles.container}>
				<Menu
					vertical
					inverted
					style={desktopView ? styles.menuDesktop : styles.menu}
					className={isTutorial && 'glow'}
					onClickCapture={isTutorial ? handleTutorialClick.bind(this) : undefined}
				>
					{props.manager?.club &&
						<Menu.Item
							as={Link}
							to={{pathname: generatePath(URL.clubFinances)}}
							active={isPathActive(location.pathname, [URL.clubFinances])}
							style={desktopView ? styles.balanceDesktop : styles.balanceMobile}
						>
							<Currency value={props.manager.club.balance} />
						</Menu.Item>
					}
					{menu.map((item) => {
						if(item.loggedIn === false && !!props.manager) return null;
						if(item.loggedIn === true && !props.manager) return null;
						if(item.employed === true && !props.manager?.club) return null;
						if(item.employed === false && !!props.manager?.club) return null;

						const icon = <FontAwesomeIcon icon={item.icon} fixedWidth={true} style={desktopView ? {float: 'right'} : {}} />

						return(
							<Menu.Item
								as={Link}
								to={{
									pathname: item.to.pathname(props.manager?.club?.id, props.manager?.club?.firstTeam.currentTLS.LS.leagueId),
									state: item.to.state,
									search: !!item.to.search && item.to.search(checkTutorial, getSearchParamsString)
								}}
								active={isPathActive(location.pathname, item.activePaths, item.name)} key={item.name}
								style={!desktopView ? {display: 'flex', justifyContent: 'center'} : {}}
								className={!!item.glow && item.glow(checkTutorial)}
							>
								{item.name === 'messages' ?
									<Icon.Group style={desktopView ? {position: 'relative', float: 'right'} : {position: 'relative'}}>
										{icon}
										{(messageCountQuery.data?.data?.response !== undefined && messageCountQuery.data?.data?.response > 0) ?
											<Label color='grey' size='mini' floating style={{padding: '2px 3px', left: '22px', top: '-7px'}}>{messageCountQuery.data?.data?.response}</Label>
											:
											null
										}
									</Icon.Group>	
								:
									null
								}
								{item.name === 'league' ?
									<Icon.Group style={desktopView ? {position: 'relative', float: 'right'} : {position: 'relative'}}>
										{icon}
										<Label color={props.manager.club.firstTeam.currentTLS.position <= 3 ? 'green' : props.manager.club.firstTeam.currentTLS.position >= 6 ? 'red' : 'grey'} size='mini' floating style={{padding: '2px 3px', left: '22px', top: '-7px'}}>{props.manager.club.firstTeam.currentTLS.position}</Label>
									</Icon.Group>	
									:
									null
								}
								{item.name === 'transfers' ?
									<Icon.Group style={desktopView ? {position: 'relative', float: 'right'} : {position: 'relative'}}>
										{icon}
										{(props.transferOfferCount !== undefined && props.transferOfferCount > 0) ?
											<Label color='blue' size='mini' floating style={{padding: '2px 3px', left: '22px', top: '-7px'}}>{props.transferOfferCount}</Label>
											:
											null
										}
									</Icon.Group>	
									:
									null
								}
								{['messages', 'league', 'transfers'].indexOf(item.name) === -1 &&
									icon
								}
								{desktopView ? <FormattedMessage {...messages[item.name]} /> : null}
							</Menu.Item>
						);
					})}
				</Menu>
			</div>
		</div>
	)
}

const menu = [
	{
		name: "home",
		icon: faNewspaper,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.home),
		},
		activePaths: [URL.home]
	},
	{
		name: 'login',
		icon: faSignIn,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.login),
		},
		activePaths: [URL.login],
		loggedIn: false
	},
	{
		name: 'register',
		icon: faUserPen,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.register),
		},
		activePaths: [URL.register],
		loggedIn: false
	},
	{
		name: "messages",
		icon: faEnvelope,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.messages),
		},
		activePaths: [
			URL.messages,
			URL.message
		],
		loggedIn: true
	},
	{
		name: "players",
		icon: faUserGroup,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.teamPlayers, {clubId, teamType: 'first'}),
			search: (checkTutorial, getSearchParamsString) => checkTutorial('players', 12) && (getSearchParamsString('players', 13) || undefined)
		},
		activePaths: [
			URL.teamPlayers.replace(':teamType', 'first'),
			URL.teamPlayers.replace(':teamType', 'youth'),
			URL.playerProfile,
			URL.playerHistory
		],
		employed: true,
		glow: (checkTutorial) => (checkTutorial('players', 12) && 'glow') || undefined
	},
	{
		name: "tactics",
		icon: faSliders,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.teamTactics, {clubId, teamType: 'first'}),
		},
		activePaths: [
			URL.teamTactics.replace(':teamType', 'first'),
			URL.teamTactics.replace(':teamType', 'youth')
		],
		employed: true
	},
	{
		name: "training",
		icon: faDumbbell,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.teamTraining, {clubId, teamType: 'first'}),
		},
		activePaths: [
			URL.teamTraining.replace(':teamType', 'first'),
			URL.teamTraining.replace(':teamType', 'youth')
		],
	 	employed: true
	},
	{
		name: "club",
		icon: faShieldHalved,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.clubProfile, {clubId}),
		},
		activePaths: [
			URL.clubProfile,
			URL.teamLeagueSeasons
		],
		employed: true
	},
	{
		name: "staff",
		icon: faUserTie,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.clubStaff, {clubId})
		},
		activePaths: [
			URL.clubStaff,
			URL.staffProfile,
			URL.staffHistory
		],
	 	employed: true
	},
	{
		name: "fixtures",
		icon: faCalendarDays,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.teamFixtures, {clubId, teamType: 'first'}),
		},
		activePaths: [
			URL.teamFixtures.replace(':teamType', 'first'),
			URL.teamFixtures.replace(':teamType', 'youth'),
			URL.leagueFixture,
			URL.cupFixture
		],
		employed: true
	},
	{
		name: "league",
		icon: faListOl,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.teamLeague, {clubId, teamType: 'first'}),
		},
		activePaths: [
			URL.league,
			URL.teamLeague.replace(':teamType', 'first'),
			URL.teamLeague.replace(':teamType', 'youth')
		],
		employed: true
	},
	{
		name: "transfers",
		icon: faRightLeft,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.clubPlayerTransferOffers, {clubId}),
			search: (checkTutorial, getSearchParamsString) => checkTutorial('transfers', 9) && (getSearchParamsString('transfers', 10) || undefined)
		},
		activePaths: [
			URL.clubPlayerTransferOffers,
			URL.transferSearchPlayers,
			URL.transferSearchStaff,
			URL.transferHistory
		],
		employed: true,
		glow: (checkTutorial) => (checkTutorial('transfers', 9) && 'glow') || undefined
	},
	{
		name: 'jobs',
		icon: faBriefcase,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.jobs, {}, {}),
			state: {index: random(1, 21), replace: true}
		},
		activePaths: [URL.jobs],
		employed: false,
		loggedIn: true
	},
	// {
	// 	name: "manager",
	// 	icon: "male",
	// 	path: URL.home,
	// 	activePaths: [URL.managerProfile, URL.managerHistory, URL.newManager],
	// },
	{
		name: 'favourites',
		icon: faStar,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.favPlayers),
		},
		activePaths: [URL.favPlayers],
		loggedIn: true
	},
	{
		name: 'game',
		icon: faGlobe,
		to: {
			pathname: (clubId, firstTeamLeagueId) => generatePath(URL.nationList),
			search: (checkTutorial, getSearchParamsString) => checkTutorial('world', 4) && (getSearchParamsString('world', 5) || undefined)
		},
		activePaths: [
			URL.worldOverview,
			URL.nationList,
			URL.nation,
			URL.nationLeagues
		],
		loggedIn: true,
		glow: (checkTutorial) => (checkTutorial('world', 4) && 'glow') || undefined
	},
	// {
	// 	name: "settings",
	// 	icon: "settings",
	// 	path: URL.settings,
	// 	activePaths: [URL.settings]
	// }
]

const styles = {
	wrapper: {
		order: 0,
		flex: "0 1 auto",
		alignSelf: "stretch",
		display: "flex",
		flexDirection: "column",
		flexWrap: "nowrap",
		justifyContent: "flex-start",
		alignContent: "stretch",
		alignItems: "flex-start"
	},
	container: {
		order: 0,
		flex: "1 1 auto",
		alignSelf: "stretch",
		backgroundColor: "#1B1C1D"
	},
	title: {
		margin: 0,
		order: 0,
		flex: "0 1 auto",
		selfAlign: "auto"
	},
	menuDesktop: {
		margin: 0,
		borderRadius: 0
	},
	menu: {
		width: "auto",
		overflowY: "auto",
		borderRadius: 0
	},
	balanceMobile: {
		paddingLeft: 0,
		paddingRight: 0,
		fontSize: '12px',
		lineHeight: '18px',
		textAlign: 'center',
		// backgroundColor: 'rgba(15, 16, 17)',
		height: '40px'
	},
	balanceDesktop: {
		fontSize: '14px',
		fontWeight: 'bold',
		lineHeight: '14px',
		// backgroundColor: 'rgba(15, 16, 17)',
		height: '40px'
	}
}

const messages = defineMessages({
	home: {
		id: "Component_HomeItem.text",
		defaultMessage: "News",
		description: "Link in the main navigation menu to go to the news page"
	},
	login: {
		id: "Component_LoginItem.text",
		defaultMessage: 'Login',
		description: 'Link in the main navigation to login'
	},
	logout: {
		id: "Component_LogoutItem.text",
		defaultMessage: 'Logout',
		description: 'Link in the main navigation to logout'
	},
	register: {
		id: "Component_RegisterItem.text",
		defaultMessage: 'Register',
		description: 'Link in the main navigation to register'
	},
	manager: {
		id: "Component_ManagerItem.text",
		defaultMessage: "Manager",
		description: "Link in the main navigation menu to go to the manager profile page"
	},
	settings: {
		id: "Component_SettingsItem.text",
		defaultMessage: "Settings",
		description: "Link in the main navigation menu to go to the settings page"
	},
	admin: {
		id: "Component_AdminItem.text",
		defaultMessage: "Admin",
		description: "Link in the main navigation menu to go to the admin page"
	},
	messages: {
		id: "Component_MessagesItem.text",
		defaultMessage: "Messages",
		description: "Link in the main navigation menu to go to the messages page"
	},
	players: {
		id: "Component_PlayersItem.text",
		defaultMessage: "Players",
		description: "Link in the main navigation menu to go to the club players list page"
	},
	tactics: {
		id: "Component_TacticsItem.text",
		defaultMessage: "Tactics",
		description: "Link in the main navigation menu to go to the tactics page"
	},
	training: {
		id: "Component_TrainingItem.text",
		defaultMessage: "Training",
		description: "Link in the main navigation menu to go to the training page"
	},
	staff: {
		id: 'Component_StaffItem.text',
		defaultMessage: 'Staff',
		description: 'Link in main nav for staff page'
	},
	youth: {
		id: 'sidebar:youthTeam',
		defaultMessage: 'Youth team',
		description: 'Link in main nav for youth team page'
	},
	club: {
		id: "Component_ClubItem.text",
		defaultMessage: "Club",
		description: "Link in the main navigation menu to go to the club profile page"
	},
	fixtures: {
		id: "Component_FixturesItem.text",
		defaultMessage: "Fixtures",
		description: "Link in the main navigation menu to go to the fixtures page"
	},
	league: {
		id: "Component_LeagueItem.text",
		defaultMessage: "League",
		description: "Link in the main navigation menu to go to the league page"
	},
	transfers: {
		id: "Component_TransfersItem.text",
		defaultMessage: "Transfers",
		description: "Link in the main navigation menu to go to the transfers page"
	},
	jobs: {
		id: "Component.JobsItem.text",
		defaultMessage: 'Jobs',
		description: "Link in the main navigation menut to go to the jobs page"
	},
	finances: {
		id: "Component_FinancesItem.text",
		defaultMessage: "Finances",
		description: "Link in the main navigation menu to go to the finances page"
	},
	game: {
		id: 'Sidebar:world',
		defaultMessage: 'World',
		description: 'Link in the main navigation menu to go to the world overview page'
	},
	favourites: {
		id: 'Sidebar:favourites',
		defaultMessage: 'Favourites',
		description: 'Link in the main navigation menu to go to the favourites page'
	}
});
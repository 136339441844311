import { useState } from "react";
import { Accordion, Table } from "semantic-ui-react";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import axios from "axios";

export default function TeamMetaAccordion(props) {
    const [isExpanded, setIsExpanded] = useState(false);

    return(
        <Accordion
            inverted
        >
            <Accordion.Title
                active={isExpanded}
                onClick={() => setIsExpanded(isExpanded => !isExpanded)}
                index={0}
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} className='inlineIcon' />
                    Team info
                </SUIIconShim>
            </Accordion.Title>
            <Accordion.Content
                active={isExpanded}
            >
                <TeamMeta
                    active={isExpanded}
                />
            </Accordion.Content>
        </Accordion>
    )
}

function TeamMeta(props) {
    const {clubId, teamType} = useParams();

    const {data} = useQuery(
        ['getTeamPlayerRegistrationMetaInfo', clubId, teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/${teamType}/playerRegistrations/?context=meta&ownershipClaim=false`),
        {
            enabled: props.active
        }
    );

    return(
        <Table
            definition
            size='small'
            compact
            striped
            unstackable
        >
            <Table.Body>
                <Table.Row>
                    <Table.HeaderCell width={8}>Players</Table.HeaderCell>
                    <Table.Cell width={4}>
                        {data?.data?.response.teamPlayerCount}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell width={8}>Avg. rating</Table.HeaderCell>
                    <Table.Cell width={4}>
                        {data?.data?.response.averagePlayerCA}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.HeaderCell width={8}>Avg. age</Table.HeaderCell>
                    <Table.Cell width={4}>
                        {data?.data?.response.averagePlayerAge}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );
}
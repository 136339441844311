import Flag from '../../common/components/Flag';
import NationGroupName from './NationGroupName';

export default function NationGroupNameWithFlag(props) {
    return(
        <>
            <Flag name={props.shortcode} responsiveClass={props.responsiveClass} className='playerNationFlag' />
            <NationGroupName shortcode={props.shortcode} />
        </>
    );
}
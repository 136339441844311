import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { generatePath, useHistory } from "react-router";
import DataTable from "../../../../common/components/DataTable";
import TitledSegment from "../../../../common/components/TitledSegment";
import WideLayout from "../../../../common/components/WideLayout";
import { clubName } from "../../../../common/dataTableColumns/club/clubName";
import { currency } from "../../../../common/dataTableColumns/currency";
import { playerName } from "../../../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../../../common/dataTableColumns/player/playerPosition";
import { transferAuctionDuration } from "../../../../common/dataTableColumns/transferAuctionDuration";
import { currentAbility } from '../../../../common/dataTableColumns/currentAbility';
import { URL } from "../../../../common/lib/paths";
import { auctionStatus } from "../../../../common/dataTableColumns/auctionStatus";

export default function ClubPlayerTransferAuctionPaidInterface(props) {
    return(
        <WideLayout>
            <TitledSegment
                title='Offers made'
                icon={faArrowUp}
                noPadding={true}
            >
                <ProposedPlayerTransferAuctionPaidTable
                    isLoading={props.isLoading}
                    playerTransferAuctionPaid={props.proposedPlayerTransferAuctionPaid}
                    manager={props.manager}
                />
            </TitledSegment>
            <TitledSegment
                title='Offers received'
                icon={faArrowDown}
                noPadding={true}
            >
                <ReceivedPlayerTransferAuctionPaidTable
                    isLoading={props.isLoading}
                    playerTransferAuctionPaid={props.receivedPlayerTransferAuctionPaid}
                />
            </TitledSegment>
        </WideLayout>
    )
}

function ProposedPlayerTransferAuctionPaidTable(props) {
    const history = useHistory();

    const columns = [
        auctionStatus(
            (playerTransferAuctionPaid) => 
                playerTransferAuctionPaid.isHistorical ?
                    playerTransferAuctionPaid.winningBid.club.id === props.manager.club.id ?
                        'won'
                        :
                        'lost'
                    :
                    playerTransferAuctionPaid.playerTransferAuctionTopBidPaid.club.id === props.manager.club.id ?
                        'winning'
                        :
                        'losing'
        ),
        playerName(
            (playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.player : playerTransferAuctionPaid.playerContract.player,
            (playerTransferAuctionPaid) => playerTransferAuctionPaid.playerContract,
            () => false
        ),
        clubName((playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.fromClub : playerTransferAuctionPaid.playerContract.club),
        currency(
            'auctionPrice',
            (playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.winningBid.finalBid : playerTransferAuctionPaid.currentBid
        ),
        transferAuctionDuration((playerTransferAuctionPaid) => playerTransferAuctionPaid.interval),
        playerPosition((playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.player.position : playerTransferAuctionPaid.playerContract.player.position),
        currentAbility((playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.player.currentAbility : playerTransferAuctionPaid.playerContract.player.currentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.playerTransferAuctionPaid}
            onRowClicked={(row) => row.isHistorical ? history.push(generatePath(URL.playerTransferAuctionPaidHistory, {playerTransferAuctionPaidHistoryId: row.id})) : history.push(generatePath(URL.playerTransferAuction, {playerTransferAuctionId: row.id}))}
        />
    )
}

function ReceivedPlayerTransferAuctionPaidTable(props) {
    const history = useHistory();

    const columns = [
        auctionStatus((playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? 'completed' : 'inProgress'),
        playerName(
            (playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.player : playerTransferAuctionPaid.playerContract.player,
            (playerTransferAuctionPaid) => playerTransferAuctionPaid.playerContract,
            () => false
        ),
        currency(
            'auctionPrice',
            (playerTransferAuctionPaid) =>  playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.winningBid.finalBid : playerTransferAuctionPaid.playerTransferAuctionTopBidPaid.maxBid
        ),
        transferAuctionDuration((playerTransferAuctionPaid) => playerTransferAuctionPaid.interval),
        playerPosition((playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.player.position : playerTransferAuctionPaid.playerContract.player.position),
        currentAbility((playerTransferAuctionPaid) => playerTransferAuctionPaid.isHistorical ? playerTransferAuctionPaid.player.currentAbility : playerTransferAuctionPaid.playerContract.player.currentAbility)
    ];

    return(
        <DataTable
            columns={columns}
            isLoading={props.isLoading}
            placeholderCount={4}
            data={props.playerTransferAuctionPaid}
            onRowClicked={(row) => row.isHistorical ? history.push(generatePath(URL.playerTransferAuctionPaidHistory, {playerTransferAuctionPaidHistoryId: row.id})) : history.push(generatePath(URL.playerTransferAuction, {playerTransferAuctionId: row.id}))}
        />
    )
}
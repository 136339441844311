import axios from "axios";
import { useEffect } from "react";
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';
import {useParams, useHistory, generatePath} from 'react-router-dom';

import NarrowLayout from '../../../common/components/NarrowLayout';
import MessageInterface from './interface';
import {URL} from '../../../common/lib/paths';

export default function MessageData(props) {
    const {messageId} = useParams();
    const queryClient = useQueryClient();
    const history = useHistory();


    useEffect(() => {
        props.setToolbar({title: 'Message', navigation: []});
    }, []);

    useEffect(() => {
        queryClient.invalidateQueries({queryKey: 'countMessages'});
    }, []);

    const {isLoading, data} = useQuery(
        ['getMessage', messageId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/messages/${messageId}`),
        {
            enabled: messageId !== undefined
        }
    );

    const deleteMessage = useMutation(() => axios.delete(`${process.env.REACT_APP_APPHOST}/messages/?messageIds=${messageId}`), {
        onSuccess: () => {
            history.replace(generatePath(URL.messages));
        }
    })

    return(
        <NarrowLayout>
            <MessageInterface
                isLoading={isLoading}
                message={data ? data.data.response : undefined}
                deleteInProgress={deleteMessage.isLoading}
                deleteMessage={() => deleteMessage.mutate()}
            />
        </NarrowLayout>
    );
}

/*

    const messages = [
        {
            read: false,
            date: dayjs.utc(),
            type: 'game:welcome',
            values: null,
            fromEntityType: 'game',
            fromEntityId: null,
            fromEntityName: 'Manager.Soccer',
            actionButton: null
        },
        {
            read: false,
            date: dayjs.utc(),
            type: 'club:welcome',
            fromEntityType: 'club',
            fromEntityId: 1,
            fromEntityName: 'Reading',
            values: [
                {
                    name: 'clubName',
                    entityType: 'club',
                    values: [
                        {
                            text: 'Reading',
                            routeName: 'clubProfile',
                            routeParameterName: 'clubId',
                            routeParameterValue: 1,
                        }
                    ]
                }
            ],
            actionButton: {
                name: 'clubProfile',
                routeName: 'clubProfile',
                routeParameterName: 'clubId',
                routeParameterValue: 1
            }
        },
    ]

*/
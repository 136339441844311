import { Redirect } from "react-router";
import { useTutorial } from "../hooks/useTutorial";
import { Loader } from "semantic-ui-react";

import {default as tutorialData} from '../tutorials/finances';
import { useEffect } from "react";
import { cloneDeep } from "lodash";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function FinancesTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    const clubFinancesQuery = useQuery(
        ['getClubFinances'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/finances`)
    );

    useEffect(() => {
        if(!cachedTutorialData && clubFinancesQuery.isSuccess) {
            var compiledTutorialData = cloneDeep(tutorialData);

            if(clubFinancesQuery.data.data.response.minAllocation >= clubFinancesQuery.data.data.response.maxAllocation) {
                compiledTutorialData.steps.splice(1, 2);
            }

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        step.path = {
                            pathname: step.path
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData, clubFinancesQuery.isSuccess]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
import { useEffect, useState } from "react";
import { Button, Form, Header, Segment, Table } from "semantic-ui-react";
import NarrowLayout from "../../common/components/NarrowLayout";
import Currency from "../../common/components/Currency";

function format(value) {
    return '£'+unformat(value).toLocaleString('en-US');
}

function unformat(value) {
    if(!isNaN(value)) return value;

    return parseInt(value.replaceAll(',', '').replaceAll('£', '')) || 0;
}

function format2(value, setter) {
    setter(unformat2(value) + '%');
}

function unformat2(value) {
    return parseInt(value.replaceAll('%', '')) || 0;
}

export default function Budget(props) {
    const [startingBalance, setStartingBalance] = useState(format(323671072));
    const [seasonExpenditure, setSeasonExpenditure] = useState(format(118694500));
    const [seasonIncome, setSeasonIncome] = useState(format(135075180));
    const [maxIncomeToWageRatio1, setMaxIncomeToWageRatio1] = useState('70%');
    const [maxIncomeToWageRatio2, setMaxIncomeToWageRatio2] = useState('100%');
    const [maxIncomeToWageRatio3, setMaxIncomeToWageRatio3] = useState('115%');
    const [maxIncomeToWageRatio4, setMaxIncomeToWageRatio4] = useState('130%');
    const [allocation, setAllocation] = useState(0);
    const [contingency, setContingency] = useState('100%');
    const [maxWageAllocation, setMaxWageAllocation] = useState(100);
    const [initialAllocationSet, setInitialAllocationSet] = useState(false);

    function handleSetMaxWageAllocation(newMaxWage) {
        setMaxWageAllocation(newMaxWage);
        setAllocation(Math.min(newMaxWage, allocation));
    }

    function handleSetAllocation(newAllocation) {
        if(newAllocation > maxWageAllocation) {
            return false
        } else {
            setAllocation(newAllocation);
        }
    }

    return(
        <>
            <Segment>
                <Form>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input
                                label='Starting balance'
                                value={startingBalance}
                                onChange={(e, {value}) => setStartingBalance(format(value))}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                label='Wages (season expenditure)'
                                value={seasonExpenditure}
                                onChange={(e, {value}) => setSeasonExpenditure(format(value))}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                label='Estimated prize money (season income)'
                                value={seasonIncome}
                                onChange={(e, {value}) => setSeasonIncome(format(value))}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input
                                label='Max income to wage ratio'
                                value={maxIncomeToWageRatio1}
                                onChange={(e, {value}) => format2(value, setMaxIncomeToWageRatio1)}
                            />
                            <p>When season runway is less than 1</p>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                label='Max income to wage ratio'
                                value={maxIncomeToWageRatio2}
                                onChange={(e, {value}) => format2(value, setMaxIncomeToWageRatio2)}
                            />
                            <p>When season runway is between 1 and 2</p>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                label='Max income to wage ratio'
                                value={maxIncomeToWageRatio3}
                                onChange={(e, {value}) => format2(value, setMaxIncomeToWageRatio3)}
                            />
                            <p>When season runway is between 2 and 5</p>
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                label='Max income to wage ratio'
                                value={maxIncomeToWageRatio4}
                                onChange={(e, {value}) => format2(value, setMaxIncomeToWageRatio4)}
                            />
                            <p>When season runway is greater than 5</p>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input
                                label='Budget contingency'
                                value={contingency}
                                onChange={(e, {value}) => format2(value, setContingency)}
                            />
                            <p>How much balance is set aside as contingency and not included in budgets</p>
                        </Form.Field>
                        <Form.Field>
                            <label>Budget allocation ({Math.round(allocation)}%)</label>
                            <span>Transfers</span>
                            <input type='range' style={{width: '80%'}} value={allocation} onChange={(e) => handleSetAllocation(parseInt(e.target.value))} />
                            <span>Wages</span>
                            <p>Max: {Math.round(maxWageAllocation)}%</p>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
            <BudgetInfo
                startingBalance={unformat(startingBalance)}
                seasonExpenditure={unformat(seasonExpenditure)}
                seasonIncome={unformat(seasonIncome)}
                maxIncomeToWageRatio1={unformat2(maxIncomeToWageRatio1) / 100}
                maxIncomeToWageRatio2={unformat2(maxIncomeToWageRatio2) / 100}
                maxIncomeToWageRatio3={unformat2(maxIncomeToWageRatio3) / 100}
                maxIncomeToWageRatio4={unformat2(maxIncomeToWageRatio4) / 100}
                allocation={allocation/100}
                contingency={unformat2(contingency) / 100}
                setMaxWage={handleSetMaxWageAllocation}
                setStartingBalance={setStartingBalance}
                setSeasonExpenditure={setSeasonExpenditure}
                setAllocation={setAllocation}
                initialAllocationSet={initialAllocationSet}
                setInitialAllocationSet={setInitialAllocationSet}
            />
        </>
    )
}

function BudgetInfo(props) {
    const estimatedEndSeasonBalance = props.startingBalance + (props.seasonIncome - props.seasonExpenditure);
    const contingency = props.seasonExpenditure*props.contingency;
    const availableBalance = Math.max(estimatedEndSeasonBalance - contingency, 0);

    //How many seasons of runway?
    const expenditureRunway = props.startingBalance / props.seasonExpenditure;

    //select MITWR based on runway
    const MITWR = (function() {
        if(expenditureRunway <= 1) {
            return props.maxIncomeToWageRatio1;
        } else if(expenditureRunway > 1 && expenditureRunway < 2) {
            return props.maxIncomeToWageRatio2;
        } else if(expenditureRunway >= 2 && expenditureRunway <= 5) {
            return props.maxIncomeToWageRatio3;
        } else {
            return props.maxIncomeToWageRatio4;
        }
    }());

    const maxWageBudget = props.seasonIncome * MITWR;

    const incrementalWageBudget = (availableBalance*props.allocation)/3;
    const totalWageBudget = Math.min(maxWageBudget, props.seasonExpenditure + incrementalWageBudget);
    const transferBudget = (availableBalance*(1-props.allocation));

    useEffect(() => {
        const maxIncrementalWage = maxWageBudget - props.seasonExpenditure;
        const maxIncrementalWageBudgetCost = maxIncrementalWage*3;
        props.setMaxWage(Math.min(Math.max((maxIncrementalWageBudgetCost/availableBalance), 0), 1) * 100);
    }, [props.startingBalance, props.seasonExpenditure, props.seasonIncome, props.contingency, props.maxIncomeToWageRatio1, props.maxIncomeToWageRatio2, props.maxIncomeToWageRatio3, props.allocation]);

    function handleNextSeasonW() {
        props.setStartingBalance(format(estimatedEndSeasonBalance - totalWageBudget + props.seasonIncome));
        props.setSeasonExpenditure(format(totalWageBudget));
        props.setInitialAllocationSet(false);
    }

    function handleNextSeasonT() {
        props.setStartingBalance(format(estimatedEndSeasonBalance - transferBudget + props.seasonIncome));
        props.setInitialAllocationSet(false);
    }

    function handleNextSeasonB() {
        props.setStartingBalance(format(estimatedEndSeasonBalance - transferBudget - totalWageBudget + props.seasonIncome));
        props.setSeasonExpenditure(format(totalWageBudget));
        props.setInitialAllocationSet(false);
    }

    return(
        <NarrowLayout>
            <Segment>
                <Header as='h4'>General</Header>
                <Table definition>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>End season balance</Table.Cell>
                            <Table.Cell><Currency value={estimatedEndSeasonBalance} /></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>Contingency</Table.Cell>
                            <Table.Cell><Currency value={contingency} /></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>Available balance</Table.Cell>
                            <Table.Cell><Currency value={availableBalance} /></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Header as='h4'>Wage budget</Header>
                <Table definition>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>
                                Expenditure runway (seasons)
                            </Table.Cell>
                            <Table.Cell>{expenditureRunway}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                Max ITWR
                            </Table.Cell>
                            <Table.Cell>{Math.round(MITWR*100)}%</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>
                                Current ITWR
                            </Table.Cell>
                            <Table.Cell>{Math.round((props.seasonExpenditure / props.seasonIncome)*100)}%</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>Max wage budget</Table.Cell>
                            <Table.Cell><Currency value={maxWageBudget} /></Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell collapsing>New wage budget</Table.Cell>
                            <Table.Cell><Currency value={totalWageBudget} /> (<Currency value={Math.round(totalWageBudget-props.seasonExpenditure)} />)</Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>``
                <Header as='h4'>Transfer budget</Header>
                <Table definition>
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell collapsing>New transfer budget</Table.Cell>
                            <Table.Cell><Currency value={transferBudget} /></Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
                <Button
                    onClick={handleNextSeasonW}
                >
                    Next season (wages)
                </Button>
                <Button
                    onClick={handleNextSeasonT}
                >
                    Next season (transfer)
                </Button>
                <Button
                    onClick={handleNextSeasonB}
                >
                    Next season (both)
                </Button>
            </Segment>
        </NarrowLayout>
    )
}
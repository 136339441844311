import {Label} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';

export default function InfoLabel(props) {
    var colour = null;
    var text = null;

    if(!!props.player.injuredUntil) {
        colour = 'red';
        text = <FormattedMessage {...messages.injuryLabel} />
    } else if(props.player.numberOfGamesSuspended > 0) {
        colour = 'red';
        text = <FormattedMessage {...messages.suspensionLabel} />
    } else if(props.player.condition < 70) {
        colour = 'orange';
        text = <FormattedMessage {...messages.unfitLabel} />
    } else if(!!props.player.retirementSeasonId) {
        colour = 'grey';
        text = <FormattedMessage {...messages.retiringLabel} />
    } else if(props.importantOnly !== true && (props.player.activeFreeAuction > 0 || props.player.activePaidAuction > 0 || props.playerContract?.activePaidAuction > 0)) {
        colour = 'green';
        text = <FormattedMessage {...messages.auctionLabel} />
    } else if(props.importantOnly !== true && (props.player.activeOfferLoan > 0 || props.player.activeOfferPaid > 0 || props.playerContract?.activeOfferLoan > 0 || props.playerContract?.activeOfferPaid > 0)) {
        colour = 'green';
        text = <FormattedMessage {...messages.offerLabel} />
    } else if(props.importantOnly !== true && props.player.interestedClubs > 0) {
        colour = 'blue';
        text = <FormattedMessage {...messages.wantedLabel} />
    } else if(props.importantOnly !== true && (props.playerContract?.transferListed === true || props.playerContract?.loanListed === true)) {
        colour = 'blue';
        text = <FormattedMessage {...messages.listedLabel} />
    } else if(props.importantOnly !== true && !!props.player.isOnLoan) {
        colour = 'purple';
        text = <FormattedMessage {...messages.loanLabel} />
    }

    if(colour === null || text === null) return null;

    return(
        <Label
            size='mini'
            color={colour}
            className='infoLabel'
        >
            {text}
        </Label>
    )
}

const messages = {
    suspensionLabel: {
        id: "Component_InfoLabel.suspensionLabel",
        defaultMessage: "SUS",
        description: "Label for the small box that appears next to a player/staff indicating key info. This label indicates that the player is suspended."
    },
    suspensionTextPlural: {
        id: "Component_InfoLabel.suspensionTextPlural",
        defaultMessage: "Suspended for {suspensionLength} games",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text indicates the length of the suspension (plural)."
    },
    suspensionTextSingular: {
        id: "Component_InfoLabel.suspensionTextSingular",
        defaultMessage: "Suspended for {suspensionLength} game",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text indicates the length of the suspension (singular)."
    },
    injuryLabel: {
        id: "Component_InfoLabel.injuryLabel",
        defaultMessage: "INJ",
        description: "Label for the small box that appears next to a player/staff indicating key info. This label indicates that the player is injured."
    },
    injuryTextSingular: {
        id: "Component_InfoLabel.injuryTextSingular",
        defaultMessage: "Injured for {injuryLength} day",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text indicates the length of the injury (singular)."
    },
    injuryTextPlural: {
        id: "Component_InfoLabel.injuryTextPlural",
        defaultMessage: "Injured for {injuryLength} days",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text indicates the length of the injury (plural)."
    },
    wantedLabel: {
        id: "Component_InfoLabel.wantedLabel",
        defaultMessage: "WNT",
        description: "Label for the small box that appears next to a player/staff indicating key info. This label indicates that the player/staff is wanted by another club."
    },
    wantedTextSingular: {
        id: "Component_InfoLabel.wantedTextSingular",
        defaultMessage: "Wanted by {wantedCount} club",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text indicates the number of clubs interested in the player/staff (singular)."
    },
    wantedTextPlural: {
        id: "Component_InfoLabel.wantedTextPlural",
        defaultMessage: "Wanted by {wantedCount} clubs",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text indicates the number of clubs interested in the player/staff (plural)."
    },
    contractLabel: {
        id: "Component_InfoLabel.contractLabel",
        defaultMessage: "CTR",
        description: "Label for the small box that appears next to a player/staff indicating key info. This label indicates that the player/staff's contract expires at the end of the season."
    },
    contractText: {
        id: "Component_InfoLabel.contractText",
        defaultMessage: "Contract expires at the end of this season",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text explains that the player/staff's contract expires at the end of the season."
    },
    noContractText: {
        id: "Component_InfoLabel.noContractText",
        defaultMessage: "Contract has expired",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text explains that the player/staff's contract has expired"
    },
    unfitLabel: {
        id: "Component_InfoLabel.unfitLabel",
        defaultMessage: "UNF",
        description: "Label for the small box that appears next to a player/staff indicating key info. This label indicates that the player is unfit."
    },
    unfitText: {
        id: "Component_InfoLabel.unfitText",
        defaultMessage: "Needs a rest",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text explains that the player needs a rest."
    },
    loanLabel: {
        id: "Component_InfoLabel.loanLabel",
        defaultMessage: "LOA",
        description: "Label for the small box that appears next to a player/staff indicating key info. This label indicates that the player is on loan."
    },
    loanText: {
        id: "Component_InfoLabel.loanText",
        defaultMessage: "On loan",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text explains that the player is on loan."
    },
    auctionLabel: {
        id: "infoLabel:auction",
        defaultMessage: "AUC",
        description: "Label for the small box that appears next to a player/staff indicating key info. This label indicates that an auction is in progress for the player"
    },
    offerLabel: {
        id: "infoLabel:offer",
        defaultMessage: "BID",
        description: "Label for the small box that appears next to a player/staff indicating key info. This label indicates that a paid/loan offer has been made for a player"
    },
    bidTextSingular: {
        id: "Component_InfoLabel.bidTextSingular",
        defaultMessage: "{offerCount} offer",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text indicates the number of bids for the player/staff (singular)."
    },
    bidTextPlural: {
        id: "Component_InfoLabel.bidTextPlural",
        defaultMessage: "{offerCount} offers",
        description: "Hover text for the small box that appears next to a player/staff indicating key info. This text indicates the number of bids for the player/staff (plural)."
    },
    listedLabel: {
        id: "Component_InfoLabel.listedLabel",
        defaultMessage: "LST",
        description: "Label for the small box that appears next to a player/staff name indicating key info. This label indicates that the player has been placed on the transfer/loan list."
    },
    transferListedText: {
        id: "Component_InfoLabel.transferListedText",
        defaultMessage: "Transfer listed",
        description: "Hoever text for the small box that appears next to a player/staff name indicating key info. This text indicates that the player has been placed on the transfer list"
    },
    loanListedText: {
        id: "Component_InfoLabel.loanListedText",
        defaultMessage: "Loan listed",
        description: "Hoever text for the small box that appears next to a player/staff name indicating key info. This text indicates that the player has been placed on the loan list"
    },
    retiringLabel: {
        id: 'InfoLabel:retiring',
        defaultMessage: 'RET',
        description: 'Label for info label shown when a player is retired or is intending to retire'
    }
}
import { faExchange, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory, useLocation, useParams } from "react-router";
import { Menu } from "semantic-ui-react";
import { useEffect } from "react";
import Profile from "../../toolbar/Profile";
import ContractOffer from '../../toolbar/Contract';
import TransferOfferNew from "../../toolbar/Transfer";
import History from "../../toolbar/History";
import ShortenedPlayerName from "../../common/ShortenedName";
import NarrowLayout from "../../../common/components/NarrowLayout";
import PlayerTransferOffer from './playerTransferOffer/data';
import PlayerLoanOffer from './playerLoanOffer/data';
import { useQuery } from '@tanstack/react-query';
import axios from "axios";

const includeStr = 'includes=playerContract,person'

export default function PlayerTransferOrLoanOffer(props) {
    const {playerId} = useParams();
    const location = useLocation();
    const history = useHistory();

    const getPlayerQuery = useQuery(
        ['getPlayer', playerId, includeStr],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/players/${playerId}/?${includeStr}`)
    );

    useEffect(() => {
        if(getPlayerQuery.data?.data?.response) {
            props.setToolbar({
                title: <><ShortenedPlayerName player={getPlayerQuery.data.data.response} /> offer</>,
                navigation: [
                    <Profile key={1} playerId={playerId} />,
                    <ContractOffer key={2} playerId={playerId} myClubId={props.manager.club?.id} playerClubId={getPlayerQuery.data.data.response.playerContract?.clubId} retirementSeasonId={getPlayerQuery.data.data.response.retirementSeasonId} />,
                    <TransferOfferNew key={3} playerId={playerId} myClubId={props.manager.club?.id} playerClubId={getPlayerQuery.data.data.response.playerContract?.clubId} retirementSeasonId={getPlayerQuery.data.data.response.retirementSeasonId} />,
                    <History key={4} playerId={playerId} />
                ]
            })
        }
    }, [getPlayerQuery.data]);

    return(
        <NarrowLayout>
            <div className='playerTransferOrOfferContainer'>
                <Menu compact pointing>
                    <Menu.Item
                        active={!location.state || location.state === 'transfer'}
                        onClick={() => history.push(location.pathname, 'transfer')}
                    >
                        <FontAwesomeIcon icon={faExchange} className='inlineIcon' />
                        Transfer
                    </Menu.Item>
                    <Menu.Item
                        active={location.state === 'loan'}
                        onClick={() => history.push(location.pathname, 'loan')}
                    >
                        <FontAwesomeIcon icon={faStopwatch} className='inlineIcon' />
                        Loan
                    </Menu.Item>
                </Menu>
            </div>
            {(!location.state || location.state === 'transfer') &&
                <PlayerTransferOffer
                    isLoading={getPlayerQuery.isLoading}
                    player={getPlayerQuery.data?.data?.response}
                    manager={props.manager}
                />
            }
            {location.state === 'loan' &&
                <PlayerLoanOffer
                    isLoading={getPlayerQuery.isLoading}
                    player={getPlayerQuery.data?.data?.response}
                    manager={props.manager}
                />
            }
        </NarrowLayout>
    )
}
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LeagueLink from '../../league/components/common/Link';
import { faListOl } from '@fortawesome/free-solid-svg-icons';

function leagueName(
    resolver,
    hide = 580,
    widths = {}
) {
    return {
        id: 'leagueName',
        name: <FontAwesomeIcon icon={faListOl} />,
        cell: (rowData) => {const d = resolver(rowData); return !!d ? <LeagueLink league={d} /> : ''},
        compact: true,
        grow: 2,
        hide,
        ...widths,
        ignoreRowClick: true
    }
}

export {
    leagueName
}
const outOfPositionPenalties = {
    LB: {
        RB: 1,
        CB: 2,
        LWB: 1,
        RWB: 1,
        CDM: 2,
        LM: 2,
        RM: 2,
        CM: 3,
        LW: 3,
        RW: 3,
        CAM: 4,
        ST: 4
    },
    RB: {
        LB: 1,
        CB: 2,
        LWB: 1,
        RWB: 1,
        CDM: 2,
        LM: 2,
        RM: 2,
        CM: 3,
        LW: 3,
        RW: 3,
        CAM: 4,
        ST: 4
    },
    CB: {
        LB: 2,
        RB: 2,
        LWB: 3,
        RWB: 3,
        CDM: 1,
        LM: 3,
        RM: 3,
        CM: 2,
        LW: 4,
        RW: 4,
        CAM: 4,
        ST: 4
    },
    LWB: {
        LB: 1,
        RB: 1,
        CB: 3,
        RWB: 1,
        CDM: 3,
        LM: 1,
        RM: 1,
        CM: 3,
        LW: 2,
        RW: 2,
        CAM: 4,
        ST:  4
    },
    RWB: {
        LB: 1,
        RB: 1,
        CB: 3,
        LWB: 1,
        CDM: 3,
        LM: 1,
        RM: 1,
        CM: 3,
        LW: 2,
        RW: 2,
        CAM: 4,
        ST:  4
    },
    CDM: {
        LB: 2,
        RB: 2,
        CB: 1,
        LWB: 3,
        RWB: 3,
        LM: 2,
        RM: 2,
        CM: 1,
        LW: 4,
        RW: 4,
        CAM: 3,
        ST: 4 
    },
    LM: {
        LB: 2,
        RB: 2,
        CB: 3,
        LWB: 1,
        RWB: 1,
        CDM: 2,
        RM: 1,
        CM: 1,
        LW: 1,
        RW: 1,
        CAM: 3,
        ST: 4 
    },
    RM: {
        LB: 2,
        RB: 2,
        CB: 3,
        LWB: 1,
        RWB: 1,
        CDM: 2,
        LM: 1,
        CM: 1,
        LW: 1,
        RW: 1,
        CAM: 3,
        ST: 4
    },
    CM: {
        LB: 3,
        RB: 3,
        CB: 2,
        LWB: 3,
        RWB: 3,
        CDM: 1,
        LM: 1,
        RM: 1,
        LW: 3,
        RW: 3,
        CAM: 1,
        ST: 4 
    },
    LW: {
        LB: 3,
        RB: 3,
        CB: 4,
        LWB: 2,
        RWB: 2,
        CDM: 4,
        LM: 1,
        RM: 1,
        CM: 3,
        RW: 1,
        CAM: 2,
        ST: 2
    },
    RW: {
        LB: 3,
        RB: 3,
        CB: 4,
        LWB: 2,
        RWB: 2,
        CDM: 4,
        LM: 1,
        RM: 1,
        CM: 3,
        LW: 1,
        CAM: 2,
        ST: 2
    },
    CAM: {
        LB: 4,
        RB: 4,
        CB: 4,
        LWB: 4,
        RWB: 4,
        CDM: 3,
        LM: 3,
        RM: 3,
        CM: 1,
        LW: 2,
        RW: 2,
        ST: 2
    },
    ST: {
        LB: 4,
        RB: 4,
        CB: 4,
        LWB: 4,
        RWB: 4,
        CDM: 4,
        LM: 4,
        RM: 4,
        CM: 4,
        LW: 2,
        RW: 2,
        CAM: 2
    }
}

export default outOfPositionPenalties;
import {Link, generatePath} from "react-router-dom";
import {URL} from "../../../common/lib/paths.js";
import ClubName from "./Name.js";

export default function ClubLink(props) {
    if(!props.club) return '-';

    return(
        <span style={{width: '100%'}}>
            <Link
                style={props.highlight ? {fontWeight: 'bold'} : {}}
                to={generatePath(URL.clubProfile, {clubId: props.club.id})}
                className={!props.inline ? 'noTextwrap' : undefined}
            >
                <ClubName
                    {...props}
                />
            </Link>
        </span>
    );
}
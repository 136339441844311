import { defineMessages } from "@formatjs/intl";
import { compileMessage } from "./util.js"

const messages = {
    'favPlayer:playerTransferListed': defineMessages(compileMessage({
        subject: {
            id: 'favPlayer:playerTransferListed',
            defaultMessage: '{playerName} transfer listed',
            description: 'Subject for the message template which a user receives when a player on their favPlayer has been transfer listed'
        },
        body: {
            id: 'favPlayer:playerTransferListed',
            defaultMessage: '{playerName}, who is on your favourites list, has been transfer listed by their club {clubName}.',
            description: 'message body for the message template which a user receives when a player on their favourites list has been transfer listed'
        }
    })),
    'favPlayer:playerLoanListed': defineMessages(compileMessage({
        subject: {
            id: 'favPlayer:playerLoanListed',
            defaultMessage: '{playerName} loan listed',
            description: 'Subject for the message template which a user receives when a player on their favourites list has been loan listed'
        },
        body: {
            id: 'favPlayer:playerLoanListed',
            defaultMessage: '{playerName}, who is on your favourites list, has been loan listed by their club {clubName}.',
            description: 'message body for the message template which a user receives when a player on their favourites list has been loan listed'
        }
    })),
    'favPlayer:player:contractExpired': defineMessages(compileMessage({
        subject: {
            id: 'favPlayer:player:contractExpired',
            defaultMessage: '{playerName} contract expired',
            description: 'Subject for the message template which a user receives when a player on their favourites list contact expires'
        },
        body: {
            id: 'favPlayer:player:contractExpired',
            defaultMessage: '{playerName}, who is on your favourites list, is now a free agent following the expiry of their contract at {clubName}.',
            description: 'message body for the message template which a user receives when a player on their favourites list contract expires'
        }
    })),
}

export default messages;
import {useEffect} from 'react';
import {useLocation, useRouteMatch} from 'react-router-dom';
import ReactGA from 'react-ga4';

export default (props) => {
    const location = useLocation();
    const match = useRouteMatch();

    useEffect(() => {
        console.log('page view triggered');
        console.log(match.path);
        ReactGA.send({
            hitType: 'pageview',
            page: match.path
        });
    }, [location.path]);

    return props.children;
}
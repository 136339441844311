import StaffLink from "../../../staff/common/Link"

function staffName(
    staffResolver,
    staffLinkSearch,
    staffLinkClassName
) {
    return {
        id: 'staffName',
        name: 'Staff',
        selector: (rowData, i) => 
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}} data-tag='allowRowEvents'>
                <div className='playerName'>
                    <StaffLink
                        staff={staffResolver(rowData)}
                        search={staffLinkSearch ? staffLinkSearch(rowData, i) : undefined}
                        className={staffLinkClassName ? staffLinkClassName(rowData, i) : undefined}
                    />
                </div>
            </div>,
        wrap: false,
        compact: true,
        grow: 3,
        ignoreRowClick: true,
        style: {
            '&[role="cell"]': {
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    }
}

export {
    staffName
}
import { generatePath } from "react-router";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useTutorial } from "../../tutorial/hooks/useTutorial.js";

const messages = {
    text: {
        id: 'transfers:toolbar:scouting',
        defaultMessage: 'Scouting',
        description: 'Link for scoutingy page'
    }
}

export default function Scouting(props) {
    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('transfers', 10);

    return(
        <ToolbarItem
            path={{
                pathname: generatePath(URL.transferScouting),
                search: isTutorial1 && getSearchParamsString('transfers', 11)
            }}
            icon={faMagnifyingGlass}
            messages={messages}
            activeToolbarItemRef={props.activeToolbarItemRef}
            className={isTutorial1 && 'glow'}
        />
    )
}
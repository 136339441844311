import assistIcon from '../../../player/common/assists-icon.png';
import DataTable from '../../../common/components/DataTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faFutbol, faHand, faShirt, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import usePersistedState from '../../../common/lib/usePersistedState';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {Button} from 'semantic-ui-react';
import SUIIconShim from '../../../common/components/SUIIconShim';
import { playerName } from '../../../common/dataTableColumns/player/playerName';
import { playerPosition } from '../../../common/dataTableColumns/player/playerPosition';
import { clubName } from '../../../common/dataTableColumns/club/clubName';
import { currentAbility } from '../../../common/dataTableColumns/currentAbility';
import { statColumn } from '../../../common/dataTableColumns/player/PTLSStat';

export default function StatsTable(props) {
    const {persistedState, updatePersistedState} = usePersistedState(props.persistedStateKey, {page: 1, stat: 'goalsScored'});

    const columns = [
        playerName(
            (PTLS) => PTLS.player,
            () => null,
            () => null,
            () => null,
            undefined,
            undefined,
            undefined
        ),
        playerPosition(
            (PTLS) => PTLS.player.position
        ),
        clubName(
            props.clubResolver,
            374
        ),
        currentAbility(
            (PTLS) => PTLS.player.currentAbility,
            420
        ),
        statColumn(
            'appearances',
            (PTLS) => PTLS.appearances,
            <FontAwesomeIcon icon={faShirt} />,
            500
        ),
        statColumn(
            persistedState.stat,
            statResolvers[persistedState.stat],
            statColumnNames[persistedState.stat],
            undefined,
            [
                'shotsOnTargetP',
                'shotConversion',
                'savesToShotsFaced',
            ].includes(persistedState.stat)
        )
    ]

    return(
        <>
            <DataTable
                columns={columns}
                data={props.playerTeamLeagueSeasons}
                isLoading={props.playerTeamLeagueSeasonsQueryIsLoading}
                placeholderCount={10}
            />
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Button.Group>
                    <Button
                        icon
                        disabled={persistedState.page === 1}
                        onClick={() => updatePersistedState({page: persistedState.page-1, stat: persistedState.stat})}
                        color='black'
                    ><SUIIconShim><FontAwesomeIcon icon={faArrowLeft} /></SUIIconShim></Button>
                    <Button disabled className="opacity-override" color='black' style={{width: '10em'}}>Page {persistedState.page}</Button>
                    <Button
                        icon
                        disabled={props.playerTeamLeagueSeasons?.length < 10}
                        onClick={() => updatePersistedState({page: persistedState.page+1, stat: persistedState.stat})}
                        color='black'
                    ><SUIIconShim><FontAwesomeIcon icon={faArrowRight} /></SUIIconShim></Button>
                </Button.Group>
            </div>
        </>
    );
}

const statColumnNames = {
    goalsScored: <FontAwesomeIcon icon={faFutbol} />,
    assists: <img src={assistIcon} style={{width: '16px', height: '16px'}} />,
    averageMatchRating: <FontAwesomeIcon icon={faThumbsUp} />,
    chancesCreated90: 'Chances',
    totalShots90: 'Shots',
    totalShotsOnTarget90: 'SOT',
    shotsOnTargetP: 'SOT',
    shotConversion: 'SC',
    totalSaves90: <FontAwesomeIcon icon={faHand} />,
    goalsConceded90: 'GC',
    savesToShotsFaced: 'S %',
    shotsFaced90: 'SF',
    cleanSheets: <FontAwesomeIcon icon={faBan} />
}

const statResolvers = {
    goalsScored: (PTLS) => PTLS.goalsScored,
    assists: (PTLS) => PTLS.assists,
    averageMatchRating: (PTLS) => parseFloat(PTLS.averageMatchRating),
    chancesCreated90: (PTLS) => parseFloat(PTLS.chancesCreated90),
    totalShots90: (PTLS) => parseFloat(PTLS.totalShots90),
    totalShotsOnTarget90: (PTLS) => parseFloat(PTLS.totalShotsOnTarget90),
    shotsOnTargetP: (PTLS) => parseInt(PTLS.shotsOnTargetP),
    shotConversion: (PTLS) => parseInt(PTLS.shotConversion),
    totalSaves90: (PTLS) => parseFloat(PTLS.totalSaves90),
    goalsConceded90: (PTLS) => parseFloat(PTLS.goalsConceded90),
    savesToShotsFaced: (PTLS) => parseInt(PTLS.savesToShotsFaced),
    shotsFaced90: (PTLS) => parseFloat(PTLS.shotsFaced90),
    totalCleanSheets: (PTLS) => PTLS.totalCleanSheets
}
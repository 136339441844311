import {useEffect} from 'react';
import {useQuery} from '@tanstack/react-query';
import axios from 'axios';
import {useLocation, useParams} from 'react-router-dom';

import usePersistedState from '../../../common/lib/usePersistedState';

import StatsTable from '../../../leagueSeason/playerTeamLeagueSeason/view/interface';
import CupName from '../../name/CupName';
import { FormattedMessage } from 'react-intl';
import CupFixturesToolbarItem from '../../toolbar/Fixtures';
import CupStatsToolbarItem from '../../toolbar/Stats';
import CupHistoryToolbarItem from '../../toolbar/History';

const PERSISTED_STATE_KEY = 'playerTeamCupSeasons';

export default function StatsTableData(props) {
    const location = useLocation();
    const {cupId} = useParams();
    const {persistedState} = usePersistedState(PERSISTED_STATE_KEY, {page: 1, stat: 'goalsScored'});
    
    const cupSeasonId = location.state?.cupSeasonId;

    const playerTeamCupSeasonsQuery = useQuery(
        ['getCupSeasonPlayerTeamCupSeasons', cupSeasonId, persistedState.stat, persistedState.page],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cupSeasons/${cupSeasonId}/playerTeamCupSeasons?stat=${persistedState.stat}&page=${persistedState.page}`),
        {
            keepPreviousData: true
        }
    );

    useEffect(() => {
        if(!!playerTeamCupSeasonsQuery.data?.data?.response) {
            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{cupName: <CupName cup={playerTeamCupSeasonsQuery.data.data.response.cup} />}} />,
                navigation: [
                    <CupFixturesToolbarItem key={1} cupId={cupId} />,
                    <CupStatsToolbarItem key={2} cupId={cupId} />,
                    <CupHistoryToolbarItem key={3} cupId={cupId} />
                ]
            });
        }
    }, [playerTeamCupSeasonsQuery?.data?.data?.response]);

    return(
        <StatsTable
            playerTeamLeagueSeasonsQueryIsLoading={playerTeamCupSeasonsQuery.isLoading}
            playerTeamLeagueSeasons={playerTeamCupSeasonsQuery.data?.data?.response?.playerTeamCupSeasons}
            highlightTeamIds={props.highlightTeamIds}
            persistedStateKey={PERSISTED_STATE_KEY}
            clubResolver={(PTCS) => PTCS.TCS.team.club}
        />
    )
}

const messages = {
    screenTitle: {
        id: 'test',
        defaultMessage: '{cupName} stats',
        description: 'todo'
    }
}
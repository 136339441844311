import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../../common/lib/paths.js";
import { faSliders, faUserGroup } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "toolbarItem:teamTactics",
		defaultMessage: "Tactics",
		description: "Link for team tactics page"
	}
})

export default function TeamTacticsToolbarItem(props) {
	if(!props.isMyClub) return null;

	return(
		<ToolbarItem
			path={generatePath(URL.teamTactics, {teamType: props.teamType})}
			icon={faSliders}
			messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
		/>
	);
}
import {Segment, Form, Header, Placeholder, Divider} from 'semantic-ui-react';
import {useHistory, generatePath, Link, useLocation} from 'react-router-dom';
import times from 'lodash/times';

import LeagueLink from '../../league/components/common/Link';
import {URL} from '../../common/lib/paths';
import FullNationNameWithFlag from '../../nation/common/FullNationNameWithFlag';

import {useTutorial} from '../../tutorial/hooks/useTutorial';

export default function NationLeagueInterface(props) {
    const history = useHistory();
    const location = useLocation();
    const {checkTutorial} = useTutorial();

    const isTutorial1 = checkTutorial('world', 2);
    const isTutorial2 = checkTutorial('world', 3);

    return(
        <>
            <Segment
                inverted
            >
                <Form inverted>
                    <Form.Group unstackable inline widths={3} style={{margin: 0}}>
                        <Form.Select
                            options={props.nationLeagueLevels ? times(props.nationLeagueLevels, (level) => (
                                {
                                    key: level+1,
                                    text: "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(level),
                                    value: level+1
                                }
                            )) : []}
                            value={props.leagueLevel}
                            onChange={(e, {value}) => history.replace({
                                pathname: generatePath(URL.nationLeagues, {nationId: props.nation.id}),
                                state: {leagueLevel: value},
                                search: location.search
                            })}
                            loading={props.nationQueryIsLoading}
                            disabled={props.nationQueryIsLoading}
                            label='Level'
                            fluid
                            className={isTutorial1 && 'glow'}
                        />
                        <Form.Input
                            placeholder='League name'
                            value={props.searchValue}
                            onChange={(e, {value}) => props.setSearchValue(value)}
                            disabled={props.nationLeaguesQueryIsLoading}
                            label='Search'
                            fluid
                            className={isTutorial2 && 'glow'}
                        />
                        <Form.Button
                            content='Search'
                            onClick={props.handlePerformSearch}
                            disabled={props.nationLeaguesQueryIsLoading}
                            fluid
                            label='&nbsp;'
                        />
                    </Form.Group>
                </Form>
            </Segment>
            <Segment>
                {!props.nationLeaguesQueryIsLoading ?
                    <Header as='h2'><FullNationNameWithFlag nation={props.nation} /> League {"ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(props.leagueLevel-1)}</Header>
                    :
                    <Placeholder>
                        <Placeholder.Header>
                            <Placeholder.Line />
                        </Placeholder.Header>
                    </Placeholder>
                }
                <Divider active />
                {!props.nationLeaguesQueryIsLoading ?
                    props.nationLeagues.length !== 0 ?
                        props.nationLeagues.map((league) => (
                            <><LeagueLink league={league} /><br /></>
                        ))
                        :
                        <p>No leagues found</p>
                    :
                    <Placeholder>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((index) => (
                            <Placeholder.Line key={index} />
                        ))}
                    </Placeholder>
                }
            </Segment>
        </>
    );
}
import axios from "axios";
import { useEffect, useMemo } from "react";
import { useQuery } from '@tanstack/react-query';
import { Redirect, useHistory, useLocation, useParams } from "react-router";
import ClubName from "../common/Name";
import ClubTransferHistoryInterface from "./interface";
import TeamPlayersToolbarItem from "../toolbar/TeamPlayers";
import TeamTacticsToolbarItem from "../toolbar/TeamTactics";
import TeamTrainingToolbarItem from "../toolbar/TeamTraining";
import TeamFixturesToolbarItem from "../toolbar/TeamFixtures";
import TeamLeagueToolbarItem from "../toolbar/TeamLeague";
import ClubStaffToolbarItem from "../toolbar/ClubStaff.js";
import ClubTransfersToolbarItem from "../toolbar/ClubTransfers";
import TeamLeagueSeasonsToolbarItem from "../toolbar/TeamLeagueSeasons";
import { FormattedMessage } from "react-intl";
import ClubProfileToolbarItem from "../toolbar/ClubProfile.js";

export default function ClubTransferHistory(props) {
    const {clubId} = useParams();
    const location = useLocation();
    const history = useHistory();

    const teamLeagueSeasonsQuery = useQuery(
        ['getTeamLeagueSeasons', clubId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/teams/first/leagueSeasons?includeTLS=false&includeActiveSeason=true`),
        {
            onSuccess: (data) => {
                if(location.state?.seasonId === undefined) {
                    history.replace(
                        {
                            pathname: location.pathname,
                            state: {...location.state, seasonId: data.data.response.TLS[0].LS.season.id}
                        }
                    )
                }
            },
            enabled: clubId !== undefined
        }
    );

    const clubTransferHistoryQuery = useQuery(
        ['getClubTransferHistory', clubId, location.state?.seasonId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${clubId}/transferHistory?seasonId=${location.state?.seasonId}`),
        {
            enabled: location.state?.seasonId !== undefined && clubId !== undefined
        }
    );

    const seasons = useMemo(() => {
        if(!teamLeagueSeasonsQuery.data?.data?.response) {
            return undefined;
        } else {
            return teamLeagueSeasonsQuery.data?.data?.response.TLS.map((TLS) => TLS.LS.season);
        }
    }, [teamLeagueSeasonsQuery.data?.data?.response]);

    useEffect(() => {
        if(!!teamLeagueSeasonsQuery.data?.data?.response) {
            const isOwner = !!props.manager.club && (parseInt(clubId) === props.manager.club.id);

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{clubName: <ClubName club={teamLeagueSeasonsQuery.data.data.response.club} />}} />,
                navigation: [
                    <ClubProfileToolbarItem clubId={clubId} key={1} />,
                    <TeamPlayersToolbarItem clubId={clubId} teamType='first' key={2} />,
                    <TeamTacticsToolbarItem isMyClub={isOwner} teamType='first' key={3} />,
                    <TeamTrainingToolbarItem isMyClub={isOwner} teamType='first' key={4} />,
                    <TeamFixturesToolbarItem clubId={clubId} teamType='first' key={5} />,
                    <TeamLeagueToolbarItem clubId={clubId} teamType='first' key={6} />,
                    <ClubStaffToolbarItem clubId={clubId} key={7} />,
                    <ClubTransfersToolbarItem clubId={clubId} key={8} />,
                    <TeamLeagueSeasonsToolbarItem clubId={clubId} teamType='first' key={9} />
                ]
            });
        }
    }, [teamLeagueSeasonsQuery.data?.data?.response]);

    if(location.state?.view === undefined) {
        return(
            <Redirect
                to={{
                    pathname: location.pathname,
                    state: {
                        view: 'incoming'
                    }
                }}
            />
        );
    }

    return(
        <ClubTransferHistoryInterface
            seasonsQueryIsLoading={teamLeagueSeasonsQuery.isLoading}
            transferHistoryQueryIsLoading={clubTransferHistoryQuery.isLoading}
            seasons={seasons}
            transferHistory={{
                incoming: clubTransferHistoryQuery.data?.data?.response.incoming,
                outgoing: clubTransferHistoryQuery.data?.data?.response.outgoing
            }}
        />
    )
}

const messages = {
    screenTitle: {
        id: 'todo',
        defaultMessage: '{clubName} transfer history',
        descripion: 'todo'
    }
}
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import ClubPlayerTransferAuctionFreeInterface from "./interface";
import usePersistedState from "../../../../common/lib/usePersistedState";

export default function ClubPlayerTransferAuctionFree(props) {
    const {persistedState} = usePersistedState('myTransferOffers', {view: 'paidAuctions', activeOnly: false});

    const getClubPlayerTransferAuctionFree = useQuery(
        [
            'getClubPlayerTransferAuctionFree',
            persistedState.activeOnly
        ],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/playerTransferAuctionFree?activeOnly=${persistedState.activeOnly}`)
    );

    return(
        <ClubPlayerTransferAuctionFreeInterface
            isLoading={getClubPlayerTransferAuctionFree.isLoading}
            playerTransferAuctionFree={getClubPlayerTransferAuctionFree.data?.data?.response}
            manager={props.manager}
        />
    )
}
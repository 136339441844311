import { useMemo, useReducer, createContext, useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { cloneDeep } from "lodash";
import * as tutorialTopics from '../tutorials'
import { useQueryClient } from "@tanstack/react-query";

const TutorialContext = createContext();

export function TutorialProvider({children}) {
    const [tutorialCache, tutorialCacheDispatch] = useReducer(tutorialReducer, {});

    return(
        <TutorialContext.Provider
            value={{tutorialCache, tutorialCacheDispatch}}
        >
            {children}
        </TutorialContext.Provider>
    )
}

function tutorialReducer(currentTutorialState, action) {
    let newTutorialState = cloneDeep(currentTutorialState);

    console.log(currentTutorialState);
    console.log(action);

    switch(action.name) {
        case 'addTopic':
            newTutorialState[action.value?.key] = action.value.data
            break;
    }

    return newTutorialState;
}

export function useTutorial() {
    const {tutorialCache, tutorialCacheDispatch} = useContext(TutorialContext);
    const location = useLocation();
    const history = useHistory();
    const queryClient = useQueryClient();

    const {searchParams, tutorialKey, tutorialStep} = useMemo(() => {
        const searchParams = new URLSearchParams(location.search);

        const tutorialKey = searchParams.get('tutorialTopic');
        let tutorialStep = parseInt(searchParams.get('tutorialStep'));

        if(isNaN(tutorialStep)) {
            tutorialStep = null;
        } else {
            tutorialStep = parseInt(tutorialStep);
        }

        return {
            searchParams,
            tutorialKey,
            tutorialStep
        }
    }, [location.search]);

    const isTutorialActive = tutorialKey !== null && tutorialStep !== null;

    function getSearchParamsString(tutorialKey, step) {
        const params = new URLSearchParams();

        params.set('tutorialTopic', tutorialKey);
        params.set('tutorialStep', step);

        return params.toString();
    }

    function getCached(key, redirectOnMiss = true) {
        if(key in tutorialCache === false && redirectOnMiss === true) {
            history.push({
                pathname: tutorialTopics[key].loaderPath
            });
        }

        return tutorialCache[key]
    }

    function setCached(key, data) {
        tutorialCacheDispatch({name: 'addTopic', value: {key, data}});
    }

    function goNextStep() {
        if(!isTutorialActive) {
            throw new Error('tutorial not active');
        }

        const s = tutorialStep+1
        const p = tutorialCache[tutorialKey]?.steps[s];

        _navigate(p.path, getSearchParamsString(tutorialKey, s));
        p.updatePersistedState !== undefined && p.updatePersistedState(queryClient);
    }

    function goPrevStep() {
        if(!isTutorialActive) {
            throw new Error('tutorial not active');
        }

        const s = tutorialStep-1
        const p = tutorialCache[tutorialKey]?.steps[s];
    
        _navigate(p.path, getSearchParamsString(tutorialKey, s));
        p.updatePersistedState !== undefined && p.updatePersistedState(queryClient)
    }
    
    function exitTutorial() {
        history.push({
            pathname: location.pathname,
            state: location.state,
            search: undefined
        });
    }

    function _navigate(path, searchParams) {
        history.push({
            pathname: path.pathname,
            state: {
                ...location.state,
                ...path.state
            },
            search: searchParams
        });
    }

    function checkTutorial(targetTutorialKey, targetStepKey) {
        return targetTutorialKey === tutorialKey && targetStepKey === tutorialCache[tutorialKey]?.steps[tutorialStep]?.key;
    }

    return {
        searchParams,
        isTutorialActive,
        tutorialKey,
        tutorialStep,
        getSearchParamsString,
        getCached,
        setCached,
        goNextStep,
        goPrevStep,
        exitTutorial,
        checkTutorial
    };
}
import { Point } from "pixi.js";

const orientations = {
    LEFT: 'left',
    RIGHT: 'right'
}

const sizes = {
    pitch: {
        x: 100,
        y: 60
    },
    ballRadius: 6,
    playerRadius: 14
}

function getTeamInitial(orientation) {
    if(orientation === orientations.LEFT) {
        return 'L';
    } else if(orientation === orientations.RIGHT) {
        return 'R';
    }
}

/*

1 - 2
|   |
4 - 3

*/
const shootingZones = [
    {
        bounds: [
            new Point(92, 23),  //8 x 37
            new Point(98, 37)   //2 x 23
        ],
        domainWeight: 1
    },
    {
        bounds: [
            new Point(85, 19),
            new Point(95, 41)
        ],
        domainWeight: 0.8
    },
    {
        bounds: [
            new Point(92, 19),
            new Point(95, 41)
        ],
        domainWeight: 0.6
    },
    {
        bounds: [
            new Point(81, 14),
            new Point(95, 46)
        ],
        domainWeight: 0.6
    },
    {
        bounds: [
            new Point(76, 10),
            new Point(92, 50)
        ],
        domainWeight: 0.4
    },
    {
        bounds: [
            new Point(72, 10),
            new Point(76, 50)
        ],
        domainWeight: 0.2
    }
]

const locations = {
    leftGoal: new Point(0, sizes.pitch.y/2),
    rightGoal: new Point(sizes.pitch.x, sizes.pitch.y/2),
    leftDangerZone: [
        new Point(80, 15),
        new Point(95, 45)
    ],
    rightDangerZone: [
        new Point(15, 5),
        new Point(20, 45)
    ]
}

const movementTypes = {
    /*
        Once a dribble is in progress, nothing should stop it. Always wait for it to finish.
    */
    DRIBBLE: 'dribble',
    /*
        Onside movement can end early if player becomes on-side due to movement of last defender
    */
    ONSIDE: 'onside',
    /*
        Move into space is a tricky one. Not ending it means players have a tendency to wander off
        If you do end it then player movement can appear erratic.
        
        You probably want to end it under certain conditions:
            A) player strays offside
            B) player gets too close to teammate
            C) player marker changes and distance is too close
            D) player goes too far from targetX
    */
    INTO_SPACE: 'intoSpace',
    /*
        Defender movement, never interrupted
    */
    DEFENSIVE_LINE: 'defensiveLine',
    /*
        Defender movement, never interrupted
    */
    MARKING: 'marking',
    /*
        Defender movement, never interrupted
    */
    SHAPE: 'shape',
    /*
        Receiving of a pass, can never be interrupted
    */
    IN_BEHIND: 'inBehind',
    JOSTLE: 'jostle',
    /*
        N/A - ball movements
    */
    PASS_TO_FEET: 'passToFeet',
    PASS_IN_BEHIND: 'passInBehind',
    SHOT: 'shot',
    ATTEMPT_SAVE: 'attemptSave'
}

const ballSpeed = 20 / 1000; //pixels per second
const shotSpeed = 50 / 1000;
const runWithBallSpeed = 12 / 1000; //pixels per second
const runWithoutBallSpeed = 10 / 1000; //pixels per second

function getPassSpeed(distance) {
    if(!distance) throw new Error('e');

    return ballSpeed+(((distance/10)*0.005)-0.01);
}

function getDribbleSpeed() {
    return runWithBallSpeed// + (getRandomInt(-5, 5) / 10000);
}

function getRunningSpeed() {
    return runWithoutBallSpeed// + (getRandomInt(-5, 5) / 10000);
}

function getShotSpeed() {
    return shotSpeed + (getRandomInt(-20, 5) / 1000)
}

function convertPointForOrientation(point, orientation) {
    return new Point(
        convertXForOrientation(point.x, orientation),
        convertYForOrientation(point.y, orientation)
    );
}

function convertXForOrientation(x, orientation) {
    if(orientation === orientations.LEFT) {
        return x;
    } else if(orientation === orientations.RIGHT) {
        return sizes.pitch.x - x;
    }
}

function convertYForOrientation(y, orientation) {
    if(orientation === orientations.LEFT) {
        return y;
    } else if(orientation === orientations.RIGHT) {
        return sizes.pitch.y - y;
    }
}

const playerPositions = {
    LB: {
        shape: {
            x: 25,
            y: [1, 15]
        },
        bound: 10,
        dribbleYTargets: [5, 10, 15],
        possessionWeights: {
            shot: 1,
            jostle: 0.4,
            dribble: 0.2,
            progressivePass: 0.2,
            regressivePass: 0.6,
            holdPossession: 0.6
        },
        dribblingTargets: [
            [25, 5],
            [25, 15]
        ],
        targetXScaler: [4, 1.3] //0 = in possession, 1 = out of possession
    },
    CB: {
        shape: {
            x: 25,
            y: [15, 45]
        },
        bound: 10,
        dribbleYTargets: [20, 30, 40],
        possessionWeights: {
            shot: 1,
            jostle: 0.4,
            dribble: 0.1,
            progressivePass: 0.1,
            regressivePass: 0.7,
            holdPossession: 0.7
        },
        targetXScaler: [4, 1.3] //0 = in possession, 1 = out of possession
    },
    RB: {
        shape: {
            x: 25,
            y: [45, 59]
        },
        bound: 10,
        dribbleYTargets: [35, 45, 55],
        possessionWeights: {
            shot: 1,
            jostle: 0.4,
            dribble: 0.2,
            progressivePass: 0.2,
            regressivePass: 0.6,
            holdPossession: 0.6
        },
        targetXScaler: [4, 1.3] //0 = in possession, 1 = out of possession
    },
    LWB: {
        shape: {
            x: 40,
            y: [1, 15]
        },
        bound: 10,
        dribbleYTargets: [5, 10, 15],
        possessionWeights: {
            shot: 1,
            jostle: 0.5,
            dribble: 0.3,
            progressivePass: 0.4,
            regressivePass: 0.3,
            holdPossession: 0.3
        },
        targetXScaler: [3, 1.3] //0 = in possession, 1 = out of possession
    },
    CDM: {
        shape: {
            x: 40,
            y: [15, 45]
        },
        bound: 10,
        dribbleYTargets: [20, 30, 40],
        possessionWeights: {
            shot: 1,
            jostle: 0.5,
            dribble: 0.3,
            progressivePass: 0.4,
            regressivePass: 0.3,
            holdPossession: 0.3
        },
        targetXScaler: [3, 1.3] //0 = in possession, 1 = out of possession
    },
    RWB: {
        shape: {
            x: 40,
            y: [45, 59]
        },
        bound: 10,
        dribbleYTargets: [35, 45, 55],
        possessionWeights: {
            shot: 1,
            jostle: 0.5,
            dribble: 0.3,
            progressivePass: 0.4,
            regressivePass: 0.3,
            holdPossession: 0.3
        },
        targetXScaler: [3, 1.3] //0 = in possession, 1 = out of possession
    },
    LM: {
        shape: {
            x: 50,
            y: [1, 15]
        },
        bound: 10,
        dribbleYTargets: [5, 10, 15],
        possessionWeights: {
            shot: 1,
            jostle: 0.6,
            dribble: 0.3,
            progressivePass: 0.4,
            regressivePass: 0.2,
            holdPossession: 0.2
        },
        targetXScaler: [3, 1.3] //0 = in possession, 1 = out of possession
    },
    CM: {
        shape: {
            x: 50,
            y: [15, 45]
        },
        bound: 10,
        dribbleYTargets: [20, 30, 40],
        possessionWeights: {
            shot: 1,
            jostle: 0.6,
            dribble: 0.3,
            progressivePass: 0.4,
            regressivePass: 0.2,
            holdPossession: 0.2
        },
        targetXScaler: [3, 1.3] //0 = in possession, 1 = out of possession
    },
    RM: {
        shape: {
            x: 50,
            y: [45, 59]
        },
        bound: 10,
        dribbleYTargets: [35, 45, 55],
        possessionWeights: {
            shot: 1,
            jostle: 0.6,
            dribble: 0.3,
            progressivePass: 0.4,
            regressivePass: 0.2,
            holdPossession: 0.2
        }
    },
    LW: {
        shape: {
            x: 75,
            y: [1, 20]
        },
        bound: 10,
        dribbleYTargets: [10, 20, 30],
        possessionWeights: {
            shot: 1,
            jostle: 1,
            dribble: 0.6,
            progressivePass: 0.4,
            regressivePass: 0.2,
            holdPossession: 0.2
        }
    },
    CAM: {
        shape: {
            x: 65,
            y: [15, 45]
        },
        bound: 10,
        dribbleYTargets: [20, 30, 40],
        possessionWeights: {
            shot: 1,
            jostle: 1,
            dribble: 0.4,
            progressivePass: 0.4,
            regressivePass: 0.2,
            holdPossession: 0.2
        }
    },
    RW: {
        shape: {
            x: 75,
            y: [40, 59]
        },
        bound: 10,
        dribbleYTargets: [30, 40, 50],
        possessionWeights: {
            shot: 1,
            jostle: 1,
            dribble: 0.6,
            progressivePass: 0.4,
            regressivePass: 0.2,
            holdPossession: 0.2
        }
    },
    ST: {
        shape: {
            x: 75,
            y: [15, 45]
        },
        bound: 10,
        dribbleYTargets: [20, 30, 40],
        possessionWeights: {
            shot: 1,
            jostle: 1,
            dribble: 0.4,
            progressivePass: 0.4,
            regressivePass: 0.2,
            holdPossession: 0.2
        }
    }
}

//inclusive of min and max
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function probability(probability) {
    return Math.random() < probability;
}

function weightedRandom(probabilities, debug) {
    if(debug === true) {
        console.log(probabilities);
    }

    let totalWeight = 0;

    for(let i=0;i<probabilities.length;i++) {
        totalWeight += probabilities[i].probability;
    }

    if(totalWeight.toFixed(2) !== '1.00') {
        throw new Error(`Total probability weight must equal 1 but got ${totalWeight}`);
    }

    let random = Math.random() * totalWeight;

    for(let i=0;i<probabilities.length;i++) {
        if(random < probabilities[i].probability) {
            return probabilities[i];
        }

        random -= probabilities[i].probability;
    }

    throw new ApplicationException('Could not select random weighted entity')
}

function normaliseValue(value, max) {
    return value / max;
}

function constrainValue(value, min, max) {
    return Math.min(Math.max(value, min), max);
}

class ApplicationException extends Error {
    constructor(error) {
        super(error);

        this.name = this.constructor.name;

        if(Error.captureStackTrace) {
            Error.captureStackTrace(this, this.constructor);
        }
    }
}

function isApplicationException(error) {
    return error instanceof ApplicationException;
}

export {
    sizes,
    orientations,
    locations,
    ballSpeed,
    shotSpeed,
    runWithBallSpeed,
    runWithoutBallSpeed,
    playerPositions,
    movementTypes,
    shootingZones,
    getTeamInitial,
    getRandomInt,
    getDribbleSpeed,
    getRunningSpeed,
    getShotSpeed,
    getPassSpeed,
    probability,
    weightedRandom,
    normaliseValue,
    constrainValue,
    isApplicationException,
    convertPointForOrientation,
    convertXForOrientation,
    convertYForOrientation,
    ApplicationException
}
import axios from "axios";
import { getFilterQueryParams, getFilterStateFromPersistedState } from '../club/filter/lib';
import { useQuery } from "@tanstack/react-query";

export default function useGetClubs(filterState, favClubs, enabled, nationId) {
    return useQuery(
        [
            'getClubs',
            ...getFilterStateFromPersistedState(filterState)
        ],
        () => fetchClubs(filterState, favClubs, nationId),
        {
            keepPreviousData: true,
            enabled
        }
    );
}

function fetchClubs(filterState, favClubs, nationId) {
    const filterParams = getFilterQueryParams(filterState, favClubs, nationId);

    return axios.get(`${process.env.REACT_APP_APPHOST}/clubs?${filterParams.join('&')}`);
}
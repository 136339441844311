import { faSliders } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/lib/paths";

export default {
    key: 'tactics',
    name: 'Tactics',
    loaderPath: URL.tacticsTutorial,
    icon: faSliders,
    description: 'Tweak your tactics',
    steps: [
        {
            key: 0,
            path: URL.teamTactics,
            description: (
                <>
                    <p>Okay first off, select the icon of “3 people behind a line” this dropdown is every formation currently in the game.</p>
                    <p>Your team should already be in a formation that suits the current crop of players so don't think you need to make a decision now.</p>
                </>
            ),
            summary: 'Change formation',
            task: 'browse'
        },
        {
            key: 10,
            path: URL.teamTactics,
            description: 'Note the "mentality" drop down next to "formation". These change the way your team plays. For example if you think you might lose, switch to defensive to try and get a point.',
            summary: 'Select mentality',
            task: 'browse'
        },
        {
            key: 14,
            path: URL.teamTactics,
            description: 'Next select the C in a circle icon',
            summary: 'Select icon',
            task: 'interaction'
        },
        {
            key: 15,
            path: URL.teamTactics,
            description: 'Use the drop down arrows for the three shown columns to pick who you want to be your captain, Set piece and penalty taker. The captain role is superficial unless you have a player with the leader trait. Likewise, having players with "set piece specialist" trait for penalties and set pieces is advised.',
            summary: 'Select players',
            task: 'browse'
        },
        {
            key: 16,
            path: URL.teamTactics,
            description: 'Select save to confirm your choices',
            summary: 'Save choices',
            task: 'interaction'
        },
        {
            key: 1,
            path: URL.teamTactics,
            description: 'Next select an empty or filled shirt on the football pitch in the position of the goalkeeper. This will then allow you to select players for that position',
            summary: 'Select a position',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.teamTactics,
            description: 'Select GK',
            summary: 'Select GK position',
            task: 'interaction'
        },
        {
            key: 3,
            path: URL.teamTactics,
            description: 'Select the player with the highest rating taking into account their fitness % and press "Select player"',
            summary: 'Select a GK',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.teamTactics,
            description: 'Note that you can put players out of position such as a LW in a ST position however this will incur a penalty on that players rating.',
            task: 'info'
        },
        {
            key: 5,
            path: URL.teamTactics,
            description: 'Don\'t like the whole team ? Select the bin icon to start again.',
            summary: 'Delete the lineup',
            task: 'interaction'
        },
        {
            key: 6,
            path: URL.teamTactics,
            description: 'Lazy? Select the magic wand icon for the auto fill feature.',
            summary: 'Auto select players',
            task: 'interaction'
        },
        {
            key: 7,
            path: URL.teamTactics,
            description: 'At the bottom of your screen you will see 5 substitute spots. These players will either come on if you get an injury or if you pre set substitutes',
            summary: 'View subs',
            task: 'browse'
        },
        {
            key: 8,
            path: URL.teamTactics,
            description: 'Please Select the "two arrow" icon next to the bin icon to configure your substitutes.',
            summary: 'Select subs',
            task: 'interaction'
        },
        {
            key: 9,
            path: URL.teamTactics,
            description: 'Substitutions are broken at the moment =(',
            summary: 'Select subs',
            task: 'browse'
        },
        {
            key: 11,
            path: URL.teamTactics,
            description: 'If you want to do the same with your youth team it\'s simple. Select the “little dude icon” next to the “big dude icon”',
            summary: 'Select youth team',
            task: 'interaction'
        },
        {
            key: 12,
            path: URL.teamTactics,
            description: 'If you don\'t want to manage your youth team you can select the “joystick icon” this means the staff member that you have employed will do more work.',
            summary: 'Delegate to coach',
            task: 'interaction'
        },
        {
            key: 13,
            path: URL.teamTactics,
            description: 'Well done! You have completed the tutorial',
        }
    ]
}
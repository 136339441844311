import {defineMessages} from 'react-intl';

import {URL} from '../../common/lib/paths';
import ToolbarItem from '../../menu/toolbar/ToolbarItem';
import { generatePath } from 'react-router';
import { faShieldHalved } from '@fortawesome/free-solid-svg-icons';

const messages = defineMessages({
    text: {
        id: 'nation:toolbar:clubs',
        defaultMessage: 'Clubs',
        description: 'Link in the toolbar in the nation section for the nation clubs page'
    }
})

export default function NationClubs(props) {
    return(
        <ToolbarItem
            path={{
                pathname: generatePath(URL.nationClubs, {nationId: props.nationId})
            }}
            icon={faShieldHalved}
            messages={messages}
            genericPath={URL.nationClubs}
        />
    )
}
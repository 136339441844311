import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Accordion } from "semantic-ui-react";
import SUIIconShim from "../../common/components/SUIIconShim";
import ContractTable from "../staffContract/contractTable";

export default function StaffContractDemandsInterface(props) {
    if(props.isLoading) {
        return null;
    }

    return(
        <>
            {getCustomIntroText()}
            {getIntroText()}
            {getDemandModifierText()}
            {getContractDemands()}
            <ExistingContract
                staffContract={props.staffContract}
                currentSeason={props.currentSeason}
            />
        </>
    );

    function getCustomIntroText() {
        if(!props.introMessage) {
            return null;
        } else {
            return(
                <p>{props.introMessage}</p>
            )
        }
    }

    function getIntroText() {
        if(props.isMyStaff) {
            return(<p><FormattedMessage {...messages.introContractRenewal} /></p>);
        } else {
            return(<p><FormattedMessage {...messages.introTransferOffer} /></p>);
        }
    }

    function getDemandModifierText() {
        return(
            <>
                {props.contractDemands?.modifiers.isHomeCity &&
                    props.isMyStaff ? <p><FormattedMessage {...messages.homeClubDiscountRenewal} /></p> : <p><FormattedMessage {...messages.homeClubDiscountTransfer} /></p>
                }
            </>
        );
    }

    function getContractDemands() {
        if(props.contractOffer) {
            return(
                <>
                    <FormattedMessage {...messages.contractDemandedText} />
                    <ContractTable
                        contractSalary={props.contractOffer.salaryDemand}
                        contractExpiry={props.contractOffer.endSeasonDemand.endDate}
                        currentSeason={props.currentSeason}
                    />
                </>
            )
        } else {
            return(
                <>
                    <FormattedMessage {...messages.contractDemandsText} />
                    <ContractTable
                        contractSalary={props.contractDemands.adjustedSalaryDemand}
                        contractExpiry={props.contractDemands.adjustedEndSeasonDemand.endDate}
                        currentSeason={props.currentSeason}
                    />
                </>
            )
        }
    }
}

function ExistingContract(props) {
    const [isExpanded, setIsExpanded] = useState(false);

    if(!props.staffContract) return null;
    
    return(
        <Accordion>
            <Accordion.Title
                active={isExpanded}
                onClick={() => setIsExpanded(!isExpanded)}
                index={0}
            >
                <SUIIconShim>
                    <FontAwesomeIcon icon={faCaretDown} className='inlineIcon' />
                    Existing contract
                </SUIIconShim>
            </Accordion.Title>
            <Accordion.Content
                active={isExpanded}
            >
                <ContractTable
                    contractSalary={props.staffContract.salary}
                    contractExpiry={props.staffContract.endSeason.endDate}
                    currentSeason={props.currentSeason}
                />
            </Accordion.Content>
        </Accordion>
    );
}

const messages = {
    introContractRenewal: {
        id: "Layout_PersonContractOffer.introContractRenewal",
        defaultMessage: "I'm really enjoying my time at the club so I'm delighted that you want to offer me a new contract.",
        description: "Conversation-style sentence where the person states that they're happy to open contract renewal discussions with the club"
    },
    introTransferOffer: {
        id: "Layout_PersonContractOffer.introTransferOffer",
        defaultMessage: "I'm really exicited joining this great club. My contract demands are below.",
        description: "Conversation-style sentence where the person states their happiness that the club have decided to make a transfer bid for them"
    },
    homeClubDiscountTransfer: {
        id: 'player:contractOffer:homeClubDiscount',
        defaultMessage: 'As you can see, I have lowered my wage demands as I would like to return to my home city.',
        description: 'Conversational sentence shown when player is in negotiations about a return to their home club'
    },
    homeClubDiscountRenewal: {
        id: 'player:contractOffer:homeClubDiscount',
        defaultMessage: 'As you can see, I have again lowered my wage demands as I am enjoying working in my home city.',
        description: 'Conversational sentence shown when player is in negotiations about renewing with their home club'
    },
    contractDemandsText: {
        id: "Layout_PersonContractOffer.contractDemandsText",
        defaultMessage: "This is the contract that I want.",
        description: "Conversation-style sentence where the person states their contract demands"
    },
    contractDemandedText: {
        id: "Layout_PersonContractOffer.contractDemandedText",
        defaultMessage: "This is the contract that I requested.",
        description: "Conversation-style sentence where the person states the contract that they had demanded (contract offer in progress)"
    },
}
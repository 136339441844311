import {Formik} from 'formik';
import {Form as SForm, Message, Divider, Loader} from "semantic-ui-react";
import {Form, Field, Checkbox, Input, SubmitButton, Select} from 'formik-semantic-ui-react';
import {FormattedMessage} from "react-intl";
import sortBy from "lodash/sortBy";

import messages from "./messages";

export default function NewManagerInterface(props) {
    const handleSubmit = (values) => {
        props.handleNewManagerSubmit(values);
    }

    if(props.isLoading) return(<Loader active />);

    const nationOptions = sortBy(props.nations.map((nation) => {
        return {
            key: nation.shortcode,
            value: nation.shortcode,
            flag: nation.shortcode,
            text: messages[nation.shortcode].defaultMessage
        }
    }), (nation) => nation.text);

    return(
        <Formik
            initialValues={{
                username: '',
                nationality: '',
                TCs: false,
                emailConsent: false,
                duplicateAcc: false
            }}
            setSubmitting={props.isSubmitting}
            validate={values => {
                const errors = {};

                if(!values.username) {
                    errors.username = 'Username is required';
                } else if(values.username.length < 3 || values.username.length > 14) {
                    errors.username = 'Your username must be between 3 and 14 characters';
                }

                if(!values.nationality) {
                    errors.nationality = 'Nationality is required';
                }

                if(!values.TCs) {
                    errors.TCs = 'You must accept our Terms and Conditions';
                }

                if(!values.duplicateAcc) {
                    errors.duplicateAcc = 'Multiple accounts are not allowed. We regularly monitor for players using more than one account and remove these from the game.';
                }

                return errors;
            }}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
        >
            {({values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                <Form error={Object.keys(errors).length > 0}>
                    <SForm.Field required>
                        <label>Username</label>
                        <Input
                            name='username'
                            placeholder='Username'
                            error={!!errors.username}
                        />
                        <p style={{fontSize: '0.8em'}}>Between 3-14 characters. This is the name of your manager in game, and is visible to other players.</p>
                        {errors.username && <Message error content={errors.username} />}
                    </SForm.Field>
                    <Divider horizontal />
                    <SForm.Field required>
                        <label>Nationality</label>
                        <Select
                            name='nationality'
                            placeholder='Select country'
                            search
                            options={nationOptions}
                            error={!!errors.nationality}
                        />
                        {errors.nationality && <Message error content={errors.nationality} />}
                    </SForm.Field>
                    <Divider horizontal />
                    <SForm.Field>
                        <Checkbox
                            name='TCs'
                            label={<label>I agree to the Terms and Conditions</label>}
                            error={!!errors.TCs}
                        />
                        {errors.TCs && <Message error content={errors.TCs} />}
                    </SForm.Field>
                    <SForm.Field>
                        <Checkbox
                            name='emailConsent'
                            label='I would like to receive email updates from Manager.Soccer'
                        />
                    </SForm.Field>
                    <SForm.Field>
                        <Checkbox
                            name='duplicateAcc'
                            label='I do not have another Manager.Soccer account'
                            error={!!errors.duplicateAcc}
                        />
                        {errors.duplicateAcc && <Message error content={errors.duplicateAcc} />}
                    </SForm.Field>
                    <Divider horizontal />
                    <SubmitButton primary><FormattedMessage {...messages.formSubmit} /></SubmitButton>
                </Form>
            )}
        </Formik>
    )
}
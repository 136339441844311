import {Table, Divider} from 'semantic-ui-react';

import sortBy from 'lodash/sortBy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol, faStopwatch } from '@fortawesome/free-solid-svg-icons';

const styles = {
    scorer: {
        margin: 0
    },
    assist: {
        margin: 0,
        color: '#767676'
    }
}

const genericEvents = [
    {
        type: 'HT',
        time: 45
    },
    {
        type: 'FT',
        time: 90
    }
]

export default function Summary(props) {
    const allEvents = sortBy([...props.events.home, ...props.events.away, ...genericEvents], 'time');

    return(
        <Table basic='very' compact='very' unstackable>
            <Table.Body>
                {allEvents.map((event) => {
                    if(event.side === 1) {
                        return (
                            <Table.Row style={{border: 'none'}}>
                                <Table.Cell verticalAlign='middle' textAlign='left' width={1} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    {(event.side === 1 ?
                                        <span style={{fontWeight: 'bold'}}>{event.time}'</span>
                                    : null)}
                                </Table.Cell>
                                <Table.Cell verticalAlign='middle' textAlign='left' width={1} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    {(event.side === 1 ?
                                        <FontAwesomeIcon icon={faFutbol} />
                                    : null)}
                                </Table.Cell>
                                <Table.Cell colSpan={3} textAlign={(event.side === 1 ? 'left' : 'right')} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    <p style={styles.scorer}>{`${event.primaryPlayer.firstName.substr(0, 1)}. ${event.primaryPlayer.lastName}`}</p>
                                    <small style={styles.assist}>Assist {`${event.secondaryPlayer.firstName.substr(0, 1)}. ${event.secondaryPlayer.lastName}`}</small>
                                </Table.Cell>
                            </Table.Row>
                        )
                    } else if(event.side === 2) {
                        return (
                            <Table.Row style={{borderBottom: 'none'}}>
                                <Table.Cell colSpan={3} textAlign={(event.side === 1 ? 'left' : 'right')} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    <p style={styles.scorer}>{`${event.primaryPlayer.firstName.substr(0, 1)}. ${event.primaryPlayer.lastName}`}</p>
                                    <small style={styles.assist}>Assist {`${event.secondaryPlayer.firstName.substr(0, 1)}. ${event.secondaryPlayer.lastName}`}</small>
                                </Table.Cell>
                                <Table.Cell verticalAlign='middle' textAlign='right' width={1} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    {(event.side === 2 ?
                                        <FontAwesomeIcon icon={faFutbol} />
                                    : null)}
                                </Table.Cell>
                                <Table.Cell verticalAlign='middle' textAlign='right' width={1} style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    {(event.side === 2 ?
                                        <span style={{fontWeight: 'bold'}}>{event.time}'</span>
                                    : null)}
                                </Table.Cell>
                            </Table.Row>
                        )
                    } else {
                        return (
                            <Table.Row style={{borderBottom: 'none'}}>
                                <Table.Cell colSpan={5} textAlign='center'  style={{padding: '0.4em 0.2em', border: 'none'}}>
                                    <Divider horizontal style={{width: '100%', fontSize: 'inherit', fontWeight: 'inherit', letterSpacing: 'inherit', textTransform: 'none'}}>
                                        <FontAwesomeIcon icon={faStopwatch} className='inlineIcon' color='#767676' />
                                        <span style={{color: '#767676'}}>{event.type === 'HT' ? 'Half time' : 'Full time'}</span>
                                    </Divider>
                                </Table.Cell>
                            </Table.Row>
                        );
                    }
                })}
            </Table.Body>
        </Table>
    );
}
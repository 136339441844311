import { List, ListContent, ListHeader, ListItem, Placeholder, PlaceholderLine, Segment } from "semantic-ui-react";
import CupLink from '../../../cup/link/CupLink';

export default function WorldCupsInterface(props) {
    return(
        <div>
            <Segment>
                <p>Here is a list of all the cup competitions in the game, including the infamous Mug of Nations where the best clubs in the game compete against each other.</p>
            </Segment>
            <Segment>
                <List
                    divided
                    relaxed
                >
                    {props.cups?.map((cup) => (
                        <ListItem>
                            <ListContent>
                                <ListHeader>
                                    <CupLink cup={cup} />
                                </ListHeader>
                            </ListContent>
                        </ListItem>
                    ))}
                    {props.isLoading ?
                        <ListItem>
                            <Placeholder>
                                <PlaceholderLine />
                            </Placeholder>
                        </ListItem>
                        :
                        null
                    }
                </List>
            </Segment>
        </div>
    );
}
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../../common/lib/paths.js";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "toolbarItem:teamPlayers",
		defaultMessage: "Players",
		description: "Link for team players page"
	}
})

export default function TeamPlayersToolbarItem(props) {
	return(
		<ToolbarItem
			path={generatePath(URL.teamPlayers, {clubId: props.clubId, teamType: props.teamType})}
			icon={faUserGroup}
			messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
		/>
	);
}
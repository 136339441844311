import {useQuery, useQueryClient, useMutation} from '@tanstack/react-query';
import {useEffect} from 'react';
import {generatePath, matchPath, useLocation, useParams} from 'react-router';
import axios from "axios";
import { FormattedMessage } from 'react-intl';

import TrainingInterface from "./interface";
import TeamName from '../team/common/TeamName';
import TrainingBoosts from './trainingBoosts/data';
import TeamPlayersToolbarItem from '../club/components/toolbar/TeamPlayers';
import TeamTacticsToolbarItem from '../club/components/toolbar/TeamTactics';
import TeamLeagueToolbarItem from '../club/components/toolbar/TeamLeague';
import TeamFixturesToolbarItem from '../club/components/toolbar/TeamFixtures';
import TeamLeagueSeasonsToolbarItem from '../club/components/toolbar/TeamLeagueSeasons';
import TeamTrainingToolbarItem from '../club/components/toolbar/TeamTraining';
import ClubProfileToolbarItem from '../club/components/toolbar/ClubProfile';
import ClubStaffToolbarItem from '../club/components/toolbar/ClubStaff';
import ClubTransfersToolbarItem from '../club/components/toolbar/ClubTransfers';
import { Grid, Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { URL } from '../common/lib/paths';
import { faChild, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SUIIconShim from '../common/components/SUIIconShim';
import { useTutorial } from '../tutorial/hooks/useTutorial';

export default function Training(props) {
    const {teamType} = useParams();
    const location = useLocation();
    const queryClient = useQueryClient();
    const {checkTutorial, getSearchParamsString, tutorialStep} = useTutorial();

    const getSquadQuery = useQuery(
        ['getSquad', 'training'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/${props.manager.club.id}/teams/${teamType}/playerRegistrations?ownershipClaim=true&context=training`)
    );

    const getTrainingBoostsQuery = useQuery(
        ['getTrainingBoosts', teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teams/${teamType}/trainingBoosts`)
    );

    const updateTrainingFocus = useMutation(
        ({playerContractId, trainingFocus}) =>
            axios.patch(
                `${process.env.REACT_APP_APPHOST}/playerContracts/${playerContractId}`,
                {
                    trainingFocus
                }
            )
        , {
            onSuccess: () => {
                queryClient.invalidateQueries('getSquad');
            }
        }
    );

    useEffect(() => {
        if(getSquadQuery.data?.data?.response) {
            const navigation = [
                <ClubProfileToolbarItem clubId={props.manager.club.id} key={1} />,
                <TeamPlayersToolbarItem clubId={props.manager.club.id} teamType={teamType} key={2} />,
                <TeamTacticsToolbarItem isMyClub={true} teamType={teamType} key={3} />,
                <TeamTrainingToolbarItem isMyClub={true} teamType={teamType} key={4} />,
                <TeamFixturesToolbarItem clubId={props.manager.club.id} teamType={teamType} key={5} />,
                <TeamLeagueToolbarItem clubId={props.manager.club.id} teamType={teamType} key={6} />,
                <ClubStaffToolbarItem clubId={props.manager.club.id} key={7} />,
                <ClubTransfersToolbarItem clubId={props.manager.club.id} key={8} />,
                <TeamLeagueSeasonsToolbarItem clubId={props.manager.club.id} teamType={teamType} key={9} />
            ];

            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{teamName: <TeamName team={getSquadQuery.data.data.response} />}} />,
                navigation
            });
        }
    }, [teamType, getSquadQuery.data?.data?.response]);

    const handleUpdateTrainingFocus = function(playerContractId, trainingFocus) {
        updateTrainingFocus.mutate({playerContractId, trainingFocus});
    }

    const isTutorial1 = checkTutorial('training', 4);

    return(
        <div>
            <Grid className='gridResetInherit trainingBoosts' stackable>
                <Grid.Row columns={1} style={{padding: '0 0 1em 0', maxWidth: '750px', margin: '0 auto'}}>
                    <Menu
                        icon
                        inverted
                        style={{margin: '0 auto'}}
                        size='tiny'
                    >
                        <Menu.Item
                            as={Link}
                            to={{pathname: generatePath(URL.teamTraining, {teamType: 'first'})}}
                            active={matchPath(location.pathname, {path: generatePath(URL.teamTraining, {teamType: 'first'})})}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faUser} />
                            </SUIIconShim>
                        </Menu.Item>
                        <Menu.Item
                            as={Link}
                            to={{pathname: generatePath(URL.teamTraining, {teamType: 'youth'}), search: isTutorial1 && getSearchParamsString('training', tutorialStep+1)}}
                            active={matchPath(location.pathname, {path: generatePath(URL.teamTraining, {teamType: 'youth'})})}
                            className={isTutorial1 && 'glow'}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faChild} />
                            </SUIIconShim>
                        </Menu.Item>
                    </Menu>
                </Grid.Row>
                <TrainingBoosts
                    isLoading={getTrainingBoostsQuery.isLoading}
                    trainingBoosts={getTrainingBoostsQuery.data?.data?.response.trainingBoosts}
                    pointsRequiredForBonusBoost={getTrainingBoostsQuery.data?.data?.response.pointsRequiredForBonusBoost}
                    playerRegistrations={getSquadQuery.data?.data?.response?.playerReg}
                    teamId={getSquadQuery.data?.data?.response?.id}
                    formation={getSquadQuery.data?.data?.response.currentFormation}
                />
            </Grid>
            <TrainingInterface
                isLoading={getSquadQuery.isLoading}
                playerRegistrations={getSquadQuery.data?.data?.response?.playerReg}
                teamId={getSquadQuery.data?.data?.response?.id}
                clubId={props.manager.club.id}
                updateTrainingFocus={handleUpdateTrainingFocus}
            />
        </div>
    );
}

const messages = {
    screenTitle: {
        id: 'teamTactics:screenTitle',
        defaultMessage: '{teamName} training',
        description: 'Title for the screen showing a teams training'
    }
}
import { faExchange } from '@fortawesome/free-solid-svg-icons';
import {URL} from '../../common/lib/paths';

export default {
    key: 'transfers',
    name: 'Transfers',
    loaderPath: URL.transfersTutorial,
    icon: faExchange,
    description: 'Learn how to buy and sell players',
    steps: [
        {
            key: 0,
            path: URL.transferSearchPlayers,
            description: 'Use the "filter" option to start searching for the player of your dreams.',
            summary: 'Open the filter tool',
            task: 'interaction'
        },
        {
            key: 1,
            path: URL.transferSearchPlayers,
            description: 'Select "interested players only"',
            summary: 'Filter uninterested players',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.transferSearchPlayers,
            description: 'Before we go any further please note that you can try to bid for any player you want, however your rival teams are unlikely to accept unless they have transfer or loan listed the player you want.',
            task: 'info'
        },
        {
            key: 3,
            path: URL.transferSearchPlayers,
            description: 'With that in mind select "Transfer"',
            summary: 'Filter transfer listed players',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.transferSearchPlayers,
            description: 'Select “apply"',
            summary: 'Apply filters',
            task: 'interaction'
        },
        {
            key: 5,
            path: URL.transferSearchPlayers,
            description: (
                <>
                    <p>This is a list in rating order of transfer listed players, please note:</p>
                    <ul>
                        <li>If you see AUC highlighted in green next to a players name it means a bid has been accepted and that you have 12 hours or under to place a rival bid</li>
                        <li>If you see LST highlighted in black next to the players name then no one has yet placed a bid</li>
                        <li>If you see BID highlighted in green then a bid has been placed but has yet to be accepted</li>
                    </ul>
                </>
            ),
            summary: 'View results',
            task: 'browse'
        },
        {
            key: 6,
            path: URL.transferSearchPlayers,
            description: 'Please select the first player from the list',
            summary: 'Select a player',
            task: 'interaction'
        },
        {
            key: 7,
            path: URL.playerProfile,
            description: 'It is highly recommended that you scout a player before you buy. To help me teach you please select "begin scouting"',
            summary: 'Begin scouting',
            task: 'interaction'
        },
        {
            key: 8,
            path: URL.playerProfile,
            description: 'However its worth checking the "Estimated completion" of the scout report compared to the " Auction end" (if they are already at auction) to make sure you can get the valuable information in time. Otherwise you might have to risk it.',
            summary: 'Timing is important',
            task: 'info'
        },
        {
            key: 9,
            path: URL.playerProfile,
            description: 'We are now going to learn more about scouting. Please select the "transfer" tab at the side of your screen.',
            summary: 'Select transfer tab',
            task: 'interaction'
        },
        {
            key: 10,
            path: URL.clubPlayerTransferOffers,
            description: 'Now select the "scouting" tab at the top of your screen',
            summary: 'Select scouting tab',
            task: 'interaction'
        },
        {
            key: 11,
            path: URL.transferScouting,
            description: 'If you have previously scouted players you can observe them here on the "completed" section of scouting.',
            summary: 'Select scouting tab',
            task: 'interaction'
        },
        {
            key: 12,
            path: URL.transferScouting,
            description: 'Please select "In progress"',
            summary: 'Open in progress scout reports',
            task: 'interaction'
        },
        {
            key: 13,
            path: URL.transferScouting,
            description: 'This is the list of people that you have selected to scout. The total number of people you can scout a day is 12. This is dictated by whether or not you have all your scouting staff. Each scout staff member employed allows you 4 scout reports a day. The better the scouts, the more accurate the scout reports will be.',
            summary: 'View in progress scout reports',
            task: 'browse'
        },
        {
            key: 14,
            path: URL.transferScouting,
            description: 'The next feature will come up many times in this game and is very useful, please select the compare button.',
            summary: 'Add players to compare',
            task: 'interaction'
        },
        {
            key: 15,
            path: URL.transferScouting,
            description: 'Now go to the bottom left corner and select the same symbol again being careful not to click the X on the right hand side',
            summary: 'Open compare tool',
            task: 'interaction'
        },
        {
            key: 16,
            path: URL.playerCompare,
            description: 'If this is your first time here then there may only be one player here, however if there are more players then you can use the arrows to quickly go back and forth between them, this is more useful when the reports are complete.',
            summary: 'View player comparison',
            task: 'browse'
        },
        {
            key: 17,
            path: URL.playerCompare,
            description: 'Well done! You have completed the tutorial',
            summary: 'Congrats'
        }
    ]
}
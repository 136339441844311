import {URL} from '../../common/lib/paths';
import { faListOl } from '@fortawesome/free-solid-svg-icons';

export default {
    key: 'league',
    name: 'League',
    loaderPath: URL.leagueTutorial,
    icon: faListOl,
    description: 'Learn about promotion and relegation',
    steps: [
        {
            key: 0,
            path: URL.teamLeague,
            description: (
                <>
                    <p>This is the league table.</p>
                    <ul>
                        <li>Green: first or play off final</li>
                        <li>White: safety</li>
                        <li>Red: relegation or relegation play off</li>
                    </ul>
                </>
            ),
            summary: 'Explore your league',
            task: 'browse'
        },
        {
            key: 1,
            path: URL.teamLeague,
            description: 'Select "youth team" at the top of your page.',
            summary: 'Explore youth league',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.teamLeague,
            description: 'Now you have assessed how you are doing in both first team and youth team football, please return to "first team"',
            summary: 'Go back',
            task: 'interaction'
        },
        {
            key: 3,
            path: URL.teamLeague,
            description: 'Now select the Icon next to the  "1 2" icon at the top of your screen. This is the goal stats for the current season',
            summary: 'Open league stats',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.teamLeague,
            description: 'To change the stats you are viewing select the drop down below "statistics"',
            summary: 'Change stat',
            task: 'browse'
        },
        {
            key: 5,
            path: URL.teamLeague,
            description: 'Now select the third icon that looks like a calendar.',
            summary: 'Open league fixtures',
            task: 'interaction'
        },
        {
            key: 6,
            path: URL.teamLeague,
            description: 'Here you can see every game that is being played in your league that day.',
            summary: 'View league fixtures',
            task: 'browse'
        },
        {
            key: 7,
            path: URL.teamLeague,
            description: 'Next select the drop down under the word "date" to view other score lines from other days.',
            summary: 'Change date',
            task: 'interaction'
        },
        {
            key: 8,
            path: URL.teamLeague,
            description: 'Select the icon next to the "pen" icon that looks like a "clock" to see previous seasons winners',
            summary: 'Change season',
            task: 'interaction'
        },
        {
            key: 9,
            path: URL.teamLeague,
            description: 'Select the “pen” icon to go down an endless rabbit hole.',
            summary: 'Change league',
            task: 'interaction'
        },
        {
            key: 10,
            path: URL.teamLeague,
            description: 'Well done! You have completed the tutorial',
            summary: 'Congrats'
        }
    ]
}
import { defineMessages } from "@formatjs/intl";
import {compileMessage} from './util.js';

const messages = {
    'playerTransferOfferLoan:created': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferLoan:created',
            defaultMessage: "Loan offer for {playerName}",
            description: "Subject for the system message template which a user receives when a club makes a loan offer for a player"
        },
        body: {
            id: 'playerTransferOfferLoan:created',
            defaultMessage: "{toClubName} have offered to take {playerName} on loan until the end of the season.\n\nYou must respond by {responseDeadline}.",
            description: "Message body for the system message template which a user receives when a club makes a loan offer for a player"
        }
    })),
    'favPlayer:playerTransferOfferLoan:created': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferLoan:created',
            defaultMessage: "{toClubName} submit loan offer for {playerName}",
            description: "Subject for the system message template which a user receives when a club makes a loan offer for a player on their favourites"
        },
        body: {
            id: 'playerTransferOfferLoan:created',
            defaultMessage: "{toClubName} have offered to take {playerName}, who is on our favourites, on loan until the end of the season.",
            description: "Message body for the system message template which a user receives when a club makes a loan offer for a player on their favourites"
        }
    })),
    'playerTransferOfferLoan:accepted': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferLoan:accepted',
            defaultMessage: "Loan offer for {playerName} accepted",
            description: "Subject for the system message template which a user receives when a club acccepts their loan offer"
        },
        body: {
            id: 'playerTransferOfferLoan:accepted',
            defaultMessage: "We have accepted your offer to take {playerName} on loan until the end of the current season. The player will join your team shortly.",
            description: "Body for the system message template which a user receives when a club acccepts their loan offer"
        }
    })),
    'playerTransferOfferLoan:rejected': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferLoan:rejected',
            defaultMessage: "Loan offer for {playerName} rejected",
            description: "Subject for the system message template which a user receives when a club rejects their loan offer"
        },
        body: {
            id: 'playerTransferOfferLoan:rejected',
            defaultMessage: "We have rejected your offer to take {playerName} on loan until the end of the season.",
            description: "Body for the system message template which a user receives when a club rejects their loan offer"
        }
    })),
    'playerTransferOfferLoan:withdrawn': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferLoan:withdrawn',
            defaultMessage: "Loan offer for {playerName} withdrawn",
            description: "Subject for the system message template which a user receives when a club withdraws their loan offer"
        },
        body: {
            id: 'playerTransferOfferLoan:withdrawn',
            defaultMessage: "We have withdrawn our offer to take {playerName} on loan until the end of the season.",
            description: "Body for the system message template which a user receives when a club withdraws their loan offer"
        }
    })),
    'playerTransferOfferLoan:timedOut': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferLoan:timedOut',
            defaultMessage: "Loan offer for {playerName} cancelled",
            description: "Subject for the system message template which a user receives when another club have not responded in time to their loan offer"
        },
        body: {
            id: 'playerTransferOfferLoan:timedOut',
            defaultMessage: "Your offer to take {playerName} on loan until the end of the season has been cancelled because {toClubName} did not respond before the deadline.",
            description: "Body for the system message template which a user receives when another club have not responded in time to their loan offer"
        }
    })),
}

export default messages;
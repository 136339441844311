import { faClock, faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { defineMessages, FormattedMessage } from "react-intl";

function transferAuctionDuration(resolver) {
    return {
        id: 'auctionDuration',
        name: <FontAwesomeIcon icon={faStopwatch} />,
        cell: (rowData) => resolver(rowData) === undefined ? '-' : <FormattedMessage {...messages.auctionEnd} values={{interval: resolver(rowData)}} />,
        compact: true,
        center: true,
        grow: 1,
        minWidth: '45px',
        maxWidth: '100px'
    }
}

export {
    transferAuctionDuration
}

const messages = defineMessages({
    auctionEnd: {
        id: 'dataTable:playerTransferAuction:duration',
        defaultMessage: '{interval} hrs',
        description: 'Label for auction end date/time'
    }
});
import {Grid, Image, Header, Menu, Segment, Icon, Loader, Divider, Dropdown} from 'semantic-ui-react';
import {FormattedMessage, FormattedDate} from 'react-intl';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import {useHistory, useLocation} from 'react-router';

import PlayerLink from '../../player/common/Link';
import LeagueLink from '../../league/components/common/Link';
import ClubLink from '../../club/components/common/Link';
import CupLink from '../../cup/link/CupLink';
import CupRoundName from '../../cup/RoundName';

import Stats from './views/Stats';
import Lineups from './views/Lineups';
import Summary from './views/Summary';
import ShortenedPlayerName from '../../player/common/ShortenedName';
import Ratings from './views/Ratings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faFutbol, faAward, faTrophy } from '@fortawesome/free-solid-svg-icons';
import {useTutorial} from '../../tutorial/hooks/useTutorial';
import colours from '../../common/colours';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';

export default function FixtureInterface(props) {
    const history = useHistory();
    const location = useLocation();
    const {checkTutorial, getSearchParamsString} = useTutorial();

    if(!props.isLoading && props.fixture.status === 'complete') {
        var homeTeamGoals = reduce(props.fixture.lineupData.home, (summary, lineup) => {
            for(const player of lineup.player) {
                if(!player.goals || player.goals === 0) continue;
        
                const newSummary = {
                    playerId: player.playerId,
                    playerName: <ShortenedPlayerName player={{person: {firstName: player.firstName, lastName: player.lastName}}} />,
                    goalTimes: player.goalEventIndexes.map((eventIndex) => `${props.fixture.eventData.home[eventIndex].time}'${props.fixture.eventData.home[eventIndex].isPenalty ? ' (P)' : ''}`).sort((a, b) => {const aInt = parseInt(a); const bInt = parseInt(b); if(aInt < bInt) {return -1} if(aInt > bInt) {return 1} return 0})
                }
        
                newSummary.timeOfFirstGoal = newSummary.goalTimes[0];
                newSummary.goalTimes = newSummary.goalTimes.join(', ');
        
                summary.push(newSummary);
            }
            return summary;
        }, []);
    
        var awayTeamGoals = reduce(props.fixture.lineupData.away, (summary, lineup) => {
            for(const player of lineup.player) {
                if(!player.goals || player.goals === 0) continue;
    
                const newSummary = {
                    playerId: player.playerId,
                    playerName: <ShortenedPlayerName player={{person: {firstName: player.firstName, lastName: player.lastName}}} />,
                    goalTimes: player.goalEventIndexes.map((eventIndex) => `${props.fixture.eventData.away[eventIndex].time}'${props.fixture.eventData.away[eventIndex].isPenalty ? ' (P)' : ''}`).sort((a, b) => {const aInt = parseInt(a); const bInt = parseInt(b); if(aInt < bInt) {return -1} if(aInt > bInt) {return 1} return 0})
                }
        
                newSummary.timeOfFirstGoal = newSummary.goalTimes[0];
                newSummary.goalTimes = newSummary.goalTimes.join(', ');
        
                summary.push(newSummary);
            }
    
            return summary;
        }, []);
        
        homeTeamGoals = sortBy(homeTeamGoals, (goal) => {const timeOfFirstGoal = parseInt(goal.timeOfFirstGoal); return timeOfFirstGoal});
        awayTeamGoals = sortBy(awayTeamGoals, (goal) => {const timeOfFirstGoal = parseInt(goal.timeOfFirstGoal); return timeOfFirstGoal});

        var homeTeamRedCards = reduce(props.fixture.lineupData.home, (summary, lineup) => {
            for(const player of lineup.player) {
                if(!player.card || player.card.type === 'yellow') continue;

                const newSummary = {
                    playerId: player.playerId,
                    playerName: <ShortenedPlayerName player={{person: {firstName: player.firstName, lastName: player.lastName}}} />,
                    time: `${player.card.time}'`
                }

                summary.push(newSummary);
            }

            return summary;
        }, []);

        var awayTeamRedCards = reduce(props.fixture.lineupData.away, (summary, lineup) => {
            for(const player of lineup.player) {
                if(!player.card || player.card.type === 'yellow') continue;

                const newSummary = {
                    playerId: player.playerId,
                    playerName: <ShortenedPlayerName player={{person: {firstName: player.firstName, lastName: player.lastName}}} />,
                    time: `${player.card.time}'`
                }

                summary.push(newSummary);
            }

            return summary;
        }, []);

        var homeMOTM, awayMOTM;

        for(const lineup of props.fixture.lineupData.home) {
            for(const player of lineup.player) {
                if(player.isMOTM === true) {
                    homeMOTM = player;
                }
            }
        }

        for(const lineup of props.fixture.lineupData.away) {
            for(const player of lineup.player) {
                if(player.isMOTM === true) {
                    awayMOTM = player;
                }
            }
        }
    }

    function getSegmentStyles() {
        const baseStyles = {width: '100%', paddingTop: '1px', paddingBottom: 0}

        if(location.state?.view === 'lineups') {
            baseStyles.paddingLeft = 0;
            baseStyles.paddingRight = 0;
        }

        return baseStyles;
    }

    const isTutorial1 = checkTutorial('fixtures', 5);

    return(
        <div>
            <Segment>
                {props.isLoading ?
                    <div style={{height: '250px'}}>
                        <Loader active />
                    </div>
                    :
                    <Grid style={{margin: 0}}>
                        <Grid.Row style={{padding: 0}}>
                            <Grid.Column width={16} textAlign='center'>
                                {props.type === 'league' && 
                                    <>
                                        <Header as='h4' style={{margin: 0}}><LeagueLink league={props.fixture.leagueFixtureRound.LS.league} /></Header>
                                        <Header as='h4' style={{margin: 0}}><FormattedDate value={props.fixture.leagueFixtureRound.gameJob.startDate} month='long' day='numeric' hour='numeric' /></Header>
                                        <Header as='h5' style={{margin: 0}}>Season <FormattedDate value={props.fixture.leagueFixtureRound.LS.season.startDate} day='numeric' month='short' year='2-digit' /></Header>
                                    </>
                                }
                                {props.type === 'cup' &&
                                    <>
                                        <Header as='h4' style={{margin: 0}}>
                                            <FontAwesomeIcon icon={faTrophy} className='inlineIcon' color={colours.gold} />
                                            <CupLink cup={props.fixture.cupFixtureRound.CS.cup} />
                                        </Header>
                                        <Header as='h4' style={{margin: 0}}>
                                            <CupRoundName roundsFromFinal={props.fixture.cupFixtureRound.roundsFromFinal} />
                                        </Header>
                                        <Header as='h4' style={{margin: 0}}><FormattedDate value={props.fixture.cupFixtureRound.gameJob.startDate} month='long' day='numeric' hour='numeric' /></Header>
                                        <Header as='h5' style={{margin: 0}}>Season <FormattedDate value={props.fixture.cupFixtureRound.CS.season.startDate} day='numeric' month='short' year='2-digit' /></Header>
                                    </>
                                }
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={6} style={{padding: 0}}>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/crests/${props.fixture.homeTeam.club.badgeNumber}.png`}
                                    style={{
                                        filter: 'drop-shadow(1px 1px 3px #000)'
                                    }}
                                    size='tiny'
                                    centered
                                />
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='center' verticalAlign='middle' style={{padding: 0}}>
                                <Header as='h2'>
                                    {props.fixture.status === 'complete' ?
                                        <FormattedMessage
                                            {...messages.fixtureTitleSeparatorPlayed}
                                            values={{
                                                homeTeamScore: props.fixture.scoreHome.toString(),
                                                awayTeamScore: props.fixture.scoreAway.toString()
                                            }}
                                        />
                                        :
                                        <FormattedMessage {...messages.fixtureTitleSeparatorNotPlayed} />
                                    }
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={6} style={{padding: 0}}>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/crests/${props.fixture.awayTeam.club.badgeNumber}.png`}
                                    style={{
                                        filter: 'drop-shadow(1px 1px 3px #000)'
                                    }}
                                    size='tiny'
                                    centered
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{padding: 0}}>
                            <Grid.Column width={6} textAlign='center' style={{padding: 0}}>
                                <Header as='h4'><ClubLink club={props.fixture.homeTeam.club} /></Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='center'></Grid.Column>
                            <Grid.Column width={6} textAlign='center' style={{padding: 0}}>
                                <Header as='h4'><ClubLink club={props.fixture.awayTeam.club} /></Header>
                            </Grid.Column>
                        </Grid.Row>
                        {props.fixture.status === 'complete' ?
                            <Divider style={{marginBottom: 0}} />
                            :
                            null
                        }
                        {props.fixture.status === 'complete' && (props.fixture.scoreHome !== 0 || props.fixture.scoreAway !== 0) ?
                            <Grid.Row style={{paddingBottom: 0}}>
                                <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0}}>
                                    {homeTeamGoals.map((player) => 
                                        <p style={{margin: 0}}><PlayerLink player={{id: player.playerId, fullName: player.playerName}} /> {player.goalTimes}</p>
                                    )}
                                </Grid.Column>
                                <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                    <FontAwesomeIcon icon={faFutbol} />
                                </Grid.Column>
                                <Grid.Column width={7} textAlign='left' style={{paddingRight: 0}}>
                                    {awayTeamGoals.map((player) => 
                                        <p style={{margin: 0}}><PlayerLink player={{id: player.playerId, fullName: player.playerName}} /> {player.goalTimes}</p>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            :
                            null
                        }
                        {props.fixture.status === 'complete' && (homeTeamRedCards.length !== 0 || awayTeamRedCards.length !==0) ?
                            <Grid.Row>
                                <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0}}>
                                    {homeTeamRedCards.map((player) => 
                                        <p style={{margin: 0}}><PlayerLink player={{id: player.playerId, fullName: player.playerName}} /> {player.time}</p>
                                    )}
                                </Grid.Column>
                                <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                    <FontAwesomeIcon icon={faSquare} color='#DB2828' />
                                </Grid.Column>
                                <Grid.Column width={7} textAlign='left' style={{paddingRight: 0}}>
                                {awayTeamRedCards.map((player) => 
                                        <p style={{margin: 0}}><PlayerLink player={{id: player.playerId, fullName: player.playerName}} /> {player.time}</p>
                                    )}
                                </Grid.Column>
                            </Grid.Row>
                            :
                            null
                        }
                        {props.fixture.status === 'complete' ?
                            <Grid.Row style={{paddingBottom: 0}}>
                                <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0}}>
                                    {homeMOTM ? <PlayerLink player={{id: homeMOTM.playerId, person: {firstName: homeMOTM.firstName, lastName: homeMOTM.lastName}}} /> : null}
                                </Grid.Column>
                                <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                    <FontAwesomeIcon icon={faAward} />
                                </Grid.Column>
                                <Grid.Column width={7} textAlign='left' style={{paddingRight: 0}}>
                                    {awayMOTM ? <PlayerLink player={{id: awayMOTM.playerId, person: {firstName: awayMOTM.firstName, lastName: awayMOTM.lastName}}} /> : null}
                                </Grid.Column>
                            </Grid.Row>
                            :
                            null
                        }
                        {props.fixture.status === 'forfeit' ?
                            <Grid.Row columns={1} style={{paddingBottom: 0}}>
                                <Grid.Column style={{textAlign: 'center'}}>
                                    {props.fixture.scoreHome > props.fixture.scoreAway ?
                                        <Header as='h5'>{props.fixture.awayTeam.club.name} forfeitted the fixture as they did not have enough players</Header>
                                        :
                                        <Header as='h5'>{props.fixture.homeTeam.club.name} forfeitted the fixture as they did not have enough players</Header>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            :
                            null
                        }
                        {props.fixture.penaltyScoreHome !== null && props.fixture.penaltyScoreAway !== null ?
                            <Grid.Row columns={1} style={{paddingBottom: 0}}>
                                <Grid.Column style={{textAlign: 'center'}}>
                                    {props.fixture.penaltyScoreHome > props.fixture.penaltyScoreAway ?
                                        <Header as='h6'>{props.fixture.homeTeam.club.name} won {props.fixture.penaltyScoreHome}-{props.fixture.penaltyScoreAway} on penalties</Header>
                                        :
                                        <Header as='h6'>{props.fixture.awayTeam.club.name} won {props.fixture.penaltyScoreAway}-{props.fixture.penaltyScoreHome} on penalties</Header>
                                    }
                                </Grid.Column>
                            </Grid.Row>
                            :
                            null
                        }
                    </Grid>
                }
            </Segment>
            {!props.isLoading && props.fixture.status === 'complete' ?
                <>
                    <Menu pointing secondary fluid widths={4} attached='top' style={{backgroundColor: '#FFF'}}>
                        <Menu.Item name='Stats' active={location.state?.view === 'stats' || !location.state} onClick={() => history.replace({pathname: location.pathname, state: {view: 'stats'}})} />
                        <Menu.Item name='Summary' active={location.state?.view === 'summary'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'summary'}})} />
                        <Menu.Item name='Ratings' active={location.state?.view === 'ratings'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'ratings', side: 'home'}})} />
                        <Menu.Item name='Line ups' className={isTutorial1 && 'glow'} active={location.state?.view === 'lineups'} onClick={() => history.replace({pathname: location.pathname, state: {view: 'lineups'}, search: isTutorial1 && getSearchParamsString('fixtures', 6)})} />
                    </Menu>
                    <Segment attached='bottom' style={getSegmentStyles()} color={(location.state?.view === 'lineups' ? 'black' : null)} inverted={(location.state?.view === 'lineups' ? true : false)}>
                        {location.state?.view === 'ratings' && !props.isLoading ? <Ratings lineup={props.fixture?.lineupData} isLoading={props.isLoading} homeClubName={props.fixture.homeTeam.club.name} awayClubName={props.fixture.awayTeam.club.name} /> : null}
                        {(location.state?.view === 'stats' || !location.state) && !props.isLoading ? <Stats stats={props.fixture?.stats} lineups={props.fixture?.lineupData} isLoading={props.isLoading} /> : null}
                        {location.state?.view === 'lineups' && !props.isLoading ? <Lineups lineups={props.fixture?.lineupData} tactics={props.fixture?.tacticData} homeTeamName={props.fixture?.homeTeam.club?.name} awayTeamName={props.fixture?.awayTeam.club?.name} isLoading={props.isLoading} /> : null}
                        {location.state?.view === 'summary' && !props.isLoading ? <Summary events={props.fixture?.eventData} isLoading={props.isLoading} /> : null}
                    </Segment>
                </>
                :
                null
            }
            {!props.isLoading && props.fixture.status === 'planned' ?
                props.type === 'league' ?
                    <LeagueTacticSelector
                        fixtureId={props.fixture.id}
                    />
                    :
                    <CupTacticSelector
                        fixtureId={props.fixture.id}
                    />
                :
                null
            }
        </div>
    )
}

function LeagueTacticSelector(props) {
    const queryClient = useQueryClient();

    const leagueFixtureTacticQuery = useQuery(
        ['getLeagueFixtureTactic', props.fixtureId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/leagueFixtures/${props.fixtureId}/teamTactic`)
    );

    const setLeagueFixtureTactic = useMutation(
        (teamTacticId) => axios.patch(`${process.env.REACT_APP_APPHOST}/leagueFixtures/${props.fixtureId}`, {
            teamTacticId
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getLeagueFixtureTactic')
            }
        }
    )

    const getTeamTacticsQuery = useQuery(
        ['getTeamTactics', leagueFixtureTacticQuery.data?.data.response.teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teams/${leagueFixtureTacticQuery.data?.data.response.teamType}/teamTactics`),
        {
            enabled: !!leagueFixtureTacticQuery.data?.data.response
        }
    );

    return (
        <TacticSelector
            isLoading={leagueFixtureTacticQuery.isLoading || getTeamTacticsQuery.isLoading}
            teamTactics={getTeamTacticsQuery.data?.data.response.teamTactics || []}
            selectedTeamTacticId={leagueFixtureTacticQuery.data?.data.response.id}
            handleSelectTactic={setLeagueFixtureTactic.mutate}
        />
    );
}

function CupTacticSelector(props) {
    const queryClient = useQueryClient();

    const leagueFixtureTacticQuery = useQuery(
        ['getCupFixtureTactic', props.fixtureId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/cupFixtures/${props.fixtureId}/teamTactic`)
    );

    const setLeagueFixtureTactic = useMutation(
        (teamTacticId) => axios.patch(`${process.env.REACT_APP_APPHOST}/cupFixtures/${props.fixtureId}`, {
            teamTacticId
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getCupFixtureTactic')
            }
        }
    )

    const getTeamTacticsQuery = useQuery(
        ['getTeamTactics', leagueFixtureTacticQuery.data?.data.response.teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teams/${leagueFixtureTacticQuery.data?.data.response.teamType}/teamTactics`),
        {
            enabled: !!leagueFixtureTacticQuery.data?.data.response
        }
    );

    return (
        <TacticSelector
            isLoading={leagueFixtureTacticQuery.isLoading || getTeamTacticsQuery.isLoading}
            teamTactics={getTeamTacticsQuery.data?.data.response.teamTactics || []}
            selectedTeamTacticId={leagueFixtureTacticQuery.data?.data.response.id}
            handleSelectTactic={setLeagueFixtureTactic.mutate}
        />
    );
}

function TacticSelector(props) {
    return(
        <Segment>
            <p>Select one of your saved tactics to use for this fixture</p>
            <Dropdown
                selection
                options={props.teamTactics.map((tactic) => (
                    {
                        value: tactic.id,
                        key: tactic.id,
                        text: `Tactic ${tactic.name} ${tactic.isActive === true ? '(active)' : ''}`
                    }
                ))}
                value={props.selectedTeamTacticId}
                onChange={(e, {value}) => props.handleSelectTactic(value)}
            />
        </Segment>
    );
}

const messages = {
    notEnoughPlayers: {
        id: "Page_Fixture.notEnoughPlayers",
        defaultMessage: "The match did not start because neither team had enough players.",
        description: "Error message which is shown if both teams do not have enough players to complete the fixture"
    },
    notEnoughPlayersOneClub: {
        id: "Page_Fixture.notEnoughPlayersOneClub",
        defaultMessage: "{clubName} forfeited the match 3-0 as they did not have enough players.",
        description: "Error message which is shown if one team does not have enough players to complete the fixture"
    },
    notEnoughPlayersErrorHeader: {
        id: "Page_Fixture.notEnoughPlayersErrorHeader",
        defaultMessage: "This match was abandoned",
        description: "Header of the error message which is shown if the match was abandoned"
    },
    fixtureTitleSeparatorNotPlayed: {
        id: "Page_Fixture.fixtureTitleSeparatorNotPlayed",
        defaultMessage: "vs.",
        description: "Separator for the fixture title (i.e. Manchester v Arsenal) when fixture has not been played yet"
    },
    fixtureTitleSeparatorPlayed: {
        id: "Page_Fixture.fixtureTitleSeparatorPlayed",
        defaultMessage: "{homeTeamScore} - {awayTeamScore}",
        description: "Separator for the fixture title (i.e. Manchester 1 - 2 Arsenal) when fixture has been played"
    },
    kickOff: {
        id: "Page_Fixture.kickOff",
        defaultMessage: "Kick off",
        description: "Name for the event at the start of the fixture when the match begins (0 minutes)"
    },
    halfTime: {
        id: "Page_Fixture.halfTime",
        defaultMessage: "Half time",
        description: "Name for the break in the match at 45 minutes"
    },
    fullTime: {
        id: "Page_Fixture.fullTime",
        defaultMessage: "Full time",
        description: "Name for the end of the game (90 minutes)"
    },
    leagueName: {
        id: "Page_Fixture.leagueName",
        defaultMessage: "League {leagueName}",
        description: "Name for the competition of the fixture"
    },
    lineUpsHeader: {
        id: "Page_Fixture.lineUpsHeader",
        defaultMessage: "Line ups",
        description: "Header for the table which contains the home and away team line ups"
    },
    attendance: {
        id: "Page_Fixture.attendance",
        defaultMessage: 'Attendance: {attendance}',
        description: 'Label for the attendance for a fixture'
    }
}
import {Menu} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {generatePath, matchPath, useLocation, useParams} from 'react-router';
import SUIIconShim from '../common/components/SUIIconShim';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDay, faChartSimple, faChild, faClockRotateLeft, faListOl, faUser } from '@fortawesome/free-solid-svg-icons';
import ChangeLeagueModal from './changeLeague/ChangeLeagueModal';
import { URL } from '../common/lib/paths';
import {useTutorial} from '../tutorial/hooks/useTutorial';

export default function LeagueSeasonMenu(props) {
    const location = useLocation();
    const {clubId, teamType} = useParams();
    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('league', 1);
    const isTutorial2 = checkTutorial('league', 2);
    const isTutorial3 = checkTutorial('league', 3);
    const isTutorial4 = checkTutorial('league', 5);
    const isTutorial5 = checkTutorial('league', 8);

    return(
        <div
            style={{
                display: 'flex'
            }}
        >
            {!!clubId && !!teamType &&
                <>
                <Menu icon inverted size='tiny' style={{margin: 0}}>
                    <Menu.Item
                        as={Link}
                        to={{
                            pathname: generatePath(URL.teamLeague, {clubId, teamType: 'first'}),
                            state: {view: location.state?.view},
                            search: isTutorial2 && getSearchParamsString('league', 3)
                        }}
                        active={!!matchPath(location.pathname, {path: generatePath(URL.teamLeague, {clubId, teamType: 'first'})})}
                        className={isTutorial2 && 'glow'}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faUser} />
                        </SUIIconShim>
                    </Menu.Item>
                    <Menu.Item
                        as={Link}
                        to={{
                            pathname: generatePath(URL.teamLeague, {clubId, teamType: 'youth'}),
                            state: {view: location.state?.view},
                            search: isTutorial1 && getSearchParamsString('league', 2)
                        }}
                        active={matchPath(location.pathname, {path: generatePath(URL.teamLeague, {clubId, teamType: 'youth'})})}
                        className={isTutorial1 && 'glow'}
                    >
                        <SUIIconShim>
                            <FontAwesomeIcon icon={faChild} />
                        </SUIIconShim>
                    </Menu.Item>
                </Menu>
                <div style={{flex: '1 auto'}}></div>
                </>
            }
            <Menu icon inverted size='tiny' style={{margin: '0 auto'}}>
                <Menu.Item
                    active={location.state.view === 'table'}
                    as={Link}
                    to={{
                        pathname: location.pathname,
                        state: {...location.state, view: 'table'},
                    }}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faListOl} />
                    </SUIIconShim>
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    active={location.state.view === 'stats'}
                    to={{
                        pathname: location.pathname,
                        state: {...location.state, view: 'stats'},
                        search: isTutorial3 && getSearchParamsString('league', 4)
                    }}
                    className={isTutorial3 && 'glow'}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faChartSimple} />
                    </SUIIconShim>
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    active={location.state.view === 'fixtures'}
                    to={{
                        pathname: location.pathname,
                        state: {...location.state, view: 'fixtures'},
                        search: isTutorial4 && getSearchParamsString('league', 6)
                    }}
                    className={isTutorial4 && 'glow'}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faCalendarDay} />
                    </SUIIconShim>
                </Menu.Item>
                <Menu.Item
                    as={Link}
                    active={location.state.view === 'history'}
                    to={{
                        pathname: location.pathname,
                        state: {...location.state, view: 'history'},
                        search: isTutorial5 && getSearchParamsString('league', 9)
                    }}
                    className={isTutorial5 && 'glow'}
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faClockRotateLeft} />
                    </SUIIconShim>
                </Menu.Item>
                <ChangeLeagueModal />
            </Menu>
        </div>
    )
}
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import { URL } from "../../common/lib/paths";

export default {
    key: 'players',
    name: 'Players',
    loaderPath: URL.playersTutorial,
    icon: faPerson,
    description: 'Learn all about your club\'s players',
    steps: [
        {
            key: 0,
            path: URL.teamPlayers,
            description: (
                <>
                    <p>Here are your players boss. First thing to notice is that the first 11s positions are highlighted black.</p>
                    <p>If you have any Injured or suspended players then it will show here as "inj" or "sus". This is a good way to review your players.</p>
                </>
            ),
            summary: 'View your players',
            task: 'browse'
        },
        {
            key: 1,
            path: URL.teamPlayers,
            description: 'Secondly you should note the button that looks like an eye, select this to change between Selection, Contract, Stats.” ',
            summary: 'Open view menu',
            task: 'browse'
        },
        {
            key: 2,
            path: URL.teamPlayers,
            description: 'The contract option shows you how long your players are contractually tied to your team. This games time is in real time, so when you see a players contract is running out tomorrow, its running out tomorrow. Stats show an overview of your team\'s performances of the current season.',
            task: 'info'
        },
        {
            key: 3,
            path: URL.teamPlayers,
            description: 'Please select one of your players names to see their player profile.',
            summary: 'Select a player',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.playerProfile,
            description: 'Scroll up and down to view the information about your player.',
            summary: 'View player profile',
            task: 'browse'
        },
        {
            key: 5,
            path: URL.playerProfile,
            description: (
                <>
                    <p>Please now focus on the Coach report section, one of the most important pieces of information in the game. This report can be generated by either your scouting department when you decide to scout a player or by your coaching department. Lets talk through it:</p>
                    <ul>
                        <li>The higher the potential the better the players attributes will be</li>
                        <li>The more stars the better</li>
                        <li>The better the stamina, the more games they can play at their base player rating and the lower the injury risk</li>
                        <li>The better the longevity, the longer the players career, peak and period a player can keep improving.</li>
                        <li>Injury risk tells you how likely a player is to get injured</li>
                        <li>Aggression tells you how likely your player is to get yellow/ red cards or give away set pieces to the opposition side</li>
                        <li>Retrain tells you if its possible to change this players position to another one</li>
                        <li>The icons at the bottom of the report are "traits" rare attributes that give players useful skills, select them to find out more.</li>
                    </ul>
                </>
            ),
            summary: 'View coach report',
            task: 'browse'
        },
        {
            key: 6,
            path: URL.playerProfile,
            description: 'Please select the contract tab.',
            summary: 'Find the contract tab',
            task: 'interaction'
        },
        {
            key: 7,
            path: URL.playerContractOfferNew,
            description: 'Here you can either accept the players demands or try to negotiate a contract extension.',
            summary: 'Have a look around',
            task: 'browse'
        },
        {
            key: 8,
            path: URL.playerContractOfferNew,
            description: 'Go back to the player profile page',
            summary: 'Select player profile',
            task: 'interaction'
        },
        {
            key: 9,
            path: URL.playerProfile,
            description: 'The next feature will come up many times in this game and is very useful, please select the compare button.',
            summary: 'Select compare player',
            task: 'interaction'
        },
        {
            key: 10,
            path: URL.playerProfile,
            description: 'Now go to the bottom left corner and select the same symbol again being careful not to click the X on the right hand side',
            summary: 'Open compare tool',
            task: 'interaction'
        },
        {
            key: 11,
            path: URL.playerCompare,
            description: 'Use the two arrows to move between your players. This will help you realise if its worth liking them or not',
            summary: 'Compare the players',
            task: 'interaction'
        },
        {
            key: 12,
            path: URL.playerCompare,
            description: 'Now go back to your players',
            summary: 'Select players tab',
            task: 'interaction'
        },
        {
            key: 13,
            path: URL.teamPlayers,
            description: 'If you wanted to do the same with your youth team it\'s simple. Select the player tab at the side of your screen and then select "The little person icon" next to "Big person icon" at the top of your screen.',
            summary: 'Select youth team',
            task: 'interaction'
        },
        {
            key: 14,
            path: URL.teamPlayers,
            description: 'Well done! You have completed the tutorial',
            summary: 'Congrats'
        }
    ]
}
import axios from 'axios';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router';
import { FormattedMessage } from 'react-intl';
import {useQuery, useMutation, useQueryClient} from '@tanstack/react-query';

import TacticsInterface from './interface';
import NarrowLayout from '../common/components/NarrowLayout';
import TeamName from '../team/common/TeamName';
import TeamPlayersToolbarItem from '../club/components/toolbar/TeamPlayers';
import TeamTacticsToolbarItem from '../club/components/toolbar/TeamTactics';
import TeamLeagueToolbarItem from '../club/components/toolbar/TeamLeague';
import TeamFixturesToolbarItem from '../club/components/toolbar/TeamFixtures';
import TeamLeagueSeasonsToolbarItem from '../club/components/toolbar/TeamLeagueSeasons';
import TeamTrainingToolbarItem from '../club/components/toolbar/TeamTraining';
import ClubStaffToolbarItem from '../club/components/toolbar/ClubStaff';
import ClubTransfersToolbarItem from '../club/components/toolbar/ClubTransfers';
import ClubProfileToolbarItem from '../club/components/toolbar/ClubProfile';

export default function Tactics(props) {   
    const {teamType} = useParams(); 
    const queryClient = useQueryClient();
    const [activeTeamTacticIndex, setActiveTeamTacticIndex] = useState(undefined);

    useEffect(() => {
        props.setHideScrollbar(true);

        return () => {
            props.setHideScrollbar(false);
        }
    }, []);

    const getTeamTacticsQuery = useQuery(
        ['getTeamTactics', teamType],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teams/${teamType}/teamTactics`),
        {
            onSuccess: (data) => {
                for(let i=0; i<data.data.response.teamTactics.length; i++) {
                    if(data.data.response.teamTactics[i].isActive === true && i !== activeTeamTacticIndex) {
                        setActiveTeamTacticIndex(i);
                    }
                }
            }
        }
    );

    const getTeamTacticQuery = useQuery(
        ['getTeamTactic', activeTeamTacticIndex],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teamTactics/${getTeamTacticsQuery.data.data.response.teamTactics[activeTeamTacticIndex].id}`),
        {
            enabled: activeTeamTacticIndex !== undefined
        }
    );

    useEffect(() => {
        if(getTeamTacticsQuery.data?.data?.response) {
            const navigation = [
                <ClubProfileToolbarItem clubId={props.manager.club.id} key={1} />,
                <TeamPlayersToolbarItem clubId={props.manager.club.id} teamType={teamType} key={2} />,
                <TeamTacticsToolbarItem isMyClub={true} teamType={teamType} key={3} />,
                <TeamTrainingToolbarItem isMyClub={true} teamType={teamType} key={4} />,
                <TeamFixturesToolbarItem clubId={props.manager.club.id} teamType={teamType} key={5} />,
                <TeamLeagueToolbarItem clubId={props.manager.club.id} teamType={teamType} key={6} />,
                <ClubStaffToolbarItem clubId={props.manager.club.id} key={7} />,
                <ClubTransfersToolbarItem clubId={props.manager.club.id} key={8} />,
                <TeamLeagueSeasonsToolbarItem clubId={props.manager.club.id} teamType={teamType} key={9} />
            ];
            
            props.setToolbar({
                title: <FormattedMessage {...messages.screenTitle} values={{teamName: <TeamName team={getTeamTacticsQuery.data.data.response} />}} />,
                navigation
            });
        }
    }, [teamType, getTeamTacticsQuery.data?.data?.response]);

    const deleteLineupMutation = useMutation(() => 
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/teams/${teamType}/teamTactics/playerRegIds`,
            {
                actions: ['unsetAll']
            }
        ),
        {
            onSuccess: () => queryClient.invalidateQueries('getTeamTactic')
        }
    );

    const fixLineupMutation = useMutation(() =>
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/teams/${teamType}/teamTactics/playerRegIds`,
            {
                actions: ['fillEmpty']
            }
        ),
        {
            onSuccess: () => queryClient.invalidateQueries('getTeamTactic')
        }
    );

    const delegateMutation = useMutation((delegate) =>
        axios.patch(
            `${process.env.REACT_APP_APPHOST}/teams/${teamType}`,
            {
                setLineup: delegate
            }
        ),
        {
            onSuccess: () => queryClient.invalidateQueries('getTactics')
        }
    );

    const updateTeamTacticPositionMutation = useMutation(
        ({teamTacticPositionId, playerRegId}) => axios.patch(`${process.env.REACT_APP_APPHOST}/teamTacticPositions/${teamTacticPositionId}`, {
            playerRegId
        }),
        {
            onSuccess: () => queryClient.invalidateQueries('getTactics')
        }
    );

    const setActiveTeamTacticMutation = useMutation(
        (teamTacticId) => axios.patch(`${process.env.REACT_APP_APPHOST}/teamTactics/${teamTacticId}`, {
            isActive: true
        }),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('getTeamTactics')
            }
        }
    )

    const handleDeleteLineup = () => {
        deleteLineupMutation.mutate();
    }

    const handleFixLineup = () => {
        fixLineupMutation.mutate();
    }

    const handleDelegate = (delegate) => {
        delegateMutation.mutate(delegate);
    }

    const handleSelectPlayer = (teamTacticPositionId, playerRegId) => {
        updateTeamTacticPositionMutation.mutate({teamTacticPositionId, playerRegId});
    }

    function handleSetActiveTeamTactic(teamTacticId) {
        setActiveTeamTacticMutation.mutate(teamTacticId);
    }

    return(
        <NarrowLayout>
            <TacticsInterface
                getTeamTacticQueryIsLoading={getTeamTacticQuery.isLoading}
                teamTactic={getTeamTacticQuery.data?.data?.response}
                activeTeamTacticIndex={activeTeamTacticIndex}
                getTeamTacticsQueryIsLoading={getTeamTacticsQuery.isLoading}
                teamTactics={getTeamTacticsQuery.data?.data?.response.teamTactics}
                deleteLineupMutationIsLoading={deleteLineupMutation.isLoading}
                fixLineupMutationIsLoading={fixLineupMutation.isLoading}
                delegateTactics={getTeamTacticsQuery.data?.data?.response.delegateTactics}
                handleDeleteLineup={handleDeleteLineup.bind(this)}
                handleFixLineup={handleFixLineup.bind(this)}
                handleDelegate={handleDelegate.bind(this)}
                clubId={props.manager?.club?.id}
                managerId={props.manager?.id}
                handleSelectPlayer={handleSelectPlayer.bind(this)}
                handleSetActiveTeamTactic={handleSetActiveTeamTactic.bind(this)}
            />
        </NarrowLayout>
    )
}

const messages = {
    screenTitle: {
        id: 'teamTactics:screenTitle',
        defaultMessage: '{teamName} tactics',
        description: 'Title for the screen showing a teams tactics'
    }
}
import { defineMessages } from "@formatjs/intl";

const messages = {
    'team:youthIntake:bestPlayer': defineMessages({
        subject: {
            id: 'messageSubject:team:youthIntake:bestPlayer',
            defaultMessage: 'Youth intake',
            description: 'Subject of the message for a youth intake'
        },
        body: {
            id: 'messageSubject:team:youthIntake:bestPlayer',
            defaultMessage: 'This season we have recruited {newPlayerCount} new players for the youth team.\n\nTheir names are {playerNames}.\n\n{bestPlayer} looks like a real talent, you should keep a close eye on their development.',
            description: 'Subject of the message for a youth intake'
        }
    }),
    'team:youthIntake:noBestPlayer': defineMessages({
        subject: {
            id: 'messageSubject:team:youthIntake:noBestPlayer',
            defaultMessage: 'Youth intake',
            description: 'Subject of the message for a youth intake'
        },
        body: {
            id: 'messageSubject:team:youthIntake:noBestPlayer',
            defaultMessage: 'This season we have recruited {newPlayerCount} new players for the youth team.\n\nTheir names are {playerNames}.\n\nUnfortunately, none of the players look like they will go on to achieve much in the game.',
            description: 'Subject of the message for a youth intake'
        }
    }),
    'team:youthIntake:noPlayers': defineMessages({
        subject: {
            id: 'messageSubject:team:youthIntake:noPlayers',
            defaultMessage: 'Youth intake',
            description: 'Subject of the message for a youth intake'
        },
        body: {
            id: 'messageSubject:team:youthIntake:noPlayers',
            defaultMessage: 'Sorry boss, we have not been able to recruit any new players into our youth team this season.\n\nIf we upgraded our facilities we will have a better chance of attracting new talent next season.',
            description: 'Subject of the message for a youth intake'
        }
    }),
    'team:extraYouthIntake': defineMessages({
        subject: {
            id: 'messageSubject:team:extraYouthIntake',
            defaultMessage: 'New youth player',
            description: 'Subject of the message for an extra youth intake'
        },
        body: {
            id: 'messageSubject:team:extraYouthIntake',
            defaultMessage: 'Hi boss,\n\nGreat news! We have managed to recruit an extra player into this season\'s youth team.\n\n{playerName} looks like a real talent, you should keep a close eye on him.',
            description: 'Subject of the message for an extra youth intake'
        }
    })
}

export default messages;
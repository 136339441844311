import { useQuery } from "@tanstack/react-query";
import axios from "axios";

export default function useGetMessages(enabled = true) {
    return useQuery(
        ['getMessages'],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/messages`),
        {
            enabled
        }
    );
}
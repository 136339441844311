import React, {Component} from "react";

export default class Component_RatingRoundall extends Component {
    hsvToRgb(hsv) {
        let h = hsv[0], s = hsv[1], v = hsv[2];

        var rgb, i, data = [];
        if (s === 0) {
            rgb = [v,v,v];
        } else {
            h = h / 60;
            i = Math.floor(h);
            data = [v*(1-s), v*(1-s*(h-i)), v*(1-s*(1-(h-i)))];
            switch(i) {
                case 0:
                    rgb = [v, data[2], data[0]];
                    break;
                case 1:
                    rgb = [data[1], v, data[0]];
                    break;
                case 2:
                    rgb = [data[0], v, data[2]];
                    break;
                case 3:
                    rgb = [data[0], data[1], v];
                    break;
                case 4:
                    rgb = [data[2], data[0], v];
                    break;
                default:
                    rgb = [v, data[0], data[1]];
                    break;
            }
        }

        return '#' + rgb.map(function(x){
            return ("0" + Math.round(x*255).toString(16)).slice(-2);
        }).join('');
    }

    ratingToHsv(rating) {
        //rating = rating * 5;

        return [
            Math.floor((rating) * (this.props.scale || 120) / 100),
            1,
            1
        ]
    }

    getColor(hexcolor) {
        var r = parseInt(hexcolor.substr(0,2),16);
        var g = parseInt(hexcolor.substr(2,2),16);
        var b = parseInt(hexcolor.substr(4,2),16);
        var yiq = ((r*299)+(g*587)+(b*114))/1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    render() {
        const style = {
            backgroundColor: this.hsvToRgb(this.ratingToHsv(this.props.rating)),
            color: this.getColor(this.hsvToRgb(this.ratingToHsv(this.props.rating)).substr(1)),
            fontSize: '0.7rem',
            border: '1px solid #000',
            width: '16px',
            height: '16px',
            lineHeight: 'normal',
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }

        return(
            <span style={{display: 'inline-block', verticalAlign: 'text-bottom', marginLeft: '.35714286em'}}><div className="ui circular label fix4" style={{...style, ...this.props.style}}>{Math.round(this.props.rating)}</div></span>
        );    }
}
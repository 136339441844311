import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import {useTutorial} from '../../tutorial/hooks/useTutorial';
import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faFileContract } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_PlayerOffer.text",
		defaultMessage: "Contract",
		description: "Link for player contract page"
	}
});

export default function PlayerContractOfferNew(props) {
	const {checkTutorial, getSearchParamsString, tutorialKey, tutorialStep} = useTutorial();

	const isTutorial = checkTutorial('players', 6)

    if(!props.isMyPlayer || props.isRetiring) return null;

	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.playerContractOfferNew, {playerId: props.playerId}),
				search: isTutorial ? getSearchParamsString(tutorialKey, tutorialStep+1) : undefined
			}}
			genericPath={URL.playerContractOfferNew} 
			icon={faFileContract}
			messages={messages}
			glow={isTutorial}
		/>
	);
}
import {useEffect, useState} from 'react';
import {useMutation} from '@tanstack/react-query';
import {useHistory} from 'react-router-dom';
import { Capacitor } from '@capacitor/core';

import {URL} from '../../common/lib/paths';
import LoginInterface from './interface';
import random from 'lodash/random';
import NarrowLayout from '../../common/components/NarrowLayout';

export default function Login(props) {
    const history = useHistory();
    
    const [loginError, setLoginError] = useState(null);

    const handleLoginSubmit = (values, actions) => {
        loginMutation.mutate({
            email: values.email,
            password: values.password,
            FCMToken: props.FCMToken,
            deviceId: props.deviceId,
            deviceType: `hybrid-${Capacitor.getPlatform()}`
        }, {
            onSettled: () => {
                actions.setSubmitting(false);
            }
        });
    }

    const loginMutation = useMutation(login =>
        fetch(
            `${process.env.REACT_APP_APPHOST}/login`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(login)
            }
        ).then((res) => res.json()),
        {
            onSuccess: data => {
                if(data.success) {
                    props.handleLogin({refreshToken: data.response.refreshToken, accessToken: data.response.accessToken});
                } else if(!data.success) {
                    switch(data.response.reason) {
                        case 'mismatch':
                            setLoginError('mismatch');
                            break;
                        default:
                            setLoginError('unknown');
                            break;
                    }
                }
            }
        }
    );

    useEffect(() => {
        props.setToolbar({title: 'Login', navigation: null});
    }, []);

    if(props.manager) {
        if(props.manager.isNewManager === true) {
            history.replace(URL.jobs, {index: random(1, 21)});
        } else if(props.manager.isNewManager === false) {
            history.replace(URL.messages);
        }
    }

    if(!props.deviceId) {
        throw new Error('deviceId is not set');
    }

    return(
        <NarrowLayout>
            <LoginInterface
                isSubmitting={loginMutation.isLoading}
                handleLoginSubmit={handleLoginSubmit.bind(this)}
                loginError={loginError}
            />
        </NarrowLayout>
    )
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Header, Image, Placeholder, Segment } from "semantic-ui-react";

export default function TitledSegment(props) {
    return(
        <>
            <Header
                as='h5'
                attached='top'
                inverted
                className='titledSegmentHeader'
            >
                {props.isLoading && !props.title ?
                    <Placeholder inverted style={{display: 'inline-block', width: '60%', '&after:backgroundColor': 'rgb(27 29 30)'}}>
                        <Placeholder.Header>
                            <Placeholder.Line length='full' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    props.title
                }
                {props.icon &&
                    <FontAwesomeIcon icon={props.icon} />
                }
            </Header>
            <Segment
                attached
                className={`titledSegment${props.noPadding === true ? ' noPadding' : ''}${props.className ? ` ${props.className}` : ''}`}
                style={props.contentStyle}
            >
                {props.isLoading && !!props.placeholder ?
                    <props.placeholder />
                    :
                    props.children
                }
            </Segment>
        </>
    )
}
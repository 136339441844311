import { useEffect } from "react";
import useGetClubs from "../../../club/useGetClubs";
import WideLayout from "../../../common/components/WideLayout";
import ClubFilterButton from "../../../club/filter/triggers/button";
import { filterStateReducer, initialFilterState, isFilterActive } from "../../../club/filter/lib";
import ClubListView from "../../../club/components/lists/ClubListView";
import { Button } from "semantic-ui-react";
import SUIIconShim from "../../../common/components/SUIIconShim";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import WorldNationsToolbarItem from "../toolbar/WorldNationsToolbarItem";
import WorldClubsToolbarItem from "../toolbar/WorldClubsToolbarItem";
import WorldRecordsToolbarItem from "../toolbar/WorldRecordsToolbarItem";
import usePersistedState from "../../../common/lib/usePersistedState";
import WorldCupsToolbarItem from "../toolbar/WorldCupsToolbarItem";

const PERSISTED_STATE_KEY = 'worldClubsFilter';

export default function WorldClubs(props) {
    const {persistedState, updatePersistedState} = usePersistedState(PERSISTED_STATE_KEY, initialFilterState);

    useEffect(() => {
        props.setToolbar({
            title: 'All clubs',
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        })
    }, []);

    const {isLoading, data} = useGetClubs(persistedState, false, true);

    return(
        <WideLayout>
            <ClubFilterButton
                loading={isLoading}
                isFilterActive={isFilterActive(persistedState)}
                persistedStateKey={PERSISTED_STATE_KEY}
            />
            <ClubListView
                isLoading={isLoading}
                clubs={data?.data.response}
            />
            <Button.Group>
                <Button
                    icon
                    disabled={persistedState.page === 1}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'previousPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </SUIIconShim>
                </Button>
                <Button
                    disabled
                    className="opacity-override"
                    color='black'
                    style={{width: '10em'}}
                >
                    Page {persistedState.page}
                </Button>
                <Button
                    icon
                    disabled={data?.data?.response.length < 10}
                    onClick={() => updatePersistedState(filterStateReducer(persistedState, {name: 'nextPage'}))}
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faArrowRight} />
                    </SUIIconShim>
                </Button>
            </Button.Group>
        </WideLayout>
    )
}
import axios from "axios";

function fetchRecords(record, timePeriod, nationGroupShortcode, competition) {
    const searchParams = new URLSearchParams({record});

    if(!!timePeriod) {
        searchParams.append('timePeriod', timePeriod);
    }

    if(!!nationGroupShortcode) {
        searchParams.append('nationGroupShortcode', nationGroupShortcode);
}

    if(!!competition) {
        searchParams.append('competition', competition);
    }

    return axios.get(`${process.env.REACT_APP_APPHOST}/world/records/players?${searchParams}`)
}

export {
    fetchRecords
}
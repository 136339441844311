import {Component} from 'react';
import {Message} from 'semantic-ui-react';

export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            hasError: false,
            errorMessage: null
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorMessage: error.message };
      }

      componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
      }

    render() {
        if (this.state.hasError) {
            return(
                <Message negative>
                    <Message.Header>Something went wrong</Message.Header>
                    <p>Please use the feedback button to help us fix it</p>
                    <p>{this.state.errorMessage}</p>
                </Message>
            );
        }

        return this.props.children; 
    }
}
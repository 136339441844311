import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import {URL} from '../../common/lib/paths';

export default {
    key: 'messages',
    name: 'Messages',
    loaderPath: URL.messagesTutorial,
    icon: faEnvelope,
    description: 'Manage your mailbox',
    steps: [
        {
            key: 0,
            path: URL.messages,
            description: 'Welcome to your Inbox. Here you will receive messages from members of staff, the board, news regarding transfers and my maker',
            summary: 'Explore your inbox',
            task: 'browse'
        },
        {
            key: 1,
            path: URL.messages,
            description: 'If you have a message please access it by selecting the header beneath the word “subject”',
            summary: 'Open a message',
            task: 'interaction'
        },
        {
            key: 2,
            path: URL.message,
            description: 'After you have read your message, go back by selecting the go back arrow',
            summary: 'Go back to inbox',
            task: 'interaction'
        },
        {
            key: 3,
            path: URL.messages,
            description: 'If you want to delete a message, select the singular “tick” icon to individually select your messages or the double “tick icon” to select all of your messages.',
            summary: 'Select a message',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.messages,
            description: 'select the bin Icon to delete them.',
            summary: 'Delete messages',
            task: 'interaction'
        },
        {
            key: 5,
            path: URL.messages,
            description: 'Well done! You have completed the tutorial',
            summary: 'Congrats'
        }
    ]
}
export default {
    managerNameLabel: {
        id: "NewManager.managerNameLabel",
        defaultMessage: "Manager name",
        description: "Label for the input field where the user enters their desired manager name"
    },
    formSubmit: {
        id: "NewManager.formSubmit",
        defaultMessage: "Create",
        description: "Value of the button which creates the user's manager profile"
    },
    "e1": {
        id: "Component_NationalityText.england",
        defaultMessage: "England",
        description: "Name of the country 'England'. Note: this is England; NOT Britain, United Kingdom, Great Britain, etc" 
    },
    "s1": {
        id: "Component_NationalityText.scotland",
        defaultMessage: "Scotland",
        description: "Name of the country 'Scotland'"
    },
    "w1": {
        id: "Component_NationalityText.wales",
        defaultMessage: "Wales",
        description: "Name of the country 'Wales'"
    },
    "ad":{  
        "id":"Component_NationalityText.andorra",
        "defaultMessage":"Andorra",
        "description":"Name of the country 'Andorra'. Country code 'ad' in ISO-3166"
    },
    "ae":{  
        "id":"Component_NationalityText.uae",
        "defaultMessage":"U.A.E",
        "description":"Name of the country 'U.A.E'. Country code 'ae' in ISO-3166"
    },
    "af":{  
        "id":"Component_NationalityText.afghanistan",
        "defaultMessage":"Afghanistan",
        "description":"Name of the country 'Afghanistan'. Country code 'af' in ISO-3166"
    },
    "ag":{  
        "id":"Component_NationalityText.antigua",
        "defaultMessage":"Antigua",
        "description":"Name of the country 'Antigua'. Country code 'ag' in ISO-3166"
    },
    "ai":{  
        "id":"Component_NationalityText.anguilla",
        "defaultMessage":"Anguilla",
        "description":"Name of the country 'Anguilla'. Country code 'ai' in ISO-3166"
    },
    "al":{  
        "id":"Component_NationalityText.albania",
        "defaultMessage":"Albania",
        "description":"Name of the country 'Albania'. Country code 'al' in ISO-3166"
    },
    "am":{  
        "id":"Component_NationalityText.armenia",
        "defaultMessage":"Armenia",
        "description":"Name of the country 'Armenia'. Country code 'am' in ISO-3166"
    },
    "an":{  
        "id":"Component_NationalityText.netherlandsantilles",
        "defaultMessage":"Netherlands Antilles",
        "description":"Name of the country 'Netherlands Antilles'. Country code 'an' in ISO-3166"
    },
    "ao":{  
        "id":"Component_NationalityText.angola",
        "defaultMessage":"Angola",
        "description":"Name of the country 'Angola'. Country code 'ao' in ISO-3166"
    },
    "ar":{  
        "id":"Component_NationalityText.argentina",
        "defaultMessage":"Argentina",
        "description":"Name of the country 'Argentina'. Country code 'ar' in ISO-3166"
    },
    "as":{  
        "id":"Component_NationalityText.americansamoa",
        "defaultMessage":"American Samoa",
        "description":"Name of the country 'American Samoa'. Country code 'as' in ISO-3166"
    },
    "at":{  
        "id":"Component_NationalityText.austria",
        "defaultMessage":"Austria",
        "description":"Name of the country 'Austria'. Country code 'at' in ISO-3166"
    },
    "au":{  
        "id":"Component_NationalityText.australia",
        "defaultMessage":"Australia",
        "description":"Name of the country 'Australia'. Country code 'au' in ISO-3166"
    },
    "aw":{  
        "id":"Component_NationalityText.aruba",
        "defaultMessage":"Aruba",
        "description":"Name of the country 'Aruba'. Country code 'aw' in ISO-3166"
    },
    "ax":{  
        "id":"Component_NationalityText.alandislands",
        "defaultMessage":"Aland Islands",
        "description":"Name of the country 'Aland Islands'. Country code 'ax' in ISO-3166"
    },
    "az":{  
        "id":"Component_NationalityText.azerbaijan",
        "defaultMessage":"Azerbaijan",
        "description":"Name of the country 'Azerbaijan'. Country code 'az' in ISO-3166"
    },
    "ba":{  
        "id":"Component_NationalityText.bosnia",
        "defaultMessage":"Bosnia",
        "description":"Name of the country 'Bosnia'. Country code 'ba' in ISO-3166"
    },
    "bb":{  
        "id":"Component_NationalityText.barbados",
        "defaultMessage":"Barbados",
        "description":"Name of the country 'Barbados'. Country code 'bb' in ISO-3166"
    },
    "bd":{  
        "id":"Component_NationalityText.bangladesh",
        "defaultMessage":"Bangladesh",
        "description":"Name of the country 'Bangladesh'. Country code 'bd' in ISO-3166"
    },
    "be":{  
        "id":"Component_NationalityText.belgium",
        "defaultMessage":"Belgium",
        "description":"Name of the country 'Belgium'. Country code 'be' in ISO-3166"
    },
    "bf":{  
        "id":"Component_NationalityText.burkinafaso",
        "defaultMessage":"Burkina Faso",
        "description":"Name of the country 'Burkina Faso'. Country code 'bf' in ISO-3166"
    },
    "bg":{  
        "id":"Component_NationalityText.bulgaria",
        "defaultMessage":"Bulgaria",
        "description":"Name of the country 'Bulgaria'. Country code 'bg' in ISO-3166"
    },
    "bh":{  
        "id":"Component_NationalityText.bahrain",
        "defaultMessage":"Bahrain",
        "description":"Name of the country 'Bahrain'. Country code 'bh' in ISO-3166"
    },
    "bi":{  
        "id":"Component_NationalityText.burundi",
        "defaultMessage":"Burundi",
        "description":"Name of the country 'Burundi'. Country code 'bi' in ISO-3166"
    },
    "bj":{  
        "id":"Component_NationalityText.benin",
        "defaultMessage":"Benin",
        "description":"Name of the country 'Benin'. Country code 'bj' in ISO-3166"
    },
    "bm":{  
        "id":"Component_NationalityText.bermuda",
        "defaultMessage":"Bermuda",
        "description":"Name of the country 'Bermuda'. Country code 'bm' in ISO-3166"
    },
    "bn":{  
        "id":"Component_NationalityText.brunei",
        "defaultMessage":"Brunei",
        "description":"Name of the country 'Brunei'. Country code 'bn' in ISO-3166"
    },
    "bo":{  
        "id":"Component_NationalityText.bolivia",
        "defaultMessage":"Bolivia",
        "description":"Name of the country 'Bolivia'. Country code 'bo' in ISO-3166"
    },
    "br":{  
        "id":"Component_NationalityText.brazil",
        "defaultMessage":"Brazil",
        "description":"Name of the country 'Brazil'. Country code 'br' in ISO-3166"
    },
    "bs":{  
        "id":"Component_NationalityText.bahamas",
        "defaultMessage":"Bahamas",
        "description":"Name of the country 'Bahamas'. Country code 'bs' in ISO-3166"
    },
    "bt":{  
        "id":"Component_NationalityText.bhutan",
        "defaultMessage":"Bhutan",
        "description":"Name of the country 'Bhutan'. Country code 'bt' in ISO-3166"
    },
    "bv":{  
        "id":"Component_NationalityText.bouvetisland",
        "defaultMessage":"Bouvet Island",
        "description":"Name of the country 'Bouvet Island'. Country code 'bv' in ISO-3166"
    },
    "bw":{  
        "id":"Component_NationalityText.botswana",
        "defaultMessage":"Botswana",
        "description":"Name of the country 'Botswana'. Country code 'bw' in ISO-3166"
    },
    "by":{  
        "id":"Component_NationalityText.belarus",
        "defaultMessage":"Belarus",
        "description":"Name of the country 'Belarus'. Country code 'by' in ISO-3166"
    },
    "bz":{  
        "id":"Component_NationalityText.belize",
        "defaultMessage":"Belize",
        "description":"Name of the country 'Belize'. Country code 'bz' in ISO-3166"
    },
    "ca":{  
        "id":"Component_NationalityText.canada",
        "defaultMessage":"Canada",
        "description":"Name of the country 'Canada'. Country code 'ca' in ISO-3166"
    },
    "cc":{  
        "id":"Component_NationalityText.cocosislands",
        "defaultMessage":"Cocos Islands",
        "description":"Name of the country 'Cocos Islands'. Country code 'cc' in ISO-3166"
    },
    "cd":{  
        "id":"Component_NationalityText.congo",
        "defaultMessage":"Congo",
        "description":"Name of the country 'Congo'. Country code 'cd' in ISO-3166"
    },
    "cf":{  
        "id":"Component_NationalityText.centralafricanrepublic",
        "defaultMessage":"Central African Republic",
        "description":"Name of the country 'Central African Republic'. Country code 'cf' in ISO-3166"
    },
    "cg":{  
        "id":"Component_NationalityText.congobrazzaville",
        "defaultMessage":"Congo Brazzaville",
        "description":"Name of the country 'Congo Brazzaville'. Country code 'cg' in ISO-3166"
    },
    "ch":{  
        "id":"Component_NationalityText.switzerland",
        "defaultMessage":"Switzerland",
        "description":"Name of the country 'Switzerland'. Country code 'ch' in ISO-3166"
    },
    "ci":{  
        "id":"Component_NationalityText.cotedivoire",
        "defaultMessage":"Cote Divoire",
        "description":"Name of the country 'Cote Divoire'. Country code 'ci' in ISO-3166"
    },
    "ck":{  
        "id":"Component_NationalityText.cookislands",
        "defaultMessage":"Cook Islands",
        "description":"Name of the country 'Cook Islands'. Country code 'ck' in ISO-3166"
    },
    "cl":{  
        "id":"Component_NationalityText.chile",
        "defaultMessage":"Chile",
        "description":"Name of the country 'Chile'. Country code 'cl' in ISO-3166"
    },
    "cm":{  
        "id":"Component_NationalityText.cameroon",
        "defaultMessage":"Cameroon",
        "description":"Name of the country 'Cameroon'. Country code 'cm' in ISO-3166"
    },
    "cn":{  
        "id":"Component_NationalityText.china",
        "defaultMessage":"China",
        "description":"Name of the country 'China'. Country code 'cn' in ISO-3166"
    },
    "co":{  
        "id":"Component_NationalityText.colombia",
        "defaultMessage":"Colombia",
        "description":"Name of the country 'Colombia'. Country code 'co' in ISO-3166"
    },
    "cr":{  
        "id":"Component_NationalityText.costarica",
        "defaultMessage":"Costa Rica",
        "description":"Name of the country 'Costa Rica'. Country code 'cr' in ISO-3166"
    },
    "cu":{  
        "id":"Component_NationalityText.cuba",
        "defaultMessage":"Cuba",
        "description":"Name of the country 'Cuba'. Country code 'cu' in ISO-3166"
    },
    "cv":{  
        "id":"Component_NationalityText.capeverde",
        "defaultMessage":"Cape Verde",
        "description":"Name of the country 'Cape Verde'. Country code 'cv' in ISO-3166"
    },
    "cx":{  
        "id":"Component_NationalityText.christmasisland",
        "defaultMessage":"Christmas Island",
        "description":"Name of the country 'Christmas Island'. Country code 'cx' in ISO-3166"
    },
    "cy":{  
        "id":"Component_NationalityText.cyprus",
        "defaultMessage":"Cyprus",
        "description":"Name of the country 'Cyprus'. Country code 'cy' in ISO-3166"
    },
    "cz":{  
        "id":"Component_NationalityText.czechrepublic",
        "defaultMessage":"Czech Republic",
        "description":"Name of the country 'Czech Republic'. Country code 'cz' in ISO-3166"
    },
    "de":{  
        "id":"Component_NationalityText.germany",
        "defaultMessage":"Germany",
        "description":"Name of the country 'Germany'. Country code 'de' in ISO-3166"
    },
    "dj":{  
        "id":"Component_NationalityText.djibouti",
        "defaultMessage":"Djibouti",
        "description":"Name of the country 'Djibouti'. Country code 'dj' in ISO-3166"
    },
    "dk":{  
        "id":"Component_NationalityText.denmark",
        "defaultMessage":"Denmark",
        "description":"Name of the country 'Denmark'. Country code 'dk' in ISO-3166"
    },
    "dm":{  
        "id":"Component_NationalityText.dominica",
        "defaultMessage":"Dominica",
        "description":"Name of the country 'Dominica'. Country code 'dm' in ISO-3166"
    },
    "do":{  
        "id":"Component_NationalityText.dominicanrepublic",
        "defaultMessage":"Dominican Republic",
        "description":"Name of the country 'Dominican Republic'. Country code 'do' in ISO-3166"
    },
    "dz":{  
        "id":"Component_NationalityText.algeria",
        "defaultMessage":"Algeria",
        "description":"Name of the country 'Algeria'. Country code 'dz' in ISO-3166"
    },
    "ec":{  
        "id":"Component_NationalityText.ecuador",
        "defaultMessage":"Ecuador",
        "description":"Name of the country 'Ecuador'. Country code 'ec' in ISO-3166"
    },
    "ee":{  
        "id":"Component_NationalityText.estonia",
        "defaultMessage":"Estonia",
        "description":"Name of the country 'Estonia'. Country code 'ee' in ISO-3166"
    },
    "eg":{  
        "id":"Component_NationalityText.egypt",
        "defaultMessage":"Egypt",
        "description":"Name of the country 'Egypt'. Country code 'eg' in ISO-3166"
    },
    "eh":{  
        "id":"Component_NationalityText.westernsahara",
        "defaultMessage":"Western Sahara",
        "description":"Name of the country 'Western Sahara'. Country code 'eh' in ISO-3166"
    },
    "er":{  
        "id":"Component_NationalityText.eritrea",
        "defaultMessage":"Eritrea",
        "description":"Name of the country 'Eritrea'. Country code 'er' in ISO-3166"
    },
    "es":{  
        "id":"Component_NationalityText.spain",
        "defaultMessage":"Spain",
        "description":"Name of the country 'Spain'. Country code 'es' in ISO-3166"
    },
    "et":{  
        "id":"Component_NationalityText.ethiopia",
        "defaultMessage":"Ethiopia",
        "description":"Name of the country 'Ethiopia'. Country code 'et' in ISO-3166"
    },
    "fi":{  
        "id":"Component_NationalityText.finland",
        "defaultMessage":"Finland",
        "description":"Name of the country 'Finland'. Country code 'fi' in ISO-3166"
    },
    "fj":{  
        "id":"Component_NationalityText.fiji",
        "defaultMessage":"Fiji",
        "description":"Name of the country 'Fiji'. Country code 'fj' in ISO-3166"
    },
    "fk":{  
        "id":"Component_NationalityText.falklandislands",
        "defaultMessage":"Falkland Islands",
        "description":"Name of the country 'Falkland Islands'. Country code 'fk' in ISO-3166"
    },
    "fm":{  
        "id":"Component_NationalityText.micronesia",
        "defaultMessage":"Micronesia",
        "description":"Name of the country 'Micronesia'. Country code 'fm' in ISO-3166"
    },
    "fo":{  
        "id":"Component_NationalityText.faroeislands",
        "defaultMessage":"Faroe Islands",
        "description":"Name of the country 'Faroe Islands'. Country code 'fo' in ISO-3166"
    },
    "fr":{  
        "id":"Component_NationalityText.france",
        "defaultMessage":"France",
        "description":"Name of the country 'France'. Country code 'fr' in ISO-3166"
    },
    "ga":{  
        "id":"Component_NationalityText.gabon",
        "defaultMessage":"Gabon",
        "description":"Name of the country 'Gabon'. Country code 'ga' in ISO-3166"
    },
    "gd":{  
        "id":"Component_NationalityText.grenada",
        "defaultMessage":"Grenada",
        "description":"Name of the country 'Grenada'. Country code 'gd' in ISO-3166"
    },
    "ge":{  
        "id":"Component_NationalityText.georgia",
        "defaultMessage":"Georgia",
        "description":"Name of the country 'Georgia'. Country code 'ge' in ISO-3166"
    },
    "gf":{  
        "id":"Component_NationalityText.frenchguiana",
        "defaultMessage":"French Guiana",
        "description":"Name of the country 'French Guiana'. Country code 'gf' in ISO-3166"
    },
    "gh":{  
        "id":"Component_NationalityText.ghana",
        "defaultMessage":"Ghana",
        "description":"Name of the country 'Ghana'. Country code 'gh' in ISO-3166"
    },
    "gi":{  
        "id":"Component_NationalityText.gibraltar",
        "defaultMessage":"Gibraltar",
        "description":"Name of the country 'Gibraltar'. Country code 'gi' in ISO-3166"
    },
    "gl":{  
        "id":"Component_NationalityText.greenland",
        "defaultMessage":"Greenland",
        "description":"Name of the country 'Greenland'. Country code 'gl' in ISO-3166"
    },
    "gm":{  
        "id":"Component_NationalityText.gambia",
        "defaultMessage":"Gambia",
        "description":"Name of the country 'Gambia'. Country code 'gm' in ISO-3166"
    },
    "gn":{  
        "id":"Component_NationalityText.guinea",
        "defaultMessage":"Guinea",
        "description":"Name of the country 'Guinea'. Country code 'gn' in ISO-3166"
    },
    "gp":{  
        "id":"Component_NationalityText.guadeloupe",
        "defaultMessage":"Guadeloupe",
        "description":"Name of the country 'Guadeloupe'. Country code 'gp' in ISO-3166"
    },
    "gq":{  
        "id":"Component_NationalityText.equatorialguinea",
        "defaultMessage":"Equatorial Guinea",
        "description":"Name of the country 'Equatorial Guinea'. Country code 'gq' in ISO-3166"
    },
    "gr":{  
        "id":"Component_NationalityText.greece",
        "defaultMessage":"Greece",
        "description":"Name of the country 'Greece'. Country code 'gr' in ISO-3166"
    },
    "gs":{  
        "id":"Component_NationalityText.sandwichislands",
        "defaultMessage":"Sandwich Islands",
        "description":"Name of the country 'Sandwich Islands'. Country code 'gs' in ISO-3166"
    },
    "gt":{  
        "id":"Component_NationalityText.guatemala",
        "defaultMessage":"Guatemala",
        "description":"Name of the country 'Guatemala'. Country code 'gt' in ISO-3166"
    },
    "gu":{  
        "id":"Component_NationalityText.guam",
        "defaultMessage":"Guam",
        "description":"Name of the country 'Guam'. Country code 'gu' in ISO-3166"
    },
    "gw":{  
        "id":"Component_NationalityText.guineabissau",
        "defaultMessage":"Guinea-bissau",
        "description":"Name of the country 'Guinea-bissau'. Country code 'gw' in ISO-3166"
    },
    "gy":{  
        "id":"Component_NationalityText.guyana",
        "defaultMessage":"Guyana",
        "description":"Name of the country 'Guyana'. Country code 'gy' in ISO-3166"
    },
    "hk":{  
        "id":"Component_NationalityText.hongkong",
        "defaultMessage":"Hong Kong",
        "description":"Name of the country 'Hong Kong'. Country code 'hk' in ISO-3166"
    },
    "hm":{  
        "id":"Component_NationalityText.heardisland",
        "defaultMessage":"Heard Island",
        "description":"Name of the country 'Heard Island'. Country code 'hm' in ISO-3166"
    },
    "hn":{  
        "id":"Component_NationalityText.honduras",
        "defaultMessage":"Honduras",
        "description":"Name of the country 'Honduras'. Country code 'hn' in ISO-3166"
    },
    "hr":{  
        "id":"Component_NationalityText.croatia",
        "defaultMessage":"Croatia",
        "description":"Name of the country 'Croatia'. Country code 'hr' in ISO-3166"
    },
    "ht":{  
        "id":"Component_NationalityText.haiti",
        "defaultMessage":"Haiti",
        "description":"Name of the country 'Haiti'. Country code 'ht' in ISO-3166"
    },
    "hu":{  
        "id":"Component_NationalityText.hungary",
        "defaultMessage":"Hungary",
        "description":"Name of the country 'Hungary'. Country code 'hu' in ISO-3166"
    },
    "id":{  
        "id":"Component_NationalityText.indonesia",
        "defaultMessage":"Indonesia",
        "description":"Name of the country 'Indonesia'. Country code 'id' in ISO-3166"
    },
    "ie":{  
        "id":"Component_NationalityText.ireland",
        "defaultMessage":"Ireland",
        "description":"Name of the country 'Ireland'. Country code 'ie' in ISO-3166"
    },
    "il":{  
        "id":"Component_NationalityText.israel",
        "defaultMessage":"Israel",
        "description":"Name of the country 'Israel'. Country code 'il' in ISO-3166"
    },
    "in":{  
        "id":"Component_NationalityText.india",
        "defaultMessage":"India",
        "description":"Name of the country 'India'. Country code 'in' in ISO-3166"
    },
    "io":{  
        "id":"Component_NationalityText.indianoceanterritory",
        "defaultMessage":"Indian Ocean Territory",
        "description":"Name of the country 'Indian Ocean Territory'. Country code 'io' in ISO-3166"
    },
    "iq":{  
        "id":"Component_NationalityText.iraq",
        "defaultMessage":"Iraq",
        "description":"Name of the country 'Iraq'. Country code 'iq' in ISO-3166"
    },
    "ir":{  
        "id":"Component_NationalityText.iran",
        "defaultMessage":"Iran",
        "description":"Name of the country 'Iran'. Country code 'ir' in ISO-3166"
    },
    "is":{  
        "id":"Component_NationalityText.iceland",
        "defaultMessage":"Iceland",
        "description":"Name of the country 'Iceland'. Country code 'is' in ISO-3166"
    },
    "it":{  
        "id":"Component_NationalityText.italy",
        "defaultMessage":"Italy",
        "description":"Name of the country 'Italy'. Country code 'it' in ISO-3166"
    },
    "jm":{  
        "id":"Component_NationalityText.jamaica",
        "defaultMessage":"Jamaica",
        "description":"Name of the country 'Jamaica'. Country code 'jm' in ISO-3166"
    },
    "jo":{  
        "id":"Component_NationalityText.jordan",
        "defaultMessage":"Jordan",
        "description":"Name of the country 'Jordan'. Country code 'jo' in ISO-3166"
    },
    "jp":{  
        "id":"Component_NationalityText.japan",
        "defaultMessage":"Japan",
        "description":"Name of the country 'Japan'. Country code 'jp' in ISO-3166"
    },
    "ke":{  
        "id":"Component_NationalityText.kenya",
        "defaultMessage":"Kenya",
        "description":"Name of the country 'Kenya'. Country code 'ke' in ISO-3166"
    },
    "kg":{  
        "id":"Component_NationalityText.kyrgyzstan",
        "defaultMessage":"Kyrgyzstan",
        "description":"Name of the country 'Kyrgyzstan'. Country code 'kg' in ISO-3166"
    },
    "kh":{  
        "id":"Component_NationalityText.cambodia",
        "defaultMessage":"Cambodia",
        "description":"Name of the country 'Cambodia'. Country code 'kh' in ISO-3166"
    },
    "ki":{  
        "id":"Component_NationalityText.kiribati",
        "defaultMessage":"Kiribati",
        "description":"Name of the country 'Kiribati'. Country code 'ki' in ISO-3166"
    },
    "km":{  
        "id":"Component_NationalityText.comoros",
        "defaultMessage":"Comoros",
        "description":"Name of the country 'Comoros'. Country code 'km' in ISO-3166"
    },
    "kn":{  
        "id":"Component_NationalityText.saintkittsandnevis",
        "defaultMessage":"Saint Kitts And Nevis",
        "description":"Name of the country 'Saint Kitts And Nevis'. Country code 'kn' in ISO-3166"
    },
    "kp":{  
        "id":"Component_NationalityText.northkorea",
        "defaultMessage":"North Korea",
        "description":"Name of the country 'North Korea'. Country code 'kp' in ISO-3166"
    },
    "kr":{  
        "id":"Component_NationalityText.southkorea",
        "defaultMessage":"South Korea",
        "description":"Name of the country 'South Korea'. Country code 'kr' in ISO-3166"
    },
    "kw":{  
        "id":"Component_NationalityText.kuwait",
        "defaultMessage":"Kuwait",
        "description":"Name of the country 'Kuwait'. Country code 'kw' in ISO-3166"
    },
    "ky":{  
        "id":"Component_NationalityText.caymanislands",
        "defaultMessage":"Cayman Islands",
        "description":"Name of the country 'Cayman Islands'. Country code 'ky' in ISO-3166"
    },
    "kz":{  
        "id":"Component_NationalityText.kazakhstan",
        "defaultMessage":"Kazakhstan",
        "description":"Name of the country 'Kazakhstan'. Country code 'kz' in ISO-3166"
    },
    "la":{  
        "id":"Component_NationalityText.laos",
        "defaultMessage":"Laos",
        "description":"Name of the country 'Laos'. Country code 'la' in ISO-3166"
    },
    "lb":{  
        "id":"Component_NationalityText.lebanon",
        "defaultMessage":"Lebanon",
        "description":"Name of the country 'Lebanon'. Country code 'lb' in ISO-3166"
    },
    "lc":{  
        "id":"Component_NationalityText.saintlucia",
        "defaultMessage":"Saint Lucia",
        "description":"Name of the country 'Saint Lucia'. Country code 'lc' in ISO-3166"
    },
    "li":{  
        "id":"Component_NationalityText.liechtenstein",
        "defaultMessage":"Liechtenstein",
        "description":"Name of the country 'Liechtenstein'. Country code 'li' in ISO-3166"
    },
    "lk":{  
        "id":"Component_NationalityText.srilanka",
        "defaultMessage":"Sri Lanka",
        "description":"Name of the country 'Sri Lanka'. Country code 'lk' in ISO-3166"
    },
    "lr":{  
        "id":"Component_NationalityText.liberia",
        "defaultMessage":"Liberia",
        "description":"Name of the country 'Liberia'. Country code 'lr' in ISO-3166"
    },
    "ls":{  
        "id":"Component_NationalityText.lesotho",
        "defaultMessage":"Lesotho",
        "description":"Name of the country 'Lesotho'. Country code 'ls' in ISO-3166"
    },
    "lt":{  
        "id":"Component_NationalityText.lithuania",
        "defaultMessage":"Lithuania",
        "description":"Name of the country 'Lithuania'. Country code 'lt' in ISO-3166"
    },
    "lu":{  
        "id":"Component_NationalityText.luxembourg",
        "defaultMessage":"Luxembourg",
        "description":"Name of the country 'Luxembourg'. Country code 'lu' in ISO-3166"
    },
    "lv":{  
        "id":"Component_NationalityText.latvia",
        "defaultMessage":"Latvia",
        "description":"Name of the country 'Latvia'. Country code 'lv' in ISO-3166"
    },
    "ly":{  
        "id":"Component_NationalityText.libya",
        "defaultMessage":"Libya",
        "description":"Name of the country 'Libya'. Country code 'ly' in ISO-3166"
    },
    "ma":{  
        "id":"Component_NationalityText.morocco",
        "defaultMessage":"Morocco",
        "description":"Name of the country 'Morocco'. Country code 'ma' in ISO-3166"
    },
    "mc":{  
        "id":"Component_NationalityText.monaco",
        "defaultMessage":"Monaco",
        "description":"Name of the country 'Monaco'. Country code 'mc' in ISO-3166"
    },
    "md":{  
        "id":"Component_NationalityText.moldova",
        "defaultMessage":"Moldova",
        "description":"Name of the country 'Moldova'. Country code 'md' in ISO-3166"
    },
    "me":{  
        "id":"Component_NationalityText.montenegro",
        "defaultMessage":"Montenegro",
        "description":"Name of the country 'Montenegro'. Country code 'me' in ISO-3166"
    },
    "mg":{  
        "id":"Component_NationalityText.madagascar",
        "defaultMessage":"Madagascar",
        "description":"Name of the country 'Madagascar'. Country code 'mg' in ISO-3166"
    },
    "mh":{  
        "id":"Component_NationalityText.marshallislands",
        "defaultMessage":"Marshall Islands",
        "description":"Name of the country 'Marshall Islands'. Country code 'mh' in ISO-3166"
    },
    "mk":{  
        "id":"Component_NationalityText.macedonia",
        "defaultMessage":"Macedonia",
        "description":"Name of the country 'Macedonia'. Country code 'mk' in ISO-3166"
    },
    "ml":{  
        "id":"Component_NationalityText.mali",
        "defaultMessage":"Mali",
        "description":"Name of the country 'Mali'. Country code 'ml' in ISO-3166"
    },
    "mm":{  
        "id":"Component_NationalityText.burma",
        "defaultMessage":"Burma",
        "description":"Name of the country 'Burma'. Country code 'mm' in ISO-3166"
    },
    "mn":{  
        "id":"Component_NationalityText.mongolia",
        "defaultMessage":"Mongolia",
        "description":"Name of the country 'Mongolia'. Country code 'mn' in ISO-3166"
    },
    "mo":{  
        "id":"Component_NationalityText.macau",
        "defaultMessage":"Macau",
        "description":"Name of the country 'Macau'. Country code 'mo' in ISO-3166"
    },
    "mp":{  
        "id":"Component_NationalityText.northernmarianaislands",
        "defaultMessage":"Northern Mariana Islands",
        "description":"Name of the country 'Northern Mariana Islands'. Country code 'mp' in ISO-3166"
    },
    "mq":{  
        "id":"Component_NationalityText.martinique",
        "defaultMessage":"Martinique",
        "description":"Name of the country 'Martinique'. Country code 'mq' in ISO-3166"
    },
    "mr":{  
        "id":"Component_NationalityText.mauritania",
        "defaultMessage":"Mauritania",
        "description":"Name of the country 'Mauritania'. Country code 'mr' in ISO-3166"
    },
    "ms":{  
        "id":"Component_NationalityText.montserrat",
        "defaultMessage":"Montserrat",
        "description":"Name of the country 'Montserrat'. Country code 'ms' in ISO-3166"
    },
    "mt":{  
        "id":"Component_NationalityText.malta",
        "defaultMessage":"Malta",
        "description":"Name of the country 'Malta'. Country code 'mt' in ISO-3166"
    },
    "mu":{  
        "id":"Component_NationalityText.mauritius",
        "defaultMessage":"Mauritius",
        "description":"Name of the country 'Mauritius'. Country code 'mu' in ISO-3166"
    },
    "mv":{  
        "id":"Component_NationalityText.maldives",
        "defaultMessage":"Maldives",
        "description":"Name of the country 'Maldives'. Country code 'mv' in ISO-3166"
    },
    "mw":{  
        "id":"Component_NationalityText.malawi",
        "defaultMessage":"Malawi",
        "description":"Name of the country 'Malawi'. Country code 'mw' in ISO-3166"
    },
    "mx":{  
        "id":"Component_NationalityText.mexico",
        "defaultMessage":"Mexico",
        "description":"Name of the country 'Mexico'. Country code 'mx' in ISO-3166"
    },
    "my":{  
        "id":"Component_NationalityText.malaysia",
        "defaultMessage":"Malaysia",
        "description":"Name of the country 'Malaysia'. Country code 'my' in ISO-3166"
    },
    "mz":{  
        "id":"Component_NationalityText.mozambique",
        "defaultMessage":"Mozambique",
        "description":"Name of the country 'Mozambique'. Country code 'mz' in ISO-3166"
    },
    "na":{  
        "id":"Component_NationalityText.namibia",
        "defaultMessage":"Namibia",
        "description":"Name of the country 'Namibia'. Country code 'na' in ISO-3166"
    },
    "nc":{  
        "id":"Component_NationalityText.newcaledonia",
        "defaultMessage":"New Caledonia",
        "description":"Name of the country 'New Caledonia'. Country code 'nc' in ISO-3166"
    },
    "ne":{  
        "id":"Component_NationalityText.niger",
        "defaultMessage":"Niger",
        "description":"Name of the country 'Niger'. Country code 'ne' in ISO-3166"
    },
    "nf":{  
        "id":"Component_NationalityText.norfolkisland",
        "defaultMessage":"Norfolk Island",
        "description":"Name of the country 'Norfolk Island'. Country code 'nf' in ISO-3166"
    },
    "ng":{  
        "id":"Component_NationalityText.nigeria",
        "defaultMessage":"Nigeria",
        "description":"Name of the country 'Nigeria'. Country code 'ng' in ISO-3166"
    },
    "ni":{  
        "id":"Component_NationalityText.nicaragua",
        "defaultMessage":"Nicaragua",
        "description":"Name of the country 'Nicaragua'. Country code 'ni' in ISO-3166"
    },
    "nl":{  
        "id":"Component_NationalityText.netherlands",
        "defaultMessage":"Netherlands",
        "description":"Name of the country 'Netherlands'. Country code 'nl' in ISO-3166"
    },
    "no":{  
        "id":"Component_NationalityText.norway",
        "defaultMessage":"Norway",
        "description":"Name of the country 'Norway'. Country code 'no' in ISO-3166"
    },
    "np":{  
        "id":"Component_NationalityText.nepal",
        "defaultMessage":"Nepal",
        "description":"Name of the country 'Nepal'. Country code 'np' in ISO-3166"
    },
    "nr":{  
        "id":"Component_NationalityText.nauru",
        "defaultMessage":"Nauru",
        "description":"Name of the country 'Nauru'. Country code 'nr' in ISO-3166"
    },
    "nu":{  
        "id":"Component_NationalityText.niue",
        "defaultMessage":"Niue",
        "description":"Name of the country 'Niue'. Country code 'nu' in ISO-3166"
    },
    "nz":{  
        "id":"Component_NationalityText.newzealand",
        "defaultMessage":"New Zealand",
        "description":"Name of the country 'New Zealand'. Country code 'nz' in ISO-3166"
    },
    "om":{  
        "id":"Component_NationalityText.oman",
        "defaultMessage":"Oman",
        "description":"Name of the country 'Oman'. Country code 'om' in ISO-3166"
    },
    "pa":{  
        "id":"Component_NationalityText.panama",
        "defaultMessage":"Panama",
        "description":"Name of the country 'Panama'. Country code 'pa' in ISO-3166"
    },
    "pe":{  
        "id":"Component_NationalityText.peru",
        "defaultMessage":"Peru",
        "description":"Name of the country 'Peru'. Country code 'pe' in ISO-3166"
    },
    "pf":{  
        "id":"Component_NationalityText.frenchpolynesia",
        "defaultMessage":"French Polynesia",
        "description":"Name of the country 'French Polynesia'. Country code 'pf' in ISO-3166"
    },
    "pg":{  
        "id":"Component_NationalityText.newguinea",
        "defaultMessage":"New Guinea",
        "description":"Name of the country 'New Guinea'. Country code 'pg' in ISO-3166"
    },
    "ph":{  
        "id":"Component_NationalityText.philippines",
        "defaultMessage":"Philippines",
        "description":"Name of the country 'Philippines'. Country code 'ph' in ISO-3166"
    },
    "pk":{  
        "id":"Component_NationalityText.pakistan",
        "defaultMessage":"Pakistan",
        "description":"Name of the country 'Pakistan'. Country code 'pk' in ISO-3166"
    },
    "pl":{  
        "id":"Component_NationalityText.poland",
        "defaultMessage":"Poland",
        "description":"Name of the country 'Poland'. Country code 'pl' in ISO-3166"
    },
    "pm":{  
        "id":"Component_NationalityText.saintpierre",
        "defaultMessage":"Saint Pierre",
        "description":"Name of the country 'Saint Pierre'. Country code 'pm' in ISO-3166"
    },
    "pn":{  
        "id":"Component_NationalityText.pitcairnislands",
        "defaultMessage":"Pitcairn Islands",
        "description":"Name of the country 'Pitcairn Islands'. Country code 'pn' in ISO-3166"
    },
    "pr":{  
        "id":"Component_NationalityText.puertorico",
        "defaultMessage":"Puerto Rico",
        "description":"Name of the country 'Puerto Rico'. Country code 'pr' in ISO-3166"
    },
    "ps":{  
        "id":"Component_NationalityText.palestine",
        "defaultMessage":"Palestine",
        "description":"Name of the country 'Palestine'. Country code 'ps' in ISO-3166"
    },
    "pt":{  
        "id":"Component_NationalityText.portugal",
        "defaultMessage":"Portugal",
        "description":"Name of the country 'Portugal'. Country code 'pt' in ISO-3166"
    },
    "pw":{  
        "id":"Component_NationalityText.palau",
        "defaultMessage":"Palau",
        "description":"Name of the country 'Palau'. Country code 'pw' in ISO-3166"
    },
    "py":{  
        "id":"Component_NationalityText.paraguay",
        "defaultMessage":"Paraguay",
        "description":"Name of the country 'Paraguay'. Country code 'py' in ISO-3166"
    },
    "qa":{  
        "id":"Component_NationalityText.qatar",
        "defaultMessage":"Qatar",
        "description":"Name of the country 'Qatar'. Country code 'qa' in ISO-3166"
    },
    "re":{  
        "id":"Component_NationalityText.reunion",
        "defaultMessage":"Reunion",
        "description":"Name of the country 'Reunion'. Country code 're' in ISO-3166"
    },
    "ro":{  
        "id":"Component_NationalityText.romania",
        "defaultMessage":"Romania",
        "description":"Name of the country 'Romania'. Country code 'ro' in ISO-3166"
    },
    "rs":{  
        "id":"Component_NationalityText.serbia",
        "defaultMessage":"Serbia",
        "description":"Name of the country 'Serbia'. Country code 'rs' in ISO-3166"
    },
    "ru":{  
        "id":"Component_NationalityText.russia",
        "defaultMessage":"Russia",
        "description":"Name of the country 'Russia'. Country code 'ru' in ISO-3166"
    },
    "rw":{  
        "id":"Component_NationalityText.rwanda",
        "defaultMessage":"Rwanda",
        "description":"Name of the country 'Rwanda'. Country code 'rw' in ISO-3166"
    },
    "sa":{  
        "id":"Component_NationalityText.saudiarabia",
        "defaultMessage":"Saudi Arabia",
        "description":"Name of the country 'Saudi Arabia'. Country code 'sa' in ISO-3166"
    },
    "sb":{  
        "id":"Component_NationalityText.solomonislands",
        "defaultMessage":"Solomon Islands",
        "description":"Name of the country 'Solomon Islands'. Country code 'sb' in ISO-3166"
    },
    "sc":{  
        "id":"Component_NationalityText.seychelles",
        "defaultMessage":"Seychelles",
        "description":"Name of the country 'Seychelles'. Country code 'sc' in ISO-3166"
    },
    "sd":{  
        "id":"Component_NationalityText.sudan",
        "defaultMessage":"Sudan",
        "description":"Name of the country 'Sudan'. Country code 'sd' in ISO-3166"
    },
    "se":{  
        "id":"Component_NationalityText.sweden",
        "defaultMessage":"Sweden",
        "description":"Name of the country 'Sweden'. Country code 'se' in ISO-3166"
    },
    "sg":{  
        "id":"Component_NationalityText.singapore",
        "defaultMessage":"Singapore",
        "description":"Name of the country 'Singapore'. Country code 'sg' in ISO-3166"
    },
    "sh":{  
        "id":"Component_NationalityText.sainthelena",
        "defaultMessage":"Saint Helena",
        "description":"Name of the country 'Saint Helena'. Country code 'sh' in ISO-3166"
    },
    "si":{  
        "id":"Component_NationalityText.slovenia",
        "defaultMessage":"Slovenia",
        "description":"Name of the country 'Slovenia'. Country code 'si' in ISO-3166"
    },
    "sj":{  
        "id":"Component_NationalityText.janmayen",
        "defaultMessage":"Jan Mayen",
        "description":"Name of the country 'Jan Mayen'. Country code 'sj' in ISO-3166"
    },
    "sk":{  
        "id":"Component_NationalityText.slovakia",
        "defaultMessage":"Slovakia",
        "description":"Name of the country 'Slovakia'. Country code 'sk' in ISO-3166"
    },
    "sl":{  
        "id":"Component_NationalityText.sierraleone",
        "defaultMessage":"Sierra Leone",
        "description":"Name of the country 'Sierra Leone'. Country code 'sl' in ISO-3166"
    },
    "sm":{  
        "id":"Component_NationalityText.sanmarino",
        "defaultMessage":"San Marino",
        "description":"Name of the country 'San Marino'. Country code 'sm' in ISO-3166"
    },
    "sn":{  
        "id":"Component_NationalityText.senegal",
        "defaultMessage":"Senegal",
        "description":"Name of the country 'Senegal'. Country code 'sn' in ISO-3166"
    },
    "so":{  
        "id":"Component_NationalityText.somalia",
        "defaultMessage":"Somalia",
        "description":"Name of the country 'Somalia'. Country code 'so' in ISO-3166"
    },
    "sr":{  
        "id":"Component_NationalityText.suriname",
        "defaultMessage":"Suriname",
        "description":"Name of the country 'Suriname'. Country code 'sr' in ISO-3166"
    },
    "st":{  
        "id":"Component_NationalityText.saotome",
        "defaultMessage":"Sao Tome",
        "description":"Name of the country 'Sao Tome'. Country code 'st' in ISO-3166"
    },
    "sv":{  
        "id":"Component_NationalityText.elsalvador",
        "defaultMessage":"El Salvador",
        "description":"Name of the country 'El Salvador'. Country code 'sv' in ISO-3166"
    },
    "sy":{  
        "id":"Component_NationalityText.syria",
        "defaultMessage":"Syria",
        "description":"Name of the country 'Syria'. Country code 'sy' in ISO-3166"
    },
    "sz":{  
        "id":"Component_NationalityText.swaziland",
        "defaultMessage":"Swaziland",
        "description":"Name of the country 'Swaziland'. Country code 'sz' in ISO-3166"
    },
    "tc":{  
        "id":"Component_NationalityText.caicosislands",
        "defaultMessage":"Caicos Islands",
        "description":"Name of the country 'Caicos Islands'. Country code 'tc' in ISO-3166"
    },
    "td":{  
        "id":"Component_NationalityText.chad",
        "defaultMessage":"Chad",
        "description":"Name of the country 'Chad'. Country code 'td' in ISO-3166"
    },
    "tf":{  
        "id":"Component_NationalityText.frenchterritories",
        "defaultMessage":"French Territories",
        "description":"Name of the country 'French Territories'. Country code 'tf' in ISO-3166"
    },
    "tg":{  
        "id":"Component_NationalityText.togo",
        "defaultMessage":"Togo",
        "description":"Name of the country 'Togo'. Country code 'tg' in ISO-3166"
    },
    "th":{  
        "id":"Component_NationalityText.thailand",
        "defaultMessage":"Thailand",
        "description":"Name of the country 'Thailand'. Country code 'th' in ISO-3166"
    },
    "tj":{  
        "id":"Component_NationalityText.tajikistan",
        "defaultMessage":"Tajikistan",
        "description":"Name of the country 'Tajikistan'. Country code 'tj' in ISO-3166"
    },
    "tk":{  
        "id":"Component_NationalityText.tokelau",
        "defaultMessage":"Tokelau",
        "description":"Name of the country 'Tokelau'. Country code 'tk' in ISO-3166"
    },
    "tl":{  
        "id":"Component_NationalityText.timorleste",
        "defaultMessage":"Timorleste",
        "description":"Name of the country 'Timorleste'. Country code 'tl' in ISO-3166"
    },
    "tm":{  
        "id":"Component_NationalityText.turkmenistan",
        "defaultMessage":"Turkmenistan",
        "description":"Name of the country 'Turkmenistan'. Country code 'tm' in ISO-3166"
    },
    "tn":{  
        "id":"Component_NationalityText.tunisia",
        "defaultMessage":"Tunisia",
        "description":"Name of the country 'Tunisia'. Country code 'tn' in ISO-3166"
    },
    "to":{  
        "id":"Component_NationalityText.tonga",
        "defaultMessage":"Tonga",
        "description":"Name of the country 'Tonga'. Country code 'to' in ISO-3166"
    },
    "tr":{  
        "id":"Component_NationalityText.turkey",
        "defaultMessage":"Turkey",
        "description":"Name of the country 'Turkey'. Country code 'tr' in ISO-3166"
    },
    "tt":{  
        "id":"Component_NationalityText.trinidad",
        "defaultMessage":"Trinidad",
        "description":"Name of the country 'Trinidad'. Country code 'tt' in ISO-3166"
    },
    "tv":{  
        "id":"Component_NationalityText.tuvalu",
        "defaultMessage":"Tuvalu",
        "description":"Name of the country 'Tuvalu'. Country code 'tv' in ISO-3166"
    },
    "tw":{  
        "id":"Component_NationalityText.taiwan",
        "defaultMessage":"Taiwan",
        "description":"Name of the country 'Taiwan'. Country code 'tw' in ISO-3166"
    },
    "tz":{  
        "id":"Component_NationalityText.tanzania",
        "defaultMessage":"Tanzania",
        "description":"Name of the country 'Tanzania'. Country code 'tz' in ISO-3166"
    },
    "ua":{  
        "id":"Component_NationalityText.ukraine",
        "defaultMessage":"Ukraine",
        "description":"Name of the country 'Ukraine'. Country code 'ua' in ISO-3166"
    },
    "ug":{  
        "id":"Component_NationalityText.uganda",
        "defaultMessage":"Uganda",
        "description":"Name of the country 'Uganda'. Country code 'ug' in ISO-3166"
    },
    "um":{  
        "id":"Component_NationalityText.usminorislands",
        "defaultMessage":"Us Minor Islands",
        "description":"Name of the country 'Us Minor Islands'. Country code 'um' in ISO-3166"
    },
    "us":{  
        "id":"Component_NationalityText.unitedstates",
        "defaultMessage":"USA",
        "description":"Name of the country 'United States'. Country code 'us' in ISO-3166"
    },
    "uy":{  
        "id":"Component_NationalityText.uruguay",
        "defaultMessage":"Uruguay",
        "description":"Name of the country 'Uruguay'. Country code 'uy' in ISO-3166"
    },
    "uz":{  
        "id":"Component_NationalityText.uzbekistan",
        "defaultMessage":"Uzbekistan",
        "description":"Name of the country 'Uzbekistan'. Country code 'uz' in ISO-3166"
    },
    "va":{  
        "id":"Component_NationalityText.vaticancity",
        "defaultMessage":"Vatican City",
        "description":"Name of the country 'Vatican City'. Country code 'va' in ISO-3166"
    },
    "vc":{  
        "id":"Component_NationalityText.saintvincent",
        "defaultMessage":"Saint Vincent",
        "description":"Name of the country 'Saint Vincent'. Country code 'vc' in ISO-3166"
    },
    "ve":{  
        "id":"Component_NationalityText.venezuela",
        "defaultMessage":"Venezuela",
        "description":"Name of the country 'Venezuela'. Country code 've' in ISO-3166"
    },
    "vg":{  
        "id":"Component_NationalityText.britishvirginislands",
        "defaultMessage":"British Virgin Islands",
        "description":"Name of the country 'British Virgin Islands'. Country code 'vg' in ISO-3166"
    },
    "vi":{  
        "id":"Component_NationalityText.usvirgin islands",
        "defaultMessage":"Us Virgin Islands",
        "description":"Name of the country 'Us Virgin Islands'. Country code 'vi' in ISO-3166"
    },
    "vn":{  
        "id":"Component_NationalityText.vietnam",
        "defaultMessage":"Vietnam",
        "description":"Name of the country 'Vietnam'. Country code 'vn' in ISO-3166"
    },
    "vu":{  
        "id":"Component_NationalityText.vanuatu",
        "defaultMessage":"Vanuatu",
        "description":"Name of the country 'Vanuatu'. Country code 'vu' in ISO-3166"
    },
    "wf":{  
        "id":"Component_NationalityText.wallisand futuna",
        "defaultMessage":"Wallis And Futuna",
        "description":"Name of the country 'Wallis And Futuna'. Country code 'wf' in ISO-3166"
    },
    "ws":{  
        "id":"Component_NationalityText.samoa",
        "defaultMessage":"Samoa",
        "description":"Name of the country 'Samoa'. Country code 'ws' in ISO-3166"
    },
    "ye":{  
        "id":"Component_NationalityText.yemen",
        "defaultMessage":"Yemen",
        "description":"Name of the country 'Yemen'. Country code 'ye' in ISO-3166"
    },
    "yt":{  
        "id":"Component_NationalityText.mayotte",
        "defaultMessage":"Mayotte",
        "description":"Name of the country 'Mayotte'. Country code 'yt' in ISO-3166"
    },
    "za":{  
        "id":"Component_NationalityText.southafrica",
        "defaultMessage":"South Africa",
        "description":"Name of the country 'South Africa'. Country code 'za' in ISO-3166"
    },
    "zm":{  
        "id":"Component_NationalityText.zambia",
        "defaultMessage":"Zambia",
        "description":"Name of the country 'Zambia'. Country code 'zm' in ISO-3166"
    },
    "zw":{  
        "id":"Component_NationalityText.zimbabwe",
        "defaultMessage":"Zimbabwe",
        "description":"Name of the country 'Zimbabwe'. Country code 'zw' in ISO-3166"
    }
}
import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import ClubPlayerTransferOfferLoanInterface from "./interface";
import usePersistedState from "../../../../common/lib/usePersistedState";

export default function ClubPlayerTransferOfferLoan(props) {
    const {persistedState} = usePersistedState('myTransferOffers', {view: 'paidAuctions', activeOnly: false});

    const getClubPlayerTransferOfferLoanQuery = useQuery(
        [
            'getClubPlayerTransferOfferLoan',
            persistedState.activeOnly
        ],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/clubs/playerTransferOfferLoan?activeOnly=${persistedState.activeOnly}`)
    );

    return(
        <ClubPlayerTransferOfferLoanInterface
            isLoading={getClubPlayerTransferOfferLoanQuery.isLoading}
            proposedPlayerTransferOfferLoan={getClubPlayerTransferOfferLoanQuery.data?.data?.response.proposedPlayerTransferOfferLoan}
            receivedPlayerTransferOfferLoan={getClubPlayerTransferOfferLoanQuery.data?.data?.response.receivedPlayerTransferOfferLoan}
        />
    )
}
import { FormattedMessage } from "react-intl";
import { Table, Segment, Header, Placeholder } from "semantic-ui-react";
import ClubName from "../../club/components/common/Name";
import LeagueLink from "../../league/components/common/Link";
import RatingRoundall from '../../player/common/RatingRoundall2';

export default function TeamProfileInterface(props) {
    return(
        <div>
            <Header as='h5' attached='top' inverted className='attachedHeader'>
                {props.isLoading ?
                    <Placeholder inverted style={{display: 'inline-block', width: '60%', '&after:backgroundColor': 'rgb(27 29 30)'}}>
                        <Placeholder.Header>
                            <Placeholder.Line length='full' />
                        </Placeholder.Header>
                    </Placeholder>
                    :
                    <FormattedMessage {...messages[props.team.type]} />
                }
            </Header>
            <Segment attached className='attachedInvertedSegment profileSegmentFix2'>
                <Table
                    basic='very'
                    compact='very'
                    unstackable
                    style={{width: '100%'}}
                >
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>Team</Table.Cell>
                            {props.isLoading ?
                                <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                :
                                <Table.Cell width={8}>
                                    <ClubName club={props.club} />
                                </Table.Cell>
                            }
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>League</Table.Cell>
                            {props.isLoading ?
                                <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                :
                                <Table.Cell width={8}><LeagueLink league={props.team.currentLeague} /></Table.Cell>
                            }
                        </Table.Row>
                        {props.team?.primaryFormation &&
                            <Table.Row>
                                <Table.Cell style={{fontWeight: 'bold'}} width={6}>Best formation</Table.Cell>
                                <Table.Cell width={8}>{props.team.primaryFormation}</Table.Cell>
                            </Table.Row>
                        }
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    );
}

const messages = {
    first: {
        id: 'teamTypes:first',
        defaultMessage: 'First team',
        description: 'name for first (main) team'
    },
    youth: {
        id: 'teamTypes:youth',
        defaultMessage: 'Youth team',
        description: 'name for youth team'
    }
}
import { Table, Grid, Placeholder, Message} from "semantic-ui-react";
import {FormattedDate, FormattedMessage} from "react-intl";

import NationLink from "../../../nationGroup/common/NationGroupLinkWithFlag";
import LeagueLink from "../../../league/components/common/Link";

import {default as RatingColor} from '../RatingRoundall'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCakeCandles, faClock, faCoins, faFlag, faListOl, faMagnifyingGlass, faMedkit, faShieldHalved, faShoppingBasket, faStar, faStreetView, faTag } from '@fortawesome/free-solid-svg-icons';
import Component_Currency from "../../../common/components/Currency";
import ContractExpiry from "../ContractExpiry";
import ShortenedPlayerName from "../ShortenedName";
import dayjs from "dayjs";
import ClubLink from "../../../club/components/common/Link";
import TeamLink from "../../../team/common/TeamLink";

export default function ProfileOverviewInterface(props) {
    function getTableRow(icon, getValue) {
        return(
            <Table.Row>
                <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={icon} /></Table.Cell>
                <Table.Cell textAlign="right" style={{maxWidth: 0, display: 'table-cell'}} className='noTextwrap'>
                    {props.isLoading ?
                        <Placeholder><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                        :
                        getValue(props.player)
                    }
                </Table.Cell>
            </Table.Row>
        )
    }

    return(
        <Grid style={{width: '100%', margin: 0, padding: '0.5em 0 1em 0'}}>
            <Grid.Row style={{padding: 0}}>
                <Grid.Column width={8} style={{borderRight: '1px solid #2224261a'}}>
                    <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable'>
                        <Table.Body>
                            {getTableRow(faStreetView, (player) => player.position)}
                            {getTableRow(faFlag, (player) => <NationLink shortcode={player.person.nation.nationGroupShortcode} />)}
                            {getTableRow(faShieldHalved, (player) => player.playerContract?.playerReg.team ? <TeamLink team={player.playerContract.playerReg.team} /> : 'Free agent')}
                            {getTableRow(faListOl, (player) => player.playerContract ? <LeagueLink league={player.playerContract.playerReg.team.currentTLS.LS.league} /> : null)} 
                            {props.extendedView === true &&
                                <>
                                    {getTableRow(faCoins, (player) => player.playerContract ? <Component_Currency value={player.playerContract.salary} /> : null)}
                                    {getTableRow(faMagnifyingGlass, (player) => `${player.interestedClubs || 0} favourites`) /* 940 - is interestedClubs the correct prop? */}
                                </>
                            }
                        </Table.Body>
                    </Table>
                </Grid.Column>
                <Grid.Column width={8}>
                    <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable'>
                        <Table.Body>
                            
                            {getTableRow(faStar, (player) => <RatingColor rating={player.currentAbility} disableFloat={true} />)}
                            {getTableRow(faTag, (player) => <FormattedMessage {...messages[`playerType_${player.type}`]} />)}
                            {getTableRow(faCakeCandles, (player) => <span>{player.person.age} years old</span>)}
                            {getTableRow(faCoins, (player) => <Component_Currency value={player.value} />)} 
                            {props.extendedView === true &&
                                <>
                                    {getTableRow(faClock, (player) => player.playerContract ? <ContractExpiry playerContract={player.playerContract} /> : null)}
                                    {getTableRow(faShoppingBasket, (player) => {
                                        if(!!player.activeFreeAuction || !!player.activePaidAuction) {
                                            return 'Auction'
                                        } else {
                                            return `${player.activeOfferLoan + player.activeOfferPaid} offers`
                                        }
                                    })}
                                </>
                            }
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid.Row>
            {!props.isLoading && props.player.playerContract?.club.id !== props.player.playerContract?.playerReg.team.club.id &&
                <Grid.Row
                    style={{paddingBottom: 0}}
                >
                    <Grid.Column width={16}>
                        <p><ShortenedPlayerName player={props.player} /> is on loan from <ClubLink club={props.player.playerContract.club} inline={true} /></p>
                    </Grid.Column>
                </Grid.Row>
            }
            {!props.isLoading && props.player.retirementSeasonId !== null &&
                <Grid.Row
                    style={{paddingBottom: 0}}
                >
                    <Grid.Column width={16}>
                        {dayjs(props.player.retirementSeason.endDate).isAfter(new Date()) ?
                            <p><ShortenedPlayerName emphasise={true} player={props.player} /> will retire on <FormattedDate value={props.player.retirementSeason.endDate} day='numeric' month='short' /></p>
                            :
                            <p><ShortenedPlayerName emphasise={true} player={props.player} /> retired on <FormattedDate value={props.player.retirementSeason.endDate} /></p>
                        }
                    </Grid.Column>
                </Grid.Row>
            }
            {!props.isLoading && props.player.injuredUntil !== null &&
                <Grid.Row style={{paddingBottom: 0}}>
                    <Grid.Column width={16}>
                        <p><FontAwesomeIcon icon={faMedkit} className='inlineIcon' /><ShortenedPlayerName player={props.player} /> is injured until <span style={{fontWeight: 'bold'}}><FormattedDate value={props.player.injuredUntil} weekday='short' day='numeric' month='short' /></span></p>
                    </Grid.Column>
                </Grid.Row>
            }
            {!props.isLoading && props.player.numberOfGamesSuspended > 0 &&
                <Grid.Row style={{paddingBottom: 0}}>
                    <Grid.Column width={16}>
                        <p><ShortenedPlayerName player={props.player} /> is suspended for {props.player.numberOfGamesSuspended} game(s)</p>
                    </Grid.Column>
                </Grid.Row>
            }
        </Grid>
    )
}

const messages = {
    playerType_defensive: {
        id: "playerType_defensive",
        defaultMessage: "Defensive",
        description: "Description for a player with high defensive attributes"
    },
    playerType_standard: {
        id: "playerType_standard",
        defaultMessage: "Standard",
        description: "Description for a player with balanced  attributes"
    },
    playerType_attacking: {
        id: "playerType_attacking",
        defaultMessage: "Attacking",
        description: "Description for a player with high attacking attributes"
    }
}
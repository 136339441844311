import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../../common/lib/paths.js";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { useTutorial } from "../../../tutorial/hooks/useTutorial.js";

const messages = defineMessages({
	text: {
		id: "toolbarItem:teamFixtures",
		defaultMessage: "Fixtures",
		description: "Link for team fixtures page"
	}
})

export default function TeamFixturesToolbarItem(props) {
	const {checkTutorial, getSearchParamsString} = useTutorial();

	const isTutorial1 = checkTutorial('fixtures', 3);

	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.teamFixtures, {clubId: props.clubId, teamType: props.teamType}),
				search: isTutorial1 && getSearchParamsString('fixtures', 4)
			}}
			icon={faCalendarDays}
			messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
			glow={isTutorial1}
			genericPath={URL.teamFixtures}
		/>
	);
}
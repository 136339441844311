import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {FormattedMessage} from "react-intl";
import {Link, useLocation, matchPath} from 'react-router-dom';

import {Menu} from "semantic-ui-react";

export default function ToolbarItem(props) {
    const location = useLocation();

    const isActive = props.genericPath !== undefined ?
        matchPath(location.pathname, {path: props.genericPath})
        :
        location.pathname === props.path;

    if(!props.clickHandler) {
        return(
            <Menu.Item
                as={Link}
                to={props.path}
                style={style}
                active={isActive}
                className={`${props.className} ${props.glow && 'glow'}`}
            >
                <FontAwesomeIcon icon={props.icon} style={{margin: 0, marginRight: ".946428571em"}} />
                <span
                    ref={setRefValue.bind(this)} 
                >
                    <FormattedMessage {...props.messages.text} />
                </span>
            </Menu.Item>
        );
    } else if(!!props.clickHandler) {
        return(
            <Menu.Item
                onClick={props.clickHandler}
                style={style}
                active={isActive}
                ref={setRefValue.bind(this)}
                className={`${props.className} ${props.glow && 'glow'}`}
            >
                <FontAwesomeIcon icon={props.icon} style={{margin: 0, marginRight: ".946428571em"}} />
                <span ref={isActive ? props.activeToolbarItemRef : undefined}><FormattedMessage {...props.messages.text} /></span>
            </Menu.Item>
        )
    }

    function setRefValue(el) {
        if(
            !props.activeToolbarItemRef ||
            (!props.glow && !isActive)
        ) {
            return;
        }

        if(!!props.glow) {
            props.activeToolbarItemRef.current.glow = el;
        } else if(!!isActive) {
            props.activeToolbarItemRef.current.active = el;
        } else {
            return;
        }
    }
}

const style = {margin: 0, borderRadius: 0, height: '100%'}
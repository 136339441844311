import { useEffect } from "react";
import { useTutorial } from "../hooks/useTutorial";
import { cloneDeep } from "lodash";
import { Redirect, generatePath } from "react-router";
import { Loader } from "semantic-ui-react";
import useUser from '../../common/useUser';

import {default as tutorialData} from '../tutorials/league';

export default function LeagueTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    const userQuery = useUser(!cachedTutorialData);

    useEffect(() => {
        if(!cachedTutorialData && userQuery.isSuccess) {
            var compiledTutorialData = cloneDeep(tutorialData);

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                    case 3:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: userQuery.club.id, teamType: 'first'}),
                            state: {
                                view: 'table'
                            }
                        }
                        break;
                    case 2:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: userQuery.club.id, teamType: 'youth'}),
                            state: {
                                view: 'table'
                            }
                        }
                        break;
                    case 4:
                    case 5:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: userQuery.club.id, teamType: 'first'}),
                            state: {
                                view: 'stats'
                            }
                        }
                        break;
                    case 6:
                    case 7:
                    case 8:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: userQuery.club.id, teamType: 'first'}),
                            state: {
                                view: 'fixtures'
                            }
                        }
                        break;
                    case 9:
                    case 10:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: userQuery.club.id, teamType: 'first'}),
                            state: {
                                view: 'history'
                            }
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData, userQuery.isSuccess]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
//last verified against STAGE FOUR spreadsheet 3/10/2021

export default {
    positionMap: [
        'GK',
        'LB',
        'CB',
        'CB',
        'RB',
        'LM',
        'CM',
        'RM',
        'CAM',
        'CAM',
        'ST'
    ],
    pitchHalfMap: [
        12,
        1,
        7,
        19,
        25,
        3,
        15,
        27,
        10,
        22,
        17
    ]
}
import sortByLineup from "./sorters/sortByLineup";
import usePersistedState from "../../common/lib/usePersistedState";
import { useTutorial } from "../../tutorial/hooks/useTutorial";

import { playerName } from "../../common/dataTableColumns/player/playerName";
import { playerPosition } from "../../common/dataTableColumns/player/playerPosition";
import {personAge} from '../../common/dataTableColumns/person/personAge';
import { currency } from "../../common/dataTableColumns/currency";
import { contractExpiry } from "../../common/dataTableColumns/date";
import { currentAbility } from "../../common/dataTableColumns/currentAbility";
import PlayerExpander from "../common/PlayerExpander/Index";
import DataTable from "../../common/components/DataTable";
import colours from "../../common/colours";

export default function PlayersContractView(props) {
    const {tutorialKey, tutorialStep, checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial = checkTutorial('players', 3);

    const {
        persistedState,
        updatePersistedState
    } = usePersistedState(
        props.isOwner ? 'squadContractViewSort_1' : 'squadContractViewSort_0',
        props.isOwner ?
            {
                column: 1,
                direction: 'asc'
            }
            :
            {
                column: 2,
                direction: 'desc'
            }
    );

    const columns = [
        playerName(
            (playerReg) => playerReg.playerContract.player,
            (playerReg) => playerReg.playerContract,
            (playerReg) => playerReg,
            (playerReg) => playerReg.teamTacticPosition,
            undefined,
            (rowData, i) => isTutorial ? getSearchParamsString(tutorialKey, tutorialStep+1) : undefined,
            (rowData, i) => isTutorial && i === 0 && 'glow',
            sortByLineup.bind(this, props.ownerClubFormation, (playerReg) => playerReg.playerContract.player)
        ),
        playerPosition(
            (playerReg) => playerReg.playerContract.player.position,
            500
        ),
        personAge(
            (playerReg) => playerReg.playerContract.player.person.age,
            580
        ),
        currency(
            'playerSalary',
            (playerReg) => playerReg.playerContract.salary,
            374,
            true
        ),
        contractExpiry(
            (playerReg) => playerReg.playerContract.endSeason.endDate,
            true,
            350
        ),
        currency(
            'playerValue',
            (playerReg) => playerReg.playerContract.player.value,
            425,
            true
        ),
        currentAbility(
            (playerReg) => playerReg.playerContract.player.currentAbility,
            650
        )
    ]

    return(
        <DataTable
            columns={columns}
            data={props.playerRegistrations}
            isLoading={props.isLoading}
            placeholderCount={14}
            expandableRows
            expandableRowsComponent={PlayerExpander}
            defaultSortFieldId={persistedState.column}
            defaultSortAsc={persistedState.direction === 'asc'}
            keyField='id'
            onSort={(column, direction) => updatePersistedState({column: column.id, direction})}
            conditionalRowStyles={[
                {
                    when: (playerReg) => playerReg.teamId !== props.teamId,
                    style: {backgroundColor: colours.grey}
                }
            ]}
        />
    );
}
import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faUserGroup } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_TransfersPlayers.text",
		defaultMessage: "Players",
		description: "Link for player transfer search page"
	}
});

export default function Players(props) {
	return(
		<ToolbarItem
			path={generatePath(URL.transferSearchPlayers)}
			icon={faUserGroup}
			messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
		/>
	)
}
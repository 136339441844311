import axios from "axios";
import { useMutation } from '@tanstack/react-query';
import { Message } from "semantic-ui-react";
import NarrowLayout from "../../common/components/NarrowLayout";
import TitledSegment from "../../common/components/TitledSegment";
import ContractDemandsInterface from "../contract/common/ContractDemands";
import ContractOfferForm from "../contract/offerForm/interface";

export default function PlayerContractOfferInterface(props) {
    const createPlayerContractOffer = useMutation(
        ({salaryOffer, endSeasonIdOffer}) => axios.patch(`${process.env.REACT_APP_APPHOST}/playerContracts/${props.player.playerContract.id}`, {
            salary: salaryOffer,
            endSeasonId: endSeasonIdOffer
        })
    );

    function handleSubmitContractOffer(salaryOffer, endSeasonIdOffer) {
        createPlayerContractOffer.mutate({
            salaryOffer: parseInt(salaryOffer),
            endSeasonIdOffer: parseInt(endSeasonIdOffer)
        });
    }

    if(!props.isInterested) {
        return(
            <Message
                negative
            >
                <p>This player is not interested in renewing their contract</p>
            </Message>
        );
    }

    return(
        <NarrowLayout>
            <TitledSegment
                title='Contract demands'
            >
                <ContractDemandsInterface
                    isLoading={props.isLoading}
                    isMyPlayer={true}
                    isYouthTeamPlayer={props.player?.playerContract.playerReg.team.type === 'youth'}
                    contractDemands={props.contractDemands}
                    currentSeason={props.currentSeason}
                    contractOffer={null}
                    playerContract={props.player?.playerContract}
                />
            </TitledSegment>
            {createPlayerContractOffer.isSuccess === false ?
                <TitledSegment
                    title='Contract offer'
                >
                    <ContractOfferForm
                        isLoading={props.isLoading}
                        isMyPlayer={true}
                        currentSeason={props.currentSeason}
                        existingContractExpiry={props.player?.playerContract.endSeason.endDate}
                        contractDemands={props.contractDemands}
                        reputationWithinRange={props.isInterested}
                        handleSubmit={handleSubmitContractOffer}
                        isSubmitting={createPlayerContractOffer.isLoading}
                        submitOfferError={createPlayerContractOffer.error?.response?.data}
                    />
                </TitledSegment>
                :
                <Message
                    positive
                >
                    <p>The contract offer has been accepted</p>
                </Message>
            }
        </NarrowLayout>
    );
}
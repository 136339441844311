import { FormattedMessage, defineMessages } from "react-intl";

export default function CupRoundName(props) {
    if(!!messages[props.roundsFromFinal]) {
        return (
            <FormattedMessage {...messages[props.roundsFromFinal]} />
        )
    } else {
        return(
            <FormattedMessage {...messages.generic} values={{teamCount: Math.pow(2, (props.roundsFromFinal+1))}} />
        )
    }
}

const messages = defineMessages({
    0: {
        id: 'cup:roundName:final',
        defaultMessage: 'Final',
        description: 'Name for the final round of a knock out tournament'
    },
    1: {
        id: 'cup:roundName:semiFinal',
        defaultMessage: 'Semi-final',
        description: 'Name for the penultimate (2nd to last) round of a knock out tournament'
    },
    2: {
        id: 'cup:roundName:quarterFinal',
        defaultMessage: 'Quarter-final',
        description: 'Name for the 3rd to last round of a knock out tournament'
    },
    generic: {
        id: 'cup:roundName:generic',
        defaultMessage: 'Round of {teamCount}',
        description: 'Name for earlier stages of a knock out tournament, defined by the number of teams participating in that round'
    }
})
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { hsvToRgb, ratingToHsv, getColor } from '../../common/lib/color';

function currentAbility(
    resolver,
    hide
) {
    return {
        id: 'playerCurrentAbility',
        name: <FontAwesomeIcon icon={faStar} />,
        selector: resolver,
        sortable: true,
        sortFunction: sorter,
        wrap: false,
        allowOverflow: false,
        center: true,
        compact: true,
        hide,
        grow: 1,
        minWidth: '30px',
        maxWidth: '40px',
        conditionalCellStyles: [
            {
                when: (rowData) => !!resolver(rowData),
                style: (rowData) => ({
                    backgroundColor: hsvToRgb(ratingToHsv(resolver(rowData))),
                    color: getColor(hsvToRgb(ratingToHsv(resolver(rowData))).substr(1)),
                    fontWeight: '700'
                })
            }
        ]
    }

    function sorter(a, b) {
        a = resolver(a);
        b = resolver(b);

        return b-a;
    }
}

export {
    currentAbility
}
import {useMutation} from '@tanstack/react-query';
import {Loader} from 'semantic-ui-react';
import axios from 'axios';
import { useEffect } from 'react';

export default function Logout(props) {    
    const logoutMutation = useMutation((refreshToken) =>
        axios.delete(
            `${process.env.REACT_APP_APPHOST}/logout`,
            {data: {
                token: refreshToken
            }}
        ),
        {
            onSuccess: () => props.handleLogout()
        }
    );

    useEffect(() => {
        const timer = setTimeout(() => {
            logoutMutation.mutate(props.refreshToken);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);
    
    return(<Loader active/>);
}
import { defineMessages } from "@formatjs/intl";
import {compileMessage} from './util.js';

const messages = {
    'playerTransferOfferPaid:created': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferPaid:created',
            defaultMessage: "Transfer offer for {playerName}",
            description: "Subject for the system message template which a user receives when a club makes a transfer offer for a player"
        },
        body: {
            id: 'playerTransferOfferPaid:created',
            defaultMessage: "{toClubName} have submitted a transfer offer of {compensation} for {playerName}.\n\nYou must respond by {responseDeadline}.",
            description: "Message body for the system message template which a user receives when a club makes a transfer offer for a player"
        }
    })),
    'favPlayer:playerTransferOfferPaid:created': defineMessages(compileMessage({
        subject: {
            id: 'favPlayer:playerTransferOfferPaid:created',
            defaultMessage: "{toClubName} submit bid for {playerName}",
            description: "Subject for the system message template which a user receives when a club makes a transfer offer for a player on their favourites"
        },
        body: {
            id: 'favPlayer:playerTransferOfferPaid:created',
            defaultMessage: "{toClubName} have submitted a transfer offer of {compensation} for {playerName} who is on our favourites.",
            description: "Message body for the system message template which a user receives when a club makes a transfer offer for a player on their favourites"
        }
    })),
    'playerTransferOfferPaid:accepted': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferPaid:accepted',
            defaultMessage: "Transfer offer for {playerName} accepted",
            description: "Subject for the system message template which a user receives when one of their transfer bids has been accepted by the other club"
        },
        body: {
            id: 'playerTransferOfferPaid:accepted',
            defaultMessage: "We have accepted your offer of {compensation} for {playerName}. An auction for the player will now begin, more details will follow soon.",
            description: "Message body for the system message template which a user receives when one of their tranfer bids has been accepted by the other club"
        }
    })),
    'playerTransferOfferPaid:rejected:suggestedBid': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferPaid:rejected:suggestedBid',
            defaultMessage: "Transfer offer for {playerName} rejected",
            description: "Subject for the system message template which a user receives when one of their transfer bids has been rejected by the other club, and the other club suggests an acceptable offer"
        },
        body: {
            id: 'playerTransferOfferPaid:rejected:suggestedBid',
            defaultMessage: "We have rejected your offer of {compensation} for {playerName}.\n\nIf you still want to sign them, you should submit another offer in the region of {suggestedBid}.",
            description: "Message body for the system message template which a user receives when one of their transfer bids has been rejected by the other club, and the other club suggests an acceptable offer"
        }
    })),
    'playerTransferOfferPaid:rejected': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferPaid:rejected',
            defaultMessage: "Transfer offer for {playerName} rejected",
            description: "Subject for the system message template which a user receives when one of their transfer bids has been rejected by the other club"
        },
        body: {
            id: 'playerTransferOfferPaid:rejected',
            defaultMessage: "We have rejected your offer of {compensation} for {playerName}.\n\nIf you still want to sign them, you should submit another offer.",
            description: "Message body for the system message template which a user receives when one of their transfer bids has been rejected by the other club"
        }
    })),
    'playerTransferOfferPaid:withdrawn': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferPaid:withdrawn',
            defaultMessage: "Transfer offer for {playerName} withdrawn",
            description: "Subject for the system message template which a user receives when another club have withdrawn a transfer bid for one of their players"
        },
        body: {
            id: 'playerTransferOfferPaid:withdrawn',
            defaultMessage: "We have decided to withdraw our offer of {compensation} for {playerName}.\n\nWe appreciate your time on this matter.",
            description: "Message body for the system message template which a user receives when another club have withdrawn their transfer offer for one of their players/staff"
        }
    })),
    'playerTransferOfferPaid:timedOut': defineMessages(compileMessage({
        subject: {
            id: 'playerTransferOfferPaid:timedOut',
            defaultMessage: "Transfer offer for {playerName} cancelled",
            description: "Subject for the system message template which a user receives when another club have not responded in time to their transfer offer"
        },
        body: {
            id: 'playerTransferOfferPaid:timedOut',
            defaultMessage: "Your offer of {compensation} for {playerName} has been cancelled because {toClubName} did not respond before the deadline.",
            description: "Body for the system message template which a user receives when another club have not responded in time to their transfer offer"
        }
    })),
}

export default messages;
import {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import 'animate.css';
import {QueryClientProvider, QueryClient} from '@tanstack/react-query';
import {IntlProvider} from "react-intl";
import {BrowserRouter as Router} from "react-router-dom";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import App from './App';
import ReactGA from "react-ga4";
import { ConfettiProvider } from './common/useConfetti';

dayjs.extend(utc);
dayjs.extend(isoWeek);

ReactGA.initialize('G-GCT4130QCJ', { testMode: process.env.NODE_ENV !== 'production' });

if(window.history.scrollRestoration) {
    window.history.scrollRestoration = 'auto';
}

const queryClient = new QueryClient();

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <StrictMode>
        <QueryClientProvider client={queryClient}>
            <IntlProvider locale='en'>
                <Router forceRefresh={false}>
                    <ConfettiProvider>
                        <App />
                    </ConfettiProvider>
                </Router>                              
            </IntlProvider>
        </QueryClientProvider>
    </StrictMode>
);
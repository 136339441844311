import { BitmapText, Container, Graphics, Point } from "pixi.js";
import { ApplicationException, orientations, playerPositions, sizes, getRandomInt, getRunningSpeed, isApplicationException, movementTypes, constrainValue, getTeamInitial, shootingZones, normaliseValue, weightedRandom, getPassSpeed, getDribbleSpeed, locations, getShotSpeed, probability, runWithoutBallSpeed, convertPointForOrientation, convertYForOrientation, convertXForOrientation } from "./lib";
import { calculateDirection, calculateDistance, calculateFieldOfView, isPointInBoundingBox, isPointInTriangle } from "./physics";
import Movement from "./movement";

class Player {
    constructor(
        team,
        playerIndex,
        position,
        initialYLocation,
        name
    ) {
        this.team = team;

        this.player = {
            index: playerIndex,
            position,
            name
        }

        this.calculateBounds();

        this.player.initialLocation = new Point(
            _getInitialXPosition(position, team.orientation),
            this.player.shape.notInPossession.y[0] + initialYLocation
        );

        this.reset();

        this.graphics = _createPlayerGraphic(
            this.team.app,
            this.team.sim,
            this.player,
            team.colour
        );
    }

    /* COMMON METHODS */

    calculateBounds() {
        const xMinInPossession = convertXForOrientation((convertXForOrientation(this.getTargetXLocation(0, true), this.team.orientation)), this.team.orientation);
        const xMaxInPossession = convertXForOrientation((convertXForOrientation(this.getTargetXLocation(100, true), this.team.orientation)), this.team.orientation);

        const xMinNotInPossession = convertXForOrientation((convertXForOrientation(this.getTargetXLocation(0, false), this.team.orientation)), this.team.orientation);
        const xMaxNotInPossession = convertXForOrientation((convertXForOrientation(this.getTargetXLocation(100, false), this.team.orientation)), this.team.orientation);

        const yMin = convertYForOrientation(playerPositions[this.player.position].shape.y[this.team.orientation === orientations.LEFT ? 0 : 1], this.team.orientation);
        const yMax = convertYForOrientation(playerPositions[this.player.position].shape.y[this.team.orientation === orientations.LEFT ? 1 : 0], this.team.orientation);

        const bound = playerPositions[this.player.position].bound;

        this.player.shape = {
            inPossession: {
                x: [
                    xMinInPossession,
                    xMaxInPossession
                ],
                y: [
                    yMin,
                    yMax
                ]
            },
            notInPossession: {
                x: [
                    xMinNotInPossession,
                    xMaxNotInPossession
                ],
                y: [
                    yMin,
                    yMax
                ]
            }
        }

        this.player.bounds = {
            inPossession: {
                x: [
                    constrainValue(this.player.shape.inPossession.x[0]-bound, 0, sizes.pitch.x),
                    constrainValue(this.player.shape.inPossession.x[1]+bound, 0, sizes.pitch.x)
                ],
                y: [
                    constrainValue(this.player.shape.inPossession.y[0]-bound, 0, sizes.pitch.y),
                    constrainValue(this.player.shape.inPossession.y[1]+bound, 0, sizes.pitch.y)
                ]
            },
            notInPossession: {
                x: [
                    constrainValue(this.player.shape.notInPossession.x[0]-bound, 0, sizes.pitch.x),
                    constrainValue(this.player.shape.notInPossession.x[1]+bound, 0, sizes.pitch.x)
                ],
                y: [
                    constrainValue(this.player.shape.notInPossession.y[0]-bound, 0, sizes.pitch.y),
                    constrainValue(this.player.shape.notInPossession.y[1]+bound, 0, sizes.pitch.y)
                ]
            }
        };
    }

    getTargetXLocation(ballXLocation = this.team.sim.ball.location.x, inPossession = this.team.hasPossession()) {
        if(this.player.name === 'L1') {
            console.log(1234);
        }

        //ballXLocation < 0 = ball in own half
        //ballXLocation > 0 = ball in opponent half
        //ballXLocation = 0 = neutral position
        if(this.team.orientation === orientations.LEFT) {
            ballXLocation = ballXLocation - 50;
        } else if(this.team.orientation === orientations.RIGHT) {
            ballXLocation = 50 - ballXLocation;
        }
        
        let scalar; //Scaler - lower number = push further up or further back
        if(inPossession === true) {
            if(ballXLocation > 0) {
                //in possession and in opposition half
                //push further up
                scalar = 1.3;
            } else {
                //in possession and in own half
                //push further back ???
                scalar = 4;
            }
        } else if (inPossession === false) {
            if(ballXLocation > 0) {
                //not in possession and ball in opposition half
                //push further back ???
                scalar = 6;
            } else {
                //not in possession and ball in own half
                //push further forward ???
                scalar = 1.2;
            }
        }
        
        return convertXForOrientation(constrainValue((((ballXLocation)/scalar)+playerPositions[this.player.position].shape.x), 5, 95), this.team.orientation);
    }

    handleMovementComplete() {
        this.log('movement completed');
        
        this.cancelMovement();

        if(this.player.movement?.type === movementTypes.DRIBBLE && this.isPlayerInPenaltyArea() === true) {
            this.player.timeSinceLastShotEvaluation = null;
        }
    }

    reset() {
        this.player.prevState = null;
        this.player.nextState = null;
        this.player.movement = null;
        this.player.fieldOfView = null;
        this.player.timeInPossession = 0;
        this.player.timeInXDirection = 0;
        this.player.timeInYDirection = 0;
        this.player.timeSinceMovementChange = 0;
        this.player.timeSinceLastShotEvaluation = null;

        this.player.location = this.player.initialLocation.clone();

        this.friendlyDistanceNodes = [];
        this.opponentDistanceNodes = [];

        this.marking = null;
        this.marker = null;
    }

    _log(m, i) {
        if(i === undefined || i === this.player.index) {
            console.log(`${getTeamInitial(this.team.orientation)}${this.player.index}:`, m);
        }
    }

    log(m, i) {
        if(this.team.logConfig.players.includes(this.player.index) === true) {
            this._log(m, i)
        }
    }

    verbose(m, i) {
        if(
            this.team.logConfig.players.includes(this.player.index) === true &&
            this.team.sim.logConfig.verbose === true
        ) {
            this._log(m, i)
        }
    }

    constrainPointsWithinBounds(point) {
        let bounds;

        if(this.team.hasPossession() === true) {
            bounds = this.player.bounds.inPossession;
        } else if(this.team.hasPossession() === false) {
            bounds = this.player.bounds.notInPossession;
        }

        point.x = Math.min(point.x, bounds.x[1]);
        point.x = Math.max(point.x, bounds.x[0]);
        
        point.y = Math.min(point.y, bounds.y[1]);
        point.y = Math.max(point.y, bounds.y[0]);

        return point;
    }

    updatePlayerLocation(deltaMS) {
        if(this.player.movement !== null) {
            this.player.location = this.player.movement.update(deltaMS);
        }
    }

    setMovement(movement) {
        if(this.player.movement !== null) {
            throw new ApplicationException(`movement already in progress: ${this.player.movement.type}`);
        }

        movement.callback = this.handleMovementComplete.bind(this);

        this.player.movement = movement;
        this.player.timeSinceMovementChange = 0;

        this.log(`new player movemenet registered:`)
        // console.log(this.player.movement);
    }

    isValidMovement(movement) {
        let bounds;

        if(this.team.hasPossession() === true) {
            bounds = this.player.bounds.inPossession;
        } else if(this.team.hasPossession() === false) {
            bounds = this.player.bounds.notInPossession;
        }

        return (
            movement.toLocation.x >= bounds.x[0] &&
            movement.toLocation.x <= bounds.x[1] &&
            movement.toLocation.y >= bounds.y[0] &&
            movement.toLocation.y <= bounds.y[1]
        );
    }

    replaceMovement(newMovement, checkConstraints = true) {        
        if(!this.isValidMovement(newMovement)) {
            // console.log(newMovement);
            throw new ApplicationException('invalid movement');
        }

        if(this.player.movement === null) {
            this.setMovement(newMovement);
        } else if(this.player.movement.isComplete === true) {
            this.cancelMovement();
            this.setMovement(newMovement);
        } else {
            if(this.player.movement.toLocation.equals(newMovement.toLocation)) {
                this.verbose('new movement equals existing movement');
            }
            if(checkConstraints === true && this.player.timeSinceMovementChange < 1000) {
                throw new ApplicationException('movement cooldown');
            }

            let directionXChange = false;
            if(
                (this.player.movement.direction.x >= 0 && newMovement.direction.x <= 0) ||
                (this.player.movement.direction.x <= 0 && newMovement.direction.x >= 0)
            ) {
                directionXChange = true;

                if(checkConstraints === true && this.player.timeInXDirection < 400) {
                    throw new ApplicationException('change of X direction cooldown');
                }
            }

            let directionYChange = false;
            if(
                (this.player.movement.direction.y >= 0 && newMovement.direction.y <= 0) ||
                (this.player.movement.direction.y <= 0 && newMovement.direction.y >= 0)
            ) {
                directionYChange = true;
                if(checkConstraints === true && this.player.timeInYDirection < 400) {
                    throw new ApplicationException('change of Y direction cooldown');
                }
            }

            if(directionXChange === true) {
                this.player.timeInXDirection = 0;
            }

            if(directionYChange === true) {
                this.player.timeInYDirection = 0;
            }

            this.cancelMovement();
            this.setMovement(newMovement);
        }
    }

    cancelMovement() {
        this.log(`cancelling existing movement in progess: ${this.player.movement?.type}`);
        this.player.movement = null;
    }

    commonTick(time) {
        this.player.timeSinceMovementChange += time.deltaMS;
        this.player.timeInXDirection += time.deltaMS;
        this.player.timeInYDirection += time.deltaMS;
        if(this.player.timeSinceLastShotEvaluation !== null) {
            this.player.timeSinceLastShotEvaluation += time.deltaMS;
        }

        this.updatePlayerLocation(time.deltaMS);
    }

    buildFrame() {
        const transformedPosition = this.team.sim.transformPos(this.player.location);

        this.graphics.puck.position = transformedPosition;

        this.graphics.positionLabel.position = transformedPosition;

        this.graphics.nameLabelBg.position = {
            x: transformedPosition.x - this.graphics.nameLabelBg.width/2,
            y: transformedPosition.y + 20
        }

        this.graphics.nameLabel.position = {
            x: transformedPosition.x,
            y: transformedPosition.y + 28
        }

        if(!!window.debug && window.debug[this.player.name].state === true) {
            this.graphics.stateLabel.text = this.nextState || '...';

            this.graphics.stateLabel.x = this.team.sim._transformPosX(this.player.location.x);
            this.graphics.stateLabel.y = this.team.sim._transformPosY(this.player.location.y)-28;

            this.graphics.stateLabelBg.width = this.graphics.stateLabel.width+8;
            this.graphics.stateLabelBg.height = this.graphics.stateLabel.height+2;

            this.graphics.stateLabelBg.x = this.team.sim._transformPosX(this.player.location.x) - (this.graphics.stateLabelBg.width / 2);
            this.graphics.stateLabelBg.y = this.team.sim._transformPosY(this.player.location.y) - 34;
            
            this.graphics.stateContainer.visible = true;
        } else {
            this.graphics.stateContainer.visible = false;
        }

        if(
            !!window.debug && 
            (
                window.debug[this.player.name].direction === true &&
                this.player.movement !== null
            )
        ) {
            this.graphics.direction.clear();
            this.graphics.direction.beginPath();
            this.graphics.direction.moveTo(this.graphics.puck.x, this.graphics.puck.y)
            this.graphics.direction.lineTo(
                this.team.sim._transformPosX(this.player.location.x + (this.player.movement.direction.x * 5)),
                this.team.sim._transformPosY(this.player.location.y + (this.player.movement.direction.y * 5))
            );
            this.graphics.direction.stroke();

            this.graphics.direction.visible = true;
        } else {
            this.graphics.direction.visible = false;
        }

        if(
            !!window.debug && 
            (
                window.debug[this.player.name].view === true &&
                this.player.movement !== null
            )
        ) {
            const fieldOfView = calculateFieldOfView(this.player.location, this.player.movement.direction);

            if(fieldOfView !== null) {
                this.graphics.view
                    .clear()
                    .beginPath()
                    .moveTo(this.graphics.puck.x, this.graphics.puck.y)
                    .lineTo(
                        this.team.sim._transformPosX(fieldOfView.left.x),
                        this.team.sim._transformPosY(fieldOfView.left.y)
                    )
                    .lineTo(
                        this.team.sim._transformPosX(fieldOfView.right.x),
                        this.team.sim._transformPosY(fieldOfView.right.y)
                    )
                    .closePath()
                    .stroke()
                    .fill();
            }
            
            this.graphics.view.visible = fieldOfView !== null;
        } else {
            this.graphics.view.visible = false;
        }

        if(!!window.debug) {
            this.graphics.zone.visible = window.debug[this.player.name].zone;
        } else {
            this.graphics.zone.visible = false;
        }
    }

    /* ATTACK METHODS */

    tickAttack(time) {
        this.prevState = this.nextState;
        this.nextState = undefined;

        this.log(`defender distance: ${this.player.marker?.dIndex} (${this.player.marker?.distance})`);

        if(
            this.player.movement !== null &&
            this.player.movement.isComplete === false
        ) {
            this.nextState = 'continueMovement';
            //review movement in progress
            switch(this.player.movement.type) {
                case movementTypes.ONSIDE:
                    //check if player is now on-side
                    if(this.isOffside() === false) {
                        this.cancelMovement();
                    }
                    break;
                case movementTypes.INTO_SPACE:
                    if(this.shouldCancelMovementIntoSpace() === true) {
                        this.cancelMovement();
                    }
                    break;
            }
        } else {
            //assess movement options
            if(
                this.team.sim.ball.currentPlayer === null &&
                this.team.sim.ball.previousPlayer !== this
            ) {
                this.verbose('ball is loose');
                this.processLooseBall();
            } else if(this.team.sim.ball.currentPlayer === this) {
                this.verbose('player in possession');
                this.player.timeInPossession += time.deltaMS;
                this.processPlayerInPossession();
            }

            if(this.nextState === undefined) {
                this.verbose('movement only');
                this.processPlayerMovement();
            }
        }

        this.log(`final state: ${this.nextState}`);

        this.commonTick(time);
    }

    playerTakeBallPossession() {
        this.log(`taking possession of ball`);

        this.team.sim.ball.previousPlayer = this.team.sim.ball.currentPlayer;
        this.team.sim.ball.currentPlayer = this;
        this.team.sim.ball.cancelMovement();

        this.passiveMSAfterPossessionTaken = getRandomInt(2, 5) * 100; //random possession cooldown each possession
        
        this.player.timeInPossession = this.isPlayerInPenaltyArea() == true ? 2000 : 0; //allow for quick decisions when receive ball inside box

        this.player.timeSinceLastShotEvaluation = null;
    }

    canPlayerTakeBallPossession() {
        const d = calculateDistance(this.team.sim.ball.location, this.player.location);
        return d < 1;
    }

    willPlayerReceiveBall() {
        return this.team.sim.ball.movement?.toLocation.equals(this.player.location);
    }

    passBallToFeetToPlayer(playerIndex, subType, distance) {
        this.log(`passing ball to feet to ${playerIndex}`);

        try {
            const ballMovement = new Movement(
                movementTypes.PASS_TO_FEET,
                this.team.sim.ball.location,
                this.team.players[playerIndex].player.location,
                getPassSpeed(distance),
                undefined,
                undefined,
                undefined,
                undefined,
                subType === 'cross' ? 2 : undefined
            );

            this.team.sim.ball.replaceMovement(ballMovement);

            this.team.sim.ball.previousPlayer = this.team.sim.ball.currentPlayer;
            this.team.sim.ball.currentPlayer = null;

            this.team.players[playerIndex].cancelMovement();
        } catch(error) {
            if(!isApplicationException(error)) throw error;

            this.log(`could not pass ball: ${error.message}`);
            this.nextState = 'passive';
        }
    }

    /*

        1. Calculate point in front of player where player will run into and receive ball in behind (x+rand(5, 10))
        2. Calculate required speed for ball and player to arrive at the same time
        3. Set ball direction + speed
        4. Set player direction + speed
        5. Ensure player and ball direction + speed will remain unchanged in subsequent ticks until movement completes
    */
    passBallInBehindToPlayer(playerIndex, passLocation) {
        this.log(`passing ball in behind to ${playerIndex}`);

        const runningSpeed = getRunningSpeed();

        const playerDistance = calculateDistance(this.team.players[playerIndex].player.location, passLocation);
        const ballDistance = calculateDistance(this.team.sim.ball.location, passLocation);

        const d = playerDistance / runningSpeed;

        const ballSpeed = ballDistance / d;

        try {
            const inBehindMovement = new Movement(
                movementTypes.IN_BEHIND,
                this.team.players[playerIndex].player.location,
                passLocation,
                runningSpeed
            );

            const ballMovement = new Movement(
                movementTypes.PASS_IN_BEHIND,
                this.team.sim.ball.location,
                passLocation,
                ballSpeed
            );

            this.team.players[playerIndex].replaceMovement(inBehindMovement, false);

            this.team.sim.ball.previousPlayer = this.team.sim.ball.currentPlayer;
            this.team.sim.ball.currentPlayer = null;
            this.team.sim.ball.replaceMovement(ballMovement);
        } catch(error) {
            if(!isApplicationException(error)) throw error;

            this.log(`could not perform pass in behind: ${error.message}`);
            this.nextState = 'passive';
        }
    }

    doShot(decision) {
        try {
            let opponentGoal;
            if(this.team.orientation === orientations.LEFT) {
                opponentGoal = locations.rightGoal
            } else if(this.team.orientation === orientations.RIGHT) {
                opponentGoal = locations.leftGoal;
            }

            const shotTarget = new Point(
                opponentGoal.x,
                getRandomInt((sizes.pitch.y/2)*0.9, (sizes.pitch.y/2)*1.1)
            );

            const shotSpeed = getShotSpeed();

            const gkDistance = calculateDistance(this.team.sim.defendingTeam.goalkeeper.player.location, shotTarget);

            const shotDistance = calculateDistance(this.player.location, opponentGoal);

            const d = shotDistance / shotSpeed;

            const ballMovement = new Movement(
                movementTypes.SHOT,
                this.player.location,
                shotTarget,
                shotSpeed
            );

            const goalkeeperMovement = new Movement(
                movementTypes.ATTEMPT_SAVE,
                this.team.sim.defendingTeam.goalkeeper.player.location,
                new Point(
                    this.team.sim.defendingTeam.goalkeeper.player.location.x,
                    shotTarget.y
                ),
                gkDistance / d,
                probability(0.5) ? 350 : 150
            );

            this.team.sim.ball.replaceMovement(ballMovement);
            this.team.sim.defendingTeam.goalkeeper.handleShot(goalkeeperMovement);

            this.team.sim.ball.previousPlayer = this.team.sim.ball.currentPlayer;
            this.team.sim.ball.currentPlayer = null;
        } catch(error) {
            if(!isApplicationException(error)) throw error;

            this.log(`could not take shot: ${error.message}`);
        }
    }

    doJostle(decision) {
        try {
            this.cancelMovement();
            this.team.sim.defendingTeam.players[this.player.marker.dIndex].cancelMovement();

            const aMovement = new Movement(
                movementTypes.JOSTLE,
                this.player.location,
                new Point(
                    this.player.location.x + Math.random() * 2 - 2 / 2,
                    this.player.location.y + Math.random() * 2 - 2 / 2
                ),
                runWithoutBallSpeed
            );

            const bMovement = new Movement(
                movementTypes.JOSTLE,
                this.team.sim.defendingTeam.players[this.player.marker.dIndex].player.location,
                new Point(
                    this.team.sim.defendingTeam.players[this.player.marker.dIndex].player.location.x + Math.random() * 6 - 6 / 2,
                    this.team.sim.defendingTeam.players[this.player.marker.dIndex].player.location.y + Math.random() * 6 - 6 / 2
                ),
                runWithoutBallSpeed
            );

            this.setMovement(aMovement);
            this.team.sim.defendingTeam.players[this.player.marker.dIndex].setMovement(bMovement);
        } catch(error) {
            if(!isApplicationException(error)) throw error;

            this.log(`could not jostle: ${error.message}`);
            this.nextState = 'passive';
        }
    }

    evaluateJostlingOption() {
        if(this.team.sim.hasPhaseOfPlayExpired() === true) {
            return new PossessionOption(this.player.position, 'jostle', 0, 'phase of play expired');
        }

        const option = {
            type: 'jostle'
        }

        if(this.player.marker?.distance < 2) {
            option.domainWeight = 1;
        } else {
            option.domainWeight = 0;
            option.reason = 'opponent too far';
        }

        return new PossessionOption(this.player.position, option.type, option.domainWeight, option.reason);
    }

    evaluateShootingOption() {
        if(this.team.sim.isLastPhaseOfPlay() === false) {
            return new PossessionOption(this.player.position, 'shot', 0, 'not last phase of play');
        }

        for(const shootingZone of shootingZones) {
            const bounds = [
                convertPointForOrientation(shootingZone.bounds[0], this.team.orientation),
                convertPointForOrientation(shootingZone.bounds[1], this.team.orientation)
            ];

            if(this.team.orientation === orientations.RIGHT) {
                const t1 = bounds[0];
                const t2 = bounds[1];

                bounds[0] = t2;
                bounds[1] = t1;
            }

            if(isPointInBoundingBox(this.player.location, bounds)) {
                if(shootingZone.domainWeight < 1 && this.player.timeSinceLastShotEvaluation !== null && this.player.timeSinceLastShotEvaluation < 500) {
                    return new PossessionOption(this.player.position, 'shot', 0, 'cooldown');
                } else {
                    this.player.timeSinceLastShotEvaluation = 0;
    
                    return new PossessionOption(this.player.position, 'shot', shootingZone.domainWeight);
                }
            }
        }

        return new PossessionOption(this.player.position, 'shot', 0, 'distance/angle constraint');
    }

    /*
        1. determine new direction (for now always a static point most advanced in zone)
        2. calculate FoV for new direction
        3. distance of closest player determines dribble distance (once dribble over recalculate next action)
        4. greater distance = greater territory gain potential = higher probability of dribble
            a. add additional weight if player is already dribbling (e.g. continue dribbling is easier than starting)
    */
    evaluateDribblingOptions() {
        if(convertXForOrientation(this.player.location.x, this.team.orientation) >= 70 && this.team.sim.isLastPhaseOfPlay() === false) {
            return [new PossessionOption(this.player.position, 'dribble', 0, 'not final phase')];
        }

        const options = [];

        for(let n=0; n<playerPositions[this.player.position].dribbleYTargets.length; n++) {
            let dribbleCompass;

            if(this.team.orientation === orientations.LEFT) {
                dribbleCompass = this.constrainPointsWithinBounds(
                    new Point(
                        Math.min(convertXForOrientation(90, this.team.orientation), convertXForOrientation(this.player.bounds.inPossession.x[1], this.team.orientation)),
                        playerPositions[this.player.position].dribbleYTargets[n]+getRandomInt(-5, 5)
                    )
                );
            } else if(this.team.orientation === orientations.RIGHT) {
                dribbleCompass = this.constrainPointsWithinBounds(
                    new Point(
                        Math.max(convertXForOrientation(90, this.team.orientation), convertXForOrientation(this.player.bounds.inPossession.x[1], this.team.orientation)),
                        playerPositions[this.player.position].dribbleYTargets[n]+getRandomInt(-5, 5)
                    )
                );
            }

            const distance = calculateDistance(this.player.location, dribbleCompass);
    
            if(distance < 5) {
                options.push(new PossessionOption(this.player.position, 'dribble', 0, 'distance too short'))
                continue;
            }
    
            const direction = calculateDirection(this.player.location, dribbleCompass);
    
            const fieldOfView = calculateFieldOfView(this.player.location, direction);
    
            if(!fieldOfView) {
                options.push(new PossessionOption(this.player.position, 'dribble', 0, 'invalid dFoV'));
                continue;
            }
    
            const triangle = [
                this.player.location.clone(),
                fieldOfView.left.clone(),
                fieldOfView.right.clone()
            ]
    
            let closestOpponent;
            for(let i=0; i<this.opponentDistanceNodes.length; i++) {
                if(!isPointInTriangle(this.team.sim.defendingTeam.players[this.opponentDistanceNodes[i].playerIndex].player.location, triangle)) continue;
    
                if(!closestOpponent || this.opponentDistanceNodes[i].distance < closestOpponent.distance) {
                    closestOpponent = this.opponentDistanceNodes[i];
                }
            }
    
            let bonusWeight = 0;
    
            if(this.prevState === 'dribbleTowardsGoal') {
                bonusWeight += 1;
            }
    
            const option = new PossessionOption(this.player.position, 'dribble', normaliseValue(Math.min(3, (((closestOpponent?.distance || 30) / 5) * 0.6)+bonusWeight), 3));
            option.dribble = {
                direction,
                distance: Math.min(closestOpponent?.distance+1 || 15, distance)
            }

            options.push(option);
        }

        for(let i=0; i<options.length; i++) {
            options[i].dilute(options.length);
        }

        return options;
    }

    hasStrayedFromTargetX(targetX) {
        return Math.abs(this.player.location.x - targetX) > 5
    }

    getMovementIntoSpace() {
        const targetX = this.getTargetXLocation();

        this.log(`targetX: ${targetX}`);

        if(
            !['CB', 'LB', 'RB', 'LWB', 'CDM', 'RWB'].includes(this.player.position) &&
            this.isBeingTightlyMarked() === true
        ) {
            const evadeY = this.getEvadeY();

            if(this.hasStrayedFromTargetX(targetX) === true) {
                this.verbose('move X, evade Y');

                return new Movement(
                    movementTypes.INTO_SPACE,
                    this.player.location,
                    new Point(
                        targetX,
                        evadeY
                    ),
                    getRunningSpeed(),
                    150
                );
            } else {
                this.verbose('shuffle X, evade Y');

                return new Movement(
                    movementTypes.INTO_SPACE,
                    this.player.location,
                    new Point(
                        constrainValue(this.player.location.x + getRandomInt(-4, 4), 0, 100),
                        evadeY
                    ),
                    getRunningSpeed(),
                    150,
                    {
                        evadingDefenderIndex: this.player.marker.dIndex
                    }
                );
            }
        } else {
            //so, player is not being marked tightly be a defender
            //but might they be too close to a teammate?
            let targetY;
            let targetYDebugDescriptor;
            let data;

            if(this.isCrowdingTeammate() === true) {
                this.verbose('is crowding teammate');
                //should this player move or the other player?
                //based on which player is further from their original position
                const this_dy = Math.abs(this.player.location.y - this.player.initialLocation.y)
                const other_dy = Math.abs(this.team.players[this.friendlyDistanceNodes[0].playerIndex].player.location.y - this.team.players[this.friendlyDistanceNodes[0].playerIndex].player.initialLocation.y);

                if(this_dy > other_dy) {
                    //this player should move Y towards initial Y
                    targetY = this.player.initialLocation.y+getRandomInt(-4, 4);
                    targetYDebugDescriptor = 'initial Y';
                    data = {evadingTeammateIndex: this.friendlyDistanceNodes[0].playerIndex}
                } else {
                    //other player should move Y towards initial Y
                    //this player keeps existing Y
                    targetY = this.player.location.y+getRandomInt(-4, 4);
                    targetYDebugDescriptor = 'keep Y';
                }
            } else {
                //distance to nearest teammate OK
                targetY = Math.max(Math.min(this.player.initialLocation.y + getRandomInt(-4, 4), 100), 0);
                targetYDebugDescriptor = 'keep Y';
            }

            if(this.hasStrayedFromTargetX(targetX)) {
                this.verbose(`move X, ${targetYDebugDescriptor}`);
                return new Movement(
                    movementTypes.INTO_SPACE,
                    this.player.location,
                    new Point(
                        targetX,
                        targetY
                    ),
                    getRunningSpeed(),
                    150,
                    data
                );
            } else {
                this.verbose(`keep X, ${targetYDebugDescriptor}`);
                return new Movement(
                    movementTypes.INTO_SPACE,
                    this.player.location,
                    new Point(
                        constrainValue(this.player.location.x + getRandomInt(-4, 4), 0, 100),
                        targetY
                    ),
                    getRunningSpeed(),
                    150,
                    data
                );
            }
        }
    }

    getEvadeY() {        
        const markerY = this.team.sim.defendingTeam.players[this.player.marker.dIndex].player.location.y;

        const y1 = Math.abs(this.player.shape.notInPossession.y[0]-markerY);
        const y2 = Math.abs(this.player.shape.notInPossession.y[1]-markerY);

        if(y1 > y2) {
            return this.player.shape.notInPossession.y[0];
        } else {
            return this.player.shape.notInPossession.y[1];
        }
    }

    processLooseBall() {
        const willPlayerReceiveBall = this.willPlayerReceiveBall();

        this.verbose(`will player receive ball: ${willPlayerReceiveBall}`);

        if(
            // willPlayerReceiveBall &&
            this.canPlayerTakeBallPossession()
        ) {
            this.log(`took possession of the ball`);
            this.playerTakeBallPossession();
            this.nextState = 'passive';
        } else if(willPlayerReceiveBall) {
            //is ball being passed to my current position?
            this.log(`waiting to receive the ball`);
            this.nextState = 'receivePass';
        }
    }

    processPlayerInPossession() {
        //player has ball
        this.log(`remains in possession of the ball`);

        //minimum possession time constraint for passing/dribbling
        if(this.player.timeInPossession < this.passiveMSAfterPossessionTaken) {
            //hold possession
            this.log(`possession cooldown`);
            this.nextState = 'passive';
        } else {
            this.verbose('no possession cooldown');

            const possessionOptions = [];

            possessionOptions.push(this.evaluateShootingOption());
            possessionOptions.push(this.evaluateJostlingOption());
            possessionOptions.push(...this.evaluateDribblingOptions());
            possessionOptions.push(...this.evaluateProgressivePassOptions());
            possessionOptions.push(...this.evaluateRegressivePassOptions());

            let agg = 0;
            //calc aggregate
            for(let i=0; i<possessionOptions.length; i++) {
                if((possessionOptions[i] instanceof PossessionOption) === false) {
                    throw new ApplicationException('invalid possession option');
                }
                agg += possessionOptions[i].comparativeWeight;
            }

            //calc probability
            for(let i=0; i<possessionOptions.length; i++) {
                possessionOptions[i].probability = possessionOptions[i].comparativeWeight / agg;
            }

            console.log(possessionOptions);

            if(agg > 0) {
                let decision;
                if(possessionOptions[0].domainWeight > 0.9) {
                    decision = possessionOptions[0];
                } else {
                    decision = weightedRandom(possessionOptions);
                }

                switch(decision.type) {
                    case 'shot':
                        this.log('will attempt shot');
                        this.doShot(decision);
                        break;
                    case 'dribble':
                        this.log('will attempt dribble');
                        this.doDribble(decision);
                        break;
                    case 'progressivePass':
                        this.log('will attempt prog. pass');
                        this.doProgressivePass(decision);
                        break;
                    case 'regressivePass':
                        this.log('will attempt reg. pass');
                        this.doRegressivePass(decision);
                        break;
                    case 'holdPossession':
                        this.log('will hold possesssion');
                        this.nextState = 'passive';
                        break;
                    case 'jostle':
                        this.log('will attempt jostle');
                        this.doJostle(decision);
                        break;
                    default:
                        throw new ApplicationException('invalid decision');
                        break;
                }
            } else {
                this.log('no viable possession options this tick');
                this.nextState = 'passive';
            }
        }
    }

    evaluateProgressivePassOptions() {
        //disabled to allow interceptions to happen
        // if(this.team.sim.hasPhaseOfPlayExpired() === true) {
        //     return [new PossessionOption(this.player.position, 'progressivePass', 0, 'phase of play expired')];
        // }

        //is there a progressive passing opportunity?
        //does not consider whether defender in the way of pass
        let passingOptions = [];
        let evaluators = [
            _evaluateCross.bind(this),
            _evaluatePassInBehind.bind(this),
            _evaluatePassIntoSpace.bind(this),
            _evaluatePassToFeet.bind(this)
        ];
        for(let pIndex=0; pIndex<this.team.players.length; pIndex++) {
            if(pIndex === this.player.index) continue;

            for(const evaluator of evaluators) {
                if(evaluator(pIndex, passingOptions) !== false) break;
            }
        }

        this.log(`found ${passingOptions.length} progressive passing options`);

        return passingOptions;

        function _evaluateCross(pIndex, passingOptions) {
            if(this.team.sim.isLastPhaseOfPlay() === false) return false;
            
            if(
                convertXForOrientation(this.player.location.x, this.team.orientation) > 80 &&
                (
                    this.player.location.y <= 15 ||
                    this.player.location.y >= 45
                )
            ) {
                //player in crossing position
                this.verbose('player is in crossing position');
                if(
                    this.team.players[pIndex].isOffside() === false &&
                    (
                        (
                            this.team.orientation === orientations.LEFT &&
                            isPointInBoundingBox(this.team.players[pIndex].player.location, locations.leftDangerZone) === true
                        ) ||
                        (
                            this.team.orientation === orientations.RIGHT &&
                            isPointInBoundingBox(this.team.players[pIndex].player.location, locations.rightDangerZone) === true
                        )
                    )
                ) {
                    const distance = calculateDistance(this.player.location, this.team.players[pIndex].player.location);

                    const passingOption = new PossessionOption(
                        this.player.position,
                        'progressivePass',
                        _calculateWeight(
                            distance,
                            15,// this.team.players[pIndex].opponentDistanceNodes[0].distance, //manually set distance to attacker to prevent unfair prejudice against crosses
                            convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation)
                        )
                    );
                    passingOption.pass = {
                        passType: 'toFeet',
                        subType: 'cross',
                        toPlayerIndex: pIndex,
                        distance
                    }

                    passingOptions.push(passingOption);
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        function _evaluatePassInBehind(pIndex, passingOptions) {
            if(this.team.sim.isLastPhaseOfPlay() === false) return false;

            //player must be in line with last defender and x < 85 (space to run into)
            if(
                convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) > convertXForOrientation(this.player.location.x + 5, this.team.orientation) &&
                convertXForOrientation(this.team.sim.defendingTeam.players[this.team.sim.defendingTeam.lastDefender].player.location.x, this.team.orientation)-convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) >= -1 && //allow a "slight" offside ;)
                convertXForOrientation(this.team.sim.defendingTeam.players[this.team.sim.defendingTeam.lastDefender].player.location.x, this.team.orientation)-convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) <= 3 &&
                convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) <= 85
            ) {
                let passLocation;

                if(this.team.orientation === orientations.LEFT) {
                    passLocation = this.team.players[pIndex].constrainPointsWithinBounds(
                        new Point(
                            this.team.players[pIndex].player.location.x + getRandomInt(10, 15),
                            this.team.players[pIndex].player.location.y + getRandomInt(-10, 10)
                        )
                    );
                } else {
                    passLocation = this.team.players[pIndex].constrainPointsWithinBounds(
                        new Point(
                            this.team.players[pIndex].player.location.x - getRandomInt(10, 15),
                            this.team.players[pIndex].player.location.y + getRandomInt(-10, 10)
                        )
                    );
                }

                const distance = calculateDistance(this.player.location, passLocation);

                const passingOption = new PossessionOption(
                    this.player.position,
                    'progressivePass',
                    _calculateWeight(
                        distance,
                        100,
                        100
                    )
                );
                passingOption.pass = {
                    passType: 'inBehind',
                    toPlayerIndex: pIndex,
                    distance,
                    passLocation
                }

                passingOptions.push(passingOption);
            } else {
                return false;
            }
        }

        function _evaluatePassIntoSpace(pIndex, passingOptions) {
            if(
                this.team.sim.isLastPhaseOfPlay() === false &&
                convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) >= 70
            ) return false;

            if(
                this.team.players[pIndex].isOffside() === false &&
                convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) > convertXForOrientation(this.player.location.x + 5, this.team.orientation) &&
                convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) <= 85
            ) {
                let passLocation;

                if(this.team.orientation === orientations.LEFT) {
                    passLocation = this.team.players[pIndex].constrainPointsWithinBounds(
                        new Point(
                            this.team.players[pIndex].player.location.x + getRandomInt(10, 15),
                            this.team.players[pIndex].player.location.y + getRandomInt(-5, 5)
                        )
                    );
                } else {
                    passLocation = this.team.players[pIndex].constrainPointsWithinBounds(
                        new Point(
                            this.team.players[pIndex].player.location.x - getRandomInt(10, 15),
                            this.team.players[pIndex].player.location.y + getRandomInt(-5, 5)
                        )
                    );
                }

                const direction = calculateDirection(this.team.players[pIndex].player.location, passLocation);

                const dFoV = calculateFieldOfView(this.team.players[pIndex].player.location, direction);

                if(!dFoV) return false;

                const triangle = [
                    this.team.players[pIndex].player.location,
                    dFoV.left.clone(),
                    dFoV.right.clone()
                ];

                for(let i=0; i<this.team.players[pIndex].opponentDistanceNodes.length; i++) {
                    if(isPointInTriangle(this.team.sim.defendingTeam.players[this.team.players[pIndex].opponentDistanceNodes[i].playerIndex].player.location, triangle)) {
                        return false;
                    }
                }

                const distance = calculateDistance(this.player.location, passLocation);

                const passingOption = new PossessionOption(
                    this.player.position,
                    'progressivePass',
                    _calculateWeight(
                        distance,
                        100,
                        convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation)
                    )
                );
                passingOption.pass = {
                    passType: 'intoSpace',
                    toPlayerIndex: pIndex,
                    distance,
                    passLocation
                }

                passingOptions.push(passingOption);
            } else {
                return false;
            }
        }

        function _evaluatePassToFeet(pIndex, passingOptions) {
            if(
                this.team.sim.isLastPhaseOfPlay() === false &&
                convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) >= 70
            ) return false;
            
            //pass to feet of player in sufficient space
            if(
                this.team.players[pIndex].isOffside() === false &&
                convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) > convertXForOrientation(this.player.location.x + 5, this.team.orientation) &&
                this.team.players[pIndex].opponentDistanceNodes[0].distance > 5
            ) {
                const distance = calculateDistance(this.player.location, this.team.players[pIndex].player.location);

                const passingOption = new PossessionOption(
                    this.player.position,
                    'progressivePass',
                    _calculateWeight(
                        distance,
                        this.team.players[pIndex].opponentDistanceNodes[0].distance,
                        convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation)
                    )
                );
                passingOption.pass = {
                    passType: 'toFeet',
                    toPlayerIndex: pIndex,
                    distance
                }

                passingOptions.push(passingOption);
            } else {
                return false;
            }
        }

        function _calculateWeight(
            distanceFromPasser,
            distanceFromNearestOpponent,
            xLocation
        ) {
            let weight = 0;

            // weight += constrainValue((100-(distanceFromPasser-10))/100, 0, 1);
            weight += constrainValue(Math.pow(100-distanceFromPasser, 2)/8000, 0, 1);

            weight += constrainValue((distanceFromNearestOpponent/5)/10, 0, 1);
            weight += constrainValue(xLocation/100, 0, 1);

            return weight / 3;
        }
    }

    doProgressivePass(decision) {
        if(decision.pass.passType === 'toFeet') {
            //pass the ball forwards
            this.log(`attempting progressive pass to feet with subtype ${decision.pass.subType} to A${decision.pass.toPlayerIndex} with probability ${decision.probability}`);
            this.passBallToFeetToPlayer(decision.pass.toPlayerIndex, decision.pass.subType, decision.pass.distance);
            this.nextState = 'makePass';
        } else if(decision.pass.passType === 'inBehind' || decision.pass.passType === 'intoSpace') {
            this.log(`attempting progressive pass ${decision.pass.passType} to A${decision.pass.toPlayerIndex} with probability ${decision.probability}`);
            this.passBallInBehindToPlayer(decision.pass.toPlayerIndex, decision.pass.passLocation);
            this.nextState = 'makePass';
        }

        this.player.timeSinceLastShotEvaluation = null;
        this.player.timeInPossession = 0;
    }

    evaluateRegressivePassOptions() {
        //disabled to allow interceptions to happen
        // if(this.team.sim.hasPhaseOfPlayExpired() === true) {
        //     return [new PossessionOption(this.player.position, 'regressivePass', 0, 'phase of play expired')];
        // }

        let passingOptions = [];
        for(let pIndex=0; pIndex<this.team.players.length; pIndex++) {
            if(
                pIndex !== this.player.index &&
                this.team.players[pIndex].opponentDistanceNodes[0].distance > 5 &&
                (convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) - convertXForOrientation(this.player.location.x, this.team.orientation)) < 0 &&
                (convertXForOrientation(this.team.players[pIndex].player.location.x, this.team.orientation) - convertXForOrientation(this.player.location.x, this.team.orientation)) > -30
            ) {
                const distance = calculateDistance(this.player.location, this.team.players[pIndex].player.location);

                // if(this.team.players[pIndex].player.location.x > 25) continue; //don't do silly long backwards passes

                const passingOption = new PossessionOption(
                    this.player.position,
                    'regressivePass',
                    _calculateWeight(
                        distance,
                        this.team.players[pIndex].opponentDistanceNodes[0].distance
                    )
                );
                passingOption.pass = {
                    passType: 'toFeet',
                    toPlayerIndex: pIndex,
                    distance
                }

                passingOptions.push(passingOption);
            }
        }

        if(passingOptions.length === 0) {
            passingOptions.push(new PossessionOption(this.player.position, 'holdPossession', 0.6, 'no regressive pass options'));
        }

        return passingOptions;

        function _calculateWeight(
            distanceFromPasser,
            distanceFromNearestOpponent
        ) {
            let weight = 0;

            weight += constrainValue((100-(distanceFromPasser-10))/100, 0, 1);
            weight += constrainValue((distanceFromNearestOpponent/5)/10, 0, 1);

            return weight / 2;
        }
    }

    doRegressivePass(decision) {
        this.log(`attempting regressive pass to A${decision.pass.toPlayerIndex}`);
        this.passBallToFeetToPlayer(decision.pass.toPlayerIndex, undefined, decision.pass.distance);
        this.nextState = 'makePass';
    }

    isPlayerInPenaltyArea() {
        return this.player.location.x >= 85 && this.player.location.y >= 15 && this.player.location.y <= 45;
    }

    handleDribbleNearComplete() {
        this.log('cancelling dribble early');

        this.handleMovementComplete();
        this.prevState = 'dribbleTowardsGoal'; //increase prob of continuing dribble
    }

    doDribble(decision) {
        const dribbleMovement = new Movement(
            movementTypes.DRIBBLE,
            this.player.location,
            new Point(
                this.player.location.x + (decision.dribble.direction.x * decision.dribble.distance),
                this.player.location.y + (decision.dribble.direction.y * decision.dribble.distance)
            ),
            getDribbleSpeed(),
            undefined,
            undefined,
            this.handleDribbleNearComplete.bind(this),
            0.8
        )

        try {
            this.replaceMovement(dribbleMovement);
            this.nextState = 'dribbleTowardsGoal' //TODO - update the name of this as it's no longer towards goal
        } catch(error) {
            if(!isApplicationException(error)) throw error;

            this.log(`could not perform dribble: ${error.message}`);
            this.nextState = 'passive'; //dribble failed
        }
    }

    getOppositionLastDefenderLocationX() {
        let oppositionLastDefenderLocationX;

        if(this.team.orientation === orientations.LEFT) {
            oppositionLastDefenderLocationX = this.team.sim.rightTeam.players[this.team.sim.rightTeam.lastDefender].player.location.x
        } else if(this.team.orientation === orientations.RIGHT) {
            oppositionLastDefenderLocationX = this.team.sim.leftTeam.players[this.team.sim.leftTeam.lastDefender].player.location.x
        }

        return oppositionLastDefenderLocationX;
    }

    isOffside() {
        return (
            convertXForOrientation(this.player.location.x, this.team.orientation) > convertXForOrientation(this.getOppositionLastDefenderLocationX(), this.team.orientation) //player is behind last defender
            &&
            convertXForOrientation(this.player.location.x, this.team.orientation) > convertXForOrientation(this.team.sim.ball.location.x, this.team.orientation) //player is ahead of ball
        );
    }

    processPlayerMovement() {
        if(this.isOffside() === true) {
            this.log(`is offside, moving onside`);

            let onsideMovement;

            if(this.team.orientation === orientations.LEFT) {
                onsideMovement = new Movement(
                    movementTypes.ONSIDE,
                    this.player.location,
                    new Point(
                        this.team.sim.defendingTeam.players[this.team.sim.defendingTeam.lastDefender].player.location.x-3,
                        this.player.location.y
                    ),
                    getRunningSpeed()
                );
            } else {
                onsideMovement = new Movement(
                    movementTypes.ONSIDE,
                    this.player.location,
                    new Point(
                        this.team.sim.defendingTeam.players[this.team.sim.defendingTeam.lastDefender].player.location.x+3,
                        this.player.location.y
                    ),
                    getRunningSpeed()
                );
            }

            try {
                this.replaceMovement(onsideMovement);
                this.nextState = 'moveOnside';
            } catch(error) {
                if(!isApplicationException(error)) throw error;

                this.log(`could not move player: ${error.message}`);
                this.nextState = 'passive';
            }
        } else {
            try {
                const intoSpaceMovement = this.getMovementIntoSpace();
                this.replaceMovement(intoSpaceMovement);

                this.nextState = 'moveIntoSpace';
            } catch(error) {
                if(!isApplicationException(error)) throw error;

                this.log(`could not move player: ${error.message}`);
                this.nextState = 'passive';
            }
        }
    }

    isCrowdingTeammate() {
        return this.friendlyDistanceNodes[0].distance <= 5
    }

    isBeingTightlyMarked() {
        return this.player.marker !== null && this.player.marker.distance <= 5 && this.player.position !== 'ST';
    }

    shouldCancelMovementIntoSpace() {
        let offsideThreshold;

        if(this.team.orientation === orientations.LEFT) {
            offsideThreshold = this.player.location.x - 5;
        } else if(this.team.orientation === orientations.RIGHT) {
            offsideThreshold = this.player.location.x + 5;
        }

        if(convertXForOrientation(offsideThreshold, this.team.orientation) > convertXForOrientation(this.getOppositionLastDefenderLocationX(), this.team.orientation)) {
            this.verbose('cancelling movement: strayed offside');
            return true
        };
        
        //this is not the original teammate that movement is trying to avoid
        if(
            this.player.movement.data?.evadingTeammateIndex !== undefined &&
            this.player.movement.data.evadingTeammateIndex !== this.friendlyDistanceNodes[0].playerIndex &&
            this.isCrowdingTeammate() === true
        ) {
            this.verbose('cancelling movement: new teammate proximity');
            return true;
        }

        //target X is in oppposite direction to movement
        const targetX = this.getTargetXLocation();
        if(
            this.hasStrayedFromTargetX(targetX) === true &&
            (
                (
                    targetX > this.player.location.x &&
                    this.player.movement.direction.x < 0
                ) ||
                (
                    targetX < this.player.location.x &&
                    this.player.movement.direction.x > 0
                )
            )
        ) {
            this.verbose('cancelling movement: targetX');
            return true
        };

        //this is not the original defending that movement is trying to avoid
        if(
            this.player.movement.data?.evadingDefenderIndex !== undefined &&
            this.player.marker !== null &&
            this.player.movement.data.evadingDefenderIndex !== this.player.marker.dIndex &&
            this.isBeingTightlyMarked() === true
        ) {
            this.verbose('cancelling movement: new marker proximity');
            return true;
        }

        return false;
    }

    canPlayerTackle() {
        let node;
        
        for(let i=0; i<this.opponentDistanceNodes.length; i++) {
            if(this.opponentDistanceNodes[i].playerIndex === this.team.sim.ball.currentPlayer?.player.index) {
                node = this.opponentDistanceNodes[i];
            }
        }

        return node?.distance < 3;
    }

    canPlayerIntercept() {
        return this.canPlayerTakeBallPossession();
    }

    /* DEFEND METHODS */

    tickDefend(time) {
        if(
            this.team.sim.hasPhaseOfPlayExpired() === true &&
            (
                this.canPlayerTackle() === true ||
                this.canPlayerIntercept() === true
            )
        ) {
            this.team.sim.preTick = this.team.sim.teamTakePossession.bind(this.team.sim, this.team.orientation, this.player.index);
        } else if(
            this.player.movement === null ||
            this.player.movement.isComplete === true
        ) {            
            if(
                ['CB', 'LB', 'RB'].includes(this.player.position)
            ) {
                try {
                    let movement;

                    if(
                        this.player.marking !== null &&
                        (
                            convertXForOrientation(this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.location.x, this.team.orientation) >= 73 ||
                            this.player.marking.aIndex === this.team.sim.ball.currentPlayer?.player.index
                        ) &&
                        this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.timeSinceMovementChange > 400
                    ) {
                        this.log(`closing marker down`);
                        movement = new Movement(
                            movementTypes.MARKING,
                            this.player.location,
                            this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.movement?.toLocation || this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.location,
                            getRunningSpeed()
                        );
                    } else {
                        this.log(`holding defensive line`);
                        movement = new Movement(
                            movementTypes.DEFENSIVE_LINE,
                            this.player.location,
                            this.constrainPointsWithinBounds(
                                new Point(
                                    Math.abs(this.team.defensiveLine-this.player.location.x) > 0.5 ? this.team.defensiveLine+getRandomInt(-2, 2) : this.player.location.x,
                                    this.player.marking !== null && this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.timeSinceMovementChange > 400 ? this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.location.y : this.player.initialLocation.y
                                )
                            ),
                            getRunningSpeed()
                        );
                    }

                    this.replaceMovement(movement);
                } catch(error) {
                    if(!isApplicationException(error)) throw error;

                    this.log(`could not move player: ${error.message}`);
                    this.cancelMovement();
                }
            } else if(['LM', 'CM', 'RM'].includes(this.player.position)) {
                if(
                    this.player.marking !== null &&
                    this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.timeSinceMovementChange > 400 //1s reaction time delay for defender to attacking player movement
                ) {
                    this.log(`closing attacker down`);
                    try {
                        const markingMovement = new Movement(
                            movementTypes.MARKING,
                            this.player.location,
                            this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.movement?.toLocation || this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.location,
                            getRunningSpeed()
                        );

                        this.replaceMovement(markingMovement);
                    } catch(error) {
                        if(!isApplicationException(error)) throw error;

                        this.log(`could not move player: ${error.message}`);
                        this.cancelMovement();
                    }
                } else {
                    this.log('keeping shape');
                    try {
                        const x = this.getTargetXLocation();

                        const targetLocation = this.constrainPointsWithinBounds(new Point(
                            x,
                            this.player.initialLocation.y + getRandomInt(-2, 2)
                        ));

                        const shapeMovement = new Movement(
                            movementTypes.SHAPE,
                            this.player.location,
                            targetLocation,
                            getRunningSpeed(),
                            (getRandomInt(1, 10) * 100)
                        );

                        this.replaceMovement(shapeMovement);
                    } catch(error) {
                        if(!isApplicationException(error)) throw error;

                        this.log(`could not move player: ${error.message}`);
                        this.cancelMovement();
                    }
                }
            } else {
                if(
                    this.player.marking !== null &&
                    this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.timeSinceMovementChange > 400
                ) {
                    this.log(`closing attacker down`);
                    try {
                        const markingMovement = new Movement(
                            movementTypes.MARKING,
                            this.player.location,
                            this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.movement?.toLocation || this.team.sim.attackingTeam.players[this.player.marking.aIndex].player.location,
                            getRunningSpeed()
                        );

                        this.replaceMovement(markingMovement);
                    } catch(error) {
                        if(!isApplicationException(error)) throw error;

                        this.log(`could not move player: ${error.message}`);
                        this.cancelMovement();
                    }
                } else if(this.player.location.x < this.team.sim.attackingTeam.players[this.team.sim.attackingTeam.lastDefender].player.location.x) {
                    this.log(`is offside, moving onside`);
                    try {
                        const targetLocation = this.constrainPointsWithinBounds(new Point(
                            this.team.sim.attackingTeam.players[this.team.sim.attackingTeam.lastDefender].player.location.x+3,
                            this.player.location.y
                        ));

                        const onsideMovement = new Movement(
                            movementTypes.ONSIDE,
                            this.player.location,
                            targetLocation,
                            getRunningSpeed()
                        );

                        this.replaceMovement(onsideMovement);
                    } catch(error) {
                        if(!isApplicationException(error)) throw error;

                        this.log(`could not move player: ${error.message}`);
                        this.cancelMovement();
                    }
                } else {
                    this.log('keeping shape');
                    try {
                        const x = this.getTargetXLocation();

                        const targetLocation = this.constrainPointsWithinBounds(new Point(
                            x,
                            this.player.initialLocation.y+getRandomInt(-2, 2)
                        ));

                        const shapeMovement = new Movement(
                            movementTypes.SHAPE,
                            this.player.location,
                            targetLocation,
                            getRunningSpeed(),
                            (getRandomInt(1, 10) * 100)
                        );

                        this.replaceMovement(shapeMovement);
                    } catch(error) {
                        if(!isApplicationException(error)) throw error;

                        this.log(`could not move player: ${error.message}`);
                        this.cancelMovement();
                    }
                }
            }   
        } else {
            this.log('continuing movement');
        }

        this.commonTick(time);
    }
}

function _getInitialXPosition(position, orientation) {
    return convertXForOrientation(playerPositions[position].shape.x, orientation);
}

function _createPlayerGraphic(
    app,
    sim,
    player,
    teamColour
) {
    const graphics = {};

    let puckSize;
    if(window.innerWidth > window.innerHeight) {
        puckSize = Math.round(window.innerWidth * 0.015);
    } else {
        puckSize = Math.round(window.innerHeight * 0.015);
    }

    graphics.puck = new Graphics()
        .circle(0, 0, puckSize)
        .fill(teamColour)
        .stroke('#000');

    graphics.positionLabel = new BitmapText({
        text: player.position,
        style: {
            fontFamily: 'Arial',
            fontSize: 12,
            fill: teamColour === '#0000ff' ? '#FFFFFF' : '#000000'
        },
        anchor: 0.5
    });

    graphics.nameLabel = new BitmapText({
        text: player.name,
        style: {
            fontFamily: 'Arial',
            fontSize: 12,
            fill: '#fff'
        },
        anchor: 0.5
    });

    graphics.nameLabelBg = new Graphics()
        .rect(0, 0, graphics.nameLabel.width+8, graphics.nameLabel.height+2)
        .fill('#000')
        .stroke('#fff');

    graphics.nameContainer = new Container();
    graphics.nameContainer.addChild(graphics.nameLabelBg, graphics.nameLabel);

    graphics.stateLabel = new BitmapText({
        text: 'tbc',
        style: {
            fontFamily: 'Arial',
            fontSize: 12,
            fill: '#fff'
        },
        anchor: 0.5
    });

    graphics.stateLabelBg = new Graphics()
        .rect(0, 0, graphics.stateLabel.width+8, graphics.stateLabel.height+2)
        .fill('#000')
        .stroke('#fff');

    graphics.stateContainer = new Container();
    graphics.stateContainer.addChild(graphics.stateLabelBg, graphics.stateLabel);

    graphics.direction = new Graphics()
        .setStrokeStyle({width: 1, color: '#000'});

    graphics.view = new Graphics()
        .setStrokeStyle({width: 1, color: '#000'})
        .setFillStyle({color: '#000', alpha: 0.1});

    graphics.zone = new Graphics()
        .setStrokeStyle({width: 1, color: '#000',})
        .setFillStyle({color: '#fff', alpha: 0.2})
        .beginPath()
        .moveTo(
            sim._transformPosX(player.shape.notInPossession.x[0]),
            sim._transformPosY(player.shape.notInPossession.y[0])
        )
        .lineTo(
            sim._transformPosX(player.shape.notInPossession.x[1]),
            sim._transformPosY(player.shape.notInPossession.y[0])
        )
        .lineTo(
            sim._transformPosX(player.shape.notInPossession.x[1]),
            sim._transformPosY(player.shape.notInPossession.y[1])
        )
        .lineTo(
            sim._transformPosX(player.shape.notInPossession.x[0]),
            sim._transformPosY(player.shape.notInPossession.y[1])
        )
        .closePath()
        .stroke()
        .fill();

    app.stage.addChild(graphics.puck, graphics.positionLabel, graphics.nameContainer, graphics.stateContainer, graphics.direction, graphics.view, graphics.zone);

    return graphics;
}

export default Player;

class PossessionOption {
    constructor(position, type, domainWeight, reason) {
        this.type = type;
        this.domainWeight = domainWeight;
        this.reason = reason;
        this.position = position;

        if(this.domainWeight < 0 || this.domainWeight > 1) {
            throw new ApplicationException(`invalid domain weight: ${this.domainWeight}`)
        }

        this.comparativeWeight = this.domainWeight * playerPositions[this.position].possessionWeights[this.type];
    }

    dilute(factor) {
        this.domainWeight = this.domainWeight / factor;
        this.comparativeWeight = this.domainWeight * playerPositions[this.position].possessionWeights[this.type];
    }
}
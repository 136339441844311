import {Grid, Header, Loader, Placeholder} from 'semantic-ui-react';
import clone from 'lodash/clone';
import useDimensions from 'react-cool-dimensions';
import {useHistory, generatePath} from 'react-router';

import pitchTop from '../pitch-top2.png';
import pitchBottom from '../pitch-bottom2.png';
import {URL} from '../../../common/lib/paths';
import buildPitchFromLineup from '../../../common/lib/pitch';

const styles = {
    container: {
        position: 'relative',
        color: 'rgba(0,0,0,.87)'
    },
    pitch: {
        width: '100%',
        position: 'absolute'
    },
    row: {
        padding: 0
    },
}

export default function Lineups(props) {
    const history = useHistory();

    const { observe: pitchTopRef, height: pitchTopHeight } = useDimensions();
    const { observe: pitchBottomRef, height: pitchBottomHeight } = useDimensions();

    const handleShirtClick = (positionIndex, playerId) => {
        history.push(generatePath(URL.playerProfile, {playerId: playerId}));
    }

    let homePitchMap, awayPitchMap;

    if(!props.isLoading) {

        homePitchMap = buildPitchFromLineup(
            clone(props.lineups.home),
            props.tactics.home.formation,
            props.tactics.home.mentality,
            handleShirtClick.bind(this),
            true
        );

        awayPitchMap = buildPitchFromLineup(
            clone(props.lineups.away),
            props.tactics.away.formation,
            props.tactics.away.mentality,
            handleShirtClick.bind(this),
            false
        );
    }

    return (
        <>
        {!props.isLoading ?
            <>
                <Header as='h4' textAlign='center' style={{margin: '1em 0'}}>{props.homeTeamName}</Header>
            </>
            :
            <Placeholder>
                <Placeholder.Header />
                <Placeholder.Line />
            </Placeholder>
        }
        <div style={styles.container}>
            <img src={pitchTop} style={styles.pitch} ref={pitchTopRef} />
            {!props.isLoading ?
                <Grid textAlign='center' style={{overflow: 'hidden', width: '100%', margin: 0, height: `${pitchTopHeight}px`}}>
                    {homePitchMap.map((row) => {
                        if(row.length === 0) return null
    
                        return(
                            <Grid.Row style={styles.row} columns={row.length} verticalAlign='top'>
                                {row}
                            </Grid.Row>
                        );
                    })}
                </Grid>
                :
                <Loader active />
            }
        </div>
        <div style={{...styles.container, ...{marginTop: '-2px'}}}>
            <img src={pitchBottom} style={styles.pitch} ref={pitchBottomRef} />
            {!props.isLoading ?
                <Grid textAlign='center' style={{overflow: 'hidden', width: '100%', margin: 0, height: `${pitchBottomHeight}px`}}>
                    {awayPitchMap.map((row) => {
                        if(row.length === 0) return null

                        return(
                            <Grid.Row style={styles.row} columns={row.length} verticalAlign='bottom'>
                                {row}
                            </Grid.Row>
                        );
                    })}
                </Grid>
                :
                null
            }
        </div>
        {!props.isLoading ?
            <>
                <Header as='h4' textAlign='center' style={{margin: '1em 0'}}>{props.awayTeamName}</Header>
            </>
            :
            <Placeholder>
                <Placeholder.Header />
                <Placeholder.Line />
            </Placeholder>
        }
        </>
    )
}
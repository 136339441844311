import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import ViewProposedPlayerLoanOffer from "../../../playerTransferOfferLoan/interface";

export default function ViewPlayerLoanOffer(props) {
    const getPlayerTransferOfferLoanQuery = useQuery(
        ['getPlayerLoanOffer', props.playerTransferOfferLoanId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerTransferOfferLoan/${props.playerTransferOfferLoanId}`)
    );

    return(
        <ViewProposedPlayerLoanOffer
            isLoading={getPlayerTransferOfferLoanQuery.isLoading}
            playerTransferOfferLoan={getPlayerTransferOfferLoanQuery.data?.data?.response}
            isMyPlayer={props.manager.club.id === getPlayerTransferOfferLoanQuery.data?.data?.response.playerContract.club.id}
        />
    )
}
import { faCheckCircle, faExclamationCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Grid, Header } from "semantic-ui-react"
import colours from "../../../common/colours"
import SUIIconShim from "../../../common/components/SUIIconShim"
import TitledSegment from "../../../common/components/TitledSegment"
import Component_Currency from "../../../common/components/Currency"

export default function PlayerTransferAuctionBidStatus(props) {
    return(
        <TitledSegment
            title='Bid'
            noPadding={true}
        >
            <Grid className='gridReset transferAuctionBid'>
                {props.myLatestBid !== undefined &&
                    <LatestBid
                        isWinning={props.isWinning}
                        myLatestBid={props.myLatestBid}
                    />
                }
                {props.isWinning === false &&
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Button
                                loading={props.isLoading}
                                disabled={props.isWinning === true || props.playerTransferAuction?.isFinished === true}
                                onClick={props.handleCreateBid}
                            >
                                New bid
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                }
            </Grid>
        </TitledSegment>
    )
}

function LatestBid(props) {
    return(
        <Grid.Row columns={1}>
            <Grid.Column>
                <Header icon>
                    {props.isWinning === true ?
                            <>
                                <SUIIconShim>
                                    <FontAwesomeIcon icon={faCheckCircle} color={colours.positive} />
                                </SUIIconShim>
                                You are winning the auction
                                <Header.Subheader>
                                    Maximum bid: <Component_Currency value={props.myLatestBid.maxBid} />
                                </Header.Subheader>
                            </>
                        :
                            <>
                                <SUIIconShim>
                                    <FontAwesomeIcon icon={faExclamationCircle} color={colours.negative} />
                                </SUIIconShim>
                                You've been outbid
                                <Header.Subheader>
                                    Previous bid: <Component_Currency value={props.myLatestBid.maxBid} />
                                </Header.Subheader>
                            </>
                    }
                </Header>
            </Grid.Column>
        </Grid.Row>
    );
}
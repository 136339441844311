import { Label } from "semantic-ui-react";

export default function PositionLabel(props) {
    if(!props.teamTacticPosition) return null;

    return (
        <Label
            size='mini'
            color={props.teamTacticPosition.pitchSection !== null ? 'black' : false}
            style={style}
        >
            {namedPositions[props.teamTacticPosition.pitchSection] || 'SUB'}
        </Label>
    )
}

const style = {
    width: '34px',
    textAlign: 'center',
    marginRight: '.78571429em',
    verticalAlign: 'text-top',
    display: 'inline-block',
    paddingLeft: 0,
    paddingRight: 0
}

const namedPositions = {
    1: 'LB',
    7: 'CB',
    12: 'GK',
    13: 'CB',
    19: 'CB',
    25: 'RB',
    2: 'LWB',
    8: 'CDM',
    14: 'CDM',
    20: 'CDM',
    26: 'RWB',
    3: 'LM',
    9: 'CM',
    15: 'CM',
    21: 'CM',
    27: 'RM',
    4: 'LW',
    10: 'CAM',
    16: 'CAM',
    22: 'CAM',
    28: 'RW',
    11: 'ST',
    17: 'ST',
    23: 'ST'
}
import {generatePath, useLocation} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../common/lib/paths.js";
import { faChartSimple} from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "toolbar:cup:stats",
		defaultMessage: "Stats",
		description: "Link for cup stats page"
	}
});

export default function CupStatsToolbarItem(props) {
    const location = useLocation();

	return(
		<ToolbarItem
			path={{
				pathname: generatePath(URL.cupStats, {cupId: props.cupId}),
                state: location.state
			}}
			genericPath={URL.cupStats}
			icon={faChartSimple}
			messages={messages}
		/>
	);
}
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import cloneDeep from 'lodash/cloneDeep';

const initialFilterState = {
    filterDepartment: null,
    filterIsHead: null,
    filterNationShortcode: null,
    filterRating: {minimum: 0, maximum: 100},
    filterContractStatus: false,
    filterContractLength: null,
    filterAge: {minimum: 30, maximum: 60},
    filterOwnStaff: false,
    filterUninterested: false,
    filterOverlayOpen: false,
    page: 1
}

function filterStateReducer(currentFilterState, action) {
    let newFilterState = cloneDeep(currentFilterState);

    switch(action.name) {
        case 'toggleFilterOverlay':
            newFilterState.filterOverlayOpen = !currentFilterState.filterOverlayOpen;
            break;
        case 'openFilterOverlay':
            newFilterState.filterOverlayOpen = true;
            break;
        case 'updateFilters':
            console.log(action.value);
            newFilterState.filterDepartment = action.value.filterDepartment;
            newFilterState.filterNationShortcode = action.value.filterNationShortcode;
            newFilterState.filterIsHead = action.value.filterIsHead;
            newFilterState.filterRating = action.value.filterRating;
            newFilterState.filterContractStatus = action.value.filterContractStatus;
            newFilterState.filterContractLength = action.value.filterContractLength;
            newFilterState.filterAge = action.value.filterAge;
            newFilterState.filterOwnStaff = action.value.filterOwnStaff;
            newFilterState.filterUninterested = action.value.filterUninterested;
            newFilterState.filterOverlayOpen = false;
            newFilterState.page = 1;
            break;
        case 'clearFilters':
            newFilterState = {...newFilterState, ...initialFilterState}
            break;
        case 'nextPage':
            newFilterState.page = currentFilterState.page+1;
            break;
        case 'previousPage':
            newFilterState.page = currentFilterState.page-1;
            break;
    }

    return newFilterState;
}

function getFilterQueryParams(filterState, favStaff = false) {
    const params = [`page=${filterState.page}`, `favStaff=${favStaff}`];

    if(filterState.filterDepartment !== null) {
        params.push(`department=${filterState.filterDepartment}`);
    }

    if(filterState.filterIsHead !== null) {
        params.push(`isHead=${filterState.filterIsHead}`);
    }

    if(!!filterState.filterNationShortcode) {
        params.push(`nationGroupShortcode=${filterState.filterNationShortcode}`);
    }

    if(filterState.filterRating.minimum !== 0 || filterState.filterRating.maximum !== 100) {
        params.push(`ratingMin=${filterState.filterRating.minimum}`);
        params.push(`ratingMax=${filterState.filterRating.maximum}`);
    }

    if(filterState.filterAge.minimum !== 16 || filterState.filterAge.maximum !== 38) {
        params.push(`ageMin=${filterState.filterAge.minimum}`);
        params.push(`ageMax=${filterState.filterAge.maximum}`);
    }

    if(filterState.filterContractLength !== null) {
        params.push(`contractExpiry=${filterState.filterContractLength}`);
    }

    if(filterState.filterOwnStaff) {
        params.push('filterOwnStaff=true');
    }

    if(filterState.filterUninterested) {
        params.push('filterUninterested=true');
    }

    if(filterState.filterContractStatus) {
        params.push(`freeAgent=true`);
    }

    return params;
}

const filterProperties = [
    'filterDepartment',
    'filterNationShortcode',
    'filterIsHead',
    'filterRating',
    'filterContractStatus',
    'filterContractLength',
    'filterAge',
    'filterOwnStaff',
    'filterUninterested'
];

function getFilterStateFromPersistedState(filterState) {
    return ['page', ...filterProperties].map((property) => filterState[property]);
}

function isFilterActive(filterState) {
    const a = pick(filterState, filterProperties);
    const b = omit(initialFilterState, ['filterOverlayOpen', 'page']);

    return !isEqual(
        a,
        b
    );
}

export {
    initialFilterState,
    filterStateReducer,
    isFilterActive,
    getFilterQueryParams,
    getFilterStateFromPersistedState
}
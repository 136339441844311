//last verified against STAGE FOUR spreadsheet 3/10/2021

export default {
    positionMap: [
        'GK',
        'LB',
        'CB',
        'CB',
        'RB',
        'CM',
        'CM',
        'LW',
        'CAM',
        'RW',
        'ST'
    ],
    pitchHalfMap: [
        12,
        1,
        7,
        19,
        25,
        9,
        21,
        4,
        16,
        28,
        17
    ]
}
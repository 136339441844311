import DataTable from "../../../common/components/DataTable";
import {clubName} from "../../../common/dataTableColumns/club/clubName";
import {managerName} from '../../../common/dataTableColumns/manager/managerName';
import {leagueName} from '../../../common/dataTableColumns/leagueName';
import {currentAbility} from '../../../common/dataTableColumns/currentAbility';

export default function ClubListView(props) {
    const columns = [
        clubName(
            (club) => club,
            undefined
        ),
        managerName(
            (club) => club.manager
        ),
        leagueName(
            (club) => club.league
        ),
        currentAbility(
            (club) => club.reputationAbs,
            undefined
        )
    ];

    return(
        <DataTable
            columns={columns}
            data={props.clubs}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    )
}
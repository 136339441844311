import {Loader, Card, Divider, Button, Icon, Label} from 'semantic-ui-react';
import {FormattedDate, FormattedMessage} from 'react-intl';

import MessageSubjectInterface from './MessageSubject/interface';
import MessageBodyInterface from './MessageBody/interface';
import MessageActionButton from './MessageActionButton/interface';

import { formatValues } from "../inbox/lib";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

export default function MessagesInterface(props) {
	if(props.isLoading || !props.message || props.message?.reason === 'No records found') {
		return(
			<Loader active />
		);
	}

	return(
		<Card fluid>
			<Card.Content>
				<Card.Header>
					<MessageSubjectInterface message={props.message} />
				</Card.Header>
				<Card.Meta>
					<FormattedMessage {...fromLabelMessage} values={{name: formatValues([{text: props.message.fromEntityName, routeParameterValue: props.message.fromEntityId}], props.message.fromEntityType)}} />
					<br />
					<FormattedDate value={props.message.date} year="numeric" month="long" day="numeric"/>
				</Card.Meta>
				<Divider />
				<Card.Description style={{whiteSpace: 'pre-line'}}>
					<MessageBodyInterface message={props.message} />
				</Card.Description>
			</Card.Content>
			<Card.Content extra>
				<MessageActionButton message={props.message} />
				<Button
					floated='right'
					icon
					loading={props.deleteInProgress}
					onClick={props.deleteMessage}
				>
					<FontAwesomeIcon icon={faTrash} />
				</Button>
			</Card.Content>
		</Card>
	)
}

const fromLabelMessage = {
    id: "Page_Message.fromLabel",
    defaultMessage: "From: {name}",
    description: "Label for the field which contains the name of the manager which the message will be from"
}
import { faBarsProgress } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {useMemo, useState} from 'react';
import {Menu, Table, Grid} from 'semantic-ui-react';

export default function DataTableExpander(props) {
    const [view, setView] = useState('tabularHiddenCols');

    return(
        <div style={styles.wrapper}>
            <div style={styles.container}>
                {props.additionalTabs &&
                        <Menu
                        secondary
                        pointing
                        widths={1}
                        style={{marginBottom: '0.5em'}}
                        className='expanderMenu'
                    >
                        <Menu.Item
                            active={view==='tabularHiddenCols'}
                            onClick={() => setView('tabularHiddenCols')}
                        >
                            <FontAwesomeIcon icon={faBarsProgress} className='inlineIcon' />
                            {props.defaultTabName}
                        </Menu.Item>

                    </Menu>   
                }
                {view === 'tabularHiddenCols' &&
                    <TabularHiddenColumns
                        columns={props.columns}
                        data={props.data}
                    />
                }
            </div>
        </div>
    )
}

function TabularHiddenColumns(props) {
    const viewportWidth = window.innerWidth;

    const [firstColumn, secondColumn] = useMemo(() => {
        var columns = props.columns.filter((column) => column.hide > viewportWidth);

        columns = columns.reduce((res, column, index) => {
            if(index+1<=Math.ceil(columns.length/2)) {
                res[0].push(column);
            } else {
                res[1].push(column);
            }

            return res;
        }, [[], []]);

        return columns;
    }, [viewportWidth]);

    return(
        <Grid style={{width: '100%', margin: 0}}>
        <Grid.Row style={{padding: 0}}>
            <Grid.Column width={8} style={{borderRight: '1px solid #2224261a'}}>
                <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable'>
                    <Table.Body>
                        {firstColumn.map((column) => 
                            <Table.Row>
                                <Table.Cell collapsing>
                                    {column.name}
                                </Table.Cell>
                                <Table.Cell textAlign='right' style={{maxWidth: 0}} className='noTextwrap'>
                                    {column.selector ? column.selector(props.data) : column.cell(props.data)}
                                </Table.Cell>
                            </Table.Row>
                        )}           
                    </Table.Body>
                </Table>
            </Grid.Column>
            <Grid.Column width={8}>
                <Table basic='very' compact='very' unstackable style={{width: '100%'}} className='attrTable'>
                    <Table.Body>
                        {secondColumn.map((column) => 
                            <Table.Row>
                                <Table.Cell collapsing>
                                    {column.name}
                                </Table.Cell>
                                <Table.Cell textAlign='right' style={{maxWidth: 0}} className='noTextwrap'>
                                    {column.selector ? column.selector(props.data) : column.cell(props.data)}
                                </Table.Cell>
                            </Table.Row>
                        )}           
                    </Table.Body>
                </Table>
            </Grid.Column>
        </Grid.Row>
    </Grid>
    )
}

const styles = {
    wrapper: {
        backgroundColor: '#f2f2f2'
    },
    container: {
        maxWidth: '350px',
        margin: '0 auto',
        backgroundColor: '#FFF'
    }
}
import {useState} from 'react';
import { faCakeCandles, faClock, faFileContract, faFlag, faShieldHalved, faStar, faTag, faUserTie } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Table, Header, Segment, Placeholder, Button, Modal, Message } from "semantic-ui-react";
import {FormattedDate, FormattedPlural, FormattedMessage, defineMessage } from 'react-intl';
import ClubLink from "../../club/components/common/Link";
import NationGroupLinkWithFlag from "../../nationGroup/common/NationGroupLinkWithFlag";
import Component_RatingRoundall from "../../player/common/RatingRoundall";
import StaffContractExpiry from "../common/ContractExpiry";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Currency from '../../common/components/Currency';
import SUIIconShim from '../../common/components/SUIIconShim';
import TitledSegment from '../../common/components/TitledSegment';

dayjs.extend(relativeTime);

export default function StaffProfileInterface(props) {
    const [releaseDialogeOpen, setReleaseDialogOpen] = useState(false);

    return(
        <div style={{boxSizing: 'border-box'}}>
            <div style={{overflow: 'auto'}}>
                <Button
                    icon
                    toggle
                    disabled={props.isLoading}
                    loading={props.toggleFavStaffLoading}
                    active={props.isFavStaff}
                    onClick={props.handleToggleFavStaff}
                    floated='right'
                    color='black'
                >
                    <SUIIconShim>
                        <FontAwesomeIcon icon={faStar} fixedWidth />
                    </SUIIconShim>
                </Button>
            </div>
            <TitledSegment
                title={!props.isLoading && `${props.staff.person.firstName} ${props.staff.person.lastName}`}
                icon={faUserTie}
                noPadding={true}
            >
                <Grid style={{width: '100%', margin: 0, padding: '0.5em 0 0.5em 0'}}>
                    <Grid.Row style={{padding: 0}}>
                        <Grid.Column width={8}>
                            <Table basic='very' compact='very' unstackable style={{width: '100%'}}>
                                <Table.Body>
                                    {getProfileRow(faTag, (staff) => <FormattedMessage {...messages[`role_${staff.isHead ? 'head' : ''}${staff.department}`]} />)}
                                    {getProfileRow(faFlag, (staff) => <NationGroupLinkWithFlag shortcode={staff.person.nation.nationGroupShortcode} />)}
                                    {getProfileRow(faShieldHalved, (staff) => staff.staffContract?.club ? <ClubLink club={staff.staffContract.club} /> : 'Free agent')}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Table basic='very' compact='very' unstackable style={{width: '100%'}}>
                                <Table.Body>
                                    {getProfileRow(faStar, (staff) => <Component_RatingRoundall rating={staff.currentAbility} disableFloat={true} />)}
                                    {getProfileRow(faCakeCandles, (staff) => <span>{staff.person.age} years old</span>)}
                                    {getProfileRow(faClock, (staff) => <StaffContractExpiry staffContract={staff.staffContract} />)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </TitledSegment>
            {props.staff?.staffContract &&
                <TitledSegment
                    title='Contract'
                    icon={faFileContract}
                    noPadding={true}
                >
                    <Table basic='very' compact='very' unstackable style={{width: '100%', margin: 0, padding: '0.5em'}}>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell style={{fontWeight: 'bold'}} width={7}>Wage</Table.Cell>
                                <Table.Cell width={9}>
                                    {props.isLoading ?
                                        <Placeholder style={{width: '60px'}}><Placeholder.Line length='long' style={{height: 'unset'}} /></Placeholder>
                                        :
                                        <Currency value={props.staff.staffContract.salary} />
                                    }
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{fontWeight: 'bold'}}>Contract expiry</Table.Cell>
                                <Table.Cell>
                                    {props.isLoading ?
                                        <Placeholder style={{width: '60px'}}><Placeholder.Line length='very long' style={{height: 'unset'}} /></Placeholder>
                                        :
                                        <FormattedMessage
                                            {...messages.contractLengthValue}
                                            values={{
                                                date: <FormattedDate value={props.staff.staffContract.endSeason.endDate} timeZone='UTC' day='numeric' month='short' year='numeric' />,
                                                length: <FormattedPlural
                                                    value={dayjs.utc(props.staff.staffContract.endSeason.endDate).diff(dayjs.utc().endOf('isoWeek'), 'week')}
                                                    zero={
                                                        <FormattedMessage {...messages.seasonZeroText}  />
                                                    }
                                                    one={
                                                        <FormattedMessage {...messages.seasonSingularText} values={{length: dayjs.utc(props.staff.staffContract.endSeason.endDate).diff(dayjs.utc().endOf('isoWeek'), 'week')}} />
                                                    }
                                                    other={
                                                        <FormattedMessage {...messages.seasonPluralText} values={{length: dayjs.utc(props.staff.staffContract.endSeason.endDate).diff(dayjs.utc().endOf('isoWeek'), 'week')}} />
                                                    }
                                                />
                                            }}    
                                        />
                                    }
                                </Table.Cell>
                            </Table.Row>
                            <Table.Row>
                                <Table.Cell style={{fontWeight: 'bold'}}>Time at club</Table.Cell>
                                <Table.Cell>
                                    {props.isLoading ?
                                        <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                        :
                                        dayjs.utc(props.staff.staffContract.createdAt).to(new Date(), true)
                                    }
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                    {props.isMyStaff &&
                        <Grid style={{margin: 0}}>
                            <Grid.Column textAlign='center'>
                                <Button compact negative disabled={props.isLoading} loading={props.releaseStaffLoading} onClick={() => setReleaseDialogOpen(true)}>Release staff</Button>
                            </Grid.Column>
                        </Grid>
                    }
                </TitledSegment>
            }
            {!props.isLoading && props.isMyStaff && 
                <Modal
                    onClose={() => setReleaseDialogOpen(false)}
                    open={releaseDialogeOpen}
                >
                    <Modal.Header>Release staff</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>Are you sure you want to release {props.staff.person.firstName} {props.staff.person.lastName}? You will have to pay <Currency value={(props.staff.staffContract.salary/7) * dayjs(props.staff.staffContract.endSeason.endDate).diff(dayjs.utc(), 'day')} /> in compensation.</p>
                            {props.releaseStaffIsError &&
                                <Message negative>
                                    <FormattedMessage {...releaseStaffErrorMessages[props.releaseStaffErrorMessage.response?.key || 'unknown']} values={{staffName: `${props.staff.person.firstName} ${props.staff.person.lastName}`}} />
                                </Message>
                            }
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button
                            onClick={() => setReleaseDialogOpen(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            negative
                            onClick={() => props.handleReleasedStaff(props.staff.staffContract.id)}
                            loading={props.releaseStaffIsLoading}
                            disabled={props.releaseStaffIsLoading}
                        >
                            Release staff
                        </Button>
                    </Modal.Actions>
                </Modal>
            }
        </div>
    );

    function getProfileRow(icon, getValue) {
        return(
            <Table.Row>
                <Table.Cell collapsing style={{fontWeight: 'bold'}}><FontAwesomeIcon icon={icon} /></Table.Cell>
                <Table.Cell>
                    {props.isLoading ?
                        <Placeholder><Placeholder.Line length='short' style={{height: 'unset'}} /></Placeholder>
                        :
                        getValue(props.staff)
                    }
                </Table.Cell>
            </Table.Row>
        )
    }
}

const messages = {
    contractLengthValue: {
        id: "ContractOfferInterface:contractLengthDemandsDescriptor",
        defaultMessage: "{date} ({length})"
    },
    seasonZeroText: {
        id: "Layout_PersonContractOffer.seasonZeroText",
        defaultMessage: "end of season",
        description: "end of season"
    },
    seasonSingularText: {
        id: "Layout_PersonContractOffer.seasonSingularText",
        defaultMessage: "{length} season",
        description: "Singular form of the word 'season'. Lowercase."
    },
    seasonPluralText: {
        id: "Layout_PersonContractOffer.seasonPluralText",
        defaultMessage: "{length} seasons",
        description: "Plural form of the word 'season'. Lowercase."
    },
    role_headcoaching: {
        id: "role_headcoach",
        defaultMessage: "Head coach",
        description: "Name for a head coach staff member"
    },
    role_coaching: {
        id: "role_coach",
        defaultMessage: "Coach",
        description: "Name for a coaching staff member"
    },
    role_headphysio: {
        id: "role_headphsio",
        defaultMessage: "Head physio",
        description: "Name for a head physio staff member"
    },
    role_physio: {
        id: "role_physio",
        defaultMessage: "Physio",
        description: "Name for a physio staff member"
    },
    role_headyouth: {
        id: "role_headyouth",
        defaultMessage: "Youth manager",
        description: "Name for a head physio staff member"
    },
    role_youth: {
        id: "role_youth",
        defaultMessage: "Youth coach",
        description: "Name for a physio staff member"
    },
    role_headscouting: {
        id: "role_headscout",
        defaultMessage: "Head Scout",
        description: "Name for a head physio staff member"
    },
    role_scouting: {
        id: "role_scouto",
        defaultMessage: "Scout",
        description: "Name for a physio staff member"
    },
}

const releaseStaffErrorMessages = {
    'unknown': defineMessage({
        id: 'staff:release:generalError',
        defaultMessage: 'An unknown error occured',
        description: 'An unknown error occured'
    }),
    'INSUFFICIENT_TRANSFER_BUDGET': defineMessage({
        id: 'staff:release:insufficientBudget',
        defaultMessage: 'You cannot release this staff member because you cannot afford his compensation',
        description: 'Error message shown when a user tries to release a staff but they cannot afford the compensation due'
    }),
    'MODIFY_RESOURCE_PERMISSION_DENIED': defineMessage({
        id: 'staff:release:insufficientBudget',
        defaultMessage: 'You don\'t have permission to do that',
        description: 'Error message shown when a user tries to release a staff but they do not have permission'
    })
}
import { Redirect, generatePath } from 'react-router';
import {useTutorial} from '../hooks/useTutorial';

import tutorialData from '../tutorials/fixtures';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Loader } from 'semantic-ui-react';

import useUser from '../../common/useUser';
import useGetTeamLS from '../../common/data/useGetTeamLS';
import useGetTeamFixtures from '../../club/components/fixtures/useGetTeamFixtures';

export default function FixturesTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    const userQuery = useUser(!cachedTutorialData);
    const teamLSQuery1 = useGetTeamLS(userQuery.club?.id, 'first', {enabled: !cachedTutorialData && userQuery.isSuccess});
    const teamFixturesQuery1 = useGetTeamFixtures(userQuery.club?.id, 'first', teamLSQuery1.data?.data?.response.TLS[0].LS.season.id, !cachedTutorialData && teamLSQuery1.isSuccess);

    let nextFixture, opponentClubId;

    if(teamFixturesQuery1.isSuccess) {
        nextFixture = teamFixturesQuery1.data?.data?.response.find((fixture) => fixture.status === 'planned') || null;

        if(!!nextFixture) {
            opponentClubId = nextFixture.homeTeam.club.id === userQuery.club.id ? nextFixture.awayTeam.club.id : nextFixture.homeTeam.club.id;
        }
    }

    const teamLSQuery2 = useGetTeamLS(opponentClubId, 'first', {enabled: !cachedTutorialData && !!opponentClubId});
    const teamFixturesQuery2 = useGetTeamFixtures(opponentClubId, 'first', teamLSQuery2.data?.data?.response.TLS[0].LS.season.id, !cachedTutorialData && teamLSQuery2.isSuccess);

    useEffect(() => {
        if(
            !cachedTutorialData &&
            userQuery.isSuccess &&
            teamFixturesQuery1.isSuccess &&
            (
                nextFixture === null ||
                teamFixturesQuery2.isSuccess
            )
        ) {
            var compiledTutorialData = cloneDeep(tutorialData);

            if(nextFixture === null) {
                //delete all but first and last step since no fixtures remaining
                compiledTutorialData.steps.splice(1, 6);
            } else {
                var oppositionCompleteFixture = teamFixturesQuery2.data.data.response.reverse().find((fixture) => fixture.status === 'complete');

                if(!oppositionCompleteFixture) {
                    //delete steps 4-6 if opponent does not have any results
                    compiledTutorialData.steps.splice(3, 4);
                }
            }

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                    case 7:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: userQuery.club.id, teamType: 'first'})
                        }
                        break;
                    case 2:
                    case 3:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: opponentClubId, teamType: 'first'})
                        }
                        break;
                    case 4:
                        step.path = {
                            pathname: generatePath(step.path, {clubId: opponentClubId, teamType: 'first'})
                        }
                        break;
                    case 5:
                        step.path = {
                            pathname: generatePath(step.path, {fixtureId: oppositionCompleteFixture.id}),
                            state: {
                                view: 'stats'
                            }
                        }
                        break;
                    case 6:
                        step.path = {
                            pathname: generatePath(step.path, {fixtureId: oppositionCompleteFixture.id}),
                            state: {
                                view: 'lineups'
                            }
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData, userQuery.isSuccess, teamFixturesQuery1.isSuccess, teamFixturesQuery2.isSuccess]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
import {useState, useRef, useEffect} from 'react';
import {FormattedMessage, defineMessages} from 'react-intl';
import {Form, Button, Message} from 'semantic-ui-react';
import useBodyClass from '../../../useBodyClass';
import ContractLengthOptions from './contractLengthOptions';
import {CurrencyInput, salaryDecrementer, salaryIncrementer} from '../../../common/components/CurrencyInput';

export default function ContractOfferForm(props) {
    const [contractLength, setContractLength] = useState(null);
    const salaryInputEl = useRef(null);
    useBodyClass('noSelect');

    useEffect(() => {
        if(contractLength === null && !!props.contractDemands?.adjustedEndSeasonDemand) {
            setContractLength(props.contractDemands.adjustedEndSeasonDemand.id);
        }
    }, [contractLength, props.contractDemands]);

    return(
        <>
            <Form
                isSubmitting={props.isSubmitting}
                onSubmit={handleSubmit}
            >
                <Form.Field>
                    <label><FormattedMessage {...messages.wage} /></label>
                    <CurrencyInput
                        currencyInputEl={salaryInputEl}
                        initialValue={props.contractDemands?.adjustedSalaryDemand}
                        isLoading={props.isLoading}
                        getIncrement={salaryIncrementer}
                        getDecrement={salaryDecrementer}
                    />
                </Form.Field>
                <ContractLengthOptions
                    selectedContractLength={contractLength}
                    setSelectedContractLength={setContractLength}
                    noContractShorterThan={props.isMyPlayer ? props.existingContractExpiry : props.currentSeason?.endDate}
                    isLoading={props.isLoading}
                />
                <Button
                    positive
                    fluid
                    disabled={props.isLoading}
                    type='submit'
                >
                    <FormattedMessage {...messages.submitNegotiationButton} />
                </Button>
            </Form>
            {props.submitOfferError &&
                <Message negative>
                    <FormattedMessage
                        {...(submitOfferErrorMessages[props.submitOfferError.response?.key] || submitOfferErrorMessages['unknown'])}
                        values={{key: props.submitOfferError.response?.key || '-1'}}
                    />
                </Message>
            }
        </>
    );

    function handleSubmit() {
        props.handleSubmit(salaryInputEl.current.getAttribute('rawvalue'), contractLength);
    }
}

const submitOfferErrorMessages = defineMessages({
    'unknown': {
        id: 'player:transferOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'CONTRACT_VALUE_LOW': {
        id: 'player:transferOffer:create:error:contractValueLow',
        defaultMessage: 'The player has rejected your contract offer. Please try again with a better contract offer.'
    },
    'CONTRACT_VALUE_HIGH': {
        id: 'player:transferOffer:create:error:contractValueHigh',
        defaultMessage: 'The board feel this contract offer is too generous. Please try again with a lower contract offer.'
    },
    'LENGTH_MAX_CONSTRAINT': {
        id: 'player:transferOffer:create:error:maxContractLengthExceeded',
        defaultMessage: 'The contract you have offered is too long. Please try again with a shorter offer.'
    },
    'INVALID_COMPENSATION': {
        id: 'player:transferOffer:create:error:compensation',
        defaultMessage: 'The bid value is invalid. Please try again.'
    },
    'REPUTATION_MISMATCH': {
        id: 'player:transferOffer:create:error:reputationAbs',
        defaultMessage: 'This player is not interested in renewing right now',
    }
})

const messages = {
    wage: {
        id: "ContractNegotiation.wage",
        defaultMessage: "Wage",
        description: "Salary of the player/staff"
    },
    submitNegotiationButton: {
        id: "ContractNegotiation.submitNegotiationButton",
        defaultMessage: "Submit offer",
        description: "Text for the button which submits the contract offer"
    }
}
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {default as RDTC} from 'react-data-table-component';
import { Placeholder } from 'semantic-ui-react';
import { SUIStyles } from './dataTableStyles';

import DataTableExpander from './DataTableExpander';

export default function DataTable(props) {
    if(props.isLoading) {
        return (
            <RDTC
                columns={props.columns.map((column) => {
                    return {
                        ...column,
                        cell: () => 
                            <Placeholder style={{width: '100%', margin: '5px 0'}}>
                                <Placeholder.Header>
                                    <Placeholder.Line length={column.grow > 2 ? 'long' : 'medium'} />
                                </Placeholder.Header>
                            </Placeholder>,
                        selector: undefined,
                        conditionalCellStyles: []
                    }
                })}
                data={Array.from(Array(props.placeholderCount).keys()).map((i) => ({id: i}))}
                responsive={false}
                noHeader={true}
                customStyles={SUIStyles}
                key={'loading'}
            />
        )
    } else {
        return(
            <RDTC
                columns={props.columns}
                data={props.data}
                responsive={false}
                noHeader={true}
                customStyles={SUIStyles}
                expandableRows={props.isLoading ? undefined : props.expandableRows}
                expandableRowsComponent={props.expandableRowsComponent ? props.expandableRowsComponent : DataTableExpander}
                expandableRowsComponentProps={props.expandableRowsComponentProps ? props.expandableRowsComponentProps : {columns: props.columns}}
                expandableIcon={{
                    collapsed: <FontAwesomeIcon icon={faPlus} color='#192D4D' />,
                    expanded: <FontAwesomeIcon icon={faMinus} color='#192D4D' />
                }}
                conditionalRowStyles={props.conditionalRowStyles}
                noDataComponent={props.noDataComponent}
                defaultSortFieldId={props.defaultSortFieldId}
                defaultSortAsc={props.defaultSortAsc !== undefined ? props.defaultSortAsc : true}
                keyField={props.keyField}
                onSort={props.onSort}
                sortIcon={() => null}
                dense={true}
                striped={true}
                onRowClicked={props.onRowClicked}
                key={'data'}
            />
        );
    }
}
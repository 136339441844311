function statColumn(
    id,
    resolver,
    icon,
    hide,
    isPercentage = false
) {
    return {
        id,
        name: icon,
        selector: (playerReg) => <span>{typeof resolver(playerReg) === 'number' ? resolver(playerReg) : '-'}{isPercentage === true ? '%' : ''}</span>,
        sortable: true,
        sortFunction: sorter,
        wrap: false,
        allowOverflow: false,
        compact: true,
        center: true,
        minWidth: '30px',
        maxWidth: '50px',
        hide,
        grow: 1
    }

    function sorter(a, b) {
        a = resolver(a);
        b = resolver(b);

        return b-a;
    }
}

export {
    statColumn
}
import { useQuery } from "@tanstack/react-query";
import DataTable from "../../../../common/components/DataTable";
import { useEffect, useState } from "react";
import {currentAbility} from '../../../../common/dataTableColumns/currentAbility';
import {playerPosition} from '../../../../common/dataTableColumns/player/playerPosition';
import { playerName } from "../../../../common/dataTableColumns/player/playerName";
import { clubName } from "../../../../common/dataTableColumns/club/clubName";
import { currency } from "../../../../common/dataTableColumns/currency";
import { date } from "../../../../common/dataTableColumns/date";
import { fetchRecords } from "./lib";
import WorldNationsToolbarItem from "../../toolbar/WorldNationsToolbarItem";
import WorldClubsToolbarItem from "../../toolbar/WorldClubsToolbarItem";
import WorldCupsToolbarItem from "../../toolbar/WorldCupsToolbarItem";
import WorldRecordsToolbarItem from "../../toolbar/WorldRecordsToolbarItem";
import MediumLayout from "../../../../common/components/MediumLayout";
import RecordFilter from "../filter";

export default function PlayerTransferFeeRecords(props) {
    const [timePeriod, setTimePeriod] = useState('allTime');
    const [nationGroupShortcode, setNationGroupShortcode] = useState(null);

    useEffect(() => {
        props.setToolbar({
            title: 'Player transfer records',
            navigation: [
                <WorldNationsToolbarItem key={1} />,
                <WorldClubsToolbarItem key={2} />,
                <WorldCupsToolbarItem key={4} />,
                <WorldRecordsToolbarItem key={3} />
            ]
        });
    }, []);

    const transferFeeRecordsQuery = useQuery(
        ['getPlayerTransferFeeRecords', timePeriod, nationGroupShortcode],
        () => fetchRecords('transferFee', timePeriod, nationGroupShortcode)
    );

    return(
        <MediumLayout>
            <RecordFilter
                validTimePeriodFilters={['allTime', 'anySeason', 'seasonId']}
                nationFilter={true}
                selectedTimePeriod={timePeriod}
                handleTimePeriodChange={setTimePeriod}
                selectedNationGroupShortcode={nationGroupShortcode}
                handleNationGroupShortcodeChange={setNationGroupShortcode}
            />
            {timePeriod === 'allTime' ?
                <AllTimeTransferFeeRecordsInterface
                    isLoading={transferFeeRecordsQuery.isLoading}
                    records={transferFeeRecordsQuery.data?.data?.response}
                />
                :
                <SeasonTransferFeeRecordsInterface
                    isLoading={transferFeeRecordsQuery.isLoading}
                    records={transferFeeRecordsQuery.data?.data?.response}
                    timePeriod={timePeriod}
                />
            }
        </MediumLayout>
    );
}

function AllTimeTransferFeeRecordsInterface(props) {
    const columns = [
        playerName(
            (playerTransferHistory) => playerTransferHistory.player,
            (player) => false,
            (player) => false,
            undefined,
            undefined,
            undefined,
            undefined
        ),
        playerPosition((playerTransferHistory) => playerTransferHistory.player.position),
        currentAbility((playerTransferHistory) => playerTransferHistory.player.currentAbility),
        clubName(
            (playerTransferHistory) => playerTransferHistory.club,
            350
        ),
        date(
            'transferDate',
            (playerTransferHistory) => playerTransferHistory.createdAt,
            false,
            500
        ),
        currency(
            'transferFee',
            (playerTransferHistory) => playerTransferHistory.compensation,
            undefined,
            false
        )
    ];

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    )
}

function SeasonTransferFeeRecordsInterface(props) {
    const columns = [
        playerName(
            (playerTransferHistory) => playerTransferHistory.player,
            (player) => false,
            (player) => false,
            undefined,
            undefined,
            undefined,
            undefined
        ),
        playerPosition((playerTransferHistory) => playerTransferHistory.player.position),
        currentAbility((playerTransferHistory) => playerTransferHistory.player.currentAbility),
        clubName(
            (playerTransferHistory) => playerTransferHistory.club,
            350
        ),
        date(
            'transferDate',
            (playerTransferHistory) => playerTransferHistory.createdAt,
            false,
            500,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            props.timePeriod !== 'allTime' && props.timePeriod !== 'anySeason'
        ),
        currency(
            'transferFee',
            (playerTransferHistory) => playerTransferHistory.compensation,
            undefined,
            false
        )
    ];

    return(
        <DataTable
            columns={columns}
            data={props.records}
            isLoading={props.isLoading}
            placeholderCount={10}
        />
    )
}
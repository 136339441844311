import axios from "axios";
import { useQuery } from '@tanstack/react-query';
import { Loader } from "semantic-ui-react";
import CreatePlayerTransferOffer from "./create/data";
import ViewPlayerTransferOffer from "./view/data";

export default function PlayerTransferOffer(props) {
    const getPlayerTransferOfferQuery = useQuery(
        ['getPlayerPlayerTransferOffer', props.player?.playerContract.id],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/playerContracts/${props.player?.playerContract.id}/playerTransferOfferPaid`),
        {
            enabled: props.isLoading === false
        }
    );
    
    if(props.isLoading || getPlayerTransferOfferQuery.isLoading) {
        return(
            <Loader active />
        );
    } else {
        if(getPlayerTransferOfferQuery.data?.data?.response.length !== 0) {
            return(
                <ViewPlayerTransferOffer
                    playerTransferOfferId={getPlayerTransferOfferQuery.data?.data?.response[0].id}
                    manager={props.manager}
                />
            );
        } else {
            return(
                <CreatePlayerTransferOffer
                    player={props.player}
                />
            );
        }
    }
}
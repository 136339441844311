import { useEffect } from "react";
import { useTutorial } from "../hooks/useTutorial";
import { cloneDeep } from "lodash";
import { Redirect } from "react-router";
import { Loader } from "semantic-ui-react";

import {default as tutorialData} from '../tutorials/news';

export default function NewsTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    useEffect(() => {
        if(!cachedTutorialData) {
            var compiledTutorialData = cloneDeep(tutorialData);

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                        step.path = {
                            pathname: step.path
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
import { faDumbbell } from '@fortawesome/free-solid-svg-icons';
import {URL} from '../../common/lib/paths';

export default {
    key: 'training',
    name: 'Training',
    loaderPath: URL.trainingTutorial,
    icon: faDumbbell,
    description: 'Find out how to train your players to their full potential',
    steps: [
        {
            key: 0,
            path: URL.teamTraining,
            description: 'Welcome to training boss. First look at your player\'s name and go to the far right of the column with the + symbol above it. If it is a number with a green arrow they are growing.... if its a red arrow they are in decline',
            summary: 'Check training growth',
            task: 'browse'
        },
        {
            key: 1,
            path: URL.teamTraining,
            description: 'Player growth will be determined by the level of your coaching staff for your first team and your youth staff for your youth team. However everyday you can select 1 player for the first team and 1-2 from the youth team to give a training boost to. If you win/tie youth games you get points towards earning your second training boost. The higher youth league you are in, the faster base level growth of your youth squad. It pays to not sleep on your youth academy.',
            task: 'info'
        },
        {
            key: 2,
            path: URL.teamTraining,
            description: 'Select the "change player" button.',
            summary: 'Open boost player',
            task: 'interaction'
        },
        {
            key: 3,
            path: URL.teamTraining,
            description: 'Choose a player you wish to train and then select "select player". After the tutorial is over please check your player profiles to see who has the most room to grow in terms of their current ratings and their potential ratings to make the most out of this feature.',
            summary: 'Select a player',
            task: 'interaction'
        },
        {
            key: 4,
            path: URL.teamTraining,
            description: 'If you want to do the same with your youth team it\'s simple. Select the little dude icon next to the big dude icon',
            summary: 'Switch to youth team',
            task: 'interaction'
        },
        {
            key: 5,
            path: URL.teamTraining,
            description: 'Well done! You have completed the tutorial',
        }
    ]
}
import { Redirect, generatePath } from 'react-router';
import {useTutorial} from '../hooks/useTutorial';

import tutorialData from '../tutorials/training';
import { useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Loader } from 'semantic-ui-react';

export default function TrainingTutorial(props) {
    const {getCached, setCached, getSearchParamsString} = useTutorial();

    const cachedTutorialData = getCached(tutorialData.key, false);

    useEffect(() => {
        if(!cachedTutorialData) {
            var compiledTutorialData = cloneDeep(tutorialData);

            for(const step of compiledTutorialData.steps) {
                switch(step.key) {
                    case 0:
                    case 1:
                    case 2:
                    case 4:
                        step.path = {
                            pathname: generatePath(step.path, {teamType: 'first'}),
                            state: {
                                boostModal: false,
                            }
                        }
                        break;
                    case 3:
                        step.path = {
                            pathname: generatePath(step.path, {teamType: 'first'}),
                            state: {
                                boostModal: true
                            }
                        }
                        break;
                    case 5:
                        step.path = {
                            pathname: generatePath(step.path, {teamType: 'youth'}),
                            state: {
                                boostModal: false,
                            }
                        }
                        break;
                }
            }

            setCached(tutorialData.key, compiledTutorialData);
        }
    }, [cachedTutorialData]);

    if(!!cachedTutorialData) {
        return(
            <Redirect
                to={{
                    pathname: cachedTutorialData.steps[0].path.pathname,
                    state: cachedTutorialData.steps[0].path.state,
                    search: getSearchParamsString(cachedTutorialData.key, 0)
                }}
            />
        )
    } else {
        return(
            <Loader active />
        )
    }
}
//last verified against STAGE SIX spreadsheet 26/10/21

export default {
    positionMap: [
        'GK',
        'CB',
        'CB',
        'CB',
        'CDM',
        'CDM',
        'CM',
        'ST',
        'ST',
        'ST',
        'RW'
    ],
    pitchHalfMap: [
        12,
        7,
        13,
        19,
        8,
        20,
        15,
        11,
        17,
        23,
        28
    ]
}
import { defineMessages } from "@formatjs/intl";
import { compileMessage } from "./util.js";

const messages = {
    'player:injured:fixture': defineMessages({
        subject: {
            id: "messageSubject:player:injured:fixture",
            defaultMessage: "{playerName} injured",
            description: "Subject for the system message which a user receives when their player was injured in the last match"
        },
        body: {
            id: "messageBody:player:injured:fixture",
            defaultMessage: "I'm sorry to report that {playerName} will be out of action for {injuryDuration} day(s) after picking up an injury in the last match.\n\nHe won't be available for selection until {returnDate}.",
            description: "Message body for the system message which a user receives when their player was injured in the last match"
        }
    }),
    'player:injured:training': defineMessages({
        subject: {
            id: "messageSubject:player:injured:training",
            defaultMessage: "{playerName} injured",
            description: "Subject for the system message which a user receives when their player was injured in training"
        },
        body: {
            id: "messageBody:player:injured:training",
            defaultMessage: "I'm sorry to report that {playerName} will be out of action for {injuryDuration} day(s) after picking up an injury in training.\n\nHe won't be available for selection until {returnDate}.",
            description: "Message body for the system message which a user receives when their player was injured in training"
        }
    }),
    'player:injury:recovered': defineMessages({
        subject: {
            id: "messageSubject:player:injury:recovered",
            defaultMessage: "{playerName} recovered from injury",
            description: "Subject for the system message which a user receives when their player has recovered from their injury and is available for selection again"
        },
        body: {
            id: "messageBody:player:injury:recovered",
            defaultMessage: "I am pleased to report that {playerName} has recovered from injury and is available for selection again.",
            description: "Message body for the system message which a user receives when their player has recovered from their injury and is available for selection again"
        }
    }),
    'player:retiring': defineMessages({
        subject: {
            id: "messageSubject:player:retiring",
            defaultMessage: "I've decided to retire",
            description: "Subject for the system message which a user receives when their player announces his retirement"
        },
        body: {
            id: "messageBody:player:retiring",
            defaultMessage: "After much thought I have decided that, at the age of {age}, this season will be my last and I will retire at the end of this season.\n\nThank you for all the opportunities and good luck for the future.",
            description: "Message body for the system message which a user receives when their player announces his retirement"
        }
    }),
    'player:retired': defineMessages({
        subject: {
            id: "messageSubject:player:retired",
            defaultMessage: "{playerName} retires",
            description: "Subject for the system message which a user receives when their player announces his retirement"
        },
        body: {
            id: "messageBody:player:retired",
            defaultMessage: "After a long career, {playerName} has today retired at the age of {age}.",
            description: "Message body for the system message which a user receives when their player announces his retirement"
        }
    }),
    'favPlayer:retired': defineMessages({
        subject: {
            id: "messageSubject:favPlayer:retired",
            defaultMessage: "{playerName} retires",
            description: "Subject for the system message which a user receives when their player announces his retirement"
        },
        body: {
            id: "messageBody:favPlayer:retired",
            defaultMessage: "After a long career, {playerName}, who is on your favourites, has today retired at the age of {age}.",
            description: "Message body for the system message which a user receives when their player announces his retirement"
        }
    }),
    'player:contractExpired': defineMessages({
        subject: {
            id: "messageSubject:player:contractExpired",
            defaultMessage: "{playerName} contract expired",
            description: "Subject for the system message which a user receives when a player's contract has expired and they get released from the club"
        },
        body: {
            id: "messageBody:player:contractExpired",
            defaultMessage: "{playerName}'s contract has expired and they have now left the club.",
            description: "Message body for the system message which a user receives when a player's contract has expired and they get released from the club"
        }
    }),
    'player:suspended': defineMessages({
        subject: {
            id: 'messageSubject:player:suspended',
            defaultMessage: '{playerName} suspended',
            description: 'Subject for the system message which a user receives when one of their players has been suspended'
        },
        body: {
            id: 'messageBody:player:suspended',
            defaultMessage: '{playerName} has been suspended for 1 game after receiving a red card in the last match',
            description: 'Message bdoy for the system message which a user receives when one of their players has been suspended'
        }
    }),
    'player:unsuspended': defineMessages({
        subject: {
            id: 'messageSubject:player:unsuspended',
            defaultMessage: '{playerName} available',
            description: 'Subject for the system message which a user receives when one of their players has been unsuspended'
        },
        body: {
            id: 'messageBody:player:unsuspended',
            defaultMessage: '{playerName} is available for selection again after completing his suspension.',
            description: 'Message bdoy for the system message which a user receives when one of their players has been unsuspended'
        }
    }),
    'player:retrainedPosition': defineMessages({
        subject: {
            id: 'messageSubject:player:retrainedPosition',
            defaultMessage: '{playerName} new position',
            description: 'Subject for the system message which a user receives when one of their players has changed their position'
        },
        body: {
            id: 'messageBody:player:retrainedPosition',
            defaultMessage: '{playerName} has completed his position retraining from a {originalPosition} and he is now fully effective as a {newPosition}.',
            description: 'Message bdoy for the system message which a user receives when one of their players has changed their position'
        }
    }),
    'player:nonOptimalTraining': defineMessages({
        subject: {
            id: 'messageSubject:player:nonOptimalTraining',
            defaultMessage: '{playerName} training report',
            description: 'Subject for the system message which a user receives when one of their players is not developing in training'
        },
        body: {
            id: 'messageBody:player:nonOptimalTraining',
            defaultMessage: 'We have been monitoring {playerName} in training this morning and he doesn\'t seem to be responding to his training focus. You should try changing it to see if that helps.',
            description: 'Message bdoy for the system message which a user receives when one of their players is not developing in training'
        }
    }),
    'player:joinedClub:loan': defineMessages(compileMessage({
        subject: {
            id: 'player:joinedClub:loan',
            defaultMessage: "{playerName} joins {toClubName} on loan",
            description: "Subject for the system message template when a user joins a club on loan"
        },
        body: {
            id: 'player:joinedClub:loan',
            defaultMessage: "{toClubName} have today announced that they have taken {playerName} on loan from {fromClubName} until the end of the season.",
            description: "Body for the system message template when a user joins a club on loan"
        }
    })),
    'player:joinedClub:permanent:free': defineMessages(compileMessage({
        subject: {
            id: 'player:joinedClub:permanent:free',
            defaultMessage: "{playerName} joins {toClubName}",
            description: "Subject for the system message template when a user joins a club on a free transfer"
        },
        body: {
            id: 'player:joinedClub:permanent:free',
            defaultMessage: "{toClubName} have today announced that they have signed {playerName} on a free transfer.\n\nHe has signed a {contractSalary} per season contract until {contractEndDate}.",
            description: "Body for the system message template when a user joins a club on a free transfer"
        }
    })),
    'player:joinedClub:permanent:paid': defineMessages(compileMessage({
        subject: {
            id: 'player:joinedClub:permanent:paid',
            defaultMessage: "{playerName} joins {toClubName}",
            description: "Subject for the system message template when a user joins a club on a paid transfer"
        },
        body: {
            id: 'player:joinedClub:permanent:paid',
            defaultMessage: "{toClubName} have today announced that they have signed {playerName} from {fromClubName} for {fee}.\n\nHe has signed a {contractSalary} per season contract until {contractEndDate}.",
            description: "Body for the system message template when a user joins a club on a paid transfer"
        }
    })),
    'player:loan:returnToParentClub': defineMessages(compileMessage({
        subject: {
            id: 'player:loan:returnToParentClub',
            defaultMessage: "{playerName} returns to {contractedClubName}",
            description: "Subject for the system message template when a loaned player returns to parent club"
        },
        body: {
            id: 'player:loan:returnToParentClub',
            defaultMessage: "Following his loan spell at {loanClubName}, {playerName} has today rejoined his contracted club {contractedClubName}",
            description: "Body for the system message template when a loaned player returns to parent club"
        }
    })),
    'player:renewedContract': defineMessages(compileMessage({
        subject: {
            id: 'player:renewedContract',
            defaultMessage: "{playerName} renews with {clubName}",
            description: 'Subject for the message template which a user receives when a player has renewed a contract with their existing club'
        },
        body: {
            id: 'player:renewedContract',
            defaultMessage: '{playerName} has today signed a new contract with his existing club {clubName}, worth {contractSalary} per season.\n\nHis new contract will run until {contractEndDate}.',
            description: 'Message body for the message template which a user receives when a player accepts a transfer to another club'
        }
    })),
}

export default messages;
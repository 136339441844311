import { useEffect, useState } from 'react';
import {useQuery, useMutation} from '@tanstack/react-query';
import NarrowLayout from '../../common/components/NarrowLayout';
import RegisterInterface from './interface';

export default function Register(props) {
    const [registerError, setRegisterError] = useState(null);
    const [registerSuccess, setRegisterSuccess] = useState(false);

    const handleRegisterSubmit = (values, actions) => {
        createUserMutation.mutate({
            email: values.email,
            password: values.password,
            name: values.username,
            nationShortcode: values.nationality,
            emailConsent: values.emailConsent
        }, {
            onSettled: () => {
                actions.setSubmitting(false);
            }
        });
    }

    const createUserMutation = useMutation(newUser =>
        fetch(
            `${process.env.REACT_APP_APPHOST}/users`,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newUser)
            }
        ).then((res) => res.json()),
        {
            onSuccess: data => {
                if(!data.success) {
                    switch(data.response.reason) {
                        case 'email must be unique':
                            setRegisterError('email');
                            break;
                        case 'name must be unique':
                            setRegisterError('name');
                            break;
                        default:
                            setRegisterError('unknown');
                            break;
                    }
                } else {
                    setRegisterSuccess(true);
                }
            }
        }
    );

    const {isLoading, data} = useQuery(
        ['getNations'],
        () => fetch(`${process.env.REACT_APP_APPHOST}/nations`).then((res) => res.json())
    );

    useEffect(() => {
        props.setToolbar({title: 'Register', navigation: null});
    }, []);

    return(
        <NarrowLayout>
            <RegisterInterface
                isLoading={isLoading}
                isSubmitting={createUserMutation.isLoading}
                isSuccess={registerSuccess}
                nations={data ? data.response : undefined}
                handleRegisterSubmit={handleRegisterSubmit.bind(this)}
                registerError={registerError}
            />
        </NarrowLayout>
    )
}
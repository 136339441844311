import {Link, generatePath} from "react-router-dom";
import {URL} from "../../common/lib/paths.js";
import TeamName from "./TeamName.js";

export default function TeamLink(props) {
    return(
        <span style={{width: '100%'}}>
            <Link
                style={props.highlight ? {fontWeight: 'bold'} : {}}
                to={{
                    pathname: generatePath(URL.teamPlayers, {clubId: props.team.club.id, teamType: props.team.type}),
                    search: props.search
                }}
                className={`${props.inline && 'noTextWrap'} ${props.className}`}
            >
                <TeamName
                    {...props}
                />
            </Link>
        </span>
    );
}
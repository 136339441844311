import { generatePath, useHistory, useParams } from "react-router";
import TacticsBuilderInterface from "./interface";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useEffect, useMemo } from "react";
import { URL } from "../../common/lib/paths";

export default function TacticsBuilder(props) {
    const {teamTacticId} = useParams();
    const queryClient = useQueryClient();
    const history = useHistory();

    const getTeamTacticQuery = useQuery(
        ['getTeamTactic', teamTacticId],
        () => axios.get(`${process.env.REACT_APP_APPHOST}/teamTactics/${teamTacticId}`)
    );
    
    const updateTeamTacticMutation = useMutation(
        (tactic) => axios.patch(`${process.env.REACT_APP_APPHOST}/teamTactics/${teamTacticId}/pitchSections`, {
            selectedSections: tactic.selectedSections
        }), {
            onSuccess: () => {
                queryClient.invalidateQueries('getTeamTacticQuery');
                history.push(generatePath(URL.teamTactics, {teamType: getTeamTacticQuery.data.data.response.team.type}), {manageTacticsModal: true});
            }
        }
    )

    const [selectedSections, tacticPositionIds] = useMemo(() => {
        if(!getTeamTacticQuery.data?.data.response.teamTacticPositions) return [null, null];

        const selectedSections = [];
        const tacticPositionIds = [];

        for(const position of getTeamTacticQuery.data?.data.response.teamTacticPositions) {
            if(position.pitchSection === null) continue;

            selectedSections.push(position.pitchSection);
            tacticPositionIds.push(position.id);
        }

        return [selectedSections, tacticPositionIds];
    }, [getTeamTacticQuery.data?.data.response.teamTacticPositions]);

    useEffect(() => {
        if(getTeamTacticQuery.data?.data.response) {
            props.setToolbar({
                title: `Edit tactic ${getTeamTacticQuery.data.data.response.name}`,
                navigation: []
            });
        }
    }, [getTeamTacticQuery.data?.data.response]);

    return(
        <TacticsBuilderInterface
            isLoading={getTeamTacticQuery.isLoading}
            teamTactic={getTeamTacticQuery.data?.data.response}
            selectedSections={selectedSections}
            tacticPositionIds={tacticPositionIds}
            updateTeamTacticMutation={updateTeamTacticMutation}
            updateTeamTacticMutationIsLoading={updateTeamTacticMutation.isLoading}
        />
    )
}
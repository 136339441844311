import {useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Button, Segment} from 'semantic-ui-react';
import SUIIconShim from '../../common/components/SUIIconShim';

import useBodyClass from '../../useBodyClass';
import PlayerProfile from "../profile/data";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight, faPlus, faTrashCan, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router';
import useCompare from './useCompare';
import PlayerSelector from '../../club/components/players/PlayerRegistrationSelector';
import { useTutorial } from '../../tutorial/hooks/useTutorial';

export default function CompareInterface(props) {
    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const {playerIds, removePlayerIndex, removeAll, addPlayerIds} = useCompare();
    const [selectorOpen, setSelectorOpen] = useState(false);
    const {checkTutorial, goNextStep} = useTutorial();
    useBodyClass('noMainContainerPadding');
    const location = useLocation();

    const isTutorial1 = checkTutorial('players', 11);

    function handleSelectPlayerRegistration(playerRegistrationId) {
        const playerReg = props.playerRegistrations.find((playerReg) => playerReg.id === playerRegistrationId);
        addPlayerIds([playerReg.playerContract.player.id]);
        setSelectorOpen(false);
    }

    function handleClick() {
        swiper.slideNext();
        
        if(isTutorial1 === true) {
            goNextStep();
        }
    }

    return(
        <div>
            <div style={styles.wrapper}>
                <div style={styles.menu}>
                    {playerIds.length > 0 &&
                        <>
                            <Button.Group>
                                <Button
                                    icon
                                    disabled={location.state?.page === 1}
                                    onClick={() => swiper.slidePrev()}
                                    color='black'
                                >
                                    <SUIIconShim>
                                        <FontAwesomeIcon icon={faArrowLeft} />
                                    </SUIIconShim>
                                </Button>
                                <Button disabled className="opacity-override" color='black'>
                                    {activeIndex+1}/{playerIds.length}
                                </Button>
                                <Button
                                    icon
                                    disabled={false}
                                    onClick={handleClick.bind(this)}
                                    color='black'
                                    className={isTutorial1 && 'glow'}
                                >
                                    <SUIIconShim>
                                        <FontAwesomeIcon icon={faArrowRight} />
                                    </SUIIconShim>
                                </Button>
                            </Button.Group>
                            <span style={{width: '1em'}}>&nbsp;</span>
                        </>
                    }
                    <Button.Group style={{display: 'inline-block', marginLeft: '0.5em'}}>
                        <Button
                            icon
                            color='black'
                            onClick={() => removePlayerIndex(activeIndex)}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faMinus} />
                            </SUIIconShim>
                        </Button>
                        <Button
                            icon
                            color='black'
                            loading={props.isLoading}
                            disabled={props.isLoading}
                            onClick={() => setSelectorOpen(true)}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faPlus} />
                            </SUIIconShim>
                        </Button>
                        <Button
                            icon
                            color='black'
                            onClick={() => removeAll()}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faTrashCan} />
                            </SUIIconShim>
                        </Button>
                    </Button.Group>
                </div>
                {playerIds.length === 0 ?
                    <div style={styles.empty}>
                        <Segment>No players have been added to compare</Segment>
                    </div>
                    :
                    <div style={styles.slider} className='sliderContainer'>
                        <Swiper
                            slidesPerView='auto'
                            centeredSlides={true}
                            centeredSlidesBounds={true}
                            onSwiper={setSwiper}
                            onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
                        >
                            {playerIds.map((playerId) => 
                                <SwiperSlide>
                                    <PlayerProfile
                                        playerId={playerId}
                                        manager={props.manager}
                                        setToolbar={false}
                                        style={styles.container}
                                    />
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                }
            </div>
            <PlayerSelector
                playerRegistrations={props.playerRegistrations}
                ownerClubFormation={props.currentFormation}
                open={selectorOpen}
                setOpen={setSelectorOpen}
                handleSelectPlayerRegistration={handleSelectPlayerRegistration}
            />
        </div>
    );
}

const styles = {
    container: {
        margin: '0 0.5em'
    },
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
    },
    menu: {
        flex: '0 1 auto',
        margin: '1em 1em 0 1em',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 'bold'
    },
    slider: {
        flex: '1 1 auto',
        overflow: 'auto'
    },
    nav: {
        flex: '1 0 auto',
        display: 'flex',
        justifyContent: 'center'
    },
    empty: {
        flex: '1 1 auto',
        padding: '0 1em 1em 1em'
    }
}
import { faFutbol, faSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cloneDeep } from "lodash";
import Sim from '2dmatchsim/sim.js';
import { Application, Assets, Sprite } from "2dmatchsim/node_modules/pixi.js";
import { useEffect, useReducer, useRef, useState } from "react";
import { Grid, Header, Segment, Image, Divider } from "semantic-ui-react";
import useBodyClass from '../../../useBodyClass';
import { CSSTransition } from "react-transition-group-react-18";

const events = {
    4: {
        type: 'yellowCard',
        time: 4,
        name: 'A. Higgins',
        team: 'away'
    },
    8: {
        type: 'goal',
        time: 8,
        team: 'home'
    },
    21: {
        type: 'goal',
        time: 21,
        team: 'home'
    },
    44: {
        type: 'goal',
        time: 44,
        team: 'home'
    },
    48: {
        type: 'yellowCard',
        time: 48,
        name: 'T. Matthews',
        team: 'away'
    },
    62: {
        type: 'goal',
        time: 62,
        team: 'home'
    },
    67: {
        type: 'yellowCard',
        time: 67,
        name: 'J. Simms',
        team: 'away'
    },
    88: {
        type: 'goal',
        time: 88,
        team: 'home'
    }
}

async function initApp(ref, cb) {
    window.addEventListener('resize', handleResize);
    // window.removeEventListener('resize', handleResize);

    setOrientationClasses();

    const app = new Application();

    await app.init({
        eventMode: 'none',
        interactiveChildren: false,
        background: '#268b07',
        canvas: ref.current,
        resizeTo: ref.current.parentElement
    });

    Assets.add({
        alias: 'pitch',
        src: '/pitch.png'
    });

    Assets.backgroundLoad(['pitch']);

    Assets.load('pitch').then((texture) => {
        createPitch(texture);
    });

    function handleGoal(goalScorerName) {
        cb({type: 'goal', team: 'home', name: goalScorerName});
        app.sim.reset();
    }

    app.sim = new Sim(app, handleGoal);

    return app;

    function getOrientation() {
        if(window.innerWidth >= window.innerHeight) {
            return 'landscape';
        } else {
            return 'portrait';
        }
    }

    function handleResize() {
        console.log('resize');

        app.resize();

        app.stage.removeChildAt(0);
        const texture = Assets.get('pitch');
        createPitch(texture);
        setOrientationClasses();
    }

    function setOrientationClasses() {
        const orientation = getOrientation();
        
        ref.current.parentElement.classList.remove('landscape');
        ref.current.parentElement.classList.remove('portrait');
        ref.current.parentElement.classList.add(orientation);
    }

    function createPitch(texture) {
        const pitch = new Sprite({texture, eventMode: 'none'});

        setPitchOrientation(texture)

        pitch.width = app.canvas.width;
        pitch.height = app.canvas.height;

        app.stage.addChildAt(pitch, 0);
    }

    function setPitchOrientation(texture) {
        const orientation = getOrientation();

        if(orientation === 'landscape') {
            texture.rotate = 0;
        } else {
            texture.rotate = 2;
        }
        texture.update();
    }
}

function reducer(state, event) {
    const newState = cloneDeep(state);

    switch(event.type) {
        case 'incrementTime':
            newState.time = newState.time + 1;
            break;
        case 'stopTime':
            newState.isRunning = false;
            break;
        case 'yellowCard':
            newState.events.push(event);
            break;
        case 'goal':
            if(newState.isRunning === true) {
                newState.isRunning = false;
                
            } else {
                newState.events.push(Object.assign(event, {time: newState.time}));
                newState.scoreHome = newState.scoreHome + 1;
                newState.isRunning = true;
            }
            break;
    }

    return newState;
}

const defaultState = {
    scoreHome: 0,
    scoreAway: 0,
    time: 1,
    isRunning: true,
    events: []
}

export default function Demo(props) {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [app, setApp] = useState(null);

    const containerSize = useContainerSize();

    useBodyClass('fullScreen');

    useEffect(() => {
        if(!!canvasRef && !app) {
            initApp(canvasRef, dispatch).then((a) => setApp(a));
        }
    }, [canvasRef]);

    useEffect(() => {
        let intervalId;
        if (state.isRunning) {
            intervalId = setInterval(() => dispatch({type: 'incrementTime'}), 200);
        } else {
            clearInterval(intervalId);
        }
        return () => clearInterval(intervalId);
    }, [state.isRunning]);

    useEffect(() => {
        if(state.time === 90) {
            dispatch({type: 'stopTime'});
        } else if(events[state.time] !== undefined) {
            dispatch({...events[state.time], canvasRef, dispatch});
        }
    }, [state.time]);

    useEffect(() => {
        if(!app?.sim) return;
        
        if(state.isRunning === false) {
            console.log('highlight start');
            console.log(app.sim);
            app.sim.start = true;
        } else {
            console.log('highlight stop');
            app.sim.start = false;
        }
    }, [state.isRunning]);

    const icons = {
        yellowCard: (
            <FontAwesomeIcon icon={faSquare} color='#FBBD08' />
        ),
        redCard: (
            <FontAwesomeIcon icon={faSquare} color='#DB2828' />
        ),
        goal: (
            <FontAwesomeIcon icon={faFutbol} />
        )
    };

    return(
        <div>
            <CSSTransition
                in={state.isRunning}
                timeout={300}
                classNames="simOverlay"
                mountOnEnter={true}
                unmountOnExit={true}
                appear={true}
            >
                <div>
                <Segment style={{zIndex: 100, maxWidth: '400px', margin: '0 auto'}} className='simOverlaySegment'>
                    <Grid style={{margin: 0}}>
                        <Grid.Row style={{padding: 0}}>
                            <Grid.Column width={6} style={{padding: 0}}>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/crests/1.png`}
                                    style={{
                                        filter: 'drop-shadow(1px 1px 3px #000)'
                                    }}
                                    size='tiny'
                                    centered
                                />
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='center' verticalAlign='middle' style={{padding: 0}}>
                                <Header as='h2'>
                                    {state.time}'
                                </Header>
                                <Header as='h1'>
                                    {state.scoreHome} - {state.scoreAway}
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={6} style={{padding: 0}}>
                                <Image
                                    src={`${process.env.PUBLIC_URL}/crests/2.png`}
                                    style={{
                                        filter: 'drop-shadow(1px 1px 3px #000)'
                                    }}
                                    size='tiny'
                                    centered
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row style={{padding: 0}}>
                            <Grid.Column width={6} textAlign='center' style={{padding: 0}}>
                                <Header as='h4'>London</Header>
                            </Grid.Column>
                            <Grid.Column width={4} textAlign='center'></Grid.Column>
                            <Grid.Column width={6} textAlign='center' style={{padding: 0}}>
                                <Header as='h4'>Reading</Header>
                            </Grid.Column>
                        </Grid.Row>
                        <Divider style={{marginBottom: 0}} />
                        {state.events.map((event) => (
                            <Grid.Row>
                                <Grid.Column width={7} textAlign='right' style={{paddingLeft: 0}}>
                                    {event.team === 'home' ? <p style={{margin: 0}}>{event.name} {event.time}'</p> : null}
                                </Grid.Column>
                                <Grid.Column width={2} style={{padding: 0}} textAlign='center'>
                                    {icons[event.type]}
                                </Grid.Column>
                                <Grid.Column width={7} textAlign='left' style={{paddingRight: 0}}>
                                    {event.team === 'away' ? <p style={{margin: 0}}>{event.name} {event.time}'</p> : null}
                                </Grid.Column>
                            </Grid.Row>
                        ))}
                    </Grid>
                </Segment>
                </div>
            </CSSTransition>
            <div className={state.isRunning ? 'ui dimmable dimmed sim2d' : 'ui dimmable sim2d' } style={containerSize}>
                <canvas ref={canvasRef}></canvas>
                <div className='ui simple dimmer'></div>
            </div>
        </div>
    );
}

function useContainerSize() {
    const [size, setSize] = useState(getSize());

    useEffect(() => {
        function handleResize() {
            setSize(getSize());
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return size;

    function getSize() {
        let containerWidth;
        let containerHeight;
    
        const windowAspectRatio = window.innerWidth / window.innerHeight;
    
        if(window.innerWidth >= window.innerHeight) {
            //landscape
            if(windowAspectRatio > 5/3) {
                //e.g. 600x300 (2:1) vs. 600x360 (5:3)
                //constrain to 100% Height such that
                // 500x300 (5:3)
                containerWidth = window.innerHeight * (5/3);
                containerHeight = window.innerHeight;
            } else if(windowAspectRatio <= 5/3) {
                //e.g. 600x450 (4:3) vs 600x360 (5:3)
                //constrain to 100% Width such that
                // 600x360 (5:3)
                containerWidth = window.innerWidth;
                containerHeight = window.innerWidth * (3/5);
            }
        } else {
            //portrait
            if(windowAspectRatio < 3/5) {
                //e.g. 300x600 (1:2) vs 360x600 (3:5)
                //constrain to 100% Width such that
                // 300x500 (3:5)
                containerWidth = window.innerWidth;
                containerHeight = window.innerWidth * (5/3);
            } else if(windowAspectRatio >= 3/5) {
                //e.g. 450x600 (3:4) vs 360x600 (3:5)
                //constrain to 100% Height such that
                // 360x600 (3:5)
                containerWidth = window.innerHeight * (3/5);
                containerHeight = window.innerHeight;
            }
        }

        return {width: containerWidth, height: containerHeight};
    }
}
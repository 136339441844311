import { generatePath, Link } from 'react-router-dom';
import {Header, Segment, Table, Placeholder} from 'semantic-ui-react';

import ClubLink from "../../club/components/common/Link";
import { URL } from '../../common/lib/paths';
import FullNationNameWithFlag from "../../nation/common/FullNationNameWithFlag";
import {useTutorial} from '../../tutorial/hooks/useTutorial';

export default function CityProfileInterface(props) {
    const {checkTutorial, getSearchParamsString, tutorialStep} = useTutorial();

    const isTutorial1 = checkTutorial('clubs', 6);

    return(
        <div>
            <Header as='h5' attached='top' inverted className='attachedHeader'>
                General information
            </Header>
            <Segment attached className='attachedInvertedSegment profileSegmentFix2'>
                <Table
                    basic='very'
                    compact='very'
                    unstackable
                    style={{width: '100%'}}
                >
                    <Table.Body>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>Name</Table.Cell>
                            <Table.Cell width={8}>
                                {props.isLoading ?
                                    <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                    :
                                    props.city.name
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>Club</Table.Cell>
                            <Table.Cell width={8}>
                                {props.isLoading ?
                                    <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                    :
                                    <ClubLink club={{id: props.city.club.id, name: props.city.name, nation: {nationGroupShortcode: props.city.nation.nationGroupShortcode}}} />
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>Nation</Table.Cell>
                            <Table.Cell width={8}>
                                {props.isLoading ?
                                    <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                    :
                                    <FullNationNameWithFlag nation={props.city.nation} />
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>Total players</Table.Cell>
                            <Table.Cell width={8}>
                                {props.isLoading ?
                                    <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                    :
                                    <Link
                                        to={{
                                            pathname: generatePath(URL.cityPlayers, {cityId: props.city.id}),
                                            state: {filterRetired: false},
                                            search: isTutorial1 && getSearchParamsString('clubs', tutorialStep+1)
                                        }}
                                        className={isTutorial1 && 'glow'}
                                    >
                                        {props.city.totalPlayerCount} players
                                    </Link>
                                }
                            </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell style={{fontWeight: 'bold'}} width={6}>Active players</Table.Cell>
                            <Table.Cell width={8}>
                                {props.isLoading ?
                                    <Placeholder style={{width: '60px'}}><Placeholder.Line length='full' style={{height: 'unset'}} /></Placeholder>
                                    :
                                    <Link to={{
                                        pathname: generatePath(URL.cityPlayers, {cityId: props.city.id})
                                    }}>
                                        {props.city.activePlayerCount} players
                                    </Link>
                                }
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                </Table>
            </Segment>
        </div>
    )
}
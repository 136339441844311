import { Link, generatePath } from "react-router-dom";
import CupName from "../name/CupName";
import { URL } from "../../common/lib/paths";

export default function CupLink(props) {
    return(
        <Link
            to={generatePath(URL.cupFixtures, {cupId: props.cup.id})}
        >
            <CupName cup={props.cup} />
        </Link>
    )
}
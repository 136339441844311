const positionBgColors = {
    'GK': '#5E37C1',
    'CB': '#51B2AC',
    'LB': '#51B2AC',
    'RB': '#51B2AC',
    'LWB': '#51B2AC',
    'RWB': '#51B2AC',
    'CDM': '#42B3CA',
    'CM': '#42B3CA',
    'LM': '#42B3CA',
    'RM': '#42B3CA',
    'CAM': '#42B3CA',
    'LW': '#4283CA',
    'RW': '#4283CA',
    'ST': '#4283CA'
}

const wrapper = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px'
}

function getStyle(position, standalone = false) {
    let style = {
        backgroundColor: positionBgColors[position],
        fontWeight: '700',
        color: '#FFF',
        fontSize: '12px'
    }

    if(standalone === true) {
        style.width = '38px';
        style.height = '100%';
        style.display = 'flex';
        style.alignItems = 'center';
        style.justifyContent = 'center';
        
    }

    return style;
}

function PositionColour(props) {
    return(
        <div style={wrapper}>
            <div style={getStyle(props.position, true)}>
                {props.position}
            </div>
        </div>
    )
}

export {getStyle, PositionColour}
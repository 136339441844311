import { Grid, Header, Loader, Message, Placeholder, Statistic } from "semantic-ui-react";
import TitledSegment from "../../../common/components/TitledSegment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircleExclamation, faExclamationCircle, faTimeline } from "@fortawesome/free-solid-svg-icons";
import colours from "../../../common/colours";
import ClubLink from "../../../club/components/common/Link";
import { FormattedDate } from "react-intl";
import Component_Currency from "../../../common/components/Currency";
import NarrowLayout from "../../../common/components/NarrowLayout";
import SUIIconShim from "../../../common/components/SUIIconShim";

export default function PlayerTransferAuctionPaidHistoryInterface(props) {
    if(props.isLoading) return(<Loader active />);

    return(
        <NarrowLayout>
            <TitledSegment
                title='Auction history'
                noPadding={true}
            >
                <Grid className='gridReset transferAuctionBidStatus'>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Statistic size='mini'>
                                <Statistic.Value>
                                    <Component_Currency value={props.winningBid.finalBid} />
                                </Statistic.Value>
                                <Statistic.Label>
                                    Winning bid
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic
                                size='mini'
                                style={{width: '100%'}}
                            >
                                <Statistic.Value>
                                    <ClubLink club={props.winningBid.club} />
                                </Statistic.Value>
                                <Statistic.Label>
                                    Winning club
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <span>
                                <FontAwesomeIcon icon={faCircleExclamation} color={colours.negative} /> This auction ended on <FormattedDate value={props.playerTransferAuctionPaidHistory.endDate} />
                            </span>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </TitledSegment>
            {props.myBid &&
                <TitledSegment
                    title='My bid'
                    noPadding={true}
                >
                    <Grid className='gridReset transferAuctionBid'>
                        <Grid.Row columns={1}>
                            <Grid.Column>
                                <Header icon>
                                    {props.myBid.isWinner === true ?
                                        <>
                                            <SUIIconShim>
                                                <FontAwesomeIcon icon={faCheckCircle} color={colours.positive} />
                                            </SUIIconShim>
                                                You won this auction
                                            <Header.Subheader>
                                                Final price: <Component_Currency value={props.myBid.finalBid} />
                                            </Header.Subheader>
                                        </>
                                        :
                                        <>
                                            <SUIIconShim>
                                                <FontAwesomeIcon icon={faExclamationCircle} color={colours.negative} />
                                            </SUIIconShim>
                                                You lost this auction
                                            <Header.Subheader>
                                                Your max bid: <Component_Currency value={props.myBid.finalBid} />
                                            </Header.Subheader>
                                        </>
                                    }
                                </Header>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </TitledSegment>
            }
            <TitledSegment
                title='Other offers'
                icon={faTimeline}
                isLoading={props.isLoading}
                placeholder={BidHistoryPlaceholder}
            >
                <div className='bidHistory'>
                    {props.playerTransferAuctionPaidHistory.playerTransferAuctionBidPaidHistory?.map((bid) => (
                        <Bid bid={bid} key={bid.id} />
                    ))}
                </div>
            </TitledSegment>
        </NarrowLayout>
    );
}

function Bid(props) {
    const styles = {
        container: {
            display: 'flex',
            alignItems: 'center'
        },
        cola: {
            flex: '1 1 auto'
        },
        colb: {
            flex: '0 1 auto'
        },
        club: {
            float: 'right',
            clear: 'right'
        },
        date: {
            float: 'right',
            clear: 'right'
        }
    }

    return(
        <div style={styles.container}>
            <div style={styles.cola}>
                <h3><Component_Currency value={props.bid.finalBid} /></h3>
            </div>
            <div style={styles.colb}>
                <span style={styles.club}><ClubLink club={props.bid.club} /></span>
                <span style={styles.date}><FormattedDate value={props.bid.createdAt} day='numeric' month='short' /></span>
            </div>
        </div>
    )
}

function BidHistoryPlaceholder() {
    return(
        <Placeholder>
            <Placeholder.Header>
                <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
                <Placeholder.Line />
            </Placeholder.Paragraph>
        </Placeholder>
    )
}
import { faListOl } from '@fortawesome/free-solid-svg-icons';
import {defineMessages} from 'react-intl';
import {generatePath} from 'react-router-dom'

import {URL} from '../../common/lib/paths';
import ToolbarItem from '../../menu/toolbar/ToolbarItem';

import {useTutorial} from '../../tutorial/hooks/useTutorial';

const messages = defineMessages({
    text: {
        id: 'nation:toolbar:leagues',
        defaultMessage: 'Leagues',
        description: 'Link in the toolbar in the nation section for the nation leagues page'
    }
})

export default function NationLeagues(props) {
    const {checkTutorial, getSearchParamsString} = useTutorial();

    const isTutorial1 = checkTutorial('world', 1);

    return(
        <ToolbarItem
            path={{
                pathname: generatePath(URL.nationLeagues, {nationId: props.nationId}),
                search: isTutorial1 && getSearchParamsString('world', 2)
            }}
            icon={faListOl}
            messages={messages}
            glow={isTutorial1}
            genericPath={URL.nationLeagues}
        />
    )
}
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dimmer, Header, Menu, Segment } from "semantic-ui-react";
import { faArrowLeft, faArrowRight, faBook, faXmark } from "@fortawesome/free-solid-svg-icons";
import {useTutorial} from '../hooks/useTutorial';
import { useEffect, useRef, useState } from "react";
import { useConfetti } from "../../common/useConfetti";
import colours from "../../common/colours";
import SUIIconShim from "../../common/components/SUIIconShim";
import { useHistory } from "react-router";
import { URL } from "../../common/lib/paths";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group-react-18";

export default function TutorialWidget(props) {
    const history = useHistory();
    const [tutorialWidgetOpen, setTutorialWidgetOpen] = useState(false);
    const {setShowConfetti} = useConfetti();
    const {
        isTutorialActive,
        tutorialKey,
        tutorialStep,
        getCached,
        goPrevStep,
        goNextStep,
        exitTutorial,
        checkTutorial
    } = useTutorial();
    const nodeRef = useRef(null);
    
    useEffect(() => {
        if(isTutorialActive === true) {
            setTutorialWidgetOpen(true);
        }
    }, [isTutorialActive]);

    useEffect(() => {
        if(isTutorialActive === true && tutorialWidgetOpen === false) {
            setTutorialWidgetOpen(true);
        }
    }, [tutorialStep]);

    if(!isTutorialActive) return null;

    const tutorialData = getCached(tutorialKey);

    if(!tutorialData) return null;

    const currentStepData = tutorialData?.steps[tutorialStep];
    const isFirstStep = tutorialStep === 0;
    const isLastStep = tutorialStep+1 === tutorialData?.steps.length;

    const isTutorial1 = checkTutorial('intro', 0);

    return(
        <div style={styles.container}>
            <Dimmer active={tutorialWidgetOpen} page={true} className='tutorialDimmer' />
            <CSSTransition
                nodeRef={nodeRef}
                in={tutorialWidgetOpen}
                timeout={300}
                classNames='tTest'
            >
                <div style={styles.inner} ref={nodeRef}>
                    <p>{currentStepData.description}</p>
                    <div style={styles.button}>
                        <Button
                            primary
                            onClick={handleButtonClick.bind(this)}
                        >
                            {isLastStep === true && 'Finish'}
                            {currentStepData.task === 'info' && 'Got it'}
                            {currentStepData.task === 'interaction' && "Let's do it"}
                            {currentStepData.task === 'browse' && "Take a look"}
                        </Button>
                        {isLastStep === true &&
                            <Link
                                to={URL.tutorialMenu}
                                style={{marginTop: '1em'}}
                            >
                                More tutorials
                            </Link>
                        }
                    </div>
                </div>
            </CSSTransition>
            <div style={styles.banner}>
                <div style={{display: 'flex', height: '100%'}}>
                    <div
                        style={styles.summary}
                        className={`tutorialSummary ${tutorialWidgetOpen ? 'closed' : 'open'}`}
                        onClick={() => setTutorialWidgetOpen(true)}
                    >
                        <FontAwesomeIcon icon={currentStepData.icon || tutorialData.icon || faBook} size='xl' className="inlineIcon" />
                        <div>
                            <Header as='h5'>{tutorialData.name} tutorial</Header>
                            {currentStepData.summary}
                        </div>
                    </div>
                    <Menu
                        icon
                        size='tiny'
                        style={styles.controls}
                        color="blue"
                        inverted
                        className='tutorialControls'
                    >
                        <Menu.Item
                            disabled={isFirstStep}
                            onClick={() => goPrevStep()}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faArrowLeft} size='lg' />
                            </SUIIconShim>
                        </Menu.Item>
                        <Menu.Item
                            disabled={isLastStep}
                            onClick={() => goNextStep()}
                            className={currentStepData.task === 'browse' && tutorialWidgetOpen === false && 'glow'}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faArrowRight} size='lg' />
                            </SUIIconShim>
                        </Menu.Item>
                        <Menu.Item
                            onClick={() => exitTutorial()}
                            className={isTutorial1 && 'glow'}
                        >
                            <SUIIconShim>
                                <FontAwesomeIcon icon={faXmark} size='lg' />
                            </SUIIconShim>
                        </Menu.Item>
                    </Menu>
                </div>
                <div style={styles.progress} className={`tutorialProgress ${tutorialWidgetOpen ? 'closed' : 'open'}`}>
                    <FontAwesomeIcon icon={currentStepData.icon || tutorialData.icon || faBook} size='xl' className="inlineIcon" />
                    <div>
                        <Header as='h5' style={{color: 'white'}}>{tutorialData.name} tutorial</Header>
                        Step {tutorialStep+1} of {tutorialData.steps.length}
                    </div>
                </div>
            </div>
        </div>
    )

    function handleButtonClick() {
        if(isLastStep) {
            exitTutorial();
            setShowConfetti(true);
        } else if(currentStepData.task !== undefined && currentStepData.task !== 'info') {
            setTutorialWidgetOpen(false);
        } else {
            goNextStep();
        }
    }
    
    function handleTutorialClick() {
        history.push({
            pathname: URL.tutorialMenu
        });
    }
}

const styles = {
    container: {
        maxWidth: '400px',
        minWidth: '325px',
        bottom: 0,
        left: 0,
        right: 0,
        margin: '0 auto',
        position: 'absolute',
        overflow: 'hidden',
    },
    banner: {
        backgroundColor: colours.navy,
        height: '40px',
        width: '100%',
        position: 'relative',
        zIndex: 100000,
        overflow: 'hidden'
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    inner: {
        minHeight: '100px',
        width: '100%',
        position: 'relative',
        backgroundColor: '#FFF',
        zIndex: 100000,
        padding: '1em',
        margin: 0,
        borderTopLeftRadius: '.28571429rem',
        borderTopRightRadius: '.28571429rem',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0
    },
    summary: {
        backgroundColor: 'white',
        height: '100%',
        borderTop: '1px solid #192d4d',
        borderLeft: '1px solid #192d4d',
        flex: '1 1 auto',
        padding: '0 0.5em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer'
    },
    label: {
        display: 'flex',
        alignItems: 'center'
    },
    controls: {
        flex: '0 0 auto',
        margin: 0
    },
    progress: {
        width: '50%',
        height: '100%',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        padding: '0 0.5em'
    }
}
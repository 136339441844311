import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { defineMessages, FormattedMessage } from "react-intl";
import { Button, Message } from "semantic-ui-react";
import TitledSegment from "../../../../../common/components/TitledSegment";

export default function CreatePlayerLoanOfferInterface(props) {
    return(
        <TitledSegment
            title='Loan offer'
            icon={faStopwatch}
        >
            <p>You can offer to take this player on loan until the end of the season</p>
            <p>The player's contracted club will continue to pay their salary</p>
            <Button
                positive
                fluid
                loading={props.isSubmitting}
                onClick={props.handleSubmitBid}
            >
                Submit loan offer
            </Button>
            {props.submitBidError &&
                <Message negative>
                    <FormattedMessage
                        {...(submitBidErrorMessages[props.submitBidError.response?.key] || submitBidErrorMessages['unknown'])}
                        values={{key: props.submitBidError.response?.key || '-1'}}
                    />
                </Message>
            }
        </TitledSegment>
    )
}

const submitBidErrorMessages = defineMessages({
    'unknown': {
        id: 'player:loanOffer:create:error:general',
        defaultMessage: 'An unknown error occured, please report this ({key})',
        description: 'An unknown error occured'
    },
    'FREQUENCY_ERROR': {
        id: 'player:loanOffer:create:error:pendingBid',
        defaultMessage: 'You have already submitted a bid for this player or have recently had one rejected.'
    },
    'AUCTION_IN_PROGRESS': {
        id: 'player:loanOffer:create:error:auctionInProgress',
        defaultMessage: 'You cannot bid for this player as there is currently an auction in progress'
    },
    'PLAYER_RETIRING': {
        id: 'player:loanOffer:create:error:playerRetiring',
        defaultMessage: 'You cannot bid for this player because they are retired or retiring',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is retired/retiring'
    },
    'REPUTATION_MISMATCH': {
        id: 'player:loanOffer:create:error:reputationAbs',
        defaultMessage: 'This player is not interested in joining your club',
    },
    'OWN_PLAYER': {
        id: 'player:transferOffer:create:error:ownPlayer',
        defaultMessage: 'You cannot bid for your own player.'
    },
    'MODIFY_RESOURCE_PERMISSION_DENIED': {
        id: 'player:loanOffer:create:error:permission',
        defaultMessage: 'The player\'s current club have blocked offers for this player.'
    },
    'YOUTH_BLOCK': {
        id: 'player:loanOffer:create:error:youthPlayer',
        defaultMessage: 'Loan offers are not allowed for youth players',
        description: 'Error message shown if a transfer bid cannot be submitted because the player is a youth player'
    },
    'PLAYER_LOAN': {
        id: 'player:loanOffer:create:error:playerLoan',
        defaultMessage: 'You cannnot make a bid for a player who is already on loan at another club',
        description: 'Error message shown when a  bid is made for a player who is on loan'
    },
    'TRANSFER_BLOCK': {
        id: 'player:loanOffer:create:error:transferBlock',
        defaultMessage: 'You cannot bid for this player as they have only recently joined their club.',
        description: 'Error message shown if you try to bid for a player who has only recently moved clubs'
    },
    'NOT_ENOUGH_PLAYERS': {
        id: 'player:loanOffer:create:error:tooFewPlayers',
        defaultMessage: 'You cannot bid for this player because their club would be left with too few players',
        description: 'Error message shown if a transfer bid cannot be submitted because the receiving club has too few players'
    },
    'PLAYER_RATING_HIGH': {
        id: 'player:loanOffer:create:error:playerRatingHigh',
        defaultMessage: 'This player is too good for his club to let him leave on loan',
        description: 'Error message shown if a loan bid is made for a player who is a valuable first team player'
    },
    'TOO_MANY_PLAYERS': {
        id: 'player:loanOffer:create:error:tooManyPlayers',
        defaultMessage: 'You cannot bid for this player because you have too many players',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of players'
    },
    'LOAN_OFFER_LIMIT': {
        id: 'player:loanOffer:create:error:loanOfferLimit',
        defaultMessage: 'You cannot have more than 4 active loan offers at a time',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of loan offers'
    },
    'LOAN_LIMIT': {
        id: 'player:loanOffer:create:error:loanLimit',
        defaultMessage: 'You cannot bid for this player because you already have the maximum number of loan players (4)',
        description: 'Error message shown if a transfer bid cannot be submitted because the club already has the maximum number of loan players'
    }
});
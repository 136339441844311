import {generatePath} from "react-router";
import {defineMessages} from "react-intl";

import ToolbarItem from "../../../menu/toolbar/ToolbarItem.js";

import {URL} from "../../../common/lib/paths.js";
import { faRightLeft } from "@fortawesome/free-solid-svg-icons";

const messages = defineMessages({
	text: {
		id: "Component_Toolbar_ClubTransfers.text",
		defaultMessage: "Transfers",
		description: "Link for club transfers page"
	}
})

export default function ClubTransfersToolbarItem(props) {
	if(props.clubId === undefined) return null;

	return(
		<ToolbarItem
			path={generatePath(URL.clubTransferHistory, {clubId: props.clubId})}
			icon={faRightLeft}
			messages={messages}
			activeToolbarItemRef={props.activeToolbarItemRef}
		/>
	);
}
//last verified against STAGE SIX spreadsheet 26/10/21

export default {
    positionMap: [
        'GK',
        'LB',
        'CB',
        'CB',
        'RB',
        'CM',
        'CM',
        'CM',
        'CAM',
        'ST',
        'ST'
    ],
    pitchHalfMap: [
        12,
        1,
        7,
        19,
        25,
        9,
        15,
        21,
        16,
        11,
        23
    ]
}
import InfoLabel from "../../../player/common/InfoLabel"
import PlayerLink from "../../../player/common/Link"
import PositionLabel from "../../../player/common/position/PositionLabel"

/*

    Want info labels to be right aligned? Wrap in the following

    <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}} data-tag='allowRowEvents'>
    </div>

*/

<div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}} data-tag='allowRowEvents'></div>

function playerName(
    playerResolver,
    playerContractResolver,
    playerRegResolver,
    teamTacticPositionResolver,
    teamFormation,
    playerLinkSearch,
    playerLinkClassName,
    sortFunction
) {
    return {
        id: 'playerName',
        name: 'Name',
        selector: (rowData, i) => 
            <>
                {!!teamTacticPositionResolver ?
                    <PositionLabel
                        teamTacticPosition={teamTacticPositionResolver(rowData, i)}
                    />
                    :
                    null
                }
                <div className='playerName'>
                    <PlayerLink
                        player={playerResolver(rowData, i)}
                        search={playerLinkSearch ? playerLinkSearch(rowData, i) : undefined}
                        className={playerLinkClassName ? playerLinkClassName(rowData, i) : undefined}
                    />
                </div>
                <div className='infoLabels'>
                    <InfoLabel
                        player={playerResolver(rowData, i)}
                        playerContract={playerContractResolver(rowData, i)}
                    />
                </div>
            </>,
        sortable: true,
        sortFunction,
        ignoreRowClick: true,
        wrap: false,
        compact: true,
        grow: 3,
        style: {
            '&[role="cell"]': {
                paddingTop: 0,
                paddingBottom: 0
            }
        }
    }
}

export {
    playerName
}